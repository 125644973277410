import React, { forwardRef } from 'react';
import { IRadioButtonProps } from './RadioButton.types';
import RadioButtonStyles from './RadioButton.styles';
import Label from '../Label';
import Stack from '../Stack/Stack';

const RadioButton = forwardRef<HTMLDivElement, IRadioButtonProps>((props, ref) => {
    const { children, label, className, name, onChange, checked, readOnly, id, disabled, labelProps, required, tabIndex = 0, dataAid, ...rest } = props;
    const inputRef = React.useRef<HTMLInputElement>(null);
    const [checkedState, setCheckedState] = React.useState<boolean>(checked || false);

    const handleOnChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        if (checked === undefined) {
            setCheckedState(event.target.checked);
        }
        onChange && onChange(event);
    };

    const handleOnLabelClick = () => {
        inputRef?.current?.click();
    };

    React.useEffect(() => {
        setCheckedState(checked || false);
    }, [checked]);

    return (
        <RadioButtonStyles.Wrapper
            {...rest}
            className={className}
            ref={ref}
            disabled={disabled || readOnly}
        >
            <Stack padding={2}>
                <RadioButtonStyles.Input
                    ref={inputRef}
                    checked={checkedState}
                    disabled={disabled || readOnly}
                    type='radio'
                    name={name || `radioButton-${id}`}
                    onChange={handleOnChange}
                    id={id}
                    tabIndex={tabIndex}
                    data-aid={dataAid}
                />
            </Stack>
            <RadioButtonStyles.LabelWrapper disabled={disabled || readOnly} size={labelProps?.size || 'md'} hasChildren={!!children} onClick={handleOnLabelClick}>
                {label && <Label {...labelProps} size={labelProps?.size || 'md'} text={label} color="inherit" required={required} whiteSpace='normal' />}
                {children}
            </RadioButtonStyles.LabelWrapper>
        </RadioButtonStyles.Wrapper>
    );
});
RadioButton.displayName = 'RadioButton';

export default RadioButton;
