import * as React from 'react';
import { SVGProps, Ref, forwardRef } from 'react';
const SvgDatabase = (
  props: SVGProps<SVGSVGElement>,
  ref: Ref<SVGSVGElement>
) => (
  <svg
    viewBox="0 0 16 16"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    ref={ref}
    {...props}
  >
    <ellipse
      cx={8}
      cy={3.5}
      rx={5.5}
      ry={2}
      stroke="currentColor"
      strokeLinejoin="round"
    />
    <path
      d="M13.5 9.5c0 1.105-2.462 2-5.5 2s-5.5-.895-5.5-2M13.5 6.5c0 1.105-2.462 2-5.5 2s-5.5-.895-5.5-2"
      stroke="currentColor"
      strokeLinejoin="round"
    />
    <path
      d="M2.5 3.5s0-2 5.5-2 5.5 2 5.5 2v9s0 2-5.5 2-5.5-2-5.5-2v-9Z"
      stroke="currentColor"
      strokeLinejoin="round"
    />
  </svg>
);
const ForwardRef = forwardRef(SvgDatabase);
export default ForwardRef;
