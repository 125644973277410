import * as React from 'react';
import { SVGProps, Ref, forwardRef } from 'react';
const SvgCluster = (
  props: SVGProps<SVGSVGElement>,
  ref: Ref<SVGSVGElement>
) => (
  <svg
    viewBox="0 0 16 16"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    ref={ref}
    {...props}
  >
    <rect
      x={0.5}
      y={0.5}
      width={15}
      height={4}
      rx={0.5}
      stroke="currentColor"
    />
    <rect
      x={0.5}
      y={6.5}
      width={15}
      height={4}
      rx={0.5}
      stroke="currentColor"
    />
    <rect
      x={6.5}
      y={13.5}
      width={3}
      height={2}
      rx={1}
      stroke="currentColor"
      strokeLinejoin="round"
    />
    <path
      d="M.5 14.5h6M9.5 14.5h6M3.5 10.5v4M12.5 10.5v4M12.5 4.5v2M3.5 4.5v2M2.5 2.5h4m5 0h1m-10 6h4m5 0h1"
      stroke="currentColor"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </svg>
);
const ForwardRef = forwardRef(SvgCluster);
export default ForwardRef;
