import { AllIconsName } from 'common/design-system/components-v2/Icon/Icon.types';
import { IListItemProps } from 'common/design-system/components-v2/List/List.types';
import { buildPath } from 'common/extensibility/AddinContainer';
import { ICloudAccount } from 'common/interface/data_services';

export interface PermissionResponse {
  userId: number;
  srl: string;
  manage: boolean;
  view: boolean;
  add: boolean;
  access: boolean;
}

export const PATH_ENVIRONMENT_PAGE = 'environment';
export const PATH_AWS_ENVIRONMENT = buildPath(PATH_ENVIRONMENT_PAGE, 'awsEnvironment');
export const PATH_ENVIRONMENT_PAGE_DETAILS_PANEL = buildPath(PATH_ENVIRONMENT_PAGE, 'detailsPanel');
export const PATH_ENVIRONMENT_PAGE_TAB = buildPath(PATH_ENVIRONMENT_PAGE, 'tab');
export const PATH_ENVIRONMENT_PAGE_ACTION = buildPath(PATH_ENVIRONMENT_PAGE, 'action');
export const PATH_ENVIRONMENT_PAGE_CHIP = buildPath(PATH_ENVIRONMENT_PAGE, 'chip');

export interface AwsEnvironmentTab {
  name: string;
  label: string;
  isReactTab: boolean;
  content?: React.ReactElement;
}

export enum ActionType {
  Remove = 'remove',
  Rename = 'rename',
  SyncNow = 'sync-now',
  AddCloudBots = 'add-cloud-bots',
  RemoveIntelligence = 'remove-intelligence',
  AddCloudTrail = 'add-cloud-trail',
  AddFlowLogs = 'add-flow-logs',
  AddGuardDuty = 'add-guard-duty',
  RemoveGuardDuty = 'remove-guard-duty',
  EditCredentials = 'edit-credentials',
}

export interface ActionItem {
  type: NonNullable<IListItemProps['itemType']> | 'button',
  label: string,
  icon?: AllIconsName,
  callback?: (awsAccount: AwsEnvironmentData, extraData: AwsEnvironmentDataExtraData, reloadActions?: () => void) => void,
  disabled?: (awsAccount: AwsEnvironmentData, extraData: AwsEnvironmentDataExtraData) => boolean,
}

// AwsAction is a toolbar action that executes an action defined in AwsEnvironment Toolbar.
// Look what actions are supported at the function handleOnActionClick in:
// frontend/src/modules/assets/Environments/AwsEnvironment/components/Toolbar/Toolbar.tsx 
export interface AwsAction {
  type: 'aws',
  actionType: ActionType,
  label: string,
  icon?: AllIconsName,
}

export type AwsEnvironmentToolbarActions = ActionItem | AwsAction;

export interface AwsEnvironmentDataExtraData {
  permission: PermissionResponse;
  serverlessAccount?: ServerlessAwsAccountStatesGetResponse;
  cloudAccount: ICloudAccount;
}

export interface AwsEnvironmentData {
  account: AWSCloudAccount;
  stats: AWSCloudAccountStats;
}

export interface AWSCloudAccount {
  id: string;
  vendor: string;
  name: string;
  externalAccountNumber: string;
  error: string;
  isFetchingSuspended: boolean;
  creationDate: string;
  credentials: CloudAccountCredentials;
  iamSafe?: any;
  netSec: CloudAccountNetSec;
  magellan: boolean;
  fullProtection: boolean;
  allowReadOnly: boolean;
  organizationalUnitId?: any;
  organizationalUnitPath: string;
  organizationalUnitName: string;
  lambdaScanner: boolean;
  serverless: CloudAccountServerless;
  onboardingMode: CloudAccountOnboardingMode;
  organizationId?: string | null;
}

interface CloudAccountServerless {
  codeAnalyzerEnabled: boolean;
  codeDependencyAnalyzerEnabled: boolean;
}

interface CloudAccountNetSec {
  regions: CloudAccountRegion[];
}

interface CloudAccountRegion {
  region: string;
  name: string;
  hidden: boolean;
  newGroupBehavior: string;
}

interface CloudAccountCredentials {
  apikey?: any;
  arn: string;
  secret?: any;
  iamUser?: any;
  type: 'UserBased' | 'RoleBased';
  isReadOnly: boolean;
}

export enum CloudAccountOnboardingMode {
  UNDEFINED = 'Undefined',
  LEGACY = 'Legacy',
  CLOUD_GUARD_MANAGED = 'CloudGuardManaged',
  CUSTOMER_MANAGED = 'CustomerManaged'
}


export interface AWSCloudAccountStats {
  id: string;
  regions: CloudAccountStatsRegion[];
}

interface CloudAccountStatsRegion {
  region: string;
  protectedGroups: number;
  monitoredGroups: number;
  instances: number;
}

export interface ServerlessAwsAccountStatesGetResponse {
  cloudAccountId: string;
  externalAccountNumber: string;
  cloudVendor: string;
  state: string;
  accountId: number;
  reason: string;
  shouldUpdate: boolean;
  isOrgOnboarding: boolean;
  hasMissingPermissions: boolean;
  validatePermissionsStatus: string;
  lastValidatePermissionsTimestamp: number;
}
