import * as React from 'react';
import { SVGProps, Ref, forwardRef } from 'react';
const SvgEye = (props: SVGProps<SVGSVGElement>, ref: Ref<SVGSVGElement>) => (
  <svg
    viewBox="0 0 17 16"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    ref={ref}
    {...props}
  >
    <path
      d="M15.222 6.94c.179.223.278.513.278.813 0 .3-.099.59-.278.814-1.133 1.371-3.712 4.042-6.722 4.042-3.01 0-5.589-2.67-6.722-4.042a1.303 1.303 0 0 1-.278-.814c0-.3.1-.59.278-.813C2.911 5.568 5.49 2.898 8.5 2.898c3.01 0 5.59 2.67 6.722 4.042Z"
      stroke="currentColor"
      strokeWidth={1.5}
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M8.5 9.911a2.158 2.158 0 1 0 0-4.316 2.158 2.158 0 0 0 0 4.316Z"
      stroke="currentColor"
      strokeWidth={1.5}
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </svg>
);
const ForwardRef = forwardRef(SvgEye);
export default ForwardRef;
