import { FC } from 'react';
import { MultiChipCellContent } from 'common/erm-components/custom/MultiChipCellContent/MultiChipCellContent';
import { SelectOption } from 'common/design-system/components-v2/SelectV2/Select.types';
import { IOptionsAndValues } from 'common/erm-components/utils/ermComponents.interface';

export const MultiOptionsComponent: FC<IOptionsAndValues> = (params) => {
    const { selectedValues, allOptions } = params;

    const selectedOptions: SelectOption[] = [];
    selectedValues.forEach(value => {
        const option: SelectOption | undefined = allOptions.find(op => op.value === value);
        if (option) {
            selectedOptions.push(option);
        }
    });

    if (selectedOptions.length === 0) {
        return null;
    }

    return (
        <MultiChipCellContent propsList={selectedOptions.map(option => ({
            label: option.label,
            leadingIconProps: option.labelProps?.leadingIconProps,
        }))} />
    );
};