import React, { useCallback, useEffect, useState } from 'react';
import { Button, Stack } from 'common/design-system/components-v2';
import { useTranslation } from 'react-i18next';
import { ITableProps } from 'common/design-system/components-v2/Table/Table.types';

const withShowMoreButton = ( WrappedComponent: React.ComponentType<ITableProps>) => {

    const ITEMS_SLICE_SIZE = 5;
    const ComponentWithButton: React.FC<ITableProps> = (props) => {

        const [topItems, setTopItems] = useState<any[]>([]);
        const [maxItems, setMaxItems] = useState<number>(ITEMS_SLICE_SIZE);
        const { t } = useTranslation();
        const { gridOptions } = props;

        const updateTopItems = useCallback(() => {
            if (gridOptions?.rowData) {
                setTopItems([...gridOptions.rowData].splice(0, maxItems));
            }
        }, [gridOptions?.rowData, maxItems]);

        const hasMoreItemsToShow = useCallback(() => {
            return gridOptions?.rowData && topItems.length < gridOptions?.rowData?.length;
        }, [gridOptions?.rowData, topItems]);

        const showMoreItems = useCallback(() => {
            setMaxItems(maxItems + ITEMS_SLICE_SIZE);
        }, [maxItems]);

        useEffect(() => {
            updateTopItems();
        }, [gridOptions?.rowData, updateTopItems]);

        return (
            <Stack fullWidth>
                <WrappedComponent {...props}
                    gridOptions={{ ...gridOptions, rowData: topItems }}
                />
                {hasMoreItemsToShow() &&
                    <Stack alignItems='center' justifyContent='center' fullWidth>
                        <Button onClick={showMoreItems} iconProps={{ name: 'arrowDown' }}>
                            {t('GENERAL.SHOW_MORE')}
                        </Button>
                    </Stack>
                }
            </Stack>
        );
    };
    return ComponentWithButton;
};
export default withShowMoreButton;