import { buildPath, globalAddinContainer } from '../../../extensibility/AddinContainer';
import { PATH_SETTINGS_PREFIX } from './Integrations';
import { IntegrationDataLoader, IntegrationsDefinition } from './consts';
import { Addin } from '../../../extensibility/AddinRegistry';

export const PATH_INTEGRATIONS_OBJECT = buildPath(PATH_SETTINGS_PREFIX, 'integrations');
export const PATH_INTEGRATIONS_DATA_LOADERS = buildPath(PATH_SETTINGS_PREFIX, 'configurations-data-loaders');

export const IntegrationsRegistry = {
    addIntegrationsDefinition: (integrations: Addin<IntegrationsDefinition>[]) => {
        globalAddinContainer.add(PATH_INTEGRATIONS_OBJECT,[...integrations]);
    },

    getIntegrationsDefinitions: (): IntegrationsDefinition[] => {
        return globalAddinContainer.get<IntegrationsDefinition>(PATH_INTEGRATIONS_OBJECT);
    },

    getConfigurationsDataLoaders: (): Addin<IntegrationDataLoader>[] => {
        return globalAddinContainer.getAddins<IntegrationDataLoader>(PATH_INTEGRATIONS_DATA_LOADERS);
    },

    addConfigurationDataLoader: (dataLoader: Addin<IntegrationDataLoader>) => {
        globalAddinContainer.add(PATH_INTEGRATIONS_DATA_LOADERS, [dataLoader]);
    }
};
