import { CellToClipboardHandler } from 'common/components/ProtectedAssets/ProtectedAssetsTable';
import RiskScoreBadge from '../../RiskScore/RiskScoreBadge';


function RiskScoreCellRenderer(params: any) {
    let { value }: { value: number } = params;
    const { className }: { className: string } = params;

    if (typeof value === 'string' && value !== '' && !isNaN(Number(value))) {
        value = Number(value);
    }
    if (typeof value === 'number') {
        return <RiskScoreBadge score={value} className={className} />;
    }

    CellToClipboardHandler(params, String(value));
    return (<></>);
}

export default RiskScoreCellRenderer;
