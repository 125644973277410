import styled from 'styled-components';
import { Label, Stack, Typography } from '../../../design-system/components-v2';
import { IItemWidthInfo } from './CustomForm.interface';

const TopDiv = styled(Stack)`
  flex-direction: column;
  width: 100%;
  height: 100%;
  position: relative;
`;

const LoadingOverlay = styled(Stack)`
    position: fixed;
    left: 0;
    right: 0;
    top: 0;
    bottom: 0;
    z-index: 999;
    background: black;
    opacity: 0.2;
    align-items: center;
    justify-content: center;
`;

const Content = styled(Stack)`
`;

const TopAreaDiv = styled(Stack)`
  overflow-y: auto;
  padding-right: 10px;
`;

const ButtonsDiv = styled(Stack)`
  flex-direction: column;
  width: 100%;
  padding-right: 20px;
`;

const Error = styled.div`
  font-size: 10px;
  color: red;
`;

const ErrorContainer = styled(Stack)`
  width: 100%;
`;

const RadioButtonsHeader = styled(Typography)`
    padding: 8px 0 3px 0
`;

const RadioButtons = styled(Stack)`
  flex-direction: row;
  width: 100%;
  gap: 24px;
`;


const TopItem = styled(Stack)<{ widthInfo?: IItemWidthInfo, isInner?: boolean, withBackground?: boolean }>`
    flex-direction: column;
    gap: 5px;
    min-width: ${props => props.widthInfo?.minWidth};
    max-width: ${props => props.widthInfo?.minWidth};
    width: ${props => props.widthInfo?.width ?? (props.isInner ? undefined : '100%')};
    flex-grow: ${props => props.widthInfo?.flexGrow ?? (props.isInner ? (props.widthInfo?.width ? 0 : 1) : undefined )};
    background: ${({ theme, withBackground }) => withBackground ? theme.palette.surface.secondary : undefined};
`;

const TextCounter = styled.span`
    font-size: 10px;
    text-align: right;
    margin-top: -3px;
`;

const Divider = styled.div`
    margin: 15px 0 5px;
    border-bottom: ${({ theme }) => `${theme.border.width.standard}${theme.units} solid ${theme.palette.border.strong}`};
`;

const Spacer = styled.div`
    margin: 15px 0;
`;

const TagTitle = styled(Label)`
    flex: 1;
`;

const TagTitleFiller = styled.div`
    width: 20px;
    flex-grow: 0;
`;

const TagInputContainer = styled(Stack)`
    flex: 1;
`;

const MultiInputEntryContainer = styled(Stack)`
    flex: 1;
`;

const Asterisk = styled(Typography)`
    align-self: flex-start;
    padding: 0 3px;
`;

export const FormStyled = {
    TopDiv,
    LoadingOverlay,
    TopItem,
    RadioButtons,
    RadioButtonsHeader,
    Content,
    TopAreaDiv,
    TextCounter,
    ButtonsDiv,
    ErrorContainer,
    Error,
    Divider,
    Spacer,
    Asterisk,
    TagTitle,
    TagTitleFiller,
    TagInputContainer,
    MultiInputEntryContainer,
};