import styled, { css } from 'styled-components';
import Stack from '../Stack/Stack';
import IconButton from '../IconButton/IconButton';
import { addAlpha } from '../../theme/colors';

const Wrapper = styled(Stack)`

`;

interface ListItemProps {
    isNested: boolean;
    selected?: boolean;
    disabled?: boolean;
    itemType?: 'seperator' | 'text';
}
const ListItem = styled(Stack)<ListItemProps>`
    border-radius: 8px;
    outline: unset;

    ${({ disabled, theme }) => !disabled && css`
        cursor: pointer;
        * {
            cursor: pointer;
        }
        &:hover {
            background: ${theme.palette.componentStates.ghostHover};
        }
        &:focus-visible {
            background: ${theme.palette.componentStates.ghostHover};
            box-shadow: ${theme.shadows.focusThin};
        }
    `}

    ${({ selected, disabled, theme }) => selected && css`
        background: ${disabled ? addAlpha(theme.palette.componentStates.ghostActive, theme.disabledOpacity) : theme.palette.componentStates.ghostActive};
        ${!disabled && css`
            &:hover {
                background: ${theme.palette.componentStates.ghostDoubleActive};
            }
            &:focus-visible {
                background: ${theme.palette.componentStates.ghostDoubleActive};
                box-shadow: ${theme.shadows.focusThin};
            }
        `}
    `}

    ${({ disabled }) => disabled && css`
        /* pointer-events: none; */
        /* * {
            pointer-events: none;
        } */
    `}

    ${({ itemType }) => (itemType === 'seperator' || itemType === 'text') && css`
        padding: 0;
        height: 1px;
        background: ${({ theme }) => theme.palette.componentStates.ghostHover};
    `}
`;

interface NestedIconProps {
    isOpen: boolean;
}
const NestedIcon = styled(IconButton)<NestedIconProps>`
    ${({ isOpen }) => isOpen && css`
        transform: rotate(90deg);
    `}
`;

const DividerItem = styled(Stack)`
    &::before, &::after {
        content: '';
        background: ${({ theme }) => theme.palette.componentStates.ghostHover};
        height: 1px;
        flex: 1;
        min-width: 30px;
    }
`;

const ListStyles = {
    Wrapper,
    ListItem,
    NestedIcon,
    DividerItem
};

export default ListStyles;