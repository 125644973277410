import React from 'react';
import { BoxStyle } from './StatusBox.styled';
import { Typography } from 'common/design-system/components-v2';

export const StatusBox: React.FC<{ text: string, children: React.ReactNode }> = ({ text, children }) => {
    return (
        <BoxStyle.Top>
            <Typography variant={'body500'}>{text}</Typography>
            <BoxStyle.Body>
                {children}
            </BoxStyle.Body>
        </BoxStyle.Top>
    );
};
