import { GSL_RUN_URL, GSL_SERVICE_ID, IGslService } from 'common/module_interface/intelligence/intelligence';
import { IGslRunResponse } from 'common/module_interface/intelligence/Intelligence.interface';
import { globalAddinContainer } from 'common/extensibility/AddinContainer';
import {
    convertFacetsToAggregations, createGetAggregationsQuery,
    createGetCountQuery,
    createGetItemsQuery,
} from './GslServiceQueries';
import { IGslCount, IGslFilter, IGslSort } from 'common/erm-components/services/gsl/GslService.interface';
import { Aggregations } from 'common/components/FilterPanel/FilterPanel.interface';
import { sendHttpRequest } from '../../utils/ermComponents.http';
import isArray from 'lodash/isArray';

export class GslService implements IGslService {

    public async getItems(filter: IGslFilter, limit?: number, sort?: IGslSort[], additionalParams?: any): Promise<IGslRunResponse> {
        const gslQuery = createGetItemsQuery(filter, limit, sort);
        return sendHttpRequest(GSL_RUN_URL, {
            method: 'POST',
            data: {
                ...additionalParams,
                gsl: gslQuery,
                withCredentials: true,
            }
        });
    }

    public async getCount(filter: IGslFilter, count: IGslCount, additionalParams?: any): Promise<number> {
        const gslQuery = createGetCountQuery(filter, count);
        return sendHttpRequest<number>(GSL_RUN_URL, {
            method: 'POST',
            data: {
                ...additionalParams,
                gsl: gslQuery,
                withCredentials: true,
            }
        });
    }

    public async getAggregations(filter: IGslFilter, aggregations: string[], additionalParams?: any): Promise<Aggregations> {
        // Note: there is an any here and a small workaround to fix a backend issue, it's supposed to be fixed in
        // the server, and then we will be able to remove this
        const gslQuery = createGetAggregationsQuery(filter, aggregations);
        const result: IGslRunResponse | IGslRunResponse[] = await sendHttpRequest<IGslRunResponse | IGslRunResponse[]>(GSL_RUN_URL, {
            method: 'POST',
            data: {
                ...additionalParams,
                gsl: gslQuery,
                withCredentials: true,
            },
        });
        const facets: IGslRunResponse[] = [];
        if (!isArray(result)) {
            facets.push(result as IGslRunResponse);
        } else {
            facets.push(...result as IGslRunResponse[]);
        }
        return convertFacetsToAggregations(aggregations, facets);
    }
}

export default function initializeGslService() {
    globalAddinContainer.addService(GSL_SERVICE_ID, new GslService());
}
