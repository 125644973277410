import {
    Aggregations,
    IFilterFields,
    IFilterPanelView,
    IFiltersValues,
    ILastEvent,
    IRenderedFilter,
    IRenderedHandler,
    SetInRequestObj,
} from './FilterPanel.interface';
import FilterPanel from './FilterPanel';
import { FILTER_EVENTS, FILTER_PANEL_QUERY_NAMES } from './FilterPanel.consts';
import { getStoreService } from '../../interface/services';
import FilterPanelReducer, { setFiltersValues } from './FilterPanel.reducer';
import { IApplicationReducer } from '../../interface/redux';
import { APPLICATION_REDUCERS } from '../../extensibility/WellKnownPaths';
import { globalAddinContainer } from '../../extensibility/AddinContainer';
import { logEvent } from '../../utils/logUtils';
import { FC } from 'react';
import { getStoredFilterValuesById } from './filterUtils';

export interface IFilterProps<R=any> {
    filterProps: any;
    displayTypes?: string[];
    keyInObjectForAPI?: string;
    hiddenList?: string[];
    setInRequestObj?: SetInRequestObj<R>;
    renderFunction: (
        props: any,
        displayTypes?: string[],
        hiddenList?: string[],
        keyInObjectForAPI?: string,
        setInRequestObj?: SetInRequestObj,
    ) => IRenderedFilter | IRenderedHandler;
}

export interface IFilterPanelManagerProps<R=any> {
    filterPanelElementsList: IFilterProps<R>[];
    getAggregations: (filtersValues: IFiltersValues) => Promise<Aggregations>;
    shouldBuildObjectForAPI?: boolean;
    webAppQueryParams?: IFiltersValues;
    shouldUseQueryParams?: boolean,
    filterId?: string;
    onFilterChangeCallBack?: Function;
    onFilterPanelAsyncChangeFinishedCallback?: Function;
    onFilterPanelAsyncChangeStartedCallback?: Function;
    filterPanelViews?: { key: string, component: FC<{ filterViewProps: IFilterPanelView }> }[],
    initialValues?: any;
}

export const renderFilters = (filtersProps: any): IRenderedFilter[] => {
    const filters = [] as IRenderedFilter[];

    filtersProps.forEach((filter: any) => {
        filters.push(
            filter.renderFunction(filter.filterProps, filter.displayTypes, filter.hiddenList, filter.keyInObjectForAPI, filter.setInRequestObj),
        );
    });

    return filters;
};


export const extractFilterFieldsToNewModel = (filterFields: IFiltersValues[]): IFilterFields => {
    return filterFields.reduce((acc, filter) => {
        if (!acc[filter.name]) {
            acc[filter.name] = [];
        }
        acc[filter.name].push(filter.value);
        return acc;
    }, {});
};


export const getFilterParamsFromURL = (): any => {
    const queryParams = new URLSearchParams(window.location.search);
    const query = queryParams.get(FILTER_PANEL_QUERY_NAMES.FILTER_PANEL) || '';
    return query ? JSON.parse(query) : {};
};

function filterUnusedFilterValues(filtersValues: IFiltersValues, filters: IRenderedFilter[]) {
    const filterValuesForParams = {} as IFiltersValues;
    Object.keys(filtersValues).forEach((key) => {
        if (isFilterShouldBeInQuery(key, filtersValues[key], filters)) {
            filterValuesForParams[key] = filtersValues[key];
        }
    });
    return filterValuesForParams;
}

export const setFilterParamsToURL = (filtersValues: IFiltersValues, filters: IRenderedFilter[]) => {
    const filterValuesForParams = filterUnusedFilterValues(filtersValues, filters);

    const queryParams = new URLSearchParams(window.location.search);
    queryParams.delete(FILTER_PANEL_QUERY_NAMES.QUERY); // << query is the default name from webapp, if we will need we can add this to props of initFilterPanel later
    queryParams.delete(FILTER_PANEL_QUERY_NAMES.FILTER_PANEL);

    window.history.replaceState(
        null,
        '',
        `${window.location.origin + window.location.pathname}?${FILTER_PANEL_QUERY_NAMES.FILTER_PANEL}=${JSON.stringify(
            filterValuesForParams,
        )}&${queryParams.toString()}`,
    );
};

const FILTER_CHANGED_EVENT = 'filterChanged';

function convertWebAppParams(filtersValues: IFiltersValues, filters: IRenderedFilter[]): IFiltersValues {
    const filterValuesForParams = {} as IFiltersValues;
    Object.keys(filtersValues).forEach((key) => {
        const filterFromList = filters.find((filter) => filter.filterProps.key === key);
        if (filterFromList && filterFromList.filterProps.convertWebAppValue) {
            filterValuesForParams[key] = filterFromList.filterProps.convertWebAppValue(filtersValues[key]);
        } else {
            filterValuesForParams[key] = filtersValues[key];
        }
    });
    return filterValuesForParams;
}

const isFilterShouldBeInQuery = (key: string, filterValue: any, filters: IRenderedFilter[]): boolean => {
    const filterFromList = filters.filter((filter) => filter.filterProps.key === key);
    return filterFromList[0] && filterFromList[0].filterUtils.shouldBeInURLParams
        ? filterFromList[0].filterUtils.shouldBeInURLParams(filterValue)
        : !!filterValue.length;
};

export const setFiltersInitialValues = (filtersInitialValues: any, filtersList: any) => {
    return filtersList.map((filter: any) => {
        if (filtersInitialValues[filter.filterProps.key]) {
            filter.filterProps.value = filtersInitialValues[filter.filterProps.key];
        }
        return filter;
    });
};


export const initFilterPanel = (filterPanelData: IFilterPanelManagerProps): JSX.Element => {
    const {
        getAggregations,
        filterPanelElementsList,
        shouldBuildObjectForAPI = false,
        shouldUseQueryParams = true,
        webAppQueryParams,
        filterId,
        onFilterChangeCallBack,
        onFilterPanelAsyncChangeFinishedCallback,
        onFilterPanelAsyncChangeStartedCallback,
        filterPanelViews,
        initialValues,
    } = filterPanelData;

    const filters = renderFilters(filterPanelElementsList);
    const dispatch = getStoreService().dispatch;

    //set initial values to filter, from query params first, if not exist from state, if state empty from default values
    const filtersFromQueryParams = getFilterParamsFromURL();
    const hasFiltersFromQueryParams = (filtersItem: IFiltersValues) => Object.keys(filtersItem).length > 0;
    let filtersInitialValues: any = {};
    if (initialValues) {
        filtersInitialValues = initialValues;
    } else if (webAppQueryParams && hasFiltersFromQueryParams(webAppQueryParams)) {
        filtersInitialValues = convertWebAppParams(webAppQueryParams, filters);
        setFilterParamsToURL(filtersInitialValues, filters);
    } else if (shouldUseQueryParams && filtersFromQueryParams && hasFiltersFromQueryParams(filtersFromQueryParams)) {
        filtersInitialValues = filtersFromQueryParams;
    } else if (filterId) {
        filtersInitialValues = getStoredFilterValuesById(filterId);
    }

    const filtersFromQuery = setFiltersInitialValues(filtersInitialValues, filters);

    let globalAggregationsPromise = null;
    //handle changes in filter panel
    const handleFilterChanged = async ({ action, filtersValues }: ILastEvent) => {
        onFilterChangeCallBack && onFilterChangeCallBack(action, filtersValues);
        switch (action) {
            case FILTER_EVENTS.FILTER_CHANGED: {
                const filterObjectForPage = shouldBuildObjectForAPI
                    ? buildFilterObjectForAPI(filtersValues!, filters)
                    : filtersValues;
                shouldUseQueryParams && setFilterParamsToURL(filtersValues!, filters);
                if (filterId) {
                    dispatch(setFiltersValues({ filtersValues, filterId }));
                }
                logEvent(FILTER_CHANGED_EVENT, filterObjectForPage);
                const aggregationsPromise = getAggregations(filterObjectForPage);
                globalAggregationsPromise = aggregationsPromise;
                const aggregations = await aggregationsPromise;
                if (aggregationsPromise === globalAggregationsPromise) {
                    return aggregations;
                }
                return false;
            }

            default:
                return false;
        }
    };

    //return filter element for the page to use
    return <FilterPanel filterPanelProps={{
        filters: filtersFromQuery,
        onFilterPanelChange: handleFilterChanged,
        onFilterPanelAsyncChangeFinished: onFilterPanelAsyncChangeFinishedCallback,
        onFilterPanelAsyncChangeStarted: onFilterPanelAsyncChangeStartedCallback,
        views: filterPanelViews,
    }} />;
};

const buildFilterObjectForAPI = (filters: IFiltersValues, filtersList: IRenderedFilter[]): any => {
    const filterObjectForAPI = {};
    filtersList.forEach((filter) => {
        filters[filter.filterProps.key] &&
        filter.filterUtils.setInRequestObj &&
        filter.filterUtils.setInRequestObj(filterObjectForAPI, filters[filter.filterProps.key], filter.filterUtils.keyInObjectForAPI ?? filter.filterProps.key);
    });

    return filterObjectForAPI;
};

export function initializeFilterPanelSlice() {
    const reducers: IApplicationReducer[] = [
        { name: 'FilterPanelSlice', reducer: FilterPanelReducer, isBlackList: true },
    ];
    globalAddinContainer.addMap(APPLICATION_REDUCERS, reducers, 'name');
}
