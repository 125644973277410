import i18n from 'i18next';
import { CompoundFilterLogicalOperator, ICompoundFilterNode } from '../custom/FilterTree/CompoundFilter';
import { IFilterCondition } from '../custom/FilterTree/FilterCondition';
import { IDisplayMappingObject } from '../../components/FilterPanel/DefaultFilters/DefaultFilters.interface';
import { Aggregations } from '../../components/FilterPanel/FilterPanel.interface';
import { GenericObject } from '../../interface/general';
import _ from 'lodash';
import { FindingFields, FindingStatusCode } from '../../module_interface/intelligence/Findings/Findings.const';

export const getYesNoDisplayMapping = (): IDisplayMappingObject => ({
    true: {
        displayText: i18n.t('GENERAL.YES'),
    },
    false: {
        displayText: i18n.t('GENERAL.NO'),
    },
});

export const getCleanCompoundFilter = (conditions?: IFilterCondition[]): ICompoundFilterNode | undefined => {
    if (!conditions || (conditions.length === 0)) {
        return;
    }

    return {
        logicalOperator: CompoundFilterLogicalOperator.AND,
        operands: conditions,
    };
};

export const ensureDefaultBooleanAggregations = (aggregations: Aggregations, fieldName: string): void => {
    for (const currKey of [true, false]) {
        if (!aggregations[fieldName]?.find((item) => currKey === item.value)) {
            aggregations[fieldName]?.push({ value: currKey, count: 0 });
        }
    }
};

export const ensureDefaultStatusAggregations = (aggregations: Aggregations): void => {
    const statusCodes: string[] = Object.values(FindingStatusCode)
        .filter((value: string | FindingStatusCode) => isFinite(Number(value)))
        .map(String);

    statusCodes.forEach((currKey: string) => {
        if (!aggregations[FindingFields.statusId]?.find(item => currKey === item.value)) {
            aggregations[FindingFields.statusId]?.push({ value: currKey, count: 0 });
        }
    });
};

export const removeEmptyValues = (obj?: GenericObject<any>): GenericObject<any> | undefined => {
    if (!obj) {
        return;
    }

    const result: GenericObject<any> = {};
    Object.keys(obj).forEach(key => {
        const value = obj[key];
        if (!_.isEmpty(value)) {
            result[key] = value;
        }
    });
    return Object.keys(result).length > 0 ? result : undefined;
};
