import * as yup from 'yup';

export interface ValidationError { [key:string]: { message:string, type: string }[] }

export const getValidationErrors = (error: any): ValidationError => {
    return new yup.ValidationError(error).inner.reduce((acc, val)=>{
        const validationName = val.path;
        if(validationName && val.type && val.message) {
            if(acc[validationName]) {
                acc[validationName].push({ message: val.message, type: val.type });
            } else {
                acc[validationName] = [{ message: val.message, type: val.type }];
            }
        }
        return acc;
    }, {} as ValidationError);
};