import * as React from 'react';
import { SVGProps, Ref, forwardRef } from 'react';
const SvgRefresh = (
  props: SVGProps<SVGSVGElement>,
  ref: Ref<SVGSVGElement>
) => (
  <svg
    viewBox="0 0 16 16"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    ref={ref}
    {...props}
  >
    <path
      d="m1.5 2.5.336 3.423L5 4.5m6.5 6.5 2.656-.93.344 3.43m-.337-3.435A6.5 6.5 0 0 1 1.515 8.469m.322-2.534a6.5 6.5 0 0 1 12.648 1.601"
      stroke="currentColor"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </svg>
);
const ForwardRef = forwardRef(SvgRefresh);
export default ForwardRef;
