import styled from 'styled-components/macro';


const PreTag = styled.div.attrs(() => ({
    className: 'p-6 flex-1'
}))`
    border-radius: 0.3em;
    border: 1px solid #00000017;
    background-color: #F5F5F5;
    color: black;
`;

export default PreTag;