import React from 'react';
import { setIsAngularFrameOnRemote, setIsReactPageWithAngular } from 'App.reducer';
import { useDispatch } from 'react-redux';
import { useHistory } from 'react-router-dom';
import { Tab } from '../EntityViewer.interface';
import useReactRouterQuery, { ChangeParamPayload } from 'common/hooks/useReactRouterQuery';

const useTabs = (tabs: Array<Tab>) => {
    const dispatch = useDispatch();
    const history = useHistory();
    const { changeParams, deleteParams, params } = useReactRouterQuery<{ tabName?: string; tabOnly?: string; }>();

    type GetSelectedTabFromUrl = () => Tab | undefined;
    const getSelectedTabFromUrl: GetSelectedTabFromUrl = React.useCallback(() => {
        return tabs.find(tab => tab.name.toLowerCase() === params.tabName?.toLowerCase()) || tabs[0];
    }, [params, tabs]);

    const selectedTab = React.useMemo(() => {
        return getSelectedTabFromUrl();
    }, [getSelectedTabFromUrl]);

    const handleOnTabChange = React.useCallback((newTab: Tab) => {
        if (newTab.name === selectedTab?.name && params.tabOnly && params.tabName) return;
        const paramsToChange: ChangeParamPayload[] = [{ paramKey: 'tabName', newValue: newTab.name }];

        if (!params.tabOnly) {
            paramsToChange.push({ paramKey: 'tabOnly', newValue: 'true' });
        }

        changeParams(paramsToChange);
    }, [changeParams, params, selectedTab]);

    React.useEffect(() => {
        if (selectedTab) {
            dispatch(setIsReactPageWithAngular(!selectedTab.isReactTab));
            dispatch(setIsAngularFrameOnRemote(!selectedTab.isReactTab));
        }
    }, [selectedTab, dispatch]);

    React.useEffect(() => {
        const newTab = getSelectedTabFromUrl();
        if (!newTab) return;
        handleOnTabChange(newTab);
    }, [history, getSelectedTabFromUrl, handleOnTabChange]);

    React.useEffect(() => {
        return () => {
            dispatch(setIsReactPageWithAngular(false));
            dispatch(setIsAngularFrameOnRemote(false));
            deleteParams(['tabName', 'tabOnly']);
        };
    }, [dispatch, deleteParams]);

    return { selectedTab, handleOnTabChange };
};

export default useTabs;
