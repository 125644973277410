import { lightPallete } from '../../design-system/theme/colors';
import { getAscendingLevels, getLevelInfo, getLevels, IBaseLevelInfo } from '../../consts/levels';
import { SortOrder } from '../../interface/general';
import { AllIconsName } from '../../design-system/components-v2/Icon/Icon.types';

export interface IRiskLevelCounters {
    unknown?: number;
    low?: number;
    medium?: number;
    high?: number;
    critical?: number;
}

export enum RiskLevelEnum {
    unknown = 'unknown',
    low = 'low',
    medium = 'medium',
    high = 'high',
    critical = 'critical',
}

export interface ILowHighRange {
    low: number;
    high: number;
}

export enum RiskLevelServerEnum {
    Unknown = 'Unknown',
    Low = 'Low',
    Medium = 'Medium',
    High = 'High',
    Critical = 'Critical',
}

export interface IRiskLevelInfo extends IBaseLevelInfo<RiskLevelEnum> {
    serverKey: RiskLevelServerEnum;
    scoreRange: ILowHighRange;
    gaugeIconName: AllIconsName;
}

export const RiskLevelsMap: { [key in RiskLevelEnum]: IRiskLevelInfo } = {
    unknown: {
        key: RiskLevelEnum.unknown,
        displayText: 'Unknown',
        serverKey: RiskLevelServerEnum.Unknown,
        level: -1,
        color: lightPallete.riskLevel.unknownFg,
        bg: lightPallete.riskLevel.unknownBg,
        context: 'default',
        scoreRange: { low: -1, high: -1 },
        gaugeIconName: 'riskGaugeUnknown',
        iconProps: {
            name: 'severityUnknown',
        },
    },
    low: {
        key: RiskLevelEnum.low,
        displayText: 'Low',
        serverKey: RiskLevelServerEnum.Low,
        level: 1,
        color: lightPallete.riskLevel.lowFg,
        bg: lightPallete.riskLevel.lowBg,
        context: 'lowRisk',
        scoreRange: { low: 0, high: 4 },
        gaugeIconName: 'riskGaugeLow',
        iconProps: {
            name: 'severityLowRisk',
        },
    },
    medium: {
        key: RiskLevelEnum.medium,
        displayText: 'Medium',
        serverKey: RiskLevelServerEnum.Medium,
        level: 2,
        color: lightPallete.riskLevel.mediumFg,
        bg: lightPallete.riskLevel.mediumBg,
        context: 'medium',
        scoreRange: { low: 4, high: 7 },
        gaugeIconName: 'riskGaugeMedium',
        iconProps: {
            name: 'severityMedium',
        },
    },
    high: {
        key: RiskLevelEnum.high,
        displayText: 'High',
        serverKey: RiskLevelServerEnum.High,
        level: 3,
        color: lightPallete.riskLevel.highFg,
        bg: lightPallete.riskLevel.highBg,
        context: 'high',
        scoreRange: { low: 7, high: 9 },
        gaugeIconName: 'riskGaugeHigh',
        iconProps: {
            name: 'severityHigh',
        },
    },
    critical: {
        key: RiskLevelEnum.critical,
        displayText: 'Critical',
        serverKey: RiskLevelServerEnum.Critical,
        level: 4,
        color: lightPallete.riskLevel.criticalFg,
        bg: lightPallete.riskLevel.criticalBg,
        context: 'critical',
        scoreRange: { low: 9, high: 10.1 },
        gaugeIconName: 'riskGaugeCritical',
        iconProps: {
            name: 'severityCritical',
        },
    },
};

const getAllRiskLevels = (): IRiskLevelInfo[] => {
    return Object.values(RiskLevelsMap);
};

const getAscendingRiskLevels = (): IRiskLevelInfo[] => {
    return getAscendingLevels<RiskLevelEnum>(getAllRiskLevels()) as IRiskLevelInfo[];
};

export const getRiskLevelInfo = (key: RiskLevelEnum | string | number): IRiskLevelInfo | undefined => {
    return getLevelInfo<RiskLevelEnum>(getAllRiskLevels(), key) as IRiskLevelInfo | undefined;
};

export const getRiskLevelByScore = (score: number): IRiskLevelInfo | undefined => {
    return getAscendingRiskLevels().find((info: IRiskLevelInfo) => {
        return info.scoreRange.low <= score && info.scoreRange.high > score;
    });
};

export const getRiskLevelColorByScore = (score: number): string | undefined => {
    const riskLevelInfo = getRiskLevelByScore(score);
    return riskLevelInfo?.color;
};

export const getRiskLevels = (order?: SortOrder, lowestKey?: RiskLevelEnum): IRiskLevelInfo[] => {
    return getLevels<RiskLevelEnum>(getAllRiskLevels(), order, lowestKey) as IRiskLevelInfo[];
};
