import { FC, useCallback } from 'react';
import { CalcStyled } from './RecipeEditorCalculator.styled';
import { Typography } from '../../../../design-system/components-v2';
import { ICustomComponentProps } from '../../../custom/CustomForm/CustomForm.interface';
import { RecipeItemNames } from '../RecipeEditor.consts';
import { getSafeTrimmedMultiValue } from '../../../custom/CustomForm/CustomForm.values';

interface IExampleCustomComponentProps extends ICustomComponentProps {}

const SPICE_COST = 2;
const VEGETABLE_COST = 10;
const MEAT_COST = 130;
const DAIRY_PRODUCT_COST = 40;
const MEAT_ALTERNATIVES_COST = 370;

export const RecipeEditorCalculator: FC<IExampleCustomComponentProps> = ({ itemsMap }) => {

    const calcIngredientCost = useCallback((itemName: string, costPerItem: number): number => {
        const values: string[] = getSafeTrimmedMultiValue(itemsMap[itemName]);
        return values ? values.length * costPerItem : 0;
    }, [itemsMap]);

    let cost = 0;
    cost += calcIngredientCost(RecipeItemNames.spices, SPICE_COST);
    cost += calcIngredientCost(RecipeItemNames.vegetables, VEGETABLE_COST);
    cost += calcIngredientCost(RecipeItemNames.dairyProducts, DAIRY_PRODUCT_COST);
    cost += calcIngredientCost(RecipeItemNames.meatTypes, MEAT_COST);
    cost += calcIngredientCost(RecipeItemNames.meatAlternatives, MEAT_ALTERNATIVES_COST);

    return (
        <CalcStyled.Top alignItems={'center'} justifyContent={'center'}>
            <Typography variant={'h2'}>{ cost } $</Typography>
        </CalcStyled.Top>
    );
};
