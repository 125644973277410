import { AllIconsName } from '../../../design-system/components-v2/Icon/Icon.types';

export const RECIPE_EDITOR_KEY = 'RECIPE_EDITOR_KEY';
export const RECIPE_PAGE_TABLE_ID = 'RECIPE_PAGE_TABLE';
export const RECIPE_ICON_NAME: AllIconsName = 'listCheck';

export enum RecipeItemNames {
    ingredientsLabel = 'ingredientsLabel',
    name = 'name',
    spices = 'spices',
    vegetablesStack = 'vegetablesStack',
    moreStack = 'moreStack',
    vegetablesIcon = 'vegetablesIcon',
    vegetables = 'vegetables',
    meatCategory = 'meatCategory',
    meatCategoryPraise = 'meatCategoryPraise',
    meatTypes = 'meatTypes',
    dairyProducts = 'dairyProducts',
    meatAlternatives = 'meatAlternatives',
    deliveryDate = 'deliveryDate',
    inventors = 'inventors',
    instructions = 'instructions',
    tastingTimeFrame = 'tastingTimeFrame',
    tags = 'tags',
    calculator='calculator',
    orgIds='orgIds',
    chefs='chefs',
    meatRadio = 'meatRadio',
    meatRadioOption = 'meatRadioOption',
    dairyRadioOption = 'dairyRadioOption',
    meatAlternativesRadioOption = 'meatAlternativesRadioOption',
}

export const RecipeHeadersNames: { [key in RecipeItemNames]: string } = {
    name: 'Recipe name',
    spices: 'Spices',
    vegetablesStack: 'Vegetables Stack',
    moreStack: 'More Stack',
    vegetablesIcon: 'Great Vegetables',
    vegetables: 'World Wide Vegetables',
    meatCategory: 'Meat Category',
    meatCategoryPraise: 'Great Choice!',
    meatTypes: 'Meat or Fish',
    dairyProducts: 'Dairy Products',
    meatAlternatives: 'Meat Alternative',
    deliveryDate: 'Delivery Date',
    inventors: 'Inventors',
    instructions: 'Instructions',
    tastingTimeFrame: 'Tasting Time Frame',
    tags: 'Tags',
    calculator: 'Cost Calculator',
    orgIds: 'Contributing Organizations',
    chefs: 'Inspiring Chefs',
    ingredientsLabel: '',
    meatRadio: '',
    meatRadioOption: '',
    dairyRadioOption: '',
    meatAlternativesRadioOption: '',
};

