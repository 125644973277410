import { Vendors } from 'common/consts/vendors';
import { getProtectedAssetsService } from 'common/module_interface/assets/ProtectedAssets';
import React from 'react';
import { ICellRendererParams } from 'ag-grid-enterprise';
import { IAsset, UrlFuncResult } from 'common/assets/common.assets';
import { CellToClipboardHandler } from 'common/components/ProtectedAssets/ProtectedAssetsTable';
import EntityLink from './EntityLink';

export const getFullEntityName = (entityName: string, entityId: string, platform: string): string => {
    if (platform === Vendors.AZURE) {
        return entityName || entityId;
    } else {
        return entityName && entityName !== entityId ? `${entityName} (${entityId})` : entityId;
    }
};

export const getPureEntityName = (entityName: string, entityId: string): string => {
    return entityName || entityId;
};

const EntityCellRender: React.FC<ICellRendererParams & { componentName?: string, generateUrl: (asset: IAsset, assetUrl: UrlFuncResult) => string }> = (params) => {
    const { entityId, name, platform, typeByPlatform, isGrouped } = params.data;
    if (isGrouped) {
        return null;
    }
    const entityName = getFullEntityName(name, entityId, platform);
    const asset = getProtectedAssetsService().getAssetByType(typeByPlatform) as IAsset;

    const url = getProtectedAssetsService().getProtectedAssetUrl({ ...params.data, generateUrl: params.generateUrl });
    if (url == null) {
        return (<div>{entityName}</div>);
    }

    CellToClipboardHandler(params, String(entityName));
    return <EntityLink entityName={entityName} url={url} containerName={params.componentName} iconProps={asset?.icon ? { vendorNameOrPath: asset.icon } : undefined} />;
};

export default EntityCellRender;