import * as React from 'react';
import { SVGProps, Ref, forwardRef } from 'react';
const SvgInstallation = (
  props: SVGProps<SVGSVGElement>,
  ref: Ref<SVGSVGElement>
) => (
  <svg
    viewBox="0 0 16 16"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    ref={ref}
    {...props}
  >
    <path
      d="m1 15 6.252-6.252"
      stroke="currentColor"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M10.31 5.604a1 1 0 0 0 1.415 0l3.37-3.37A1 1 0 1 0 13.681.82l-3.37 3.37a1 1 0 0 0 0 1.414Zm0 0L8.705 7.209"
      stroke="currentColor"
      strokeLinejoin="round"
    />
    <path
      d="m5.61 7.11 7.683 7.683a1 1 0 0 0 1.414 0l.086-.086a1 1 0 0 0 0-1.414L7.109 5.609"
      stroke="currentColor"
    />
    <path
      d="M5.358.774A3.5 3.5 0 0 0 3.12.613L4.59 2.089a1 1 0 0 1 .256.971l-.236.857a1 1 0 0 1-.702.7l-.848.23a1 1 0 0 1-.97-.258L.615 3.111A3.5 3.5 0 1 0 5.358.774Z"
      stroke="currentColor"
      strokeLinejoin="round"
    />
  </svg>
);
const ForwardRef = forwardRef(SvgInstallation);
export default ForwardRef;
