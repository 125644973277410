import { getProtectedAssetsService } from 'common/module_interface/assets/ProtectedAssets';
import { IAsset } from 'common/assets/common.assets';
import { getCloudAccountsService, getOrganizationalUnitService } from 'common/interface/data_services';
import { IProtectedAssetViewModel } from 'common/components/ProtectedAssets/ProtectedAssetsTable.interface';
import { ValueGetterFunc } from 'ag-grid-enterprise';


export function getEntityTypeValue(typeByPlatform: string): string | null {
    const asset = getProtectedAssetsService().getAssetByType(typeByPlatform) as IAsset;
    return asset?.displayName ?? asset?.type ?? null;
}

export async function getOrganizationalUnitValue(data: any): Promise<string | null> {
    const allCloudAccounts = await getCloudAccountsService().getAllCloudAccounts();
    const relevantCloudAccount = allCloudAccounts.find((cloudAccount) => {
        return cloudAccount.id === data.cloudAccountId;
    });
    if (relevantCloudAccount) {
        const organizationalUnitsPath = await getOrganizationalUnitService().getOrganizationalUnitPathById(
            relevantCloudAccount.organizationalUnitId,
        );
        if (organizationalUnitsPath) {
            const newText = organizationalUnitsPath.map((ou) => ou.item.name).join('/');
            return newText;
        }
    }
    return null;
}

export function getAdditionalFieldValue(data: IProtectedAssetViewModel, fieldName: string) {
    const additionalFields = data?.additionalFields;
    if (!additionalFields) {
        return null;
    }
    const match = additionalFields.find((item) => item.name === fieldName);
    return match?.value ?? null;
}

export function getAdditionalFieldValueExport(fieldName: string) {
    return async (data: any) => {
        return Promise.resolve(getAdditionalFieldValue(data, fieldName));
    };
}

export function getAdditionalFieldValueGetter(fieldName: string): ValueGetterFunc {
    return (params) => {
        if (params.data.isGrouped) {
            return params.data[fieldName];
        }
        
        return getAdditionalFieldValue(params?.data, fieldName) ?? undefined;
    };
}

