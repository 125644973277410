import { forwardRef } from 'react';
import Stack from '../../Stack';
import { WizardHeaderProps } from './WizardHeader.types';
import Typography from '../../Typography';
import Icon from '../../Icon';
import { Separator } from '../../Separator';
import IconButton from '../../IconButton';

const WizardHeader = forwardRef<HTMLDivElement, WizardHeaderProps>((props, ref) => {
    const { title, onClose, info } = props;

    return (
        <Stack
            ref={ref}
        >
            <Stack
                direction='row'
                alignItems='center'
                justifyContent='space-between'
                fullWidth
                padding={[4, 6]}
            >
                <Typography variant='subtitleLg'>{title}</Typography>
                <Stack direction='row' alignItems='center'>
                    {info && <Icon name='info' />}
                    {onClose && <IconButton 
                        iconProps={{
                            name: 'remove',
                        }} 
                        onClick={onClose} 
                    />}
                </Stack>
            </Stack>
            <Separator />
        </Stack>
    );
});

WizardHeader.displayName = 'WizardHeader';

export default WizardHeader;