import * as React from 'react';
import { SVGProps, Ref, forwardRef } from 'react';
const SvgSonataNexus = (
  props: SVGProps<SVGSVGElement>,
  ref: Ref<SVGSVGElement>
) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    viewBox="0 0 256 256"
    style={{
      shapeRendering: 'geometricPrecision',
      textRendering: 'geometricPrecision',
      fillRule: 'evenodd',
      clipRule: 'evenodd',
    }}
    ref={ref}
    {...props}
  >
    <path
      style={{
        opacity: 0.967,
      }}
      fill="#1b1e29"
      d="M120.5-.5h2c.332 11.02-.001 22.02-1 33a8908.152 8908.152 0 0 0-77.5 45 2401.13 2401.13 0 0 0 0 98 10313.564 10313.564 0 0 0 83.5 48l83-47.5c.663-30.767 1.33-61.6 2-92.5l27-16a1954.113 1954.113 0 0 1 0 125 4272.236 4272.236 0 0 0-108 63h-8a4271.883 4271.883 0 0 0-108-63c-1.333-44-1.333-88 0-132a7951.683 7951.683 0 0 0 105-61Z"
    />
    <path
      style={{
        opacity: 0.963,
      }}
      fill="#29b472"
      d="M132.5-.5h3c33.27 19.64 66.604 39.307 100 59a379.776 379.776 0 0 1-27.5 17 8212.063 8212.063 0 0 1-74.5-43 273.102 273.102 0 0 1-1-33Z"
    />
    <path
      style={{
        opacity: 0.939,
      }}
      fill="#29b472"
      d="M146.5 93.5h7v18c-8.383-1.217-15.549 1.116-21.5 7-.5 15.33-.667 30.663-.5 46h-19v-70h19a32.462 32.462 0 0 0 .5 8c4.343-3.915 9.177-6.915 14.5-9Z"
    />
  </svg>
);
const ForwardRef = forwardRef(SvgSonataNexus);
export default ForwardRef;
