import * as React from 'react';
import { SVGProps, Ref, forwardRef } from 'react';
const SvgNotFound = (
  props: SVGProps<SVGSVGElement>,
  ref: Ref<SVGSVGElement>
) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    viewBox="0 0 91.788 62.44"
    ref={ref}
    {...props}
  >
    <g data-name="Group 9739" transform="translate(-921.497 -293.502)">
      <path
        data-name="Path 17006"
        d="M941.898 352.647a18.9 18.9 0 0 1-18.879-19.755 19.115 19.115 0 0 1 13.014-17.055 18.008 18.008 0 0 1 6.96-.951c-.012-.291-.044-.578-.044-.872a19 19 0 0 1 36.052-8.388 18.869 18.869 0 0 1 22.529 15.575 16.305 16.305 0 0 1-6.054 31.447h-10"
        fill="none"
        stroke="#333"
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth={3}
        strokeDasharray="3 7"
      />
      <path
        data-name="Path 17007"
        d="m983.321 347.302-34.855 6.878"
        fill="none"
        stroke="#e45886"
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth={3}
      />
      <circle
        data-name="Ellipse 676"
        cx={3.5}
        cy={3.5}
        r={3.5}
        transform="translate(956.541 332)"
        fill="#e45886"
      />
      <circle
        data-name="Ellipse 677"
        cx={3.5}
        cy={3.5}
        r={3.5}
        transform="translate(970.541 332)"
        fill="#e45886"
      />
    </g>
  </svg>
);
const ForwardRef = forwardRef(SvgNotFound);
export default ForwardRef;
