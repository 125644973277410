import { getCloudAccountsService, getOrganizationalUnitService } from '../../../interface/data_services';
import { Aggregations, IFiltersValues } from '../FilterPanel.interface';
import { initFilterPanel } from '../FilterPanelManager';
import { FILTER_DISPAY_TYPES, FILTER_EVENTS } from '../FilterPanel.consts';
import FilterPanelSeparateFields from '../FilterViews/FilterSeperateFields';
import FilterPanelRow from '../FilterViews/FilterPanelRow';
import FilterPanelBox from '../FilterViews/FilterPanelBox';

export const getFiltersInitialData = async () => {
    const organizationalUnitsPromise = getOrganizationalUnitService().getOrganizationalUnitsView();
    return Promise.all([ organizationalUnitsPromise]).then(
        ([ organizationalUnits]) => {
            return {
                organizationalUnits,
            };
        },
    );
};

export const extractNewFilters = (filtersValues: IFiltersValues)=>{
    return Object.keys(filtersValues).reduce((result: { name: string, value: any }[], key: string) => {
        if(Array.isArray(filtersValues[key])) {
            filtersValues[key].forEach((valueItem: string) => {
                result.push({
                    name: key, value: valueItem
                });
            });
        } else {
            result.push({
                name: key, value: filtersValues[key]
            });
        }
        return result;
    }, []);
};


// @ts-ignore
export async function renderFilterPanelForWidgets(filterId, filters,getAggregationsFunction, getFilterDefs, widget,onFilterPanelAsyncChangeFinished,onFilterPanelAsyncChangeStarted, updateWidgetCallback, aggregations: Aggregations, initialValues, shouldBuildObjectForAPI = true) {
    const filterPanelViews = [{ key: FILTER_DISPAY_TYPES.SEPARATE_FIELDS, component: FilterPanelSeparateFields }, { key: FILTER_DISPAY_TYPES.ROW, component: FilterPanelRow },{ key: FILTER_DISPAY_TYPES.BOX, component: FilterPanelBox }];
    const filtersInitialData = await getFiltersInitialData();
    const allCloudAccounts = await getCloudAccountsService().getAllCloudAccounts();

    const filterPanelElementsList = getFilterDefs(filters, {
        aggregations,
        filtersInitialData,
        allCloudAccounts,
    });

    const getAggregations = await getAggregationsFunction();

    const noArrayFilters = ['date-picker', 'free-text'];
    const filtersFromWidget = initialValues?.reduce((result: any, filterItem: any) => {
        if(result[filterItem.name]) {
            result[filterItem.name].push(filterItem.value);
        } else {
            result[filterItem.name] = (filterItem.value || filterItem.value === 0) && !noArrayFilters.includes(filterItem.name) ? [filterItem.value] : filterItem.value;
        }
        return result;
    }, {});


    const filterPanel = initFilterPanel({
        filterPanelElementsList,
        getAggregations: getAggregations,
        shouldBuildObjectForAPI: shouldBuildObjectForAPI,
        filterId: filterId,
        onFilterChangeCallBack: (action: any, filtersValues: any) => {onFilterChangeCallBack(action, filtersValues, widget, updateWidgetCallback);},
        onFilterPanelAsyncChangeFinishedCallback: onFilterPanelAsyncChangeFinished,
        onFilterPanelAsyncChangeStartedCallback: onFilterPanelAsyncChangeStarted,
        filterPanelViews,
        initialValues: filtersFromWidget
    });
    return filterPanel;
}

export const onFilterChangeCallBack = (action: any, filtersValues: any, widget:any, updateWidgetCallback:any) => {
    if ( action === FILTER_EVENTS.FILTER_CHANGED ) {
        updateWidgetCallback(extractNewFilters(filtersValues));
    }
};
