import * as React from 'react';
import { SVGProps, Ref, forwardRef } from 'react';
const SvgRule = (props: SVGProps<SVGSVGElement>, ref: Ref<SVGSVGElement>) => (
  <svg
    viewBox="0 0 16 16"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    ref={ref}
    {...props}
  >
    <path
      d="M12 15.5H2.5a2 2 0 0 1-2-2h10v.5a1.5 1.5 0 0 0 1.5 1.5Zm0 0a1.5 1.5 0 0 0 1.5-1.5V1.5a1 1 0 0 1 1-1m0 0a1 1 0 0 1 1 1v1h-2m1-2h-10a2 2 0 0 0-2 2v11m3-10h5m-5 3h5m-5 3h5"
      stroke="currentColor"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </svg>
);
const ForwardRef = forwardRef(SvgRule);
export default ForwardRef;
