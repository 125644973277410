import { FC } from 'react';
import { FormStyled } from '../../CustomForm.styled';
import MultiSelectorTreeDropdown from '../../../../../components/MultiSelector/MultiSelectorTreeDropdown';
import { FieldLabelComponent } from '../helperComponents/FieldLabelComponent';
import { ErrorComponent } from '../helperComponents/ErrorComponent';
import { getItemSelectPlaceholder } from '../../CustomForm.values';
import { IFullErrorInfo, IOnFieldChanged, IOrgUnitsItem } from '../../CustomForm.interface';

export interface IOrgUnitsSelectComponentProps {
    item: IOrgUnitsItem;
    onFieldChanged: IOnFieldChanged;
    showErrors: boolean;
    errorInfo?: IFullErrorInfo;
    skipTitle?: boolean;
    isInner?: boolean;
}

export const OrgUnitsItemComponent: FC<IOrgUnitsSelectComponentProps> = ({ item, onFieldChanged, errorInfo, showErrors, skipTitle, isInner }) => {
    const { state } = item;
    return (
        <FormStyled.TopItem widthInfo={state.widthInfo} isInner={isInner}>
            {!skipTitle && <FieldLabelComponent item={item} />}
            <MultiSelectorTreeDropdown
                organizationalUnitsRoot={state.orgUnitsRoot}
                settings={{ maxHeight: '200px', minHeight: '150px' }}
                isEdit={!(state.disabled || state.readonly)}
                selectedIds={state.value}
                placeholder={getItemSelectPlaceholder(item)}
                onSelectionChanged={ orgUnitIds => onFieldChanged({ ...item, state: { ...state, value: orgUnitIds } })}
            />
            <ErrorComponent showErrors={showErrors} errorInfo={errorInfo} />
        </FormStyled.TopItem>
    );
};