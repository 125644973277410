import React from 'react';
import { Chip, ComponentOverflow } from 'common/design-system/components-v2';
import { useTheme } from 'styled-components';
import { IconProps } from 'common/design-system/components-v2/Icon/Icon.types';

export interface IMultiChipCellProps {
    label: string | number;
    leadingIconProps?: IconProps;
    trailIconProps?: IconProps;
}

export const MultiChipCellContent: React.FC<{ propsList: IMultiChipCellProps[] }> = ({ propsList }) => {
    const theme = useTheme();
    return (
        <ComponentOverflow
            components={propsList.map((chipProps, index) => (
                <Chip
                    label={chipProps.label}
                    leadingIconProps={chipProps.leadingIconProps}
                    trailIconProps={chipProps.trailIconProps}
                    size='sm'
                    customColor={theme.palette.surface.strong}
                    key={`chip-${index}`}
                />
            ))}
        />
    );
};
