import { Addin } from 'common/extensibility/AddinRegistry';
import globalAddinContainer, { buildPath } from '../extensibility/AddinContainer';
import {
    AlibabaEnvironmentAction,
    AlibabaEnvironmentData,
    AlibabaEnvironmentTab,
    PATH_ENVIRONMENT_PAGE,
    PATH_ENVIRONMENT_PAGE_ACTION,
    PATH_ENVIRONMENT_PAGE_ALIBABA_DETAILS_PANEL,
    PATH_ENVIRONMENT_PAGE_ALIBABA_TAB,
    PATH_ENVIRONMENT_PAGE_ALIBABA_CHIP,
} from 'common/module_interface/assets/AlibabaEnvironment';
import { BlockInfoProps } from 'common/design-system/components-v2/BlockInfo/BlockInfo.types';
import { EnvironmentChip } from '../module_interface/assets/Environments';


export interface AlibabaEnvironmentAddingBase<T> {
    isRelevant?: (alibabaData: AlibabaEnvironmentData) => Promise<boolean> | boolean;
    getValue: (alibabaData: AlibabaEnvironmentData) => Promise<T> | T;
    id: string;
    customLoadingLabel?: string; // Relevant for chips only at the moment
}

function addDetailsPanelItems(item: Addin<AlibabaEnvironmentAddingBase<BlockInfoProps>>[]) {
    globalAddinContainer.add(buildPath(PATH_ENVIRONMENT_PAGE, PATH_ENVIRONMENT_PAGE_ALIBABA_DETAILS_PANEL), item);
}

function getDetailsPanelItems(): AlibabaEnvironmentAddingBase<BlockInfoProps>[] {
    return globalAddinContainer.get(buildPath(PATH_ENVIRONMENT_PAGE, PATH_ENVIRONMENT_PAGE_ALIBABA_DETAILS_PANEL));
}

function addTabs(item: Addin<AlibabaEnvironmentAddingBase<AlibabaEnvironmentTab>>[]) {
    globalAddinContainer.add(buildPath(PATH_ENVIRONMENT_PAGE, PATH_ENVIRONMENT_PAGE_ALIBABA_TAB), item);
}

function getTabs(): AlibabaEnvironmentAddingBase<AlibabaEnvironmentTab>[] {
    return globalAddinContainer.get(buildPath(PATH_ENVIRONMENT_PAGE, PATH_ENVIRONMENT_PAGE_ALIBABA_TAB));
}

function addActions(item: Addin<AlibabaEnvironmentAddingBase<AlibabaEnvironmentAction>>[]) {
    globalAddinContainer.add(buildPath(PATH_ENVIRONMENT_PAGE, PATH_ENVIRONMENT_PAGE_ACTION), item);
}

function getActions(): AlibabaEnvironmentAddingBase<AlibabaEnvironmentAction>[] {
    return globalAddinContainer.get(buildPath(PATH_ENVIRONMENT_PAGE, PATH_ENVIRONMENT_PAGE_ACTION));
}

function addChips(item: Addin<AlibabaEnvironmentAddingBase<EnvironmentChip>>[]) {
    globalAddinContainer.add(buildPath(PATH_ENVIRONMENT_PAGE, PATH_ENVIRONMENT_PAGE_ALIBABA_CHIP), item);
}

function getChips(): AlibabaEnvironmentAddingBase<EnvironmentChip>[] {
    return globalAddinContainer.get(buildPath(PATH_ENVIRONMENT_PAGE, PATH_ENVIRONMENT_PAGE_ALIBABA_CHIP));
}

export const AlibabaEnvironmentAddinRegistry = {
    addDetailsPanelItems,
    getDetailsPanelItems,
    addTabs,
    getTabs,
    addActions,
    getActions,
    addChips,
    getChips
};