//wait <delay> ms from the last time the function has been called
export const debounce = (func: Function, delay = 300) => {
    let timeout: any;

    return function executedFunction(...args: any[]) {
        const later = () => {
            clearTimeout(timeout);
            func(...args);
        };

        clearTimeout(timeout);
        timeout = setTimeout(later, delay);
    };
};

//For any number of times function call , execute <func> once every <delay> ms
export const throttle = (func: Function, delay = 300) => {
    let prev = 0;
    return (...args: any[]) => {
        const now = new Date().getTime();
        if (now - prev > delay) {
            prev = now;
            return func(...args);
        }
    };
};

export const sleep = async (milliseconds: number): Promise<void> => {
    return new Promise((resolve) => {
        setTimeout(resolve, milliseconds);
    });
};