import * as React from 'react';
import { SVGProps, Ref, forwardRef } from 'react';
const SvgStatusWarning = (
  props: SVGProps<SVGSVGElement>,
  ref: Ref<SVGSVGElement>
) => (
  <svg
    viewBox="0 0 16 16"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    ref={ref}
    {...props}
  >
    <path
      d="M.283 15.097c.351.562.96.903 1.626.903h12.182a1.91 1.91 0 0 0 1.626-.903 1.92 1.92 0 0 0 .082-1.858L9.703 1.053A1.9 1.9 0 0 0 8 0a1.9 1.9 0 0 0-1.703 1.053L.2 13.239a1.92 1.92 0 0 0 .082 1.858ZM8.774 5.14v4.918a.773.773 0 1 1-1.548 0V5.141a.773.773 0 1 1 1.548 0Zm-1.548 7.742v-.563a.773.773 0 1 1 1.548 0v.563a.777.777 0 0 1-.774.774.777.777 0 0 1-.774-.774Z"
      fill="#EB9A00"
    />
  </svg>
);
const ForwardRef = forwardRef(SvgStatusWarning);
export default ForwardRef;
