import * as React from 'react';
import { SVGProps, Ref, forwardRef } from 'react';
const SvgSave = (props: SVGProps<SVGSVGElement>, ref: Ref<SVGSVGElement>) => (
  <svg
    viewBox="0 0 16 16"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    ref={ref}
    {...props}
  >
    <g
      clipPath="url(#save_svg__a)"
      stroke="currentColor"
      strokeLinecap="round"
      strokeLinejoin="round"
    >
      <path d="M15.5 14.5v-13a1 1 0 0 0-1-1H3.914a1 1 0 0 0-.707.293L.793 3.207a1 1 0 0 0-.293.707V14.5a1 1 0 0 0 1 1h13a1 1 0 0 0 1-1Z" />
      <path d="M6.5.5v3a1 1 0 0 0 1 1h4a1 1 0 0 0 1-1v-3m0 15v-3a1 1 0 0 0-1-1h-6a1 1 0 0 0-1 1v3" />
    </g>
    <defs>
      <clipPath id="save_svg__a">
        <path fill="#fff" d="M0 0h16v16H0z" />
      </clipPath>
    </defs>
  </svg>
);
const ForwardRef = forwardRef(SvgSave);
export default ForwardRef;
