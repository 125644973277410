import * as React from 'react';
import { SVGProps, Ref, forwardRef } from 'react';
const SvgPci = (props: SVGProps<SVGSVGElement>, ref: Ref<SVGSVGElement>) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    viewBox="0 0 128 77"
    xmlSpace="preserve"
    ref={ref}
    {...props}
  >
    <path
      fill="#225E63"
      d="m109.585 50.565 6.584-2.072-2.139-4.746c-1.57 1.972-3.141 4.512-4.445 6.818zm-2.239-21.69L94.446.133 0 5.481l26.168 71.386 50.364-15.875c-3.208-4.612-4.378-10.093-.668-12.767 4.144-3.008 10.394.468 14.371 5.381 3.843-6.417 14.638-21.356 17.111-24.731z"
    />
    <path
      fill="#FEFEFE"
      d="M91.504 20.904c3.944 0 7.152-3.008 7.152-6.751s-3.208-6.751-7.152-6.751-7.152 3.008-7.152 6.751c0 3.71 3.208 6.751 7.152 6.751zm-5.849 2.908h11.697v30.58H85.655v-30.58zM79.873 32.735c.1.033.167 0 .167-.1v-7.754c0-.1-.067-.201-.167-.267 0 0-1.537-1.003-6.216-1.27-.234-.134-4.345-.167-5.448 0-17.044 1.37-17.679 13.702-17.679 14.237v3.008c0 .368 0 12.967 17.679 14.07 1.738.134 5.113 0 5.448 0 4.077 0 6.885-1.237 6.885-1.237a.252.252 0 0 0 .167-.234v-7.252c0-.1-.067-.134-.134-.067 0 0-1.27 1.003-6.818 1.571-1.571.167-2.339.1-2.908 0-7.887-1.337-8.255-7.085-8.255-7.085 0-.1-.033-.267-.033-.334v-2.206c0-.1 0-.267.033-.334 0 0 .535-6.183 8.255-6.851h2.908c3.376.434 6.116 2.105 6.116 2.105zM18.046 54.291c0 .1.067.167.167.167h11.396c.1 0 .167-.067.167-.167v-8.89c0-.1.067-.167.167-.167 0 0 18.214 1.303 18.214-10.895 0-9.658-11.43-10.695-15.173-10.527H18.212c-.1 0-.167.067-.167.167v30.312zm11.53-15.841v-7.987h2.807s4.044.167 4.378 2.941c.067.201.067 1.571 0 1.638-.535 3.242-4.044 3.409-4.044 3.409h-3.141z"
    />
    <path
      fill="#2BBC5D"
      d="M91.839 69.146c.936 0 1.671 0 2.874-.535 4.144-2.172 18.147-36.361 32.919-46.889.1-.067.201-.167.267-.267.1-.134.1-.267.1-.267s0-.702-2.172-.702c-13.134-.368-26.803 27.204-33.989 38.099-.1.134-.568 0-.568 0s-4.813-5.681-8.99-7.854c-.1-.033-.568-.201-1.069-.167-.334 0-2.273.401-3.175 1.337C76.967 53.037 77 53.672 77 55.043c0 .1.067.568.201.802 1.036 1.805 5.681 8.221 9.525 11.764.568.435 1.47 1.537 5.113 1.537z"
    />
  </svg>
);
const ForwardRef = forwardRef(SvgPci);
export default ForwardRef;
