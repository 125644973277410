import * as React from 'react';
import { SVGProps, Ref, forwardRef } from 'react';
const SvgFolder = (props: SVGProps<SVGSVGElement>, ref: Ref<SVGSVGElement>) => (
  <svg
    viewBox="0 0 16 16"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    ref={ref}
    {...props}
  >
    <path
      d="M.5 1.5a1 1 0 0 1 1-1h4.586a1 1 0 0 1 .707.293l1.414 1.414a1 1 0 0 0 .707.293H14.5a1 1 0 0 1 1 1v10a1 1 0 0 1-1 1h-13a1 1 0 0 1-1-1v-12Z"
      stroke="currentColor"
    />
  </svg>
);
const ForwardRef = forwardRef(SvgFolder);
export default ForwardRef;
