import { buildPath, globalAddinContainer } from '../extensibility/AddinContainer';
import { Addin } from '../extensibility/AddinRegistry';

const PATH_COMMON_FIELD_CONVERTORS = buildPath('common', 'fieldConvertors');

export enum FieldEntityKind {
    ASSET = 'ASSET',
    ISSUE = 'ISSUE',
    FINDING = 'FINDING',
    WAF = 'WAF',
}

export interface IFieldConvertor<T=any> {
    sourceEntityKind: FieldEntityKind;
    targetEntityKind: FieldEntityKind;
    sourceFieldName: string;
    targetFieldName?: string;
    getTargetValue?: (sourceValue: any, sourceEntity?: T) => any;
}

const getFieldConvertorSignature = (sourceEntityKind: FieldEntityKind, targetEntityKind: FieldEntityKind, sourceFieldName: string) => {
    const obj = {
        sourceEntityKind,
        targetEntityKind,
        sourceFieldName,
    };
    return JSON.stringify(obj);
};

export const IGNORE_FIELD = Symbol('IGNORE THIS FIELD');
export const FieldConvertorsRegistry = {
    addFieldConvertors: (fieldConvertors: IFieldConvertor[]) => {
        const addins: Addin<IFieldConvertor>[] = fieldConvertors.map((fieldConvertor: IFieldConvertor) => {
            const id = getFieldConvertorSignature(fieldConvertor.sourceEntityKind, fieldConvertor.targetEntityKind, fieldConvertor.sourceFieldName);
            return {
                id,
                content: fieldConvertor,
            };
        });
        globalAddinContainer.add<IFieldConvertor>(PATH_COMMON_FIELD_CONVERTORS, addins);
    },

    getFieldConvertor: (sourceEntityKind: FieldEntityKind, targetEntityKind: FieldEntityKind, sourceFieldName: string): IFieldConvertor | undefined => {
        const id = getFieldConvertorSignature(sourceEntityKind, targetEntityKind, sourceFieldName);
        return globalAddinContainer.getById<IFieldConvertor>(PATH_COMMON_FIELD_CONVERTORS, id);
    },
};
