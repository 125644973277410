import { FC } from 'react';
import { Label, Stack } from '../../../../../design-system/components-v2';
import { IItem, IItemLabelProps } from '../../CustomForm.interface';
import { LabelColor } from '../../../../../design-system/components-v2/Label/Label.types';

export const FieldLabelComponent: FC<{ item: IItem }> = ({ item: item }) => {
    const { state } = item;
    if (!state.label && !state.tooltip) {
        return null;
    }
    const labelProps: IItemLabelProps = state.labelProps || {};

    return (
        <Stack direction={'row'} alignItems={'center'} spacing={2} >
            <Label {...labelProps}
                required={state.isRequired}
                text={state.label || ''}
                tooltip={state.tooltip}
                tooltipIconSize={12}
                color={state.labelProps?.color as LabelColor}
            />
        </Stack>
    );
};
