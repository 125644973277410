import * as React from 'react';
import { SVGProps, Ref, forwardRef } from 'react';
const SvgHarbor = (props: SVGProps<SVGSVGElement>, ref: Ref<SVGSVGElement>) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    viewBox="0 0 64 64"
    fill="#4a00d8"
    ref={ref}
    {...props}
  >
    <path d="m19.304 38.7 10.8 4.755zm-.924 10.453-.426 4.815a25.93 25.93 0 0 0 13.336 4.101l-.274-3.348zm1.055-11.923 10.528 4.64-.726-8.87h1.378v-2.967h-1.022V23.97h1.022v-1.74l-5.678-5.556v-.4a.536.536 0 0 0-1.072 0v.4l-5.678 5.556v1.74h1.022v6.054h-1.022V33H19.8zm1.418-13.26h7.096v6.054h-1.684v-2.586c0-.666-.355-1.28-.932-1.614a1.86 1.86 0 0 0-1.864 0 1.863 1.863 0 0 0-.932 1.614v2.586h-1.684zm-2.035 20.226 11.758 5.182-.176-2.158-11.405-5.025zm-.13 1.484-.177 2 12.374 5.453-.176-2.157zm11.406-2.215-10.8-4.755-.177 2.002 11.143 4.9zm26.057-24.42 4.425-1.422-.058-.122a32.1 32.1 0 0 0-3.415-5.307l-4.15 2.148a27.44 27.44 0 0 1 3.2 4.703z" />
    <path d="m54.83 19.357.05.097 1.27-.408a27.403 27.403 0 0 0-3.2-4.703l-1.2.62a26.22 26.22 0 0 1 3.078 4.393zm4.514 10.963 4.6.33a31.82 31.82 0 0 0-.546-4.71l-4.652.16a27.38 27.38 0 0 1 .587 4.221z" />
    <path d="M57.426 26.144c.307 1.342.508 2.707.6 4.08l1.32.095a27.38 27.38 0 0 0-.587-4.221zM32 59.393c-.203 0-.404-.01-.606-.015l.176 2.15-13.895-4.415.148-1.67a27.4 27.4 0 0 1 1.501-47.742A27.4 27.4 0 0 1 46.96 9.044l3.23-3.36c-11.536-7.976-26.92-7.5-37.953 1.15S-2.9 30.33 2.1 43.43s17.97 21.4 31.964 20.497a32 32 0 0 0 29.033-24.47l-4.363-1.514C55.955 50.475 44.84 59.4 32 59.393z" />
    <path d="M32 58.097c-.24 0-.475-.012-.712-.018l.106 1.3.606.015c12.84-.003 23.954-8.918 26.744-21.45l-1.234-.428c-2.613 12-13.228 20.565-25.5 20.582zM5.897 31.994A26.1 26.1 0 0 1 19.45 9.1a26.105 26.105 0 0 1 26.6.904l.922-.96A27.4 27.4 0 0 0 4.614 31.135a27.4 27.4 0 0 0 13.209 24.309l.13-1.467A26.1 26.1 0 0 1 5.897 31.994zM17.675 57.114 31.57 61.53l-.176-2.15a27.26 27.26 0 0 1-13.572-3.934z" />
    <path d="M17.823 55.444a27.26 27.26 0 0 0 13.572 3.934l-.106-1.3a25.93 25.93 0 0 1-13.336-4.101zm35.127-41.1 4.15-2.148-.555-.703a32.16 32.16 0 0 0-5.475-5.178l-.88-.63-3.23 3.36a27.793 27.793 0 0 1 6 5.3z" />
    <path d="m30.884 25.763 22.067-11.42a27.33 27.33 0 0 0-6-5.3l-.922.96zM51.75 14.964l1.2-.62zM63.954 30.65l-4.6-.33.056 1.674a27.45 27.45 0 0 1-.655 5.95l4.363 1.514.05-.2A31.96 31.96 0 0 0 64 31.994l-.006-.253-.04-1.1z" />
    <path d="m59.344 30.32-28.46-2.043 27.86 9.666a27.49 27.49 0 0 0 .655-5.95l-.056-1.674zm-.587-4.222 4.652-.16-.288-1.377a31.76 31.76 0 0 0-2.546-6.939l-4.425 1.422a27.21 27.21 0 0 1 2.607 7.052z" />
    <path d="m31.295 27.033 27.462-.935a27.21 27.21 0 0 0-2.607-7.052l-1.27.408z" />
  </svg>
);
const ForwardRef = forwardRef(SvgHarbor);
export default ForwardRef;
