import { forwardRef, useEffect, useState } from 'react';
import { SwitchProps } from './Switch.types';
import SwitchStyles from './Switch.styled';
import Label from '../Label';
import Stack from '../Stack';

const Switch = forwardRef<HTMLInputElement, SwitchProps>((props, ref) => {
    const { checked, onChange, disabled, size = 'small', label, helperText, defaultChecked = false } = props;
    const [innerChecked, setInnerChecked] = useState<boolean>(checked || defaultChecked);
    const [inputFocused, setInputFocused] = useState<boolean>(false);
    
    useEffect(() => {
        if (checked === undefined) return;
        setInnerChecked(checked);
    }, [checked]);

    const innerOnChagned = (event: React.ChangeEvent<HTMLInputElement>) => {
        setInnerChecked(event.target.checked);

        if (onChange) {
            onChange(event);
        }
    };

    return (
        <Stack direction='row' spacing={2} alignItems='center'>
            <SwitchStyles.SwitchContainer
                size={size}
                data-aid={`switch-container-${size}`}
            >
                <SwitchStyles.HiddenInput
                    ref={ref}
                    type='checkbox'
                    checked={innerChecked} 
                    onChange={innerOnChagned} 
                    disabled={disabled}
                    className="visually-hidden"
                    onFocus={() => setInputFocused(true)}
                    onBlur={() => setInputFocused(false)}
                />
                <SwitchStyles.Slider
                    data-aid='switcher-slider'
                    className='switcher-slider'
                    isChecked={innerChecked}
                    isDisabled={disabled}
                    size={size}
                    isInputFocused={inputFocused}
                />
            </SwitchStyles.SwitchContainer>
            {label &&
                <Stack>
                    <Label
                        text={label}
                        disabled={disabled}
                        size={(size === 'medium' || helperText) ? 'lg' : 'md'}
                        color={helperText ? 'strong' : 'light'} />
                    {helperText && <Label text={helperText} color='light' disabled={disabled} />}
                </Stack>
            }
        </Stack>
    );
});

Switch.displayName = 'Switch';

export default Switch;