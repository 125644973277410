import React from 'react';
import { ColumnApi, GridApi } from 'ag-grid-community';
import { Button, ComponentOverflow, Dropdown, Stack } from 'common/design-system/components-v2';
import { ITableAction } from '../../Table.types';
import { IButtonProps } from '../../../Button/Button.types';
import ActionsStyles from './Actions.styles';

export interface ActionsProps {
    gridApi: GridApi | undefined;
    columnApi: ColumnApi | undefined;
    selectedRows: any[];
    actions: ITableAction[];
}

const Actions: React.FC<ActionsProps> = props => {
    const { selectedRows, gridApi, columnApi, actions } = props;

    const [overflowCount, setOverflowCount] = React.useState<number>(0);
    const [isDropdownOpen, setIsDropdownOpen] = React.useState<boolean>(false);

    const filteredActions = React.useMemo(() => {
        return actions.filter(action => {
            if (action.isActionHidden) {
                return !action.isActionHidden(selectedRows);
            }
            return true;
        });
    }, [actions, selectedRows]);

    const actionsForOverflowDropdown = React.useMemo<ITableAction[]>(() => {
        return filteredActions.slice((filteredActions.length) - overflowCount);
    }, [filteredActions, overflowCount]);

    const generateButtons = React.useCallback((relevantActions: ITableAction[], fullWidth?: boolean) => {
        if (!gridApi || !columnApi) return [];
        return relevantActions.map(action => {
            let buttonProps: Partial<IButtonProps> = {};
            if (typeof action.buttonProps === 'function') {
                buttonProps = action.buttonProps(selectedRows);
            } else if (action.buttonProps) {
                buttonProps = action.buttonProps;
            }
            if (action.customComponent) {
                return action.customComponent(selectedRows);
            }
            return (
                <Button
                    data-aid={action.id}
                    variant='text'
                    key={action.id}
                    label={action.name}
                    disabled={action.isActionDisabled ? action.isActionDisabled(selectedRows) : selectedRows.length === 0}
                    onClick={() => {
                        setIsDropdownOpen(false);
                        action.callback(selectedRows, { gridApi, columnApi });
                    }}
                    {...buttonProps || {}}
                    buttonAlignment='flex-start'
                    fullWidth={fullWidth}
                />
            );
        });
    }, [selectedRows, gridApi, columnApi]);

    return (
        <ActionsStyles.Wrapper spacing={overflowCount === filteredActions.length ? 0 : 2} direction='row' alignItems='center' fullWidth>
            {filteredActions.length > 0 && (
                <ComponentOverflow
                    hideOverflowCount
                    onOverflowCountChange={setOverflowCount}
                    components={generateButtons(filteredActions)}
                    ellipsisMinWidth={80}
                />
            )}
            {(overflowCount > 0) && (
                <Dropdown
                    open={isDropdownOpen}
                    onStateChange={(state) => setIsDropdownOpen(state)}
                    buttonProps={{
                        iconButton: true,
                        iconProps: { name: 'more' },
                    }}
                    maxHeight={500}
                >
                    <Stack spacing={0.5} direction='column'>
                        {generateButtons(actionsForOverflowDropdown, true)}
                    </Stack>
                </Dropdown>
            )}
        </ActionsStyles.Wrapper>
    );
};

export default Actions;
