import * as React from 'react';
import { SVGProps, Ref, forwardRef } from 'react';
const SvgThirdParty = (
  props: SVGProps<SVGSVGElement>,
  ref: Ref<SVGSVGElement>
) => (
  <svg
    viewBox="0 0 16 16"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    ref={ref}
    {...props}
  >
    <circle cx={13} cy={10.5} r={1.5} stroke="currentColor" />
    <circle cx={2} cy={10.5} r={1.5} stroke="currentColor" />
    <path
      d="M7.5 8.5v2M5.5 10.5h4"
      stroke="currentColor"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="m7.5 2 2 1v2.5l-2 1-2-1V3l2-1Z"
      stroke="currentColor"
      strokeLinejoin="round"
    />
  </svg>
);
const ForwardRef = forwardRef(SvgThirdParty);
export default ForwardRef;
