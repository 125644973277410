import { SVGProps } from 'react';
import { ColorsWithState } from '../../theme/theme';
import { AllIcons as systemIcon } from '../../icons/SvgComponents/system-icons';
import { AllIcons as logos } from '../../icons/SvgComponents/logos';
import { IIconProps as VendorIconPropsFromLib } from '@dome9/berries/react-components';
import { Context } from 'common/design-system/theme/colors/colors.types';

export type IconSizes = 6 | 8 | 10 | 12 | 14 | 16 | 20 | 24 | 32 | 48 | 64 | 96 | 128 | 192;

export const allIcons = {
    ...systemIcon,
    ...logos
};

export type AllIconsName = keyof typeof allIcons;

export interface IconComponentProps extends Omit<SVGProps<SVGElement>, 'ref'> {
    name: AllIconsName;
    size?: IconSizes,
    color?: ColorsWithState;
    customColor?: string;
    context?: Context;
    saturation?: boolean;
}

export interface VendorIconProps extends Omit<VendorIconPropsFromLib, 'name'> {
    vendorNameOrPath: string; // vendor icon name or image path
}

export type IconProps<T extends IconComponentProps | VendorIconProps = any> = T extends { vendorNameOrPath: string } ? VendorIconProps & { name?: never } : IconComponentProps;
