import * as React from 'react';
import { SVGProps, Ref, forwardRef } from 'react';
const SvgChevronUp = (
  props: SVGProps<SVGSVGElement>,
  ref: Ref<SVGSVGElement>
) => (
  <svg
    viewBox="0 0 16 16"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    ref={ref}
    {...props}
  >
    <path
      d="M14 11 8.707 5.707a1 1 0 0 0-1.414 0L2 11"
      stroke="currentColor"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </svg>
);
const ForwardRef = forwardRef(SvgChevronUp);
export default ForwardRef;
