import styled, { DefaultTheme, css } from 'styled-components';
import { getTextContrastColor, ColorsWithState } from '../../theme/theme';
import Typography from '../Typography';
import { ButtonSize, ButtonVariants } from './Button.types';

const resolvePadding = (theme: DefaultTheme, size: ButtonSize, iconButton: boolean, removePadding?: boolean) => {
    if (removePadding) return 0;
    if (iconButton) {
        switch (size) {
            case 'small':
                return `${theme.spacing(0.5)}${theme.units}`;
            case 'medium':
                return `${theme.spacing(2) - 1}${theme.units}`;
        }
    }

    switch (size) {
        case 'small':
            return `${theme.spacing(1) - 1}${theme.units}`;
        case 'medium':
            return `${theme.spacing(2) - 1}${theme.units} ${theme.spacing(4) - 1}${theme.units}`;
        case 'large':
            return `${theme.spacing(4) - 1}${theme.units}`;
    }
};

interface IWrapperProps {
  isDisabled?: boolean;
  variant: ButtonVariants;
  color: ColorsWithState;
  size: ButtonSize;
  iconButton: boolean;
  active?: boolean;
  disableHoverEffect?: boolean;
  fullWidth?: boolean;
  removePadding?: boolean;
}
const Wrapper = styled.button<IWrapperProps>`
  width: 100%;
  max-width: ${({ fullWidth }) => fullWidth ? '100%' : 'fit-content'};
  padding: ${({ theme, size, iconButton, removePadding }) => resolvePadding(theme, size, iconButton, removePadding)};
  gap: ${({ theme }) => `${theme.spacing(2)}${theme.units}`};
  border-radius: ${({ theme }) => `${theme.border.radius(2)}${theme.units}`};
  border: ${({ theme, color }) => `${theme.border.width.standard}${theme.units} solid ${theme.palette.surface[color]}`};
  outline: none;

  & svg {
    color: currentColor
  }

  ${({ theme, variant, color, isDisabled, active }) => variant === 'contained' && css`
    background: ${theme.palette.surface[color]};
    color: ${getTextContrastColor(theme.palette.surface[color], theme)};

    ${!isDisabled && css`
      &:hover {
        background: ${theme.palette.surfaceStates[`${color}Hover`]};
        color: ${getTextContrastColor(theme.palette.surfaceStates[`${color}Hover`], theme)};
        border-color: ${theme.palette.surfaceStates[`${color}Hover`]};
      }
      &&:focus-visible {
        background: ${theme.palette.surfaceStates[`${color}Focus`]};
        color: ${getTextContrastColor(theme.palette.surfaceStates[`${color}Focus`], theme)};
        border-color: ${theme.palette.onSurface.reverse};
        box-shadow: ${theme.shadows.focus};
      }
      &&:active {
        background: ${theme.palette.surfaceStates[`${color}Active`]};
        color: ${getTextContrastColor(theme.palette.surfaceStates[`${color}Active`], theme)};
        border-color: ${theme.palette.surfaceStates[`${color}Active`]};
      }

      ${active && css`
        background: ${theme.palette.surfaceStates[`${color}Active`]};
        color: ${getTextContrastColor(theme.palette.surfaceStates[`${color}Active`], theme)};
        border-color: ${theme.palette.surfaceStates[`${color}Active`]};
      `}
    `}
  `}

  ${({ theme, variant, color, isDisabled, active }) => variant === 'outlined' && css`
    background: transparent;
    border-color: ${theme.palette.onSurface[color]};
    color: ${theme.palette.onSurface[color]};


    ${!isDisabled && css`
      &:hover {
        background: ${theme.palette.componentStates.ghostHover};
        border-color: ${theme.palette.surfaceStates[`${color}Hover`]};
      }
      &&:focus-visible {
        background: ${theme.palette.componentStates.ghostFocus};
        border-color: ${theme.palette.onSurface.reverse};
        box-shadow: ${theme.shadows.focus};
      }
      &&:active {
        background: ${theme.palette.componentStates.ghostActive};
        border-color: ${theme.palette.surfaceStates[`${color}Active`]};
      }

      ${active && css`
        background: ${theme.palette.componentStates.ghostActive};
        border-color: ${theme.palette.surfaceStates[`${color}Active`]};
        &:hover {
          background: ${theme.palette.componentStates.ghostDoubleActive};
        }
      `}
    `}
  `}

  ${({ theme, variant, color, isDisabled, active }) => variant === 'text' && css`
    background: transparent;
    border-color: transparent;
    color: ${theme.palette.onSurface[color]};

    ${!isDisabled && css`
      &:hover {
        background: ${theme.palette.surfaceStates.primaryHover};
      }
      &&:focus-visible {
        background: ${theme.palette.componentStates.ghostFocus};
        box-shadow: ${theme.shadows.focus};
      }
      &&:active {
        background: ${theme.palette.componentStates.ghostActive};
      }

      ${active && css`
        background: ${theme.palette.componentStates.ghostActive};
        &:hover {
          background: ${theme.palette.componentStates.ghostDoubleActive};
        }
      `}
    `}
  `}

  ${({ theme, isDisabled }) => isDisabled && css`
    box-shadow: ${theme.shadows[0]};
    opacity: ${theme.disabledOpacity};
    cursor: not-allowed;
  `}

  ${({ disableHoverEffect }) => disableHoverEffect && css`
    &:hover {
      background: transparent;
    }
  `}
`;

const Label = styled(Typography)`
  text-overflow: ellipsis;
  overflow: hidden;
  white-space: nowrap;
`;

const ButtonStyles = {
    Wrapper,
    Label
};

export default ButtonStyles;
