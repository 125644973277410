import { getIsCloudInfra } from '../../../utils/RuntimeEnvironment';

export const DOC_PLACEHOLDER_PART = '___placeholder___';
export const DOC_PORTAL_PART = 'Infinity_Portal/WebAdminGuides/EN/CloudGuard-PM-Admin-Guide';
export const DOC_STANDALONE_PART = 'CloudGuard_Dome9';

export const getDocLink = (genericUrl: string): string => {
    const replacementPart = getIsCloudInfra() ? DOC_PORTAL_PART : DOC_STANDALONE_PART;
    return genericUrl.replace(DOC_PLACEHOLDER_PART, replacementPart);
};
