import { globalAddinContainer } from 'common/extensibility/AddinContainer';
import { CGColDef } from 'common/components/ProtectedAssets/ProtectedAssetsTable.interface';
import { Addin } from 'common/extensibility/AddinRegistry';
import { IFilterProps } from 'common/components/FilterPanel/FilterPanelManager';
import { IProtectedAssetFilterParams } from '../assets/ProtectedAssets';
import { REPORTS_TABLE_ACTION_DEFS, REPORTS_TABLE_COLUMN_DEFS, REPORTS_TABLE_FILTER_DEFS } from './Reports.const';
import { ITableAction } from 'common/design-system/components-v2/Table/Table.types';

export const ReportsTableRegistry = {
    addColumnDefs: (columnDefs: CGColDef[], idProperty: string) => {
        globalAddinContainer.addMap<CGColDef>(REPORTS_TABLE_COLUMN_DEFS, columnDefs, idProperty);
    },

    getColumnDefs: (): CGColDef[] => {
        return globalAddinContainer.get<CGColDef>(REPORTS_TABLE_COLUMN_DEFS);
    },

    addFilterDefs: (filterAddins: Addin<IFilterProps>[]) => {
        globalAddinContainer.add<IFilterProps>(REPORTS_TABLE_FILTER_DEFS, filterAddins);
    },

    getFilterById: (filterId: string, filterParams?: IProtectedAssetFilterParams[]): IFilterProps | undefined => {
        return globalAddinContainer.getById<IFilterProps>(REPORTS_TABLE_FILTER_DEFS, filterId, filterParams);
    },

    getFilterDefs: (): IFilterProps[] => {
        return globalAddinContainer.get<IFilterProps>(REPORTS_TABLE_FILTER_DEFS, []);
    },

    addActionDefs: (actionDefs: ITableAction[], idProperty: string) => {
        globalAddinContainer.addMap(REPORTS_TABLE_ACTION_DEFS, actionDefs, idProperty);
    },

    getActionDefs: (): ITableAction[] => {
        return globalAddinContainer.get<ITableAction>(REPORTS_TABLE_ACTION_DEFS);
    },
};
