import { FC } from 'react';
import { FormStyled } from '../../CustomForm.styled';
import Select from '../../../../../design-system/components-v2/SelectV2';
import { FieldLabelComponent } from '../helperComponents/FieldLabelComponent';
import { getItemSelectPlaceholder } from '../../CustomForm.values';
import {
    IFullErrorInfo,
    IOnFieldChanged,
    ISingleSelectItem,
} from '../../CustomForm.interface';

export interface ISingleSelectComponentProps {
    item: ISingleSelectItem;
    onFieldChanged: IOnFieldChanged;
    showErrors: boolean;
    errorInfo?: IFullErrorInfo;
    skipTitle?: boolean;
    isInner?: boolean;
}

export const SingleSelectItemComponent: FC<ISingleSelectComponentProps> = ({ item, onFieldChanged, showErrors, errorInfo, skipTitle, isInner }) => {
    const { state } = item;
    return (
        <FormStyled.TopItem widthInfo={state.widthInfo} isInner={isInner}>
            {!skipTitle && <FieldLabelComponent item={item} />}
            <Select
                value={state?.value}
                onChange={value => onFieldChanged({ ...item, state: { ...state, value: value } })}
                placeholder={getItemSelectPlaceholder(item)}
                options={state.options || []}
                fullWidth
                clearable
                isMulti={false}
                isError={!!(showErrors && errorInfo)}
                helperText={(showErrors && errorInfo) ? errorInfo.errorMessage: ''}
                disabled={state.disabled || state.readonly}
            />
        </FormStyled.TopItem>
    );
};