import React, { Fragment } from 'react';
import { ModalHeaderProps } from './ModalHeader.types';
import Button from '../../Button';
import Typography from '../../Typography';
import Stack from '../../Stack';
import StatusIcon, { getColor, getVariant } from '../StatusIcon';
import { Separator } from '../../Separator';
import { TextContainer, SubTitle } from './ModalHeader.styles';

const CloseButton: React.FC<Pick<ModalHeaderProps, 'onClose' | 'dataAid'>> = ({ onClose, dataAid }) => {
    return (
        <Button
            iconProps={{ name: 'remove', size: 14 }}
            variant='text'
            size='small'
            onClick={onClose}
            dataAid={dataAid ? `${dataAid}-modal-header-close` : 'modal-close-button'}
            id='modal-close-button'
        />
    );
};

const ModalHeader: React.FC<ModalHeaderProps> = ({ actions = [], onClose, title, subTitle, dataAid, icon, hasSeparator = true }) => {
    return (
        <Stack direction='column' alignItems='flex-end'>
            <Stack direction='row' justifyContent='flex-end' padding={[2, 2, 0, 0]}>
                {actions.map((action, index) => <Fragment key={`action-${index}`}>{action}</Fragment>)}
                {onClose && <CloseButton dataAid={dataAid} onClose={onClose} />}
            </Stack>
            <Stack fullWidth direction='row' padding={[0, 5]} alignItems='flex-start' spacing={4}>
                {icon && <StatusIcon variant={getVariant(icon)} color={getColor(icon)} />}
                <TextContainer spacing={1} direction='column' alignItems='flex-start'>
                    <Typography variant='h2'>{title}</Typography>
                    {subTitle && Array.isArray(subTitle)
                        ? (
                            <Stack spacing={1}>
                                {subTitle.map((subT, index) => (
                                    <SubTitle key={`subtitle-${index}`} title={subT} variant='body' color='light'>{subT}</SubTitle>
                                ))}
                            </Stack>)
                        : <SubTitle title={subTitle} variant='body' color='light'>{subTitle}</SubTitle>
                    }
                </TextContainer>
            </Stack>
            {hasSeparator && (
                <Stack fullWidth padding={[6, 0, 0, 0]}>
                    <Separator />
                </Stack>
            )}
        </Stack>
    );
};

export default ModalHeader;
