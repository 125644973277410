import React, { ReactElement } from 'react';
import { PropStyled } from './PropertyBox.styled';

const PropertyBox: React.FC<{ title: string, content: ReactElement }> = ({ title, content }) => {
    return (
        <PropStyled.TopDiv>
            <PropStyled.TitleDiv>{title}</PropStyled.TitleDiv>
            <PropStyled.ContentDiv>{content}</PropStyled.ContentDiv>
        </PropStyled.TopDiv>
    );
};

export default PropertyBox;
