import { AxiosResponse } from 'axios';
import { getHttpService } from 'common/interface/services';
import {
    FindingsReportExportToCsv,
    FindingsReportExportToCsvDownloadCSV,
    FindingsReportExportToCsvDownloadLink,
} from './findingsReport.interface';

const apiBaseUrl = 'findingsReport';

const exportToCsv: FindingsReportExportToCsv.Function = async (request) => {
    return await getHttpService().request<AxiosResponse<FindingsReportExportToCsv.Response>>(
        `${apiBaseUrl}/exportToCsv`,
        { method: 'POST', data: request, },
        { returnAsAxiosResponse: true },
        err => { throw err; }
    );
};

const downloadLink: FindingsReportExportToCsvDownloadLink.Function = async (request) => {
    return await getHttpService().request<AxiosResponse<FindingsReportExportToCsvDownloadLink.Response>>(
        `${apiBaseUrl}/exportToCsv/downloadLink`,
        { method: 'POST', data: request, },
        { returnAsAxiosResponse: true },
        err => { throw err; }
    );
};

const downloadCSV: FindingsReportExportToCsvDownloadCSV.Function = async (request) => {
    return await getHttpService().request<AxiosResponse<FindingsReportExportToCsvDownloadCSV.Response>>(
        `${apiBaseUrl}/exportToCsv/downloadCSV`,
        { method: 'POST', data: request, responseType: 'blob' },
        { returnAsAxiosResponse: true },
        err => { throw err; }
    );
};

const FindingsReportApi = {
    exportToCsv,
    downloadLink,
    downloadCSV,
};

export default FindingsReportApi;
