import { ICloudAccount, IOrganizationalUnit } from 'common/interface/data_services';
import { Aggregations } from 'common/components/FilterPanel/FilterPanel.interface';
import React from 'react';
import { ColDef, ColumnApi, GridApi, ICellRendererParams, IServerSideDatasource } from 'ag-grid-community';
import { ITableAction } from 'common/design-system/components-v2/Table/Table.types';
import { IButtonProps } from 'common/design-system/components-v2/Button/Button.types';
import { buildPath } from 'common/extensibility/AddinContainer';
import { GenericObject } from 'common/interface/general';
import { ICustomzationResponse } from 'common/interface/services';
import { IEvent } from 'common/module_interface/events/Events';
import { FindingsDataSource, ISearchFilterViewModel } from 'common/components/Findings/Findings.interface';

export const newEventsPageFeatureFlagName = 'react-events-page-view';
export const newEventsPageCurrentView = 'events-page-view'; // localStorage key

const PATH_EVENTS_TABLE_PREFIX = 'events-table';
const PATH_EVENTS_DRAWER_PREFIX = 'events-drawer';
export const PATH_EVENTS_MODALS_PREFIX = 'events-modals';
export const PATH_EVENTS_TABLE_PAGE = buildPath(PATH_EVENTS_TABLE_PREFIX, 'page');
export const PATH_EVENTS_TABLE_PAGE_TABLE_COLUMNS = buildPath(PATH_EVENTS_TABLE_PAGE, 'columns');
export const PATH_EVENTS_TABLE_PAGE_TABLE_FILTERS = buildPath(PATH_EVENTS_TABLE_PAGE, 'filters');
export const PATH_EVENTS_TABLE_PAGE_TABLE_ACTIONS = buildPath(PATH_EVENTS_TABLE_PAGE, 'actions');
export const PATH_EVENTS_TABLE_PAGE_TABLE_EXPORTS = buildPath(PATH_EVENTS_TABLE_PAGE, 'exports');
export const PATH_EVENTS_TABLE_PAGE_TABLE_TABS = buildPath(PATH_EVENTS_TABLE_PAGE, 'tabs');
export const PATH_EVENTS_TABLE_PAGE_TABLE_CELL_RENDERERS = buildPath(PATH_EVENTS_TABLE_PAGE, 'renderers');
export const PATH_EVENTS_TABLE_FILTERS_DEFINITIONS = buildPath(PATH_EVENTS_TABLE_PREFIX, 'filters_definitions');

export const PATH_EVENTS_DRAWER_DETAILS_PANEL = buildPath(PATH_EVENTS_DRAWER_PREFIX, 'details-panel');
export const PATH_EVENTS_DRAWER_ACTIONS = buildPath(PATH_EVENTS_DRAWER_PREFIX, 'actions');
export const PATH_EVENTS_DRAWER_CHIPS = buildPath(PATH_EVENTS_DRAWER_PREFIX, 'chips');
export const PATH_EVENTS_DRAWER_TABS = buildPath(PATH_EVENTS_DRAWER_PREFIX, 'tabs');
export const PATH_EVENTS_DRAWER_SECTIONS = buildPath(PATH_EVENTS_DRAWER_PREFIX, 'sections');

export interface IEventTableCellRendererAddinContent {
    renderer: React.FC<ICellRendererParams>;
    params: GenericObject<any>;
}

export interface IEventTableFilter {
    id: string;
    filterProps?: GenericObject<any>;
}

export interface IEventTableAction extends ITableAction {}

export interface IEventTableActionKey {
    id: string;
}

export interface IEventTableExport {
    id: string;
    name: string;
    icon?: IButtonProps['iconProps'];
    tooltip?: (gridApi: GridApi<IEvent>, columnApi: ColumnApi, dataSource: IEventsDatasource) => IButtonProps['tooltip'];
    callback: (gridApi: GridApi<IEvent>, columnApi: ColumnApi, dataSource: IEventsDatasource) => void | Promise<void>;
    isActionDisabled?: (gridApi: GridApi<IEvent>, columnApi: ColumnApi, dataSource: IEventsDatasource) => boolean;
}

export interface IFiltersInitialData {
    recentlyUsed?: ICustomzationResponse<any>;
    savedFilters?: ICustomzationResponse<any>;
    organizationalUnits?: IOrganizationalUnit;
    allCloudAccounts?: ICloudAccount[];
}

export interface IEventTableFilterParams {
    aggregations: Aggregations;
    filtersInitialData?: IFiltersInitialData;
    savedFiltersComponentName: string;
    recentlyUsedComponentName: string;
}

export interface EventsTableColDef<T = any> extends ColDef<T> {
    permission?: () => boolean;
    position?: number;
    id?: string;
    sortField?: string;
}

export interface Filters {
    fields?: { value: string; name: string }[];
    freeTextPhrase?: string;
}

export interface IEventsDatasource extends IServerSideDatasource {
    isFeching: boolean;
    isLoading: boolean;
    isError: boolean;
    rowCount: number;
    totalRowCount: number;
    initialParams?: IEventsElasticDatasourceParams;
    filters?: ISearchFilterViewModel;

    getAggregations: () => Promise<Aggregations>;
    updateFilters: (filters?: ISearchFilterViewModel) => void;
    downloadCSV: (selectedColumns?: string[]) => Promise<boolean>;
    emailCSV: (recipents: string[], selectedColumns?: string[]) => Promise<boolean>;

    subscribeToStateChange: (callback: (state: IEventsDatasource) => void) => string;
    unsubscribeFromStateChange: (key: string) => void;
}

export interface IEventsElasticDatasourceParams {
    id: string;
    findingsDataSource: FindingsDataSource;
    defaultFilters?: ISearchFilterViewModel;
}

export interface EventsTableTab {
    type: 'datasourceTab';
    id: string;
    label: string;
    columns: { id: string; overrides?: Partial<EventsTableColDef<IEvent>> }[];
    filters: string[];
    actions: string[];
    archiveActions: string[];
    exportOptions?: string[];
    createDataSource: (archiveView: boolean) => IEventsDatasource;
}

export interface EventsTableTabComponentProps {
    archiveView: boolean;
}
export interface EventsTableTabComponent {
    type: 'componentTab';
    id: string;
    label: string;
    component: React.FC<EventsTableTabComponentProps>;
}
