import { getRecipeFromStates, getSafeName } from './RecipeEditor.values';
import { getRecipeExampleService } from '../RecipeExampleCenter/RecipeExampleService';
import { IRecipe, IRecipeProps } from '../RecipeExampleCenter/RecipeExample.interface';
import { IItemsMap } from '../../custom/CustomForm/CustomForm.interface';

export const saveRecipe = async (itemsMap: IItemsMap, origRecipe?: IRecipeProps): Promise<void> => {
    const recipe: IRecipeProps = getRecipeFromStates(itemsMap);
    if (origRecipe?.id) {
        await getRecipeExampleService().updateRecipe(origRecipe.id, recipe);
    } else {
        await getRecipeExampleService().createRecipe(recipe);
    }
};

export const getRecipeSubmitSuccessNotification = (itemsMap: IItemsMap, recipe?: IRecipeProps): string => {
    const name = getSafeName(itemsMap);
    if (recipe?.id) {
        return `Recipe ${name} was updated successfully`;
    } else {
        return `Recipe ${name} was added successfully`;
    }
};

export const getRecipeSubmitFailedNotification = (itemsMap: IItemsMap, error: string, recipe?: IRecipeProps): string => {
    const name = getSafeName(itemsMap);
    if (recipe?.id) {
        return `Failed updating recipe ${name} with error: ${error}`;
    } else {
        return `Failed adding recipe ${name} with error: ${error}`;
    }
};

export const getOtherRecipeNames = async (recipeProps?: IRecipeProps): Promise<string[]> => {
    const allRecipes: IRecipe[] = await getRecipeExampleService().getAllRecipes();
    const otherRecipes: IRecipe[] = recipeProps?.id ? allRecipes.filter(recipe => recipe.id !== recipeProps.id) : allRecipes;
    return otherRecipes.map(recipe => recipe.name);
};

