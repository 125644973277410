import { buildPath, globalAddinContainer } from '../../../extensibility/AddinContainer';
import { IDrawerContentProvider } from './Drawer.interface';
import { Addin } from '../../../extensibility/AddinRegistry';

const DRAWER_CONTENT_WIDGETS_PATH = buildPath('drawer', 'widgets');
export const DrawerRegistry = {
    addContentProvider: (contentProvider: IDrawerContentProvider) => {
        const addin: Addin<IDrawerContentProvider> = {
            id: contentProvider.id,
            content: contentProvider,
        };
        globalAddinContainer.add<IDrawerContentProvider>(DRAWER_CONTENT_WIDGETS_PATH, [addin]);
    },

    getContentProvider: (id: string): IDrawerContentProvider | undefined => {
        return globalAddinContainer.getById<IDrawerContentProvider>(DRAWER_CONTENT_WIDGETS_PATH, id);
    },
};
