import { Width } from '../../../design-system/components-v2/Modal';
import React from 'react';

export type IDrawerIcon = string | JSX.Element;

export interface IDrawerOptions{
    hasHeaderLineSeparator?: boolean;
    disableSpacing?: boolean;
    width?: Width;
    suppressCloseOnEscape?: boolean;
    onCloseHandlerId?: string;
}
export interface IDrawerContent {
    component: React.FC<any>;
    componentProps?: any,
    title?: string;
    icon?: IDrawerIcon,
    rightHeaderContent?: React.ReactNode;
}

export enum DrawerState {
    VISIBLE_LOADING = 'visibleLoading',
    VISIBLE_READY = 'visibleReady',
    HIDDEN = 'hidden',
}

export type IDrawerHandlersIdMap = { [key: string]: string };

export interface IDrawerWidgetUrlParams {
    widgetId: string;
    widgetData?: string;
    handlersIdMap?: IDrawerHandlersIdMap;
}

export interface IDrawerContentProvider {
    id: string;
    getDrawerContent: (baseComponentData?: any, handlersIdMap?: IDrawerHandlersIdMap) => Promise<IDrawerContent | undefined>;
    options?: IDrawerOptions;
}
