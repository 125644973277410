import { buildPath } from '../../../extensibility/AddinContainer';

export const PATH_SETTINGS_PREFIX = buildPath('settings', 'integrations');
export const NEW_NOTIFICATIONS_PAGE_AF = 'new-integrations';

export interface IConfiguration {
    name: string,
    id?: string,
    configurationObj: any
}

export interface IGetAllConfigurationsModel {
    [key: string]: IConfiguration[]
}
