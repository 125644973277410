import Ciem from './Ciem';
export { default as Ciem } from './Ciem';
import InterfaceFolderAdd from './InterfaceFolderAdd';
export { default as InterfaceFolderAdd } from './InterfaceFolderAdd';
import Xls from './Xls';
export { default as Xls } from './Xls';
import AccessControl from './AccessControl';
export { default as AccessControl } from './AccessControl';
import Acknowledge from './Acknowledge';
export { default as Acknowledge } from './Acknowledge';
import AddSection from './AddSection';
export { default as AddSection } from './AddSection';
import AdmissionControl from './AdmissionControl';
export { default as AdmissionControl } from './AdmissionControl';
import Archive from './Archive';
export { default as Archive } from './Archive';
import ArrowDownRight from './ArrowDownRight';
export { default as ArrowDownRight } from './ArrowDownRight';
import ArrowDown from './ArrowDown';
export { default as ArrowDown } from './ArrowDown';
import ArrowLeft from './ArrowLeft';
export { default as ArrowLeft } from './ArrowLeft';
import ArrowRight from './ArrowRight';
export { default as ArrowRight } from './ArrowRight';
import ArrowUpRight from './ArrowUpRight';
export { default as ArrowUpRight } from './ArrowUpRight';
import ArrowUp from './ArrowUp';
export { default as ArrowUp } from './ArrowUp';
import Assessment from './Assessment';
export { default as Assessment } from './Assessment';
import AssetCrownJewel from './AssetCrownJewel';
export { default as AssetCrownJewel } from './AssetCrownJewel';
import Assets from './Assets';
export { default as Assets } from './Assets';
import Assign from './Assign';
export { default as Assign } from './Assign';
import Asterisk from './Asterisk';
export { default as Asterisk } from './Asterisk';
import Attachment from './Attachment';
export { default as Attachment } from './Attachment';
import AutomatedAction from './AutomatedAction';
export { default as AutomatedAction } from './AutomatedAction';
import Bell from './Bell';
export { default as Bell } from './Bell';
import Board from './Board';
export { default as Board } from './Board';
import Book from './Book';
export { default as Book } from './Book';
import Business from './Business';
export { default as Business } from './Business';
import Calendar from './Calendar';
export { default as Calendar } from './Calendar';
import Card from './Card';
export { default as Card } from './Card';
import Check from './Check';
export { default as Check } from './Check';
import CheckCircle from './CheckCircle';
export { default as CheckCircle } from './CheckCircle';
import ChevronDown from './ChevronDown';
export { default as ChevronDown } from './ChevronDown';
import ChevronLeft from './ChevronLeft';
export { default as ChevronLeft } from './ChevronLeft';
import ChevronRight from './ChevronRight';
export { default as ChevronRight } from './ChevronRight';
import ChevronUp from './ChevronUp';
export { default as ChevronUp } from './ChevronUp';
import ClearAll from './ClearAll';
export { default as ClearAll } from './ClearAll';
import Clock from './Clock';
export { default as Clock } from './Clock';
import Cloud from './Cloud';
export { default as Cloud } from './Cloud';
import CloudFunction from './CloudFunction';
export { default as CloudFunction } from './CloudFunction';
import Cluster from './Cluster';
export { default as Cluster } from './Cluster';
import Columns from './Columns';
export { default as Columns } from './Columns';
import Comments from './Comments';
export { default as Comments } from './Comments';
import Compute from './Compute';
export { default as Compute } from './Compute';
import Configuration from './Configuration';
export { default as Configuration } from './Configuration';
import ConnectedStatus from './ConnectedStatus';
export { default as ConnectedStatus } from './ConnectedStatus';
import ContainerImage from './ContainerImage';
export { default as ContainerImage } from './ContainerImage';
import ContainersRegistry from './ContainersRegistry';
export { default as ContainersRegistry } from './ContainersRegistry';
import Copy from './Copy';
export { default as Copy } from './Copy';
import Cve from './Cve';
export { default as Cve } from './Cve';
import CveText from './CveText';
export { default as CveText } from './CveText';
import DataSensitivity from './DataSensitivity';
export { default as DataSensitivity } from './DataSensitivity';
import Database from './Database';
export { default as Database } from './Database';
import Datalake from './Datalake';
export { default as Datalake } from './Datalake';
import Delete from './Delete';
export { default as Delete } from './Delete';
import Disable from './Disable';
export { default as Disable } from './Disable';
import Document from './Document';
export { default as Document } from './Document';
import DoubleCheck from './DoubleCheck';
export { default as DoubleCheck } from './DoubleCheck';
import Download from './Download';
export { default as Download } from './Download';
import Drag from './Drag';
export { default as Drag } from './Drag';
import Duplicate from './Duplicate';
export { default as Duplicate } from './Duplicate';
import Edit from './Edit';
export { default as Edit } from './Edit';
import EmailAttachment from './EmailAttachment';
export { default as EmailAttachment } from './EmailAttachment';
import Email from './Email';
export { default as Email } from './Email';
import Error from './Error';
export { default as Error } from './Error';
import Events from './Events';
export { default as Events } from './Events';
import Exclude from './Exclude';
export { default as Exclude } from './Exclude';
import Expand from './Expand';
export { default as Expand } from './Expand';
import Export from './Export';
export { default as Export } from './Export';
import ExternalLinkInline from './ExternalLinkInline';
export { default as ExternalLinkInline } from './ExternalLinkInline';
import ExternalLink from './ExternalLink';
export { default as ExternalLink } from './ExternalLink';
import Eye from './Eye';
export { default as Eye } from './Eye';
import EyeOff from './EyeOff';
export { default as EyeOff } from './EyeOff';
import Favorite from './Favorite';
export { default as Favorite } from './Favorite';
import File from './File';
export { default as File } from './File';
import Filter from './Filter';
export { default as Filter } from './Filter';
import Find from './Find';
export { default as Find } from './Find';
import Folder from './Folder';
export { default as Folder } from './Folder';
import GenericImage from './GenericImage';
export { default as GenericImage } from './GenericImage';
import GroupColumns from './GroupColumns';
export { default as GroupColumns } from './GroupColumns';
import GroupExclusion from './GroupExclusion';
export { default as GroupExclusion } from './GroupExclusion';
import Help from './Help';
export { default as Help } from './Help';
import History from './History';
export { default as History } from './History';
import IamImpact from './IamImpact';
export { default as IamImpact } from './IamImpact';
import IdentityCard from './IdentityCard';
export { default as IdentityCard } from './IdentityCard';
import ImageProtection from './ImageProtection';
export { default as ImageProtection } from './ImageProtection';
import Import from './Import';
export { default as Import } from './Import';
import Inactive from './Inactive';
export { default as Inactive } from './Inactive';
import Info from './Info';
export { default as Info } from './Info';
import Installation from './Installation';
export { default as Installation } from './Installation';
import Issue from './Issue';
export { default as Issue } from './Issue';
import Key from './Key';
export { default as Key } from './Key';
import Link from './Link';
export { default as Link } from './Link';
import ListCheck from './ListCheck';
export { default as ListCheck } from './ListCheck';
import ListView from './ListView';
export { default as ListView } from './ListView';
import Location from './Location';
export { default as Location } from './Location';
import Log from './Log';
export { default as Log } from './Log';
import Magnify from './Magnify';
export { default as Magnify } from './Magnify';
import Malicious from './Malicious';
export { default as Malicious } from './Malicious';
import ManyKeys from './ManyKeys';
export { default as ManyKeys } from './ManyKeys';
import Microscope from './Microscope';
export { default as Microscope } from './Microscope';
import Minus from './Minus';
export { default as Minus } from './Minus';
import Monitor from './Monitor';
export { default as Monitor } from './Monitor';
import More from './More';
export { default as More } from './More';
import MoreHorizontal from './MoreHorizontal';
export { default as MoreHorizontal } from './MoreHorizontal';
import Network from './Network';
export { default as Network } from './Network';
import NoResults from './NoResults';
export { default as NoResults } from './NoResults';
import NotConnectedStatus from './NotConnectedStatus';
export { default as NotConnectedStatus } from './NotConnectedStatus';
import NotFound from './NotFound';
export { default as NotFound } from './NotFound';
import NotebookWithMagnify from './NotebookWithMagnify';
export { default as NotebookWithMagnify } from './NotebookWithMagnify';
import ObjectExplorer from './ObjectExplorer';
export { default as ObjectExplorer } from './ObjectExplorer';
import Overview from './Overview';
export { default as Overview } from './Overview';
import Package from './Package';
export { default as Package } from './Package';
import PartiallyConnectedStatus from './PartiallyConnectedStatus';
export { default as PartiallyConnectedStatus } from './PartiallyConnectedStatus';
import Pin from './Pin';
export { default as Pin } from './Pin';
import Play from './Play';
export { default as Play } from './Play';
import Plus from './Plus';
export { default as Plus } from './Plus';
import PlusCircle from './PlusCircle';
export { default as PlusCircle } from './PlusCircle';
import PostureManagement from './PostureManagement';
export { default as PostureManagement } from './PostureManagement';
import PresetRowGroup from './PresetRowGroup';
export { default as PresetRowGroup } from './PresetRowGroup';
import Private from './Private';
export { default as Private } from './Private';
import Refresh from './Refresh';
export { default as Refresh } from './Refresh';
import RemedyFill from './RemedyFill';
export { default as RemedyFill } from './RemedyFill';
import Remedy from './Remedy';
export { default as Remedy } from './Remedy';
import Remove from './Remove';
export { default as Remove } from './Remove';
import Report from './Report';
export { default as Report } from './Report';
import RiskGaugeCritical from './RiskGaugeCritical';
export { default as RiskGaugeCritical } from './RiskGaugeCritical';
import RiskGaugeHigh from './RiskGaugeHigh';
export { default as RiskGaugeHigh } from './RiskGaugeHigh';
import RiskGaugeLow from './RiskGaugeLow';
export { default as RiskGaugeLow } from './RiskGaugeLow';
import RiskGaugeMedium from './RiskGaugeMedium';
export { default as RiskGaugeMedium } from './RiskGaugeMedium';
import RiskGaugeUnknown from './RiskGaugeUnknown';
export { default as RiskGaugeUnknown } from './RiskGaugeUnknown';
import RiskManagement4 from './RiskManagement4';
export { default as RiskManagement4 } from './RiskManagement4';
import Robot from './Robot';
export { default as Robot } from './Robot';
import Rocket from './Rocket';
export { default as Rocket } from './Rocket';
import RowGroup from './RowGroup';
export { default as RowGroup } from './RowGroup';
import Rule from './Rule';
export { default as Rule } from './Rule';
import Ruleset from './Ruleset';
export { default as Ruleset } from './Ruleset';
import RuntimeProtection from './RuntimeProtection';
export { default as RuntimeProtection } from './RuntimeProtection';
import Save from './Save';
export { default as Save } from './Save';
import SecurityEvent from './SecurityEvent';
export { default as SecurityEvent } from './SecurityEvent';
import SelectAll from './SelectAll';
export { default as SelectAll } from './SelectAll';
import SemiPublic from './SemiPublic';
export { default as SemiPublic } from './SemiPublic';
import Settings from './Settings';
export { default as Settings } from './Settings';
import SeverityCritical from './SeverityCritical';
export { default as SeverityCritical } from './SeverityCritical';
import SeverityHigh from './SeverityHigh';
export { default as SeverityHigh } from './SeverityHigh';
import SeverityInfo from './SeverityInfo';
export { default as SeverityInfo } from './SeverityInfo';
import SeverityLowRisk from './SeverityLowRisk';
export { default as SeverityLowRisk } from './SeverityLowRisk';
import SeverityLow from './SeverityLow';
export { default as SeverityLow } from './SeverityLow';
import SeverityMedium from './SeverityMedium';
export { default as SeverityMedium } from './SeverityMedium';
import SeverityUnknown from './SeverityUnknown';
export { default as SeverityUnknown } from './SeverityUnknown';
import Sheet from './Sheet';
export { default as Sheet } from './Sheet';
import ShieldChecked from './ShieldChecked';
export { default as ShieldChecked } from './ShieldChecked';
import ShieldWithCircle from './ShieldWithCircle';
export { default as ShieldWithCircle } from './ShieldWithCircle';
import Shiftleft from './Shiftleft';
export { default as Shiftleft } from './Shiftleft';
import Shine from './Shine';
export { default as Shine } from './Shine';
import Sidebar from './Sidebar';
export { default as Sidebar } from './Sidebar';
import StatusError from './StatusError';
export { default as StatusError } from './StatusError';
import StatusInfo from './StatusInfo';
export { default as StatusInfo } from './StatusInfo';
import StatusSuccess from './StatusSuccess';
export { default as StatusSuccess } from './StatusSuccess';
import StatusUnsecured from './StatusUnsecured';
export { default as StatusUnsecured } from './StatusUnsecured';
import StatusWarning from './StatusWarning';
export { default as StatusWarning } from './StatusWarning';
import Storage from './Storage';
export { default as Storage } from './Storage';
import ThirdParty from './ThirdParty';
export { default as ThirdParty } from './ThirdParty';
import TimelineChart from './TimelineChart';
export { default as TimelineChart } from './TimelineChart';
import Tips from './Tips';
export { default as Tips } from './Tips';
import Traffic from './Traffic';
export { default as Traffic } from './Traffic';
import Unarchive from './Unarchive';
export { default as Unarchive } from './Unarchive';
import Unlink from './Unlink';
export { default as Unlink } from './Unlink';
import Unpin from './Unpin';
export { default as Unpin } from './Unpin';
import Unsecured from './Unsecured';
export { default as Unsecured } from './Unsecured';
import Upload from './Upload';
export { default as Upload } from './Upload';
import User from './User';
export { default as User } from './User';
import Users from './Users';
export { default as Users } from './Users';
import Warning from './Warning';
export { default as Warning } from './Warning';
import Web from './Web';
export { default as Web } from './Web';
import WorkloadProtection from './WorkloadProtection';
export { default as WorkloadProtection } from './WorkloadProtection';

export const AllIcons = {
  ciem: Ciem,
  interfaceFolderAdd: InterfaceFolderAdd,
  xls: Xls,
  accessControl: AccessControl,
  acknowledge: Acknowledge,
  addSection: AddSection,
  admissionControl: AdmissionControl,
  archive: Archive,
  arrowDownRight: ArrowDownRight,
  arrowDown: ArrowDown,
  arrowLeft: ArrowLeft,
  arrowRight: ArrowRight,
  arrowUpRight: ArrowUpRight,
  arrowUp: ArrowUp,
  assessment: Assessment,
  assetCrownJewel: AssetCrownJewel,
  assets: Assets,
  assign: Assign,
  asterisk: Asterisk,
  attachment: Attachment,
  automatedAction: AutomatedAction,
  bell: Bell,
  board: Board,
  book: Book,
  business: Business,
  calendar: Calendar,
  card: Card,
  check: Check,
  checkCircle: CheckCircle,
  chevronDown: ChevronDown,
  chevronLeft: ChevronLeft,
  chevronRight: ChevronRight,
  chevronUp: ChevronUp,
  clearAll: ClearAll,
  clock: Clock,
  cloud: Cloud,
  cloudFunction: CloudFunction,
  cluster: Cluster,
  columns: Columns,
  comments: Comments,
  compute: Compute,
  configuration: Configuration,
  connectedStatus: ConnectedStatus,
  containerImage: ContainerImage,
  containersRegistry: ContainersRegistry,
  copy: Copy,
  cve: Cve,
  cveText: CveText,
  dataSensitivity: DataSensitivity,
  database: Database,
  datalake: Datalake,
  delete: Delete,
  disable: Disable,
  document: Document,
  doubleCheck: DoubleCheck,
  download: Download,
  drag: Drag,
  duplicate: Duplicate,
  edit: Edit,
  emailAttachment: EmailAttachment,
  email: Email,
  error: Error,
  events: Events,
  exclude: Exclude,
  expand: Expand,
  export: Export,
  externalLinkInline: ExternalLinkInline,
  externalLink: ExternalLink,
  eye: Eye,
  eyeOff: EyeOff,
  favorite: Favorite,
  file: File,
  filter: Filter,
  find: Find,
  folder: Folder,
  genericImage: GenericImage,
  groupColumns: GroupColumns,
  groupExclusion: GroupExclusion,
  help: Help,
  history: History,
  iamImpact: IamImpact,
  identityCard: IdentityCard,
  imageProtection: ImageProtection,
  import: Import,
  inactive: Inactive,
  info: Info,
  installation: Installation,
  issue: Issue,
  key: Key,
  link: Link,
  listCheck: ListCheck,
  listView: ListView,
  location: Location,
  log: Log,
  magnify: Magnify,
  malicious: Malicious,
  manyKeys: ManyKeys,
  microscope: Microscope,
  minus: Minus,
  monitor: Monitor,
  more: More,
  moreHorizontal: MoreHorizontal,
  network: Network,
  noResults: NoResults,
  notConnectedStatus: NotConnectedStatus,
  notFound: NotFound,
  notebookWithMagnify: NotebookWithMagnify,
  objectExplorer: ObjectExplorer,
  overview: Overview,
  package: Package,
  partiallyConnectedStatus: PartiallyConnectedStatus,
  pin: Pin,
  play: Play,
  plus: Plus,
  plusCircle: PlusCircle,
  postureManagement: PostureManagement,
  presetRowGroup: PresetRowGroup,
  private: Private,
  refresh: Refresh,
  remedyFill: RemedyFill,
  remedy: Remedy,
  remove: Remove,
  report: Report,
  riskGaugeCritical: RiskGaugeCritical,
  riskGaugeHigh: RiskGaugeHigh,
  riskGaugeLow: RiskGaugeLow,
  riskGaugeMedium: RiskGaugeMedium,
  riskGaugeUnknown: RiskGaugeUnknown,
  riskManagement4: RiskManagement4,
  robot: Robot,
  rocket: Rocket,
  rowGroup: RowGroup,
  rule: Rule,
  ruleset: Ruleset,
  runtimeProtection: RuntimeProtection,
  save: Save,
  securityEvent: SecurityEvent,
  selectAll: SelectAll,
  semiPublic: SemiPublic,
  settings: Settings,
  severityCritical: SeverityCritical,
  severityHigh: SeverityHigh,
  severityInfo: SeverityInfo,
  severityLowRisk: SeverityLowRisk,
  severityLow: SeverityLow,
  severityMedium: SeverityMedium,
  severityUnknown: SeverityUnknown,
  sheet: Sheet,
  shieldChecked: ShieldChecked,
  shieldWithCircle: ShieldWithCircle,
  shiftleft: Shiftleft,
  shine: Shine,
  sidebar: Sidebar,
  statusError: StatusError,
  statusInfo: StatusInfo,
  statusSuccess: StatusSuccess,
  statusUnsecured: StatusUnsecured,
  statusWarning: StatusWarning,
  storage: Storage,
  thirdParty: ThirdParty,
  timelineChart: TimelineChart,
  tips: Tips,
  traffic: Traffic,
  unarchive: Unarchive,
  unlink: Unlink,
  unpin: Unpin,
  unsecured: Unsecured,
  upload: Upload,
  user: User,
  users: Users,
  warning: Warning,
  web: Web,
  workloadProtection: WorkloadProtection,
};
