import { IConfigurationContainerProps } from 'common/module_interface/settings/integrations/configurations.interface';
import { IConfiguration } from 'common/module_interface/settings/integrations/Integrations';

export const SENTRA_INTEGRATION_ID = 'Sentra';

export interface IGenericConfiguration extends IConfiguration {
    configurationObj: ISentraConfiguration;
}

export interface IGenericConfigurationProps extends IConfigurationContainerProps {
    configuration: IGenericConfiguration;
}

interface ISentraCredentialsConfiguration {
    apiKeyName: string;
    apiKey: string;
    email: string;
}

export interface ISentraConfiguration {
    id: string;
    name: string;
    sentraOrganizationName: string;
    accountCredentials: ISentraCredentialsConfiguration;
    created: string;
    lastSuccessfulSync: string | null;
}
