import { DrawerRegistry } from '../../../components/DrawerInfra/Drawer/DrawerRegistry';
import { IDrawerContent } from '../../../components/DrawerInfra/Drawer/Drawer.interface';
import { CustomForm } from './CustomForm';
import { LevelIcon } from '../../../design-system/components-v2';
import { CUSTOM_FORM_DRAWER_EVENT_HANDLER_ID } from './CustomForm.consts';
import { ICustomFormProps, IFullCustomFormProps } from './CustomForm.interface';
import { closeDrawer } from '../../../components/DrawerInfra/Drawer/Drawer.utils';

export const initializeCustomFormDrawer = (drawerKey: string, getFormProps: (data?: any) => ICustomFormProps) => {
    DrawerRegistry.addContentProvider({
        id: drawerKey,
        getDrawerContent: async (data?: any): Promise<IDrawerContent | undefined> => {
            const formProps: ICustomFormProps = getFormProps(data);
            const fullFormProps: IFullCustomFormProps = {
                formProps,
                data,
                onClose: closeDrawer,
            };
            const result: IDrawerContent = {
                title: formProps.title,
                icon: formProps.iconProps ? <LevelIcon iconProps={formProps.iconProps} /> : undefined,
                component: CustomForm,
                componentProps: fullFormProps,
            };
            return Promise.resolve(result);
        },
        options: {
            width: 'xl',
            suppressCloseOnEscape: true,
            onCloseHandlerId: CUSTOM_FORM_DRAWER_EVENT_HANDLER_ID,
        },
    });
};


