import { Backgrounds, Palette } from './colors.types';

export const backgrounds = (palette:Palette):Backgrounds => ({
    default: {
        content: palette.slate.opaque[10],
        aside: palette.slate.opaque[30],
        canvas: palette.slate.opaque[40],
        default: palette.slate.opaque[20],
        hover: palette.slate.opaque[30],
        selected: palette.blue.opaque[20],
        hoverTransparent: palette.slate.alpha[40],
        strength: {
            strongest: palette.slate.opaque[900],
            stronger: palette.slate.opaque[700],
            strong: palette.slate.opaque[400],
            regular: palette.slate.opaque[100],
            weak: palette.slate.opaque[40],
            weaker: palette.slate.opaque[30],
            weakest: palette.slate.opaque[20]
        }
    },
    critical: {
        content: palette.magenta.opaque[10],
        aside: palette.magenta.opaque[20],
        canvas: palette.magenta.opaque[30],
        default: palette.magenta.opaque[30],
        hover: palette.magenta.opaque[40],
        selected: palette.magenta.opaque[40],
        hoverTransparent: palette.magenta.alpha[40],
        strength: {
            strongest: palette.magenta.opaque[900],
            stronger: palette.magenta.opaque[700],
            strong: palette.magenta.opaque[500],
            regular: palette.magenta.opaque[300],
            weak: palette.magenta.opaque[30],
            weaker: palette.magenta.opaque[20],
            weakest: palette.magenta.opaque[10]
        }

    },
    high: {
        content: palette.red.opaque[10],
        aside: palette.red.opaque[20],
        canvas: palette.red.opaque[30],
        default: palette.red.opaque[30],
        hover: palette.red.opaque[40],
        selected: palette.red.opaque[40],
        hoverTransparent: palette.red.alpha[40],
        strength: {
            strongest: palette.red.opaque[900],
            stronger: palette.red.opaque[700],
            strong: palette.red.opaque[500],
            regular: palette.red.opaque[300],
            weak: palette.red.opaque[30],
            weaker: palette.red.opaque[20],
            weakest: palette.red.opaque[10]
        }
    },
    medium: {
        content: palette.orange.opaque[10],
        aside: palette.orange.opaque[20],
        canvas: palette.orange.opaque[30],
        default: palette.orange.opaque[30],
        hover: palette.orange.opaque[40],
        selected: palette.orange.opaque[40],
        hoverTransparent: palette.orange.alpha[40],
        strength: {
            strongest: palette.orange.opaque[900],
            stronger: palette.orange.opaque[700],
            strong: palette.orange.opaque[500],
            regular: palette.orange.opaque[300],
            weak: palette.orange.opaque[30],
            weaker: palette.orange.opaque[20],
            weakest: palette.orange.opaque[10]
        }
    },
    low: {
        content: palette.yellow.opaque[10],
        aside: palette.yellow.opaque[20],
        canvas: palette.yellow.opaque[30],
        default: palette.yellow.opaque[30],
        hover: palette.yellow.opaque[40],
        selected: palette.yellow.opaque[40],
        hoverTransparent: palette.yellow.alpha[40],
        strength: {
            strongest: palette.yellow.opaque[900],
            stronger: palette.yellow.opaque[700],
            strong: palette.yellow.opaque[500],
            regular: palette.yellow.opaque[300],
            weak: palette.yellow.opaque[30],
            weaker: palette.yellow.opaque[20],
            weakest: palette.yellow.opaque[10]
        }
    },
    lowRisk: {
        content: palette.green.opaque[10],
        aside: palette.green.opaque[20],
        canvas: palette.green.opaque[30],
        default: palette.green.opaque[30],
        hover: palette.green.opaque[40],
        selected: palette.green.opaque[40],
        hoverTransparent: palette.green.alpha[40],
        strength: {
            strongest: palette.green.opaque[900],
            stronger: palette.green.opaque[700],
            strong: palette.green.opaque[500],
            regular: palette.green.opaque[300],
            weak: palette.green.opaque[30],
            weaker: palette.green.opaque[20],
            weakest: palette.green.opaque[10]
        }
    },
    info: {
        content: palette.blue.opaque[10],
        aside: palette.blue.opaque[20],
        canvas: palette.blue.opaque[30],
        default: palette.blue.opaque[30],
        hover: palette.blue.opaque[40],
        selected: palette.blue.opaque[40],
        hoverTransparent: palette.blue.alpha[40],
        strength: {
            strongest: palette.blue.opaque[900],
            stronger: palette.blue.opaque[700],
            strong: palette.blue.opaque[500],
            regular: palette.blue.opaque[300],
            weak: palette.blue.opaque[30],
            weaker: palette.blue.opaque[20],
            weakest: palette.blue.opaque[10]
        }
    },
    important: {
        content: palette.purple.opaque[10],
        aside: palette.purple.opaque[20],
        canvas: palette.purple.opaque[30],
        default: palette.purple.opaque[30],
        hover: palette.purple.opaque[40],
        selected: palette.purple.opaque[40],
        hoverTransparent: palette.purple.alpha[40],
        strength: {
            strongest: palette.purple.opaque[900],
            stronger: palette.purple.opaque[700],
            strong: palette.purple.opaque[500],
            regular: palette.purple.opaque[300],
            weak: palette.purple.opaque[30],
            weaker: palette.purple.opaque[20],
            weakest: palette.purple.opaque[10]
        }
    },
});