import * as React from 'react';
import { SVGProps, Ref, forwardRef } from 'react';
const SvgAssetCrownJewel = (
  props: SVGProps<SVGSVGElement>,
  ref: Ref<SVGSVGElement>
) => (
  <svg
    viewBox="0 0 16 16"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    ref={ref}
    {...props}
  >
    <path
      d="M.5 2.5v8a2 2 0 0 0 2 2h11a2 2 0 0 0 2-2v-8l-4 3.5L8 .5 4.5 6l-4-3.5Z"
      stroke="currentColor"
      strokeOpacity={0.78}
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </svg>
);
const ForwardRef = forwardRef(SvgAssetCrownJewel);
export default ForwardRef;
