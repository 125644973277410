import React from 'react';
import { ICellRendererParams } from 'ag-grid-enterprise';
import IconLinkCellRenderer, { IIconLinkCellProps } from 'common/components/ag-grid/Renderers/IconLinkCellRenderer';
import { isNil } from 'common/utils/helpFunctions';
import { CellToClipboardHandler } from 'common/components/ProtectedAssets/ProtectedAssetsTable';
import { showRecipeForm } from '../../../RecipeEditor.items';
import { RECIPE_ICON_NAME } from '../../../RecipeEditor.consts';
import { IRecipe } from '../../../../RecipeExampleCenter/RecipeExample.interface';

export const RecipeNameCellRender: React.FC<ICellRendererParams<IRecipe> & { getIsUsingDrawer: () => boolean }> = (params) => {
    const recipe: IRecipe | undefined = params.data;
    if (!recipe?.name) {
        return '';
    }

    const onClick = () => {
        showRecipeForm(params.getIsUsingDrawer(), recipe);
    };

    const options: IIconLinkCellProps = {
        isLink: !isNil(onClick),
        value: params.value,
        onClick,
        levelIconProps: {
            iconProps: { name: RECIPE_ICON_NAME },
            size: 'md',
        }
    };

    CellToClipboardHandler(params, recipe.name);
    return (<IconLinkCellRenderer {...options} />);
};
