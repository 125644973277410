import * as React from 'react';
import { SVGProps, Ref, forwardRef } from 'react';
const SvgSettings = (
  props: SVGProps<SVGSVGElement>,
  ref: Ref<SVGSVGElement>
) => (
  <svg
    viewBox="0 0 16 16"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    ref={ref}
    {...props}
  >
    <path
      d="M4.586 2.081a1 1 0 0 1 1.643-.68l1.125.952a1 1 0 0 0 1.292 0l1.125-.952a1 1 0 0 1 1.643.68l.122 1.47a1 1 0 0 0 .914.913l1.469.122a1 1 0 0 1 .68 1.643l-.952 1.125a1 1 0 0 0 0 1.292l.952 1.125a1 1 0 0 1-.68 1.643l-1.47.122a1 1 0 0 0-.913.914l-.122 1.469a1 1 0 0 1-1.643.68l-1.125-.952a1 1 0 0 0-1.292 0l-1.125.952a1 1 0 0 1-1.643-.68l-.122-1.47a1 1 0 0 0-.914-.913l-1.469-.122a1 1 0 0 1-.68-1.643l.952-1.125a1 1 0 0 0 0-1.292l-.952-1.125a1 1 0 0 1 .68-1.643l1.47-.122a1 1 0 0 0 .913-.914l.122-1.469Z"
      stroke="currentColor"
    />
    <path d="M10 8a2 2 0 1 1-4 0 2 2 0 0 1 4 0Z" stroke="currentColor" />
  </svg>
);
const ForwardRef = forwardRef(SvgSettings);
export default ForwardRef;
