import {
    RECIPE_EDITOR_KEY,
    RECIPE_ICON_NAME, RECIPE_PAGE_TABLE_ID, RecipeHeadersNames, RecipeItemNames,
} from './RecipeEditor.consts';
import {
    getOtherRecipeNames,
    getRecipeSubmitFailedNotification,
    getRecipeSubmitSuccessNotification,
    saveRecipe,
} from './RecipeEditor.datasource';
import { IRecipeProps } from '../RecipeExampleCenter/RecipeExample.interface';
import { RecipeEditorCalculator } from './components/RecipeEditorCalculator';
import {
    commonCustomComponentFieldItemProps,
    commonInputItemProps, commonLabelItemProps,
    commonLazySelectFieldItemProps,
    commonMultiSelectFieldItemProps, commonOrgUnitsFieldItemProps, commonRadioItemProps, commonBoxItemProps,
    commonSingleSelectFieldItemProps,
    commonTagsFieldItemProps, commonTextAreaItemProps,
    commonDateRangeFieldItemProps, FormItemType, commonSingleDateFieldItemProps, commonMultiInputItemProps,
} from '../../custom/CustomForm/CustomForm.consts';
import {
    getChefOptions,
    getDairyProductsOptions,
    getMeatAlternativesOptions, getMeatCategoryOptions,
    getMeatTypeOptions, getOrgsRoot, getSpiceOptions, getVegetableOptions,
} from './RecipeEditor.options';
import { getMeatPraiseText, getNewMeatPraiseState, getNewMeatState } from './RecipeEditor.values';
import { showDrawer } from '../../../components/DrawerInfra/Drawer/Drawer.utils';
import { globalModelUtils } from '../../../components/GlobalModals/GlobalModals';
import { ICustomFormProps, IItem, IRadioOption, IBoxItem } from '../../custom/CustomForm/CustomForm.interface';
import { IMeatCategory } from '../RecipeExampleCenter/FakeRecipeServer/FakeRecipeServer.data';
import { getRecipeExampleService } from '../RecipeExampleCenter/RecipeExampleService';
import {
    convertDatesToValues,
    convertDateToValue,
    convertTagsToValues,
} from '../../custom/CustomForm/CustomForm.values';
import { checkIngredientsValidation } from './RecipeEditor.validations';

const getMeatRadioOption = async (recipe?: IRecipeProps): Promise<IRadioOption> => {
    const meatTypes: string[] = [];
    let meatCategory: IMeatCategory | undefined;
    if (recipe?.meatTypes && (recipe.meatTypes.length > 0) && recipe?.meatCategory) {
        const allCategories: IMeatCategory[] = await getRecipeExampleService().getAllMeatCategories();
        meatCategory = allCategories.find(category => category.name === recipe.meatCategory);
        if (meatCategory) {
            recipe.meatTypes.forEach(meatType => {
                if (meatCategory!.meatTypes.includes(meatType)) {
                    meatTypes.push(meatType);
                }
            });
        }
    }

    return {
        name: RecipeItemNames.meatRadioOption,
        label: 'Meat & Fish',
        subItems: [
            {
                ...commonSingleSelectFieldItemProps,
                name: RecipeItemNames.meatCategory,
                state: {
                    label: RecipeHeadersNames[RecipeItemNames.meatCategory],
                    options: await getMeatCategoryOptions(),
                    tooltip: 'Select the wanted meat category',
                    value: meatCategory?.name,
                },
            },
            {
                ...commonLabelItemProps,
                name: RecipeItemNames.meatCategoryPraise,
                getNewItemState: getNewMeatPraiseState,
                state: {
                    label: getMeatPraiseText(recipe?.meatCategory) || '',
                    labelProps: { size: 'lg' },
                    hidden: !meatCategory,
                }
            },
            {
                ...commonMultiSelectFieldItemProps,
                name: RecipeItemNames.meatTypes,
                getNewItemState: getNewMeatState,
                state: {
                    label: RecipeHeadersNames[RecipeItemNames.meatTypes],
                    options: await getMeatTypeOptions(recipe?.meatCategory),
                    value: meatTypes,
                    hidden: !meatCategory,
                }
            },
        ],
    };
};

const getDairyRadioOption = async (recipe?: IRecipeProps): Promise<IRadioOption> => ({
    name: RecipeItemNames.dairyRadioOption,
    label: 'Dairy Products',
    subItems: [
        {
            ...commonMultiSelectFieldItemProps,
            name: RecipeItemNames.dairyProducts,
            state: {
                label: RecipeHeadersNames[RecipeItemNames.dairyProducts],
                options: await getDairyProductsOptions(),
                value: recipe?.dairyProducts,
            },
        }
    ],
});

const getMeatAlternativeRadioOption = async (recipe?: IRecipeProps): Promise<IRadioOption> => ({
    name: RecipeItemNames.meatAlternativesRadioOption,
    label: 'Meat Alternative',
    subItems: [
        {
            ...commonMultiSelectFieldItemProps,
            name: RecipeItemNames.meatAlternatives,
            state: {
                label: RecipeHeadersNames[RecipeItemNames.meatAlternatives],
                options: await getMeatAlternativesOptions(),
                value: recipe?.meatAlternatives,
            },
        },
    ],
});

const getVegetablesStack = async (recipe?: IRecipeProps): Promise<IBoxItem> => ({
    ...commonBoxItemProps,
    name: RecipeItemNames.vegetablesStack,
    state: {
    },
    subItems: [
        {
            ...commonLabelItemProps,
            name: RecipeItemNames.vegetablesIcon,
            state: {
                label: '',
                icon: {
                    name: 'web',
                    size: 48,
                },
                widthInfo: { flexGrow: 0 },
            }
        },
        {
            ...commonMultiSelectFieldItemProps,
            name: RecipeItemNames.vegetables,
            state: {
                label: RecipeHeadersNames[RecipeItemNames.vegetables],
                options: await getVegetableOptions(),
                value: recipe?.vegetables,
                widthInfo: { flexGrow: 1 },
            },
        },
    ],
});

const getMoreStack = async (recipe?: IRecipeProps): Promise<IBoxItem> => ({
    ...commonBoxItemProps,
    name: RecipeItemNames.moreStack,
    state: {
        direction: 'column',
        label: 'More...',
        padding: [5, 0, 0, 0],
    },
    subItems: [
        {
            ...commonLazySelectFieldItemProps,
            name: RecipeItemNames.chefs,
            getLazyOptions: getChefOptions,
            state: {
                label: RecipeHeadersNames[RecipeItemNames.chefs],
                value: recipe?.chefs,
            },
        },
        {
            ...commonOrgUnitsFieldItemProps,
            name: RecipeItemNames.orgIds,
            state: {
                label: RecipeHeadersNames[RecipeItemNames.orgIds],
                orgUnitsRoot: await getOrgsRoot(),
                value: recipe?.orgIds,
            },
        },
    ],
});

const getItems = async (recipe?: IRecipeProps): Promise<IItem[]> => [
    {
        ...commonInputItemProps,
        name: RecipeItemNames.name,
        state: {
            value: recipe?.name,
            isRequired: true,
            charsLimit: 100,
            label: RecipeHeadersNames[RecipeItemNames.name],
            forbiddenOtherValues: await getOtherRecipeNames(recipe),
        }
    },
    {
        ...commonCustomComponentFieldItemProps,
        name: RecipeItemNames.calculator,
        Component: RecipeEditorCalculator,
        state: {
            label: 'Cost Calculator',
        },
    },
    {
        ...commonLabelItemProps,
        name: RecipeItemNames.ingredientsLabel,
        checkValidation: checkIngredientsValidation,
        state: {
            label: 'Ingredients',
            isRequired: true,
        },
    },
    {
        ...commonMultiSelectFieldItemProps,
        name: RecipeItemNames.spices,
        state: {
            label: RecipeHeadersNames[RecipeItemNames.spices],
            options: await getSpiceOptions(),
            value: recipe?.spices,
        }
    },
    (await getVegetablesStack(recipe)),
    {
        ...commonRadioItemProps,
        itemType: FormItemType.radio,
        name: RecipeItemNames.meatRadio,
        options: [
            await getMeatRadioOption(recipe),
            await getDairyRadioOption(recipe),
            await getMeatAlternativeRadioOption(recipe),
        ],
        state: {},
    },
    {
        ...commonSingleDateFieldItemProps,
        name: RecipeItemNames.deliveryDate,
        state: {
            label: RecipeHeadersNames[RecipeItemNames.deliveryDate],
            value: convertDateToValue(recipe?.deliveryDate),
        },
    },
    {
        ...commonMultiInputItemProps,
        name: RecipeItemNames.inventors,
        state: {
            label: RecipeHeadersNames[RecipeItemNames.inventors],
            value: recipe?.inventors,
        },
    },
    {
        ...commonTextAreaItemProps,
        name: RecipeItemNames.instructions,
        state: {
            charsLimit: 200,
            label: RecipeHeadersNames[RecipeItemNames.instructions],
            value: recipe?.instructions,
        },
    },
    {
        ...commonDateRangeFieldItemProps,
        name: RecipeItemNames.tastingTimeFrame,
        state: {
            label: RecipeHeadersNames[RecipeItemNames.tastingTimeFrame],
            value: recipe?.tastingTimeFrame ? convertDatesToValues(recipe.tastingTimeFrame) : undefined,
            allowPast: true,
        },
    },
    (await getMoreStack(recipe)),
    {
        ...commonTagsFieldItemProps,
        name: RecipeItemNames.tags,
        state: {
            label: RecipeHeadersNames[RecipeItemNames.tags],
            value: recipe?.tags ? convertTagsToValues(recipe.tags) : undefined,
        }
    }];

export const getRecipeFormProps = (recipe?: IRecipeProps): ICustomFormProps => {
    return {
        getItems,
        submitData: saveRecipe,
        getSubmitSuccessNotification: getRecipeSubmitSuccessNotification,
        getSubmitFailedNotification: getRecipeSubmitFailedNotification,
        eventHandlerId: RECIPE_PAGE_TABLE_ID,
        title: recipe?.id ? recipe.name : 'Add Recipe',
        iconProps: { name: RECIPE_ICON_NAME },
        minDialogHeight: 300,
    };
};

export const showRecipeForm = (isUsingDrawer: boolean, recipe?: IRecipeProps) => {
    if (isUsingDrawer) {
        showDrawer(RECIPE_EDITOR_KEY, recipe);
    } else {
        globalModelUtils.showCustomFormModal({
            formProps: getRecipeFormProps(),
            data: recipe,
        });
    }
};

