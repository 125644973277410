import React from 'react';
import FilterPanel from 'common/components/FilterPanel/FilterPanel';
import { FILTERS_KEYS } from 'common/components/FilterPanel/FilterPanel.consts';
import { renderAddFilter, renderClearAll, renderDefaultFreeTextFilter, renderSavedFilters } from 'common/components/FilterPanel/FilterPanelDefaultFilters';
import FilterPanelPlaceholderStyled from './FilterPanelPlaceholder.styled';

export const FilterPanelPlaceholder: React.FC = () => {
    return (
        <FilterPanelPlaceholderStyled.Wrapper>
            <FilterPanel
                filterPanelProps={{
                    filters: [
                        renderAddFilter({ key: FILTERS_KEYS.ADD_FILTER, isLoading: true }),
                        renderClearAll({ key: FILTERS_KEYS.CLEAR_BUTTON }),
                        renderSavedFilters({ key: FILTERS_KEYS.SAVED_FILTERS, savedFilters: [] }),
                        renderDefaultFreeTextFilter({ key: FILTERS_KEYS.FREE_TEXT, title: '', value: '' }),
                    ],
                    onFilterPanelChange: () => new Promise(() => null),
                    containerQuery: {} 
                }}
            />
        </FilterPanelPlaceholderStyled.Wrapper>
    );
};

export default FilterPanelPlaceholder;
