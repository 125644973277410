import { RecipeItemNames } from './RecipeEditor.consts';
import {
    convertValuesToDates,
    convertValuesToTags, convertValueToDate, getTrimmedMultiValue,
    getTrimmedSingleValue,
} from 'common/erm-components/custom/CustomForm/CustomForm.values';
import { IRecipeProps } from '../RecipeExampleCenter/RecipeExample.interface';
import { MeatCategory } from '../RecipeExampleCenter/FakeRecipeServer/FakeRecipeServer.data';
import { getMeatTypeOptionsByCategory } from './RecipeEditor.options';
import {
    IMultiSelectState,
    IItemChanges,
    IItemsMap, IMultiSelectItem, ILabelItem, ILabelState,
} from '../../custom/CustomForm/CustomForm.interface';

export const getSafeName = (itemsMap: IItemsMap): string =>
    getTrimmedSingleValue(itemsMap[RecipeItemNames.name]) || '';

export const getRecipeFromStates = (itemsMap: IItemsMap): IRecipeProps => {
    return {
        name: getSafeName(itemsMap),
        instructions: getTrimmedSingleValue(itemsMap[RecipeItemNames.instructions]),
        spices: getTrimmedMultiValue(itemsMap[RecipeItemNames.spices]),
        vegetables: getTrimmedMultiValue(itemsMap[RecipeItemNames.vegetables]),
        inventors: getTrimmedMultiValue(itemsMap[RecipeItemNames.inventors]),
        meatCategory: getTrimmedSingleValue(itemsMap[RecipeItemNames.meatCategory]),
        meatTypes: getTrimmedMultiValue(itemsMap[RecipeItemNames.meatTypes]),
        meatAlternatives: getTrimmedMultiValue(itemsMap[RecipeItemNames.meatAlternatives]),
        dairyProducts: getTrimmedMultiValue(itemsMap[RecipeItemNames.dairyProducts]),
        orgIds: getTrimmedMultiValue(itemsMap[RecipeItemNames.orgIds]),
        chefs: getTrimmedMultiValue(itemsMap[RecipeItemNames.chefs]),
        deliveryDate: convertValueToDate(getTrimmedSingleValue(itemsMap[RecipeItemNames.deliveryDate])),
        tastingTimeFrame: convertValuesToDates(getTrimmedMultiValue(itemsMap[RecipeItemNames.tastingTimeFrame])),
        tags: convertValuesToTags(getTrimmedMultiValue(itemsMap[RecipeItemNames.tags])),
    };
};

export const getMeatPraiseText = (category?: string): string | undefined => {
    if (!category) {
        return undefined;
    }

    switch (category) {
        case MeatCategory.Fish:
            return 'Fish is great for your health!';

        case MeatCategory.Beef:
            return 'Please respect the beef you get.';

        case MeatCategory.Poultry:
            return 'This dish will give you wings...';

        default:
            return;
    }
};

const getMeatTooltip = (category: string): string | undefined => {
    switch (category) {
        case MeatCategory.Fish:
            return 'Pick a nice fish';

        case MeatCategory.Beef:
            return 'Pick a juicy beef';

        case MeatCategory.Poultry:
            return 'Pick a cool poultry';

        default:
            return;
    }
};

export const getNewMeatState = async (item: IMultiSelectItem, changeDetails: IItemChanges): Promise<IMultiSelectState | undefined> => {
    const { newItem } = changeDetails;
    if (newItem.name !== RecipeItemNames.meatCategory) {
        return Promise.resolve(undefined);
    }

    const categoryName: string | undefined = getTrimmedSingleValue(newItem);
    return {
        ...item.state,
        hidden: !categoryName,
        tooltip: categoryName && getMeatTooltip(categoryName),
        options: categoryName ? await getMeatTypeOptionsByCategory(categoryName) : [],
    };
};

export const getNewMeatPraiseState = async (item: ILabelItem, changeDetails: IItemChanges): Promise<ILabelState | undefined> => {
    const { newItem } = changeDetails;
    if (newItem.name !== RecipeItemNames.meatCategory) {
        return Promise.resolve(undefined);
    }

    const categoryName: string | undefined = getTrimmedSingleValue(newItem);
    return {
        ...item.state,
        hidden: !categoryName,
        label: getMeatPraiseText(categoryName) || '',
    };
};
