import { getService } from 'common/extensibility/AddinContainer';
import {
    ICloudBotRemediationDetails,
    IRemediationExecutionInfo,
    IRemediationRequest,
    IRemediationResponse
} from './CloudBots.interface';
import { CLOUD_BOTS_SERVICE_ID } from './CloudBots.const';
import { Vendors } from '../../../consts/vendors';

export interface ICloudBotsService {
    saveEndpoint(cloudAccountId: string, endpoint: string): void;
    getEndpoint(cloudAccountId: string, vendor: Vendors): Promise<string | undefined>;
    executeRemediation(data: IRemediationRequest): Promise<IRemediationResponse>;
    setRemediationDetails(component:string, data: ICloudBotRemediationDetails): Promise<ICloudBotRemediationDetails | undefined>;
    getRemediationDetails(component:string): Promise<ICloudBotRemediationDetails | undefined>;
    clearRemediationDetails(component:string): Promise<any>;
    pollRemediationExecutionStatus(executionIds: string[], remediationType?: string): Promise<IRemediationExecutionInfo[]>;
}

export function getCloudBotsService(): ICloudBotsService {
    return getService<ICloudBotsService>(CLOUD_BOTS_SERVICE_ID);
}
