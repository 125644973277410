import React from 'react';
import { ICellRendererParams } from 'ag-grid-enterprise';
import IconLinkCellRenderer, { IIconLinkCellProps } from '../../../ag-grid/Renderers/IconLinkCellRenderer';


const IconCellRenderer: React.FC<ICellRendererParams> = (params) => {
    const iconName = params.data.icon;
    const className = params.colDef && params.colDef.cellRendererParams ? params.colDef.cellRendererParams.className : '';
    const options: IIconLinkCellProps = {
        isLink: false,
        iconProps: iconName ? { vendorNameOrPath: iconName } : undefined,
        value: params.data.displayName,
        className: className
    };

    return <IconLinkCellRenderer {...options} />;
};

export default IconCellRenderer;
