import { convertCompoundFilterToQuery } from 'common/erm-components/services/gsl/GslCompoundFilter';
import {
    IGslCount,
    IGslFilter,
    IGslFreeTextFilter,
    IGslQueryRequest,
    IGslSort,
    IGslTimeFilter,
} from 'common/erm-components/services/gsl/GslService.interface';
import { IGslRunResponse } from 'common/module_interface/intelligence/Intelligence.interface';
import { Aggregations } from 'common/components/FilterPanel/FilterPanel.interface';
import { EMPTY_STRING } from 'common/consts/GeneralConsts';
import { isEmpty } from 'lodash';


import { GSL_CLAUSES } from '../../../module_interface/intelligence/Gsl/GslService.const';

export const createGslQuery = (gslQueryRequest: IGslQueryRequest): string => {
    const { filter, sort, count, limit,
        aggregations } = gslQueryRequest;
    const query: string[] = [];
    query.push(createFilterClause(filter));
    if (sort) query.push(createSortClause(sort));
    if (aggregations) query.push(createAggregationClause(aggregations));
    if (count) query.push(createCountClause(count));
    if (limit) query.push(createLimitClause(limit));
    return `${filter.source} ${query.join(' ')}`;
};

export const createFilterClause = (filter: IGslFilter): string => {
    const filtersQuery = [];
    if (filter.compoundFilter) {
        filtersQuery.push(`${convertCompoundFilterToQuery(filter.compoundFilter)}`);
    }
    if (filter.freeTextFilter) {
        filtersQuery.push(`(${createFreeTextClause(filter.freeTextFilter)})`);
    }
    if (filter.timeFilter && filter.timeFilter.range.from && filter.timeFilter.range.to) {
        filtersQuery.push(`(${createTimeTRangeClause(filter.timeFilter)})`);
    }
    return `${GSL_CLAUSES.WHERE} ${filtersQuery.join(' and ')}`;
};

export const createFreeTextClause = (freeTextFilter: IGslFreeTextFilter): string => {
    const freeTextClauses = freeTextFilter.fields.map((field: string) =>
        `${field} like '%${freeTextFilter.phrase}%'`);
    return freeTextClauses.join(' or ');
};

export const createTimeTRangeClause = (timeRangeFilter: IGslTimeFilter): string => {
    const timeClause: string[] = [];
    const { range, field } = timeRangeFilter;
    timeClause.push(`${field} >= ${GSL_CLAUSES.UNIX_TO_DATETIME}(${range.from})`);
    timeClause.push(`${field} <= ${GSL_CLAUSES.UNIX_TO_DATETIME}(${range.to})`);
    return timeClause.join(' and ');
};

export const createSortClause = (sort: IGslSort[]): string => {
    const sortQuery: string[] = [];
    sortQuery.push(GSL_CLAUSES.ORDER_BY);
    sortQuery.push(createSortByClause(sort));
    return sortQuery.join(' ');
};

const createSortByClause = (sort: IGslSort[]): string => {
    const orderByClause: string[] = [];
    sort.forEach((sort: IGslSort) => {
        orderByClause.push(`${sort.fieldName} ${sort.direction}`);
    });
    return orderByClause.join(', ');
};

export const createAggregationClause = (aggregations: string[]): string => {
    const aggregationsQuery: string[] = [];
    aggregationsQuery.push(GSL_CLAUSES.FACET_BY);
    aggregationsQuery.push(aggregations.join(', '));
    return aggregationsQuery.join(' ');
};

export const createCountClause = (count: IGslCount): string => {
    if (count.distinctCount) {
        return `${GSL_CLAUSES.SUMMARIZE} ${GSL_CLAUSES.DISTINCT_COUNT}(${count.field})`;
    }
    return `${GSL_CLAUSES.SUMMARIZE} ${GSL_CLAUSES.COUNT}(${count.field})`;
};

export const createLimitClause = (limit: number): string => {
    return `${GSL_CLAUSES.LIMIT} ${limit}`;
};

export const createGetItemsQuery = (filter: IGslFilter, limit?: number, sort?: IGslSort[]): string => {
    return createGslQuery({ filter, sort, limit });
};

export const createGetCountQuery = (filter: IGslFilter, count: IGslCount): string => {
    return createGslQuery({ filter, count });
};

export const createGetAggregationsQuery = (filter: IGslFilter, aggregations: string[]): string => {
    return createGslQuery({ filter, aggregations });
};

export const convertFacetsToAggregations = (facetFields: string[], facets: IGslRunResponse[]): Aggregations => {
    const aggregations: Aggregations = {};
    facets?.forEach(facet => {
        const cols = Object.keys(facet.cols);
        const facetFieldName = cols.find(col => facetFields.includes(col)) || EMPTY_STRING;
        if(!isEmpty(facetFieldName)) {
            const facetCountFieldName = `count_${facetFieldName}`;
            aggregations[`${facetFieldName}`] = facet.data.map(data => {
                return { 'value': data[facet.cols[facetFieldName]], 'count': data[facet.cols[facetCountFieldName]] };
            });
        }
    });
    return aggregations;
};
