import React, { forwardRef } from 'react';
import ComponentOverflowStyles from './ComponentOverflow.styles';
import { IComponentOverflowProps } from './ComponentOverflow.types';
import Tooltip from '../Tooltip/Tooltip';
import Chip from '../Chip/Chip';
import { useTheme } from 'styled-components';
import Stack from '../Stack/Stack';
import Typography from '../Typography/Typography';

const ComponentOverflow = forwardRef<HTMLDivElement, IComponentOverflowProps>((props, ref) => {
    const {
        components,
        spacing = 1,
        overflowSpacing = 1,
        minWidth,
        maxWidth,
        hideOverflowCount,
        commaSeperated,
        overflowCountBorder,
        onOverflowCountChange,
        ellipsisMinWidth = 20,
    } = props;
    
    const theme = useTheme();

    const [overflowCount, setOverflowCount] = React.useState<number>(0);
    const containerRef = React.useRef<HTMLDivElement>(null);
    const componentRefs = React.useRef<any[]>([]);
    const counterChipRef = React.useRef<HTMLDivElement>(null);
    
    React.useImperativeHandle(ref, () => containerRef.current as HTMLDivElement);

    const updateVisibleComponents = React.useCallback(() => {
        if (!containerRef.current) return;
        const countChipWidth = 40 + theme.spacing(spacing);
        if (counterChipRef.current) {
            counterChipRef.current.style.display = 'none';
        }
        let totalWidth = hideOverflowCount ? 0 : countChipWidth;
        let hiddenComponentsCount = 0;

        for (let i = 0; i < components.length; i++) {
            const componentRef = componentRefs.current[i];
            if (!componentRef) break;
            componentRef.style.opacity = '0';
            componentRef.style.display = 'flex';
            componentRef.style.maxWidth = 'unset';
            componentRef.style.overflow = 'unset';
        }
        
        if (!hideOverflowCount) {
            containerRef.current.style.paddingRight = `${countChipWidth}px`;
        }
        const containerWidth = containerRef.current.offsetWidth;

        let appliedEllipsis = false;
        for (let i = 0; i < components.length; i++) {
            const componentRef = componentRefs.current[i];
            if (!componentRef) break;
            const componentWidth = componentRef.offsetWidth;
            totalWidth += componentWidth;
            if (i > 0) {
                totalWidth += theme.spacing(spacing);
                if (commaSeperated) {
                    totalWidth += 5;
                }
            }
            if (
                appliedEllipsis || ((totalWidth > containerWidth))
            ) {
                if (!appliedEllipsis && (containerWidth - (totalWidth - componentWidth)) > ellipsisMinWidth) {
                    appliedEllipsis = true;
                    componentRef.style.display = 'flex';
                    componentRef.style.opacity = '1';
                    componentRef.style.maxWidth = `${containerWidth - (totalWidth - componentWidth)}px`;
                    componentRef.style.overflow = 'hidden';
                } else {
                    hiddenComponentsCount++;
                    componentRef.style.display = 'none';
                    componentRef.style.opacity = '0';
                }
            } else {

                componentRef.style.display = 'flex';
                componentRef.style.opacity = '1';
            }
        }

        if (counterChipRef.current) {
            counterChipRef.current.style.display = 'flex';
        }
        
        if (!hideOverflowCount) {
            containerRef.current.style.paddingRight = '0';
        }
        setOverflowCount(hiddenComponentsCount);
    }, [components, commaSeperated, spacing, theme, hideOverflowCount, ellipsisMinWidth]);

    React.useEffect(() => {
        if (!containerRef.current) return;

        updateVisibleComponents();
        let timeout: NodeJS.Timeout;
        let interval: NodeJS.Timeout;
        const resizeObserver = new ResizeObserver(() => {
            if (!containerRef.current) return;
            clearTimeout(timeout);
            timeout = setTimeout(() => {
                updateVisibleComponents();
            }, 100);
            clearInterval(interval);
            interval = setInterval(() => {
                updateVisibleComponents();
            }, 3000);
        });
        resizeObserver.observe(containerRef.current);

        return () => {
            clearTimeout(timeout);
            clearInterval(interval);
            if (resizeObserver && containerRef.current) {
                // eslint-disable-next-line react-hooks/exhaustive-deps
                resizeObserver.unobserve(containerRef.current);
            }
        };
    }, [updateVisibleComponents]);

    React.useEffect(() => {
        if (onOverflowCountChange) {
            onOverflowCountChange(overflowCount);
        }
    }, [overflowCount, onOverflowCountChange]);

    const overflowComponent = React.useMemo(() => (
        <Stack direction='column' spacing={overflowSpacing}>
            {components.slice(components.length - overflowCount).map((component, index) => (
                <React.Fragment key={`overflow-component-${index}`}>
                    {component}
                </React.Fragment>
            ))}
        </Stack>
    ), [components, overflowCount, overflowSpacing]);

    const shouldAddComma = (index: number) => {
        if (commaSeperated && index < components.length - 1 && index < components.length - overflowCount - 1) {
            return true;
        }
        return false;
    };

    if (components.length <= 1) {
        return (
            <ComponentOverflowStyles.Wrapper
                ref={containerRef}
                fullWidth
                minWidth={minWidth}
                maxWidth={maxWidth}
            >
                {components}
            </ComponentOverflowStyles.Wrapper>
        );
    }

    return (
        <ComponentOverflowStyles.Wrapper
            ref={containerRef}
            direction='row'
            alignItems='center'
            spacing={spacing}
            fullWidth
            minWidth={minWidth}
            maxWidth={maxWidth}
        >
            <Stack
                direction='row'
                alignItems='center'
                spacing={spacing}
            >
                {components.map((component, index) => (
                    <Stack key={`component-${index}`} ref={el => componentRefs.current[index] = el} direction='row'>
                        {component}{shouldAddComma(index) && <Typography>,</Typography>}
                    </Stack>
                ))}
            </Stack>
            {(!hideOverflowCount && (overflowCount > 0)) && (
                <Tooltip interactive content={overflowComponent} placement='auto'>
                    <Chip
                        ref={counterChipRef}
                        label={`+${overflowCount}`}
                        size='xs'
                        customColor={theme.palette.surface.brandLight}
                        withBorder={overflowCountBorder}
                    />
                </Tooltip>
            )}
        </ComponentOverflowStyles.Wrapper>
    );
});
ComponentOverflow.displayName = 'ComponentOverflow';

export default ComponentOverflow;
