import * as React from 'react';
import { SVGProps, Ref, forwardRef } from 'react';
const SvgConfiguration = (
  props: SVGProps<SVGSVGElement>,
  ref: Ref<SVGSVGElement>
) => (
  <svg
    viewBox="0 0 16 16"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    ref={ref}
    {...props}
  >
    <circle cx={5.5} cy={2.5} r={2} stroke="currentColor" />
    <circle cx={10.5} cy={7.5} r={2} stroke="currentColor" />
    <circle cx={5.5} cy={12.5} r={2} stroke="currentColor" />
    <path
      d="M.5 2.5h3m4 0h8m-15 5h8m4 0h3m-15 5h3m4 0h8"
      stroke="currentColor"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </svg>
);
const ForwardRef = forwardRef(SvgConfiguration);
export default ForwardRef;
