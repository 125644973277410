import { IRecipeModel } from '../RecipeExampleService';

export const spiceList = [
    'Ground cumin',
    'Basil',
    'Cinnamon',
    'Bay leaves',
    'Smoked paprika',
    'Thyme',
    'Garlic powder',
    'Oregano',
    'Onion powder',
    'Rosemary',
    'Nutmeg powder',
    'Red pepper flakes',
    'Coriander powder',
    'Cayenne pepper',
    'Ground cloves',
    'Tumeric',
    'Curry powder',
    'Yellow mustard',
    'Cardamon powder',
    'Cajun seasoning',
    'Allspice powder',
    'Chili powder',
    'Ginger powder',
    'Black pepper',
    'Sea salt',
];

export const vegetableList = [
    'Artichoke',
    'Asparagus',
    'Beets',
    'Bok Choy',
    'Broccolini',
    'Brussel Sprouts',
    'Cabbage',
    'Carrots',
    'Cauliflower',
    'Celery',
    'Corn',
    'Cucumber',
    'Eggplant',
    'Fennel',
    'Green Beans',
    'Leeks',
    'Mushrooms',
    'Onions',
    'Peas',
    'Peppers',
    'Potatoes',
    'Sweet Potatoes',
    'Tomatoes',
    'Winter Squash',
    'Zucchini',
];

export enum MeatCategory {
    Beef = 'Beef',
    Fish = 'Fish',
    Poultry = 'Poultry',
}

export interface IMeatCategory {
    name: MeatCategory;
    meatTypes: string[];
}

export const meatCategoryList: IMeatCategory[] = [
    {
        name: MeatCategory.Beef,
        meatTypes: ['Cattle', 'Lamb', 'Bison'],
    },
    {
        name: MeatCategory.Poultry,
        meatTypes: ['Turkey', 'Chicken', 'Duck', 'Dove'],
    },
    {
        name: MeatCategory.Fish,
        meatTypes: ['Salmon', 'Tuna','Trout','Cod', 'Sea Bass'],
    },
];

export const dairyProductList = [
    'Butter',
    'Goat Cheese',
    'Sour Cream',
    'Milk',
    'Gouda',
    'Roquefort',
    'Camembert',
    'Cream Cheese',
];

export const meatAlternativeLists = [
    'Tofu',
    'Chickpeas',
    'Mushroom',
    'Black Beans',
];

export const recipeList: IRecipeModel[] = [
    {
        name: 'Salmon in the Oven',
        instructions: 'Just do it!',
        spices: [
            'Smoked paprika',
            'Basil'
        ],
        vegetables: [
            'Corn',
            'Fennel'
        ],
        meatCategory: 'Fish',
        meatTypes: [
            'Salmon'
        ],
        deliveryDate: 'Mon Aug 12 2024 00:00:00 GMT+0300 (Israel Daylight Time)',
        tastingTimeFrame: [
            'Mon Aug 12 2024 00:00:00 GMT+0300 (Israel Daylight Time)',
            'Thu Aug 29 2024 00:00:00 GMT+0300 (Israel Daylight Time)'
        ],
        inventors: [
            'YP & Co',
            'Team B.',
        ],
        id: 'my-recipe-1'
    },
    {
        name: 'Yaman Soup',
        instructions: 'Take it, put it, mix it, eat it',
        spices: [
            'Basil',
            'Thyme'
        ],
        vegetables: [
            'Broccolini'
        ],
        meatAlternatives: [
            'Black Beans'
        ],
        tags: [
            'yaman1',
            '1',
            'yaman2',
            '2'
        ],
        id: 'my-recipe-2'
    },
    {
        name: 'Tomahawk Steak',
        spices: [
            'Cinnamon',
            'Smoked paprika',
            'Thyme'
        ],
        vegetables: [
            'Bok Choy',
            'Broccolini',
            'Asparagus'
        ],
        meatCategory: 'Beef',
        meatTypes: [
            'Lamb',
            'Bison',
            'Cattle'
        ],
        chefs: [
            'Björn Frantzén (Sweden)',
            'René Redzepi (Denmark)'
        ],
        orgIds: [
            '38f735fc-db4f-4361-93a5-badc4aa0742b',
            '384d5ce9-4277-4d9d-bd65-075ab8cc2123'
        ],
        id: 'my-recipe-3'
    }
];

export const chefList = [
    'Dabiz Muñoz (Spain)',
    'Albert Adrià (Spain)',
    'Ana Roš (Slovenia)',
    'René Redzepi (Denmark)',
    'Andoni Luis Aduriz (Spain)',
    'Rasmus Munk (Denmark)',
    'Mateu Casañas, Oriol Castro',
    'Eduard Xatruch (Spain)',
    'Joan Roca (Spain)',
    'Junghyun Park (South Korea)',
    'Rodolfo Guzmán (Chile)',
    'Massimo Bottura (Italy)',
    'Eric Vildgaard (Denmark)',
    'Ángel León (Spain)',
    'Mitsuharu Tsumura (Peru)',
    'Virgilio Martínez (Peru)',
    'Danny Yip (China)',
    'Alex Atala (Brazil)',
    'Grant Achatz (USA)',
    'Manu Buffara (Brazil)',
    'Javier & Sergio Torres (Spain)',
    'Anne-Sophie Pic (France)',
    'Riccardo Camanini (Italy)',
    'Jorge Vallejo (Mexico)',
    'Yoshihiro Narisawa (Japan)',
    'Daniel Calvert (UK)',
    'Eneko Atxa (Spain)',
    'Himanshu Saini (India)',
    'Enrico Crippa (Italy)',
    'Natsuko Shoji (Japan)',
    'Quique Dacosta (Spain)',
    'Mauro Colagreco (Argentina)',
    'Josh Niland (Australia)',
    'José Avillez (Portugal)',
    'Álvaro Clavijo (Colombia)',
    'Vicky Cheng (China)',
    'Andreas Caminada (Switzerland)',
    'Dominique Crenn (France)',
    'Santiago Lastra (Mexico)',
    'Grégoire Berger (France)',
    'Alberto Landgraf (Brazil)',
    'Björn Frantzén (Sweden)',
    'Diego Guerrero (Spain)',
    'Rasmus Kofoed (Denmark)',
    'Vaughan Mabee (New Zealand)',
    'Leonor Leo Espinosa (Colombia)',
    'Agustin Balbi (Argentina)',
    'Alain Passard (France)',
    'Paolo Casagrande (Italy)',
    'Heston Blumenthal (UK)',
    'Jonnie Boer (Netherlands)',
];
