import { getUserService } from '../../interface/services';
import { IEvent } from './Events';
import { IFindingModelAlertTypeString } from '../../components/Findings/Findings.interface';
import { AssetTypes } from '../../assets/assets.const';

export const hasPermissionToEventActions = () : boolean => {
    const user = getUserService().getUser();
    const calculatedPermissions = user.calculatedPermissions;
    const permissions = user.permissions;
    const isSuperUser = user.isSuperUser;
    return !!(calculatedPermissions['alertActions']?.length || permissions['alertActions']?.length || isSuperUser);
};

export const isServerlessExclusion = (event : IEvent) => {
    const isSecurityEvent = event?.alertType === IFindingModelAlertTypeString.SecurityEvent;
    const isFindingLambdaType = event?.entityTypeByEnvironmentType === AssetTypes.AWS_LAMBDA;
    const isFindingFunctionAppType = event?.entityTypeByEnvironmentType === AssetTypes.AZURE_FUNCTIONAPP;
    return isSecurityEvent && (isFindingLambdaType || isFindingFunctionAppType );
};