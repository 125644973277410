import { IItemPermissions } from './account';

export type GenericObject<T> = { [key: string]: T };

export type AnyFunction<T> = (...args: any[]) => T;

export interface IComponentData {
    component: React.FC,
    componentProps?: any
}

export interface ISelectOption {
    label: string | null;
    value: string | null;
}

export enum LoadingState {
    IS_INITIALIZING = 'isInitializing',
    IS_LOADING = 'isLoading',
    LOADING_SUCCEEDED = 'loadingSucceeded',
    LOADING_FAILED = 'loadingFailed',
}

export interface IColumnUsageDef {
    id: string;
    colDefOverride?: GenericObject<any>;
    permission?: IItemPermissions;
}

export interface IActionUsageDef {
    id: string;
    actionDefOverride?: GenericObject<any>;
    permission?: IItemPermissions;
}

export interface IFieldInfo {
    name: string,
    value: any,
}

export interface IValueCount {
    value: string;
    count: number;
}

export enum SortOrder {
    ascending,
    descending,
}

export interface IWebAppFilterParams {
    filter?: {
        fields?: IWebAppFilter[];
        freeTextPhrase?: string;
        tags?: IWebAppKeyValue[]
    };
}

interface IWebAppFilter {
    name: string,
    value: string
}

interface IWebAppKeyValue {
    key: string,
    value: string
}

export enum SortDirection {
    ASC = 'asc',
    DESC = 'desc',
}