export enum CommonFilterNames {
    FREE_TEXT = 'free text',
    ADD_FILTER = 'add filter',
    CLEAR_ALL = 'clear all',
    SAVED_FILTERS = 'saved filters',
    RECENTLY_USED = 'recently used',
}

export enum CommonFilterEnum {
    ADD_FILTER = CommonFilterNames.ADD_FILTER,
    SAVED_FILTERS = CommonFilterNames.SAVED_FILTERS,
    RECENTLY_USED = CommonFilterNames.RECENTLY_USED,
    CLEAR_ALL = CommonFilterNames.CLEAR_ALL,
}