import * as React from 'react';
import { SVGProps, Ref, forwardRef } from 'react';
const SvgWarning = (
  props: SVGProps<SVGSVGElement>,
  ref: Ref<SVGSVGElement>
) => (
  <svg
    viewBox="0 0 16 16"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    ref={ref}
    {...props}
  >
    <path
      d="M6.612 1.717.755 13.041a1 1 0 0 0 .888 1.459h11.714a1 1 0 0 0 .888-1.46L8.388 1.718a1 1 0 0 0-1.776 0ZM7.5 5.5v4"
      stroke="currentColor"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M7.8 12a.3.3 0 1 1 0-.019"
      stroke="currentColor"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </svg>
);
const ForwardRef = forwardRef(SvgWarning);
export default ForwardRef;
