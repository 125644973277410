import { Addin } from 'common/extensibility/AddinRegistry';
import globalAddinContainer, { buildPath } from '../extensibility/AddinContainer';
import {
    AwsEnvironmentToolbarActions,
    AwsEnvironmentData,
    AwsEnvironmentDataExtraData,
    AwsEnvironmentTab,
    PATH_ENVIRONMENT_PAGE,
    PATH_ENVIRONMENT_PAGE_ACTION,
    PATH_ENVIRONMENT_PAGE_CHIP,
    PATH_ENVIRONMENT_PAGE_DETAILS_PANEL,
    PATH_ENVIRONMENT_PAGE_TAB,
} from 'common/module_interface/assets/AwsEnvironment';
import { BlockInfoProps } from 'common/design-system/components-v2/BlockInfo/BlockInfo.types';
import { EnvironmentChip } from '../module_interface/assets/Environments';

export interface AwsEnvironmentAddingBase<T> {
    id: string;
    customLoadingLabel?: string; // Relevant for chips only at the moment
    isRelevant?: (awsData: AwsEnvironmentData, awsExraData: AwsEnvironmentDataExtraData) => boolean;
    getValue: (awsData: AwsEnvironmentData, awsExraData: AwsEnvironmentDataExtraData) => Promise<T> | T;
}

function addDetailsPanelItems(item: Addin<AwsEnvironmentAddingBase<BlockInfoProps>>[]) {
    globalAddinContainer.add(buildPath(PATH_ENVIRONMENT_PAGE, PATH_ENVIRONMENT_PAGE_DETAILS_PANEL), item);
}

function getDetailsPanelItems(): AwsEnvironmentAddingBase<BlockInfoProps>[] {
    return globalAddinContainer.get(buildPath(PATH_ENVIRONMENT_PAGE, PATH_ENVIRONMENT_PAGE_DETAILS_PANEL));
}

function addTabs(item: Addin<AwsEnvironmentAddingBase<AwsEnvironmentTab>>[]) {
    globalAddinContainer.add(buildPath(PATH_ENVIRONMENT_PAGE, PATH_ENVIRONMENT_PAGE_TAB), item);
}

function getTabs(): AwsEnvironmentAddingBase<AwsEnvironmentTab>[] {
    return globalAddinContainer.get(buildPath(PATH_ENVIRONMENT_PAGE, PATH_ENVIRONMENT_PAGE_TAB));
}

function addActions(item: Addin<AwsEnvironmentAddingBase<AwsEnvironmentToolbarActions>>[]) {
    globalAddinContainer.add(buildPath(PATH_ENVIRONMENT_PAGE, PATH_ENVIRONMENT_PAGE_ACTION), item);
}

function getActions(): AwsEnvironmentAddingBase<AwsEnvironmentToolbarActions>[] {
    return globalAddinContainer.get(buildPath(PATH_ENVIRONMENT_PAGE, PATH_ENVIRONMENT_PAGE_ACTION));
}

function addChips(item: Addin<AwsEnvironmentAddingBase<EnvironmentChip>>[]) {
    globalAddinContainer.add(buildPath(PATH_ENVIRONMENT_PAGE, PATH_ENVIRONMENT_PAGE_CHIP), item);
}

function getChips(): AwsEnvironmentAddingBase<EnvironmentChip>[] {
    return globalAddinContainer.get(buildPath(PATH_ENVIRONMENT_PAGE, PATH_ENVIRONMENT_PAGE_CHIP));
}

export const AwsEnvironmentAddinRegistry = {
    addDetailsPanelItems,
    getDetailsPanelItems,
    addTabs,
    getTabs,
    addActions,
    getActions,
    addChips,
    getChips,
};