export function getExposureColor(score?: number): string {
    if (score === undefined || score === null) {
        return 'var(--color-info-darker)';
    }
    if (score < 2) {
        return 'var(--color-success-default)';
    }
    if (score < 4) {
        return 'var(--color-warning-lighter)';
    }
    if (score < 6) {
        return 'var(--color-warning-default)';
    }
    if (score < 8) {
        return 'var(--color-danger-default)';
    }
    if (score <= 10) {
        return 'var(--color-danger-darker)';
    }
    return 'var(--color-info-darker)';
}
