import * as React from 'react';
import { SVGProps, Ref, forwardRef } from 'react';
const SvgDownload = (
  props: SVGProps<SVGSVGElement>,
  ref: Ref<SVGSVGElement>
) => (
  <svg
    viewBox="0 0 16 16"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    ref={ref}
    {...props}
  >
    <path
      d="M7.5 10.5v-9m0 9 3-3m-3 3-3-3M.5 11.5v1a2 2 0 0 0 2 2h10a2 2 0 0 0 2-2v-1"
      stroke="currentColor"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </svg>
);
const ForwardRef = forwardRef(SvgDownload);
export default ForwardRef;
