import React, { forwardRef, useState } from 'react';
import CopiableLineStyled from './CopiableLine.styled';
import { CopiableLineProps } from './CopiableLine.types';

const CopiableLine: React.FunctionComponent<CopiableLineProps> = forwardRef<HTMLDivElement, CopiableLineProps>((props, ref) => {
    const { value, children } = props;
    const [isTextCopied, setIsTextCopied] = useState(false);

    const isValid = (info?: string | number) => {
        if (info === null || !info) {
            return 'N/A';
        }

        if (typeof info === 'string') {
            return info ? info : 'N/A';
        }
        return info;
    };

    const copyToClipboard = async (info?: string | number) => {
        if (isTextCopied) return;
        const infoText = isValid(info).toString();
        navigator.clipboard.writeText(infoText).then(() => {
            setIsTextCopied(true);
            setTimeout(() => {
                setIsTextCopied(false);
            }, 700);
        });
    };

    return (
        <CopiableLineStyled.CopiableLineWrapper ref={ref}>
            {children}
            <CopiableLineStyled.CopyIconWrapper>
                <CopiableLineStyled.IconButtonOnHover
                    iconProps={{ name: isTextCopied ? 'check' : 'copy', size: 10 }}
                    size="small"
                    circleShape={false}
                    isCopied={isTextCopied}
                    onClick={() => copyToClipboard(value)}
                />
            </CopiableLineStyled.CopyIconWrapper>
        </CopiableLineStyled.CopiableLineWrapper>
    );
});

CopiableLine.displayName = 'CopiableLine';
export default CopiableLine;
