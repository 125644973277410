import * as React from 'react';
import { SVGProps, Ref, forwardRef } from 'react';
const SvgShine = (props: SVGProps<SVGSVGElement>, ref: Ref<SVGSVGElement>) => (
  <svg
    viewBox="0 0 16 16"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    ref={ref}
    {...props}
  >
    <path
      d="m6 4 .365.866a8 8 0 0 0 4.269 4.27l.866.364-.866.365a8 8 0 0 0-4.27 4.269L6 15l-.365-.866a8 8 0 0 0-4.269-4.27L.5 9.5l.866-.365a8 8 0 0 0 4.27-4.269L6 4Z"
      stroke="#CF0FEE"
      strokeLinejoin="round"
    />
    <path
      d="m12.5.5.415.985a3 3 0 0 0 1.6 1.6l.985.415-.985.415a3 3 0 0 0-1.6 1.6L12.5 6.5l-.415-.985a3 3 0 0 0-1.6-1.6L9.5 3.5l.985-.415a3 3 0 0 0 1.6-1.6L12.5.5Z"
      stroke="#7371E8"
      strokeLinejoin="round"
    />
    <path
      d="m13.5 11.5.276.656a2 2 0 0 0 1.068 1.068l.656.276-.656.276a2 2 0 0 0-1.068 1.068l-.276.656-.276-.656a2 2 0 0 0-1.068-1.068L11.5 13.5l.656-.276a2 2 0 0 0 1.068-1.068l.276-.656Z"
      stroke="#E954DA"
      strokeLinejoin="round"
    />
  </svg>
);
const ForwardRef = forwardRef(SvgShine);
export default ForwardRef;
