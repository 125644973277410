import { buildPath, globalAddinContainer } from '../../../extensibility/AddinContainer';
import { Addin } from '../../../extensibility/AddinRegistry';
import { IProtectedAssetDetailProvider } from '../../assets/ProtectedAssets';
import { InfoPaneFieldProps } from '../../../components/InfoPane/InfoPane';
import { IIssueViewerPanelProvider } from './Issues.interface';

export const PATH_ISSUE_VIEWER_PANELS = buildPath('risk management', 'issues', 'issue-viewer');
const PATH_ISSUE_VIEWER_PANEL_FIELDS = buildPath(PATH_ISSUE_VIEWER_PANELS, 'fields');

export const IssueViewerRegistry = {
    addPanelProvider: (content: IIssueViewerPanelProvider, position: number) => {
        globalAddinContainer.add<IIssueViewerPanelProvider>(PATH_ISSUE_VIEWER_PANELS, [{
            id: content.panelId,
            position,
            content,
            permission: content.permission,
        }]);
    },

    getPanelProviders: (): IIssueViewerPanelProvider[] => {
        return globalAddinContainer.get<IIssueViewerPanelProvider>(PATH_ISSUE_VIEWER_PANELS);
    },

    addFieldAddins: (fieldAddins: Addin<IProtectedAssetDetailProvider<InfoPaneFieldProps>>[], panelId: string) => {
        const fullPath = buildPath(PATH_ISSUE_VIEWER_PANEL_FIELDS, panelId);
        globalAddinContainer.add<IProtectedAssetDetailProvider<InfoPaneFieldProps>>(fullPath, fieldAddins);
    },

    getFields: (panelId: string): IProtectedAssetDetailProvider<InfoPaneFieldProps>[] => {
        const fullPath = buildPath(PATH_ISSUE_VIEWER_PANEL_FIELDS, panelId);
        return globalAddinContainer.get<IProtectedAssetDetailProvider<InfoPaneFieldProps>>(fullPath);
    },
};
