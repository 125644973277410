import styled from 'styled-components';
import Stack from '../Stack';

const StepperBox = styled(Stack)`
    max-width: 196px;
    width: 100%;
    height: 100%;
    background-color: ${({ theme }) => theme.palette.surface.tertiary};
    border-radius: ${({ theme }) => `${theme.border.radius(2)}${theme.units}`};
`;

const StepperStyles = {
    StepperBox,
};

export default StepperStyles;