import { forwardRef } from 'react';
import { ICounterProps } from './Counter.types';
import Tooltip from '../Tooltip/Tooltip';
import Chip from '../Chip/Chip';

const Counter = forwardRef<HTMLDivElement, ICounterProps>((props, ref) => {
    const { count, size = 'sm', context = 'info', ...rest } = props;
    const fixedCount = count > 999 ? '999+' : count.toString();

    return (
        <Tooltip content={count.toLocaleString()} disabled={count <= 999} {...rest}>
            <Chip label={fixedCount} ref={ref} size={size} context={context} disableInteraction />
        </Tooltip>
    );
});
Counter.displayName = 'Counter';

export default Counter;
