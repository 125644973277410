import * as React from 'react';
import { SVGProps, Ref, forwardRef } from 'react';
const SvgStatusError = (
  props: SVGProps<SVGSVGElement>,
  ref: Ref<SVGSVGElement>
) => (
  <svg
    viewBox="0 0 16 16"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    ref={ref}
    {...props}
  >
    <path
      d="M8 0a8 8 0 1 0 8 8 8.009 8.009 0 0 0-8-8Zm2.828 9.886a.667.667 0 0 1 0 .943.684.684 0 0 1-.943 0L8 8.943l-1.885 1.886a.674.674 0 0 1-.943 0 .667.667 0 0 1 0-.943L7.057 8 5.172 6.114a.667.667 0 1 1 .943-.943L8 7.057l1.885-1.886a.667.667 0 1 1 .943.943L8.943 8l1.885 1.886Z"
      fill="#C51616"
    />
  </svg>
);
const ForwardRef = forwardRef(SvgStatusError);
export default ForwardRef;
