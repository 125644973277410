import * as React from 'react';
import { SVGProps, Ref, forwardRef } from 'react';
const SvgAlibaba = (
  props: SVGProps<SVGSVGElement>,
  ref: Ref<SVGSVGElement>
) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    viewBox="0 0 64 40"
    ref={ref}
    {...props}
  >
    <path
      data-name="Path 16893"
      d="M8.7.227a11.549 11.549 0 0 0-7.35 5.484C.048 8.265 0 8.893 0 19.644c0 5.737.1 10.462.24 11.2A10.963 10.963 0 0 0 8.894 39.5c1.326.24 18.585.362 18.585.1 0-.193-1.47-5.978-1.543-6.05-.024-.048-3.086-.723-6.8-1.52-8.654-1.88-8.4-1.808-9.112-2.676L9.4 28.6V11.1l.627-.76c.723-.868.458-.8 9.112-2.676l6.8-1.52C26.01 6.095 27.48.309 27.48.127c0-.227-17.62-.12-18.78.12Zm27.94.036C36.664.7 38.014 6 38.16 6.145c.048.072 3.35.82 7.3 1.663s7.473 1.7 7.786 1.88a2.964 2.964 0 0 1 1.041 1.012c.386.675.434 1.4.434 9.16s-.048 8.485-.434 9.16a3.17 3.17 0 0 1-1.037 1.012c-.313.17-3.8 1.012-7.786 1.88l-7.3 1.675c-.145.145-1.495 5.448-1.52 5.882 0 .265 1.736.313 9.353.24 10.5-.1 10.63-.12 13.306-1.928a11 11 0 0 0 4.146-5.424C64 30.742 64 30.6 64 19.87S64 9 63.445 7.383A11.01 11.01 0 0 0 59.3 1.959C56.623.153 56.5.127 45.993.032 38.376-.04 36.64 0 36.64.272Zm-10.76 19.1a1.74 1.74 0 0 0-.048.8c.072.4.506.434 6.17.506l6.075.048v-1.69H32.06c-5.038 0-6.075.048-6.17.337Z"
      fill="#ee722e"
    />
  </svg>
);
const ForwardRef = forwardRef(SvgAlibaba);
export default ForwardRef;
