import * as React from 'react';
import { SVGProps, Ref, forwardRef } from 'react';
const SvgStorage = (
  props: SVGProps<SVGSVGElement>,
  ref: Ref<SVGSVGElement>
) => (
  <svg
    viewBox="0 0 16 16"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    ref={ref}
    {...props}
  >
    <path
      d="M.5 3.5v11a1 1 0 0 0 1 1h13a1 1 0 0 0 1-1v-11m-15 0L3.207.793A1 1 0 0 1 3.914.5h8.172a1 1 0 0 1 .707.293L15.5 3.5m-15 0h15"
      stroke="currentColor"
      strokeLinejoin="round"
    />
    <path
      d="M.5 11.5h15m-15-4h15"
      stroke="currentColor"
      strokeLinecap="round"
    />
    <path
      d="M2.5 5.5h4m5 0h1M2.5 9.5h4m5 0h1M2.5 13.5h4m5 0h1"
      stroke="currentColor"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </svg>
);
const ForwardRef = forwardRef(SvgStorage);
export default ForwardRef;
