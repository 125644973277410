import React from 'react';
import { ICellRendererParams } from 'ag-grid-enterprise';
import { Icon } from '@dome9/berries/react-components';
import { Stack } from 'common/design-system/components-v2';

interface iHeaderWithInfoTooltipRendererProps extends ICellRendererParams {
    text: string;
}

const HeaderWithInfoTooltipRenderer: React.FC<iHeaderWithInfoTooltipRendererProps> = (params) => {
    return (
        <Stack direction={'row'} alignItems={'center'} fullHeight={true}>
            {params.text} <Icon name={'info'} className={'ml-6'} size={10}></Icon>
        </Stack>
    );
};

export default HeaderWithInfoTooltipRenderer;
