import * as React from 'react';
import { SVGProps, Ref, forwardRef } from 'react';
const SvgEdit = (props: SVGProps<SVGSVGElement>, ref: Ref<SVGSVGElement>) => (
  <svg
    viewBox="0 0 16 16"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    ref={ref}
    {...props}
  >
    <path
      d="m6 13-1.304 1.304a1 1 0 0 1-.465.263l-2.114.529a1 1 0 0 1-1.213-1.213l.529-2.114a1 1 0 0 1 .263-.465L3 10m3 3-3-3m3 3 7.5-7.5M3 10l7.5-7.5m0 0 1.293-1.293a1 1 0 0 1 1.414 0l1.586 1.586a1 1 0 0 1 0 1.414L13.5 5.5m-3-3 3 3"
      stroke="currentColor"
    />
  </svg>
);
const ForwardRef = forwardRef(SvgEdit);
export default ForwardRef;
