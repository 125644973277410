import * as React from 'react';
import { SVGProps, Ref, forwardRef } from 'react';
const SvgEvents = (props: SVGProps<SVGSVGElement>, ref: Ref<SVGSVGElement>) => (
  <svg
    viewBox="0 0 16 16"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    ref={ref}
    {...props}
  >
    <path
      d="M5.5 5.5h5m-5 3h5m-5 3h5"
      stroke="currentColor"
      strokeLinecap="round"
    />
    <rect x={6} y={0.5} width={4} height={2} rx={1} stroke="currentColor" />
    <path
      d="M6 1.5H3.5a1 1 0 0 0-1 1v12a1 1 0 0 0 1 1h9a1 1 0 0 0 1-1v-12a1 1 0 0 0-1-1H10"
      stroke="currentColor"
    />
  </svg>
);
const ForwardRef = forwardRef(SvgEvents);
export default ForwardRef;
