import * as React from 'react';
import { SVGProps, Ref, forwardRef } from 'react';
const SvgStatusUnsecured = (
  props: SVGProps<SVGSVGElement>,
  ref: Ref<SVGSVGElement>
) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    viewBox="0 0 12 16"
    fill="none"
    ref={ref}
    {...props}
  >
    <path
      d="M0 1.883c2.182 0 4.364-1.066 6-2.133C7.636.817 9.818 1.883 12 1.883v6.934c0 4.266-6 6.933-6 6.933s-6-2.667-6-6.933V1.883Z"
      fill="#FF401A"
    />
    <path
      d="M0 2.133c2.182 0 4.364-1.066 6-2.133 1.636 1.067 3.818 2.133 6 2.133v6.934C12 13.333 6 16 6 16s-6-2.667-6-6.933V2.133Z"
      fill="#FF401A"
    />
    <path
      d="M3.5 5 6 7.5m0 0L8.5 10M6 7.5 8.5 5M6 7.5 3.5 10"
      stroke="#fff"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </svg>
);
const ForwardRef = forwardRef(SvgStatusUnsecured);
export default ForwardRef;
