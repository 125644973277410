import { buildPath } from './AddinContainer';

export const APPLICATION_REDUCERS = buildPath('application', 'reducers'); //Register your reducers with this path
export const REACT_PAGE_ROUTE = buildPath('router', 'reactPages'); //Register pages to be added to the React router

//Main menu
export const MENU_ITEMS = buildPath('mainMenu', 'items');

//SideBar

export const SIDEBAR_COMPONENTS = buildPath('subMenu', 'components');

//Global search

export const GLOBAL_SEARCH_SEARCHERS = buildPath('globalSearch', 'searchers');

/***
 * Events
 */

export const AFTER_LOGIN_EVENT = buildPath('events', 'after', 'login');
