import { DrawerRegistry } from '../Drawer/DrawerRegistry';
import { IDrawerContent, IDrawerHandlersIdMap } from '../Drawer/Drawer.interface';
import { DrawerExampleTypeViewer, DrawerLargeExampleTextViewer } from './DrawerExampleComponents';
import { IReactPageRoute } from '../../../interface/routing';
import { globalAddinContainer } from '../../../extensibility/AddinContainer';
import { REACT_PAGE_ROUTE } from '../../../extensibility/WellKnownPaths';
import { addMainMenuItem } from '../../../helpers/menu';
import { isAfPageDrawerExample } from '../../../utils/debugUtils';
import { DrawerExamplePage } from './DrawerExamplePage';
import { createElement } from 'react';
import { DrawerBus } from '../Drawer/DrawerBus';
import { Icon } from 'common/design-system/components-v2';

const DRAWER_EXAMPLE_PAGE_URL = '/risk-management/drawer-example';

export interface IExampleTypeViewerData {
    typeByPlatform: string;
    title: string;
    icon?: string;
}

export enum ExampleListOperationEnum {
    REVERSE,
    RESTART,
}

export const DRAWER_EXAMPLE_TYPE_VIEWER = {
    key: 'DRAWER_EXAMPLE_TYPE_VIEWER_KEY',
    eventTypes: {
        delete: 'delete',
    },
};

export const DRAWER_LARGE_TEXT_VIEWER = {
    key: 'DRAWER_LARGE_TEXT_VIEWER_KEY',
    eventTypes: {
        reverse: 'reverse',
        restart: 'restart',
    },
};

export const initializeDrawerExamples = () => {
    DrawerRegistry.addContentProvider({
        id: DRAWER_EXAMPLE_TYPE_VIEWER.key,
        getDrawerContent: (typeData: IExampleTypeViewerData, handlersIdMap?: IDrawerHandlersIdMap): Promise<IDrawerContent> => {
            const onDelete = (typeByPlatform: string) => {
                DrawerBus.sendEvent(handlersIdMap![DRAWER_EXAMPLE_TYPE_VIEWER.eventTypes.delete], typeByPlatform);
            };

            const result = {
                title: typeData.title,
                icon: createElement(Icon,{ size: 32, vendorNameOrPath: typeData.icon || 'web' }),
                component: DrawerExampleTypeViewer,
                componentProps: { typeByPlatform: typeData.typeByPlatform, onDelete },
            };
            return Promise.resolve(result);
        },
    });

    DrawerRegistry.addContentProvider({
        id: DRAWER_LARGE_TEXT_VIEWER.key,
        getDrawerContent: (text: string, handlersIdMap?: IDrawerHandlersIdMap): Promise<IDrawerContent> => {

            const onRestart = (date: Date) => {
                DrawerBus.sendEvent(handlersIdMap![DRAWER_LARGE_TEXT_VIEWER.eventTypes.restart], {
                    listOperation: ExampleListOperationEnum.RESTART,
                    date,
                });
            };

            const onReverse = (date: Date) => {
                DrawerBus.sendEvent(handlersIdMap![DRAWER_LARGE_TEXT_VIEWER.eventTypes.reverse], {
                    listOperation: ExampleListOperationEnum.REVERSE,
                    date,
                });
            };

            const result: IDrawerContent = {
                title: text,
                icon: 'web',
                component: DrawerLargeExampleTextViewer,
                componentProps: { text, onRestart, onReverse },
            };
            return Promise.resolve(result);
        },
        options: {
            hasHeaderLineSeparator: true,
            width: 'xl'
        }
    });

    addMainMenuItem({
        id: 'drawer-example',
        position: 0,
        icon: 'microscope',
        label: 'Drawer Examples',
        permission: () => isAfPageDrawerExample(),
        sections: [
            {
                id: 'default',
                position: 0,
                items: [
                    {
                        id: 'drawer example',
                        position: 0,
                        label: 'Drawer Example',
                        state: DRAWER_EXAMPLE_PAGE_URL,
                        permission: () => isAfPageDrawerExample(),
                    },
                ],
            },
        ],
    });

    const examplePage = (): IReactPageRoute => {
        return {
            condition: isAfPageDrawerExample(),
            component: DrawerExamplePage,
            path: DRAWER_EXAMPLE_PAGE_URL,
        };
    };

    globalAddinContainer.add(REACT_PAGE_ROUTE, [
        { id: 'DRAWER_EXAMPLE_PAGE', content: examplePage },
    ]);
};
