import React from 'react';
import { SeverityLevelIconPropsLevel } from '../../../design-system/components-v2/LevelIcon/LevelIcon.types';
import { AllIconsName } from '../../../design-system/components-v2/Icon/Icon.types';
import { ICellRendererParams } from 'ag-grid-enterprise';
import { calcGroupedColLeafIndent, getGroupedCols, TABLE_GROUP_COLUMN_ID } from '../../../utils/tableUtils';
import { ColDef } from 'ag-grid-community/dist/lib/entities/colDef';
import IconLinkCellRenderer, { IIconLinkCellProps } from './IconLinkCellRenderer';

export interface IRuleTitleCellComponentProps {
    params: ICellRendererParams;
    ruleTitle: string;
    ruleColId: string;
    severityLevel?: number;
    drawerOpener: () => void;
    getSeverityKey: (severity?: number) => SeverityLevelIconPropsLevel;
    iconName: AllIconsName;
    customOnClick?: () => void;
}

export const RuleTitleCellComponent: React.FC<IRuleTitleCellComponentProps> =
({ params, ruleTitle, ruleColId, severityLevel, drawerOpener, getSeverityKey, iconName, customOnClick }) => {
    const isAutoGroupCol = (params.colDef?.colId === TABLE_GROUP_COLUMN_ID);
    let onClick: (() => void) | undefined;
    let style: React.CSSProperties | undefined;
    let severityKey: SeverityLevelIconPropsLevel;
    if (params.node.group) {
        if (!isAutoGroupCol) {
            return null;
        }
        severityKey = getSeverityKey();
    } else {
        if (isAutoGroupCol) {
            const colDefs: ColDef[] = getGroupedCols(params.columnApi);
            if (colDefs.every(colDef => colDef.colId !== ruleColId)) {
                return null;
            }
            const indent = calcGroupedColLeafIndent(params.columnApi);
            style = indent === undefined ? undefined : { paddingLeft: `${indent}px` };
        }
        onClick = customOnClick ?? drawerOpener;
        severityKey = getSeverityKey(severityLevel);
    }

    const options: IIconLinkCellProps = {
        isLink: !!onClick,
        value: ruleTitle,
        style,
        onClick,
        levelIconProps: {
            category: 'severity',
            level: severityKey,
            iconProps: {
                name: iconName,
            },
            size: 'md',
        }
    };
    return <IconLinkCellRenderer {...options} />;
};