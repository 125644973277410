import React from 'react';
import { LinkProps } from './Link.types';
import LinkStyled from './Link.styled';
import CopiableLine from '../CopiableLine/CopiableLine';
import IconButton from '../IconButton/IconButton';

const Link = React.forwardRef<HTMLAnchorElement, LinkProps>((props, ref) => {
    const { internalUrl, externalUrl, removeExternalIcon = false, copyText, children, dataAid } = props;

    React.useEffect(() => {
        if (!internalUrl && !externalUrl) {
            console.error('internalUrl or externalUrl is required.');
        }
        if (internalUrl && externalUrl) {
            console.warn('Both internalUrl and externalUrl are provided. Only internalUrl will be used.');
        }
    }, [internalUrl, externalUrl]);

    const Content = React.useMemo(() => {
        if (internalUrl) {
            return (
                <LinkStyled.StyledInnerA href={internalUrl} ref={ref} dataAid={dataAid}>
                    {children}
                </LinkStyled.StyledInnerA>
            );
        }
        if (externalUrl) {
            return (
                <LinkStyled.StyledOuterA ref={ref} href={externalUrl} target='_blank' rel="noreferrer" data-aid={dataAid}>
                    {children}
                    {!removeExternalIcon && <IconButton iconProps={{ name: 'externalLink', size: 10 }} size='small' />}
                </LinkStyled.StyledOuterA>
            );
        }
        return null;

    }, [children, internalUrl, externalUrl, ref, dataAid, removeExternalIcon]);

    if (copyText) {
        return (
            <CopiableLine value={copyText}>
                {Content}
            </CopiableLine>
        );
    }

    return Content;
});

Link.displayName = 'Link';

export default Link;
