import { FC, useRef } from 'react';
import { FormStyled } from '../../CustomForm.styled';
import { IFullErrorInfo } from '../../CustomForm.interface';

export interface IErrorComponentProps {
    showErrors: boolean;
    errorInfo?: IFullErrorInfo;
}

export const ErrorComponent: FC<IErrorComponentProps> = ({ showErrors, errorInfo }) => {
    const ref = useRef<HTMLInputElement>(null);
    if (errorInfo) {
        errorInfo.componentRef = ref;
    }

    if (!errorInfo) {
        return null;
    }

    return (
        <FormStyled.ErrorContainer ref={ref}>
            { showErrors && <FormStyled.Error>{errorInfo.errorMessage}</FormStyled.Error> }
        </FormStyled.ErrorContainer>
    );
};
