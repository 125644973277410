import * as React from 'react';
import { SVGProps, Ref, forwardRef } from 'react';
const SvgRobot = (props: SVGProps<SVGSVGElement>, ref: Ref<SVGSVGElement>) => (
  <svg
    viewBox="0 0 16 16"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    ref={ref}
    {...props}
  >
    <path
      d="M11.5 4.5h-7a2 2 0 0 0-2 2v5a2 2 0 0 0 2 2h7a2 2 0 0 0 2-2v-5a2 2 0 0 0-2-2Z"
      stroke="currentColor"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <circle
      cx={5.5}
      cy={7.5}
      r={1}
      stroke="currentColor"
      strokeLinecap="round"
    />
    <circle
      cx={10.5}
      cy={7.5}
      r={1}
      stroke="currentColor"
      strokeLinecap="round"
    />
    <path
      d="M8 4.5v-2"
      stroke="currentColor"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <circle cx={8} cy={1.5} r={1} stroke="currentColor" strokeLinecap="round" />
    <path
      d="M2.5 6.5H2A1.5 1.5 0 0 0 .5 8v1A1.5 1.5 0 0 0 2 10.5h.5m11-4h.5A1.5 1.5 0 0 1 15.5 8v1a1.5 1.5 0 0 1-1.5 1.5h-.5M5.5 10.5v0c.306.613.933 1 1.618 1h1.764c.685 0 1.312-.387 1.618-1v0"
      stroke="currentColor"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </svg>
);
const ForwardRef = forwardRef(SvgRobot);
export default ForwardRef;
