import React from 'react';
import { ICellRendererParams } from 'ag-grid-enterprise';
import IconLinkCellRenderer, { IIconLinkCellProps } from 'common/components/ag-grid/Renderers/IconLinkCellRenderer';
import { CellToClipboardHandler } from 'common/components/ProtectedAssets/ProtectedAssetsTable';
import { getProtectedAssetsService } from '../../../module_interface/assets/ProtectedAssets';

const EventEntityTypeCellRenderer: React.FC<ICellRendererParams> = (params) => {
    const { entityType, entityDome9Id } = params.data;
    if (!entityType || !entityDome9Id) {
        return null;
    }
    const asset = getProtectedAssetsService().getProtectedAssetByTypeAndDome9Id(entityType, entityDome9Id);

    const options: IIconLinkCellProps = {
        isLink: false,
        value: asset?.displayName || entityType,
    };

    CellToClipboardHandler(params, String(options.value));
    return <IconLinkCellRenderer {...options} />;
};

export default EventEntityTypeCellRenderer;
