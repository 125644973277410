import * as React from 'react';
import { SVGProps, Ref, forwardRef } from 'react';
const SvgIso27001 = (
  props: SVGProps<SVGSVGElement>,
  ref: Ref<SVGSVGElement>
) => (
  <svg
    id="ISO_27001_svg__svg2"
    xmlns="http://www.w3.org/2000/svg"
    x={0}
    y={0}
    viewBox="0 0 16 14.9"
    xmlSpace="preserve"
    ref={ref}
    {...props}
  >
    <style>
      {'.ISO_27001_svg__st0{fill:#2a3e92}.ISO_27001_svg__st1{fill:#fff}'}
    </style>
    <g
      id="ISO_27001_svg__g11"
      transform="matrix(1.25 0 0 -1.25 -260.665 468.063)"
    >
      <g id="ISO_27001_svg__g2537" transform="translate(8 -8)">
        <path
          id="ISO_27001_svg__path45"
          className="ISO_27001_svg__st0"
          d="M210.3 378.2c.1-.1.1-.3.1-.4v-.9c0-.1-.1-.2-.2-.3-.2-.1-.4-.1-.6 0-.1.1-.2.3-.2.4 0 .3 0 .7.1 1 .1.2.3.2.4.2.1.2.2.2.4 0z"
        />
        <path
          id="ISO_27001_svg__path47"
          className="ISO_27001_svg__st0"
          d="M200.5 370.5v11.9h12.8v-11.9h-12.8zm6.7 10.8H206.6c-1.1-.1-2.2-.7-2.9-1.7h.2c.1.2.2.3.4.5.1.1.2.2.4.3.1-.1.3-.1.4-.2-.1-.2-.3-.4-.4-.6h.1c.1.2.2.4.4.5.2-.1.4-.2.6-.2l-.1-.3h.1c0 .1.1.2.1.3.3-.1.6-.1.9-.1v-.1h.1v.1c.3 0 .6 0 .8.1l.1-.2h.1l-.1.3c.2.1.4.1.6.2.1-.2.3-.3.4-.5h.1c-.1.1-.1.2-.2.3-.1.1-.1.2-.2.3l.3.2c.3-.2.6-.5.8-.8h.2c-.4.9-1.4 1.5-2.6 1.6zm.5-.2c-.4-.3-1.1-.3-1.5-.1 0 0-.1 0-.1.1.5.1 1.1.1 1.6 0zm1.3-.6-.3-.2c-.3.3-.6.5-.9.7l.1.1c.4-.2.8-.4 1.1-.6zm-3 .4c-.3-.2-.6-.4-.9-.7-.1 0-.2.1-.4.2.3.2.6.4 1 .5.2.1.2.1.3 0 0 .1 0 .1 0 0zm2.5-.7c-.2-.1-.4-.2-.6-.2-.1.3-.3.5-.5.8.1 0 .1.1.2.1.4-.2.7-.4.9-.7zm-2.1.6c-.2-.3-.3-.5-.5-.8-.2.1-.4.1-.6.2.3.3.5.5.8.6h.3zm.4-.1v-.8h-.4c-.1 0-.3 0-.4.1.1.3.3.5.5.8l.3-.1zm.5 0c.2-.2.4-.5.5-.7-.3-.1-.5-.1-.8-.1v.8h.3zm-.4-1.6c-.5.2-1.3.2-1.7-.1-.3-.2-.4-.6-.3-1 .1-.3.4-.6.7-.7.4-.2.9-.3 1.3-.4l.1-.1s0-.1-.1-.1c-.4-.1-.9 0-1.3.3 0 0-.1.1-.1.2h-.6V376h.7v.2c.1 0 .2-.1.2-.1.5-.2 1.1-.3 1.6 0 .4.2.6.6.5 1-.1.4-.4.8-.8.9l-1.2.3h-.1l-.1.1c.1.2.3.1.5.1.3 0 .7-.2.9-.5h.7v1.1h-.7v-.2l-.2.2zm4.1-.2c-.6.4-1.6.4-2.2.1-.3-.2-.6-.5-.7-.9-.1-.6-.1-1.3.4-1.8s1.3-.6 2-.4c0 0 .1 0 .2.1.2.1.4.2.6.4.4.5.4 1.3.2 1.9-.1.3-.3.5-.5.6zm-8.2.3h-.3v-.8h.5V376.7h-.5v-.8h2.1v.8h-.5v1.7h.5v.8h-1.8zm3.1-3.7h-.1l.1-.3c-.2-.1-.4-.1-.6-.2-.1.1-.3.3-.4.5 0 .1-.1 0-.2 0 .1-.2.3-.4.4-.6-.1-.1-.3-.2-.4-.2-.3.2-.6.5-.8.8h-.2c.3-.5.9-1 1.4-1.3.4-.2.8-.3 1.2-.4 1.5-.2 2.9.4 3.7 1.7h-.2c-.2-.3-.5-.6-.7-.8-.1.1-.2.1-.4.2.2.2.3.4.4.6h-.1c-.1-.2-.2-.4-.4-.5-.2.1-.4.2-.6.2 0 .1.1.2.1.3h-.1l-.1-.3c-.3.1-.6.1-.9.1v.1h-.1v-.1c-.3 0-.6 0-.9-.1l-.1.3zm1-1.1h-.3c-.2.2-.4.5-.5.8.2 0 .5.1.8.1v-.9zm.9.8c-.1-.3-.3-.5-.5-.8H207v.8h.8zm-1.9-.1c.1-.3.3-.5.5-.8-.1 0-.1-.1-.2-.1l-.9.6c.2.2.4.3.6.3zm2.7-.2-.5-.5c-.1-.1-.2-.1-.3-.2l-.2.1c.2.2.3.5.5.7.1.1.3 0 .5-.1zm.5-.3c-.3-.2-.6-.4-.9-.5l-.2-.1s-.1 0-.1.1c.3.2.6.4.8.7.1 0 .3-.1.4-.2zm-3.1-.4c0-.1 0-.1 0 0-.5 0-.9.2-1.2.5.1.1.2.1.4.2.2-.3.5-.5.8-.7zm1.7-.1c.1-.1 0-.1 0 0-.5-.1-1.1-.1-1.6 0 .5.3 1.2.3 1.6 0z"
        />
      </g>
    </g>
    <path
      className="ISO_27001_svg__st1"
      d="M5.6 13.6H4.3v-.3l.5-.5.3-.3c0-.1.1-.1.1-.2v-.1c0-.1 0-.1-.1-.2h-.2c-.1 0-.1 0-.2.1-.1 0-.1.1-.2.1l-.2-.3c.1-.1.2-.1.2-.2.1 0 .1-.1.2-.1h.2c.1 0 .2 0 .3.1.1 0 .2.1.2.2.1.1.1.2.1.3v.2c0 .1-.1.2-.1.2L5 13l-.2.2h.8v.4zM6 13.6l.7-1.6h-.9v-.3h1.4v.3l-.7 1.6H6zM8.7 12.6c0 .3-.1.6-.2.7-.1.2-.3.2-.5.2s-.4-.1-.5-.2c-.1-.2-.2-.4-.2-.7 0-.3.1-.6.2-.7.1-.2.3-.2.5-.2s.4.1.5.3c.1.1.2.3.2.6zm-1 0c0 .2 0 .4.1.5 0 .1.1.2.2.2s.2-.1.2-.2.1-.3.1-.5 0-.4-.1-.5c0-.1-.1-.1-.2-.1s-.2.1-.2.2c0 0-.1.2-.1.4zM10.2 12.6c0 .3-.1.6-.2.7s-.3.2-.5.2-.4-.1-.5-.2c-.1-.2-.2-.4-.2-.7 0-.3.1-.6.2-.7.1-.2.3-.2.5-.2s.4.1.5.3c.1.1.2.3.2.6zm-.9 0c0 .2 0 .4.1.5 0 .1.1.2.2.2s.2-.1.2-.2.1-.3.1-.5 0-.4-.1-.5-.2-.1-.3-.1c-.1 0-.2.1-.2.2v.4zM11.4 13.6H11v-1.5l-.1.1-.2.2-.2-.2.6-.5h.3v1.9z"
    />
  </svg>
);
const ForwardRef = forwardRef(SvgIso27001);
export default ForwardRef;
