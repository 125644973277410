import React from 'react';
import { BoxStyle } from './LevelBox.styled';
import { formatNumberShorthand, isNil } from 'common/utils/helpFunctions';
import { ILevelBoxItem } from './LevelBox.interface';

export const LevelBox: React.FC<{ item: ILevelBoxItem, width?: string }> = ({ item, width }) => {
    return (
        <BoxStyle.TopDiv onClick={item.onClick} isLink={!isNil(item.onClick)} width={width}>
            <BoxStyle.BarDiv color={ item.color } />
            <BoxStyle.BodyDiv>
                <BoxStyle.TextDiv>{item.text}</BoxStyle.TextDiv>
                <BoxStyle.CounterDiv isLink={!isNil(item.onClick)}>{formatNumberShorthand(item.count)}</BoxStyle.CounterDiv>
            </BoxStyle.BodyDiv>
        </BoxStyle.TopDiv>
    );
};