import { Mode, Palette } from './colors.types';


const lightPalette:Palette = {
    slate: {
        alpha: {
            10: 'rgba(0, 37, 102, .02)',
            20: 'rgba(0, 37, 102, .03)',
            30: 'rgba(0, 37, 102, .04)',
            40: 'rgba(0, 37, 102, .07)',
            50: 'rgba(0, 37, 102, .14)',
            60: 'rgba(0, 37, 102, .18)',
            70: 'rgba(10, 40, 92, .21)',
            80: 'rgba(10, 40, 92, .24)',
            90: 'rgba(5, 39, 97, .28)',
            100: 'rgba(12, 33, 69, .55)',
            200: 'rgba(11, 33, 70, .61)',
            300: 'rgba(11, 31, 66, .66)',
            400: 'rgba(12, 33, 69, .72)',
            500: 'rgba(13, 33, 68, .78)',
            600: 'rgba(13, 33, 68, .83)',
            700: 'rgba(12, 33, 69, .90)',
            800: 'rgba(11, 31, 66, .95)',
            900: '#0d2040'
        },
        opaque: {
            10: '#ffffff',
            20: '#F4F6FB',
            30: '#F0F2FA',
            40: '#E8EEF7',
            50: '#E1E7F5',
            60: '#D9E2F2',
            70: '#D1DBF0',
            80: '#CAD6ED',
            90: '#C1D2EB',
            100: '#7F93B3',
            200: '#6C82A7',
            300: '#5C7399',
            400: '#506486',
            500: '#455672',
            600: '#374967',
            700: '#25395A',
            800: '#162C50',
            900: '#091E42'
        }
    },
    blue: {
        alpha: {
            10: 'rgba(0, 53, 112, .05)',
            20: 'rgba(0, 53, 112, .10)',
            30: 'rgba(0, 53, 112, .15)',
            40: 'rgba(0, 53, 112, .20)',
            50: 'rgba(0, 53, 112, .25)',
            60: 'rgba(0, 53, 112, .30)',
            70: 'rgba(0, 53, 112, .35)',
            80: 'rgba(0, 53, 112, .40)',
            90: 'rgba(0, 53, 112, .45)',
            100: 'rgba(0, 53, 112, .60)',
            200: 'rgba(0, 53, 112, .65)',
            300: 'rgba(0, 53, 112, .70)',
            400: 'rgba(0, 53, 112, .75)',
            500: 'rgba(0, 53, 112, .80)',
            600: 'rgba(0, 53, 112, .85)',
            700: 'rgba(0, 53, 112, .90)',
            800: 'rgba(0, 53, 112, .95)',
            900: '#003570'
        },
        opaque: {
            10: '#f0f7ff',
            20: '#E0EFFF',
            30: '#D1E7FF',
            40: '#BDDBFF',
            50: '#A8D1FF',
            60: '#8AC0FF',
            70: '#75B5FF',
            80: '#61ACFF',
            90: '#4DA1FF',
            100: '#3392FF',
            200: '#2585F4',
            300: '#0D77F2',
            400: '#0B6BDA',
            500: '#0A60C2',
            600: '#0957AE',
            700: '#004DA3',
            800: '#00438F',
            900: '#003A7A'
        }
    },
    purple: {
        alpha: {
            10: 'rgba(60, 0, 102, .05)',
            20: 'rgba(60, 0, 102, .10)',
            30: 'rgba(60, 0, 102, .15)',
            40: 'rgba(60, 0, 102, .20)',
            50: 'rgba(60, 0, 102, .25)',
            60: 'rgba(60, 0, 102, .30)',
            70: 'rgba(60, 0, 102, .35)',
            80: 'rgba(60, 0, 102, .40)',
            90: 'rgba(60, 0, 102, .45)',
            100: 'rgba(60, 0, 102, .60)',
            200: 'rgba(60, 0, 102, .65)',
            300: 'rgba(60, 0, 102, .70)',
            400: 'rgba(60, 0, 102, .75)',
            500: 'rgba(60, 0, 102, .80)',
            600: 'rgba(60, 0, 102, .85)',
            700: 'rgba(60, 0, 102, .90)',
            800: 'rgba(60, 0, 102, .95)',
            900: '#3c0066'
        },
        opaque: {
            10: '#f9f0ff',
            20: '#f1dbff',
            30: '#e6c7ff',
            40: '#dfb3ff',
            50: '#d79eff',
            60: '#ce8aff',
            70: '#c575ff',
            80: '#bd61ff',
            90: '#b44dff',
            100: '#a933ff',
            200: '#9d25f4',
            300: '#920df2',
            400: '#840bda',
            500: '#7700cc',
            600: '#6800b2',
            700: '#580099',
            800: '#4b0080',
            900: '#3c0066'
        }
    },
    magenta: {
        alpha: {
            10: 'rgba(163, 0, 54, .05)',
            20: 'rgba(163, 0, 54, .12)',
            30: 'rgba(163, 0, 54, .18)',
            40: 'rgba(163, 0, 54, .22)',
            50: 'rgba(163, 0, 54, .30)',
            60: 'rgba(163, 0, 54, .40)',
            70: 'rgba(163, 0, 54, .47)',
            80: 'rgba(163, 0, 54, .53)',
            90: 'rgba(229, 0, 77, .63)',
            100: 'rgba(229, 0, 77, .71)',
            200: 'rgba(229, 0, 77, .80)',
            300: 'rgba(235, 0, 78, .90)',
            400: 'rgba(204, 0, 68, .90)',
            500: 'rgba(184, 0, 61, .95)',
            600: 'rgba(163, 0, 54, .95)',
            700: 'rgba(143, 0, 48, .95)',
            800: 'rgba(117, 0, 40, .95)',
            900: 'rgba(92, 0, 31, .95)'
        },
        opaque: {
            10: '#fff0f4',
            20: '#ffe0eb',
            30: '#ffccdd',
            40: '#ffb8cf',
            50: '#ffa3c2',
            60: '#ff8fb4',
            70: '#ff7aa7',
            80: '#ff6699',
            90: '#ff4785',
            100: '#f53d7a',
            200: '#f4256a',
            300: '#f20d59',
            400: '#da0b50',
            500: '#c20a47',
            600: '#b2003b',
            700: '#990033',
            800: '#80002a',
            900: '#660022'
        }
    },
    red: {
        alpha: {
            10: 'rgba(102, 20, 0, .05)',
            20: 'rgba(102, 20, 0, .10)',
            30: 'rgba(102, 20, 0, .15)',
            40: 'rgba(102, 20, 0, .20)',
            50: 'rgba(102, 20, 0, .25)',
            60: 'rgba(102, 20, 0, .30)',
            70: 'rgba(102, 20, 0, .35)',
            80: 'rgba(102, 20, 0, .40)',
            90: 'rgba(102, 20, 0, .45)',
            100: 'rgba(102, 20, 0, .60)',
            200: 'rgba(102, 20, 0, .65)',
            300: 'rgba(102, 20, 0, .70)',
            400: 'rgba(102, 20, 0, .75)',
            500: 'rgba(102, 20, 0, .80)',
            600: 'rgba(102, 20, 0, .85)',
            700: 'rgba(102, 20, 0, .90)',
            800: 'rgba(102, 20, 0, .95)',
            900: '#661400'
        },
        opaque: {
            10: '#fff3f0',
            20: '#ffe2db',
            30: '#ffd2c7',
            40: '#ffc2b2',
            50: '#ffb19e',
            60: '#ffa18a',
            70: '#ffa18a',
            80: '#ff9175',
            90: '#ff8161',
            100: '#ff5c33',
            200: '#ff471a',
            300: '#ff3300',
            400: '#e52e00',
            500: '#cc2900',
            600: '#b22400',
            700: '#991f00',
            800: '#801a00',
            900: '#661400'
        }
    },
    orange: {
        alpha: {
            10: 'rgba(102, 51, 0, .05)',
            20: 'rgba(102, 51, 0, .10)',
            30: 'rgba(102, 51, 0, .15)',
            40: 'rgba(102, 51, 0, .20)',
            50: 'rgba(102, 51, 0, .25)',
            60: 'rgba(102, 51, 0, .30)',
            70: 'rgba(102, 51, 0, .35)',
            80: 'rgba(102, 51, 0, .40)',
            90: 'rgba(102, 51, 0, .45)',
            100: 'rgba(102, 51, 0, .60)',
            200: 'rgba(102, 51, 0, .65)',
            300: 'rgba(102, 51, 0, .70)',
            400: 'rgba(102, 51, 0, .75)',
            500: 'rgba(102, 51, 0, .80)',
            600: 'rgba(102, 51, 0, .85)',
            700: 'rgba(102, 51, 0, .90)',
            800: 'rgba(102, 51, 0, .95)',
            900: '#663300'
        },
        opaque: {
            10: '#fff7f0',
            20: '#ffeddb',
            30: '#ffe2c7',
            40: '#ffd9b2',
            50: '#ffcf9e',
            60: '#ffc58a',
            70: '#ffba75',
            80: '#ffaf61',
            90: '#ffa54d',
            100: '#ff9933',
            200: '#ff8c1a',
            300: '#ff8000',
            400: '#e57200',
            500: '#cc6500',
            600: '#b25900',
            700: '#994d00',
            800: '#804200',
            900: '#663500'
        }
    },
    yellow: {
        alpha: {
            10: 'rgba(77, 64, 0, .05)',
            20: 'rgba(77, 64, 0, .10)',
            30: 'rgba(77, 64, 0, .15)',
            40: 'rgba(77, 64, 0, .20)',
            50: 'rgba(77, 64, 0, .25)',
            60: 'rgba(77, 64, 0, .30)',
            70: 'rgba(77, 64, 0, .35)',
            80: 'rgba(77, 64, 0, .40)',
            90: 'rgba(77, 64, 0, .45)',
            100: 'rgba(77, 64, 0, .60)',
            200: 'rgba(77, 64, 0, .65)',
            300: 'rgba(77, 64, 0, .70)',
            400: 'rgba(77, 64, 0, .75)',
            500: 'rgba(77, 64, 0, .80)',
            600: 'rgba(77, 64, 0, .85)',
            700: 'rgba(77, 64, 0, .90)',
            800: 'rgba(77, 64, 0, .95)',
            900: '#4d4000'
        },
        opaque: {
            10: '#FFF9E0',
            20: '#FDF6D3',
            30: '#FCF3C5',
            40: '#F9EEB8',
            50: '#F8EAA5',
            60: '#F6E593',
            70: '#F5E17F',
            80: '#F7E069',
            90: '#F6DB56',
            100: '#F2CC0D',
            200: '#E5BF00',
            300: '#CCAA00',
            400: '#B29400',
            500: '#998000',
            600: '#806A00',
            700: '#665500',
            800: '#4D4000',
            900: '#332B00'
        }
    },
    green: {
        alpha: {
            10: 'rgba(0, 77, 0, .5)',
            20: 'rgba(0, 77, 0, .10)',
            30: 'rgba(0, 77, 0, .15)',
            40: 'rgba(0, 77, 0, .20)',
            50: 'rgba(0, 77, 0, .25)',
            60: 'rgba(0, 77, 0, .30)',
            70: 'rgba(0, 77, 0, .35)',
            80: 'rgba(0, 77, 0, .40)',
            90: 'rgba(0, 77, 0, .45)',
            100: 'rgba(0, 77, 0, .60)',
            200: 'rgba(0, 77, 0, .65)',
            300: 'rgba(0, 77, 0, .70)',
            400: 'rgba(0, 77, 0, .75)',
            500: 'rgba(0, 77, 0, .80)',
            600: 'rgba(0, 77, 0, .85)',
            700: 'rgba(0, 77, 0, .90)',
            800: 'rgba(0, 77, 0, .95)',
            900: '#004d00'
        },
        opaque: {
            10: '#f1fdf1',
            20: '#e4fbe4',
            30: '#cef8ce',
            40: '#bcf5bc',
            50: '#aaf3aa',
            60: '#98f098',
            70: '#87ee87',
            80: '#75eb75',
            90: '#63e963',
            100: '#42d742',
            200: '#2dd22d',
            300: '#22c322',
            400: '#1ab21a',
            500: '#12a112',
            600: '#089108',
            700: '#008000',
            800: '#006600',
            900: '#004c00'
        }
    }
};

const darkPalette:Palette = {
    slate: {
        opaque: {
            10: '#091e42',
            20: '#15294b',
            30: '#243757',
            40: '#354764',
            50: '#42526d',
            60: '#4b5e7e',
            70: '#54698b',
            80: '#5d749c',
            90: '#6c83a7',
            100: '#afc3e4',
            200: '#bacbe8',
            300: '#c6d3ec',
            400: '#d1dcf0',
            500: '#dde5f4',
            600: '#e8eff7',
            700: '#f0f2fa',
            800: '#f7fafc',
            900: '#ffffff'
        },
        alpha: {
            10: 'rgba(229, 238, 255, .04)',
            20: 'rgba(229, 238, 255, .06)',
            30: 'rgba(229, 238, 255, .08)',
            40: 'rgba(229, 238, 255, .12)',
            50: 'rgba(229, 238, 255, .18)',
            60: 'rgba(229, 238, 255, .24)',
            70: 'rgba(229, 238, 255, .32)',
            80: 'rgba(229, 238, 255, .40)',
            90: 'rgba(229, 238, 255, .48)',
            100: 'rgba(229, 238, 255, .65)',
            200: 'rgba(229, 238, 255, .70)',
            300: 'rgba(229, 238, 255, .75)',
            400: 'rgba(229, 238, 255, .80)',
            500: 'rgba(229, 238, 255, .85)',
            600: 'rgba(229, 238, 255, .90)',
            700: 'rgba(229, 238, 255, .95)',
            800: '#e5eeff',
            900: '#ffffff'
        }
    },
    blue: {
        opaque: {
            10: '#003066',
            20: '#003C80',
            30: '#004899',
            40: '#0954AA',
            50: '#0A5FC2',
            60: '#0C6BDA',
            70: '#0C77F3',
            80: '#1A84FF',
            90: '#3391FF',
            100: '#61ACFF',
            200: '#75B7FF',
            300: '#8AC0FF',
            400: '#9ECBFF',
            500: '#B2D6FF',
            600: '#C2DDFF',
            700: '#D1E8FF',
            800: '#E0EFFF',
            900: '#F0F7FF'
        },
        alpha: {
            10: 'rgba(204, 228, 255, .05)',
            20: 'rgba(204, 228, 255, .10)',
            30: 'rgba(204, 228, 255, .15)',
            40: 'rgba(204, 228, 255, .20)',
            50: 'rgba(204, 228, 255, .25)',
            60: 'rgba(204, 228, 255, .30)',
            70: 'rgba(204, 228, 255, .35)',
            80: 'rgba(204, 228, 255, .40)',
            90: 'rgba(204, 228, 255, .45)',
            100: 'rgba(204, 228, 255, .60)',
            200: 'rgba(204, 228, 255, .65)',
            300: 'rgba(204, 228, 255, .70)',
            400: 'rgba(204, 228, 255, .75)',
            500: 'rgba(204, 228, 255, .80)',
            600: 'rgba(204, 228, 255, .85)',
            700: 'rgba(204, 228, 255, .90)',
            800: 'rgba(204, 228, 255, .95)',
            900: '#cce4ff'
        }
    },
    purple: {
        opaque: {
            10: '#3c0066',
            20: '#4b0080',
            30: '#580099',
            40: '#6800b2',
            50: '#7700cc',
            60: '#840bda',
            70: '#920df2',
            80: '#9d25f4',
            90: '#a933ff',
            100: '#b44dff',
            200: '#bd61ff',
            300: '#c575ff',
            400: '#ce8aff',
            500: '#d79eff',
            600: '#dfb3ff',
            700: '#e6c7ff',
            800: '#f1dbff',
            900: '#f9f0ff'
        },
        alpha: {
            10: 'rgba(213, 153, 255, .05)',
            20: 'rgba(213, 153, 255, .10)',
            30: 'rgba(213, 153, 255, .15)',
            40: 'rgba(213, 153, 255, .20)',
            50: 'rgba(213, 153, 255, .25)',
            60: 'rgba(213, 153, 255, .30)',
            70: 'rgba(213, 153, 255, .35)',
            80: 'rgba(213, 153, 255, .40)',
            90: 'rgba(213, 153, 255, .45)',
            100: 'rgba(213, 153, 255, .60)',
            200: 'rgba(213, 153, 255, .65)',
            300: 'rgba(213, 153, 255, .70)',
            400: 'rgba(223, 178, 255, .95)',
            500: 'rgba(234, 204, 255, .95)',
            600: 'rgba(242, 224, 255, .95)',
            700: '#f2e0ff',
            800: '#f9f0ff',
            900: '#ffffff'
        }
    },
    magenta: {
        opaque: {
            10: '#660022',
            20: '#80002a',
            30: '#990033',
            40: '#b2003b',
            50: '#c20a47',
            60: '#da0b50',
            70: '#f20d59',
            80: '#f4256a',
            90: '#f53d7a',
            100: '#ff4785',
            200: '#ff6699',
            300: '#ff7aa7',
            400: '#ff8fb4',
            500: '#ffa3c2',
            600: '#ffb8cf',
            700: '#ffccdd',
            800: '#ffe0eb',
            900: '#fff0f4'
        },
        alpha: {
            10: 'rgba(255, 153, 178, .05)',
            20: 'rgba(255, 153, 178, .10)',
            30: 'rgba(255, 153, 178, .15)',
            40: 'rgba(255, 153, 178, .20)',
            50: 'rgba(255, 153, 178, .25)',
            60: 'rgba(255, 153, 178, .30)',
            70: 'rgba(255, 153, 178, .35)',
            80: 'rgba(255, 153, 178, .40)',
            90: 'rgba(255, 153, 178, .45)',
            100: 'rgba(255, 153, 178, .60)',
            200: 'rgba(255, 153, 178, .65)',
            300: 'rgba(255, 153, 178, .70)',
            400: 'rgba(255, 153, 178, .75)',
            500: 'rgba(255, 153, 178, .80)',
            600: 'rgba(255, 153, 178, .85)',
            700: 'rgba(255, 204, 216, .95)',
            800: '#ffccd9',
            900: '#ffebf0'
        }
    },
    red: {
        opaque: {
            10: '#661400',
            20: '#801a00',
            30: '#991f00',
            40: '#b22400',
            50: '#cc2900',
            60: '#e52e00',
            70: '#ff3300',
            80: '#ff471a',
            90: '#ff5c33',
            100: '#ff704d',
            200: '#ff7b61',
            300: '#ff9175',
            400: '#ffa18a',
            500: '#ffb19e',
            600: '#ffc1b2',
            700: '#ffd2c7',
            800: '#ffe2db',
            900: '#fff3f0'
        },
        alpha: {
            10: 'rgba(255, 173, 153, .05)',
            20: 'rgba(255, 173, 153, .10)',
            30: 'rgba(255, 173, 153, .15)',
            40: 'rgba(255, 173, 153, .20)',
            50: 'rgba(255, 173, 153, .25)',
            60: 'rgba(255, 173, 153, .30)',
            70: 'rgba(255, 173, 153, .35)',
            80: 'rgba(255, 173, 153, .40)',
            90: 'rgba(255, 173, 153, .45)',
            100: 'rgba(255, 173, 153, .60)',
            200: 'rgba(255, 173, 153, .65)',
            300: 'rgba(255, 173, 153, .70)',
            400: 'rgba(255, 173, 153, .75)',
            500: 'rgba(255, 173, 153, .80)',
            600: 'rgba(255, 173, 153, .85)',
            700: 'rgba(255, 173, 153, .90)',
            800: 'rgba(255, 173, 153, .95)',
            900: '#ffad99'
        }
    },
    orange: {
        opaque: {
            10: '#4d2800',
            20: '#663500',
            30: '#804200',
            40: '#994d00',
            50: '#b25900',
            60: '#cc6500',
            70: '#e57200',
            80: '#ff8000',
            90: '#ff8c1a',
            100: '#ff9933',
            200: '#ffb266',
            300: '#ffbd7a',
            400: '#ffc78f',
            500: '#ffd1a3',
            600: '#ffdcb8',
            700: '#ffe5cc',
            800: '#ffefe0',
            900: '#fff7f0'
        },
        alpha: {
            10: 'rgba(255, 178, 102, .05)',
            20: 'rgba(255, 178, 102, .10)',
            30: 'rgba(255, 178, 102, .15)',
            40: 'rgba(255, 178, 102, .20)',
            50: 'rgba(255, 178, 102, .25)',
            60: 'rgba(255, 178, 102, .30)',
            70: 'rgba(255, 178, 102, .35)',
            80: 'rgba(255, 178, 102, .40)',
            90: 'rgba(255, 178, 102, .45)',
            100: 'rgba(255, 178, 102, .60)',
            200: 'rgba(255, 178, 102, .65)',
            300: 'rgba(255, 178, 102, .70)',
            400: 'rgba(255, 178, 102, .75)',
            500: 'rgba(255, 178, 102, .80)',
            600: 'rgba(255, 178, 102, .85)',
            700: 'rgba(255, 178, 102, .90)',
            800: 'rgba(255, 191, 128, .95)',
            900: '#ffcc99'
        }
    },
    yellow: {
        opaque: {
            10: '#332b00',
            20: '#4d4000',
            30: '#665400',
            40: '#806a00',
            50: '#998000',
            60: '#b29500',
            70: '#ccaa00',
            80: '#E5BF00',
            90: '#F2CC0D',
            100: '#F6DB56',
            200: '#F3DD6D',
            300: '#F5E17F',
            400: '#F6E593',
            500: '#F8EAA5',
            600: '#F9EEB8',
            700: '#FCF4CA',
            800: '#FDF8DD',
            900: '#E7FEED'
        },
        alpha: {
            10: 'rgba(255, 238, 153, .05)',
            20: 'rgba(255, 238, 153, .10)',
            30: 'rgba(255, 238, 153, .15)',
            40: 'rgba(255, 238, 153, .20)',
            50: 'rgba(255, 238, 153, .25)',
            60: 'rgba(255, 238, 153, .30)',
            70: 'rgba(255, 238, 153, .35)',
            80: 'rgba(255, 238, 153, .40)',
            90: 'rgba(255, 238, 153, .45)',
            100: 'rgba(255, 238, 153, .60)',
            200: 'rgba(255, 238, 153, .65)',
            300: 'rgba(255, 238, 153, .70)',
            400: 'rgba(255, 238, 153, .75)',
            500: 'rgba(255, 238, 153, .80)',
            600: 'rgba(255, 238, 153, .85)',
            700: 'rgba(255, 238, 153, .90)',
            800: 'rgba(255, 238, 153, .95)',
            900: '#ffee99'
        }
    },
    green: {
        opaque: {
            10: '#004d00',
            20: '#006600',
            30: '#008000',
            40: '#089108',
            50: '#12a112',
            60: '#19b219',
            70: '#1dc91d',
            80: '#20df20',
            90: '#36e236',
            100: '#63e963',
            200: '#75eb75',
            300: '#87ee87',
            400: '#98f098',
            500: '#aaf3aa',
            600: '#bcf5bc',
            700: '#cef8ce',
            800: '#e4fbe4',
            900: '#f1fdf1'
        },
        alpha: {
            10: 'rgba(153, 255, 153, .05)',
            20: 'rgba(153, 255, 153, .10)',
            30: 'rgba(153, 255, 153, .15)',
            40: 'rgba(153, 255, 153, .20)',
            50: 'rgba(153, 255, 153, .25)',
            60: 'rgba(153, 255, 153, .30)',
            70: 'rgba(153, 255, 153, .35)',
            80: 'rgba(153, 255, 153, .40)',
            90: 'rgba(153, 255, 153, .45)',
            100: 'rgba(153, 255, 153, .60)',
            200: 'rgba(153, 255, 153, .65)',
            300: 'rgba(153, 255, 153, .70)',
            400: 'rgba(153, 255, 153, .75)',
            500: 'rgba(153, 255, 153, .80)',
            600: 'rgba(153, 255, 153, .85)',
            700: 'rgba(153, 255, 153, .90)',
            800: 'rgba(153, 255, 153, .95)',
            900: '#99ff99'
        }
    }
};

export const getPalette = (mode:Mode = 'light'):Palette => {
    return mode === 'light' ? lightPalette : darkPalette;
};