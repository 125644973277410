import * as React from 'react';
import { SVGProps, Ref, forwardRef } from 'react';
const SvgInactive = (
  props: SVGProps<SVGSVGElement>,
  ref: Ref<SVGSVGElement>
) => (
  <svg
    viewBox="0 0 16 16"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    ref={ref}
    {...props}
  >
    <g stroke="currentColor">
      <circle cx={8} cy={8} r={7.5} />
      <path d="m13.5 3-11 10" strokeLinecap="round" strokeLinejoin="round" />
    </g>
  </svg>
);
const ForwardRef = forwardRef(SvgInactive);
export default ForwardRef;
