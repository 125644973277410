import React from 'react';
import { globalEventBus, IOnEvent } from './eventBus';

type IUseEventHandlerProps = (eventHandlerId: string, onEvent: IOnEvent) => void
export const useEventHandler: IUseEventHandlerProps = (eventHandlerId, onEvent) => {
    React.useEffect(() => {
        globalEventBus.registerEventHandler(eventHandlerId, onEvent);
        return () => {
            globalEventBus.unregisterEventHandler(eventHandlerId);
        };
    }, [eventHandlerId, onEvent]);
};
