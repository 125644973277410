import * as React from 'react';
import { SVGProps, Ref, forwardRef } from 'react';
const SvgClearAll = (
  props: SVGProps<SVGSVGElement>,
  ref: Ref<SVGSVGElement>
) => (
  <svg
    viewBox="0 0 16 16"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    ref={ref}
    {...props}
  >
    <path
      d="m8.5 8.5 7 7m0-7-7 7M15.5 2.5v0a2 2 0 0 0-2-2v0m-11 0v0a2 2 0 0 0-2 2v0m0 3v1m0 3v1m0 3v0a2 2 0 0 0 2 2v0m3-15h1m3 0h1m5 5v1m-10 9h1"
      stroke="currentColor"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </svg>
);
const ForwardRef = forwardRef(SvgClearAll);
export default ForwardRef;
