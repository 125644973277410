import * as React from 'react';
import { SVGProps, Ref, forwardRef } from 'react';
const SvgFavorite = (
  props: SVGProps<SVGSVGElement>,
  ref: Ref<SVGSVGElement>
) => (
  <svg
    viewBox="0 0 16 16"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    ref={ref}
    {...props}
  >
    <path
      d="M7.175 1.704a1 1 0 0 1 1.65 0l1.795 2.62a1 1 0 0 0 .543.393l3.046.898a1 1 0 0 1 .51 1.57L12.782 9.7a1 1 0 0 0-.207.638l.087 3.175a1 1 0 0 1-1.335.97l-2.992-1.065a1 1 0 0 0-.67 0l-2.992 1.064a1 1 0 0 1-1.335-.97l.087-3.174a1 1 0 0 0-.207-.638L1.281 7.184a1 1 0 0 1 .51-1.569l3.047-.898a1 1 0 0 0 .542-.394l1.795-2.62Z"
      stroke="currentColor"
      strokeLinejoin="round"
    />
  </svg>
);
const ForwardRef = forwardRef(SvgFavorite);
export default ForwardRef;
