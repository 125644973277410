import * as React from 'react';
import { SVGProps, Ref, forwardRef } from 'react';
const SvgMagnify = (
  props: SVGProps<SVGSVGElement>,
  ref: Ref<SVGSVGElement>
) => (
  <svg
    viewBox="0 0 16 16"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    ref={ref}
    {...props}
  >
    <path
      d="M10.743 10.743a6 6 0 1 0-8.485-8.486 6 6 0 0 0 8.485 8.486Zm0 0 4.727 4.718"
      stroke="currentColor"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </svg>
);
const ForwardRef = forwardRef(SvgMagnify);
export default ForwardRef;
