import * as React from 'react';
import { SVGProps, Ref, forwardRef } from 'react';
const SvgCve = (props: SVGProps<SVGSVGElement>, ref: Ref<SVGSVGElement>) => (
  <svg
    viewBox="0 0 16 16"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    ref={ref}
    {...props}
  >
    <path
      d="M11.5 10.5v-4a1 1 0 0 0-1-1h-6a1 1 0 0 0-1 1v4s0 4 4 4 4-4 4-4ZM9.5 2.5v.571a.429.429 0 0 1-.429.429H5.93a.429.429 0 0 1-.429-.429V2.5a2 2 0 1 1 4 0Z"
      stroke="currentColor"
      strokeLinejoin="round"
    />
    <path
      d="m14 4-2.5 1.5M1 4l2.5 1.5m10 9-2.46-2.094M1.5 14.5l2.5-2m10.5-3h-3m-11 0h3M7.5 7.5v7"
      stroke="currentColor"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </svg>
);
const ForwardRef = forwardRef(SvgCve);
export default ForwardRef;
