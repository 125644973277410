import { addAlpha } from 'common/design-system/theme/colors';
import styled, { css } from 'styled-components';

const DatePickerWrapper = styled.div`
    .rdp-button_reset.rdp-button.rdp-day {
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);
    }

    .rdp-caption_label {
        ${({ theme }) => css({ ...theme.typography.body500 })};
    }

    &&& .rdp-day_selected:not(.rdp-day_range_middle) {
        background: ${({ theme }) => theme.palette.surface.brandPrimary};
        color: ${({ theme }) => theme.palette.onSurface.reverse};

        &:hover {
            background: ${({ theme }) => theme.palette.surface.brandPrimary};
            color: ${({ theme }) => theme.palette.onSurface.reverse};
        }
    }

    && .rdp-day_range_middle {
        background: ${({ theme }) => addAlpha(theme.palette.surface.brandPrimary, 0.3)};
        color: ${({ theme }) => theme.palette.onSurface.normal};
    }
`;

const DatePickerStyled = {
    DatePickerWrapper
};

export default DatePickerStyled;