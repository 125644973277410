import globalAddinContainer from 'common/extensibility/AddinContainer';
import { Addin } from 'common/extensibility/AddinRegistry';
import {
    IActionEnabler, IRemediationModal,
    PATH_EVENTS_REMEDIATION_ENABLER, PATH_REMEDIATION_MODAL,
} from 'common/interface/remediation';

export const RemediationRegistry = {
    addRemediateEventEnablers: (enablers: Addin<IActionEnabler>[]) => {
        globalAddinContainer.add<IActionEnabler>(PATH_EVENTS_REMEDIATION_ENABLER, enablers);
    },
    getRemediateEventEnablers: () : IActionEnabler[]=> {
        return globalAddinContainer.get<IActionEnabler>(PATH_EVENTS_REMEDIATION_ENABLER);
    },
    addRemediationModals: (modal: Addin<IRemediationModal>[]) => {
        globalAddinContainer.add<IRemediationModal>(PATH_REMEDIATION_MODAL, modal);
    },
    getRemediationModals: () : IRemediationModal[]=> {
        return globalAddinContainer.get<IRemediationModal>(PATH_REMEDIATION_MODAL);
    },
};
