import * as React from 'react';
import { SVGProps, Ref, forwardRef } from 'react';
const SvgAssign = (props: SVGProps<SVGSVGElement>, ref: Ref<SVGSVGElement>) => (
  <svg
    viewBox="0 0 17 17"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    ref={ref}
    {...props}
  >
    <path
      d="M.7 1.6a1 1 0 0 1 1-1h2a1 1 0 0 1 1 1v2a1 1 0 0 1-1 1h-2a1 1 0 0 1-1-1v-2Z"
      stroke="#5D6B82"
      strokeWidth={1.2}
    />
    <path
      d="M5.7 12.6v2a1 1 0 0 0 1 1h8a1 1 0 0 0 1-1v-8a1 1 0 0 0-1-1h-8a1 1 0 0 0-1 1v1.5"
      stroke="#5D6B82"
      strokeWidth={1.2}
      strokeLinecap="round"
    />
    <path
      d="m10.7 10.6-2-2m2 2-2 2m2-2h-4s-4 0-4-4"
      stroke="#5D6B82"
      strokeWidth={1.2}
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </svg>
);
const ForwardRef = forwardRef(SvgAssign);
export default ForwardRef;
