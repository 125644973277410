import {
    FilterTreeItemType,
    IFilterTree,
    IFilterTreeCondition,
    IFilterTreeFieldDefinition,
    IFilterTreeItem,
    IFilterTreeNode,
} from './FilterTree.interface';
import i18next from 'i18next';
import { cloneFilterTreeRoot } from './FilterTree.creators';
import { ICompoundFilter } from './CompoundFilter';
import { treeToCompoundFilter } from './FilterTree.convertors';

export const getConditionError = (condition: IFilterTreeCondition): string | undefined => {
    return condition.nameErrorMsg || condition.operatorErrorMsg || condition.valuesErrorMsg;
};

const hasConditionError = (condition: IFilterTreeCondition): boolean => {
    return !!getConditionError(condition);
};

const updateConditionErrorState = (condition: IFilterTreeCondition, filterDefs: IFilterTreeFieldDefinition[]) => {
    condition.nameErrorMsg = undefined;
    condition.operatorErrorMsg = undefined;
    condition.valuesErrorMsg = undefined;
    if (!condition.name) {
        condition.nameErrorMsg = i18next.t('FILTER_TREE.ERROR_MSG.CONDITION_HAS_NO_FIELD');
    }
    if (!condition.operator) {
        condition.operatorErrorMsg = i18next.t('FILTER_TREE.ERROR_MSG.CONDITION_HAS_NO_OPERATOR');
    }
    if (condition.values.length === 0) {
        condition.valuesErrorMsg = i18next.t('FILTER_TREE.ERROR_MSG.CONDITION_HAS_NO_VALUE');
    } else {
        const filterDef: IFilterTreeFieldDefinition | undefined = filterDefs.find(def => def.name === condition.name);
        if (filterDef?.conditionValuesChecker) {
            condition.valuesErrorMsg = filterDef.conditionValuesChecker(condition.values);
        }
    }
};

export const updateNodeErrorState = (node: IFilterTreeNode) => {
    node.errorMsg = undefined;
    if (node.parentNode && (node.childItems.length === 0)) {
        node.errorMsg = i18next.t('FILTER_TREE.ERROR_MSG.NODE_HAS_NO_CHILDREN');
    }
};

const updateDeepErrorsInItem = (item: IFilterTreeItem, filterDefs: IFilterTreeFieldDefinition[]) => {
    if (item.itemType === FilterTreeItemType.condition) {
        updateConditionErrorState(item as IFilterTreeCondition, filterDefs);
        return;
    }

    const node: IFilterTreeNode = item as IFilterTreeNode;
    updateNodeErrorState(node);

    node.childItems.forEach(childNode => updateDeepErrorsInItem(childNode, filterDefs));
};

export const updateDeepErrorsInFilterTree = (treeRoot: IFilterTreeNode, filterDefs: IFilterTreeFieldDefinition[]) => {
    updateDeepErrorsInItem(treeRoot, filterDefs);
};

const hasDeepErrorsInItem = (item: IFilterTreeItem): boolean => {
    if (item.itemType === FilterTreeItemType.condition) {
        return hasConditionError(item as IFilterTreeCondition);
    }

    const node: IFilterTreeNode = item as IFilterTreeNode;
    if (node.errorMsg) {
        return true;
    }

    for (let i = 0; i < node.childItems.length; i++) {
        if (hasDeepErrorsInItem(node.childItems[i])) {
            return true;
        }
    }
    return false;
};

export const hasErrorsInFilterTree = (filterTree: IFilterTree): boolean => {
    return hasDeepErrorsInItem(filterTree.root);
};

const hasItemError = (item: IFilterTreeItem): boolean => {
    if (item.itemType === FilterTreeItemType.condition) {
        return hasConditionError(item as IFilterTreeCondition);
    }

    return !!(item as IFilterTreeNode).errorMsg;
};

const getFlatItemsWithErrorRefs = (item: IFilterTreeItem): IFilterTreeItem[] => {
    let items: IFilterTreeItem[] = [];
    if (hasItemError(item) && item.errorMarkRef) {
        items.push(item);
    }

    if (item.itemType === FilterTreeItemType.node) {
        const node: IFilterTreeNode = item as IFilterTreeNode;
        for (let i = 0; i < node.childItems.length; i++) {
            items = [...items, ...getFlatItemsWithErrorRefs(node.childItems[i])];
        }
    }
    return items;
};

export const getErrorRefs = (filterTree: IFilterTree): HTMLInputElement[] => {
    const items: IFilterTreeItem[] = getFlatItemsWithErrorRefs(filterTree.root);
    return items.map(item => item.errorMarkRef!);
};

const getValidFilterNodeSubset = (node: IFilterTreeNode): IFilterTreeNode | undefined => {
    if (node.errorMsg) {
        return undefined;
    }

    const validChildItems: IFilterTreeItem[] = [];
    node.childItems.forEach(childItem => {
        if (childItem.itemType === FilterTreeItemType.condition) {
            const condition: IFilterTreeCondition = childItem as IFilterTreeCondition;
            if (!hasConditionError(condition)) {
                validChildItems.push(condition);
            }
        } else {
            const childNode: IFilterTreeNode = childItem as IFilterTreeNode;
            const validChildNode: IFilterTreeNode | undefined = getValidFilterNodeSubset(childNode);
            if (validChildNode) {
                validChildItems.push(validChildNode);
            }
        }
    });
    if (validChildItems.length === 0) {
        return undefined;
    }
    node.childItems = validChildItems;
    return node;
};

const getValidFilterTreeNodeSubset = (treeRoot: IFilterTreeNode, filterDefs: IFilterTreeFieldDefinition[]): IFilterTreeNode | undefined => {
    const clonedRoot: IFilterTreeNode = cloneFilterTreeRoot(treeRoot, filterDefs, true);
    return getValidFilterNodeSubset(clonedRoot);
};

export const getValidCompoundFilterSubsetFromTree = (filterTree: IFilterTree, filterDefs: IFilterTreeFieldDefinition[]): ICompoundFilter | undefined => {
    const validFilterTree: IFilterTreeNode | undefined = getValidFilterTreeNodeSubset(filterTree.root, filterDefs);
    if (validFilterTree) {
        return treeToCompoundFilter({ root: validFilterTree });
    }
    return undefined;
};