export const ERM_PROTECTED_ASSET_FILTER_IDS = {
    riskScore: 'erm Risk Score',
    iamSensitivity: 'erm IAM Sensitivity',
    dataSensitivity: 'erm Data Sensitivity',
    dataClassification: 'erm Data Classification',
    businessPriority: 'erm Business Priority',
    networkExposure: 'erm Network Exposure',
    iamExposure: 'erm IAM Exposure',
    secrets: 'erm Secrets',
    cves: 'erm CVEs',
    openSecurityIssues: 'erm Open Security Issues',
    wafProtection: 'erm WAF Protection',
};
