import React, { forwardRef } from 'react';
import StackStyles from './Stack.styles';
import { IStackProps } from './Stack.types';

const Stack: React.FunctionComponent<IStackProps> = forwardRef<HTMLDivElement, IStackProps>((props, ref) => {
    const {
        direction = 'column',
        alignItems = 'stretch',
        spacing = 0,
        justifyContent = 'flex-start',
        children,
        padding = 0,
        margin = 0,
        overflow,
        ...rest
    } = props;

    return (
        <StackStyles.Stack
            ref={ref}
            direction={direction}
            alignItems={alignItems}
            spacing={spacing}
            justifyContent={justifyContent}
            padding={padding}
            margin={margin}
            overflow={overflow}
            {...rest}
        >
            {children}
        </StackStyles.Stack>
    );
});

Stack.displayName = 'Stack';
export default Stack;
