import React from 'react';
import { useTranslation } from 'react-i18next';
import { OrganizationalUnitSelectorProps } from './OrganizationalUnit.types';
import TreeData from '../TreeData';
import { generateHref } from 'common/utils/http';
import { ALink } from 'common/components/ALink';
import { useOrganizationalUnits } from 'common/hooks/useOrganizationalUnit';
import { InputLabel, Stack } from 'common/design-system/components-v2';

const OrganizationalUnit: React.FC<OrganizationalUnitSelectorProps> = ({ selectedOrganizationalUnit, disabled, onChange }) => {
    const { t } = useTranslation('k8s_registry');
    const { isLoading, organizationalUnits } = useOrganizationalUnits();

    return (
        <div className='input-container'>
            <Stack spacing={2}>
                <InputLabel text={`${t('ON_BOARDING.organizationalUnit')}:`} />
                <TreeData
                    value={selectedOrganizationalUnit}
                    placeholder={'Select organizational unit'}
                    options={organizationalUnits}
                    onChange={onChange}
                    isLoading={isLoading}
                    disabled={disabled}
                />
            </Stack>
            <ALink href={generateHref('/organizational-units')} target='_blank' rel='noreferrer'>
                {t('ON_BOARDING.manageOrganizationalUnits')}
            </ALink>
        </div>
    );
};

export default OrganizationalUnit;