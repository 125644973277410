import React, { useCallback, useMemo } from 'react';
import { IConditionValueOption, IConditionValuesComponentProps } from '../FilterTree.interface';
import { useTranslation } from 'react-i18next';
import { FilterStyled } from '../FilterTreeComponent/FilterTree.styled';
import { SelectOption } from 'common/design-system/components-v2/SelectV2/Select.types';
import { SelectV2 } from 'common/design-system/components-v2';
import { IFilterConditionValue } from '../FilterCondition';

interface IRealValueOption extends SelectOption {
    realValue: IFilterConditionValue;
}

export const getRealValuesOptions = (conditionsOptions: IConditionValueOption[]): IRealValueOption[] => {
    return conditionsOptions.map(conditionOption => ({
        ...conditionOption,
        value: String(conditionOption.value),
        realValue: conditionOption.value,
    }));
};

export const DefaultValuesSelector: React.FC<IConditionValuesComponentProps> =
    ({ condition, filterProps, onValuesChange }) => {
        const filterDef = useMemo(() => filterProps.filterDefinitions.find(def => def.name === condition.name), [condition.name, filterProps.filterDefinitions]);
        const conditionOptions: IConditionValueOption[] = useMemo(() => filterDef?.valueOptions || [], [filterDef?.valueOptions]);
        const selectOptions: IRealValueOption[] = useMemo(() => getRealValuesOptions(conditionOptions), [conditionOptions]);
        const { t } = useTranslation();

        const onOptionsChange = useCallback((options: SelectOption[]) => {
            onValuesChange(options.map((option: SelectOption) => (option as IRealValueOption).realValue));
        }, [onValuesChange]);

        return (
            <FilterStyled.StretchDiv>
                <SelectV2
                    fullWidth
                    value={conditionOptions.filter(option => condition.values.includes(option.value)).map(opt => String(opt.value))}
                    onChange={(values: string[], options: SelectOption[]) => onOptionsChange(options)}
                    placeholder={t('FILTER_TREE.VALUES_PLACEHOLDER')}
                    options={selectOptions}
                    clearable
                    isError={!!(filterProps.displayErrors && condition.valuesErrorMsg)}
                    isMulti={true}
                />
            </FilterStyled.StretchDiv>
        );
    };
