import i18n, { validLanguages } from 'common/services/translations/translations';

export const CompsNSPrefix = 'comps-v2';
export type CompsTermTypes = 'table' | 'common';
const termsFolders: Array<CompsTermTypes> = ['table', 'common'];

export const getCompsI18nNS = (term: CompsTermTypes) => `${CompsNSPrefix}_${term}`;

const jsonFilesPaths = validLanguages.reduce<Array<{ path: string; language: string; term: CompsTermTypes; }>>((acc, language) => {
    const filesPAth = termsFolders.map(term => ({ path: `${language}/${term}`, language, term }));
    return [...acc, ...filesPAth];
}, []);

export const initializeComponenetsI18n = async () => {
    await Promise.all(jsonFilesPaths.map(async (file) => {
        try {
            const contents = await require(`./languages/${file.path}.json`);
            i18n.addResourceBundle(file.language, getCompsI18nNS(file.term), contents);
        // eslint-disable-next-line
        } catch (error) { }
    }));
};