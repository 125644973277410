import { FC } from 'react';
import { IConfiguration } from './Integrations';
import { SENTRA_INTEGRATION_ID } from '../../RiskManagement/integrations/sentraIntegration/SentraIntegration.consts';
import { CYERA_INTEGRATION_ID } from '../../RiskManagement/integrations/cyeraintegration/CyeraIntegration.consts';

export const integrationCategory = {
    COLLABORATIONS_AND_MESSAGING: 'CATEGORIES.COLLABORATIONS_AND_MESSAGING',
    TICKETING_SYSTEM: 'CATEGORIES.TICKETING_SYSTEM',
    PLATFORMS_CLOUD_PROVIDERS: 'CATEGORIES.PLATFORMS_CLOUD_PROVIDERS',
    DSPM: 'CATEGORIES.DSPM',
    SSO_CI: 'CATEGORIES.SSO_CI',
    SSO_CG: 'CATEGORIES.SSO_CG',
    CLOUD_SERVICES: 'CATEGORIES.CLOUD_SERVICES',
    THIRD_PARTY: 'CATEGORIES.THIRD_PARTY',
    EVENTS_AND_LOGGING: 'CATEGORIES.EVENTS_AND_LOGGING',
    SYSTEM_AUDIT: 'CATEGORIES.SYSTEM_AUDIT',
    VULNERABILITY_SECURITY_SCANNER: 'CATEGORIES.VULNERABILITY_SECURITY_SCANNER'
};

export const INTEGRATIONS_IDS = {
    SNS: 'SNS',
    SNS_AUDIT: 'snsAudit',
    TENABLE: 'tenable',
    TEAMS: 'Teams',
    GENERIC_WEBHOOK: 'Webhook',
    SPLUNK: 'Splunk',
    SERVICE_NOW: 'ServiceNow',
    SLACK: 'Slack',
    QRADAR: 'QRadar',
    SUMO_LOGIC: 'SumoLogic',
    PAGER_DUTY: 'PagerDuty',
    JIRA: 'Jira',
    GCP_SECURITY_COMMAND_CENTER: 'GcpSecurityCommandCenter',
    AZURE_DEFENDER_FOR_CLOUD: 'AzureDefender',
    AWS_SECURITY_HUB: 'AwsSecurityHub',
    EMAIL: 'Email',
    SENTRA: SENTRA_INTEGRATION_ID,
    CYERA: CYERA_INTEGRATION_ID,
};

export interface IntegrationDataLoader {
    getData: () => Promise<IConfiguration[]>;
}

export interface IntegrationsDefinitionWrapper {
    id: string;
    content: IntegrationsDefinition;
    permission?: () => boolean;
}

export interface IntegrationsDefinition {
    id?: string,
    title: string,
    icon: string,
    category: string,
    configurationCount?: number,
    hasIntegrationPayload?: boolean,
    hasOutputTypeSelection?: boolean,
    configurationUrl?:string,
    testFormatType?: ITestFormatTypeKey,
    onClick?:Function,
    configurationComponent?: FC<any>,
    onDelete?: (configurationID: string) => Promise<any>,
    onSave?: (name: string, configurationObj: any) => Promise<any>,
    SpecialMessage?: FC<{}>
}

export interface ICategoryIntegrationObject {
    [key: string]: IntegrationsDefinition[]
}

export interface IntegrationsSearchBarDefinition{
    type: 'text',
    placeholder: string,
    onChange?: any,
}

export const INTEGRATION_DRAWER = {
    key: 'integrationDrawer',
    eventTypes: {
        configurationChanged: 'configurationChanged',
    },
};

export const CONFIGURATION_DRAWER = {
    key: 'configurationDrawer',
    eventTypes: {
        configurationChanged: 'configurationChanged',
    },
};

export const CONFIGURATION_CHANGED_HANDLER = 'CONFIGURATION_CHANGED_HANDLER';
export const CONFIGURATION_DRAWER_VIEWS = {
    LIST_VIEW: 'listView'
};

export const I18nIntegrations = 'integrations';

export const URL_ENDPOINT_PROTOCOL = 'https://';

export const AUTHENTICATION_TYPE = {
    BASIC: 'Basic',
    BasicAuth: 'BasicAuth',
    NO_AUTHENTICATION: 'No Authentication',
    QRADAR: 'QRadar'
};

export const TEST_FORMAT_TYPE = {
    JSON_WITH_FULL_ENTITY: 'JsonWithFullEntity',
    SPLUNK_BASIC: 'SplunkBasic',
    SERVICE_NOW: 'ServiceNow',
    QRADAR: 'QRadar',
    JSON_FIRST_LEVEL_ENTITY: 'JsonFirstLevelEntity',
    JIRA: 'Jira',
};

export type TEST_FORMAT_TYPE_KEY = keyof typeof TEST_FORMAT_TYPE;
export type ITestFormatTypeKey = typeof TEST_FORMAT_TYPE[TEST_FORMAT_TYPE_KEY];

export enum COMPONENT_TEXT_COLOR {
    SUCCESS = 'success',
    ALERT = 'alert'
}

export enum SUBMIT_STATUS_RESPONSE {
    SUCCESS = 'success',
    FAIL_TEST = 'failTest',
    FAIL_SAVE = 'failSave'
}

export enum INTEGRATION_PAGE_GROUP_TYPE {
    INTEGRATION = 'integration',
    CONFIGURATION = 'configuration'
}

export const CONFIGURATION_CHANGED_HANDLER_ID = 'CONFIGURATION_CHANGED_HANDLER';

export enum BUTTON_STATE {
    ACTIVE = 'active',
    DISABLED = 'disabled'
}

export enum INTEGRATION_PERMISSION_TYPES {
    IS_READ_WRITE = 'isReadWrite',
    IS_READ_ONLY = 'isReadOnly',
    IS_DISABLED = 'isDisabled'
}