import * as React from 'react';
import { SVGProps, Ref, forwardRef } from 'react';
const SvgPciDss = (props: SVGProps<SVGSVGElement>, ref: Ref<SVGSVGElement>) => (
  <svg
    id="PCI_DSS_svg__layer"
    xmlns="http://www.w3.org/2000/svg"
    x={0}
    y={0}
    viewBox="0 0 16 16"
    xmlSpace="preserve"
    ref={ref}
    {...props}
  >
    <style>
      {
        '.PCI_DSS_svg__st0{enable-background:new}.PCI_DSS_svg__st1{fill:#225e63}.PCI_DSS_svg__st2{fill:#fefefe}'
      }
    </style>
    <g transform="translate(0 -8)" id="PCI_DSS_svg__Page-1">
      <g className="PCI_DSS_svg__st0">
        <path
          className="PCI_DSS_svg__st1"
          d="M10.9 16.4h.2s0 .1-.1.1l-.1.1H10.6l-.1-.1v-.2c0-.1 0-.2.1-.2s.1-.1.2-.1h.2c.1 0 .1.1.1.1h-.2s-.1 0-.1.1v.2h.1c-.1.1 0 .1 0 0 0 .1 0 .1 0 0z"
        />
        <path
          className="PCI_DSS_svg__st1"
          d="M11.1 16.4c0-.1 0-.2.1-.2.1-.1.1-.1.2-.1s.2 0 .2.1c.1.1.1.1.1.2v.2l-.1.1h-.4l-.1-.1c.1-.1 0-.2 0-.2zm.2 0v.1h.2v-.2h-.2v.1zM11.9 16.1h.2l.1.4.1-.4h.2v.6h-.1v-.4l-.1.4h-.1l-.2-.5v.4h-.1v-.5z"
        />
        <path
          className="PCI_DSS_svg__st1"
          d="M12.6 16.1h.4v.1c0 .1 0 .1-.1.1 0 0-.1.1-.2.1h-.1v.2h-.2l.2-.5zm.2.2h.1-.2.1zM13.2 16.1h.2v.4h.3v.1h-.5v-.5zM13.8 16.1h.2v.6h-.2v-.6zM14.4 16.6h-.2v.1H14l.2-.6h.2l.2.6-.2-.1zm0-.2-.1-.2-.1.2h.2zM14.7 16.1h.2l.2.3v-.3h.2v.6h-.2l-.2-.3v.3h-.2v-.6zM15.4 16.1h.6v.1h-.2v.4h-.2v-.4h-.2v-.1z"
        />
      </g>
      <g
        id="PCI_DSS_svg__Rectangle-3-_x2B_-Letters"
        transform="translate(0 8.5)"
      >
        <path
          id="PCI_DSS_svg__Rectangle-3"
          className="PCI_DSS_svg__st1"
          d="m8.7 8.5.5-.2-.1-.4c-.1.2-.3.4-.4.6zm-.1-1.8-1-2.3-7.6.5 2.1 5.7 4-1.3c-.3-.4-.3-.8-.1-1 .3-.2.8 0 1.1.4.4-.5 1.3-1.7 1.5-2z"
        />
        <g id="PCI_DSS_svg__Letters" transform="translate(101.996 41.35)">
          <path
            id="PCI_DSS_svg__Oval-1"
            className="PCI_DSS_svg__st2"
            d="M-94.7-35.3c.3 0 .6-.2.6-.5s-.3-.5-.6-.5-.6.2-.6.5.3.5.6.5zm-.5.3h.9v2.4h-.9V-35z"
          />
          <path
            id="PCI_DSS_svg__Path-3"
            className="PCI_DSS_svg__st2"
            d="M-95.6-34.3v-.6s-.1-.1-.5-.1h-.4c-1.4.1-1.4 1.1-1.4 1.1v.2s0 1 1.4 1.1h.4c.3 0 .5-.1.5-.1v-.6s-.1.1-.5.1h-.2c-.6-.1-.7-.6-.7-.6v-.2s0-.5.7-.5h.2c.3.1.5.2.5.2z"
          />
          <path
            id="PCI_DSS_svg__Path-1"
            className="PCI_DSS_svg__st2"
            d="M-100.6-32.6h.9v-.7s1.5.1 1.5-.9c0-.8-.9-.9-1.2-.8h-1.2v2.4zm1-1.3v-.6h.2s.3 0 .3.2v.1c0 .3-.3.3-.3.3h-.2z"
          />
        </g>
        <path
          id="PCI_DSS_svg__Path-7"
          d="M7.3 9.9h.2C7.9 9.7 9 7 10.2 6.2s0-.1-.2-.1c-1 0-2.1 2.2-2.7 3s-.4-.5-.7-.6h-.1s-.2 0-.3.1c-.1.1-.1.1-.1.2v.1c.1.1.5.6.8.9.1.1.1.1.4.1z"
          style={{
            fill: '#2bbc5d',
          }}
        />
      </g>
      <g className="PCI_DSS_svg__st0">
        <path
          className="PCI_DSS_svg__st1"
          d="M10.5 13.8h.9c.2 0 .3 0 .4.1.1 0 .2.1.3.2.1.1.1.2.1.3v.4c0 .2 0 .4-.1.5s-.1.2-.2.3c-.1.1-.2.1-.3.2-.1 0-.2.1-.3.1h-.9l.1-2.1zm.6.5v1h.4c.1 0 .1-.1.1-.1v-.3c0-.2 0-.3-.1-.4-.1-.1-.2-.1-.3-.1h-.1zM12.4 15.1h.6c0 .1 0 .2.1.2.1.1.1.1.3.1.1 0 .1 0 .2-.1l.1-.1s0-.1-.1-.1c0 0-.1-.1-.3-.1-.3-.1-.4-.1-.6-.2-.1-.1-.2-.2-.2-.4 0-.1 0-.2.1-.3.1-.1.1-.2.3-.2.1-.1.3-.1.5-.1s.4 0 .6.1c.1.1.2.2.2.4h-.6c0-.1 0-.1-.1-.2 0 0-.1-.1-.2-.1h-.2c-.1 0-.1.3-.1.3v.1s.1 0 .2.1c.3.1.4.1.6.2.1.1.2.1.2.2.1.1.1.2.1.3 0 .1 0 .2-.1.3-.1.1-.2.2-.3.2-.1.1-.3.1-.5.1-.3 0-.5-.1-.7-.2 0-.1-.1-.3-.1-.5z"
        />
      </g>
      <g className="PCI_DSS_svg__st0">
        <path
          className="PCI_DSS_svg__st1"
          d="M14.3 15.1h.6c0 .1 0 .2.1.2.1.1.1.1.3.1.1 0 .1 0 .2-.1l.1-.1s0-.1-.1-.1c0 0-.1-.1-.3-.1-.3-.1-.4-.1-.6-.2-.1-.1-.2-.2-.2-.4 0-.1 0-.2.1-.3.1-.1.1-.2.3-.2.1-.1.3-.1.5-.1s.4 0 .6.1c.1.1.2.2.2.4h-.6c0-.1 0-.1-.1-.2 0 0-.1-.1-.2-.1H15l-.1.1v.1s.1 0 .2.1c.3.1.4.1.6.2.1.1.2.1.2.2.1.2.1.3.1.4 0 .1 0 .2-.1.3-.1.1-.2.2-.3.2-.1.1-.3.1-.5.1-.3 0-.5-.1-.7-.2 0 0-.1-.2-.1-.4z"
        />
      </g>
    </g>
  </svg>
);
const ForwardRef = forwardRef(SvgPciDss);
export default ForwardRef;
