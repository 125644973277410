import { Datasource } from './datasource';
import { CGColDef, IProtectedAssetViewModel } from 'common/components/ProtectedAssets/ProtectedAssetsTable.interface';
import fileDownload from 'js-file-download';
import dayjs from 'dayjs';
import { getNotificationsService } from '../../interface/services';
import i18next from 'i18next';
import { ProtectedAssetsTableRegistry } from '../../module_interface/assets/ProtectedAssetsTableRegistry';

class AssetsExporter {
    private dataSource: Datasource;

    constructor(dataSource: Datasource) {
        this.dataSource = dataSource;
    }

    public async exportToCsv(withCurrentFilter = false){
        getNotificationsService().info(i18next.t('COMMON.PROTECTED_ASSETS_TABLE.STARTED_EXPORTING'), i18next.t('COMMON.PROTECTED_ASSETS_TABLE.PLEASE_WAIT'));
        try {
            const response = await this.dataSource.exportCsv(false, withCurrentFilter);
            const fileName = `CloudGuard_Protected_Assets${withCurrentFilter && '_Filtered'}_${dayjs(new Date()).format('YYYY MM DD hh:mm A').replace(/\s+/g, '_').replace(/,/g, '')}.zip`;
            fileDownload(response, fileName);
        } catch (err) {
            console.error(err);
            getNotificationsService().error(i18next.t('COMMON.ERROR'), i18next.t('COMMON.PROTECTED_ASSETS_TABLE.EXPORT_ERROR'));
        }
    }

    public async exportToCsvEmail(withCurrentFilter = false, recipents?: string[]){
        getNotificationsService().info(i18next.t('COMMON.PROTECTED_ASSETS_TABLE.STARTED_EXPORTING'),`${i18next.t('COMMON.PROTECTED_ASSETS_TABLE.SENDING_EMAIL')} ${recipents}` );
        try {
            await this.dataSource.exportCsv(true,withCurrentFilter,recipents);
        } catch (err) {
            console.error(err);
            getNotificationsService().error(i18next.t('COMMON.ERROR'), i18next.t('COMMON.PROTECTED_ASSETS_TABLE.EXPORT_ERROR'));
        }
    }

    public async exportAssetsToCsv({ withCurrentFilters = false }) {
        getNotificationsService().info(i18next.t('COMMON.PROTECTED_ASSETS_TABLE.STARTED_EXPORTING'), i18next.t('COMMON.PROTECTED_ASSETS_TABLE.PLEASE_WAIT'));

        try {
            const assetsToExport: IProtectedAssetViewModel[] = await this.dataSource.getDataToExport({ withCurrentFilters });
            let allKeys: string[] = [];
            for (let i = 0; i < assetsToExport.length; i++) {
                if (assetsToExport[i]) {
                    allKeys = allKeys.concat(assetsToExport[i].tags?.map(tag => tag.key) || []);
                }
            }
            const uniqueKeys = Array.from(new Set(allKeys));
            const columnDefs = ProtectedAssetsTableRegistry.getColumnDefs();
            const columnsWithExport: CGColDef[] = columnDefs.filter((col) => col.csvExport)
                .sort((a, b) => (a.csvExport?.order || 0) - (b.csvExport?.order || 0));

            const csvStr = await this.createCsvByTitlesAndModels(columnsWithExport, assetsToExport, uniqueKeys);

            const fileName = `Dome9_ProtectedAssets_${dayjs(new Date()).format('YYYY MM DD hh:mm A').replace(/\s+/g, '_').replace(/,/g, '')}.csv`;
            fileDownload(csvStr, fileName);
        } catch (err) {
            console.error(err);
            getNotificationsService().error(i18next.t('COMMON.ERROR'), i18next.t('COMMON.PROTECTED_ASSETS_TABLE.EXPORT_ERROR'));
        }
    }

    private async createCsvByTitlesAndModels(colDefs: CGColDef[], data: IProtectedAssetViewModel[], uniqueKeys: string[]) {
        const allRows = [];

        // handle header
        const titles: string[] = [];
        colDefs.forEach(colDef => {
            const title = colDef.csvExport?.title || '';
            if (colDef.csvExport?.subTitles) {
                colDef.csvExport.subTitles.forEach(subTitle => {
                    titles.push(`${title}-${subTitle}`);
                });
            } else {
                titles.push(title);
            }
        });
        allRows.push(titles.join(',') + ',' + uniqueKeys.map(key => `Tag: ${key}`).join(','));

        // handle data
        for (let i = 0; i < data.length; i++) {
            const rowData: any[] = [];
            for (let j = 0; j < colDefs.length; j++) {
                const colDef = colDefs[j];
                const value = await colDef.csvExport?.calculateValue(data[i]) || 'N/A';
                if (colDef.csvExport?.subTitles) {
                    const subValues = Array.isArray(value) ? value : null;
                    for (let k = 0; k < colDef.csvExport.subTitles.length; k++) {
                        const subValue = subValues ? subValues[k] : '';
                        rowData.push(subValue);
                    }
                } else {
                    rowData.push(value);
                }
            }
            for (let j = 0; j < uniqueKeys.length; j++) {
                rowData.push(data[i].tags?.find(tag => tag.key === uniqueKeys[j])?.value || 'N/A');
            }
            allRows.push(rowData.join(','));
        }
        return allRows.join('\n');
    }
}

export default AssetsExporter;