import React from 'react';
import { ICellRendererParams } from 'ag-grid-community';
import { IEnvironment } from 'common/interface/environmentsTable';
import { Vendors } from 'common/consts/vendors';
import { A } from 'common/components/Anchor/Anchor';
import { Button, Spinner, Stack, Tooltip } from 'common/design-system/components-v2';
import { Icon } from '@dome9/berries/react-components';
import { useTranslation } from 'react-i18next';
import { FeatureStatusRenderer_Type } from 'common/module_interface/assets/Environments';
import { getOnboardingUrl, getUpdateUrl } from './FeatureStatusRenderer.utils';


interface IFeatureStatusRendererProps extends ICellRendererParams<IEnvironment> {
    type: FeatureStatusRenderer_Type;
    name: string;
    loadingField?: string;
    supportedVendors: Vendors[];
    shouldUpdateField?: string;
}
  
const FeatureStatusRenderer = React.forwardRef<any, IFeatureStatusRendererProps>((props, ref) => {
    const { t } = useTranslation();
    const [isEnabled, setIsEnabled] = React.useState<boolean>(props.value);
    const [shouldUpdate, setShouldUpdate] = React.useState<boolean>(() => {
        if (props.shouldUpdateField) {
            return props.data?.[props.shouldUpdateField] || false;
        }
        return false;
    });
    const [isLoading, setIsLoading] = React.useState<boolean>(() => {
        if (props.loadingField) {
            return props.data?.[props.loadingField] || false;
        }
        return false;
    
    });
    const [isHovered, setIsHovered] = React.useState<boolean>(false);
    const hoverDebounceTimeout = React.useRef<NodeJS.Timeout>();

    const setHovered = React.useCallback((action: 'mouseEnter' | 'mouseLeave') => {
        clearTimeout(hoverDebounceTimeout.current);
        hoverDebounceTimeout.current = setTimeout(() => {
            setIsHovered(action === 'mouseEnter');
        }, 100);
    }, []);

    React.useImperativeHandle(ref, () => {
        return {
            refresh(params: IFeatureStatusRendererProps) {
                if (params.shouldUpdateField) {
                    setShouldUpdate(params.data?.[params.shouldUpdateField] || false);
                }
                if (params.loadingField) {
                    setIsLoading(params.data?.[params.loadingField] || false);
                }
                setIsEnabled(params.value);
                return true;
            },
        };
    });

    const isStatusRelevant = React.useMemo(() => {
        return props.supportedVendors.includes(props.data?.platform as Vendors);
    }, [props]);

    if (!isStatusRelevant || !props.data) {
        return null;
    }

    if (isLoading) {
        return (
            <Stack justifyContent='center' alignItems='flex-start' fullHeight fullWidth>
                <Spinner size={16} />
            </Stack>
        );
    }

    if (shouldUpdate) {
        return (
            <Stack fullHeight fullWidth justifyContent='center' onMouseEnter={() => setHovered('mouseEnter')} onMouseLeave={() => setHovered('mouseLeave')}>
                <A href={getUpdateUrl(props.data, props.type)}>
                    <Stack fullHeight fullWidth justifyContent='center'>
                        <Button
                            variant={isHovered ? 'contained' : 'text'}
                            iconProps={{ name: isHovered ? 'checkCircle' : 'refresh' }}
                            color={isHovered ? 'normal' : 'warning'}
                            label={isHovered ? t('ASSETS.ENVIRONMENTS.CELL_RENDERERS.FEATURE_STATUS.UPDATE.TITLE') : ''}
                            tooltip={t('ASSETS.ENVIRONMENTS.CELL_RENDERERS.FEATURE_STATUS.UPDATE.TOOLTIP')}
                        />
                    </Stack>
                </A>
            </Stack>
        );
    }

    if (!isEnabled) {
        return (
            <Stack fullHeight fullWidth justifyContent='center' onMouseEnter={() => setHovered('mouseEnter')} onMouseLeave={() => setHovered('mouseLeave')}>
                <A href={getOnboardingUrl(props.data, props.type)}>
                    <Stack fullHeight fullWidth justifyContent='center'>
                        <Button
                            variant={isHovered ? 'contained' : 'text'}
                            iconProps={{ name: isHovered ? 'checkCircle' : 'disable' }}
                            color='normal'
                            label={isHovered ? t('ASSETS.ENVIRONMENTS.CELL_RENDERERS.FEATURE_STATUS.ENABLE.TITLE') : ''}
                        />
                    </Stack>
                </A>
            </Stack>
        );
    }

    return (
        <Stack fullHeight fullWidth justifyContent='center' padding={[2,4]}>
            <Tooltip content={t('ASSETS.ENVIRONMENTS.CELL_RENDERERS.FEATURE_STATUS.ENABLED_TOOLTIP', { name: props.name })}>
                <Icon name='checkCircle' color='success' />
            </Tooltip>
        </Stack>
    );
});

FeatureStatusRenderer.displayName = 'FeatureStatusRenderer';


export default FeatureStatusRenderer;