import { buildPath } from '../extensibility/AddinContainer';
import { Vendors } from '../consts/vendors';
import { RulesetVersion } from './ruleset';
import { ISetPolicyResponse } from './services';
import { ReactElement } from 'react';
const PATH_POLICY_VENDORS = buildPath('policy', 'vendors');
export const POLICY_VENDORS = buildPath(PATH_POLICY_VENDORS, 'path policy vendors');
const PATH_POLICY_ENVIRONMENT_VENDORS = buildPath('policy', 'environment', 'vendors');
export const POLICY_ENVIRONMENT_VENDORS = buildPath(PATH_POLICY_ENVIRONMENT_VENDORS, 'path policy environment vendors');

const PATH_POLICY_RULESETS = buildPath('policy', 'rulesets');
const PATH_ENVIRONMENT_POLICY_RULESETS = buildPath('policy', 'rulesets');
const PATH_OU_POLICY_RULESETS = buildPath('policy', 'ou', 'rulesets');
export const POLICY_RULESETS = buildPath(PATH_POLICY_RULESETS, 'path policy rulesets');
export const ENVIRONMENT_POLICY_RULESETS = buildPath(PATH_ENVIRONMENT_POLICY_RULESETS, 'path environment policy rulesets');
export const OU_POLICY_RULESETS = buildPath(PATH_OU_POLICY_RULESETS, 'path ou policy rulesets');
export const POLICY_SERVICE = buildPath('policy', 'service');

export interface IPolicyService{
    getPolicies(useCache:boolean): Promise<IShallowPolicy[]>;
    setPolicy(data:IPolicy[]): Promise<ISetPolicyResponse[]>;
    updatePolicy(data:IPolicy[]): Promise<ISetPolicyResponse[]>;
    deletePolicy(policyId:string): Promise<ISetPolicyResponse[]>;
}

export interface IVendorRuleset {
    getRulesets: () => Promise<IShallowRuleset[]>;
    vendorId: Vendors;
}
export interface IShallowRuleset{
    id: string;
    name?: string;
    icon?: string;
    description?: string;
    availableVersions?: RulesetVersion[];
    platform?: string;
    selectedVersion?: RulesetVersion;
}

export interface Vendor {
    id: Vendors;
    name: Vendors
    icon: string;
    title: string;
}
export interface IShallowPolicy{
    id?:string;
    notificationIds: string[];
}
export interface IPolicy extends IShallowPolicy{
    targetId?: string; //ou id
    errorMessage?: string;
    id?: string;
    notificationIds: string[];
    rulesetId?: number;
    targetExternalId?: string;
    targetInternalId?: string;
    targetType?: string;
    vendor?: string;
    rulesetVersion?: string;
    rulesetPlatform?: string;
    admissionControllerAction?: string | null;
    admissionControlUnScannedAction?: string | null;
    action?: string | null;
    topic?: string;
    provider?: string;
    cloudAccountId?: string;
}

export interface INotificationRequestData {
    name: string;
    alertsConsole: boolean;
    changeDetection: {
        awsSecurityHubIntegrationState: string,
        azureSecurityCenterIntegrationState: string,
        emailPerFindingSendingState: string,
        emailSendingState: string,
        externalTicketCreatingState: string,
        slackIntegrationState: string,
        snsSendingState: string,
        teamsIntegrationState: string,
        webhookIntegrationState: string
    };
    description: string;
    filter: {
        entityIds: [],
        entityNames: [],
        entityTypes: [],
        severities: [],
    } | null;
    gcpSecurityCommandCenterIntegration: { 
        state?: string,
        projectId?: string,
        sourceId?: string,
    };
    scheduledReport: {
        emailSendingState: string;
    };
}

export interface INotification extends INotificationRequestData {
    id: string;
}

export interface ImageAdmissionItem {
    category?: 'unScanned'| 'nonCompliant';
    type?: string | null; // 'Detection' | 'Prevention'
    id: string;
    title: string;
    description: string;
    icon: ReactElement;
    selected?: boolean;
    onClick?: () => void;
    style?: { [key: string]: string };
}

export enum TargetTypesEnum {
    organizationalUnit = 'OrganizationalUnit',
    environment = 'Environment',
    kubernetes = 'Kubernetes',
}