import React, { useMemo } from 'react';
import {
    IGenericWidgetDataItem,
} from 'common/module_interface/overview/Interface';
import _ from 'lodash';
import Legend from 'common/components/Widgets/Legend/Legend';
import { Stack } from 'common/design-system/components-v2';


const DonutLegend: React.FC<{ items?: IGenericWidgetDataItem[] }> = ({ items = [] }) => {
    const totalCount = useMemo(() => _.sumBy(items, 'value'), [items]);
    if (totalCount === 0) {
        return null;
    }
    return (
        <Stack fullWidth>
            <Legend items={items}/>
        </Stack>
    );
};

export default DonutLegend;
