/* eslint-disable react/prop-types */
import Highcharts from 'highcharts';
import HighchartsReact from 'highcharts-react-official';
import { useCallback, useEffect, useMemo, useRef } from 'react';
import { StatusTypes } from '../../common_types';
import {
    IGenericWidgetDataItem,
    IGenericWidgetDataProps
} from 'common/module_interface/overview/Interface';
import { useProfiler } from '@sentry/react';

/**
     * TODO notes here
*/

export enum TitleDisplayType {
    PERCENTAGE_AND_COUNTERS,
    COUNTERS_ONLY
}

const ActivityGaugeChart: React.FC<IGenericWidgetDataProps> = ({ data,header }) => {
    useProfiler( `ActivityGaugeChart|${header}`);

    const defaultThresholds = [
        {
            max: 32,
            min: 0
        },
        {
            max: 74,
            min: 33
        },
        {
            max: 75,
            min: 89
        },
        {
            max: 100,
            min: 90
        },
    ];


    const items = data?.items;
    //For this array always the first item will be the numerator and the second item will be the denominator.
    const numerator = items && items[0] && items[0].value as number || 0;
    const denominator = items && items[1] && items[1].value as number || 0;
    const percentage = (numerator / denominator) || 0;
    const numberAsPercentage = percentage * 100;
    const numberAsPercentageFixedString = numberAsPercentage.toFixed(1);
    const options = data?.options;
    const setThresholds = options?.threshold || defaultThresholds;
    let dangerLimit = 50;
    let warningLimit = 75;
    setThresholds.forEach((threshold: any, index: number) => {
        if(index === 0){
            // to include the max threshold number in the same color class, we add 0.1
            dangerLimit = threshold.max + 0.1;
        } else if(index === 1){
            warningLimit = threshold.max + 0.1;
        }
        return;
    });

    const getColorFromItems = useCallback((items: IGenericWidgetDataItem[]) => {
        const itemWithColor = items.find((item) => item.color);
        return itemWithColor ? itemWithColor?.color : null;
    }, []);

    const getColorFromPercentage = useCallback((numberAsPercentage: number): string => {
        if (numberAsPercentage < dangerLimit) {
            return StatusTypes.DANGER;
        } else if (numberAsPercentage < warningLimit) {
            return StatusTypes.WARNING;
        } else {
            return StatusTypes.SUCCESS;
        }
    }, [dangerLimit, warningLimit]);

    const titleDisplayType: TitleDisplayType = options?.titleDisplayType || TitleDisplayType.PERCENTAGE_AND_COUNTERS;
    let title = '';
    let subtitle: string | undefined;
    const outOfTitle = `${numerator} / ${denominator}`;
    if (titleDisplayType === TitleDisplayType.COUNTERS_ONLY) {
        title = outOfTitle.replaceAll(' ', '');
    } else {
        title =`${numberAsPercentageFixedString}%`;
        subtitle = outOfTitle;
    }

    const chartRef = useRef<any>(null);

    const _options = useMemo(() => {
        return {
            credits: {
                enabled: false,
            },
            title: '',
            chart: {
                backgroundColor: 'transparent',
                type: 'solidgauge',
                renderTo: 'container',
                margin: [0, 0, 0, 0],
                spacingTop: 0,
                spacingBottom: 0,
                spacingLeft: 0,
                spacingRight: 0,
                reflow: true,
            },
            tooltip: {
                enabled: false,
            },
            pane: {
                startAngle: 0,
                endAngle: 360,
                background: [
                    {
                        outerRadius: '100%',
                        innerRadius: '85%',
                        backgroundColor: 'var(--border-color-medium)',
                        borderWidth: 0,
                    },
                ],
            },
            yAxis: {
                min: 0,
                max: 1,
                lineWidth: 0,
                tickPositions: [],
            },
            plotOptions: {
                solidgauge: {
                    linecap: 'round',
                    stickyTracking: false,
                    rounded: true,
                    dataLabels: {
                        enabled: false,
                    },
                },
            },
            series: [
                {
                    name: '',
                    data: [
                        {
                            color: (options?.useCustomColor && items) ? getColorFromItems(items) : `var(--color-${ items && getColorFromItems(items) || getColorFromPercentage(numberAsPercentage)}-default`,
                            radius: '100%',
                            innerRadius: '85%',
                            y: percentage,
                        },
                    ],
                }
            ]
        };
    },[getColorFromItems, getColorFromPercentage, items, numberAsPercentage, options?.useCustomColor, percentage]);

    useEffect(() => {
        const chart = chartRef.current?.chart;

        if (chart) {
            chart.reflow();
        }
    }, [chartRef]);

    const containerProps = useMemo(() => {
        return {
            style: {
                width: '100%',
                display: 'flex',
                position: 'relative',
                height: '100%',
            },
        };
    },[]);

    return (
        <div className={'flex justify-center relative flex-1'}>
            <div className="@container aspect-square flex">
                <div className="flex-1 flex">
                    <HighchartsReact
                        highcharts={Highcharts}
                        containerProps={containerProps}
                        options={_options}
                        ref={chartRef}
                    />
                </div>

                <div className="flex flex-col items-center justify-center absolute inset-0">
                    <div className="flex flex-col gap-3 @[120px]:gap-5 text-center">
                        {items && numerator &&
                            <div
                                className="text-xl text-block @[120px]:text-2xl @[300px]:text-3xl @[400px]:text-4xl  hover:cursor-pointer hover:underline"
                                style={options?.titleStyle}
                                onClick={() => {
                                    items && items[0].onclick && items[0].onclick();
                                }}>
                                {title}
                            </div>
                        }
                        {subtitle && (
                            <div onClick={() => {
                                items && items[0].onclick && items[0].onclick();
                            }}>
                                <div className="border-b"></div>
                                <div
                                    className="text-weak text-block text-2xs mt-5 @[160px]:text-md"
                                    style={options?.subtitleStyle}>
                                    {subtitle}
                                </div>
                            </div>
                        )}
                    </div>
                </div>
            </div>
        </div>
    );
};


export default ActivityGaugeChart;
