import * as React from 'react';
import { SVGProps, Ref, forwardRef } from 'react';
const SvgHelp = (props: SVGProps<SVGSVGElement>, ref: Ref<SVGSVGElement>) => (
  <svg
    viewBox="0 0 16 16"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    ref={ref}
    {...props}
  >
    <path d="M14.5 7.5a7 7 0 1 1-14 0 7 7 0 0 1 14 0Z" stroke="currentColor" />
    <path
      d="M5.5 5.5v-.09A1.91 1.91 0 0 1 7.41 3.5h.207c1.418 0 2.42 1.39 1.971 2.735v0a1.187 1.187 0 0 1-.285.463L7.944 8.06A1.522 1.522 0 0 0 7.5 9.135v0M7.8 11.5a.3.3 0 1 1 0-.019"
      stroke="currentColor"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </svg>
);
const ForwardRef = forwardRef(SvgHelp);
export default ForwardRef;
