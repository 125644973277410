import React, { forwardRef, useState, useRef } from 'react';
import { TextAreaBase, InputFooterWrapper } from './TextArea.styled';
import { InputWrapper } from '../Input/Input.styled';
import { TextAreaProps } from './TextArea.types';
import Stack from '../Stack/Stack';
import IconButton from '../IconButton/IconButton';
import Typography from '../Typography/Typography';
import InputLabel from '../InputLabel/InputLabel';

const TextArea = forwardRef<HTMLTextAreaElement, TextAreaProps>((props, ref) => {
    const {
        label,
        labelProps,
        helperText,
        required,
        placeholder,
        onChange,
        onFocus,
        onBlur,
        clearable,
        startAdornment,
        endAdornment,
        tooltip,
        direction,
        readOnly,
        disabled,
        isError,
        isActive: isActiveProp,
        fullWidth = false,
        value,
        defaultValue,
        wrapperStyles,
        wrapperClassName,
        inputStyles,
        inputClassName,
        wrapperRef,
        inputWrapperRef,
        inputSize = 'normal',
        maxLength ,
        ...rest
    } = props;

    const [currentValue, setCurrentValue] = useState(value || defaultValue || '');
    const [isActive, setIsActive] = useState(isActiveProp || false);
    const inputRef = useRef<HTMLTextAreaElement>(null);

    React.useEffect(() => {
        if (value !== undefined) {
            setCurrentValue(value);
        }
    }, [value]);

    React.useEffect(() => {
        if (ref) {
            if (typeof ref === 'function') {
                ref(inputRef.current);
            } else {
                ref.current = inputRef.current;
            }
        }
    }, [ref]);

    const handleChange = (event: React.ChangeEvent<HTMLTextAreaElement>) => {
        setCurrentValue(event.target.value);
        onChange?.(event);
    };

    const handleFocus = (event: React.FocusEvent<HTMLTextAreaElement, Element>) => {
        setIsActive(true);
        onFocus?.(event);
    };

    const handleBlur = (event: React.FocusEvent<HTMLTextAreaElement, Element>) => {
        setIsActive(false);
        onBlur?.(event);
    };

    const handleOnClear = () => {
        if (inputRef.current) {
            if (inputRef.current.value === '') return;
            const event = new CustomEvent<React.ChangeEvent<HTMLTextAreaElement>>('change', { bubbles: true });
            inputRef.current.dispatchEvent(event);
            inputRef.current.value = '';
            handleChange(event as any);
        }
    };

    return (
        <Stack
            data-aid='input-text'
            spacing={2}
            alignItems={'baseline'}
            direction={direction === 'horizontal' ? 'row': 'column'}
            fullWidth={fullWidth}
            style={wrapperStyles}
            className={wrapperClassName}
            ref={wrapperRef}
        >
            {label && <InputLabel text={label} required={required} tooltip={tooltip} {...labelProps} />}
            <Stack fullWidth justifyContent={'flex-start'} spacing={2} direction={'column'}>
                <InputWrapper
                    spacing={inputSize === 'normal' ? 2 : 4}
                    data-aid='input-wrapper'
                    direction='row'
                    alignItems='flex-start'
                    fullWidth={fullWidth}
                    readOnly={readOnly}
                    isActive={isActive}
                    disabled={disabled}
                    isError={isError}
                    inputSize={inputSize}
                    ref={inputWrapperRef}
                >
                    {startAdornment && startAdornment}
                    <TextAreaBase
                        ref={inputRef}
                        value={currentValue}
                        aria-rowcount={4}
                        aria-multiline={true}
                        readOnly={readOnly}
                        onChange={handleChange}
                        placeholder={placeholder}
                        disabled={disabled}
                        onFocus={handleFocus}
                        onBlur={handleBlur}
                        isError={isError}
                        style={inputStyles}
                        className={inputClassName}
                        {...rest}
                    />
                    {(clearable && currentValue) && <IconButton onClick={handleOnClear} iconProps={{ name: 'remove', size: 16 }} size={'small'} disabled={readOnly} />}
                    {endAdornment && endAdornment}
                </InputWrapper>
                <InputFooterWrapper inputSize={inputSize} fullWidth={fullWidth} justifyContent={'space-between'} alignItems={'flex-start'} spacing={2} direction={'row'}>
                    <Stack justifyContent={'flex-start'} direction={'column'}>
                        {helperText && <Typography color={isError ? 'alert' : 'strong'} variant={'xs'}>{helperText}</Typography>}
                    </Stack>
                    { maxLength ? (<Stack justifyContent={'flex-end'} direction={'row'}>
                        <Typography color={currentValue.length > maxLength ? 'alert' : 'strong'} variant={'xs'}>{currentValue.length}/{maxLength}</Typography>
                    </Stack>) : null}
                </InputFooterWrapper>
            </Stack>
        </Stack>
    );
});

TextArea.displayName = 'TextArea';

export default TextArea;
