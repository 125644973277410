import { buildPath, globalAddinContainer } from '../../../extensibility/AddinContainer';
import { FINDINGS_TABLE } from '../Intelligence.const';
import { IRightHeaderPanelComponent } from './Findings.interface';


const PATH_FINDING_OVERVIEW = buildPath(FINDINGS_TABLE, 'finding overview');
const PATH_FINDING_OVERVIEW_RIGHT_PANEL = buildPath(PATH_FINDING_OVERVIEW, 'right header panel');

export const FindingOverviewRegistry = {
    addRightHeaderPanelProvider: (content: IRightHeaderPanelComponent, position: number) => {
        globalAddinContainer.add<IRightHeaderPanelComponent>(PATH_FINDING_OVERVIEW_RIGHT_PANEL, [{
            id: content.id,
            position,
            content,
        }]);
    },

    getRightHeaderPanelProviders: (): IRightHeaderPanelComponent[] => {
        return globalAddinContainer.get<IRightHeaderPanelComponent>(PATH_FINDING_OVERVIEW_RIGHT_PANEL);
    },
};
