import { v4 } from 'uuid';

export const IFRAME_MESSAGE_SOURCES = {
    CLOUD_INFRA: 'cloud_infra',
    WEBAPP: 'webapp',
    CLOUD_GUARD: 'cloud_guard',
};

export const IFRAME_MESSAGE_ACTIONS = {
    LOGIN_SUCCESS: 'login_success',
    UPDATE_STATE: 'update_state',
    RELOAD_STATE: 'reload_state',
    FIRST_PAINT_STATUS: 'first_paint_status',
    PAGE_MUTATION_STATUS: 'page_mutation_status',
    PAGE_LOADED: 'page_loaded',
    API_STATUS: 'api_status',
    PAGE_TITLE_UPDATE: 'page_title_update',
    URL_UPDATE: 'url_update',
    QUERY_PARAMS_UPDATE: 'query_params_update',
    AUTHENTICATE_WITH_CLOUD_INFRA: 'authenticate_with_cloud_infra',
    REFRESH_TOKEN: 'refresh_token',
    CLOUD_INFRA_ERROR_PAGE: 'cloud_infra_error_page',
    REDIRECT: 'redirect',
    LOGOUT: 'logout',
    FORCE_REFRESH: 'force_refresh',
    UPDATE_TABPANEL_TABNAME: 'update_tabpanel_tabname',
    IFRAME_READY: 'frame_ready',
    AUTHENTICATE_TOKEN_SUCCESS: 'authenticate_token_success',
    AUTHENTICATE_TOKEN_FAILED: 'authenticate_token_failed',
    REFRESH_TOKEN_FAILED: 'refresh_token_failed',
    APP_SWITCH: 'app_switch',
    TENANT_SWITCH: 'tenant_switch',
    DASHBOARDS_CHANGED: 'dashboards_changed',
    NAVIGATE: 'navigate',
    USER_NAVIGATED: 'user_navigated',
    TOAST: 'toast',
    CHANGE_LANGUAGE: 'change_language',
    ONBOARDING: {
        SET_NEW_AWS_VIEW: 'set_new_aws_view',
    },
    MENU: {
        CHANGE: 'menu_change',
        SUB_MENU: {
            CHANGE: 'sub_menu_change',
            RENAME: 'sub_menu_rename',
            REMOVE: 'sub_menu_remove',
            ADD: 'add',
            CHIP: 'chip',
        },
    },
    DASHBOARD: {
        ADD: 'new dashboard',
        DELETE: 'delete dashboard',
        RENAME: 'rename dashboard',
        FAVORITE: {
            ADD: 'add favorite dashboard',
            REMOVE: 'remove favorite dashboard',
            DELETE: 'delete favorite dashboard',
            RENAME: 'rename favorite dashboard',
            CHANGE: 'change favorite dashboard'
        },
        DEFAULT: {
            ADD: 'add default dashboard',
            REMOVE: 'remove default dashboard',
            DELETE: 'delete default dashboard',
            RENAME: 'rename default dashboard',
            CHANGE: 'change default dashboard',
        },
    },
    CLEAR_CACHE: 'clear cache',
    TOGGLE_ANGULAR_REACT: {
        PROTECTED_ASSETS: {
            OLD_PROTECTED_ASSETS_ON: 'old protected assets on',
            OLD_PROTECTED_ASSETS_OFF: 'old protected assets off'
        }
    },
    USER_ACTIVE: 'user_active',
    SET_BREADCRUMBS_LAST_CHILD: 'set breadcrumbs last child',
    NOTIFY_AWS_PAGE_ON_NOTIFICATIONS: 'notify aws page on notifications',
};

export const IFRAME_TAGS = {
    CLEAR_CACHE: {
        ENVIRONMENTS: 'Environments',
        POLICIES: 'policies'
    }
};


export interface IiframeMessage {
    id?: string;
    source?: string;
    action: string;
    data?: object;
}

export default class IframeMessageModel {
    id: string;
    source: string;
    action: string;
    data: any;

    constructor({ source = IFRAME_MESSAGE_SOURCES.CLOUD_GUARD, action, data, id = '' }: IiframeMessage) {
        this.id = id || v4();
        this.source = source?.toLowerCase();
        this.action = action?.toLowerCase();
        this.data = data;
    }
}
