import { forwardRef, useState } from 'react';
import { useTheme } from 'styled-components';
import AlertStyles from './Alert.styles';
import { IAlertProps } from './Alert.types';
import { iconProps, } from './Alert.consts';
import Stack from '../Stack';
import Icon from '../Icon';

const Alert = forwardRef<HTMLDivElement, IAlertProps>((props, ref) => {
    const [isDisplay, setIsDisplay] = useState(true);
    const theme = useTheme();
    const { children, onClose, withCloseButton, type = 'info', iconSize, hideStatusIcon, ...rest } = props;
    const iconArgs = iconProps(type, theme, iconSize);

    if(!isDisplay) return null;

    const handleOnClose = () => {
        setIsDisplay(false);
        onClose && onClose();
    };

    return (
        <AlertStyles.Wrapper type={type} ref={ref} alignItems='center' direction='row' {...rest}>
            <Stack fullWidth direction={'row'} alignItems={'center'} spacing={2} padding={3}>
                {!hideStatusIcon && <AlertStyles.StatusIcon {...iconArgs} />}
                <Stack fullWidth>{children}</Stack>
                {withCloseButton && <AlertStyles.IconWrapperClose onClick={handleOnClose}><Icon name="remove" /></AlertStyles.IconWrapperClose>}
            </Stack>
        </AlertStyles.Wrapper>
    );
});

Alert.displayName = 'Alert';

export default Alert;
