export const FILTER_PANEL_CONSTS = {
    CLEAR_ALL_BUTTON_TEXT: 'Clear All',
    SAVED_FILTERS_BUTTON_TEXT: 'Favorites',
    TAG_FILTER_KEY: 'Key',
    TAG_FILTER_VALUE: 'Value',
    ADD_FILTER_RANGE: 'range',
    ADD_FILTER_TREE: 'tree',
    ADD_FILTER_TAG: 'tag',
    ADD_FILTER_MULTI_TAG: 'multi-tag',
    ADD_FILTER_ADD_ITEM: 'add-item',
    ADD_FILTER_COUNT: 'count',
};

export const FILTER_EVENTS = {
    CLEAR_FILTERS: 'clear filters',
    FILTER_CHANGED: 'Set filters',
    HANDLER_CHANGED: 'handler changed',
    SAVED_FILTER_SELECTED: 'Saved Filter Selected',
    SAVED_FILTER_DELETED: 'Saved Filter Deleted',
    FILTER_ADDED: 'Filter Added',
    SHOW_VIEW: 'Display filters view',
    BOX_FILTER_OPEN: 'Box Filter Open',
    BOX_FILTER_SECTION_OPENED: 'Box Filter Section Opened',
};

//key of some filters should be like in saved filters key
export const FILTERS_KEYS = {
    FREE_TEXT: 'free-text',
    ADD_FILTER: 'add-filter',
    DATE_PICKER: 'date-picker',
    CLEAR_BUTTON: 'clear-button',
    SAVED_FILTERS: 'saved-filters',
    RECENTLY_USED_FILTERS: 'recently-used-filters',
    FIELDS: 'fields',
    MULTISELECT_FILTER: 'multiselect-filter',
    ALL: 'all',
    TAGS: 'tags',
    GSL: 'gsl',
};

export const FILTERS_API_OBJECT_KEYS = {
    FREE_TEXT: 'freeTextPhrase',
    DATE_PICKER: 'creationTime',
    FIELDS: 'fields',
};

export const FILTER_TYPES = {
    FILTER: 'Filter',
    FILTER_OUTSIDE: 'FilterOutside',
    HANDLER: 'Handler',
    HANDLER_CLEAR: 'HandlerClear',
    HANDLER_OUTSIDE: 'HandlerOutside',
};

export const FILTER_DISPAY_TYPES = {
    ROW: 'row-view',
    BOX: 'box-view',
    SEPARATE_FIELDS: 'separate-fields'
};

export const FILTER_BOX_DISPLAY_TYPES = {
    BOX_CONTENT_1: 'Box_Content_1',
    BOX_CONTENT_2: 'Box_Content_2',
    BOX_BUTTON: 'Filter panel dropdown button',
};

export const defaultContainerQuery = {
    'hide-btn-labels chip--xs': {
        maxWidth: 1000,
    },
};

export enum FILTER_PANEL_QUERY_NAMES {
    QUERY = 'query',
    FILTER_PANEL = 'filterPanel',
}

export const RECENTLY_USED = 'recently-used';
export const FAVORITES = 'favorites';
