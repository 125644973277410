import React, { useEffect, useMemo, useRef, useState } from 'react';
import { Button, Tooltip } from '@dome9/berries/react-components';
import i18n from 'i18next';
import { Icon, CopiableLine } from 'common/design-system/components-v2';

export interface InfoPaneFieldProps {
    title: string;

    value: string | React.FC<any>;

    componentProps?: any;
}

export interface InfoPanelActionProps {
    component: React.FC<any>;

    componentProps?: any;
}

export type InfoPaneInnerProps = InfoPanelActionProps | InfoPaneFieldProps;

export interface InfoPaneProps {
    title: string;
    icon: string;

    fields: InfoPaneFieldProps[];
    actions?: InfoPanelActionProps[];
}

export const InfoPaneItem: React.FC<InfoPaneFieldProps> = (props) => {
    const [showTooltip, setShowTooltip] = useState<boolean>(false);
    const textRef = useRef<HTMLDivElement>(null);

    const component = useMemo(() => {
        if (typeof props.value === 'string') {
            const copiableTextLine = <CopiableLine value={props.value}>
                <div className='max-w-[20ch] truncate' ref={textRef}>{props.value}</div>
            </CopiableLine>;
            return (
                <div className='flex flex-1 gap-5 group'>
                    {showTooltip ? <Tooltip content={props.value}>{copiableTextLine}</Tooltip> : copiableTextLine }
                </div>);
        } else {
            if (props.value) {
                const SubComponent: React.FC = props.value as React.FC;
                return (<SubComponent {...props.componentProps} />);
            } else {
                const error = i18n.t('COMMON.PROTECTED_ASSET.DETAILS_PANE.UNKNOWN');
                return (<div>{error}</div>);
            }
        }
    }, [showTooltip, props.componentProps, props.value]);

    useEffect(() => {
        setShowTooltip(textRef.current ? (textRef.current.scrollWidth > textRef.current.clientWidth) : false);
    }, []);

    return (<div className='group'>
        <div className='prop-container'>
            <div className='text-weak text-sm prop-title'> {props.title}</div>
            <div className='flex field-component'>
                {component}
            </div>
        </div>
    </div>);

};
const InfoPane: React.FC<InfoPaneProps> = (props) => {

    const [showDetails, setShowDetails] = useState(true);

    const showDetailsTitle = i18n.t('COMMON.PROTECTED_ASSET.DETAILS_PANE.SHOW_DETAILS');
    const hideDetailsTitle = i18n.t('COMMON.PROTECTED_ASSET.DETAILS_PANE.HIDE_DETAILS');
    const detailsTitle = showDetails ? hideDetailsTitle : showDetailsTitle;
    const detailsIconName = showDetails ? 'chevron-down' : 'chevron-right';

    return <div className='bg-content border-b '>
        <div className='flex flex-1 items-center bg-content p-6'>
            <div className='flex items-center flex-1'>
                <div className='flex items-center ml-5'>
                    <Icon vendorNameOrPath={props.icon || ''} className={'mr-6 flex-0'} size={32} />
                    <div className='truncate flex-1 text-xl font-bold'>
                        {props.title}
                    </div>
                    <div className='border-r self-stretch ml-8 border-dotted'></div>
                    <Button className='btn--integrated ml-2 flex-0 mr-5' label={detailsTitle} icon={detailsIconName}
                        iconSize={6}
                        onClick={() => {
                            setShowDetails(!showDetails);
                        }}
                        data-aid='show-hide-details-button'></Button>
                </div>
                <div className='ml-auto flex items-center space-x-5 flex-0'>
                    {props.actions?.map((item, index) => {
                        const SubComponent = item.component;
                        return (<SubComponent key={index} {...item.componentProps} />);
                    })
                    }
                </div>
            </div>
        </div>
        {showDetails && <div
            className='flex-1 entity-details grid grid-cols-[repeat(auto-fit,_minmax(120px,_1fr))] gap-8 p-8 max-h-[140px]'>
            {props.fields.map((field, index) => {
                return <InfoPaneItem key={index} {...field} />;
            })
            }
        </div>}
    </div>;
};

export default InfoPane;


