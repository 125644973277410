import React from 'react';
import './Legend.scss';
import { IGenericWidgetDataItem } from 'common/module_interface/overview/Interface';
import { v4 } from 'uuid';
import { formatNumbersForDisplay } from '../helpers';
import { Tooltip } from '@dome9/berries/react-components';
import { Stack, Typography } from '../../../design-system/components-v2';

export interface ILegendProps {
    items: IGenericWidgetDataItem[];
}
const Legend: React.FC<ILegendProps> = ({ items }) => {

    return (
        <div className={'legend-container'}>
            <div className={'legend scrollbar--inner'}>
                {items.length > 0 && items.map((item, index) => {
                    if (item.isHidden) return null;
                    const isClickable = item.onclick;
                    const formattedValue = typeof item.value === 'number' ? formatNumbersForDisplay(item.value) : item.value;
                    return (
                        <div onClick={ () => item.onclick && item.onclick() } className="legend__item" key={index+v4()}>
                            {item.color && (
                                <div className={`self-center legend__bullet flex-0 color-box ${isClickable && 'cursor-pointer'}`} style={{ background: `${item?.color}` }}></div>
                            )}
                            <Tooltip content={item?.key}>
                                <div className={`legend__item__name ${isClickable && 'hover:underline cursor-pointer' }`}>
                                    {item?.key}
                                </div>
                            </Tooltip>
                            <div className="legend__item__spacer"></div>
                            <Tooltip content = {item.tooltip || formattedValue}>
                                <Stack spacing={1} direction="row" className={`legend__item__value ${isClickable && 'hover:underline cursor-pointer'}`}>
                                    <Typography variant="body500" className="text-gray-400">{formattedValue}</Typography>
                                    {item.subtitle && <Typography variant="body" className="text-gray-100">{item.subtitle}</Typography>}
                                </Stack>
                            </Tooltip>
                        </div>
                    );
                })}
            </div>
        </div>
    );
};

export default Legend;