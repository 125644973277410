import React from 'react';
import { ICellRendererParams } from 'ag-grid-enterprise';
import { IFieldInfo } from '../../../interface/general';
import { DEFAULT_ORGANIZATIONAL_UNIT_ID } from '../../../consts/DataConsts';
import { FindingsAlertType, IFindingModel } from '../../Findings/Findings.interface';
import IconLinkCellRenderer, { IIconLinkCellProps } from './IconLinkCellRenderer';
import { getEventsUrl } from '../../../module_interface/assets/utils';
import { logComponentClickedEvent } from '../../../utils/logUtils';
import { changeUrl, generateHref } from '../../../utils/http';
import { getSafeFindingSeverityInfo } from '../../../consts/FindingSeverity';

const EventTitleCellRender: React.FC<ICellRendererParams<IFindingModel> & { componentName?: string } > = (params) => {
    if (!params.data) {
        return (<div />);
    }

    const componentName = params.componentName;
    const { entityExternalId, ruleName, severity } = params.data;
    const filterFields: IFieldInfo[] = [
        {
            name: 'entityExternalId',
            value: entityExternalId,
        },
        {
            name: 'organizationalUnitId',
            value: DEFAULT_ORGANIZATIONAL_UNIT_ID,
        },
        {
            name: 'severity',
            value: severity,
        },
    ];

    let url: string = getEventsUrl(FindingsAlertType.SECURITY, ruleName, filterFields);
    if (!url.startsWith('/')) {
        url = `/${url}`;
    }

    const severityInfo = getSafeFindingSeverityInfo(severity);
    
    const options: IIconLinkCellProps = {
        isLink: true,
        value: ruleName || '',
        href: generateHref(url),
        onClick: (event) => {
            event.preventDefault();
            if (componentName) {
                logComponentClickedEvent(componentName);
            }
            changeUrl(url);
        },
        levelIconProps: {
            iconProps: {
                name: 'securityEvent',
            },
            category: 'severity',
            level: severityInfo.key,
            size: 'md',
        }
    };
    return <IconLinkCellRenderer {...options} />;
};

export default EventTitleCellRender;
