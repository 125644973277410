import React from 'react';
import { LevelBox } from './LevelBox';
import { ListStyle } from './LevelBoxes.styled';
import { ILevelBoxItem } from './LevelBox.interface';

export const LevelBoxes: React.FC<{ items: ILevelBoxItem[] }> = ({ items }) => {
    return (
        <ListStyle.TopDiv>
            {items.map((item: ILevelBoxItem) => <LevelBox item={item} key={item.text} />)}
        </ListStyle.TopDiv>
    );
};
