import { FC, useCallback, useMemo } from 'react';
import { FormStyled } from '../../CustomForm.styled';
import { ErrorComponent } from '../helperComponents/ErrorComponent';
import { FieldLabelComponent } from '../helperComponents/FieldLabelComponent';
import { convertDatesToValues, convertValuesToDates } from '../../CustomForm.values';
import { IFullErrorInfo, IOnFieldChanged, IDateRangeItem } from '../../CustomForm.interface';
import { DatePicker } from '../../../../../design-system/components-v2';
import { IDateRange } from '../../../../../design-system/components-v2/DatePicker/DatePicker.types';

export interface IDateRangeItemComponentProps {
    item: IDateRangeItem;
    onFieldChanged: IOnFieldChanged;
    showErrors: boolean;
    errorInfo?: IFullErrorInfo;
    skipTitle?: boolean;
    isInner?: boolean;
    now: Date;
}

export const DateRangeItemComponent: FC<IDateRangeItemComponentProps> = ({ item, onFieldChanged, errorInfo, showErrors, skipTitle, isInner, now }) => {
    const { state } = item;
    const range: IDateRange = useMemo(() => convertValuesToDates(state.value), [state.value]);

    const onChange = useCallback((date: IDateRange | undefined) => {
        const values: string[] | undefined = date ? convertDatesToValues(date) : undefined;
        onFieldChanged({ ...item, state: { ...item.state, value: values } });
    }, [item, onFieldChanged]);

    return (
        <FormStyled.TopItem widthInfo={state.widthInfo} isInner={isInner}>
            {!skipTitle && <FieldLabelComponent item={item} />}
            <DatePicker
                type={'range'}
                value={range}
                onChange={onChange}
                disabled={state.disabled}
                readOnly={state.readonly}
                datePickerProps={{
                    fromDate: state.allowPast ? undefined : now,
                }}
            />
            <ErrorComponent showErrors={showErrors} errorInfo={errorInfo} />
        </FormStyled.TopItem>
    );
};
