export enum INTELLIGENCE_SOURCE {
    flowlogs = 'vpcfl',
    cloudtrail = 'cloudtrail',
    alerts = 'alerts',
    azurect = 'azurect',
    azurefl = 'azurefl',
    kubernetes = 'k8s',
    kubernetescloudtrail = 'k8sct'
}

export enum INTELLIGENCE_TYPES {
    ACCOUNT_ACTIVITY = 'accountActivity',
    NETWORK_TRAFFIC = 'networkActivity'
}

export const INTELLIGENCE_SOURCES_MAP: Record<string, any> = {
    'AWS': {
        'accountActivity': INTELLIGENCE_SOURCE.cloudtrail,
        'networkActivity': INTELLIGENCE_SOURCE.flowlogs,
    },
    'ALERTS': INTELLIGENCE_SOURCE.alerts,
    'AZURE': {
        'accountActivity': INTELLIGENCE_SOURCE.azurect,
        'networkActivity': INTELLIGENCE_SOURCE.azurefl,
    },
    'KUBERNETES': {
        'accountActivity': INTELLIGENCE_SOURCE.kubernetes,
        'networkActivity': INTELLIGENCE_SOURCE.kubernetescloudtrail,
    },
};
export const GSL_CLAUSES = {
    WHERE: 'where',
    SUMMARIZE: 'summarize',
    FACET_BY: 'facet by',
    JOIN_MITRE: 'join mitre',
    ORDER_BY: 'order by',
    LIMIT: 'limit',
    COUNT: 'count',
    DISTINCT_COUNT: 'dcount',
    UNIX_TO_DATETIME: 'unixtime_milliseconds_todatetime',
};
