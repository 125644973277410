import { EnhancedStore } from '@reduxjs/toolkit';
import { IStoreService } from '../../interface/services';
import { createStore } from './store';

class StoreService implements IStoreService {
    private _store?: EnhancedStore;
    readonly dispatch: (action: any) => any;

    constructor() {
        this.dispatch = (action: any) => {
            return this.store.dispatch(action);
        };
    }

    get store() {
        if (!this._store) {
            this._store = createStore();
        }
        return this._store;
    }

    get state() {
        return this.store.getState();
    }

    getReduxTools(): { dispatch: (action: any) => void; state: any } {
        return {
            dispatch: this.dispatch,
            state: this.state,
        };
    }
}

export default StoreService;
