import * as React from 'react';
import { SVGProps, Ref, forwardRef } from 'react';
const SvgWorkloadProtection = (
  props: SVGProps<SVGSVGElement>,
  ref: Ref<SVGSVGElement>
) => (
  <svg
    viewBox="0 0 16 16"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    ref={ref}
    {...props}
  >
    <path
      d="m6 8 1.5 1.5 3-3M8.732.824c1.73.84 2.773 1.858 4.77 2.115.548.07.998.507.998 1.06V8.5c0 3.582-4.118 5.897-5.799 6.692a1.627 1.627 0 0 1-1.402 0C5.619 14.397 1.5 12.082 1.5 8.5V3.998c0-.552.45-.989.998-1.06 1.997-.256 3.04-1.273 4.77-2.114a1.675 1.675 0 0 1 1.464 0Z"
      stroke="currentColor"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </svg>
);
const ForwardRef = forwardRef(SvgWorkloadProtection);
export default ForwardRef;
