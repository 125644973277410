import * as React from 'react';
import { SVGProps, Ref, forwardRef } from 'react';
const SvgPartiallyConnectedStatus = (
  props: SVGProps<SVGSVGElement>,
  ref: Ref<SVGSVGElement>
) => (
  <svg
    id="partially-connected-status_svg__Layer_1"
    xmlns="http://www.w3.org/2000/svg"
    viewBox="0 0 16 16"
    ref={ref}
    {...props}
  >
    <defs>
      <style>{'.partially-connected-status_svg__cls-1{fill:#2e3f58}'}</style>
    </defs>
    <path
      className="partially-connected-status_svg__cls-1"
      d="m5.98 9.21-.65-.73c-.73-.73-.81-1.86-.32-2.75L3.8 4.52l.73-.73 1.29 1.29c.81-.57 2.02-.4 2.75.32l.65.65L5.99 9.2ZM9.94 6.79l.65.65c.73.73.81 1.86.32 2.75l1.29 1.29-.73.73-1.29-1.29c-.81.57-2.02.4-2.75-.32l-.65-.65L9.93 6.8Z"
    />
    <circle
      cx={8}
      cy={8}
      r={7.4}
      style={{
        fill: 'none',
        stroke: '#2e3f58',
        strokeDasharray: '0 0 1.94 1.94',
        strokeMiterlimit: 10,
        strokeWidth: '.93px',
      }}
    />
  </svg>
);
const ForwardRef = forwardRef(SvgPartiallyConnectedStatus);
export default ForwardRef;
