import React, { useCallback, useState } from 'react';
import { Button , Icon } from '@dome9/berries/react-components';
import WizardStyle from './Wizard.styled';
import { useTranslation } from 'react-i18next';

interface WizardProps {
    title: string;
    steps: WizardStep[];
    onClose?: ()=> any;
    doneButtonText?: string;
    extraButton?:{
        text: string;
        index: number;
        parameter: any;
    };
    ShowBackButtonInLastStep?: Boolean;
}

export type RenderViewParams = {
    onValidate: OnValidate
}

export type OnValidate = (valid: boolean,backEnabled?: boolean) => void;

export interface WizardStep {
    id?: string;
    name: string;
    renderView: (onValidate: RenderViewParams)=> any;
    onBack?: ()=> any;
    onNext: (nextProp:any)=> any;
}

interface validationDict{
    [key:number]: boolean
}


export const Wizard: React.FC<WizardProps> = ({ steps, title, onClose, doneButtonText, extraButton, ShowBackButtonInLastStep= true }) => {

    const [currentStepIndex, setCurrentStepIndex] = useState(0);
    const [validationDict, setValidationDict] = useState<validationDict>({});
    const [backEnabled, setBackEnabled] = useState<boolean>(true);
    const { t } = useTranslation();

    const onWizardBack = async () => {
        try{
            await steps[currentStepIndex]?.onBack?.();
            setCurrentStepIndex(prevCurrentStepIndex => prevCurrentStepIndex - 1);
        }
        catch(err) {
            console.error(err);
        }
    };

    const onWizardNext = async (extraButtonParameter :any) => {
        try{
            await steps[currentStepIndex].onNext(extraButtonParameter);
            if(currentStepIndex < steps.length-1){
                setCurrentStepIndex(prevCurrentStepIndex => prevCurrentStepIndex + 1);
            }
            else{
                setCurrentStepIndex(0);
            }
        }
        catch(err) {
            console.error(err);
        }
    };

    const onValidate = useCallback((isValid: boolean, backEnabled = true) => {
        setBackEnabled(backEnabled);
        setValidationDict(prevDict => ({
            ...prevDict,
            [currentStepIndex]: isValid
        }));
    }, [currentStepIndex]);

    return (
        <WizardStyle.Wrapper>
            <WizardStyle.Header>
                <div className={'title'}>{title}</div>
                { onClose && (
                    <div className={'close-wizard'} onClick={onClose}>
                        <Icon name='remove' customColor={'color-fg-white'} ></Icon>
                    </div>
                )}
            </WizardStyle.Header>
            <WizardStyle.Body>
                <div className={'sidebar'}>
                    {steps &&
                        steps.map((stepItem: any, index: number) => {
                            return (
                                <div className='step-container' key={index}>
                                    <div className={`step ${index === currentStepIndex ? 'active' : ''}`}>
                                        <div className={`step-number ${index < currentStepIndex ? 'done' : ''}`}>
                                            {index < currentStepIndex ? <Icon size={14} color='primary' name='check'></Icon> : index + 1}
                                        </div>
                                        <div className='step-name'>{stepItem.name}</div>
                                    </div>
                                    {index < steps.length-1 && (
                                        <div className={`step-line ${index <= currentStepIndex ? 'active' : ''}`}/>
                                    )}
                                </div>
                            );
                        })}
                </div>
                <div className={'container'}>
                    {steps[currentStepIndex].renderView({ onValidate })}
                </div>
            </WizardStyle.Body>
            <WizardStyle.Footer>
                <div className={'footer-sidebar'}></div>
                <div className={'footer-actions'}>
                    {currentStepIndex > 0 && (currentStepIndex !== steps.length-1 || ShowBackButtonInLastStep) && <Button {...{ onClick: onWizardBack }} dataAid='wizard-back' disabled={!backEnabled}>{t('COMMON.BACK')} </Button>}
                    {(extraButton && extraButton.index === currentStepIndex) && <Button { ...{
                        disabled: !validationDict[currentStepIndex],
                        onClick: ()=> onWizardNext(extraButton.parameter) }} >{ extraButton.text }</Button>}
                    {currentStepIndex < steps.length-1 && <Button dataAid='wizard-next' {...{
                        color: 'primary',
                        disabled: !validationDict[currentStepIndex],
                        onClick: ()=> onWizardNext(null) }} >{t('COMMON.NEXT')}</Button>}
                    {currentStepIndex === steps.length-1 && <Button dataAid='wizard-done' {...{
                        color: 'primary',
                        disabled: !validationDict[currentStepIndex],
                        onClick: ()=> onWizardNext(null) }} >{ doneButtonText || t('COMMON.DONE') }</Button>}
                </div>
            </WizardStyle.Footer>
        </WizardStyle.Wrapper>
    );
};

export default Wizard;
