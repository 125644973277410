import React from 'react';
import { IListItemProps, IListProps } from './List.types';
import ListStyles from './List.styles';
import Label from '../Label/Label';
import Stack from '../Stack/Stack';
import Checkbox from '../Checkbox/Checkbox';
import Tooltip from '../Tooltip/Tooltip';
import Link from '../Link/Link';
import IconButton from '../IconButton/IconButton';


interface IListItemWrapperProps extends IListItemProps {
    children: React.ReactNode;
}
export const ListItemWrapper = React.forwardRef<HTMLDivElement, IListItemWrapperProps>((props, ref) => {
    const { linkProps, tooltip, itemType, children } = props;

    const ExtendLink = React.useCallback((jsx: React.ReactNode) => {
        if (linkProps) {
            return <Link {...linkProps}>{jsx}</Link>;
        }
        return jsx;
    }, [linkProps]);

    const ExtendTooltip = React.useCallback((jsx: React.ReactNode) => {
        if (tooltip) {
            return (
                <Tooltip content={tooltip}>
                    <span>
                        {jsx}
                    </span>
                </Tooltip>
            );
        }
        return jsx;
    }, [tooltip]);

    if (itemType === 'seperator') {
        return (
            <ListStyles.DividerItem direction='row' spacing={2} alignItems='center' justifyContent='space-between' padding={[2, 0]}>
                <Label text={props.label} size='md' {...props.labelProps} />    
            </ListStyles.DividerItem>
        );
    }

    if (itemType === 'text') {
        return (
            <Stack alignItems='center' justifyContent='center' padding={[2, 0]}>
                <Label text={props.label} size='md' {...props.labelProps} />    
            </Stack>
        );
    }
    
    return (
        <div ref={ref}>
            {ExtendLink(ExtendTooltip(children))}
        </div>
    );
}
);
ListItemWrapper.displayName = 'ListItemWrapper';

const List = React.forwardRef<HTMLDivElement, IListProps>((props, ref) => {
    const { options, withCheckbox, disabled, onOptionClick, 'data-aid': dataaid } = props;

    const [openIndexes, setOpenIndexes] = React.useState<number[]>(() => {
        return options.reduce<number[]>((acc, option, index) => {
            if (!!option.nestedOptions && option.defaultOpened) {
                acc.push(index);
            }
            return acc;
        }, []);
    });

    const isItemOpen = React.useCallback((index: number) => {
        return openIndexes.includes(index);
    }, [openIndexes]);

    const handleNestedItemClick = React.useCallback((index: number) => {
        if (isItemOpen(index)) {
            setOpenIndexes(current => current.filter(i => i !== index));
        } else {
            setOpenIndexes(current => [...current, index]);
        }
    }, [isItemOpen]);

    const isNestedList = React.useMemo(() => {
        return options.some(option => !!option.nestedOptions);
    }, [options]);

    const handleOnOptionClick = React.useCallback((e: React.MouseEvent<HTMLElement, MouseEvent>, option: IListItemProps) => {
        if (option.disabled || disabled || option.itemType === 'seperator') return;
        let isNestedIconClicked = false;
        try {
            let target = e.target as HTMLElement;
            while (target.parentElement) {
                if (target.parentElement.id === 'arrow-toggle') {
                    isNestedIconClicked = true;
                    break;
                }
                if (target.parentElement.id === 'clear-button') {
                    isNestedIconClicked = true;
                    break;
                }
                target = target.parentElement;
            }
        } catch (error) { /* empty */ }
        if (isNestedIconClicked) return;
        option.onClick?.(e);
        onOptionClick?.(option, e);
    }, [onOptionClick, disabled]);

    return (
        <ListStyles.Wrapper ref={ref} spacing={0.5} direction='column' data-aid={dataaid}>
            {options.map(((option, index) => {
                const isNested = !!option.nestedOptions;
                const isNestedOpened = isNested && isItemOpen(index);
                return (
                    <ListItemWrapper key={`list-item-${index}`} {...option}>
                        <ListStyles.ListItem
                            isNested={isNested}
                            selected={withCheckbox ? false : option.selected}
                            disabled={disabled || option.disabled}
                            spacing={withCheckbox ? 0 : 2}
                            padding={withCheckbox ? [0, 2] : [2, 3]}
                            itemType={option.itemType}
                            direction='row'
                            fullWidth
                            tabIndex={disabled || option.disabled ? -1 : 0}
                            onClick={(e) => handleOnOptionClick?.(e, option)}
                            id='list-item'
                            data-aid={`${dataaid ? `${dataaid}_` : ''}${option.label}`}
                        >
                            {isNested && (
                                <Stack justifyContent='center' alignItems='center' id='arrow-toggle'>
                                    <ListStyles.NestedIcon
                                        iconProps={{ name: 'chevronRight', size: 8 }}
                                        size='small'
                                        isOpen={isNestedOpened}
                                        disabled={disabled || option.disabled}
                                        onClick={() => handleNestedItemClick(index)}
                                    />
                                </Stack>
                            )}
                            <Stack 
                                margin={(isNestedList && !isNested) ? withCheckbox ? [0,0,0,4] : [0,0,0,6] : 0}
                                direction='row'
                                alignItems='center'
                                fullWidth
                            >
                                {withCheckbox && (
                                    <Checkbox
                                        checked={option.selected || false}
                                        tabIndex={-1}
                                        disabled={disabled || option.disabled}
                                        indeterminate={option.indeterminate}
                                    />
                                )}
                                <Stack fullWidth>
                                    <Label {...option.labelProps} data-aid={option.label} text={option.label} disabled={disabled || option.disabled} />
                                </Stack>
                                {option.endAdornment && option.endAdornment}
                            </Stack>
                            {option.onClear && (
                                <Stack justifyContent='center' alignItems='center' id='clear-button'>
                                    <IconButton
                                        iconProps={{ name: 'remove', size: 8 }}
                                        size='small'
                                        disabled={disabled || option.disabled}
                                        onClick={option.onClear}
                                    />
                                </Stack>
                            )}
                        </ListStyles.ListItem>
                        {(isNestedOpened && option.nestedOptions) && (
                            <Stack key={`list-item-${index}-nested`} margin={[0,0,0,8]}>
                                <List options={option.nestedOptions} withCheckbox={withCheckbox} onOptionClick={onOptionClick} disabled={disabled || option.disabled} />
                            </Stack>
                        )}
                    </ListItemWrapper>
                );}))}
        </ListStyles.Wrapper>
    );
});
List.displayName = 'List';

export default List;
