import { buildPath, globalAddinContainer } from '../../extensibility/AddinContainer';
import { OverviewPanelRegistry } from '../assets/OverviewPanelRegistry';
import { IOverviewPanelCategoryItem } from '../assets/ProtectedAssets';

const PATH_ERM_RISKS_WIDGETS = buildPath('RiskManagement', 'Protected Asset', 'Risks');

export const RiskManagementPanelRegistry = {
    addRisksItemWidget: (itemId: string, position: number, item: IOverviewPanelCategoryItem, permission?: () => boolean ) => {
        OverviewPanelRegistry.addCategoryItemByPath(PATH_ERM_RISKS_WIDGETS, itemId, position, item, permission);
    },

    getRisksItemWidgets: (): IOverviewPanelCategoryItem[] => {
        return globalAddinContainer.get<IOverviewPanelCategoryItem>(buildPath(PATH_ERM_RISKS_WIDGETS));
    },
};
