import * as React from 'react';
import { SVGProps, Ref, forwardRef } from 'react';
const SvgMonitor = (
  props: SVGProps<SVGSVGElement>,
  ref: Ref<SVGSVGElement>
) => (
  <svg
    viewBox="0 0 16 16"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    ref={ref}
    {...props}
  >
    <g
      clipPath="url(#monitor_svg__a)"
      stroke="currentColor"
      strokeLinecap="round"
      strokeLinejoin="round"
    >
      <path d="M.5 9.5H3l1-2L5.5 11 9 3l3 8 1-1.5h2.5" />
      <path d="M.5 14.5v-13a1 1 0 0 1 1-1h13a1 1 0 0 1 1 1v13a1 1 0 0 1-1 1h-13a1 1 0 0 1-1-1Z" />
    </g>
    <defs>
      <clipPath id="monitor_svg__a">
        <path fill="#fff" d="M0 0h16v16H0z" />
      </clipPath>
    </defs>
  </svg>
);
const ForwardRef = forwardRef(SvgMonitor);
export default ForwardRef;
