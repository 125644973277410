import { buildPath, getService } from 'common/extensibility/AddinContainer';
import { IIntelligenceCloudAccount } from '../../interface/data_services';
import { IFindingModel } from '../../components/Findings/Findings.interface';
import {
    IAcknowledgeRequest, IArchiveRequest, IAssignUserRequest, IChangeSeverityRequest, ICloseRequest, ICommentRequest,
    IGslRunFacetResponse,
    IGslRunRequest,
    IGslRunResponse, IIntelligencePolicy,
    IntelligenceRule,
    IntelligenceRuleset,
    IPolicyDto,
    IUpdatePolicyDto,
} from './Intelligence.interface';
import { IFinding } from './Findings/Findings.interface';
import { IEvent } from '../events/Events';
import { IGslCount, IGslFilter, IGslSort } from 'common/erm-components/services/gsl/GslService.interface';
import { IFindingGslFilter } from 'common/module_interface/intelligence/Gsl/FindingGslService.interface';
import { Aggregations } from '../../components/FilterPanel/FilterPanel.interface';

export const INTELLIGENCE_SERVICE_ID = 'IntelligenceService';
export const MAGELLAN_LINK_GENERATOR_SERVICE_ID = 'MagellanLinkGeneratorService';
export const GSL_SERVICE_ID = 'GslService';
export const FINDING_SERVICE_ID = 'FindingService';

export const INTELLIGENCE = 'intelligence';
export const INTELLIGENCE_TABLE = buildPath(INTELLIGENCE, 'table');
export const INTELLIGENCE_TABLE_FILTERS = buildPath(INTELLIGENCE_TABLE, 'filters');

export const NETWORK_TRAFFIC = 'network-traffic';
export const NETWORK_TRAFFIC_TABLE = buildPath(NETWORK_TRAFFIC, 'table');
export const NETWORK_TRAFFIC_TABLE_FILTERS = buildPath(NETWORK_TRAFFIC_TABLE, 'filters');

export const GSL_RUN_URL = 'gslws/gsl-run?action=fetch';

export interface IIntelligenceService {
    getGSLRunDataCount(data: IGslRunRequest): Promise<number>;
    getGSLRunData(data: IGslRunRequest): Promise<IGslRunResponse>;
    getFindings(data: IGslRunRequest): Promise<IFinding[]>;
    getIntelligenceCloudAccounts(useCache?: boolean): Promise<IIntelligenceCloudAccount[]>;
    getIntelligenceFindingGraph(findingKey: string): Promise<any>;
    getIntelligenceFinding(findingKey: string): Promise<IEvent | undefined>;
    getIntelligenceFacets(data: IGslRunRequest): Promise<IGslRunFacetResponse>;
    getIntelligenceFindingQuery(data: IGslRunRequest): Promise<IFindingModel[]>;
    getIntelligenceRulesets():Promise<IntelligenceRuleset[]>;
    getIntelligenceRules(rulesetId: number):Promise<IntelligenceRule[]>;
    savePolicies(payload:IPolicyDto): Promise<void>;
    getPolicies(useCache?:boolean): Promise<IIntelligencePolicy[]>;
    updatePolicy(payload:IUpdatePolicyDto): Promise<void>;
    acknowledgeFindings(request: IAcknowledgeRequest): Promise<void>;
    addComment(request: ICommentRequest): Promise<void>;
    closeFindings(request: ICloseRequest): Promise<void>;
    changeSeverity(request: IChangeSeverityRequest): Promise<void>;
    assignUser(request: IAssignUserRequest): Promise<void>;
    archiveFindings(request: IArchiveRequest): Promise<void>;
}

export interface IMagellanLinkGeneratorService {
    iamFunc(finding:string, feature: any) : string,
    generateFilterForProtectedAssets(feature: string, asset: { type: string }): string,
    generateLinkForFinding(finding: IFinding): string,
}

export function getMagellanLinkGeneratorService(): IMagellanLinkGeneratorService {
    return getService<IMagellanLinkGeneratorService>(MAGELLAN_LINK_GENERATOR_SERVICE_ID);
}

export function getIntelligenceService(): IIntelligenceService {
    return getService<IIntelligenceService>(INTELLIGENCE_SERVICE_ID);
}

export interface IGslService {
    getItems: (filter: IGslFilter, limit?: number, sort?: IGslSort[], additionalParams?: any) => Promise<IGslRunResponse>;
    getCount: (filter: IGslFilter, count: IGslCount, additionalParams?: any) => Promise<number>;
    getAggregations: (filter: IGslFilter, aggregations: string[], additionalParams?: any) => Promise<Aggregations>;
}

export function getGslService(): IGslService {
    return getService<IGslService>(GSL_SERVICE_ID);
}

export interface IFindingService {
    getFindings: (filter: IFindingGslFilter, limit?: number, sort?: IGslSort[]) => Promise<IFinding[]>;
    getFindingFacets: (filter: IFindingGslFilter, aggregations: string[]) => Promise<Aggregations>;
    getFindingCount: (filter: IFindingGslFilter, count: IGslCount) => Promise<number>;
}

export function getFindingsService(): IFindingService {
    return getService<IFindingService>(FINDING_SERVICE_ID);
}
