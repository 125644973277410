import * as React from 'react';
import { SVGProps, Ref, forwardRef } from 'react';
const SvgCalendar = (
  props: SVGProps<SVGSVGElement>,
  ref: Ref<SVGSVGElement>
) => (
  <svg
    viewBox="0 0 16 16"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    ref={ref}
    {...props}
  >
    <path
      d="M4.5.5v4m7-4v4"
      stroke="currentColor"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <rect
      x={0.5}
      y={2.5}
      width={15}
      height={13}
      rx={2}
      stroke="currentColor"
      strokeLinejoin="round"
    />
    <path d="M.5 7.5h15" stroke="currentColor" strokeLinecap="round" />
  </svg>
);
const ForwardRef = forwardRef(SvgCalendar);
export default ForwardRef;
