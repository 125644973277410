import * as React from 'react';
import { SVGProps, Ref, forwardRef } from 'react';
const SvgRocket = (props: SVGProps<SVGSVGElement>, ref: Ref<SVGSVGElement>) => (
  <svg
    viewBox="0 0 16 16"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    ref={ref}
    {...props}
  >
    <g clipPath="url(#rocket_svg__a)" stroke="currentColor">
      <path d="M10 2C11.458.548 13.216.44 14.287.547a1.29 1.29 0 0 1 1.168 1.166C15.566 2.782 15.458 4.537 14 6l-5.293 5.293a1 1 0 0 1-1.414 0L4.707 8.707a1 1 0 0 1 0-1.414L10 2Z" />
      <path
        d="m4.5 11.5-4 4M3 10l-1.5 1.5M6 13l-1.5 1.5M1.5 7.5l1.414-1.414A2 2 0 0 1 4.328 5.5H6.5m4 4v2.172a2 2 0 0 1-.586 1.414L8.5 14.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <circle cx={12} cy={4} r={0.5} />
    </g>
    <defs>
      <clipPath id="rocket_svg__a">
        <path fill="#fff" d="M0 0h16v16H0z" />
      </clipPath>
    </defs>
  </svg>
);
const ForwardRef = forwardRef(SvgRocket);
export default ForwardRef;
