import globalAddinContainer from 'common/extensibility/AddinContainer';
import { Addin } from 'common/extensibility/AddinRegistry';
import {
    PATH_EVENTS_TABLE_PAGE_TABLE_COLUMNS,
    PATH_EVENTS_TABLE_PAGE_TABLE_FILTERS,
    PATH_EVENTS_TABLE_PAGE_TABLE_ACTIONS,
    PATH_EVENTS_TABLE_PAGE_TABLE_EXPORTS,
    PATH_EVENTS_TABLE_PAGE_TABLE_CELL_RENDERERS,
    PATH_EVENTS_TABLE_PAGE_TABLE_TABS,

    IEventTableFilterParams,
    IEventTableFilter,
    IEventTableExport,
    IEventTableCellRendererAddinContent,
    EventsTableTab,
    EventsTableColDef,
    EventsTableTabComponent,
} from '../../interface/events';
import { ITableAction } from 'common/design-system/components-v2/Table/Table.types';
import { IFilterProps } from 'common/components/FilterPanel/FilterPanelManager';
import { IEvent } from './Events';

export const EventsTableRegistry = {
    addColumnDefAddins: (columnDefAddins: Addin<EventsTableColDef>[]) => {
        globalAddinContainer.add<EventsTableColDef>(PATH_EVENTS_TABLE_PAGE_TABLE_COLUMNS, columnDefAddins.map((columnDef) => ({ position: Infinity, ...columnDef })));
    },

    addColumnDefs: (columnDefs: EventsTableColDef[]) => {
        globalAddinContainer.addMap(PATH_EVENTS_TABLE_PAGE_TABLE_COLUMNS, columnDefs, 'id');
    },

    getColumnDefs: (): EventsTableColDef[] => {
        return globalAddinContainer.get<EventsTableColDef>(PATH_EVENTS_TABLE_PAGE_TABLE_COLUMNS);
    },

    addFilterAddins: (filterAddins: Addin<IFilterProps>[]) => {
        globalAddinContainer.add<IFilterProps>(PATH_EVENTS_TABLE_PAGE_TABLE_FILTERS, filterAddins);
    },

    getFilter: (filterId: string, filterParams: IEventTableFilterParams[]): IFilterProps | undefined => {
        return globalAddinContainer.getById<IFilterProps>(PATH_EVENTS_TABLE_PAGE_TABLE_FILTERS, filterId, filterParams);
    },


    getFilterDefs(filters: IEventTableFilter[], params: IEventTableFilterParams): IFilterProps[] {
        const result: IFilterProps[] = [];
        filters.forEach((filter) => {
            const matchedFilterDef = EventsTableRegistry.getFilter(filter.id, [params]);
            if (matchedFilterDef) {
                const filterDef = { ...matchedFilterDef };
                filterDef.filterProps = { ...filterDef.filterProps, ...filter.filterProps };
                result.push(filterDef);
            }
        });
        return result;
    },

    addActions: (actionDefs: Addin<ITableAction<IEvent>>[]) => {
        globalAddinContainer.add<ITableAction>(PATH_EVENTS_TABLE_PAGE_TABLE_ACTIONS, actionDefs);
    },

    getActions: () => {
        return globalAddinContainer.get<ITableAction<IEvent>>(PATH_EVENTS_TABLE_PAGE_TABLE_ACTIONS);
    },

    addExports: (actionDefs: Addin<IEventTableExport>[]) => {
        globalAddinContainer.add<IEventTableExport>(PATH_EVENTS_TABLE_PAGE_TABLE_EXPORTS, actionDefs);
    },

    getExports: () => {
        return globalAddinContainer.get<IEventTableExport>(PATH_EVENTS_TABLE_PAGE_TABLE_EXPORTS);
    },

    addCellRendererAddins: (cellRendererAddins: Addin<IEventTableCellRendererAddinContent>[]) => {
        globalAddinContainer.add<IEventTableCellRendererAddinContent>(PATH_EVENTS_TABLE_PAGE_TABLE_CELL_RENDERERS, cellRendererAddins);
    },

    addTabs: (tabs: Addin<EventsTableTab | EventsTableTabComponent>[]) => {
        globalAddinContainer.add(PATH_EVENTS_TABLE_PAGE_TABLE_TABS, tabs);
    },

    getTabs: () => {
        return globalAddinContainer.get<EventsTableTab | EventsTableTabComponent>(PATH_EVENTS_TABLE_PAGE_TABLE_TABS);
    },
};
