import * as React from 'react';
import { SVGProps, Ref, forwardRef } from 'react';
const SvgImageScan = (
  props: SVGProps<SVGSVGElement>,
  ref: Ref<SVGSVGElement>
) => (
  <svg
    id="imageScan_svg__Infected_host"
    data-name="Infected host"
    xmlns="http://www.w3.org/2000/svg"
    viewBox="0 0 48 48"
    ref={ref}
    {...props}
  >
    <defs>
      <style>{'.imageScan_svg__cls-1{fill:#2e3f58}'}</style>
    </defs>
    <path
      className="imageScan_svg__cls-1"
      d="M31.13 31.4h1.2v-1.21a2.65 2.65 0 0 0-1.2 1.21ZM33.67 32.74V34a2.16 2.16 0 0 0 1.2-1.21ZM33.67 38.11A6.1 6.1 0 0 0 39 32.87h-2.67a3.44 3.44 0 0 1-2.66 2.69ZM27.13 31.4h2.67a3.26 3.26 0 0 1 2.67-2.55v-2.69a5.75 5.75 0 0 0-5.34 5.24ZM32.33 34v-1.26h-1.2a1.83 1.83 0 0 0 1.2 1.26Z"
    />
    <path
      className="imageScan_svg__cls-1"
      d="M40.33 32.74a7.17 7.17 0 0 1-6.66 6.58V42a9.93 9.93 0 0 0 9.2-9.26ZM29.67 32.74H27A5.85 5.85 0 0 0 32.33 38v-2.71a3.12 3.12 0 0 1-2.66-2.55Z"
    />
    <path
      className="imageScan_svg__cls-1"
      d="M25.67 32.74H23A9.85 9.85 0 0 0 32.33 42v-2.68a7.29 7.29 0 0 1-6.66-6.58ZM25.8 31.4a7.28 7.28 0 0 1 6.53-6.58v-2.69A10.06 10.06 0 0 0 23 31.4ZM43 31.4a10.07 10.07 0 0 0-9.33-9.4v9.4Z"
    />
    <path
      d="M21 32a12 12 0 0 1 12-12v-2.57L21.5 6h-6.9a4.59 4.59 0 0 0-4.6 4.57v22.86A4.59 4.59 0 0 0 14.6 38h8a12 12 0 0 1-1.6-6Zm1-23.71L30.7 17H22Z"
      style={{
        fillRule: 'evenodd',
        fill: '#2e3f58',
      }}
    />
  </svg>
);
const ForwardRef = forwardRef(SvgImageScan);
export default ForwardRef;
