import * as React from 'react';
import { SVGProps, Ref, forwardRef } from 'react';
const SvgRiskGaugeMedium = (
  props: SVGProps<SVGSVGElement>,
  ref: Ref<SVGSVGElement>
) => (
  <svg
    viewBox="0 0 18 16"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    ref={ref}
    {...props}
  >
    <path
      d="M15.584 12.642c-.507.721-1.54.656-2.104-.021-.502-.603-.44-1.48-.061-2.169.396-.72.621-1.547.621-2.427 0-.485-.068-.955-.196-1.399l2.795-1.118c.26.792.401 1.638.401 2.517 0 1.718-.538 3.31-1.456 4.617ZM16.267 4.58l-2.795 1.118A5.043 5.043 0 0 0 9.5 3.009V0a8.043 8.043 0 0 1 6.767 4.58Z"
      fill="#C60"
      fillOpacity={0.4}
    />
    <path
      d="M8.5 3.01a5.043 5.043 0 0 0-3.972 2.688L1.733 4.58A8.044 8.044 0 0 1 8.5 0v3.01ZM3.96 8.025c0 .795.184 1.547.511 2.216.346.705.367 1.585-.164 2.164-.595.649-1.63.666-2.101-.078A8.003 8.003 0 0 1 .96 8.025c0-.879.14-1.725.401-2.517l2.795 1.118a5.043 5.043 0 0 0-.196 1.4ZM9.56 7.155 6.96 4.97c-.338-.284-.82.109-.61.497l1.623 2.986a1 1 0 0 0 1.505.302l.067-.054a1 1 0 0 0 .017-1.545Z"
      fill="#C60"
      fillOpacity={0.99}
    />
  </svg>
);
const ForwardRef = forwardRef(SvgRiskGaugeMedium);
export default ForwardRef;
