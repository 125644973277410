import * as React from 'react';
import { SVGProps, Ref, forwardRef } from 'react';
const SvgNotConnectedStatus = (
  props: SVGProps<SVGSVGElement>,
  ref: Ref<SVGSVGElement>
) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    viewBox="0 0 16 16"
    ref={ref}
    {...props}
  >
    <g
      style={{
        fill: '#2e3f58',
      }}
    >
      <path d="M3.78 9 2.64 7.86a3.69 3.69 0 0 1-.52-4.54L.03 1.23 1.25 0l2.1 2.1c1.45-.89 3.33-.67 4.54.52l1.14 1.14L3.79 9ZM12.19 6.92l1.14 1.14a3.69 3.69 0 0 1 .52 4.54l2.1 2.1-1.22 1.22-2.1-2.1c-1.45.89-3.33.67-4.54-.52l-1.14-1.14 5.24-5.24Z" />
      <path d="m6.95 5.813 1.167-1.167 1.181 1.18a.817.817 0 0 1 0 1.16.817.817 0 0 1-1.16 0l-1.18-1.18ZM4.685 8.097 5.85 6.93l1.181 1.181a.817.817 0 0 1 0 1.16.817.817 0 0 1-1.16 0L4.693 8.09Z" />
    </g>
  </svg>
);
const ForwardRef = forwardRef(SvgNotConnectedStatus);
export default ForwardRef;
