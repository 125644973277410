export enum PlatformTypes {
    aws = 'aws',
    azure = 'azure',
    gcp = 'gcp',
    terraform = 'terraform',
    serverless = 'serverless',
    kubernetes = 'kubernetes',
}

export enum StatusTypes {
    INFO = 'info',
    SUCCESS = 'success',
    WARNING = 'warning',
    DANGER = 'danger',
}

export enum ActionTypes {
    detect = 'Detect',
}

export enum HttpMethod {
    get = 'GET',
    post = 'POST',
}
