import * as React from 'react';
import { SVGProps, Ref, forwardRef } from 'react';
const SvgFind = (props: SVGProps<SVGSVGElement>, ref: Ref<SVGSVGElement>) => (
  <svg
    viewBox="0 0 16 16"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    ref={ref}
    {...props}
  >
    <ellipse cx={12.5} cy={13} rx={3} ry={2.5} stroke="currentColor" />
    <ellipse cx={3.5} cy={13} rx={3} ry={2.5} stroke="currentColor" />
    <path
      d="M6.5 13V2A1.5 1.5 0 0 0 5 .5h-.314a1.5 1.5 0 0 0-1.458 1.15L.5 13m15 0L12.776 1.65A1.5 1.5 0 0 0 11.317.5H11A1.5 1.5 0 0 0 9.5 2v3.5m0 0V13m0-7.5h-3m0 3h3"
      stroke="currentColor"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </svg>
);
const ForwardRef = forwardRef(SvgFind);
export default ForwardRef;
