import { GenericObject } from '../interface/general';
import vendorIcons from '@dome9/berries/icons/dist/icons-list_vendor.json';
import { getAssetUrl } from './assetUrls';
import { ICloudEntity } from '../module_interface/assets/ICloudEntity';
import { IProtectedAssetViewModel } from '../components/ProtectedAssets/ProtectedAssetsTable.interface';

export type AssetUrlParams = {
    asset: IAsset;
    platform?: string;
    cloudAccountId?: string;
    entityType?: string;
    entityId?: string;
    dome9Id?: string;
    region?: string;
    tabName?: string;
    typeNumber?: number;
}

export type UrlFuncResult = {
    url: string;
    query: GenericObject<any>;
}

export type UrlFunc = (params: AssetUrlParams) => (UrlFuncResult | null)

export type AssetIdMap = {
    cloudEntityIdFieldName: string;
    protectedAssetIdFieldName: string;
    caseSensitive: boolean;
}

export type SpecificEntityFetcher = (protectedAsset: IProtectedAssetViewModel) => Promise<ICloudEntity | null>;

export enum DataAssetsCategories {
    DATABASE = 'Database',
    DATALAKE = 'DataLake',
    STORAGE = 'Storage',
}

export interface IDataAssetCategory {
    id: DataAssetsCategories;
    getTitle: () => string;
    position: number;
    iconName: string;
    assetTypes: IAsset[];
}

export interface IAsset {
    type: string;
    displayName: string;
    platform: string;
    icon: string;
    isGenericObject: boolean;
    typeByPlatform: string;
    getUrl: UrlFunc;
    typeNumber?: number;
    idMap: AssetIdMap;
    isPreviewEntity?: boolean;
    category?: string;
    cloudEntityChildrenInfo?: IAssetChildrenInfo[];
    dataAssetCategoryInfo?: IDataAssetCategory;
    //Allows to fetch a specific entity from the backend without using the cloudEntity API (not all entities have specific fetchers)
    getSpecificEntity?: SpecificEntityFetcher;
}

interface IAssetChildrenInfo {
    childrenFieldName: string;
    idFieldName: string;
    idTargetFieldName: string;
    type: string;
}

export interface IAssetProps {
    icon?: string;
    type: string;
    displayName?: string;
    platform: string;
    isGenericObject: boolean;
    getUrl?: UrlFunc;
    typeNumber?: number;
    isPreviewEntity?: boolean;
    category?: string;
    idMap?: AssetIdMap; //Because the backend is inconsistent with id assignment :(
    cloudEntityChildrenInfo?: IAssetChildrenInfo[];
    getSpecificEntity?: SpecificEntityFetcher;
}

const defaultIdMap: AssetIdMap = Object.freeze<AssetIdMap>({
    caseSensitive: false,
    cloudEntityIdFieldName: 'dome9Id',
    protectedAssetIdFieldName: 'id',
});

export class Asset implements IAsset {
    displayName: string;
    icon: string;
    isGenericObject: boolean;
    platform: string;
    type: string;
    typeByPlatform: string;
    getUrl: UrlFunc;
    typeNumber?: number;
    idMap: AssetIdMap;
    isPreviewEntity?: boolean;
    category?: string;
    cloudEntityChildrenInfo?: IAssetChildrenInfo[];
    getSpecificEntity?: SpecificEntityFetcher;

    constructor({
        type,
        displayName,
        platform,
        isGenericObject,
        getUrl,
        typeNumber,
        idMap,
        icon,
        isPreviewEntity,
        category,
        cloudEntityChildrenInfo,
        getSpecificEntity,
    }: IAssetProps) {
        this.type = type;
        this.displayName = displayName || type;
        this.platform = platform;
        this.icon = icon || getAssetIconName(type, platform) || 'unknown';
        this.isGenericObject = isGenericObject;
        this.typeByPlatform = getTypeByPlatform(platform, type);
        this.getUrl = getUrl || getAssetUrl;
        this.typeNumber = typeNumber;
        this.idMap = idMap ?? defaultIdMap;
        this.isPreviewEntity = isPreviewEntity;
        this.category = category;
        this.cloudEntityChildrenInfo = cloudEntityChildrenInfo;
        this.getSpecificEntity = getSpecificEntity;
    }
}

export const getAssetIconName = (type: string, platform: string): string | undefined => {
    const icon: any = vendorIcons.find((icon: any) => {
        return (icon.type === type && icon.platform === platform);
    });
    return icon?.name;
};

export const getTypeByPlatform = (platform: string, type: string) => {
    if (!platform) {
        return type;
    }
    const lowerPlatform = platform.toLowerCase();
    return `${lowerPlatform === 'gcp' ? 'google' : lowerPlatform}|${type}`;
};


