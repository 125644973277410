import { getService } from '../../extensibility/AddinContainer';
import { RiskLevelServerEnum } from './RiskLevel';
import { ISentraConfiguration } from 'common/module_interface/RiskManagement/integrations/sentraIntegration/SentraIntegration.consts';
import { IConfiguration } from '../settings/integrations/Integrations';
import { ICyeraConfiguration } from './integrations/cyeraintegration/CyeraIntegration.consts';
import { IFieldInfo } from '../../interface/general';
import { FindingsAlertType } from '../../components/Findings/Findings.interface';
import { ITimeFromTo } from '../../components/FilterPanel/DefaultFilters/DefaultFilters.interface';
import { IAsset, UrlFuncResult } from '../../assets/common.assets';

export interface IRiskDataGroup {
    id: string,
    groupType: 'Environment',
    assetsByRiskLevel: {
        Low: number,
        Medium: number,
        High: number,
        Critical: number
    },
    riskScore: number,
    riskLevel: RiskLevelServerEnum
}

export interface IRiskDataResponse {
    groups: IRiskDataGroup[];
}

export interface IRiskManagementService {
    getAllEnvironmentsRisk(): Promise<IRiskDataGroup[]>;

    getEnvironmentRisk(cloudAccountId: string): Promise<IRiskDataGroup | undefined>;
}

export const RISK_MANAGEMENT_SERVICE_ID = 'Risk Management Service';
export const RISK_MANAGEMENT_URLS_SERVICE_ID = 'Risk Management Urls Service';

export function getRiskManagementService(): IRiskManagementService {
    return getService<IRiskManagementService>(RISK_MANAGEMENT_SERVICE_ID);
}

export const SENTRA_INTEGRATION_SERVICE_ID = 'Sentra Integration Service';
export const CYERA_INTEGRATION_SERVICE_ID = 'Cyera Integration Service';

export interface ISentraConfigurationModel {
    accounts: ISentraConfiguration[];
}

export interface ISentraIntegrationService {
    addSentraAccount(apiKey: string, name: string): Promise<IBaseIdModel>;
    updateSentraAccount(accountId: string, apiKey: string, name: string): Promise<IBaseIdModel>;
    getSentraAccounts(): Promise<ISentraConfiguration[]>;
    deleteSentraAccount(accountId: string): Promise<void>;
    getSentraConfigurations(): Promise<IConfiguration[]>;
}

export function getSentraIntegrationService(): ISentraIntegrationService {
    return getService<ISentraIntegrationService>(SENTRA_INTEGRATION_SERVICE_ID);
}

export interface IBaseIdModel {
    id: string;
}

export interface ICyeraConfigurationModel {
    accounts: ICyeraConfiguration[];
}

export interface ICyeraIntegrationService {
    addCyeraAccount(clientId: string, secret: string, name: string): Promise<IBaseIdModel>;
    updateCyeraAccount(accountId: string, name: string): Promise<IBaseIdModel>;
    getCyeraAccounts(): Promise<ICyeraConfiguration[]>;
    deleteCyeraAccount(accountId: string): Promise<void>;
    getCyeraConfigurations(): Promise<IConfiguration[]>;
}

export function getCyeraIntegrationService(): ICyeraIntegrationService {
    return getService<ICyeraIntegrationService>(CYERA_INTEGRATION_SERVICE_ID);
}

export interface IErmUrlsService {
    gotoEnvironmentTable(filterFields?: IFieldInfo[], freeText?: string, envType?: string): void;
    goToProtectedAssetsForType(typeByPlatform: string, filterFields?: IFieldInfo[]): void;
    goToProtectedAssetsTable(filtersItems?: IFieldInfo[], freeText?: string): void;
    goToEventsTable(alertType: FindingsAlertType, freeTextPhrase: string, filterFields: IFieldInfo[], creationTime?: ITimeFromTo): void;
    gotoSecurityIssuesTable(filterFields?: IFieldInfo[], freeText?: string): void;
    generateAssetUrl(asset: IAsset, assetUrl: UrlFuncResult, tabName?: string, innerTabIndex?: number): string;
}

export function getErmUrlsService(): IErmUrlsService {
    return getService<IErmUrlsService>(RISK_MANAGEMENT_URLS_SERVICE_ID);
}
