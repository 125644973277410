import { IAsset } from 'common/assets/common.assets';

let protectedAssets: IAsset[] = [];
export const setAssets = (assets: IAsset[]) => {
    protectedAssets = assets;
};

export const getAssets = () => {
    return protectedAssets;
};
