import * as React from 'react';
import { SVGProps, Ref, forwardRef } from 'react';
const SvgBell = (props: SVGProps<SVGSVGElement>, ref: Ref<SVGSVGElement>) => (
  <svg
    viewBox="0 0 16 16"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    ref={ref}
    {...props}
  >
    <path
      d="M1.145 12.5a.506.506 0 0 1-.372-.845c1.123-1.26 2.239-2.738 2.212-4.252v-2.83c.03-.755.283-1.487.732-2.117a4.333 4.333 0 0 1 1.812-1.45c.37-.16.758-.284 1.157-.37A6.338 6.338 0 0 1 9.2.616c.437.087.862.218 1.267.393a4.19 4.19 0 0 1 1.773 1.453c.432.63.665 1.36.671 2.108v2.833c.003 1.519 1.142 2.994 2.287 4.246.297.325.072.851-.368.851H1.145Z"
      stroke="currentColor"
      strokeLinejoin="round"
    />
    <path
      d="M9.782 14.408a2 2 0 0 1-3.564 0"
      stroke="currentColor"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </svg>
);
const ForwardRef = forwardRef(SvgBell);
export default ForwardRef;
