import { useCallback, useEffect, useState } from 'react';
import { singletonHook } from 'react-singleton-hook';
import { User } from '../viewCommonApi.interface';
import ViewCommonApi from '../viewCommonApi.service';

export interface UseUsersPayload {
    isLoading: boolean;
    isError: boolean;
    users: User[];
}

export type UseUsers = () => UseUsersPayload;

const useUsersImpl: UseUsers = () => {
    const [users, setUsers] = useState<User[]>([]);
    const [isLoading, setIsLoading] = useState<boolean>(true);
    const [isError, setIsError] = useState<boolean>(false);

    const getAndSetUsers = useCallback(async () => {
        setIsError(false);
        setIsLoading(true);
        try {
            await new Promise(resolve => setTimeout(resolve, 3000));
            const getUsersRes = await ViewCommonApi.getAllUsers();
            setUsers(getUsersRes.data);
        } catch (error) {
            setIsError(true);
        } finally {
            setIsLoading(false);
        }
    }, []);

    useEffect(() => {
        getAndSetUsers();
    }, [getAndSetUsers]);

    return { isLoading, isError, users };
};

export const useUsers: UseUsers = singletonHook({ isLoading: true, isError: false, users: [] }, useUsersImpl);
