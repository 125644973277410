import * as React from 'react';
import { SVGProps, Ref, forwardRef } from 'react';
const SvgCard = (props: SVGProps<SVGSVGElement>, ref: Ref<SVGSVGElement>) => (
  <svg
    viewBox="0 0 24 24"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    ref={ref}
    {...props}
  >
    <path
      d="M15.75 13.45a.7.7 0 1 0 0-1.4v1.4Zm-10.5-1.4a.7.7 0 1 0 0 1.4v-1.4Zm3 4.4a.7.7 0 1 0 0-1.4v1.4Zm-3-1.4a.7.7 0 1 0 0 1.4v-1.4Zm15 4.5H3.75v1.4h16.5v-1.4Zm-18.8-2.3V6.75H.05v10.5h1.4Zm2.3-12.8h16.5v-1.4H3.75v1.4Zm18.8 2.3v10.5h1.4V6.75h-1.4Zm-2.3-2.3a2.3 2.3 0 0 1 2.3 2.3h1.4a3.7 3.7 0 0 0-3.7-3.7v1.4Zm-18.8 2.3a2.3 2.3 0 0 1 2.3-2.3v-1.4a3.7 3.7 0 0 0-3.7 3.7h1.4Zm2.3 12.8a2.3 2.3 0 0 1-2.3-2.3H.05a3.7 3.7 0 0 0 3.7 3.7v-1.4Zm16.5 1.4a3.7 3.7 0 0 0 3.7-3.7h-1.4a2.3 2.3 0 0 1-2.3 2.3v1.4Zm3-13.4H.75v1.4h22.5v-1.4Zm-7.5 4.5H5.25v1.4h10.5v-1.4Zm-7.5 3h-3v1.4h3v-1.4Z"
      fill="currentColor"
    />
  </svg>
);
const ForwardRef = forwardRef(SvgCard);
export default ForwardRef;
