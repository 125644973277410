import * as React from 'react';
import { SVGProps, Ref, forwardRef } from 'react';
const SvgRemedyFill = (
  props: SVGProps<SVGSVGElement>,
  ref: Ref<SVGSVGElement>
) => (
  <svg
    viewBox="0 0 17 16"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    ref={ref}
    {...props}
  >
    <path
      d="M13.72 4h-.67V1c0-.55-.45-1-1-1h-8c-.55 0-1 .45-1 1v3h-.71A2.279 2.279 0 0 0 .05 6.27V13.6c-.03 1.29.99 2.37 2.28 2.4h11.43c1.26.01 2.28-1.01 2.29-2.27V6.33c0-1.29-1.04-2.33-2.33-2.33ZM5.05 2h6v2h-6V2Zm6 9h-2v2h-2v-2h-2V9h2V7h2v2h2v2Z"
      fill="currentColor"
    />
  </svg>
);
const ForwardRef = forwardRef(SvgRemedyFill);
export default ForwardRef;
