import * as React from 'react';
import { SVGProps, Ref, forwardRef } from 'react';
const SvgNzism = (props: SVGProps<SVGSVGElement>, ref: Ref<SVGSVGElement>) => (
  <svg
    id="NZISM_svg__Layer_1"
    xmlns="http://www.w3.org/2000/svg"
    x={0}
    y={0}
    viewBox="0 0 16 15.3"
    xmlSpace="preserve"
    ref={ref}
    {...props}
  >
    <style>{'.NZISM_svg__st0{fill:#e87d18}'}</style>
    <path
      className="NZISM_svg__st0"
      d="M16 4.3c-1.4.6-2.6 1.5-3.8 2.4-1 .7-1.9 1.6-2.7 2.5-.9 1-1.8 2-2.4 3.1-.6 1-1.2 2-1.7 3l-.6-.9C3.6 12.9 0 9.3 0 9.3s.4-.5.6-.8c1.6 1.7 3.3 3.1 4.7 4.9.6-.9 1.1-1.7 1.6-2.5.2-.3.4-.7.7-1 1-1.2 2-2.4 3.2-3.4 1.1-.9 4.2-3 4.4-3.1.2 0 .8.9.8.9z"
    />
    <path
      className="NZISM_svg__st0"
      d="M5 12.5C3.9 10.7 2 9.7.7 8.1l.6-.6C2.6 8.6 3.7 9.7 5 10.8c.3-.3.6-.6.8-1 .7-1 1.5-1.9 2.4-2.7.7-.7 1.5-1.4 2.3-2.1 1.1-.9 2.2-1.6 3.4-2.4.5-.4.7-.3 1.3.4C11.1 5.5 7.7 8.5 5 12.5z"
    />
    <path
      className="NZISM_svg__st0"
      d="m13.6 1.1.2.2c.1.2.2.4.3.7-.5.3-.9.7-1.4 1-.7.5-1.4 1-2.2 1.5-1.3 1-2.5 2.2-3.6 3.3-.7.6-1.1 1.4-1.6 2.1l-.2.2C3.9 9.1 2.7 8 1.5 7c0-.1.6-.6.6-.6s2 1.5 2.8 2.3c2.4-3 5.4-5.5 8.7-7.6z"
    />
    <path
      className="NZISM_svg__st0"
      d="M12.7 0c.1.1.3.2.4.4.1.2.2.3-.2.6-1.1.7-2.1 1.5-3.2 2.3-1.3 1-2.5 2.1-3.7 3.4-.4.4-.8.8-1.2 1.3-.3-.2-.4-.4-.6-.6L2.7 6.2c-.5-.3-.5-.3-.2-.8 0-.1.1-.1.2-.3.8.4 1.4.9 2 1.5 2.4-2.5 4.9-4.9 8-6.6z"
    />
  </svg>
);
const ForwardRef = forwardRef(SvgNzism);
export default ForwardRef;
