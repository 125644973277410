export interface INotificationRequestData {
    name: string;
    alertsConsole: boolean;
    changeDetection: {
        awsSecurityHubIntegrationState: string,
        azureSecurityCenterIntegrationState: string,
        emailPerFindingSendingState: string,
        emailSendingState: string,
        externalTicketCreatingState: string,
        slackIntegrationState: string,
        snsSendingState: string,
        teamsIntegrationState: string,
        webhookIntegrationState: string
    };
    description: string;
    filter: {
        entityIds: [],
        entityNames: [],
        entityTypes: [],
        severities: [],
    } | null;
    gcpSecurityCommandCenterIntegration: {
        state?: string,
        projectId?: string,
        sourceId?: string,
    };
    scheduledReport: {
        emailSendingState: string;
    };
}

export enum NotificationStatus{
    OK = 'OK',
    ERROR = 'Error',
    NA = 'NA'
}
export interface INotification extends INotificationRequestData {
    id: string;
    status: NotificationStatus;
    associations: string[];
    types: string[];
}
