import * as React from 'react';
import { SVGProps, Ref, forwardRef } from 'react';
const SvgTerraform = (
  props: SVGProps<SVGSVGElement>,
  ref: Ref<SVGSVGElement>
) => (
  <svg viewBox="0 0 64 64" ref={ref} {...props}>
    <g fillRule="evenodd">
      <path
        d="M40.743 64V43.802L23.249 33.7v20.198zm0-22.405L23.249 31.492V11.286l17.494 10.103z"
        fill="#5c4ee5"
      />
      <path d="M60.172 31.483V11.277L42.669 21.38v20.207z" fill="#4040b2" />
      <path d="M21.33 30.31V10.102L3.836 0v20.206z" fill="#5c4ee5" />
    </g>
  </svg>
);
const ForwardRef = forwardRef(SvgTerraform);
export default ForwardRef;
