import * as React from 'react';
import { SVGProps, Ref, forwardRef } from 'react';
const SvgPlusCircle = (
  props: SVGProps<SVGSVGElement>,
  ref: Ref<SVGSVGElement>
) => (
  <svg
    viewBox="0 0 24 24"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    ref={ref}
    {...props}
  >
    <path
      d="M9.75 6.25v3.5h-3.5a1 1 0 0 0-1 1v2.5a1 1 0 0 0 1 1h3.5v3.5a1 1 0 0 0 1 1h2.5a1 1 0 0 0 1-1v-3.5h3.5a1 1 0 0 0 1-1v-2.5a1 1 0 0 0-1-1h-3.5v-3.5a1 1 0 0 0-1-1h-2.5a1 1 0 0 0-1 1Z"
      stroke="currentColor"
      strokeWidth={1.4}
      strokeLinejoin="round"
    />
    <circle cx={12} cy={12} r={11.25} stroke="currentColor" strokeWidth={1.4} />
  </svg>
);
const ForwardRef = forwardRef(SvgPlusCircle);
export default ForwardRef;
