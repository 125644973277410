import React from 'react';
import { Checkbox, Icon, Stack, Typography } from 'common/design-system/components-v2';
import { CommonItemProps } from './interfaces';


export const TextIconItem: React.FC<CommonItemProps> = ({ item,handleCheckboxChange,selected ,dataAid }) => {
    return (
        <Stack spacing={2} direction={'row'} alignItems={'center'} data-aid={dataAid}>
            <Checkbox name={item.id}
                onChange={(event) => handleCheckboxChange && handleCheckboxChange(item,event.target.checked)}
                checked={selected}
            />
            {item.icon && <Icon vendorNameOrPath={item.icon} size={20}/>}
            <Typography variant={'body'}>{item.name}</Typography>
        </Stack>
    );
};
