import { getPalette } from './palette';
import { foregrounds } from './foregrounds';
import { borders } from './borders';
import { backgrounds } from './backgrounds';
import { ColorsByContex, Mode } from './colors.types';

export const getColors = (mode:Mode = 'light'):ColorsByContex => {
    const palette = getPalette(mode);
    
    return (
        {
            common: {
                black: '#000000',
                white: '#ffffff'
            },
            default: {
                borders: borders(palette).default,
                backgrounds: backgrounds(palette).default,
                foregrounds: foregrounds(palette).default,
                iconColor: palette.slate.opaque[700]
            },
            critical: {
                borders: borders(palette).critical,
                backgrounds: backgrounds(palette).critical,
                foregrounds: foregrounds(palette).critical,
                iconColor: palette.magenta.opaque[400]
            },
            high: {
                borders: borders(palette).high,
                backgrounds: backgrounds(palette).high,
                foregrounds: foregrounds(palette).high,
                iconColor: palette.red.opaque[400]
            },
            medium: {
                borders: borders(palette).medium,
                backgrounds: backgrounds(palette).medium,
                foregrounds: foregrounds(palette).medium,
                iconColor: palette.orange.opaque[400]
            },
            low: {
                borders: borders(palette).low,
                backgrounds: backgrounds(palette).low,
                foregrounds: foregrounds(palette).low,
                iconColor: palette.yellow.opaque[400]
            },
            lowRisk: {
                borders: borders(palette).lowRisk,
                backgrounds: backgrounds(palette).lowRisk,
                foregrounds: foregrounds(palette).lowRisk,
                iconColor: palette.green.opaque[500]
            },
            info: {
                borders: borders(palette).info,
                backgrounds: backgrounds(palette).info,
                foregrounds: foregrounds(palette).info,
                iconColor: palette.blue.opaque[300]
            },
            important: {
                borders: borders(palette).important,
                backgrounds: backgrounds(palette).important,
                foregrounds: foregrounds(palette).important,
                iconColor: palette.purple.opaque[200]
            }
        }
    );
};