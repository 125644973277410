import React, { useEffect, useState } from 'react';
import { extractFilterFieldsToNewModel, initFilterPanel } from 'common/components/FilterPanel/FilterPanelManager';
import { Aggregations, IFiltersValues } from 'common/components/FilterPanel/FilterPanel.interface';
import { getHttpService, ICustomzationResponse } from 'common/interface/services';
import { getCloudAccountsService, getOrganizationalUnitService } from 'common/interface/data_services';
import { getProtectedAssetsService, IProtectedAssetFilter } from 'common/module_interface/assets/ProtectedAssets';
import ProtectedAssetsTable, { CounterStatus } from 'common/components/ProtectedAssets/ProtectedAssetsTable';
import {
    AssetAggregationCacheConfig,
    Datasource,
    IDataSourceConfig,
} from 'common/components/ProtectedAssets/datasource';
import { FILTER_PANEL_QUERY_NAMES, FILTERS_KEYS } from 'common/components/FilterPanel/FilterPanel.consts';
import { EXTERNAL_ADDITIONAL_FIELDS_SOURCE } from 'common/components/ProtectedAssets/ProtectedAssetsTable.consts';
import AssetTabs from 'common/components/ProtectedAssets/AssetTabs';
import {
    DynamicProtectedAssetsTableProps,
    IDynamicDataSourceConfig,
    IWebAppFilterParams,
    pageSize,
} from './DynamicProtectedAssetsTable.types';
import FilterPanelPlaceholder from './components/FilterPanelPlaceholder';
import { Stack } from 'common/design-system/components-v2';

const getDataSourceConfig = (config: IDynamicDataSourceConfig): IDataSourceConfig => {
    const { includedEntityTypes, defaultFilters, filters, defaultSortModel, cachingConfig } = config;
    return ({
        pageSize,
        filters: {
            ...defaultFilters || {},
            ...filters || {},
            fields: [
                ...defaultFilters?.fields || [],
                ...filters?.fields || [],
            ],
            includedEntityTypes,
        },
        defaultSortModel,
        externalAdditionalFields: {
            source: EXTERNAL_ADDITIONAL_FIELDS_SOURCE.THIRD_PARTY,
        },
        cachingConfig,
    });
};

const DynamicProtectedAssetsTable: React.FC<DynamicProtectedAssetsTableProps> = (props) => {
    const { columns, includedEntityTypes, tableId, defaultFilters, defaultSortModel,withTabsPanel, withFilterPanel, hideExport, tablePadding } = props;

    const [filterElement, setFilterElement] = useState(<FilterPanelPlaceholder />);
    const [datasource, setDatasource] = useState(new Datasource(getDataSourceConfig({ includedEntityTypes, defaultFilters, defaultSortModel })));
    const [totalCount, setTotalCount] = useState(CounterStatus.Pending);
    const [currentCount, setCurrentCount] = useState(CounterStatus.Pending);
    const [isInitLoading, setIsInitLoading] = useState(true);
    const [isFilterLoading, setIsFilterLoading] = useState(false);

    useEffect(() => {
        async function getAggregations(filtersValues: IFiltersValues): Promise<Aggregations> {
            const tempDatasource = new Datasource(getDataSourceConfig({ includedEntityTypes, defaultFilters, filters: filtersValues, defaultSortModel, cachingConfig: AssetAggregationCacheConfig }));
            setDatasource(tempDatasource);
            const searchResponse = await tempDatasource.getAdHokDataFromServer();
            setCurrentCount(tempDatasource.totalCount);
            setIsFilterLoading(false);
            return searchResponse.aggregations;
        }

        const extractWebAppFiltersFromQueryParams = () => {
            const queryParams = new URLSearchParams(window.location.search);
            const webAppQueryParams = queryParams.get(FILTER_PANEL_QUERY_NAMES.QUERY);

            if (webAppQueryParams) {
                const urlFilters: IWebAppFilterParams = JSON.parse(webAppQueryParams);
                const filter = urlFilters?.filter;
                const fields = filter?.fields ? extractFilterFieldsToNewModel(filter.fields) : {};
                const freeTextPhrase = filter?.freeTextPhrase ? { [FILTERS_KEYS.FREE_TEXT]: filter?.freeTextPhrase } : {};
                const tags = filter?.tags ? { [FILTERS_KEYS.TAGS]: filter.tags } : [];

                return { ...fields, ...freeTextPhrase, ...tags };
            }
        };

        async function renderFilterPanel(aggregations: Aggregations, filters: Array<IProtectedAssetFilter>) {
            const filtersInitialData = await getFiltersInitialData();
            const webAppQueryParams = extractWebAppFiltersFromQueryParams();
            const filterPanelElementsList = getProtectedAssetsService().getFilterDefs(filters, {
                aggregations,
                filtersInitialData,
                allCloudAccounts: filtersInitialData.allCloudAccounts,
            });
            const filterPanel = initFilterPanel({
                filterPanelElementsList,
                getAggregations: getAggregations,
                shouldBuildObjectForAPI: true,
                webAppQueryParams: webAppQueryParams,
                filterId: tableId,
            });
            setFilterElement(filterPanel);
        }

        async function initPage() {
            const tempDatasource = new Datasource(getDataSourceConfig({ includedEntityTypes, defaultFilters, defaultSortModel, cachingConfig: AssetAggregationCacheConfig }));
            const initialData = await tempDatasource.getAdHokDataFromServer();
            setTotalCount(initialData.totalCount);

            if (withFilterPanel) {
                setIsInitLoading(true);
                setIsFilterLoading(true);
                const filtersWithDefaults = [
                    { id: 'add filter', position: 10 },
                    { id: 'free text' },
                    ...withFilterPanel.filters,
                    {
                        id: 'saved filters',
                        filterProps: {
                            savedFiltersComponentName: `${tableId}_server_index`,
                        },
                        position: 1000,
                    },
                    {
                        id: 'recently used',
                        filterProps: {
                            componentName: `${tableId}_server_index_recent`,
                        },
                    },
                    { id: 'clear all' },
                ];
                await renderFilterPanel(initialData.aggregations, filtersWithDefaults);
            } else {
                setDatasource(tempDatasource);
                setCurrentCount(tempDatasource.totalCount);
            }
            setIsInitLoading(false);
        }

        initPage();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    const getFiltersInitialData = async () => {
        const savedFiltersPromise = getHttpService().request<ICustomzationResponse<any>>(`customization?component=${tableId}_server_index`, {
            method: 'GET',
        }, { cachingConfig: { useCache: true } });
        const recentlyUsedPromise = getHttpService().request<ICustomzationResponse<any>>(`customization?component=${tableId}_server_index_recent`, {
            method: 'GET',
        }, { cachingConfig: { useCache: true } });
        const organizationalUnitsPromise = getOrganizationalUnitService().getOrganizationalUnitsView();
        const allCloudAccountsPromise = getCloudAccountsService().getAllCloudAccounts();
        return Promise.all([savedFiltersPromise, recentlyUsedPromise, organizationalUnitsPromise, allCloudAccountsPromise]).then(
            ([savedFilters, recentlyUsed, organizationalUnits, allCloudAccounts]) => {
                return {
                    savedFilters,
                    recentlyUsed,
                    organizationalUnits,
                    allCloudAccounts,
                };
            },
        );
    };

    return (
        <Stack fullHeight data-aid={`protected-assets-page-${tableId}`}>
            {withTabsPanel && (
                <AssetTabs
                    pageId={withTabsPanel.pageId}
                    enableTabSelection={false}
                    closeAllUrl={withTabsPanel.closeAllUrl}
                />
            )}
            <Stack fullHeight padding={tablePadding}>
                {withFilterPanel && filterElement}
                <ProtectedAssetsTable
                    columns={columns}
                    isLoading={isInitLoading || isFilterLoading}
                    totalCount={totalCount}
                    currentCount={currentCount}
                    pageSize={pageSize}
                    datasource={datasource}
                    hideExport={hideExport}
                    tableId={`protected-asset-table-workloads-${tableId}`}
                />
            </Stack>
        </Stack>
    );
};

export default DynamicProtectedAssetsTable;
