export interface IUserRole {
    accountId: number;
    accountName: string;
    role: string;
}

export interface IGetAccountRequest {
    iconUrl?: string;
    name: string;
    activeFeatures: string[];
}

export interface ICloudAccount {
    d9AwsAccountNumber: string;
}

export interface IUserAccount {
    plan: string;
    billingProvider: BillingProvider;
    crossAccountIdentifier: boolean;
    // TODO: need to add all property of account
}

export const BillingProviders = {
    AWS: 'AWS',
    MSP: 'MSP',
    Dome9: 'Dome9',
} as const;

export type BillingProvider = keyof typeof BillingProviders;

export interface IUser {
    name: string;
    isSuperUser: boolean;
    accountId: number;
    calculatedPermissions: { [key: string]: string[] };
    canSwitchRole: boolean;
    email: string;
    account: IUserAccount;
    hasApiKey: boolean;
    hasApiKeyV1: boolean;
    hasApiKeyV2: boolean;
    iamSafe: {
        cloudAccounts: string[];
    };
    id: number;
    isAuditor: boolean;
    isLocked: boolean;
    isMfaEnabled: boolean;
    isMobileDevicePaired: boolean;
    isOwner: boolean;
    isSuspended: boolean;
    lastLogin: string;
    permissions: { [key: string]: string[] };
    roleIds: number[];
    ssoEnabled: boolean;
}

export interface IUserMetadata {
    user?: IUser;
    username: string;
    roles: IUserRole[];
    account?: IGetAccountRequest;
    rootUser?: string;
    roleName?: string;
    userError?: string;
    hasRole: boolean;
    cloudAccount?: ICloudAccount;
    permissions: IPermissionObject;
    permissionsRequest: IPermissionObject;
    firstName?: string;
    lastName?: string;
}

export type IPermissionObject = { [key: string]: boolean };

export interface IAssignUser {
    id: number;
    name: string;
    isOwner: boolean;
    isCurrent: boolean;
}
