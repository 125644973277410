import { FC } from 'react';
import { Label, RadioButton, Stack } from '../../../../../design-system/components-v2';
import { IOnRadioSelectionChanged, IRadioItem, IRadioOption, IRadioOptionProps } from '../../CustomForm.interface';

export interface IRadioButtonOptionComponentProps {
    radioItem: IRadioItem;
    option: IRadioOption;
    optionProps: IRadioOptionProps;
    isSelected: boolean;
    disabled?: boolean;
    onRadioSelectionChanged: IOnRadioSelectionChanged;
}

export const RadioButtonOptionComponent: FC<IRadioButtonOptionComponentProps> = ({
    radioItem, option, optionProps, isSelected, disabled, onRadioSelectionChanged }) => {
    return (
        <Stack direction={'row'} spacing={1}>
            <RadioButton
                id={option.name}
                label={optionProps.label}
                checked={isSelected}
                disabled={!!disabled}
                onChange={() => onRadioSelectionChanged(radioItem.name, option.name)}
            />
            {(optionProps.isRequired || optionProps.tooltip) &&
                <Label required={optionProps.isRequired} text={''} tooltip={optionProps.tooltip} tooltipIconSize={12}/>}
        </Stack>
    );
};