export const ENTITIES_WITH_PERMISSIONS_TAB = [ 'Instance',
    'Lambda',
    'IamRole',
    'IamUser',
    'VirtualMachine',
    'FunctionApp',
    'User',
    'Group',
    'UserAssignedIdentity',
    'AppRegistration'
];
