import React from 'react';
import { useTranslation } from 'react-i18next';

const NoDataToDisplay: React.FC = () => {
    const { t } = useTranslation();

    return (
        <div className='flex flex-1 items-center justify-center text-weakest'>
            {t('COMMON.OVERVIEW.NO_DATA_TO_DISPLAY')}
        </div>
    );
};


export default NoDataToDisplay;
