import * as React from 'react';
import { SVGProps, Ref, forwardRef } from 'react';
const SvgImport = (props: SVGProps<SVGSVGElement>, ref: Ref<SVGSVGElement>) => (
  <svg
    viewBox="0 0 16 16"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    ref={ref}
    {...props}
  >
    <path
      d="M8 .5A7.5 7.5 0 1 1 .5 8"
      stroke="currentColor"
      strokeLinecap="round"
    />
    <path
      d="M.5 2.608c2.107-.351 3.673.186 4.626 1.14.954.954 1.39 2.42 1.048 4.131m-3.396-1.74 2.725 2.096a.483.483 0 0 0 .677-.088l2.097-2.725"
      stroke="currentColor"
      strokeLinecap="round"
    />
  </svg>
);
const ForwardRef = forwardRef(SvgImport);
export default ForwardRef;
