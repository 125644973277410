import React from 'react';
import ColumnChart from '../../Highcharts/ColumnChart';
import { IGenericWidgetDataProps } from 'common/module_interface/overview/Interface';


const WidgetColumn: React.FC<IGenericWidgetDataProps> = (props) => {
    return (
        <ColumnChart {...props} header={props.widget?.title || ''} />
    );
};


export default WidgetColumn;
