import { ColumnApi } from 'ag-grid-community/dist/lib/columns/columnApi';
import { ColumnState } from 'ag-grid-community/dist/lib/columns/columnModel';
import { ColDef } from 'ag-grid-community/dist/lib/entities/colDef';
import { Column, ColumnPinnedType } from 'ag-grid-community/dist/lib/entities/column';
import { IActionUsageDef, IColumnUsageDef } from '../interface/general';
import { getUserService } from '../interface/services';
import { loadSessionData, saveSessionData } from './session';
import { GridApi, IRowNode } from 'ag-grid-community';
import { ITableAction } from '../design-system/components-v2/Table/Table.types';
import { CGColDef } from '../components/ProtectedAssets/ProtectedAssetsTable.interface';

export const TABLE_GROUP_COLUMN_ID = 'ag-Grid-AutoColumn';
export const TABLE_SELECTION_COL_ID = 'checkbox';
const GROUPING_INDENT_OFFSET = 6;
const GROUPING_INDENT = 16;

export const SELECTION_GRID_COLUMN: CGColDef = {
    colId: TABLE_SELECTION_COL_ID,
    field: TABLE_SELECTION_COL_ID,
    pinned: 'left',
    lockPinned: true,
    headerName: '',
    maxWidth: 40,
    minWidth: 40,
    resizable: false,
    lockPosition: true,
    suppressColumnsToolPanel: false,
    headerCheckboxSelection: true,
    checkboxSelection: true,
};

export const mergeColumnDefs = (columnUsages: IColumnUsageDef[], columnDefs: ColDef[]): ColDef[] => {
    return columnUsages.reduce((acc: ColDef[], column) => {
        const matchedColumnDef = columnDefs.find((columnDef) => column.id === columnDef.colId);
        const itemHasPermissionsToShow = column.permission ? getUserService().hasPermission(column.permission) : true;
        if (matchedColumnDef && itemHasPermissionsToShow) {
            acc.push({ ...matchedColumnDef, ...column.colDefOverride });
        }
        return acc;
    }, []);
};

export const mergeActionDefs = (actionUsages: IActionUsageDef[], actionDefs: ITableAction[]): ITableAction[] => {
    return actionUsages.reduce((actionsAccumulator: ITableAction[], action) => {
        const matchedActionDef = actionDefs.find((actionDef) => action.id === actionDef.id);
        const itemHasPermissionsToShow = action.permission ? getUserService().hasPermission(action.permission) : true;
        if (matchedActionDef && itemHasPermissionsToShow) {
            actionsAccumulator.push({ ...matchedActionDef, ...action.actionDefOverride });
        }
        return actionsAccumulator;
    }, []);
};

export const getTableColDefs = (columnApi: ColumnApi): ColDef[] => {
    const columns = columnApi.getColumns() || [];
    return columns.map((col: Column) => col.getColDef());
};

export const fixPinnedColumns = (columnApi: ColumnApi) => {
    const colStates = columnApi.getColumnState();
    const hasGroupingColumn = colStates.some((colState: ColumnState) => colState.colId === TABLE_GROUP_COLUMN_ID);
    if (hasGroupingColumn) {
        colStates.forEach((colState: ColumnState) => {
            if (colState.colId === TABLE_GROUP_COLUMN_ID) {
                columnApi.setColumnPinned(colState.colId, 'left');
            } else {
                columnApi.setColumnPinned(colState.colId, null);
            }
        });
    } else {
        const colDefs: ColDef[] = getTableColDefs(columnApi);
        colDefs.forEach((colDef: ColDef) => {
            if (colDef.colId) {
                const pinned: ColumnPinnedType = colDef.pinned;
                columnApi.setColumnPinned(colDef.colId, pinned);
            }
        });
    }
};
export const saveColumnsState = (columnApi: ColumnApi, storageKey: string) => {
    const columnsState: ColumnState[] = columnApi.getColumnState();
    saveSessionData(columnsState, storageKey);
};

export const loadColumnsState = (columnApi: ColumnApi, storageKey: string) => {
    const columnsState: ColumnState[] = loadSessionData(storageKey);
    if (columnsState?.length) {
        columnApi.applyColumnState({ state: columnsState, applyOrder: true });
    }
};

export const getAllTableItems = <T = any>(gridApi: GridApi<T>): T[] => {
    const items: T[] = [];
    gridApi.forEachNode((node: IRowNode<T>) => {
        if (node.data) {
            items.push(node.data);
        }
    });
    return items;
};

export const getAllTableItemsAfterFilter = <T = any>(gridApi: GridApi<T>): T[] => {
    const items: T[] = [];
    gridApi.forEachNodeAfterFilter((node: IRowNode<T>) => {
        if (node.data) {
            items.push(node.data);
        }
    });
    return items;
};

export const getDisplayedTableItems = <T = any>(gridApi: GridApi<T>): T[] => {
    const items: T[] = [];
    const rowCount = gridApi.getDisplayedRowCount();
    for (let i = 0; i < rowCount; i++) {
        const node: IRowNode<T> | undefined = gridApi.getDisplayedRowAtIndex(i);
        if (node?.data) {
            items.push(node.data);
        }
    }
    return items;
};

export const getGroupedCols = (columnApi: ColumnApi): ColDef[] => {
    return columnApi.getRowGroupColumns().map(col => col.getColDef());
};

export const calcGroupedColLeafIndent = (columnApi: ColumnApi): number | undefined => {
    const colDefs: ColDef[] = getGroupedCols(columnApi);
    if (colDefs.length > 0) {
        return GROUPING_INDENT_OFFSET + (GROUPING_INDENT * colDefs.length);
    }
};


