import React from 'react';
import { IDropdownProps } from './Dropdown.types';
import Popover from '../Popover/Popover';
import Stack from '../Stack/Stack';
import Typography from '../Typography/Typography';
import DropdownStyles from './Dropdown.styles';
import Button from '../Button/Button';

const Dropdown = React.forwardRef<HTMLButtonElement, IDropdownProps>((props, ref) => {
    const {
        open,
        children,
        onStateChange,
        placement,
        buttonProps: { onClick: onButtonClick, dataAid: buttonDataAid, ...restButtonProps } = {},
        label,
        maxHeight
    } = props;

    const buttonRef = React.useRef<HTMLButtonElement>(null);
    const [isOpen, setIsOpen] = React.useState(open || false);

    React.useImperativeHandle(ref, () => buttonRef.current as HTMLButtonElement);

    React.useEffect(() => {
        setIsOpen(open || false);
    }, [open]);

    const handleStateChange = () => {
        setIsOpen(current => {
            onStateChange?.(!current);
            return !current;
        });
    };

    const handleClick = (e: React.MouseEvent<HTMLButtonElement, MouseEvent>) => {
        handleStateChange();
        onButtonClick?.(e);
    };

    return (
        <>
            <Button
                dataAid={buttonDataAid || 'dropdown-button'}
                variant='text'
                color='normal'
                {...restButtonProps}
                ref={buttonRef}
                onClick={handleClick}
                active={isOpen}
            >
                <Stack direction='row' spacing={2} alignItems='center'>
                    <Typography variant='body' color='inherit'>{label}</Typography>
                    <DropdownStyles.StyledIcon $isOpen={isOpen} name='chevronDown' size={10} />
                </Stack>
            </Button>
            <Popover open={isOpen} anchorEl={buttonRef.current} onClose={handleStateChange} placement={placement} maxHeight={maxHeight}>
                {children}
            </Popover>
        </>
    );
});
Dropdown.displayName = 'Dropdown';

export default Dropdown;
