import styled, { css } from 'styled-components';
import { SwitchSize } from './Switch.types';

export const switchDimensions = {
    small: { width: '32px', height: '16px' },
    medium: { width: '40px', height: '24px' },
};

const HiddenInput = styled.input`
  position: absolute;
  left: 0;
  top: 0;
  right: 0;
  bottom: 0;
  outline: unset;
  opacity: 0;
`;

const SwitchContainer = styled.label<{ size: SwitchSize }>`
  position: relative;
  display: inline-block;

  ${({ size }) => css`
    width: ${switchDimensions[size].width};
    height: ${switchDimensions[size].height};
  `}
`;

interface SliderProps {
  isChecked: boolean;
  isDisabled?: boolean;
  size: SwitchSize;
  isInputFocused: boolean;
}

const Slider = styled.span<SliderProps>`
  position: absolute;
  cursor: pointer;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: ${({ isChecked, theme }) => isChecked ? theme.palette.surface.success : theme.palette.surface.strong };
  transition: .4s;
  border-radius: ${({ theme }) => `${theme.border.radius(3)}${theme.units}` };

  ${({ theme, isDisabled, isChecked }) => !isDisabled && css`
    background-color: ${ isChecked ? theme.palette.surface.success : theme.palette.surface.strong };

    :hover {
      background-color: ${isChecked ? theme.palette.surfaceStates.successHover : theme.palette.surfaceStates.strongHover };
    }

    :active {
      background-color: ${ isChecked ? theme.palette.surfaceStates.successActive : theme.palette.surfaceStates.strongActive };
    }
  `}

  ${({ isDisabled }) => isDisabled && css`
      opacity: ${({ theme }) => theme.disabledOpacity };
      cursor: auto;
  `}

  ${({ isInputFocused, theme }) => isInputFocused && css`
    box-shadow: ${theme.shadows.focus};
  `}

  &:before {
    position: absolute;
    content: "";
    
    background-color: white;
    transition: .1s;
    border-radius: 50%;

    ${({ size, isChecked }) => size === 'small' && css`
      width: 12px;
      height: 12px;

      left: 2px;
      bottom: 2px;

      transform: ${isChecked ? 'translateX(16px)' : 'translateX(0)'};
    `}

    ${({ size, isChecked }) => size === 'medium' && css`
      width: 16px;
      height: 16px;
    
      left: 4px;
      bottom: 4px;

      transform: ${isChecked ? 'translateX(16px)' : 'translateX(0)'};

    `}
  }
`;

const SwitchStyles = {
    HiddenInput,
    SwitchContainer,
    Slider,
};

export default SwitchStyles;