import { Stack } from 'common/design-system/components-v2';
import styled from 'styled-components';

const Wrapper = styled(Stack)`
    min-width: 0px;
`;

const ActionsStyles = {
    Wrapper
};

export default ActionsStyles;
