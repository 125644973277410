import React from 'react';
import { BoxesRowStyled } from './PropertyBoxesRow.styled';

const PropertyBoxesRow: React.FC<{ children: React.ReactNode }> = ({ children }) => {
    return (
        <BoxesRowStyled.TopDiv>
            {children}
        </BoxesRowStyled.TopDiv>
    );
};

export default PropertyBoxesRow;
