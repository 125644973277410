import React, { useMemo } from 'react';
import {
    IGenericWidgetDataItem,
} from 'common/module_interface/overview/Interface';
import { useTranslation } from 'react-i18next';
import { DonutStyle } from './DonutPie.styled';
import { formatNumberShorthand } from 'common/utils/helpFunctions';
import _ from 'lodash';
import { lightPallete } from 'common/design-system/theme/colors';
import { IDonutPieSettings } from 'common/erm-components/custom/DonutPie/DonutPie.interface';
import PieChart from 'common/components/Highcharts/PieChart';
import { JustifyContent } from 'common/design-system/components-v2/Stack/Stack.types';

const EMPTY_PIE_KEY = 'empty-pie-key';
const DEFAULT_SIZE = 120;
const DEFAULT_JUSTIFY_CONTENT: JustifyContent = 'center';

const DonutPie: React.FC<{ items?: IGenericWidgetDataItem[], highchartsOptions?: any, settings?: IDonutPieSettings }> = ({ items = [], highchartsOptions, settings }) => {
    const { t } = useTranslation();
    const totalCount = useMemo(() => _.sumBy(items, 'value'), [items]);

    const finalItems: IGenericWidgetDataItem[] = useMemo(() => {
        return (totalCount > 0) ? items : [
            {
                key: EMPTY_PIE_KEY,
                value: 100,
                color: settings?.emptyDataColor || lightPallete.border.normal,
            }
        ];
    }, [items, settings?.emptyDataColor, totalCount]);

    const finalPieSettings: IDonutPieSettings = useMemo(() => {
        const finalSettings: IDonutPieSettings = {
            titleStyle: { fontSize: '20px' },
            subtitleStyle: {
                fontSize: '12px',
                marginTop: '2px',
                borderTop: `1px solid ${lightPallete.border.normal}`,
                padding: '8px 5px 0 5px',
            },
        };
        _.merge(finalSettings, settings);
        return finalSettings;
    }, [settings]);

    const finalHighchartsOptions: any = useMemo(() => {
        const finalOptions: any = {
            chart: {
                spacing: [0, 0, 0, 0],
                margin: [0, 0, 0, 0],
                size: '100%',
            },
            tooltip: {
                style: {
                    fontSize: finalPieSettings.useSimpleTooltip ? '12px' : undefined,
                },
            },
            plotOptions: {
                pie: {
                    size: '100%',
                    tooltip: {
                        pointFormat: finalPieSettings.useSimpleTooltip ? '{point.name}: {point.y:,.0f}' : undefined,
                    },
                },
            },
            series: [{
                size: '100%',
                borderWidth: 1,
                thickness: finalPieSettings.thickness,
                enableMouseTracking: (totalCount > 0),
            }],
        };
        _.merge(finalOptions, highchartsOptions);
        return finalOptions;
    }, [finalPieSettings, totalCount, highchartsOptions]);

    return (
        <DonutStyle.TopDiv justifyContent={finalPieSettings.justifyContent || DEFAULT_JUSTIFY_CONTENT}>
            <DonutStyle.PieDiv size={(finalPieSettings.size || DEFAULT_SIZE) + 20}>
                <PieChart
                    items={finalItems}
                    title={formatNumberShorthand(totalCount)}
                    subtitle={t('COMMON.IN_TOTAL2')}
                    highchartsOptions={finalHighchartsOptions}
                    settings={finalPieSettings}
                />
            </DonutStyle.PieDiv>
        </DonutStyle.TopDiv>
    );
};

export default DonutPie;
