import React, { useCallback, useEffect, useMemo, useRef, useState } from 'react';
import {
    getEntityService,
    getLocalStorageService,
    getWebAppIframeService
} from 'common/interface/services';
import { getProtectedAssetsService } from 'common/module_interface/assets/ProtectedAssets';
import { Button, Tabs } from '@dome9/berries/react-components';
import { useTranslation } from 'react-i18next';
import { useHistory, useLocation } from 'react-router-dom';
import { TabItem } from '@dome9/berries/react-components/dist/types/components/Tabs/Tabs.types';
import { ContextMenu } from 'common/components/ContextMenu/ContextMenu';
import { getAssetInfoFromLocation } from './AssetUtils';
import { Icon } from 'common/design-system/components-v2';

export interface IAssetInfo {
    id: string,
    type: string,
    url: string,
    cloudAccountId: string,
    platform: string,
    name: string,
    hash: number
}

const AssetTabs: React.FC<{ pageId: string, closeAllUrl: string, enableTabSelection?: boolean }> = (props) => {
    const { enableTabSelection = true, pageId, closeAllUrl } = props;
    const getOpenTabs = () : IAssetInfo[] => getLocalStorageService().getFromLocalStorage('OPEN_TABS', pageId ) || [];
    const history = useHistory();
    const location = useLocation();
    const assetInfo = getAssetInfoFromLocation(location);
    const [openTabs, setOpenTabs] = useState(getOpenTabs());
    const [selectedTabId, setSelectedTabId] = useState(assetInfo ? assetInfo.hash : 0 );
    const { t } = useTranslation();
    const lastLocationRef = useRef<any>(location);

    useEffect(() => {
        if (openTabs === undefined) {
            setOpenTabs([]);
        }
        if (selectedTabId === undefined) {
            setSelectedTabId(0);
        }
    }, [openTabs, selectedTabId, pageId]);

    const saveOpenTabs = useCallback((tabs : IAssetInfo[]) => {
        getLocalStorageService().setToLocalStorage('OPEN_TABS', pageId, tabs );
        setOpenTabs(tabs);
    }, [pageId]);

    const onTabSelectedInternal = useCallback((tabItem: TabItem) => {
        const asset = openTabs?.find(asset => asset.hash === tabItem.id);
        if (asset) {
            setSelectedTabId(asset.hash );
            history.replace(asset.url);
            getWebAppIframeService().navigate(asset.url);
        }
    }, [history, openTabs]);

    const buildTabs = useCallback((openAssets: IAssetInfo[]): TabItem[] => {
        const enum TabAction {
            CLOSE,
            CLOSE_OTHERS,
            CLOSE_ALL
        }

        function executeTabAction(event: MouseEvent, asset: IAssetInfo, action: TabAction) {
            switch (action) {
                case TabAction.CLOSE:
                    rebuildTabs(event, asset, (asset: IAssetInfo) => openAssets.filter(openAsset => openAsset.hash !== asset.hash));
                    break;
                case TabAction.CLOSE_OTHERS:
                    rebuildTabs(event, asset, (asset: IAssetInfo) => openAssets.filter(openAsset => openAsset.hash === asset.hash));
                    break;
                case TabAction.CLOSE_ALL:
                    rebuildTabs(event, asset, () => []);
                    break;
            }
        }

        function rebuildTabs(event: MouseEvent, asset: IAssetInfo, rebuildAction: Function) {
            event?.preventDefault();
            const _openAssets = rebuildAction(asset);
            saveOpenTabs(_openAssets);

            buildTabs(_openAssets);
            if (_openAssets.length === 0) {
                setSelectedTabId(0);
                history.push(closeAllUrl);
            } else {
                if (_openAssets.every((asset: IAssetInfo) => asset.hash !== selectedTabId)) {
                    setSelectedTabId(_openAssets[_openAssets.length - 1].hash );
                    getWebAppIframeService().navigate(_openAssets[_openAssets.length - 1].url);
                }
            }
        }

        const getIcon = (asset: IAssetInfo) => {
            const typeByPlatform = `${asset?.platform}|${asset.type}`;
            let icon = getProtectedAssetsService().getAssetByType(typeByPlatform)?.icon ?? null;
            if (!icon) {
                icon = getProtectedAssetsService().getAssetByType(asset.type)?.icon ?? '';
            }
            return icon;
        };

        return openTabs.map(asset => {
            function renderTabTitle(asset: IAssetInfo) {
                const icon = getIcon(asset);
                function renderTabsActions(asset: IAssetInfo) {
                    return (
                        <div className='flex flex-column'>
                            <Button style={{ justifyContent: 'start !important' }}
                                className='block w-full text-left' variant='integrated'
                                onClick={(event: any) => executeTabAction(event, asset, TabAction.CLOSE)}>{t('COMMON.TABS.CLOSE')}</Button>
                            <Button style={{ justifyContent: 'start !important' }}
                                className='block w-full text-left' variant='integrated'
                                onClick={(event: any) => executeTabAction(event, asset, TabAction.CLOSE_OTHERS)}>{t('COMMON.TABS.CLOSE_OTHERS')}</Button>
                            <Button style={{ justifyContent: 'start !important' }}
                                className='block w-full text-left' variant='integrated'
                                onClick={(event: any) => executeTabAction(event, asset, TabAction.CLOSE_ALL)}>{t('COMMON.TABS.CLOSE_ALL')}</Button>
                        </div>
                    );
                }

                return (
                    <>
                        <ContextMenu render={() => renderTabsActions(asset)}>
                            <div className='flex items-center w-[200px]'>
                                {icon && <Icon vendorNameOrPath={icon} size={16} />}
                                <div data-aid='tab-title'
                                    className='ml-3 flex-1 truncate text-left'>{asset?.name || asset?.id}</div>
                                <Button icon='remove' variant='integrated' iconSize={12}
                                    onClick={(event: any) => executeTabAction(event, asset, TabAction.CLOSE)}
                                    className='rounded-full self-center flex-0 ml-2 min-h-[unset] !p-[2px]'></Button>
                            </div>
                        </ContextMenu>
                    </>
                );
            }

            return {
                id: asset.hash,
                header: {
                    title: renderTabTitle(asset),
                    removable: false,
                },
            };
        });
    }, [openTabs, saveOpenTabs, history, closeAllUrl, selectedTabId, t]);

    const tabs = useMemo(() => buildTabs(openTabs ?? []), [buildTabs, openTabs]);

    useEffect(() => {

        function isTabExist(assetInfo: IAssetInfo) {
            return assetInfo.hash && openTabs.find(tab => tab.hash === assetInfo.hash);
        }

        function isAssetSelected(assetInfo: IAssetInfo) {
            return assetInfo.hash && selectedTabId === assetInfo.hash;
        }

        async function openTab(assetInfo: IAssetInfo) {
            if (assetInfo.hash === 0) {
                return;
            }
            if (!isTabExist(assetInfo)) {
                if (!assetInfo.name) {
                    const entity = await getEntityService().getEntity(assetInfo.id, assetInfo.type, assetInfo.platform, assetInfo.cloudAccountId);
                    assetInfo.name = entity?.name || '';
                }
                saveOpenTabs([...(openTabs ?? []), assetInfo]);
            }

            if (!isAssetSelected(assetInfo)) {
                setSelectedTabId(assetInfo.hash );
                history.replace((assetInfo.url));
                getWebAppIframeService().navigate(assetInfo.url);
            }
        }

        if(location.search === lastLocationRef.current.search){
            return;
        }else{
            lastLocationRef.current = location;
        }
        if (assetInfo.id === '') {
            return;
        }
        openTab(assetInfo);

    }, [location.search, location.pathname, tabs, selectedTabId, openTabs, history, pageId, location, assetInfo, saveOpenTabs]);

    const calculatedSelectedTabId = enableTabSelection ? selectedTabId : 0;
    return (
        <>
            {
                tabs.length ? <div className='flex-0 border-b pt-5 pl-4'>
                    <Tabs onTabSelected={onTabSelectedInternal} className='overflow-visible'
                        headersOnly selectedTabId={calculatedSelectedTabId}
                        variant='primary' tabs={tabs}
                    />
                </div> : ''
            }
        </>
    );
};

export default AssetTabs;
