export enum Pages {
    Report = 'report',
    BigReport = 'bigReport',
    AssessmentsExecutiveReport = 'assessmentsExecutiveReport',
    Login = 'login',
    Error = 'error',
    ResetPassword = 'reset-password',
    Register = 'register',
    Register__SignUp = 'sign-up',
    Register__Invite = 'invite',
    ForgotPassword = 'forgot-password',
    Old_CG_RegisterPage_ForTesting = 'OLD_CG_RegisterPage_ForTesting',
    Sso = 'sso',
    Sso__FailedLogin = 'failed-login',
    CloudAccountEnv = 'cloud-account',
    CloudOnboarding = 'cloud-onboarding',
}
