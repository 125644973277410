import React, { useCallback, useMemo, useState } from 'react';
import { RadioButton, Stack } from '../../../../design-system/components-v2';
import { RECIPE_PAGE_TABLE_ID } from '../RecipeEditor.consts';
import {
    ClientFilterPageTable,

} from '../../../custom/ClientFilterPageTable/ClientFilterPageTable';
import { getRecipeExampleService } from '../../RecipeExampleCenter/RecipeExampleService';
import { getRecipesColumns } from './RecipeExampleTable/RecipeExampleTable.columns';
import { IRecipe } from '../../RecipeExampleCenter/RecipeExample.interface';
import { getRecipesTableActions } from './RecipeExampleTable/RecipeExampleTable.actions';
import { getRecipeFilters } from './RecipeExampleTable/RecipeExampleTable.filters';
import { RecipeExampleEmptyState } from './RecipeExampleEmptyState/AutoActionsEmptyState';
import { IClientFilterPageTableProps } from '../../../custom/ClientFilterPageTable/ClientFilterPage.interface';

export const RecipeEditorPage: React.FC = () => {
    const [isUsingDrawer, setIsUsingDrawer] = useState<boolean>(true);
    const getIsUsingDrawer = useCallback(() => {
        return isUsingDrawer;
    }, [isUsingDrawer]);

    const pageProps: IClientFilterPageTableProps<IRecipe> = useMemo(() => ({
        fetchAllItems: () => getRecipeExampleService().getAllRecipes(),
        getTableColumnDefs: () => getRecipesColumns(getIsUsingDrawer),
        getTableFilterDefs: getRecipeFilters,
        emptyStateComponent: () => <RecipeExampleEmptyState getIsUsingDrawer={getIsUsingDrawer} />,
        getTableActions: (onTableDataChange: () => void) => getRecipesTableActions(onTableDataChange, getIsUsingDrawer),
        getFooterItemTitle: (count: number) => `${count} recipe${count !== 1 ? 's' : ''}`,
        pageTableId: RECIPE_PAGE_TABLE_ID,
    }), [getIsUsingDrawer]);

    return (
        <Stack direction={'column'} padding={5} fullHeight fullWidth>
            <Stack direction={'row'} spacing={4} alignItems={'center'} padding={[0, 10, 0, 10]}>
                <RadioButton
                    id={'drawer'}
                    label={'Open form in Drawer'}
                    checked={isUsingDrawer}
                    onChange={() => setIsUsingDrawer(true)}
                />
                <RadioButton
                    id={'dialog'}
                    label={'Open form in Dialog'}
                    checked={!isUsingDrawer}
                    onChange={() => setIsUsingDrawer(false)}
                />
            </Stack>
            <ClientFilterPageTable {...pageProps} />;
        </Stack>
    );
};
