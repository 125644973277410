import { FC } from 'react';
import { FormStyled } from '../../CustomForm.styled';
import { IDividerItem } from '../../CustomForm.interface';

export interface IDividerItemComponentProps {
    item: IDividerItem;
    isInner?: boolean;
}

export const DividerItemComponent: FC<IDividerItemComponentProps> = ({ item, isInner }) => {
    const { state } = item;
    return (
        <FormStyled.TopItem widthInfo={state.widthInfo} isInner={isInner}>
            { item.state.spacingOnly ? <FormStyled.Spacer /> : <FormStyled.Divider /> }
        </FormStyled.TopItem>
    );
};