import React, { forwardRef } from 'react';
import LevelIconStyles from './LevelIcon.styles';
import Tooltip from '../Tooltip/Tooltip';
import { LevelIconProps } from './LevelIcon.types';
import Icon from '../Icon/Icon';
import { useTheme } from 'styled-components';

const LevelIcon = forwardRef<HTMLDivElement, LevelIconProps>((props, ref) => {
    const { level, category, size = 'xl', iconProps, circleShape, tooltip, customColor, customBackgroundColor, withBorder } = props;
    const theme = useTheme();

    const propsByCategoryAndLevel = React.useMemo<{ color: string; backgroundColor: string; }>(() => {
        if (!category || !level) return {
            color: customColor || theme.palette.importance.unknownFg,
            backgroundColor: customBackgroundColor || theme.palette.importance.unknownBg
        };
        switch (category) {
            case 'severity':
                return {
                    color: theme.palette.severity[`${level}Fg`],
                    backgroundColor: theme.palette.severity[`${level}Bg`],
                };
            case 'riskLevel':
                return {
                    color: theme.palette.riskLevel[`${level}Fg`],
                    backgroundColor: theme.palette.riskLevel[`${level}Bg`],
                };
            case 'importance':
                return {
                    color: theme.palette.importance[`${level}Fg`],
                    backgroundColor: theme.palette.importance[`${level}Bg`],
                };
        }
    }, [level, category, theme, customColor, customBackgroundColor]);

    const iconSize = React.useMemo(() => {
        switch (size) {
            case 'xs':
                return 12;
            case 'sm':
            case 'md':
                return 16;
            case 'lg':
                return 20;
            case 'xl':
                return 24;
            case '2xl':
                return 32;
        }
    }, [size]);

    return (
        <Tooltip content={tooltip}>
            <LevelIconStyles.Wrapper
                ref={ref}
                size={size}
                color={propsByCategoryAndLevel.color}
                backgroundColor={propsByCategoryAndLevel.backgroundColor}
                circleShape={circleShape}
                withBorder={withBorder}
                context={props.context}
            >
                <Icon {...iconProps} size={iconSize} />
            </LevelIconStyles.Wrapper>
        </Tooltip>
    );
});
LevelIcon.displayName = 'LevelIcon';

export default LevelIcon;