import styled from 'styled-components';

const TopDiv = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: 8px;
`;

const BarsBox = styled.div`
  display: flex;
  flex-direction: row;
  align-items: flex-end;
  height: 12px;
  gap: 1px;
`;

const TextDiv = styled.div`
  overflow: hidden;
  color: var(--fg-colors-fg-regular, #354764);
  text-overflow: ellipsis;
`;

const VerticalBarDiv = styled.div<{ heightPercentage: number, bg: string, opacity: number }>`
  display: flex;
  flex-direction: row;
  height: ${props => `${props.heightPercentage}%`};
  background-color: ${props => props.bg};
  opacity: ${props => props.opacity};
  width: 3px;
`;


export const VolumeStyled = {
    TopDiv,
    BarsBox,
    TextDiv,
    VerticalBarDiv,
};