import * as React from 'react';
import { SVGProps, Ref, forwardRef } from 'react';
const SvgAsterisk = (
  props: SVGProps<SVGSVGElement>,
  ref: Ref<SVGSVGElement>
) => (
  <svg
    viewBox="0 0 16 16"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    ref={ref}
    {...props}
  >
    <path
      d="m7.5.5.003 6.994L12.45 2.55 7.506 7.497 14.5 7.5l-6.994.003 4.944 4.947-4.947-4.944L7.5 14.5l-.003-6.994L2.55 12.45l4.944-4.947L.5 7.5l6.994-.003L2.55 2.55l4.947 4.944L7.5.5Z"
      stroke="currentColor"
      strokeLinejoin="round"
    />
  </svg>
);
const ForwardRef = forwardRef(SvgAsterisk);
export default ForwardRef;
