import * as React from 'react';
import { SVGProps, Ref, forwardRef } from 'react';
const SvgDelete = (props: SVGProps<SVGSVGElement>, ref: Ref<SVGSVGElement>) => (
  <svg
    viewBox="0 0 16 16"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    ref={ref}
    {...props}
  >
    <path
      d="M3.5 3.5h9v11a1 1 0 0 1-1 1h-7a1 1 0 0 1-1-1v-11ZM6 3.5h4V2a.5.5 0 0 0-.5-.5h-3A.5.5 0 0 0 6 2v1.5Z"
      stroke="currentColor"
    />
    <path
      d="M1.5 3.5h13"
      stroke="currentColor"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M6.5 6.5v6M9.5 6.5v6"
      stroke="currentColor"
      strokeLinecap="round"
    />
  </svg>
);
const ForwardRef = forwardRef(SvgDelete);
export default ForwardRef;
