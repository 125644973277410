import React from 'react';
import BlcokInfoStyles from './BlockInfo.styled';
import { BlockInfoProps } from './BlockInfo.types';
import Stack from '../Stack/Stack';
import Icon from '../Icon/Icon';
import Link from '../Link/Link';
import CopiableLine from '../CopiableLine/CopiableLine';

const BlockInfo = React.forwardRef<HTMLDivElement, BlockInfoProps>((props, ref) => {
    const { title, iconProps, info, copyable, internalUrl, externalUrl, maxWidth, fullWidth } = props;

    const isInfoStringOrNumber = typeof info === 'string' || typeof info === 'number';

    const Content = React.useMemo(() => {
        if (isInfoStringOrNumber) {
            if (internalUrl || externalUrl) {
                return (
                    <Link externalUrl={externalUrl} internalUrl={internalUrl} copyText={copyable ? info.toString() : undefined}>
                        <BlcokInfoStyles.StringOrNumberText maxWidth={maxWidth} title={info.toString()}>
                            {info}
                        </BlcokInfoStyles.StringOrNumberText>
                    </Link>
                );
            }
            if (copyable) {
                return (
                    <CopiableLine value={info}>
                        <BlcokInfoStyles.StringOrNumberText maxWidth={maxWidth} title={info.toString()}>
                            {info}
                        </BlcokInfoStyles.StringOrNumberText>
                    </CopiableLine>
                );
            }
        }
        return (
            <BlcokInfoStyles.ComponentWrapper maxWidth={maxWidth} fullWidth={fullWidth}>
                {info}
            </BlcokInfoStyles.ComponentWrapper>
        );
    },[info, internalUrl, externalUrl, copyable, maxWidth, isInfoStringOrNumber, fullWidth]);

    return (
        <BlcokInfoStyles.BlockWrapper ref={ref} maxWidth={maxWidth} isInfoStringOrNumber={isInfoStringOrNumber} fullWidth={fullWidth}>
            <BlcokInfoStyles.StringOrNumberText variant='body500' maxWidth={maxWidth} title={title}>
                {title}
            </BlcokInfoStyles.StringOrNumberText>
            <Stack direction='row' alignItems='center' spacing={2}>
                {iconProps && <Icon {...iconProps} />}
                {Content}
            </Stack>
        </BlcokInfoStyles.BlockWrapper>
    );
});
BlockInfo.displayName = 'BlockInfo';

export default BlockInfo;
