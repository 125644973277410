import { CiemSelectOption } from '../interface/exclusion';
import { Vendors } from './vendors';


export const selectCiemRuleSet = () :CiemSelectOption => {
    return {
        id: 802,
        label: 'Entitlement Management',
        value: '802' ,
        cloudVendor: Vendors.AWS,
    };
};