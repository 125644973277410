import { TextField } from '@dome9/berries/react-components';
import { IDashboardWidget, IGenericWidgetSettingsProps } from 'common/module_interface/overview/Interface';
import { deepCloneObject } from 'common/utils/objectUtils';
import React , { useEffect, useState, ChangeEvent } from 'react';
import { useTranslation } from 'react-i18next';
import { v4 } from 'uuid';

const SummaryWidgetsSettings: React.FC<IGenericWidgetSettingsProps> = ({ widget, updateWidgetCallback }) => {
    const { t } = useTranslation('overview');
    const _widgetClone = deepCloneObject(widget);
    const thresholds = _widgetClone.options?.thresholds;
    if(!_widgetClone.options) {
        _widgetClone.options = {
            summarySubtitle: '',
        };
    }
    if(!thresholds){
        _widgetClone.options.thresholds = [{
            color: '#000000',
            min: 0,
            max: 1,
        }, 
        {
            color: '#56b40a',
            min: 2,
            max: false,
        }
        ];
    }
    const defaultColors = [
        '#56B40A',
        '#B1DBFF',
        '#FFD546',
        '#FFB72D',
        '#FF9126',
        '#F24E4E',
        '#E45785',
    ];

    const colors: string[] = thresholds && thresholds.length > 0 && thresholds[0].colorPlate || defaultColors;
    colors.unshift('#000000');
    const _moreThanNumber = thresholds && thresholds.length >= 2 && thresholds[0].max || 1;

    const [widgetClone, setWidgetClone] = useState<IDashboardWidget>(_widgetClone);
    const [moreThanNumber, setMoreThanNumber] = useState(_moreThanNumber);
    const [moreThanColor, setMoreThanColor] = useState(colors[0]);
    const [lessThanColor, setLessThanColor] = useState(colors[1]);
    const updateWidget =(widgetClone: IDashboardWidget)=>{
        updateWidgetCallback(widgetClone);
        setWidgetClone(widgetClone);
    };

    const handleChangeColor = (event: ChangeEvent<HTMLInputElement>, index: number) =>{
        const newColor = event.target.value;
        if(index === 0){
            setMoreThanColor(newColor);
        } else {
            setLessThanColor(newColor);
        }
        const _widgetClone = deepCloneObject(widgetClone);
        const thresholds = _widgetClone?.options.thresholds;
        if(thresholds && thresholds.length >= 2 && newColor){
            thresholds[index].color = newColor;
        }
        updateWidget(_widgetClone);
    };

    const handleChangeNumber = (e: ChangeEvent<HTMLInputElement>) =>{
        const valueAsNumber = e.target.valueAsNumber;
        setMoreThanNumber(valueAsNumber);
        const _widgetClone = deepCloneObject(widgetClone);
        _widgetClone.options.thresholds[0].max = valueAsNumber;
        _widgetClone.options.thresholds[1].min = valueAsNumber + 1;
        updateWidget(_widgetClone);
    };

    useEffect(() => {
        const _moreThanColor = thresholds && thresholds.length >= 2 && thresholds[0].color;
        const _lessThanColor = thresholds && thresholds.length >= 2 && thresholds[1].color;
        _moreThanColor && setMoreThanColor(_moreThanColor);    
        _lessThanColor && setLessThanColor(_lessThanColor);
    },[thresholds]);
    
    
    return (
        <div>
            <div className="font-semibold">
                {t('WIDGETS.THRESHOLDS', { ns: 'overview' })}
            </div>
            <div className='flex'>
                <div className='flex-1 mr-10'>
                    <div className='flex justify-between items-center mt-6 mr-6'>
                        <div className='flex-1'>
                            <input value={moreThanColor}
                                className='w-[50px]'
                                onChange={(option: any) => handleChangeColor(option, 0)}
                                type="color"
                                list="presets"></input>
                            <datalist id="presets">
                                {colors.map(color => <option key={v4()} value={color}>{color}</option>)}
                            </datalist>
                        </div>
                        <div className='flex-1 mx-4 '>
                                0
                        </div>
                        <div className='flex-1 mx-2'>
                                -
                        </div>
                        <div className='flex-1 z-0 max-w-[100px]'>
                            <TextField
                                allowClear={false}
                                type='number'
                                onChange={handleChangeNumber}
                                value={moreThanNumber || '0'} 
                            />
                        </div>
                    </div>
                </div>
                <div className='flex-1'>
                    <div className='flex justify-between items-center mt-6'>
                        <div className='flex-1'>
                            <input value={lessThanColor}
                                className='w-[50px]'
                                onChange={(option: any) => handleChangeColor(option, 1)}
                                type="color"
                                list="presets"></input>
                            <datalist id="presets">
                                {colors.map(color => <option key={v4()} value={color}>{color}</option>)}
                            </datalist>
                        </div>
                        <div className='flex-1 z-0 w-[120px] text-right'>
                            {moreThanNumber + 1}
                        </div>
                        <div className='flex-1 mx-4'>
                                -
                        </div>
                        <div className='flex-1  ml-6 text-right'>
                            {t('WIDGETS.INFINITY', { ns: 'overview' })}
                        </div>
                    </div>
                </div>
            </div>
            <div className="font-semibold mb-2 mt-6">
                {t('DASHBOARD.SUBTITLE', { ns: 'overview' })}
            </div>
        </div>
    );
};

export default SummaryWidgetsSettings;