import * as React from 'react';
import { SVGProps, Ref, forwardRef } from 'react';
const SvgAssets = (props: SVGProps<SVGSVGElement>, ref: Ref<SVGSVGElement>) => (
  <svg
    viewBox="0 0 16 16"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    ref={ref}
    {...props}
  >
    <path
      d="m8 .5 5.5 1M8 .5l-5.5 1M8 .5l-4 5m4-5 4 5m1.5-4 2 4m-2-4-1.5 4m3.5 0L8 15.5m7.5-10H.5m7.5 10L.5 5.5m7.5 10-4-10m4 10 4-10M.5 5.5l2-4m0 0 1.5 4"
      stroke="currentColor"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </svg>
);
const ForwardRef = forwardRef(SvgAssets);
export default ForwardRef;
