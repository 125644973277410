import styled from 'styled-components';
import { Stack } from 'common/design-system/components-v2';
import { JustifyContent } from 'common/design-system/components-v2/Stack/Stack.types';

const TopDiv = styled(Stack)<{ justifyContent: JustifyContent }>`
  flex-direction: row;
  justify-content: ${props => props.justifyContent};
  width: 100%;
`;

const PieDiv = styled(Stack)<{ size: number }>`
  flex-direction: row;
  max-width: ${props => `${props.size}px`};
  min-width: ${props => `${props.size}px`};
  max-height: ${props => `${props.size}px`};
  min-height: ${props => `${props.size}px`};
`;

export const DonutStyle = {
    TopDiv,
    PieDiv,
};
