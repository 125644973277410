import React, { useCallback, useEffect, useMemo, useRef, useState } from 'react';
import { ArrowLeft, ArrowRight, CarouselBody, CarouselWrapper, ChildWrapper, ChildrenWrapper, PaginationDot, PaginationDotsWrapper } from './Carousel.styled';
import { CarouselProps } from './Carousel.types';
import useHover from '../../hooks/useHover';
import useInterval from '../../hooks/useInterval';

const getChildWrapperStyle = (numberOfItemsToDisplay: number, gap: number): React.CSSProperties => {
    const width = `calc(${ 100 / numberOfItemsToDisplay}% - ${ gap * (numberOfItemsToDisplay - 1) / numberOfItemsToDisplay}px)`;
    return {
        minWidth: width,
        maxWidth: width,
    };
};

const Carousel: React.FC<CarouselProps> = ({ children: items, itemsToDisplay, gap, autoPlay = false, pagination = true }) => {
    const [currentIndex, setCurrentIndex] = useState(0);
    const { targetRef: carouselRef, hovered } = useHover();
    const targetRef = useRef<HTMLDivElement>(null);
    const childrenRefs = useRef<Array<HTMLSpanElement>>([]);
    const childWrapperStyle: React.CSSProperties = useMemo(
        () => getChildWrapperStyle(itemsToDisplay, gap)
        , [gap, itemsToDisplay]);

    const dots = Array.from({ length: Math.ceil(items.length / itemsToDisplay) });

    const scrollToIndex = useCallback((targetIndex: number) => {
        const newTargetInView = targetIndex * itemsToDisplay;

        if(targetRef?.current && childrenRefs.current[newTargetInView]) {
            targetRef.current.scrollLeft = childrenRefs.current[newTargetInView].offsetLeft;
        }

    }, [targetRef, childrenRefs, itemsToDisplay]);

    useInterval(() => {
        const newIndex = (currentIndex + 1) % dots.length;
        setCurrentIndex(newIndex);
    }, 3000, autoPlay && !hovered);

    useEffect(() => {
        scrollToIndex(currentIndex);
    }, [currentIndex, scrollToIndex]);

    const paginationDots = useMemo(() => {
        return (
            <PaginationDotsWrapper>
                {dots.map((key, index) => {
                    return (
                        <PaginationDot
                            key={index}
                            selected={index === currentIndex}
                            onClick={() => {
                                setCurrentIndex(index);
                                scrollToIndex(index);
                            }}
                        />
                    );
                })}
            </PaginationDotsWrapper>
        );
    }, [currentIndex, dots, scrollToIndex]);

    const scrollNext = () => {
        if (currentIndex < dots.length - 1) {
            setCurrentIndex(currentIndex + 1);
        }
    };

    const scrollBack = () => {
        if (currentIndex > 0) {
            setCurrentIndex(currentIndex - 1);
        }
    };

    return (
        <CarouselWrapper ref={carouselRef}>
            <CarouselBody>
                <ArrowLeft className='arrow-buttonn' variant='contained' direction='left' circleShape onClick={scrollBack} iconProps={{ name: 'chevronRight', size: 10 }} />
                <ArrowRight className='arrow-buttonn' variant='contained' direction='right' circleShape onClick={scrollNext} iconProps={{ name: 'chevronRight', size: 10 }} />
                <ChildrenWrapper ref={targetRef} gap={gap}>
                    {items.map(
                        (child, index) =>
                            <ChildWrapper ref={el => {
                                if (childrenRefs.current && el) {
                                    childrenRefs.current[index] = el;
                                }
                            }} key={`child-${index}`} style={childWrapperStyle}>{child}</ChildWrapper>
                    )}
                </ChildrenWrapper>
            </CarouselBody>
            {pagination && paginationDots}
        </CarouselWrapper>
    );
};

export default Carousel;
