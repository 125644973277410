import { Stack } from 'common/design-system/components-v2';
import styled from 'styled-components';

const DropdownWrapper = styled.div`
    white-space: nowrap;
    .dropdown--columns-select {
        border-radius: ${({ theme }) => `${theme.border.radius(2)}${theme.units}`};
        box-shadow: ${({ theme }) => theme.shadows[2]};
    }

    .text-field {
        height: 32px;
    }

    .text-field__body, input {
        border-radius: ${({ theme }) => `${theme.border.radius(2)}${theme.units}`};
    }
`;

const CheckboxList = styled(Stack)`
    max-height: 225px;
    overflow: auto;
`;

const CheckboxWrapper = styled.div`
    width: 100%;
    border-radius: ${({ theme }) => `${theme.border.radius(2)}${theme.units}`};

    &:hover {
        background: ${({ theme }) => theme.palette.componentStates.ghostHover};
    }

    & > label {
        width: 100%;
    }
`;

const ColumnsSelectStyles = {
    DropdownWrapper,
    CheckboxList,
    CheckboxWrapper
};

export default ColumnsSelectStyles;
