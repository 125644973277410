import globalAddinContainer from 'common/extensibility/AddinContainer';
import { Addin } from 'common/extensibility/AddinRegistry';
import {
    IActionEnabler,
    IExclusionModal,
    PATH_EVENTS_EXCLUSION_ENABLER,
    PATH_EXCLUSION_MODAL
} from 'common/interface/exclusion';


export const ExclusionRegistry = {

    addExcludeEventEnablers: (enablers: Addin<IActionEnabler>[]) => {
        globalAddinContainer.add<IActionEnabler>(PATH_EVENTS_EXCLUSION_ENABLER, enablers);
    },
    getExcludeEventEnablers: () : IActionEnabler[]=> {
        return globalAddinContainer.get<IActionEnabler>(PATH_EVENTS_EXCLUSION_ENABLER);
    },
    addExcludeModals: (modal: Addin<IExclusionModal>[]) => {
        globalAddinContainer.add<IExclusionModal>(PATH_EXCLUSION_MODAL, modal);
    },
    getExcludeModals: () : IExclusionModal[]=> {
        return globalAddinContainer.get<IExclusionModal>(PATH_EXCLUSION_MODAL);
    },

};
