import * as React from 'react';
import { SVGProps, Ref, forwardRef } from 'react';
const SvgNetwork = (
  props: SVGProps<SVGSVGElement>,
  ref: Ref<SVGSVGElement>
) => (
  <svg
    viewBox="0 0 16 16"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    ref={ref}
    {...props}
  >
    <rect x={5.5} y={2.5} width={4} height={4} rx={1} stroke="currentColor" />
    <path
      d="M2.5 10.5v-1a1 1 0 0 1 1-1h8a1 1 0 0 1 1 1v1"
      stroke="currentColor"
    />
    <rect
      x={0.5}
      y={10.5}
      width={3}
      height={3}
      rx={1}
      stroke="currentColor"
      strokeLinejoin="round"
    />
    <rect
      x={5.5}
      y={10.5}
      width={4}
      height={3}
      rx={1}
      stroke="currentColor"
      strokeLinejoin="round"
    />
    <path
      d="M7.5 6.5v4"
      stroke="currentColor"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <rect
      x={11.5}
      y={10.5}
      width={3}
      height={3}
      rx={1}
      stroke="currentColor"
      strokeLinejoin="round"
    />
  </svg>
);
const ForwardRef = forwardRef(SvgNetwork);
export default ForwardRef;
