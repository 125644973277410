import * as React from 'react';
import { SVGProps, Ref, forwardRef } from 'react';
const SvgBook = (props: SVGProps<SVGSVGElement>, ref: Ref<SVGSVGElement>) => (
  <svg
    viewBox="0 0 16 16"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    ref={ref}
    {...props}
  >
    <path
      d="M7.5 14.5v-11m0 11c-2.074-1.382-4.625-1.81-6.003-1.941C.947 12.506.5 12.052.5 11.5v-9c0-.552.447-.994.997-.941 1.378.132 3.93.559 6.003 1.941m0 11c2.074-1.382 4.625-1.81 6.003-1.941.55-.053.997-.507.997-1.059v-9c0-.552-.447-.994-.997-.941-1.378.132-3.93.559-6.003 1.941"
      stroke="currentColor"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </svg>
);
const ForwardRef = forwardRef(SvgBook);
export default ForwardRef;
