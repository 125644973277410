import * as React from 'react';
import { SVGProps, Ref, forwardRef } from 'react';
const SvgXls = (props: SVGProps<SVGSVGElement>, ref: Ref<SVGSVGElement>) => (
  <svg
    viewBox="0 0 16 16"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    ref={ref}
    {...props}
  >
    <path
      d="m6 9.5 2.5 6m0-6-2.5 6M10.5 9.5v6H12M15.5 9.5h-1a1 1 0 0 0-1 1v1a1 1 0 0 0 1 1v0a1 1 0 0 1 1 1v1a1 1 0 0 1-1 1h-1M8.5.5v3a2 2 0 0 0 2 2h3m-5-5 5 5m-5-5h-7a1 1 0 0 0-1 1v13a1 1 0 0 0 1 1H4m9.5-10v2"
      stroke="currentColor"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </svg>
);
const ForwardRef = forwardRef(SvgXls);
export default ForwardRef;
