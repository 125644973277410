import { GenericObject } from '../interface/general';
import { getHttpService } from '../interface/services';
import { AFTER_LOGIN_EVENT } from '../extensibility/WellKnownPaths';
import { globalAddinContainer } from '../extensibility/AddinContainer';
import { AllIconsName } from 'common/design-system/components-v2/Icon/Icon.types';

export enum Vendors {
    AWS = 'aws',
    AWS_CHINA = 'awschina',
    AWS_GOV = 'awsgov',
    AZURE = 'azure',
    AZURE_GOV = 'azuregov',
    AZURE_CHINA = 'azurechina',
    GCP = 'gcp',
    GOOGLE = 'google',
    GOV_CLOUD = 'govcloud', // this is acctually AWS_GOV ('awsgov') - it's an issue in the backend need's to be resolved. (DOME-13053)
    KUBERNETES = 'kubernetes',
    CONTAINER_REGISTRY = 'containerregistry',
    TERRAFORM = 'terraform',
    CFT = 'cft',
    GENERIC = 'generic',
    KUBERNETES_RUNTIME_ASSURANCE = 'kubernetesruntimeassurance',
    SHIFT_LEFT = 'shiftleft',
    IMAGE_ASSURANCE = 'imageassurance',
    SOURCE_CODE_ASSURANCE = 'sourcecodeassurance',
    ALIBABA = 'alibaba',
    OCI = 'oci',
    ORACLE = 'oracle'
}

export interface IVendorRegion { //This is how it comes from the server
    region: string; //This is the name we use in our DB
    cloudRegionXID: string; //This is the name used by the vendor
    description: string; //This is the human-readable name
    latitude: number;
    longitude: number;
    regionNumber: number;
}

export enum AssessmentCloudAccountType {
    Aws = 1,
    Azure = 7,
    Google = 10,
    Kubernetes = 11,
    Terraform = 12,
    Generic = 13,
    KubernetesRuntimeAssurance = 14,
    ShiftLeft = 15,
    SourceCodeAssurance = 16,
    ImageAssurance = 17,
    Alibaba = 18,
    Cft = 19,
    ContainerRegistry = 20,
    Ers = 21,
    Oci = 22
}

export enum SRLServiceType {
    CMP = 0,
    AWS = 1,

    SecurityGroup = 2,
    IpList = 3,
    BlackList = 4,
    User = 5,
    Fim = 6,
    Azure = 7,
    Google = 8,
    Compliance = 10,
    Kubernetes = 11,

    OrganizationalUnit = 12,
    ShiftLeft = 13,
    KubernetesRuntimeAssurance = 14,
    Alibaba = 15,
    KubernetesInterface = 16,
    ContainerRegistry = 17,
    Ers = 18,
    Oci = 19
}

export interface IVendor {
    name: Vendors;
    displayName: string;
    uri: string;
    icon: string;
    newIcon: AllIconsName;
    regions?: GenericObject<IVendorRegion>;
    assessmentVendorType: AssessmentCloudAccountType;
    srlVendorType?: SRLServiceType;
    isMain?: boolean;
}
export const VENDORS: IVendor[] = [
    {
        name: Vendors.AWS,
        displayName: 'AWS',
        icon: 'aws',
        newIcon: 'aws',
        uri: 'aws',
        assessmentVendorType: AssessmentCloudAccountType.Aws,
        srlVendorType: SRLServiceType.AWS,
        isMain: true,
    },
    {
        name: Vendors.AWS_CHINA,
        displayName: 'AWS',
        icon: 'aws',
        newIcon: 'aws',
        uri: 'aws',
        assessmentVendorType: AssessmentCloudAccountType.Aws,
        srlVendorType: SRLServiceType.AWS,
    },
    {
        name: Vendors.AWS_GOV,
        displayName: 'AWS',
        icon: 'aws',
        newIcon: 'aws',
        uri: 'aws',
        assessmentVendorType: AssessmentCloudAccountType.Aws,
        srlVendorType: SRLServiceType.AWS,
    },
    {
        name: Vendors.GOOGLE,
        displayName: 'GCP',
        icon: 'gcp',
        newIcon: 'gcp',
        uri: 'gcp',
        assessmentVendorType: AssessmentCloudAccountType.Google,
        srlVendorType: SRLServiceType.Google,
    },
    {
        name: Vendors.GCP,
        displayName: 'GCP',
        icon: 'gcp',
        newIcon: 'gcp',
        uri: 'gcp',
        assessmentVendorType: AssessmentCloudAccountType.Google,
        srlVendorType: SRLServiceType.Google,
        isMain: true,
    },
    {
        name: Vendors.GCP,
        displayName: 'GCP',
        icon: 'gcp',
        newIcon: 'gcp',
        uri: 'gcp',
        assessmentVendorType: AssessmentCloudAccountType.Google,
        srlVendorType: SRLServiceType.Compliance,
        isMain: true,
    },
    {
        name: Vendors.AZURE,
        displayName: 'Azure',
        icon: 'azure',
        newIcon: 'azure',
        uri: 'azure',
        assessmentVendorType: AssessmentCloudAccountType.Azure,
        srlVendorType: SRLServiceType.Azure,
        isMain: true,
    },
    {
        name: Vendors.AZURE_GOV,
        displayName: 'Azure',
        icon: 'azure',
        newIcon: 'azure',
        uri: 'azure',
        assessmentVendorType: AssessmentCloudAccountType.Azure,
        srlVendorType: SRLServiceType.Azure,
    },
    {
        name: Vendors.AZURE_CHINA,
        displayName: 'Azure',
        icon: 'azure',
        newIcon: 'azure',
        uri: 'azure',
        assessmentVendorType: AssessmentCloudAccountType.Azure,
        srlVendorType: SRLServiceType.Azure,
    },
    {
        name: Vendors.IMAGE_ASSURANCE,
        displayName: 'Image Assurance',
        icon: 'imageassurance',
        newIcon: 'imageScan',
        uri: 'kubernetes',
        assessmentVendorType: AssessmentCloudAccountType.ImageAssurance,
    },
    {
        name: Vendors.SOURCE_CODE_ASSURANCE,
        displayName: 'Source Code Assurance',
        icon: 'sourcecodeassurance',
        newIcon: 'shiftleft',
        uri: '',
        assessmentVendorType: AssessmentCloudAccountType.SourceCodeAssurance,
    },
    {
        name: Vendors.SHIFT_LEFT,
        displayName: 'ShiftLeft',
        uri: 'shiftleft',
        icon: 'shiftleft',
        newIcon: 'shiftleft',
        assessmentVendorType: AssessmentCloudAccountType.ShiftLeft,
        srlVendorType: SRLServiceType.ShiftLeft,
    },
    {
        name: Vendors.ALIBABA,
        displayName: 'Alibaba',
        icon: 'alibaba',
        newIcon: 'alibaba',
        uri: 'alibaba',
        assessmentVendorType: AssessmentCloudAccountType.Alibaba,
        srlVendorType: SRLServiceType.Alibaba,
        isMain: true,
    },
    {
        name: Vendors.OCI,
        displayName: 'Oci',
        icon: 'oracle',
        newIcon: 'oracle',
        uri: 'oci',
        assessmentVendorType: AssessmentCloudAccountType.Oci,
        srlVendorType: SRLServiceType.Oci,
        isMain: true,
    },
    {
        name: Vendors.CONTAINER_REGISTRY,
        displayName: 'Container Registry',
        uri: 'containerregistry',
        icon: 'containers-registry',
        newIcon: 'containersRegistry',
        assessmentVendorType: AssessmentCloudAccountType.ContainerRegistry,
        srlVendorType: SRLServiceType.ContainerRegistry,
    },
    {
        name: Vendors.KUBERNETES,
        displayName: 'Kubernetes',
        uri: 'kubernetes',
        icon: 'kubernetes',
        newIcon: 'kubernetes',
        assessmentVendorType: AssessmentCloudAccountType.Kubernetes,
        srlVendorType: SRLServiceType.Kubernetes,
        isMain: true,
    },
];

export function getVendorByNumber(cloudAccountType: string): string | undefined {
    if (!cloudAccountType) return undefined;

    const vendorType = Number(cloudAccountType);
    const srlServiceType = vendorType as SRLServiceType;

    return VENDORS.find(vendor => vendor.srlVendorType === srlServiceType)?.name;
}

export function getVendorByAssesmentNumber(assessmentCloudAccountType: number): IVendor | undefined {
    return VENDORS.find(vendor => vendor.assessmentVendorType === assessmentCloudAccountType);
}

export function getVendor(vendor: string): IVendor | null {
    if (!vendor) {
        return null;
    }
    const lowerCaseVendor = vendor.toLowerCase();

    return VENDORS.find((vendor) => vendor.name === lowerCaseVendor) ?? null;
}

export function getVendorBySrl(srl: string): IVendor | null {
    if (!srl) return null;

    const splitSrl = srl.split('|');
    const srlVendorPart = Number(splitSrl[0]);
    if (isNaN(srlVendorPart)) return null;

    const srlServiceType = srlVendorPart as SRLServiceType;

    const vendor = VENDORS.find(vendor => vendor.srlVendorType === srlServiceType);
    return vendor ?? null;
}

export function getVendorDisplayName(vendor: string): string {
    return getVendor(vendor)?.displayName ?? vendor;
}

export const getGlobalRegion = (): IVendorRegion => {
    return {
        region: 'global',
        cloudRegionXID: 'global',
        description: 'Global',
        latitude: -1,
        longitude: -1,
        regionNumber: 0,
    };
};

export function getRegionByName(regionName: string, vendorId?: string): { region: IVendorRegion, vendor: IVendor }[] {
    const result: { region: IVendorRegion, vendor: IVendor }[] = [];
    if (vendorId) {
        const vendor = getVendor(vendorId);
        if (!vendor) return result;
        let region: IVendorRegion | undefined = vendor.regions && ((vendor.regions[regionName] || vendor.regions[regionName.toLowerCase()]));
        if (!region && regionName.toLowerCase() === 'global') {
            region = getGlobalRegion();
        }
        if (region) {
            return [{ region, vendor }];
        }
    } else {
        Object.entries(VENDORS).forEach(([, vendor]) => {
            let region = vendor?.regions && ((vendor.regions[regionName] || vendor.regions[regionName.toLowerCase()]));
            if (!region && regionName.toLowerCase() === 'global') {
                region = getGlobalRegion();
            }
            if (region) {
                result.push({ region, vendor });
            }
        });
    }
    return result;
}

export function getRegionByNumber(regionNumber?: number, platform?: string): IVendorRegion | undefined {
    if (regionNumber === undefined || !platform) return;
    const vendor = getVendor(platform);
    if (!vendor || !vendor.regions) return;
    return Object.values(vendor.regions).find((region) => region.regionNumber === regionNumber);
}

interface ICloudAccountVendor {
    vendor: string,
    supportedRegions: IVendorRegion[]
}

interface IVendorGcpLocation {
    description: string;
    location: string;
    locationXID: string;
    latitude: number;
    longitude: number;
    regionNumber: number;
    zones: string[];
}

interface IGcpCloudAccountVendor { //Becaase of reasons!
    vendor: string,
    supportedLocations: IVendorGcpLocation[]
}

export function initializeVendors() {
    function getRegionsObject(supportedRegions: IVendorRegion[]): GenericObject<IVendorRegion> {
        const result: GenericObject<IVendorRegion> = {};
        supportedRegions.forEach((vendorRegion) => {
            result[vendorRegion.region] = vendorRegion;
        });
        return result;

    }

    function generateRegionFetcher(url: string) {
        return async () => {
            const cloudAccountVendors = await getHttpService().get<ICloudAccountVendor[]>(url);
            
            cloudAccountVendors.forEach((vendorData) => {
                const vendor = getVendor(vendorData.vendor);
                if (vendor) {
                    vendor.regions = getRegionsObject(vendorData.supportedRegions);
                }
            });
        };
    }

    const getAwsVendorsAddin = {
        id: 'GetAwsVendors',
        content: generateRegionFetcher('cloudAccounts/vendors'),
    };

    const getAzureVendorsAddin = {
        id: 'GetAzureVendors',
        content: generateRegionFetcher('AzureCloudAccount/vendors'),
    };

    const getGcpVendorsAddin = {
        id: 'GetGcpVendors',
        content: async () => { //Why is this different from the rest?
            const cloudAccountVendors = await getHttpService().get<IGcpCloudAccountVendor[]>('GoogleCloudAccount/vendors');
            cloudAccountVendors.forEach((vendorData) => {
                const vendor = getVendor(vendorData.vendor);
                if (vendor) {
                    const result: GenericObject<IVendorRegion> = {};
                    vendorData.supportedLocations.forEach((vendorRegion) => {
                        result[vendorRegion.location] = {
                            region: vendorRegion.location,
                            description: vendorRegion.description,
                            cloudRegionXID: vendorRegion.locationXID,
                            latitude: vendorRegion.latitude,
                            longitude: vendorRegion.longitude,
                            regionNumber: vendorRegion.regionNumber,
                        };
                    });
                    vendor.regions = result;
                }
            });
        },
    };

    const getAlibabaVendorsAddin = {
        id: 'GetAlibabaVendors',
        content: generateRegionFetcher('AlibabaCloudAccount/vendors'),
    };

    globalAddinContainer.add(AFTER_LOGIN_EVENT, [getAlibabaVendorsAddin, getAwsVendorsAddin, getAzureVendorsAddin, getGcpVendorsAddin]);
}
