import { changeUrl, generateHref } from 'common/utils/http';
import React from 'react';
import IconLinkCellRenderer, { IIconLinkCellProps } from 'common/components/ag-grid/Renderers/IconLinkCellRenderer';
import { logComponentClickedEvent } from '../../../utils/logUtils';
import { IconProps } from '../../../design-system/components-v2/Icon/Icon.types';

export interface EntityLinkProps {
    entityName: string;
    url: string;
    containerName?: string,
    iconProps?: IconProps,
    withIconWrapper?: boolean
}

const EntityLink: React.FC<EntityLinkProps> = ({ entityName, url, containerName, iconProps , withIconWrapper = false }) => {
    const options: IIconLinkCellProps = {
        isLink: true,
        value: entityName,
        href: generateHref(url),
        onClick: (event) => {
            event.preventDefault();
            if (containerName) {
                logComponentClickedEvent(containerName, { field: 'entity' });
            }
            changeUrl(url);
        },
    };

    if (iconProps && withIconWrapper) {
        options.levelIconProps = {
            iconProps,
            size: 'md'
        };
    }

    if (iconProps && !withIconWrapper) {
        options.iconProps = iconProps;
    }

    return (<IconLinkCellRenderer {...options} />);
};

export default EntityLink;
