import * as React from 'react';
import { SVGProps, Ref, forwardRef } from 'react';
const SvgOverview = (
  props: SVGProps<SVGSVGElement>,
  ref: Ref<SVGSVGElement>
) => (
  <svg
    viewBox="0 0 16 16"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    ref={ref}
    {...props}
  >
    <rect x={0.5} y={0.5} width={6} height={8} rx={1} stroke="currentColor" />
    <rect x={9.5} y={7.5} width={6} height={8} rx={1} stroke="currentColor" />
    <rect x={9.5} y={0.5} width={6} height={4} rx={1} stroke="currentColor" />
    <rect x={0.5} y={11.5} width={6} height={4} rx={1} stroke="currentColor" />
  </svg>
);
const ForwardRef = forwardRef(SvgOverview);
export default ForwardRef;
