export const FINDING_ASSET_PANEL_ID = 'intelligence-asset-panel';

export const FindingFields = {
    accountId: 'accountId',
    action: 'action',
    additionalFields: 'additionalField',
    assessmentCloudAccountType: 'env_cloudAccountType',
    isArchived: 'archive',
    category: 'category',
    cloudAccountId: 'env_cloudAccountId',
    cloudAccountName: 'env_cloudAccountName',
    cloudAccountType: 'env_cloudAccountId_name',
    cloudAccountExternalId: 'env_externalAccountId',
    comments: 'comments',
    entityExternalId: 'entity_id',
    entityId: 'entity_dome9Id',
    entityName: 'entity_name',
    entityRegion: 'entity_region',
    entityType: 'entity_type',
    eventTime: 'starttime',
    findingKey: 'findingKey',
    id: 'id',
    isAcknowledged: 'acknowledge',
    isExcluded: 'isExcluded',
    minStartTime: 'min_starttime',
    mitreDict: 'mitre_dict',
    mitreTactic: 'mitre_tactic',
    mitreTechnique: 'mitre_technique',
    organizationalUnitId: 'env_organizationalUnitId',
    originId: 'origin',
    origin: 'origin_resolved',
    owner: 'owner',
    ruleDescription: 'rule_description',
    ruleId: 'rule_ruleId',
    ruleLogic: 'rule_logic',
    ruleLogicHash: 'rule_logicHash',
    ruleName: 'rule_name',
    ruleRemediation: 'rule_remediation',
    rulesetDescription: 'bundle_description',
    rulesetId: 'bundle_id',
    rulesetName: 'bundle_name',
    severityLevel: 'rule_severity',
    severityName: 'rule_severity_name',
    statusId: 'status',
    statusName: 'status_name',
    magellanWindowStartTime: 'magellan_alertWindowStartTime',
    magellanWindowEndTime: 'magellan_alertWindowEndTime',

    // group fields - useful when table columns are grouped
    numberOfRows: 'numberOfRows'
};

export enum FindingStatusCode {
    OPEN = 0,
    CLOSE = 1,
}
