import React from 'react';
import { SummaryStyle } from './SummaryStatus.styled';
import { Spinner, Stack, Typography } from 'common/design-system/components-v2';
import { LoadingState } from 'common/interface/general';
import i18n from 'i18next';

interface ISummaryStatusProps {
    components: React.ReactNode[];
    title: string;
    loadingState: LoadingState;
}

export const SummaryStatus: React.FC<ISummaryStatusProps> = ({ components, title, loadingState }) => {
    
    if (loadingState === LoadingState.LOADING_FAILED) {
        return <SummaryStyle.TopDiv><Typography color={'alert'}>{i18n.t('COMMON.ERROR_OCCURRED')}</Typography></SummaryStyle.TopDiv>;
    }

    if ((loadingState === LoadingState.IS_LOADING) || !components){
        return <SummaryStyle.TopDiv><Stack fullWidth fullHeight alignItems={'center'} justifyContent={'center'}><Spinner /></Stack></SummaryStyle.TopDiv>;
    }

    return (
        <SummaryStyle.TopDiv>
            <SummaryStyle.Header text={title} leadingIconProps={{ name: 'info', color: 'brandPrimary' }} size={'xl'}/>
            <SummaryStyle.Body>
                { components.map((component, index) => (
                    <React.Fragment key={index}>
                        { (index !== 0) && <SummaryStyle.StatusSep key={index} /> }
                        { component }
                    </React.Fragment>
                )) }
            </SummaryStyle.Body>
        </SummaryStyle.TopDiv>
    );
};
