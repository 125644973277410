import AddinRegistry from './AddinRegistry';
import { IAddinContainer } from './AddinContainer.interface';

const AXES_SEPARATOR = '.';

export function buildPath(...axes: string[]): string {
    return axes.join(AXES_SEPARATOR);
}

export function splitPath(path: string): string[] {
    return path.split(AXES_SEPARATOR);
}

class AddinContainer extends AddinRegistry {
    private readonly _services: Map<string, any>;

    constructor() {
        super();
        this._services = new Map<string, any>();
    }

    getService<T>(id: string): T {
        const service = this._services.get(id);
        if (service === undefined) {
            throw new Error(`The service ${id} is not in the addin container`);
        }
        return service as T;
    }

    addService(id: string, service: Object): void {
        if (this._services.has(id)) {
            throw new Error(`A service with the id ${id} was already added to the container`);
        }
        this._services.set(id, service);
    }
}

export { AddinContainer };

//This is split into two lines for the autocomplete of Webstorm to work
export const globalAddinContainer = new AddinContainer();
// window['globalAddinContainer'] = globalAddinContainer;
export default globalAddinContainer as IAddinContainer;

export function getService<T>(id: string): T {
    return globalAddinContainer.getService<T>(id);
}

