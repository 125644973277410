import * as React from 'react';
import { SVGProps, Ref, forwardRef } from 'react';
const SvgAcknowledge = (
  props: SVGProps<SVGSVGElement>,
  ref: Ref<SVGSVGElement>
) => (
  <svg
    viewBox="0 0 16 16"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    ref={ref}
    {...props}
  >
    <circle cx={11} cy={11} r={4.5} stroke="currentColor" />
    <path
      d="m9 11 1.5 1.5 3-3M4.5 11.5h-2a2 2 0 0 1-2-2v-7a2 2 0 0 1 2-2h7a2 2 0 0 1 2 2v2"
      stroke="currentColor"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </svg>
);
const ForwardRef = forwardRef(SvgAcknowledge);
export default ForwardRef;
