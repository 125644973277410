import { Vendors } from 'common/consts/vendors';
import { IEnvironment } from 'common/interface/environmentsTable';
import { FeatureStatusRenderer_Type } from 'common/module_interface/assets/Environments';

function constructAwsUrl(cloudAccount: IEnvironment, type: FeatureStatusRenderer_Type): string {
    let url = '';
    let urlParams: { [key: string]: string; } = {};

    switch (type) {
        case FeatureStatusRenderer_Type.GuardDuty:
            url = '/magellan/magellan-aws-cft-onboarding';
            urlParams = {
                account: cloudAccount.externalId,
                type: 'guardduty',
                roleArn: cloudAccount.credentialId,
                accountName: cloudAccount.name
            };
            break;
        case FeatureStatusRenderer_Type.AccountActivity:
            url = '/magellan/magellan-aws-cft-onboarding';
            urlParams = {
                account: cloudAccount.externalId,
                type: 'cloudtrail',
                roleArn: cloudAccount.credentialId,
                accountName: cloudAccount.name
            };
            break;
        case FeatureStatusRenderer_Type.TrafficActivity:
            url = '/magellan/magellan-aws-cft-onboarding';
            urlParams = {
                account: cloudAccount.externalId,
                type: 'flowlogs',
                roleArn: cloudAccount.credentialId,
                accountName: cloudAccount.name
            };
            break;
        case FeatureStatusRenderer_Type.Cloudbots:
            url = '/remediation/aws-cloudbots-onboarding';
            urlParams = {
                id: cloudAccount.id,
                account: cloudAccount.externalId,
                vendor: cloudAccount.platform,
                accountName: cloudAccount.name
            };
            break;
        case FeatureStatusRenderer_Type.IamSafety:
            url = '/iam-safe/welcome';
            break;
        case FeatureStatusRenderer_Type.Serverless:
            url = `/cloud-add/serverless/${cloudAccount.id}`;
            break;
        case FeatureStatusRenderer_Type.Agentless:
            url = `/cloud-add/agentless/aws/${cloudAccount.id}`;
            break;
    }

    return `${url}?${new URLSearchParams(urlParams).toString()}`;
}

function constructAzureUrl(cloudAccount: IEnvironment, type: FeatureStatusRenderer_Type): string {
    let url = '';
    let urlParams: { [key: string]: string; } = {};

    switch (type) {
        case FeatureStatusRenderer_Type.AccountActivity:
            url = `/intelligence/azure-onboarding/${cloudAccount.name}/${cloudAccount.externalId}/ActivityLogs/${cloudAccount.id}`;
            urlParams = {};
            break;
        case FeatureStatusRenderer_Type.TrafficActivity:
            url = `/intelligence/azure-onboarding/${cloudAccount.name}/${cloudAccount.externalId}/FlowLogs/${cloudAccount.id}`;
            urlParams = {};
            break;
        case FeatureStatusRenderer_Type.Cloudbots:
            url = '/remediation/azure-cloudbots-onboarding';
            urlParams = {
                id: cloudAccount.id,
                account: cloudAccount.externalId,
                vendor: cloudAccount.platform,
                accountName: cloudAccount.name
            };
            break;
        case FeatureStatusRenderer_Type.Agentless:
            url = `/cloud-add/agentless/azure/${cloudAccount.id}`;
            break;
    }

    return `${url}?${new URLSearchParams(urlParams).toString()}`;
}

function constructKubernetesUrl(cloudAccount: IEnvironment): string {
    return `/cloud-account/kubernetes/${cloudAccount.id}`;
}

function constructAlibabaUrl(cloudAccount: IEnvironment): string {
    const url = '/magellan/onboarding-alibaba';
    const urlParams: { [key: string]: string; } = {
        account: cloudAccount.externalId,
        accountName: cloudAccount.name,
        type: 'actiontrail'
    };

    return `${url}?${new URLSearchParams(urlParams).toString()}`;
}

function constructGcpGoogleUrl(cloudAccount: IEnvironment, type: FeatureStatusRenderer_Type): string {
    const url = '/magellan/onboarding-gcp';
    let urlParams: { [key: string]: string; } = {
        account: cloudAccount.externalId,
        accountName: cloudAccount.name,
        type: 'flowlogs'
    };

    switch (type) {
        case FeatureStatusRenderer_Type.AccountActivity:
            urlParams = {
                account: cloudAccount.externalId,
                accountName: cloudAccount.name,
                type: 'cloudtrail'
            };
            break;
    }

    return `${url}?${new URLSearchParams(urlParams).toString()}`;
}

export const getOnboardingUrl = (cloudAccount: IEnvironment, type: FeatureStatusRenderer_Type): string => {
    switch (cloudAccount.platform) {
        case Vendors.AWS:
            return constructAwsUrl(cloudAccount, type);
        case Vendors.AZURE:
            return constructAzureUrl(cloudAccount, type);
        case Vendors.KUBERNETES:
            return constructKubernetesUrl(cloudAccount);
        case Vendors.ALIBABA:
            return constructAlibabaUrl(cloudAccount);
        case Vendors.GCP:
        case Vendors.GOOGLE:
            return constructGcpGoogleUrl(cloudAccount, type);
        default:
            console.error('Cloud account vendor not supported: ', cloudAccount.platform);
            return '';
    }
};

export const getUpdateUrl = (cloudAccount: IEnvironment, type: string): string => {
    let urlParams: { [key: string]: string; } = {};
    let url = '';

    switch (cloudAccount.platform) {
        case Vendors.AWS:
        case Vendors.AZURE:
            url = `/cloud-update/${type}/${cloudAccount.id}`;
            urlParams = {};
            break;
        default:
            console.error('Cloud account vendor not supported: ', cloudAccount.platform);
    }

    return `${url}?${new URLSearchParams(urlParams).toString()}`;
};