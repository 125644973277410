import React, { useEffect, useRef, useState } from 'react';
import './AddFilter.scss';
import { IFieldsFilter, IFiltersValues } from '../../FilterPanel.interface';
import { FILTER_DISPAY_TYPES, FILTER_EVENTS } from '../../FilterPanel.consts';
import TagLabel from '../../DefaultFilters/TagLabel/TagLabel';
import { IconButton } from 'common/design-system/components-v2';


const AddFilter: React.FC<any> = ({ filterProps }) => {
    const { onEvent, filtersUtils, key: filterKey,tagsMapping, filtersValues } = filterProps;
    const addFilterButtonRef = useRef(null);

    const [currTagsObject, setCurrTagsObject] = useState<IFiltersValues>({});
    const onCpTagDelete = (tagName: string) => {
        const cloneCurrTagList: IFieldsFilter = { ...currTagsObject };
        delete cloneCurrTagList[tagName];
        setCurrTagsObject(cloneCurrTagList);
        onEvent({ action: FILTER_EVENTS.CLEAR_FILTERS, filterKey: tagName, payload: {} });
    };

    useEffect(() => {
        setCurrTagsObject(filtersValues || {});
    }, [filtersValues]);

    return (

        <>
            <div className='filter-panel__add-btn' data-aid='add-filter-button' ref={addFilterButtonRef}>
                <IconButton
                    iconProps={{ name: 'filter' }}
                    size='small'
                    onClick={(e) =>
                        onEvent({
                            action: FILTER_EVENTS.SHOW_VIEW,
                            filterKey: filterKey,
                            payload: { searchTerm: '', view: FILTER_DISPAY_TYPES.BOX, target: e.target },
                        })}
                />
            </div>
            <div className='filter-panel__chips-container'>
                {
                    Object.entries(currTagsObject)?.map(([tagName, categoryContent]) => {
                        let title = tagName;
                        let content = [];
                        if (categoryContent && filtersUtils[tagName]?.getAsTagView) {
                            [title, content] = filtersUtils[tagName].getAsTagView(categoryContent, filterKey);
                        }
                        const TagComponent = (tagsMapping && tagsMapping[tagName]) ? tagsMapping[tagName] : TagLabel;

                        return(content.length > 0 &&
                                        <TagComponent onDelete={() => onCpTagDelete(tagName)} onClick={() =>
                                            onEvent({
                                                action: FILTER_EVENTS.SHOW_VIEW,
                                                filterKey: filterKey,
                                                payload: { searchTerm: title, view: FILTER_DISPAY_TYPES.BOX, target: addFilterButtonRef.current },
                                            })
                                        } tagName={tagName} content={content} key={title} title={title}/>);

                    })

                }
            </div>
        </>
    );
};

export default AddFilter;
