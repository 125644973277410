import { AllIconsName } from 'common/design-system/components-v2/Icon/Icon.types';
import { buildPath } from 'common/extensibility/AddinContainer';

export const PATH_ENVIRONMENT_PAGE = 'environment';
export const PATH_AZURE_ENVIRONMENT = buildPath(PATH_ENVIRONMENT_PAGE, 'azureEnvironment');
export const PATH_ENVIRONMENT_PAGE_AZURE_DETAILS_PANEL = buildPath(PATH_AZURE_ENVIRONMENT, 'detailsPanel');
export const PATH_ENVIRONMENT_PAGE_AZURE_TAB = buildPath(PATH_AZURE_ENVIRONMENT, 'tab');
export const PATH_ENVIRONMENT_PAGE_AZURE_CHIP = buildPath(PATH_AZURE_ENVIRONMENT, 'chip');
export const PATH_ENVIRONMENT_PAGE_ACTION = buildPath(PATH_AZURE_ENVIRONMENT, 'action');


export interface AzureEnvironmentData {
    account: AzureCloudAccount;
}

export interface AzureCloudAccount {
    id: string;
    vendor: string;
    name: string;
    subscriptionId: string;
    tenantId: string;
    externalAccountNumber: string;
    error: string;
    magellan: boolean;
    isFetchingSuspended: boolean;
    creationDate: string;
    credentials: CloudAccountCredentials;
    organizationalUnitId?: any;
    organizationalUnitPath: string;
    organizationalUnitName: string;
    totalAssets: number;
    isServerlessActive: boolean;
    isCloudbotsOnboarded?: boolean;
    onboardingManagementMethod?: string;
  }

interface CloudAccountCredentials {
    clientId: string;
    clientPassword?: string;
}

export interface ServerlessAzureAccountStatesGetResponse {
    cloudAccountId: string;
    externalAccountNumber: string;
    cloudVendor: string;
    state: string;
    accountId: number;
    reason: string;
    shouldUpdate: boolean;
    isOrgOnboarding: boolean;
    hasMissingPermissions: boolean;
    validatePermissionsStatus: string;
    lastValidatePermissionsTimestamp: number;
}

export interface AzureEnvironmentTab {
    name: string;
    label: string;
    isReactTab: boolean;
    content?: React.ReactElement;
}

export interface AzureEnvironmentAction {
    label: string;
    icon?: AllIconsName;
    callback: (azureAccount: AzureEnvironmentData) => void,
    disabled?: (azureAccount: AzureEnvironmentData) => boolean;
}