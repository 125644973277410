export const getDateMinus = (pastDaysAmount: number) => {
    const dateMinus = new Date();
    dateMinus.setDate(dateMinus.getDate() - pastDaysAmount);
    return dateMinus;
};

export const formatDate = (date: Date | string): string => {
    if (!date) return '';
    const dateObj = new Date(date);
    const month = dateObj.toLocaleString('default', { month: 'short' });
    const day = dateObj.getDate();
    const year = dateObj.getFullYear();
    const hours = dateObj.getHours();
    const minutes = dateObj.getMinutes();
    const ampm = hours >= 12 ? 'PM' : 'AM';
    const formattedHours = hours % 12 || 12;
    const formattedMinutes = minutes < 10 ? `0${minutes}` : minutes;
    return `${month} ${day} ${year} ${formattedHours}:${formattedMinutes} ${ampm}`;
};