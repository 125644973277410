import globalAddinContainer from 'common/extensibility/AddinContainer';
import { Addin } from 'common/extensibility/AddinRegistry';
import {
    PATH_EVENTS_MODALS_PREFIX,
} from '../../interface/events';
import { IEvent } from './Events';

export enum EventsModalOpenFrom {
    TABLE = 'table',
    DRAWER = 'drawer',
}

export interface EventsModalProps {
    modalType: string | null;
    setModalType: (modalType: string | null) => void;
    openFrom: EventsModalOpenFrom;
    selectedRows: IEvent[];
    closeModal: () => void
    resetSelectedRows?: () => void;
    requestNewData?: () => void;
    additionalData?: { [key: string]: any };
}

export interface EventModal {
    id: string;
    modalComponent: React.FC<EventsModalProps>;
    additionalData?: { [key: string]: any };
}

export const EventsModalsRegistry = {
    addModals: (modals: Addin<EventModal>[]) => {
        globalAddinContainer.add(PATH_EVENTS_MODALS_PREFIX, modals);
    },
    getModals: (): EventModal[] => {
        return globalAddinContainer.get(PATH_EVENTS_MODALS_PREFIX);
    }
};
