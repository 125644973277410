import React, { useState } from 'react';
import './SaveFilterForm.scss';
import { SAVED_FILTERS_CATEGORIES } from '../SavedFilters.consts';
import { useTranslation } from 'react-i18next';
import { Button, Checkbox, Input, Stack } from 'common/design-system/components-v2';

export const SaveFilterForm: React.FC<{ existingfilterNames:string[] ,onFilterAdded: Function }> = ({ onFilterAdded,existingfilterNames }) => {
    const { t } = useTranslation();
    const [filterName, setFilterName] = useState('');
    const [scope, setScope] = useState(SAVED_FILTERS_CATEGORIES.PRIVATE);

    const onFilterNameChange = (event: any) => {
        setFilterName(event.target.value);
    };

    const publicCheckboxClicked = (event: any) => {
        setScope(event?.target?.checked ? SAVED_FILTERS_CATEGORIES.PUBLIC : SAVED_FILTERS_CATEGORIES.PRIVATE);
    };

    const privateCheckboxClicked = (event: any) => {
        setScope(event?.target?.checked ? SAVED_FILTERS_CATEGORIES.PRIVATE : SAVED_FILTERS_CATEGORIES.PUBLIC);
    };

    const onSaveFilter = (filterName: string, scope: string) => {
        onFilterAdded(filterName, scope);
    };

    return (
        <div className='border-t border-dashed pt-5 mt-7'>
            <Input
                label={t('FILTER_PANEL.SAVED_FILTERS.SAVE_FILTER')}
                onChange={onFilterNameChange}
                placeholder={t('FILTER_PANEL.SAVED_FILTERS.FILTER_NAME')}
                value={filterName}
            />
            <div>
                <Stack direction='row' spacing={2}>
                    <Checkbox label={t('FILTER_PANEL.SAVED_FILTERS.PUBLIC')} onChange={publicCheckboxClicked} checked={scope === SAVED_FILTERS_CATEGORIES.PUBLIC} />
                    <Checkbox label={t('FILTER_PANEL.SAVED_FILTERS.PRIVATE')} onChange={privateCheckboxClicked} checked={scope !== SAVED_FILTERS_CATEGORIES.PUBLIC} />
                </Stack>
                <Stack alignItems='flex-end'>
                    <Button
                        tooltip={t('FILTER_PANEL.SAVED_FILTERS.FAV_ADD_RESTRICTIONS')}
                        disabled={!filterName || existingfilterNames.includes(filterName)}
                        onClick={() => onSaveFilter(filterName, scope)}
                        iconProps={{ name: 'save' }}
                        variant='outlined'
                        color='brandPrimary'
                        label={t('FILTER_PANEL.SAVED_FILTERS.SAVE_BUTTON_TEXT')} />
                </Stack>
            </div>
        </div>
    );
};

export default SaveFilterForm;
