import React from 'react';
import { singletonHook } from 'react-singleton-hook';
import { IEnvironment } from 'common/interface/environmentsTable';
import { EnvironmentsTableRegistry } from 'common/module_interface/assets/EnvironmentsTableRegistry';
import useAllCloudAccounts from './useAllCloudAccounts';

const getAndSetDataFromTableRegistryController = new AbortController();

interface IUseEnvironmentsTable {
    environmetsData: IEnvironment[];
    isLoading: boolean;
    fetchingData: boolean;
    setSelectedTab: (tab: string) => void;
}

type UseEnvironmentsTable = () => IUseEnvironmentsTable;
const useEnvironmentsTableImple: UseEnvironmentsTable = (): IUseEnvironmentsTable => {
    const [environmetsData, setEnvironmetsData] = React.useState<IEnvironment[]>([]);
    const [customRow, setCustomRow] = React.useState<IEnvironment[]>([]);
    const [selectedTab, setSelectedTab] = React.useState<string>();
    const [fetchingData, setFetchingData] = React.useState<boolean>(true);
    const [fetchingRows, setFetchingRows] = React.useState<boolean>(true);
    const [isLoading, setIsLoading] = React.useState<boolean>(true);
    const { allCloudAccounts, isLoading: isAllCloutAccountsLoading } = useAllCloudAccounts();

    const getAndSetDataFromTableRegistry = React.useCallback(() => {
        if (!selectedTab) return;
        setFetchingData(true);
        const getCustomDataById = EnvironmentsTableRegistry.getCustomDataById();
        const getCustomDataByPlatform = EnvironmentsTableRegistry.getCustomDataByPlatform();

        const allPromisesResolved = {
            byId: getCustomDataById.map(() => false),
            byPlatform: getCustomDataByPlatform.map(() => false)
        };

        const checkIfAllPromisesResolved = () => {
            if (allPromisesResolved.byId.every((promise) => promise) && allPromisesResolved.byPlatform.every((promise) => promise)) {
                setFetchingData(false);
            }
        };

        getCustomDataById.forEach((customDataById, index) => {
            const isRelevant = customDataById.isRelevant ? customDataById.isRelevant(selectedTab) : true;
            if (!isRelevant) {
                allPromisesResolved.byId[index] = true;
                checkIfAllPromisesResolved();
                return;
            }
            try {
                new Promise((resolve, reject) => {
                    getAndSetDataFromTableRegistryController.signal.addEventListener('abort', () => reject('abort'));
                    customDataById.customData(allCloudAccounts).then(customData => {
                        setEnvironmetsData(current => {
                            const newEnvironments = [...current];
                            customData.forEach((customDataItem) => {
                                const environment = newEnvironments.find((environment) => environment.id === customDataItem.id);
                                if (environment) {
                                    Object.keys(customDataItem.customData).forEach((key) => {
                                        environment[`customData|${key}`] = customDataItem.customData[key];
                                    });
                                }
                            });
                            allPromisesResolved.byId[index] = true;
                            checkIfAllPromisesResolved();
                            return newEnvironments;
                        });
                        resolve('done');
                    });
                });
            } catch (error) { /* empty */ }
        });

        getCustomDataByPlatform.forEach((customDataByPlatform, index) => {
            const isRelevant = customDataByPlatform.isRelevant ? customDataByPlatform.isRelevant(selectedTab) : true;
            if (!isRelevant) {
                allPromisesResolved.byPlatform[index] = true;
                return;
            }
            try {
                new Promise((resolve, reject) => {
                    getAndSetDataFromTableRegistryController.signal.addEventListener('abort', () => reject('abort'));
                    customDataByPlatform.customData(allCloudAccounts).then(({ platforms, customData }) => {
                        setEnvironmetsData(current => {
                            const newEnvironments = [...current];
                            newEnvironments.forEach((environment) => {
                                if (platforms.some(platform => platform === environment.platform)) {
                                    Object.keys(customData).forEach((key) => {
                                        environment[`customData|${key}`] = customData[key];
                                    });
                                }
                            });
                            allPromisesResolved.byPlatform[index] = true;
                            checkIfAllPromisesResolved();
                            return newEnvironments;
                        });
                        resolve('done');
                    });
                });
            } catch (error) { /* empty */ }
        });
    }, [allCloudAccounts, selectedTab]);

    React.useEffect(() => {
        if (isAllCloutAccountsLoading) {
            setIsLoading(true);
            return;
        }
        if (!allCloudAccounts || allCloudAccounts.length === 0) {
            setIsLoading(false);
            return;
        }

        setEnvironmetsData(allCloudAccounts);
        getAndSetDataFromTableRegistry();
        setIsLoading(false);

        return () => {
            getAndSetDataFromTableRegistryController.abort();
            setEnvironmetsData([]);
        };
    }, [allCloudAccounts, isAllCloutAccountsLoading, getAndSetDataFromTableRegistry]);

    React.useEffect(() => {
        if (!selectedTab) return;
        const controller = new AbortController();
        
        const resolveGetCustomRows = async () => {
            setFetchingRows(true);
            const customData = EnvironmentsTableRegistry.getCustomRows();
            for (let index = 0; index < customData.length; index++) {
                const customRows = customData[index];
                const isRowRelevant = customRows.isRelevant ? customRows.isRelevant(selectedTab) : true;
                if (!isRowRelevant) continue;
                try {
                    const dataRows = await new Promise<IEnvironment[]>((resolve, reject) => {
                        customRows.getRows().then(resolve).catch(reject);
                        controller.signal.addEventListener('abort', () => reject('abort'));
                    });
                    setCustomRow(current => [...current, ...dataRows]);
                } catch (error) { /* empty */ }
            }
            setFetchingRows(false);
        };
        
        resolveGetCustomRows();

        return () => {
            controller.abort();
            setCustomRow([]);
            setFetchingRows(false);
        };
    }, [selectedTab]);

    return {
        environmetsData: [...environmetsData, ...customRow],
        isLoading,
        fetchingData: fetchingData || fetchingRows,
        setSelectedTab
    };
};


const useEnvironmentsTable: UseEnvironmentsTable = singletonHook({ environmetsData: [], isLoading: true, fetchingData: true, setSelectedTab: () => null }, useEnvironmentsTableImple);
export default useEnvironmentsTable;