import * as React from 'react';
import { SVGProps, Ref, forwardRef } from 'react';
const SvgSoc = (props: SVGProps<SVGSVGElement>, ref: Ref<SVGSVGElement>) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    viewBox="0 0 64 64"
    xmlSpace="preserve"
    ref={ref}
    {...props}
  >
    <path
      fill="#0579C0"
      d="M64 32c0 3.15-.46 6.19-1.3 9.07l-6.69-2.09c.64-2.22.99-4.56.99-6.98C57 18.21 45.79 7 32 7S7 18.21 7 32c0 2.42.35 4.76.99 6.98L1.3 41.07C.46 38.19 0 35.15 0 32 0 14.33 14.33 0 32 0s32 14.33 32 32z"
    />
    <path d="M62.4 42.02C58.65 53.4 48.69 61.95 36.54 63.68c-1.48.21-3 .32-4.54.32-1.4 0-2.78-.09-4.14-.27C15.53 62.14 5.39 53.52 1.6 42.02l6.69-2.09C11.61 49.84 20.98 57 32 57s20.39-7.16 23.71-17.07l6.69 2.09zM21.898 27.683l-1.066-2.725h-3.434l-1.055 2.725h-1.008l3.387-8.602h.838l3.369 8.602h-1.031zm-1.377-3.621-.996-2.654a13.983 13.983 0 0 1-.398-1.236c-.086.375-.209.787-.369 1.236l-1.008 2.654h2.771zM24.107 27.683v-8.566h.996v8.566h-.996zM31.121 19.884c-.941 0-1.685.313-2.229.94s-.817 1.485-.817 2.575c0 1.121.263 1.987.788 2.599s1.274.917 2.247.917c.598 0 1.279-.107 2.045-.322v.873c-.594.223-1.326.334-2.197.334-1.262 0-2.235-.383-2.921-1.148s-1.028-1.854-1.028-3.264c0-.883.165-1.656.495-2.32s.807-1.176 1.43-1.535 1.356-.539 2.2-.539c.898 0 1.684.164 2.355.492l-.422.855a4.538 4.538 0 0 0-1.946-.457zM40.455 21.612c0 .867-.296 1.534-.888 2.001s-1.438.7-2.54.7H36.02v3.369h-.996v-8.566h2.221c2.14 0 3.21.832 3.21 2.496zm-4.435 1.846h.896c.883 0 1.521-.143 1.916-.428s.592-.742.592-1.371c0-.566-.186-.988-.557-1.266s-.949-.416-1.734-.416H36.02v3.481zM47.633 27.683l-1.066-2.725h-3.434l-1.055 2.725H41.07l3.387-8.602h.838l3.369 8.602h-1.031zm-1.377-3.621-.996-2.654a13.983 13.983 0 0 1-.398-1.236c-.086.375-.209.787-.369 1.236l-1.008 2.654h2.771z" />
    <path d="M26.246 40.923c0 .762-.275 1.359-.826 1.793s-1.311.65-2.279.65-1.762-.15-2.379-.451v-1.324c.391.184.806.328 1.245.434s.849.158 1.228.158c.555 0 .964-.105 1.228-.316s.396-.494.396-.85c0-.32-.121-.592-.363-.814s-.742-.486-1.5-.791c-.781-.316-1.332-.678-1.652-1.084s-.48-.895-.48-1.465c0-.715.254-1.277.762-1.688s1.189-.615 2.045-.615c.82 0 1.637.18 2.449.539l-.445 1.143c-.762-.32-1.441-.48-2.039-.48-.453 0-.797.099-1.031.296s-.352.458-.352.782c0 .223.047.413.141.571s.248.308.463.448.602.326 1.16.557c.629.262 1.09.506 1.383.732s.508.482.645.768.201.62.201 1.007zM35.51 38.954c0 1.395-.349 2.479-1.046 3.252s-1.685 1.16-2.962 1.16c-1.293 0-2.286-.384-2.979-1.151s-1.04-1.858-1.04-3.272.349-2.5 1.046-3.258 1.692-1.137 2.985-1.137c1.273 0 2.258.385 2.953 1.154s1.043 1.854 1.043 3.252zm-6.539 0c0 1.055.213 1.854.639 2.399s1.057.817 1.893.817c.832 0 1.46-.27 1.884-.809s.636-1.342.636-2.408c0-1.051-.21-1.848-.63-2.391s-1.046-.814-1.878-.814c-.84 0-1.474.271-1.901.814s-.643 1.341-.643 2.392zM40.994 35.761c-.805 0-1.438.285-1.898.855s-.691 1.357-.691 2.361c0 1.051.222 1.846.665 2.385s1.085.809 1.925.809c.363 0 .715-.036 1.055-.108s.693-.165 1.061-.278v1.201c-.672.254-1.434.381-2.285.381-1.254 0-2.217-.38-2.889-1.14s-1.008-1.847-1.008-3.261c0-.891.163-1.67.489-2.338s.798-1.18 1.415-1.535 1.342-.533 2.174-.533c.875 0 1.684.184 2.426.551l-.504 1.166c-.289-.137-.595-.257-.917-.36s-.662-.156-1.018-.156z" />
  </svg>
);
const ForwardRef = forwardRef(SvgSoc);
export default ForwardRef;
