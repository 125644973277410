import * as React from 'react';
import { SVGProps, Ref, forwardRef } from 'react';
const SvgConnectedStatus = (
  props: SVGProps<SVGSVGElement>,
  ref: Ref<SVGSVGElement>
) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    viewBox="0 0 16 16"
    xmlSpace="preserve"
    style={{
      fill: '#2e3f58',
    }}
    ref={ref}
    {...props}
  >
    <path d="M4.3 10.3 3 9c-1.4-1.4-1.6-3.6-.6-5.2L0 1.4 1.4 0l2.4 2.4c1.6-1 3.8-.8 5.2.6l1.3 1.3-6 6zM11.7 5.7 13 7c1.4 1.4 1.6 3.6.6 5.2l2.4 2.4-1.4 1.4-2.4-2.4c-1.6 1-3.8.8-5.2-.6l-1.3-1.3 6-6z" />
  </svg>
);
const ForwardRef = forwardRef(SvgConnectedStatus);
export default ForwardRef;
