import { OptionStateProps, SelectStateProps } from './Select.types';
import { DefaultTheme } from 'styled-components';
import { CSSObjectWithLabel } from 'react-select';

export const getControlStyling = ({ isDisabled, isError, isReadonly }: SelectStateProps, theme: DefaultTheme) :CSSObjectWithLabel => {
    const commonStyling = {
        ...theme.typography.body,
        minHeight: '32px',
        backgroundColor: theme.palette.surface.primary,
        borderWidth: `${theme.border.width.standard}${theme.units}`,
        borderRadius: `${theme.border.radius(2)}${theme.units}`,
        padding: `${theme.spacing(1) - 1}${theme.units} ${theme.spacing(4)}${theme.units} ${theme.spacing(1) - 1}${theme.units} ${theme.spacing(2)}${theme.units}`,
        boxShadow: 'none',
    };

    let borderColor = theme.palette.border.normal;
    if (isError) {
        borderColor = theme.palette.border.alert;
    } else if (isDisabled || isReadonly) {
        borderColor = theme.palette.border.light;
    }

    let backgroundColor = theme.palette.surface.primary;
    if (isReadonly && isError) {
        backgroundColor = theme.palette.surface.alertLight;
    } else if (isReadonly) {
        backgroundColor = theme.palette.surface.secondary;
    }

    return {
        ...commonStyling,
        borderColor,
        opacity: isDisabled ? theme.disabledOpacity : 1,
        backgroundColor,
        ':hover': {
            borderColor: isError ? theme.palette.border.alert : theme.palette.border.light,
            // boxShadow: isError ? theme.shadows['focusAlert'] : theme.shadows['focus'],
        },
        ':active': {
            borderColor: isError ? theme.palette.border.alert : theme.palette.border.information,
        },
        ':target': {
        },
        ':focus': {
            borderColor: isError ? theme.palette.border.alert : theme.palette.border.information,
        },
    };
};

export const getPlaceholderStyling = (_: SelectStateProps, theme: DefaultTheme):CSSObjectWithLabel => {
    return {
        color: theme.palette.text.helper,
    };
};

export const getInputStyling = (_: SelectStateProps, theme: DefaultTheme):CSSObjectWithLabel => {
    return {
        color: theme.palette.text.strong,
        height: '16px'
    };
};

export const getSingleValueStyling = ({ isDisabled, isError }: SelectStateProps, theme: DefaultTheme):CSSObjectWithLabel => {
    return {
        color: isError ? theme.palette.text.alert : theme.palette.text.strong,
        opacity: isDisabled ? theme.disabledOpacity : 1,
    };
};

export const getIndicatorSeparatorStyling = (_: SelectStateProps, theme: DefaultTheme):CSSObjectWithLabel => {
    return {
        backgroundColor: theme.palette.surface.primary
    };
};

export const getIndicatorContainerStyling = (_: SelectStateProps, theme: DefaultTheme):CSSObjectWithLabel => {
    return {
        height: '24px',
        color: theme.palette.onSurface.light,
        gap: `${theme.spacing(1)}${theme.units}`,
    };
};

export const getMenuStyling = (_: SelectStateProps, theme: DefaultTheme):CSSObjectWithLabel => {
    return {
        ...theme.typography.body,
        marginTop: `${theme.spacing(2)}${theme.units}`,
        padding: `${theme.spacing(2)}${theme.units}`,
        width: '100%',
        backgroundColor: theme.palette.surface.primary,
        boxShadow: theme.shadows[2],
        borderRadius: `${theme.border.radius(2)}${theme.units}`,
    };
};

export const getMenuListStyling = (_: SelectStateProps, theme: DefaultTheme):CSSObjectWithLabel => {
    return {
        display: 'flex',
        flexDirection: 'column',
        gap: `${theme.spacing(0.5)}${theme.units}`,
    } as const;
};

export const getOptionStyling = ({ isDisabled, isSelected, isMulti }: SelectStateProps & OptionStateProps, theme: DefaultTheme):CSSObjectWithLabel => {
    const commonProps = {
        cursor: 'pointer',
        borderRadius: `${theme.border.radius(2)}${theme.units}`,
        backgroundColor: theme.palette.surface.primary,
        display: 'flex',
        alignItems: 'center',
        gap: `${theme.spacing(2)}${theme.units}`,
        ...theme.typography.body,
    };
    return isMulti ? {
        ...commonProps,
        ':hover': {
            backgroundColor: isDisabled ? theme.palette.surface.primary : theme.palette.componentStates.ghostHover,
        },
        padding: `0 0 0 ${theme.spacing(2)}${theme.units}`,
        lineHeight: 0,
    } : {
        ...commonProps,
        padding: `${theme.spacing(2)}${theme.units} ${theme.spacing(2)}${theme.units} ${theme.spacing(2)}${theme.units} ${theme.spacing(3)}${theme.units}`,
        color: isDisabled ? theme.palette.text.helper : theme.palette.text.normal,
        backgroundColor: isSelected ? theme.palette.componentStates.ghostActive : theme.palette.surface.primary,
        ':hover': {
            backgroundColor: isDisabled ? theme.palette.surface.primary : theme.palette.componentStates.ghostHover,
        },
    };
};