import * as React from 'react';
import { SVGProps, Ref, forwardRef } from 'react';
const SvgIamImpact = (
  props: SVGProps<SVGSVGElement>,
  ref: Ref<SVGSVGElement>
) => (
  <svg
    viewBox="0 0 16 16"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    ref={ref}
    {...props}
  >
    <path
      d="M11.5 11.5 7.914 7.914M11 11l1.5-1.5m-3-.003 2-1.997"
      stroke="currentColor"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <circle cx={6.5} cy={6.5} r={2} stroke="currentColor" />
    <path
      d="M15.315 9.665A7.43 7.43 0 0 0 15.5 8a7.555 7.555 0 0 0-.185-1.665m-.966 5.66a7.541 7.541 0 0 1-2.354 2.354m-2.33.966A7.43 7.43 0 0 1 8 15.5a7.555 7.555 0 0 1-1.665-.185m-2.33-.966a7.542 7.542 0 0 1-2.354-2.354m-.966-2.33A7.466 7.466 0 0 1 .5 8a7.556 7.556 0 0 1 .185-1.665m.966-2.33a7.513 7.513 0 0 1 2.354-2.354m2.33-.966A7.466 7.466 0 0 1 8 .5a7.556 7.556 0 0 1 1.665.185m2.33.966a7.501 7.501 0 0 1 2.073 1.94c.098.135.192.273.28.414"
      stroke="currentColor"
      strokeLinecap="round"
    />
  </svg>
);
const ForwardRef = forwardRef(SvgIamImpact);
export default ForwardRef;
