import styled from 'styled-components';

export const ALink = styled.a`
    color: ${({ theme }) => theme.palette.status.info};
    :visited, :hover {
        color: ${({ theme }) => theme.palette.status.info};
    }

    :hover {
        cursor: pointer;
        text-decoration: underline;
    }  
`;