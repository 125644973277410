import React from 'react';
import { getSafeFindingSeverityInfo, IFindingSeverityInfo } from '../../consts/FindingSeverity';
import { Chip } from 'common/design-system/components-v2';

export const FindingSeverityCellComponent: React.FC<{ severityNameOrLevel: string | number, isLink?: boolean }> = ({ severityNameOrLevel, isLink = false }) => {
    const severityInfo: IFindingSeverityInfo = getSafeFindingSeverityInfo(severityNameOrLevel);

    return (
        <Chip
            label={severityInfo.displayText}
            leadingIconProps={{ ...severityInfo.iconProps, size: 16 }}
            context={severityInfo.context}
            pointerCursor={isLink}
            disableInteraction
        />
    );
};
