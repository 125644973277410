import React, { useCallback, useEffect, useMemo, useRef } from 'react';
import { ColDef, GridApi, IServerSideGetRowsParams } from 'ag-grid-enterprise';
import { GridOptions, GridReadyEvent } from 'ag-grid-community';
import { EventsTabledDiv } from './EventsTableWidget.styled';
import { IColumnUsageDef } from 'common/interface/general';
import { getEventsService } from 'common/module_interface/events/Events';
import { Table } from 'common/design-system/components-v2';
import { IEventsTableWidgetData } from './EventsTableWidget.interface';

const EventsTableWidget: React.FC<IEventsTableWidgetData> =
    ({ items, isLoading = false, colOverride, loadingCellRenderer }) => {
        const gridApiRef = useRef<GridApi>();
        const pendingTableDataRef = useRef<IEventsTableWidgetData>();
        const pendingSuccessCallbackRef = useRef<Function>();

        const handleUpdates = () => {
            const gridApi = gridApiRef.current;
            const pendingData = pendingTableDataRef.current;
            const successCallback = pendingSuccessCallbackRef.current;

            if (!gridApi) return;

            if (!pendingData) return;

            if (!successCallback) {
                gridApi.refreshServerSide();
                return;
            }

            const { items: newItems, isLoading: keepSearching } = pendingData;
            pendingSuccessCallbackRef.current = undefined;
            pendingTableDataRef.current = undefined;

            successCallback({
                rowData: newItems,
                rowCount: keepSearching ? newItems.length + 1 : newItems.length,
            });

            if (!newItems.length && !keepSearching) {
                gridApi.showNoRowsOverlay();
            }
        };

        const onGridReady = useCallback((params: GridReadyEvent) => {
            gridApiRef.current = params.api;
            params.api.setServerSideDatasource({
                getRows(params: IServerSideGetRowsParams) {
                    pendingSuccessCallbackRef.current = params.success;
                    handleUpdates();
                }
            });
        }, []);
        
        const columnDefs: ColDef[] = useMemo(() => {
            const columns: IColumnUsageDef[] = colOverride.map((col) => {
                return {
                    id: col.colOverride.id,
                    colDefOverride: {
                        ...col.colOverride.colDefOverride,
                        field: col.field
                    },
                };
            });
            return getEventsService().getColumnDefs(columns);
        }, [colOverride]);

        const gridOptions: GridOptions = useMemo(() => {
            return {
                columnDefs,
                onGridReady,
                defaultColDef: { resizable: true, suppressMenu: true, sortable: false, minWidth: 60, enableRowGroup: false },
                loadingCellRendererParams: { itemCount: items.length },
                loadingCellRenderer,
                suppressContextMenu: true,
                className: 'events-table-widget',
                rowModelType: 'serverSide',
                enableRangeSelection: false,
                suppressCellFocus: true,
                suppressRowDrag: true,
                suppressDragLeaveHidesColumns: true,
            };
        }, [columnDefs, items.length, onGridReady, loadingCellRenderer]);

        useEffect(() => {
            pendingTableDataRef.current = {
                items,
                isLoading: isLoading,
                colOverride
            };
            handleUpdates();
        }, [items, isLoading, colOverride]);

        return (
            <EventsTabledDiv>
                <Table
                    gridOptions={gridOptions}
                    disableColumnMenu={true}
                    disableGrouping={true}
                />
            </EventsTabledDiv>
        );
    };

export default EventsTableWidget;
