import * as React from 'react';
import { SVGProps, Ref, forwardRef } from 'react';
const SvgRuleset = (
  props: SVGProps<SVGSVGElement>,
  ref: Ref<SVGSVGElement>
) => (
  <svg
    viewBox="0 0 16 16"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    ref={ref}
    {...props}
  >
    <path
      d="M.5 12.5v-10a2 2 0 0 1 2-2h10m1 4h-1m0 0H7A1.5 1.5 0 0 0 5.5 6v7.5m7-9V12a1.5 1.5 0 0 1-1.5 1.5H5.5m-1 0h1m-2 2h11a1 1 0 0 0 1-1v-11a1 1 0 0 0-1-1h-11a1 1 0 0 0-1 1v11a1 1 0 0 0 1 1Z"
      stroke="currentColor"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </svg>
);
const ForwardRef = forwardRef(SvgRuleset);
export default ForwardRef;
