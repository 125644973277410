import React, { forwardRef } from 'react';
import ButtonStyles from './Button.styles';
import { IButtonProps } from './Button.types';
import Stack from '../Stack';
import Spinner from '../Spinner/Spinner';
import Tooltip from '../Tooltip/Tooltip';
import Icon from '../Icon/Icon';
import { ContextMenu } from '../../../components/ContextMenu/ContextMenu';

const Button = forwardRef<HTMLButtonElement, IButtonProps>((props, ref) => {
    const {
        variant = 'contained',
        size = 'medium',
        color = 'normal',
        buttonAlignment ='center',
        loading,
        iconProps,
        children,
        label,
        tooltip,
        tooltipPlacement,
        iconButton = false,
        disabled,
        contextMenuElement,
        dataAid,
        ...rest
    } = props;

    const defaultIconSize = size === 'large' ? 24 : 16;

    // this is a workaround for the tooltip not working on disabled buttons
    // implement other handlers if needed
    const handleOnClick = (e: React.MouseEvent<HTMLButtonElement, MouseEvent>) => {
        if (disabled) {
            e.preventDefault();
            e.stopPropagation();
            return;
        }
        if (props.onClick) {
            props.onClick(e);
        }
    };

    const ButtonComponent = (
        <ButtonStyles.Wrapper
            variant={variant}
            color={color}
            size={size}
            iconButton={iconButton}
            ref={ref}
            isDisabled={disabled}
            tabIndex={disabled ? -1 : undefined}
            data-aid={dataAid}
            {...rest}
            onClick={handleOnClick}
        >
            <Stack alignItems='center' justifyContent={buttonAlignment} direction='row' spacing={2}>
                {loading && <Stack justifyContent='center' alignItems='center'><Spinner size={iconProps?.size || defaultIconSize} /></Stack>}
                {(iconProps && !loading) && <Icon {...iconProps} />}
                {(label && !iconButton) && (
                    <ButtonStyles.Label ellipsis color='inherit' variant={size === 'large' ? 'bodyLg' : 'body'}>{label}</ButtonStyles.Label>
                )}
                {(children && !iconButton) && (
                    typeof children === 'string' ? <ButtonStyles.Label ellipsis color='inherit' variant={size === 'large' ? 'bodyLg' : 'body'}>{children}</ButtonStyles.Label> : children
                )}
            </Stack>
        </ButtonStyles.Wrapper>
    );

    if (tooltip) {
        return (
            <Tooltip content={tooltip} placement={tooltipPlacement}>
                {contextMenuElement ? (
                    <ContextMenu render={() => contextMenuElement}>
                        {ButtonComponent}
                    </ContextMenu>
                ) : ButtonComponent}
            </Tooltip>
        );
    }

    if (contextMenuElement) {
        return (
            <ContextMenu render={() => contextMenuElement}>
                {ButtonComponent}
            </ContextMenu>
        );
    }

    return ButtonComponent;
});
Button.displayName = 'Button';

export default Button;
