import * as React from 'react';
import { SVGProps, Ref, forwardRef } from 'react';
const SvgShieldChecked = (
  props: SVGProps<SVGSVGElement>,
  ref: Ref<SVGSVGElement>
) => (
  <svg
    viewBox="0 0 16 16"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    className="shield-checked_svg__design-system"
    ref={ref}
    {...props}
  >
    <path
      d="m6 8 1.5 1.5 3-3M8 .5c-1.5 1-3.5 2-5.5 2V9c0 4 5.5 6.5 5.5 6.5S13.5 13 13.5 9V2.5c-2 0-4-1-5.5-2Z"
      stroke="currentColor"
      strokeOpacity={0.99}
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </svg>
);
const ForwardRef = forwardRef(SvgShieldChecked);
export default ForwardRef;
