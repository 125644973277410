import * as React from 'react';
import { SVGProps, Ref, forwardRef } from 'react';
const SvgLink = (props: SVGProps<SVGSVGElement>, ref: Ref<SVGSVGElement>) => (
  <svg
    viewBox="0 0 16 16"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    ref={ref}
    {...props}
  >
    <path
      d="M7.5 10.146a2.828 2.828 0 0 1-1-4.646L10 2a2.828 2.828 0 1 1 4 4l-2.5 2.5"
      stroke="currentColor"
      strokeLinecap="round"
    />
    <path
      d="m4 8-2.5 2.5a2.828 2.828 0 1 0 4 4L9 11a2.828 2.828 0 0 0-1-4.646"
      stroke="currentColor"
      strokeLinecap="round"
    />
  </svg>
);
const ForwardRef = forwardRef(SvgLink);
export default ForwardRef;
