import { IClientFilterInfo, IItemDataGetter } from '../ClientFilter.interface';
import { createFieldGetter, createMultiGetter } from '../ClientFilter';
import { computeRangeFromSelection } from '../../../../components/FilterPanel/DefaultFilters/DateFilter/DateFilter.consts';
import {
    FilterConditionOperator,
    FilterConditionTestersMap,
    IFilterCondition,
    IFilterConditionsContainer,
    isEmptyCondition,
} from 'common/erm-components/custom/FilterTree/FilterCondition';
import { DebugActiveFeaturesMap, isAfEnabled } from '../../../../utils/debugUtils';
import { isString } from '../../../../utils/helpFunctions';
import { IEntityItem } from '../../../../utils/filterUtils';

const addOrRemoveCondition = (requestObj: IFilterConditionsContainer, condition: IFilterCondition) => {
    if (isEmptyCondition(condition)) {
        if (requestObj.conditions) {
            requestObj.conditions = requestObj.conditions.filter(cond => cond.name !== condition.name);
        }
        return;
    }

    requestObj.conditions = requestObj.conditions || [];
    const index = requestObj.conditions.findIndex(cond => cond.name === condition.name);
    if (index >= 0) {
        requestObj.conditions[index] = condition;
    } else {
        requestObj.conditions.push(condition);
    }
};

export const addSimpleClientMultiValueCondition = (requestObj: IFilterConditionsContainer, value: any[], conditionKey: string) => {
    addOrRemoveCondition(requestObj, {
        name: conditionKey,
        values: [...value],
        operator: FilterConditionOperator.In,
    });
};

export const addSimpleClientFreeTextCondition = (requestObj: IFilterConditionsContainer, conditionKey: string, value?: string) => {
    addOrRemoveCondition(requestObj, {
        name: conditionKey,
        values: [value?.trim() ?? ''],
        operator: FilterConditionOperator.Contains,
    });
};

export const addSimpleClientDatePickerCondition = (requestObj: IFilterConditionsContainer, value: any, conditionKey: string) => {
    const dateInfo = computeRangeFromSelection(value);
    const values: string[] = [];
    if (value?.epoch) {
        values.push(dateInfo.from);
        values.push(dateInfo.to);
    } else {
        const fromDate = new Date(dateInfo.from);
        const toDate = new Date(dateInfo.to);
        const gap = toDate.getTime() - fromDate.getTime();
        values.push(String(gap));
    }
    addOrRemoveCondition(requestObj, {
        name: conditionKey,
        values,
        operator: FilterConditionOperator.Between,
    });
};

export const simpleClientTestFilter = <T extends IEntityItem = IEntityItem>(
    item: T, requestObj: IFilterConditionsContainer, conditionKey: string, dataGetter: IItemDataGetter<T>): boolean => {
    const condition: IFilterCondition | undefined = requestObj.conditions ? requestObj.conditions.find(cond => cond.name === conditionKey) : undefined;
    if (!condition) {
        return true;
    }
    if (isEmptyCondition(condition)) {
        return true;
    }

    const propData: any = dataGetter(item);
    if (!propData) {
        return false;
    }

    return FilterConditionTestersMap[condition.operator].testFilter(propData, condition.values);
};

const createClientFilterInfo = <T extends IEntityItem = IEntityItem>(conditionKey: string, dataGetter: IItemDataGetter<T>, fieldName?: string, debugText?: string):
    IClientFilterInfo<T, IFilterConditionsContainer> => {
    return {
        dataGetter,
        testClientFilter: (item: T, requestObj: IFilterConditionsContainer, dataGetter: IItemDataGetter<T>): boolean => {
            if (debugText && isAfEnabled(DebugActiveFeaturesMap.DEBUG_FILTERS_ACTIVE_FEATURE.key)) {
                console.log(debugText);
            }
            return simpleClientTestFilter(item, requestObj, conditionKey, dataGetter);
        },
        conditionKey,
        fieldName,
    };
};

export const createSimpleFieldClientFilterInfo = <T extends IEntityItem = IEntityItem>(fieldName: string, conditionKey?: string, dataGetter?: IItemDataGetter<T>):
    IClientFilterInfo<T, IFilterConditionsContainer> => {
    const finalGetter: IItemDataGetter<T> = dataGetter || createFieldGetter(fieldName);
    const finalKey = conditionKey || fieldName;
    return createClientFilterInfo(finalKey, finalGetter, fieldName);
};

export const createSimpleFreeTextClientFilterInfo = <T extends IEntityItem = IEntityItem>(conditionKey: string, fieldsOrGetters: (string | IItemDataGetter<T>)[]):
    IClientFilterInfo<T, IFilterConditionsContainer> => {
    const finalGetter: IItemDataGetter<T> = createMultiGetter<T>(fieldsOrGetters);
    const fieldsOrGettersKeys: string[] = fieldsOrGetters.map(fieldOrGetter => isString(fieldOrGetter) ? `"${String(fieldOrGetter)}"` : 'Computed');
    const debugText = `### Free-Text Filter By: ${fieldsOrGettersKeys.join(', ')}`;
    return createClientFilterInfo(conditionKey, finalGetter, undefined, debugText);
};

export const simpleClientHasConditions = (conditionsContainer?: IFilterConditionsContainer): boolean => {
    if (!conditionsContainer?.conditions) {
        return false;
    }
    return (conditionsContainer.conditions.length > 0);
};

export const simpleClientTestConditions = <T extends IEntityItem = IEntityItem>(clientInfos: IClientFilterInfo<T, IFilterConditionsContainer>[], conditionsContainer?: IFilterConditionsContainer, item?: T): boolean => {
    if (!conditionsContainer?.conditions) {
        return true;
    }

    const conditions: IFilterCondition[] | undefined = conditionsContainer.conditions;
    if (conditions.length === 0) {
        return true;
    }

    if (!item) {
        return false;
    }

    const conditionKeys = conditions.map(cond => cond.name);
    const relevantClientInfos: IClientFilterInfo<T, IFilterConditionsContainer>[] = clientInfos.filter(clientInfo => conditionKeys.includes(clientInfo.conditionKey));
    for (let i = 0; i < relevantClientInfos.length; i++) {
        const clientInfo: IClientFilterInfo<T, IFilterConditionsContainer> = relevantClientInfos[i];
        if (!clientInfo.testClientFilter) {
            return false;
        }
        if (!clientInfo.testClientFilter(item, { conditions }, clientInfo.dataGetter)) {
            return false;
        }
    }
    return true;
};
