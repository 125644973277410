import {
    FilterTreeItemType,
    IFilterTree,
    IFilterTreeCondition,
    IFilterTreeFieldDefinition,
    IFilterTreeItem,
    IFilterTreeNode,
} from './FilterTree.interface';
import { ICompoundFilter, ICompoundFilterItem, ICompoundFilterNode } from './CompoundFilter';
import { createTreeCondition, createTreeNode } from './FilterTree.creators';
import { updateDeepErrorsInFilterTree } from './FilterTree.errors';
import { createDefaultCompoundFilter } from './FilterTree.utils';
import { FilterConditionOperator, IFilterCondition } from './FilterCondition';

const treeToCompoundCondition = (treeCondition: IFilterTreeCondition): IFilterCondition => {
    return {
        name: treeCondition.name,
        values: treeCondition.values,
        operator: treeCondition.operator ?? FilterConditionOperator.Unknown,
    };
};

const treeToCompoundItem = (treeItem: IFilterTreeItem): ICompoundFilterItem => {
    if (treeItem.itemType === FilterTreeItemType.node) {
        return treeToCompoundNode(treeItem as IFilterTreeNode);
    }
    return treeToCompoundCondition(treeItem as IFilterTreeCondition);
};

const treeToCompoundNode = (treeNode: IFilterTreeNode): ICompoundFilterNode => {
    return {
        logicalOperator: treeNode.logicalOperator,
        operands: treeNode.childItems.map((treeItem: IFilterTreeItem) => treeToCompoundItem(treeItem)),
    };
};

export const treeToCompoundFilter = (filterTree: IFilterTree): ICompoundFilter | undefined => {
    if (filterTree.root.childItems.length === 0) {
        return undefined;
    }
    const compoundRoot: ICompoundFilterNode = treeToCompoundNode(filterTree.root);
    return {
        root: compoundRoot,
    };
};

const compoundFilterConditionToTreeCondition = (compoundCondition: IFilterCondition, parentTreeNode: IFilterTreeNode): IFilterTreeCondition => {
    const newCondition: IFilterTreeCondition = createTreeCondition(parentTreeNode, parentTreeNode.childItems.length);
    newCondition.name = compoundCondition.name;
    newCondition.values = compoundCondition.values;
    newCondition.operator = compoundCondition.operator;
    return newCondition;
};

export const isCompoundFilterNode = (item: ICompoundFilterItem): boolean => {
    return !!(item as ICompoundFilterNode).logicalOperator;
};

export const isCompoundFilterCondition = (item: ICompoundFilterItem): boolean => {
    return !isCompoundFilterNode(item);
};

const compoundFilterItemToTreeItem = (item: ICompoundFilterItem, parentTreeNode: IFilterTreeNode): IFilterTreeItem => {
    if (isCompoundFilterNode(item)) {
        return compoundFilterNodeToTreeNode(item as ICompoundFilterNode, parentTreeNode);
    }
    return compoundFilterConditionToTreeCondition(item as IFilterCondition, parentTreeNode);
};

const compoundFilterNodeToTreeNode = (compoundNode: ICompoundFilterNode, parentTreeNode?: IFilterTreeNode): IFilterTreeNode => {
    const newNode: IFilterTreeNode = createTreeNode(compoundNode.logicalOperator, parentTreeNode, parentTreeNode ? parentTreeNode.childItems.length : 0);
    compoundNode.operands.forEach(item => {
        compoundFilterItemToTreeItem(item, newNode);
    });
    return newNode;
};

export const compoundFilterToTree = (filterDefs: IFilterTreeFieldDefinition[], compoundFilter?: ICompoundFilter): IFilterTree => {
    const rootNode: IFilterTreeNode = compoundFilterNodeToTreeNode(compoundFilter?.root || createDefaultCompoundFilter().root);
    updateDeepErrorsInFilterTree(rootNode, filterDefs);
    return { root: rootNode };
};

