import React, { useMemo } from 'react';
import { IMultiSelectorEntity, IMultiSelectorItem, IMultiSelectorSettings } from './MultiSelector.interface';
import { getAllListItems } from './MultiSelector.utils';
import MultiSelectorDropdown from './MultiSelectorDropdown';

const MultiSelectorListDropdown: React.FC<{
    entities?: IMultiSelectorEntity[],
    isEdit?: boolean,
    selectedIds?: string[],
    nonSelectableIds?: string[],
    onSelectionChanged: (selectedIds: string[]) => void,
    settings?: IMultiSelectorSettings,
    nonSelectableReason?: string,
}> = ({
    entities, isEdit = false, selectedIds = [], nonSelectableIds, onSelectionChanged, settings, nonSelectableReason,
}) => {
    const listItems: IMultiSelectorItem[] = useMemo(() => entities ? getAllListItems(entities) : [], [entities]);
    return (
        <MultiSelectorDropdown
            isTree={false}
            items={listItems}
            isEdit={isEdit}
            selectedIds={selectedIds}
            nonSelectableIds={nonSelectableIds}
            nonSelectableReason={nonSelectableReason}
            onSelectionChanged={onSelectionChanged}
            settings={settings} />
    );
};

export default MultiSelectorListDropdown;

