import { changeUrl } from 'common/utils/http';
import React from 'react';
import { useLocation } from 'react-router-dom';

export interface ChangeParamPayload {
    paramKey: string;
    newValue: string;
}
type DefaultParams = { [key: string]: string };
interface UseReactRouterQuery<T = DefaultParams> {
    params: T;
    deleteParams: (paramKeys: string[]) => void;
    changeParam: (paramKey: string, newValue: string) => void;
    changeParams: (params: ChangeParamPayload[]) => void;
}

function useReactRouterQuery<T = DefaultParams>(): UseReactRouterQuery<T> {
    const { search } = useLocation();

    const deleteParams = React.useCallback((paramKeys: string[]) => {
        const searchParams = new URLSearchParams(window.location.search);
        paramKeys.forEach(paramKey => searchParams.delete(paramKey));
        const newUrl = decodeURIComponent(`${window.location.pathname}?${searchParams.toString()}`);
        changeUrl(newUrl);
    }, []);

    const changeParams = React.useCallback((params: ChangeParamPayload[]) => {
        const searchParams = new URLSearchParams(window.location.search);
        params.forEach(({ paramKey, newValue }) => {
            searchParams.delete(paramKey);
            searchParams.append(paramKey, encodeURIComponent(newValue));
        });
        const newUrl = decodeURIComponent(`${window.location.pathname}?${searchParams.toString()}`);
        changeUrl(newUrl);
    }, []);

    const changeParam = React.useCallback((paramKey: string, newValue: string) => {
        changeParams([{ paramKey, newValue }]);
    }, [changeParams]);

    const params = React.useMemo(() => {
        const paramsKeyValue: DefaultParams = {};
        const params = new URLSearchParams(search);

        for (const [key, value] of params) {
            paramsKeyValue[key] = value;
        }

        return paramsKeyValue;

    }, [search]);

    return { params: params as T, deleteParams, changeParam, changeParams };
}

export default useReactRouterQuery;
