import { Asset } from './common.assets';
import {
    detailsWithInstanceIdUrl,
    elbUrl,
    getAssetUrlPreferDome9IdAsAssetId,
    instanceUrl,
    kubernetesUrl,
    redshiftUrl,
    securityGroupUrl,
    vmInstanceUrl,
    withPlatformUrl,
} from './assetUrls';
import { getDefaultEntityFetcher } from './AssetEntityFetchers';

export const assets = [
    //AWS
    new Asset({
        type: 'S3Bucket',
        platform: 'aws',
        isGenericObject: true,
        getUrl: withPlatformUrl,
    }),
    new Asset({
        type: 'Instance',
        platform: 'aws',
        isGenericObject: false,
        getUrl: instanceUrl,
        typeNumber: 1,
        getSpecificEntity: getDefaultEntityFetcher('cloudinstance/by-external-id-and-cloud-account', 'instanceId'),
    }),
    new Asset({
        type: 'Lambda',
        platform: 'aws',
        isGenericObject: false,
        getUrl: detailsWithInstanceIdUrl,
        typeNumber: 5,
        getSpecificEntity: getDefaultEntityFetcher('CloudLambdaFunction/by-external-id-and-cloud-account', 'functionArn'),
    }),
    new Asset({
        type: 'IamRole',
        platform: 'aws',
        isGenericObject: true,
        getUrl: withPlatformUrl,
    }),
    new Asset({
        type: 'IamUser',
        platform: 'aws',
        isGenericObject: true,
        getUrl: withPlatformUrl,
    }),
    new Asset({
        type: 'IamPolicy',
        platform: 'aws',
        isGenericObject: true,
        getUrl: getAssetUrlPreferDome9IdAsAssetId,
    }),
    new Asset({
        type: 'RDS',
        platform: 'aws',
        isGenericObject: false,
        getUrl: detailsWithInstanceIdUrl,
        typeNumber: 3,
    }),
    new Asset({
        type: 'EFS',
        platform: 'aws',
        isGenericObject: false,
        getUrl: detailsWithInstanceIdUrl,
        typeNumber: 10,
    }),
    //TODO:Test
    new Asset({
        type: 'Redshift',
        platform: 'aws',
        isGenericObject: false,
        getUrl: redshiftUrl,
        typeNumber: 9,
    }),
    new Asset({
        type: 'ELB',
        platform: 'aws',
        isGenericObject: false,
        getUrl: elbUrl,
        typeNumber: 2,
    }),
    new Asset({
        type: 'ApplicationLoadBalancer',
        platform: 'aws',
        isGenericObject: false,
        getUrl: detailsWithInstanceIdUrl,
        typeNumber: 8,
    }),
    new Asset({
        type: 'NetworkLoadBalancer',
        platform: 'aws',
        isGenericObject: false,
        getUrl: detailsWithInstanceIdUrl,
        typeNumber: 13,
    }),
    new Asset({
        type: 'EcsService',
        platform: 'aws',
        isGenericObject: true,
        getSpecificEntity: getDefaultEntityFetcher('CloudEcsService/by-external-id-and-cloud-account', 'serviceArn'),
    }),


    //Azure
    new Asset({
        type: 'LoadBalancer',
        platform: 'azure',
        isGenericObject: false,
        getUrl: detailsWithInstanceIdUrl,
        typeNumber: 7,
    }),
    new Asset({
        type: 'VirtualMachine',
        platform: 'azure',
        isGenericObject: false,
        getUrl: detailsWithInstanceIdUrl,
        typeNumber: 6,
    }),
    new Asset({
        type: 'SQLServer',
        platform: 'azure',
        isGenericObject: false,
        getUrl: detailsWithInstanceIdUrl,
        typeNumber: 14,
    }),
    new Asset({
        type: 'FunctionApp',
        platform: 'azure',
        isGenericObject: false,
        getUrl: detailsWithInstanceIdUrl,
        typeNumber: 17,
    }),
    new Asset({
        type: 'User',
        platform: 'azure',
        isGenericObject: true,
        getUrl: withPlatformUrl,
    }),

    //GCP
    new Asset({
        type: 'VMInstance',
        platform: 'gcp',
        isGenericObject: false,
        getUrl: vmInstanceUrl,
        typeNumber: 12,
    }),
    new Asset({
        type: 'GcpIamUser',
        platform: 'gcp',
        isGenericObject: true,
        getUrl: withPlatformUrl,
    }),
    new Asset({
        type: 'GcpIamRole',
        platform: 'gcp',
        isGenericObject: true,
        getUrl: withPlatformUrl,
    }),
    new Asset({
        type: 'LogBucket',
        platform: 'gcp',
        isGenericObject: true,
        getUrl: withPlatformUrl,
    }),
    new Asset({
        type: 'SecurityGroup',
        platform: 'alibaba',
        isGenericObject: true,
        getUrl: securityGroupUrl,
    }),
    new Asset({
        type: 'AutoScalingGroup',
        platform: 'aws',
        isGenericObject: true,
        cloudEntityChildrenInfo: [
            {
                childrenFieldName: 'instances',
                idFieldName: 'id',
                idTargetFieldName: 'entityId',
                type: 'Instance',
            },
        ],
    }),
    new Asset({
        type: 'Agent', platform: 'Dome9', isGenericObject: false, typeNumber: 4,
    }),

    //K8s
    new Asset({
        type: 'KubernetesPod',
        platform: 'kubernetes',
        isGenericObject: false,
        getUrl: kubernetesUrl,
        typeNumber: 21,
    }),
    new Asset({
        type: 'KubernetesImage',
        platform: 'kubernetes',
        isGenericObject: true,
        getUrl: withPlatformUrl,
    }),
    new Asset({
        icon: 'docker',
        type: 'ContainerRegistryImage',
        platform: 'containerregistry',
        isGenericObject: true,
        getUrl: withPlatformUrl,
    }),
    new Asset({
        type: 'KubernetesDaemonSet',
        platform: 'kubernetes',
        isGenericObject: false,
        getUrl: kubernetesUrl,
        typeNumber: 20,
    }),
    new Asset({
        type: 'KubernetesDeployment',
        platform: 'kubernetes',
        isGenericObject: false,
        getUrl: kubernetesUrl,
        typeNumber: 18,
    }),
    new Asset({
        type: 'KubernetesReplicaSet',
        platform: 'kubernetes',
        isGenericObject: false,
        getUrl: kubernetesUrl,
        typeNumber: 19,
    }),

    // ShiftLeft
    new Asset({
        type: 'ShiftLeftImage',
        platform: 'shiftleft',
        isGenericObject: true,
        getUrl: withPlatformUrl,
    }),
    // AWS Ecs
    new Asset({
        type: 'EcsImage',
        platform: 'aws',
        isGenericObject: true,
        getUrl: withPlatformUrl,
    }),
];
