import * as React from 'react';
import { SVGProps, Ref, forwardRef } from 'react';
const SvgReport = (props: SVGProps<SVGSVGElement>, ref: Ref<SVGSVGElement>) => (
  <svg
    viewBox="0 0 16 16"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    ref={ref}
    {...props}
  >
    <path
      d="M4.5 12.5h1m0 0h5m-5 0V11m2-2v3.5m2-2.5v2.5m-5-6h6m-6-2h6"
      stroke="currentColor"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M1.5 1.5a1 1 0 0 1 1-1h8.586a1 1 0 0 1 .707.293l2.414 2.414a1 1 0 0 1 .293.707V14.5a1 1 0 0 1-1 1h-11a1 1 0 0 1-1-1v-13Z"
      stroke="currentColor"
    />
  </svg>
);
const ForwardRef = forwardRef(SvgReport);
export default ForwardRef;
