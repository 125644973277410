import React from 'react';
import { IGenericWidgetDataProps } from '../../../module_interface/overview/Interface';
import ActivityGaugeChart from '../../Highcharts/ActivityGaugeChart';
import Trend from '../../../design-system/components-v2/Trend';

const TrendChangeSummary: React.FC<IGenericWidgetDataProps> = (props) => {
    const data = props.data;

    return ( <>
        {data?.options && data.options.result ? <div className={'flex flex-column items-center py-6 px-8 w-full'}>
            <div className={'flex flex-1 w-full'}>
                <ActivityGaugeChart data={{ items: [{ key: 'denominator', value: data.options.result.denominator },{ key: 'numerator', value: data.options.result.numerator }] }} />
            </div>
            <div className='flex gap-6'>
                <div className={'flex-1'}>
                    <span>{data.options.section1.title }</span>
                    <Trend percentage={data.options.section1.diff }/>
                </div>
                <div className={'flex-1'}>
                    <span>{data.options.section2.title }</span>
                    <Trend percentage={data.options.section2.diff }/>
                </div>
                <div className={'flex-1'}>
                    <span>{data.options.section3.title }</span>
                    <Trend percentage={data.options.section3.diff } />
                </div>
            </div>
        </div>
            : null }
    </>
    );
};

export default TrendChangeSummary;