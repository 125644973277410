import { Addin } from 'common/extensibility/AddinRegistry';
import globalAddinContainer, { buildPath } from '../extensibility/AddinContainer';
import {
    AzureEnvironmentAction,
    AzureEnvironmentData,
    AzureEnvironmentTab,
    PATH_ENVIRONMENT_PAGE,
    PATH_ENVIRONMENT_PAGE_ACTION,
    PATH_ENVIRONMENT_PAGE_AZURE_DETAILS_PANEL,
    PATH_ENVIRONMENT_PAGE_AZURE_TAB,
    PATH_ENVIRONMENT_PAGE_AZURE_CHIP,
} from 'common/module_interface/assets/AzureEnvironment';
import { BlockInfoProps } from 'common/design-system/components-v2/BlockInfo/BlockInfo.types';
import { EnvironmentChip } from '../module_interface/assets/Environments';

export interface AzureEnvironmentAddingBase<T> {
    isRelevant?: (azureData: AzureEnvironmentData) => Promise<boolean> | boolean;
    getValue: (azureData: AzureEnvironmentData) => Promise<T> | T;
    id: string;
    customLoadingLabel?: string; // Relevant for chips only at the moment
}

function addDetailsPanelItems(item: Addin<AzureEnvironmentAddingBase<BlockInfoProps>>[]) {
    globalAddinContainer.add(buildPath(PATH_ENVIRONMENT_PAGE, PATH_ENVIRONMENT_PAGE_AZURE_DETAILS_PANEL), item);
}

function getDetailsPanelItems(): AzureEnvironmentAddingBase<BlockInfoProps>[] {
    return globalAddinContainer.get(buildPath(PATH_ENVIRONMENT_PAGE, PATH_ENVIRONMENT_PAGE_AZURE_DETAILS_PANEL));
}

function addTabs(item: Addin<AzureEnvironmentAddingBase<AzureEnvironmentTab>>[]) {
    globalAddinContainer.add(buildPath(PATH_ENVIRONMENT_PAGE, PATH_ENVIRONMENT_PAGE_AZURE_TAB), item);
}

function getTabs(): AzureEnvironmentAddingBase<AzureEnvironmentTab>[] {
    return globalAddinContainer.get(buildPath(PATH_ENVIRONMENT_PAGE, PATH_ENVIRONMENT_PAGE_AZURE_TAB));
}

function addActions(item: Addin<AzureEnvironmentAddingBase<AzureEnvironmentAction>>[]) {
    globalAddinContainer.add(buildPath(PATH_ENVIRONMENT_PAGE, PATH_ENVIRONMENT_PAGE_ACTION), item);
}

function getActions(): AzureEnvironmentAddingBase<AzureEnvironmentAction>[] {
    return globalAddinContainer.get(buildPath(PATH_ENVIRONMENT_PAGE, PATH_ENVIRONMENT_PAGE_ACTION));
}

function addChips(item: Addin<AzureEnvironmentAddingBase<EnvironmentChip>>[]) {
    globalAddinContainer.add(buildPath(PATH_ENVIRONMENT_PAGE, PATH_ENVIRONMENT_PAGE_AZURE_CHIP), item);
}

function getChips(): AzureEnvironmentAddingBase<EnvironmentChip>[] {
    return globalAddinContainer.get(buildPath(PATH_ENVIRONMENT_PAGE, PATH_ENVIRONMENT_PAGE_AZURE_CHIP));
}

export const AzureEnvironmentAddinRegistry = {
    addDetailsPanelItems,
    getDetailsPanelItems,
    addTabs,
    getTabs,
    addActions,
    getActions,
    addChips,
    getChips
};