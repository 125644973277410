import { AxiosError, AxiosRequestConfig } from 'axios';
import { CACHE_TIMOUTS, getCacheService } from '../../../utils/ermComponents.http';
import { IHttpServiceConfig } from '../../../../interface/services';
import { generalApiError } from '../../../../utils/http';
import { IRecipeModel, IRecipePropsModel } from '../RecipeExampleService';
import {
    chefList,
    dairyProductList, IMeatCategory, meatAlternativeLists,
    meatCategoryList,
    recipeList,
    spiceList,
    vegetableList,
} from './FakeRecipeServer.data';

export const FAKE_RECIPE_URL = 'erm-components/recipe-example';
export const FAKE_RECIPE_SPICES_URL = 'erm-components/recipe-example/spices';
export const FAKE_RECIPE_VEGETABLES_URL = 'erm-components/recipe-example/vegetables';
export const FAKE_RECIPE_MEAT_ALTERNATIVES_URL = 'erm-components/recipe-example/meat-alternatives';
export const FAKE_RECIPE_DAIRY_PRODUCTS_URL = 'erm-components/recipe-example/dairy-products';
export const FAKE_RECIPE_MEAT_PRODUCTS_URL = 'erm-components/recipe-example/meat-products';
export const FAKE_RECIPE_CHEFS_URL = 'erm-components/recipe-example/chefs';


export const getAllRecipes = (): Promise<IRecipeModel[]> => Promise.resolve(recipeList);
export const getAllSpices = (): Promise<string[]> => Promise.resolve(spiceList);
export const getAllVegetables = (): Promise<string[]> => Promise.resolve(vegetableList);
export const getAllMeatAlternatives = (): Promise<string[]> => Promise.resolve(meatAlternativeLists);
export const getAllDairyProducts = (): Promise<string[]> => Promise.resolve(dairyProductList);
export const getAllMeatProducts = (): Promise<IMeatCategory[]> => Promise.resolve(meatCategoryList);
export const getAllChefs = (): Promise<string[]> => Promise.resolve(chefList);

let counter = 0;

export const updateRecipe = (id: string, recipe: IRecipePropsModel): Promise<void> => {
    const existingRecipe: IRecipeModel | undefined = recipeList.find(recipe => recipe.id === id);
    if (existingRecipe) {
        Object.keys(existingRecipe).forEach(key => delete existingRecipe[key]);
        Object.keys(recipe).forEach(key => existingRecipe[key] = recipe[key]);
        existingRecipe.id = id;
        return Promise.resolve();
    } else {
        throw new Error(`Recipe id ${id} not found`);
    }
};

export const deleteRecipe = (id: string): Promise<void> => {
    const index = recipeList.findIndex(recipe => recipe.id === id);
    if (index >= 0) {
        recipeList.splice(index, 1);
    }
    return Promise.resolve();
};

export const addRecipe = (recipeProps: IRecipePropsModel): Promise<string> => {
    counter++;
    const id = String(`recipe-${counter}`);
    const recipe: IRecipeModel = {
        ...recipeProps,
        id,
    };
    recipeList.push(recipe);
    return Promise.resolve(id);
};

const doNothing = () => null;
const doRequest = (path: string, requestObject: AxiosRequestConfig, serviceConfig?: IHttpServiceConfig, customHandleError?: (error: AxiosError<any>) => any) : Promise<any> => {
    if (serviceConfig || customHandleError) doNothing();
    const method: string | undefined = requestObject.method;

    if (!method) {
        throw new Error('Bad request with no path');
    }

    if (method === 'GET') {
        switch (path) {
            case FAKE_RECIPE_URL:
                return getAllRecipes();

            case FAKE_RECIPE_SPICES_URL:
                return getAllSpices();

            case FAKE_RECIPE_VEGETABLES_URL:
                return getAllVegetables();

            case FAKE_RECIPE_DAIRY_PRODUCTS_URL:
                return getAllDairyProducts();

            case FAKE_RECIPE_MEAT_PRODUCTS_URL:
                return getAllMeatProducts();

            case FAKE_RECIPE_MEAT_ALTERNATIVES_URL:
                return getAllMeatAlternatives();

            case FAKE_RECIPE_CHEFS_URL:
                return getAllChefs();
        }
    }

    if (['PUT', 'DELETE'].includes(method)) {
        const matches: any[] | null = path.match(`${FAKE_RECIPE_URL}/(.+)`);
        if (matches && (matches.length > 1)) {
            const id = matches[1];
            if (method === 'PUT') {
                void updateRecipe(id, requestObject.data as IRecipePropsModel);
            } else {
                void deleteRecipe(id);
            }
            return Promise.resolve();
        }
        throw new Error(`Bad ${method} request using path: ${path}`);
    }

    if (method === 'POST') {
        if (path === FAKE_RECIPE_URL) {
            return addRecipe(requestObject.data as IRecipePropsModel);
        }
        throw new Error('Bad POST request: ' + path);
    }

    return Promise.resolve({});
};

export interface IFakeRecipeHttpService {
    request: <T>(path: string, requestObject: AxiosRequestConfig, serviceConfig?: IHttpServiceConfig, customHandleError?: (error: AxiosError<T>) => T) => Promise<T>;
}

const fakeRecipeHttpService: IFakeRecipeHttpService = {
    request: doRequest,
};

export const getFakeRecipeHttpService = (): IFakeRecipeHttpService => fakeRecipeHttpService;
export const sendFakeRecipeHttpRequest = <T>(apiUrl: string, requestObject: AxiosRequestConfig, dataAgeLimit: number = CACHE_TIMOUTS.MEDIUM, tags?: string[]): Promise<T> => {
    return getFakeRecipeHttpService().request<T>(apiUrl, requestObject, getCacheService(dataAgeLimit, tags), generalApiError);
};