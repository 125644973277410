import { getHttpService } from '../../interface/services';

export type RiskModifierSupportedTypes = {
    networkExposure: string[],
    iamExposure: string[],
    iamSensitivity: string[],
    dataSensitivity: string[],
};


export type SupportedAssetsResponse = {
    allSupportedTypes: string[],
    riskModifierSupportedTypes: RiskModifierSupportedTypes,
    contextGraphSupportedTypes: string[],
    dataAssetsCategories: { [key: string]: string[] },
}

export async function getErmSupportedAssets() {
    const supportedAssetsData = await getHttpService().request<SupportedAssetsResponse>(
        'erm/asset-types/supported-types', { method: 'GET' }, { cachingConfig: { useCache: true } },
        (error) => {
            console.error(error);
            return {
                allSupportedTypes: [],
                riskModifierSupportedTypes: {
                    networkExposure: [],
                    iamExposure: [],
                    iamSensitivity: [],
                    dataSensitivity: [],
                },
                contextGraphSupportedTypes: [],
                dataAssetsCategories: { Database: [], DataLake: [], Storage: [] }
            };
        });
    return supportedAssetsData;
}