import { Icon } from '@dome9/berries/react-components';
import React from 'react';
import { formatNumbersForDisplay, getTextColorClass } from '../helpers';
import { IGenericWidgetDataProps } from 'common/module_interface/overview/Interface';

interface IGenericWidgetDataCallback {
    onClickCallback?: ()=> void;
}

const IconAndNumberWidget: React.FC<IGenericWidgetDataProps & IGenericWidgetDataCallback> = (props) => {
    const items = props?.data?.items;
    const className = props?.data?.className;
    const status = props?.data?.status;
    const textColorClass = status && getTextColorClass(status) || '';

    return (
        <div className='@container flex flex-1 items-center justify-center'>
            {items?.map((item, index)=> {
                return (
                    <div key={index} className={`items-center justify-center flex flex-1 p-6 @[150px]:p-8 ${className}`}>
                        <div className="flex items-center flex-col gap-6 @[150px]:gap-7 @[300px]:gap-7 @[150px]:min-w-[120px]">
                            {item.icon &&
                            <>
                                <Icon
                                    className={`flex w-[16px] h-[16px] @[150px]:h-[32px] @[150px]:w-[32px] @[200px]:h-[32px] @[200px]:w-[32px] @[300px]:h-[42px] @[300px]:w-[42px] flex-0 ${status && 'drop-shadow'} ${status && 'drop-shadow'} ${textColorClass}`}
                                    name={item.icon}/>
                                <div className={'border-t border-dashed self-stretch'}></div>
                            </>
                            }
                            <a onClick={props.onClickCallback} style={{ color: item.color }} className={`text-block text-center over:underline hover:cursor-pointer text-xl @[150px]:text-2xl @[200px]:text-3xl @[300px]:text-4xl ${status && 'drop-shadow'} `}>
                                {formatNumbersForDisplay(Number(item.value))}
                            </a>
                            {item.key &&
                    <>
                        <div className={'border-t border-dashed self-stretch'}></div>
                        <div className={`text-center text-block text-2xs @[200px]:text-md ${!status && 'text-weak'} ${status && 'drop-shadow'}`}>
                            {item.key}
                        </div>
                    </>
                            }
                        </div>
                    </div>
                );
            })}
        </div>
    );
};


export default IconAndNumberWidget;
