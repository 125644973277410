import styled from 'styled-components';
import { Stack } from 'common/design-system/components-v2';

const Top = styled(Stack)`
  flex-direction: column;
  height: 100%;
  min-width: calc(33% - 32px);
  max-width: calc(33% - 32px);
`;

const Body = styled(Stack)`
  height: 100%;
  justify-content: center;
`;

export const BoxStyle = {
    Top,
    Body,
};
