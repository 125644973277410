import styled from 'styled-components';
import { Stack, Typography } from 'common/design-system/components-v2';
import { SummaryStyle } from 'common/erm-components/custom/SummaryStatus/SummaryStatus.styled';

const TopDiv = styled(Stack)`
  flex-direction: row;
  height: 100%;
  width: 100%;
  gap: 16px;
`;

const GaugeDiv = styled(SummaryStyle.BaseHintDiv)`
  flex-direction: row;
  width: 130px;
  min-width: 130px;
  gap: 16px;
`;

const LabelContainer = styled(SummaryStyle.BaseHintDiv)`
  flex-direction: row;
  align-items: center;
`;

const MessagesBody = styled(Stack)`
  flex-direction: column;
  align-items: flex-start;
  max-height: 95px;
  overflow: auto;
  gap: 4px;
`;

const MessageTypography = styled(Typography)`
    overflow-wrap: anywhere;
    white-space: normal;
`;

const MessageItem = styled(Stack)`
    display: block;
`;

export const GaugeStyle = {
    TopDiv,
    GaugeDiv,
    LabelContainer,
    MessagesBody,
    MessageTypography,
    MessageItem,
};
