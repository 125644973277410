import React, { useState } from 'react';
import { Button, Modal, TextField } from '@dome9/berries/react-components';
import { Stack, Typography } from 'common/design-system/components-v2';
import { useTranslation } from 'react-i18next';
import { getUserService } from '../../../interface/services';

interface InsertEmailModalProps {
   isOpen: boolean,
   title: string,
   text: string,
   onConfirm: (emailRecipients: string) => void;
   onClose: () => void;
}
const InsertEmailModal: React.FunctionComponent<InsertEmailModalProps> = props => {
    const { isOpen = true, title, text, onConfirm, onClose } = props;
    const { t } = useTranslation();
    const [emailRecipients, setEmailRecipients] = useState(getUserService().getUser().email);

    function isValidEmail(email: string) {
        return /\S+@\S+\.\S+/.test(email);
    }
    const isSubmitButtonDisabled = () => {
        return emailRecipients === '' || !isValidEmail(emailRecipients);
    };

    return (
        <Modal.ModalDialog isOpen={isOpen} width={'md'} shouldCloseOnOverlayClick onRequestClose={onClose}>
            <Modal.ModalHeader title={title} onClose={onClose} />
            <Modal.ModalContent>
                <Stack spacing={4}>
                    {text && <Typography>{text}</Typography>}
                    <TextField value={ emailRecipients } onChange={(e: any) => setEmailRecipients(e.target.value)} onClear={() => setEmailRecipients('')}/>
                </Stack>
            </Modal.ModalContent>
            <Modal.ModalFooter>
                <Stack direction='row' justifyContent='flex-end' fullWidth spacing={2}>
                    <Button onClick={onClose}>
                        {t('COMMON.CANCEL')}
                    </Button>
                    <Button disabled={isSubmitButtonDisabled()} color='primary' onClick={()=>onConfirm(emailRecipients)}>
                        {t('COMMON.SEND')}
                    </Button>
                </Stack>
            </Modal.ModalFooter>
        </Modal.ModalDialog>
    );};

export default InsertEmailModal;