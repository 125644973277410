import styled from 'styled-components';

interface IModalContentProps {
    fullHeight?: boolean;
}

export const ModalContent = styled.div<IModalContentProps>`
    display: flex;
    padding: ${({ theme }) => theme.spacing(4)}px ${({ theme }) => theme.spacing(6)}px;
    flex-direction: column;
    justify-content: flex-start;
    gap: ${({ theme }) => theme.spacing(2)}px;
    align-self: stretch;
    overflow: auto;
    max-height: ${({ fullHeight }) => (fullHeight ? '100%' : '440px')};
`;

export default ModalContent;
