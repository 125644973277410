import styled from 'styled-components';
import { A } from 'common/components/Anchor/Anchor';

export const StyledA = styled(A)`
  overflow: hidden;
  text-overflow: ellipsis;
  &:hover {
    text-decoration: underline;
  }
`;

export const OverflowText = styled.span`
  overflow: hidden;
  text-overflow: ellipsis;
`;
