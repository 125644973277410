import { getCurrentDataCenter } from './RuntimeEnvironment';
import { DataCenterLocation } from '../consts/data.centers';
import { getUserService } from 'common/interface/services';
import { UserRolesTypes } from 'common/enum/UserRoles';


export const isChina = () => {
    const dataCenter = getCurrentDataCenter();
    return dataCenter?.location === DataCenterLocation.CN1;
};

export const isNotChina = () => !isChina();

export const checkChinaPermissions = (permissionsList: UserRolesTypes[]) => {
    return isNotChina() ? getUserService().hasPermission(permissionsList) : false;
};