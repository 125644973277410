import React, { forwardRef } from 'react';
import ReactModal from 'react-modal';
import { ModalProps } from './ModalDialog.types';
import { Content, Overaly } from './ModalDialog.styled';

const Modal: React.FunctionComponent<ModalProps> = forwardRef<ReactModal, ModalProps>((props, ref) => {
    const {
        id,
        isOpen,
        children,
        onRequestClose,
        width = 'md',
        shouldCloseOnOverlayClick,
        className,
        onAfterOpen,
        onAfterClose,
        fullWidth,
        customZIndex
    } = props;
    return (
        <ReactModal
            ref={ref}
            id={id}
            onAfterOpen={onAfterOpen}
            onAfterClose={onAfterClose}
            appElement={document.body}
            closeTimeoutMS={200}
            isOpen={isOpen}
            onRequestClose={onRequestClose}
            shouldCloseOnOverlayClick={shouldCloseOnOverlayClick}
            overlayElement={(props, children) => <Overaly {...props} customZIndex={customZIndex}>{children}</Overaly>}
            contentElement={(props, children) => <Content {...props} width={width} fullWidth={fullWidth}>{children}</Content>}
            overlayClassName={{
                base: `overlay-base ${className || ''}`,
                afterOpen: 'overlay-after',
                beforeClose: 'overlay-before'
            }}
            className={{
                base: `modal ${width}`,
                afterOpen: 'content-after',
                beforeClose: 'content-before'
            }}
        >
            {children}
        </ReactModal>
    );
});
Modal.displayName = 'Modal';

export default Modal;
