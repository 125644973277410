import React from 'react';
import { buildPath } from '../extensibility/AddinContainer';
import { IEvent } from '../module_interface/events/Events';
const PATH_REMEDIATION_PREFIX = 'remediation';
export interface IRemediationModal {
    id: string;
    component: React.ComponentType<any>
}

export interface IActionEnabler{
    enabler?: (event: IEvent) => boolean;
}
export const PATH_EVENTS_REMEDIATION_ENABLER = buildPath(PATH_REMEDIATION_PREFIX, 'events','enabler');
export const PATH_REMEDIATION_MODAL = buildPath(PATH_REMEDIATION_PREFIX, 'modals');

export enum RemediationModuleType {
    CIEM = 'CIEM',
    CDR = 'Magellan',
    CSPM = 'ComplianceEngine'
}

export interface IRemediation {
    id: string;
    ruleLogicHash?: string;
    ruleName: string;
    ruleId?: string;
    logic?: string;
    rulesetId?: number;
    cloudAccountId?: string | null;
    cloudAccountIds?: string[] | null;
    platform?: string;
    comment?: string;
    cloudBots?: string[];
    isSelectable: boolean;
    // ciem source type
    severities?: string[];
    logicExpressions?: string[];
}
export interface IRemediationCreation {
    rulesetId?: number;
    ruleLogicHash?: string;
    ruleName?: string;
    ruleId?: string;
    logic?: string;
    logicExpressions?: string[];
    cloudAccountId?: string | null;
    cloudAccountIds?: string[] | null;
    cloudAccountType?: string;
    platform?: string;
    comment: string;
    cloudBots?: string[]
    severities?: string[];
}

export interface ICloudBot {
    name: string;
    platform: string;
    parameters?: string[];
    isCustom?: boolean;
}
