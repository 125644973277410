import styled, { css } from 'styled-components';
import Button from 'common/design-system/components-v2/Button';
import Input from 'common/design-system/components-v2/Input';
import Stack from 'common/design-system/components-v2/Stack';

const ERROR_ICON_WIDTH = 32;
const ROW_LEFT_PADDING = 8;
const PRIMARY_ROW_HEIGHT = 32;
const HALF_PRIMARY_HEIGHT = PRIMARY_ROW_HEIGHT / 2;
const SEC_ROW_HEIGHT = 16;
const WIDE_PART = 32;
const HALF_WIDE_PART = WIDE_PART / 2;
const HALF_WIDE_PART_MINUS = HALF_WIDE_PART - 1;
const HALF_WIDE_PART_PLUS = HALF_WIDE_PART + 1;
const NARROW_PART = 12;

const TopDiv = styled(Stack)`
  flex-direction: column;
`;

const ConditionDiv = styled(Stack)`
  flex-direction: row;
  height: ${PRIMARY_ROW_HEIGHT}px;
  width: 100%;
`;

const LogicalOperatorDiv = styled(Stack)`
  flex-direction: row;
  gap: ${({ theme }) => `${theme.spacing(4)}${theme.units}`};
  height: ${PRIMARY_ROW_HEIGHT}px;
  align-items: center;
`;

const LogicalOperatorSelectorDiv = styled(Stack)`
  flex-direction: column;
  height: 100%;
  justify-content: flex-end;
`;

const RowWrapper = styled(Stack)`
  align-items: center;
  flex-direction: row;
  height: ${PRIMARY_ROW_HEIGHT}px;
`;

const ButtonsRowWrapper = styled(RowWrapper)`
  flex-grow: 1;
  justify-content: flex-end;
  height: ${PRIMARY_ROW_HEIGHT}px;
`;

const StretchDiv = styled.div`
  display: flex;
  flex-grow: 1;
  width: 0;
`;

const SelectContainer = styled(Stack)<{ width?: string }>`
  ${({ width }) => width && css`
        min-width: ${width};
        max-width: ${width};
    `}
`;

const InputDiv = styled(Input)`
  background: ${({ theme }) => `${theme.palette.surface.primary}`};
`;

const TreeItemRowDiv = styled(Stack)`
  flex-direction: row;
  border-radius: ${({ theme }) => `${theme.border.radius(2)}${theme.units}`};
  align-items: center;
  padding-left: ${ROW_LEFT_PADDING}px;
  height: ${PRIMARY_ROW_HEIGHT}px;

  &:hover {
    background: ${({ theme }) => `${theme.palette.surface.normal}`};
  }
`;

const TreeNodeContentDiv = styled(Stack)`
  flex-direction: column;
`;

const SubItemsGroupDiv = styled(Stack)`
  flex-direction: column;
`;

const DirectConnectorDiv = styled(Stack)`
  flex-direction: row;
`;

const DirectConnectorLeftDiv = styled.div<{ isLast?: boolean }>`
  width: 1px;
  height: ${props => props.isLast ? '20px' : '40px'};
  margin-bottom: ${props => props.isLast ? '18px' : '0'};
  margin-left: 9px;
  border-left: 1px solid #ccc;
`;

const DirectConnectorRightDiv = styled.div`
  width: 25px;
  height: 19px;
  border-bottom: 1px solid #ccc;
  margin-top: 2px;
`;

const DirectConnectorSpacerDiv = styled.div`
  height: 40px;
  margin-left: 9px;
  border-left: 1px solid #ccc;
  width: 25px;
`;

const DirectConnectorEmptyDiv = styled.div`
  height: 40px;
  width: 35px;
`;

const DirectConnectorTopEmptyDiv = styled.div`
  height: 40px;
  width: 0;
`;

const ActionButton = styled(Button)`
  border-color: ${({ theme }) => theme.palette.border.normal};
  height:${PRIMARY_ROW_HEIGHT}px;
`;

const AddConditionButton = styled(ActionButton)`
  border-bottom-right-radius: 0;
  border-top-right-radius: 0;
  background: ${({ theme }) => theme.palette.surface.primary};
`;

const ChevronButton = styled(ActionButton)`
  border-bottom-left-radius: 0;
  border-top-left-radius: 0;
  padding-left: 6px;
  padding-right: 8px;
  background: ${({ theme }) => theme.palette.surface.primary};
`;

const ItemErrorIconDiv = styled(Stack)`
  height: ${PRIMARY_ROW_HEIGHT}px;
  max-width: ${ERROR_ICON_WIDTH}px;
  min-width: ${ERROR_ICON_WIDTH}px;
  align-items: center;
  justify-content: center;
`;

const DragHandle = styled.div`
  width: ${NARROW_PART - 7}px;
  margin-right: 5px;
  margin-left: 2px;
  height: 14px;
  border-right-width: 1px;
  border-left-width: 1px;
  border-style: solid;
  border-color: ${({ theme }) => theme.palette.border.normal};
  padding: 1px;
  align-self: center;
  cursor: grab;
`;

export const FilterStyled = {
    TopDiv,
    ConditionDiv,
    LogicalOperatorDiv,
    LogicalOperatorSelectorDiv,
    RowWrapper,
    ButtonsRowWrapper,
    SelectContainer,
    InputDiv,
    StretchDiv,
    TreeItemRowDiv,
    ItemErrorIconDiv,
    SubItemsGroupDiv,
    TreeNodeContentDiv,
    DirectConnectorDiv,
    DirectConnectorLeftDiv,
    DirectConnectorRightDiv,
    DirectConnectorSpacerDiv,
    DirectConnectorEmptyDiv,
    DirectConnectorTopEmptyDiv,
    ActionButton,
    AddConditionButton,
    ChevronButton,
    DragHandle,
};

const PrimaryRowPartsTopDiv = styled(Stack)`
  height: ${PRIMARY_ROW_HEIGHT}px;
  flex-direction: row; 
`;

const PrimaryRowIconSpacer = styled.div`
  width: ${ERROR_ICON_WIDTH}px;
`;

const PrimaryRowWideLinePart = styled.div`
  width: 1px;
  margin: 0 ${HALF_WIDE_PART_MINUS}px 0 ${HALF_WIDE_PART}px;
  border-left: 1px solid ${({ theme }) => theme.palette.border.normal};
`;

const PrimaryRowWideEmptyPart = styled.div`
  width: ${WIDE_PART}px;
`;

const PrimaryRowNarrowPart = styled.div`
  width: ${NARROW_PART}px;
`;

const PrimaryRowLeftLinePart = styled.div`
  width: ${HALF_WIDE_PART_PLUS}px;
  border-right: 1px solid ${({ theme }) => theme.palette.border.normal};
`;

const PrimaryRowLeftHalfLinePart = styled.div`
  width: ${HALF_WIDE_PART_PLUS}px;
  border-right: 1px solid ${({ theme }) => theme.palette.border.normal};
  height: ${HALF_PRIMARY_HEIGHT}px;
  margin-bottom: ${HALF_PRIMARY_HEIGHT}px;
`;

const PrimaryRowRightHalfLinePart = styled.div`
  width: ${HALF_WIDE_PART_MINUS - 5}px;
  border-bottom: 1px solid ${({ theme }) => theme.palette.border.normal};;
  height: ${HALF_PRIMARY_HEIGHT}px;
  margin-bottom: ${HALF_PRIMARY_HEIGHT}px;
  margin-right: 5px;
`;

export const PrimaryRowStyled = {
    PrimaryRowPartsTopDiv,
    PrimaryRowIconSpacer,
    PrimaryRowWideLinePart,
    PrimaryRowWideEmptyPart,
    PrimaryRowNarrowPart,
    PrimaryRowLeftLinePart,
    PrimaryRowLeftHalfLinePart,
    PrimaryRowRightHalfLinePart,
};


const SecRowTopDiv = styled(Stack)<{ highlight?: boolean }>`
  height: ${SEC_ROW_HEIGHT}px;
  flex-direction: row; 
  padding-left: ${ROW_LEFT_PADDING}px;
  background: ${({ theme, highlight }) => highlight ? theme.palette.surface.strong : 'transparent'};
`;

const SecRowIconSpacer = styled.div`
  width: ${ERROR_ICON_WIDTH}px;
`;

const SecRowWideLinePart = styled.div`
  width: 1px;
  margin: 0 ${HALF_WIDE_PART_MINUS}px 0 ${HALF_WIDE_PART}px;
  border-left: 1px solid ${({ theme }) => theme.palette.border.normal};;
`;

const SecRowWideEmptyPart = styled.div`
  width: ${WIDE_PART}px;
`;

const SecRowNarrowPart = styled.div`
  width: ${NARROW_PART}px;
`;


const SecRowFullPart = styled(Stack)<{ highlight?: boolean }>`
  flex-direction: row; 
  background: ${({ theme, highlight }) => highlight ? theme.palette.surface.strong : 'transparent'};
  border-top-left-radius: 4px;
  border-bottom-left-radius: 4px;
`;

const SecRowStretch = styled.div<{ highlight?: boolean, hasLeftPart?: boolean }>`
  display: flex;
  flex-grow: 1;
  width: 0;
  background: ${({ theme, highlight }) => highlight ? theme.palette.surface.strong : 'transparent'};
  border-top-right-radius: 4px;
  border-bottom-right-radius: 4px;
  border-top-left-radius: ${props => props.hasLeftPart ? '0' : '4px'};
  border-bottom-left-radius: ${props => props.hasLeftPart ? '0' : '4px'};
`;

export const SecRowStyled = {
    SecRowTopDiv,
    SecRowIconSpacer,
    SecRowWideLinePart,
    SecRowWideEmptyPart,
    SecRowNarrowPart,
    SecRowFullPart,
    SecRowStretch,
};
