import { forwardRef } from 'react';
import Stack from '../../Stack';
import { WizardFooterProps } from './WizardFooter.types';
import WizardFooterStyles from './WizardFooter.styled';
import { Separator } from '../../Separator';

const WizardFooter = forwardRef<HTMLDivElement, WizardFooterProps>((props, ref) => {
    const { children } = props;

    return (
        <WizardFooterStyles.Wrapper
            ref={ref}
        >
            <Separator />
            <Stack
                direction='row'
                alignItems='center'
                justifyContent='flex-end'
                fullWidth
                padding={[4, 6]}
            >
                {children}
            </Stack>
            
        </WizardFooterStyles.Wrapper>
    );
});

WizardFooter.displayName = 'WizardFooter';

export default WizardFooter;