import { FC } from 'react';
import { FormStyled } from '../../CustomForm.styled';
import Input from '../../../../../design-system/components-v2/Input';
import { FieldLabelComponent } from '../helperComponents/FieldLabelComponent';
import { ErrorComponent } from '../helperComponents/ErrorComponent';
import { IOnFieldChanged, IInputItem, IFullErrorInfo } from '../../CustomForm.interface';
import { getItemEnterPlaceholder } from '../../CustomForm.values';

export interface IInputItemComponentProps {
    item: IInputItem;
    onFieldChanged: IOnFieldChanged;
    showErrors: boolean;
    errorInfo?: IFullErrorInfo;
    skipTitle?: boolean;
    isInner?: boolean;
}

export const InputItemComponent: FC<IInputItemComponentProps> = ({ item, onFieldChanged, showErrors, errorInfo, skipTitle, isInner }) => {
    const { state } = item;
    return (
        <FormStyled.TopItem isInner={isInner} widthInfo={state.widthInfo} >
            {!skipTitle && <FieldLabelComponent item={item} />}
            <Input
                fullWidth={true}
                value={state.value}
                onChange={e => onFieldChanged({ ...item, state: { ...state, value: e.target.value } })}
                disabled={state.disabled || state.readonly}
                placeholder={getItemEnterPlaceholder(item)}
                isError={!!(showErrors && errorInfo)}
            />
            <ErrorComponent showErrors={showErrors} errorInfo={errorInfo} />
            {(state.charsLimit !== undefined) && <FormStyled.TextCounter>{state.value?.length || 0} / {state.charsLimit}</FormStyled.TextCounter>}
        </FormStyled.TopItem>
    );
};