import Icon from '../../Icon';
import Stack from '../../Stack';
import Typography from '../../Typography';
import StepStyles from './Step.styled';

interface StepProps {
  number: number;
  title: string;
  isCompleted: boolean;
  isCurrent: boolean;
}

const Step: React.FC<StepProps> = ({
    number,
    title,
    isCompleted,
    isCurrent,
    ...rest
}: StepProps) => {
    return (
        <StepStyles.StepWrapper {...rest} >
            <Stack direction='row' alignItems='center' spacing={4}>
                <StepStyles.StepCircle
                    data-testid={`step-${number + 1}-cricle`}
                    alignItems='center'
                    justifyContent='center'
                    isCompleted={isCompleted}
                    isCurrent={isCurrent}
                >
                    {isCompleted
                        ? <Icon name={'check'} color='normal' />
                        : <Typography variant='bodyLg' color={isCurrent ? 'brandPrimary' : 'normal'} >{number}</Typography>
                    }
                </StepStyles.StepCircle>
                <StepStyles.StepTitle title={title} variant='body'>{title}</StepStyles.StepTitle >
            </Stack>
        </StepStyles.StepWrapper>
    );
};

export default Step;
