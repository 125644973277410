import { SELECTION_GRID_COLUMN } from 'common/utils/tableUtils';
import { CGColDef } from 'common/components/ProtectedAssets/ProtectedAssetsTable.interface';
import { RecipeHeadersNames, RecipeItemNames } from '../../RecipeEditor.consts';
import { RecipeNameCellRender } from './renderers/RecipeNameCellRender';
import { ValueGetterParams } from 'ag-grid-enterprise';
import { IRecipe } from '../../../RecipeExampleCenter/RecipeExample.interface';
import { RECIPE_FIELD_NAMES } from '../../../RecipeExampleCenter/RecipeExample.consts';

const getTextFromValues = (values?: string[]): string => {
    if (!values || (values.length === 0)) {
        return '';
    }
    return values.join(', ');
};

export const getRecipesColumns = (getIsUsingDrawer: () => boolean): CGColDef[] => {
    return [
        SELECTION_GRID_COLUMN,
        {
            colId: RECIPE_FIELD_NAMES.NAME,
            field: RECIPE_FIELD_NAMES.NAME,
            sortable: true,
            headerName: RecipeHeadersNames[RecipeItemNames.name],
            minWidth: 200,
            flex: 10,
            sort: 'asc',
            cellRenderer: RecipeNameCellRender,
            cellRendererParams: { getIsUsingDrawer },
        },
        {
            field: RECIPE_FIELD_NAMES.MEAT_CATEGORY,
            colId: RECIPE_FIELD_NAMES.MEAT_CATEGORY,
            headerName: RecipeHeadersNames[RecipeItemNames.meatCategory],
            minWidth: 100,
            flex: 13,
        },
        {
            field: RECIPE_FIELD_NAMES.MEAT_TYPES,
            colId: RECIPE_FIELD_NAMES.MEAT_TYPES,
            headerName: RecipeHeadersNames[RecipeItemNames.meatTypes],
            valueGetter: (param: ValueGetterParams<IRecipe>) => {
                const recipe: IRecipe | undefined = param.data;
                if (!recipe) {
                    return '';
                }
                return getTextFromValues(recipe.meatTypes) || getTextFromValues(recipe.meatAlternatives) || getTextFromValues(recipe.dairyProducts);
            },
            minWidth: 100,
            flex: 13,
        },
        {
            field: RECIPE_FIELD_NAMES.SPICES,
            colId: RECIPE_FIELD_NAMES.SPICES,
            headerName: RecipeHeadersNames[RecipeItemNames.spices],
            minWidth: 150,
            valueGetter: (param: ValueGetterParams<IRecipe>) => {
                const recipe: IRecipe | undefined = param.data;
                return getTextFromValues(recipe?.spices);
            },
            flex: 10,
        },
        {
            field: RECIPE_FIELD_NAMES.VEGETABLES,
            colId: RECIPE_FIELD_NAMES.VEGETABLES,
            headerName: RecipeHeadersNames[RecipeItemNames.vegetables],
            valueGetter: (param: ValueGetterParams<IRecipe>) => {
                const recipe: IRecipe | undefined = param.data;
                return getTextFromValues(recipe?.vegetables);
            },
            minWidth: 150,
            flex: 10,
        },
        {
            field: RECIPE_FIELD_NAMES.INSTRUCTIONS,
            colId: RECIPE_FIELD_NAMES.INSTRUCTIONS,
            headerName: RecipeHeadersNames[RecipeItemNames.instructions],
            minWidth: 100,
            flex: 15,
        },
    ];
};
