import React, { useCallback, useState } from 'react';
import { IAsset } from '../../../assets/common.assets';
import { AssetTypeRowStyled, ExampleStyled } from './DrawerExample.styled';
import { closeDrawer, showDrawer } from '../Drawer/Drawer.utils';
import { getProtectedAssetsService } from '../../../module_interface/assets/ProtectedAssets';
import { getAssets } from '../../../assets/Assets.service';
import {
    DRAWER_EXAMPLE_TYPE_VIEWER, DRAWER_LARGE_TEXT_VIEWER,
    IExampleTypeViewerData
} from './DrawerExampleInitialize';
import { getNotificationsService } from '../../../interface/services';
import { NotificationType } from '../../../interface/notifications';
import { useDrawerHandler } from '../Drawer/UseDrawerHandler';

const EXAMPLE_REVERSE_LIST_HANDLER_ID = 'EXAMPLE_REVERSE_LIST_HANDLER_ID';
const EXAMPLE_RESTART_LIST_HANDLER_ID = 'EXAMPLE_RESTART_LIST_HANDLER_ID';
const EXAMPLE_DELETE_TYPE_EVENT_HANDLER_ID = 'EXAMPLE_DELETE_TYPE_EVENT_HANDLER_ID';

export const DrawerExamplePage: React.FC = () => {
    const [assetTypes, setAssetTypes] = useState<IAsset[]>(getAssets());
    const showTypeDrawer = useCallback((typeByPlatform: string) => {
        const iAsset: IAsset | null = getProtectedAssetsService().getAssetByType(typeByPlatform);
        const widgetData: IExampleTypeViewerData = {
            typeByPlatform: typeByPlatform || 'unknown',
            title: typeByPlatform?.toUpperCase() || 'Unknown',
            icon: iAsset?.icon,
        };
        showDrawer(DRAWER_EXAMPLE_TYPE_VIEWER.key, widgetData, {
            [DRAWER_EXAMPLE_TYPE_VIEWER.eventTypes.delete]: EXAMPLE_DELETE_TYPE_EVENT_HANDLER_ID,
        });
    }, []);

    const handleReverseListEvent = useCallback((date: Date) => {
        const newAssetTypes = assetTypes.reverse();
        setAssetTypes(newAssetTypes);
        closeDrawer();
        getNotificationsService().addNotification({
            type: NotificationType.SUCCESS,
            title: 'Table order Reversed',
            text: `Table rows were reversed at: ${date}`,
        });
    }, [assetTypes]);

    const handleRestartListEvent = useCallback((date: Date) => {
        setAssetTypes(getAssets());
        closeDrawer();
        getNotificationsService().addNotification({
            type: NotificationType.SUCCESS,
            title: 'Type List Reloaded',
            text: `All types restarted at: ${date}`,
        });
    }, []);
    
    const handleDeleteTypeEvent = useCallback((typeByPlatform: string) => {
        const remainingAssetTypes = assetTypes.filter(assetType => assetType.typeByPlatform !== typeByPlatform);
        setAssetTypes(remainingAssetTypes);
        getNotificationsService().addNotification({
            type: NotificationType.SUCCESS,
            title: 'Type Deleted',
            text: `Type ${typeByPlatform} was deleted from list`,
        });

    }, [assetTypes]);

    const showNameDrawer = useCallback((name: string) => {
        showDrawer(DRAWER_LARGE_TEXT_VIEWER.key, name, {
            [DRAWER_LARGE_TEXT_VIEWER.eventTypes.restart]: EXAMPLE_RESTART_LIST_HANDLER_ID,
            [DRAWER_LARGE_TEXT_VIEWER.eventTypes.reverse]: EXAMPLE_REVERSE_LIST_HANDLER_ID,
        });
    }, []);

    const getHeader = useCallback(() => {
        return (
            <AssetTypeRowStyled.RowHeaderDiv key='header'>
                <AssetTypeRowStyled.CellHeaderDiv width={350}>{'Name'}</AssetTypeRowStyled.CellHeaderDiv>
                <AssetTypeRowStyled.CellHeaderDiv>{'Type'}</AssetTypeRowStyled.CellHeaderDiv>
                <AssetTypeRowStyled.CellHeaderDiv width={150}>{'Platform'}</AssetTypeRowStyled.CellHeaderDiv>
            </AssetTypeRowStyled.RowHeaderDiv>
        );
    }, []);

    const getRow = useCallback((assetType: IAsset) => {
        return (
            <AssetTypeRowStyled.RowSimpleDiv key={assetType.typeByPlatform}>
                <AssetTypeRowStyled.CellLinkDiv width={350} onClick={() => showNameDrawer(assetType.displayName) } color='purple'>
                    <AssetTypeRowStyled.CellIcon vendorNameOrPath={assetType.icon} />{assetType.displayName}
                </AssetTypeRowStyled.CellLinkDiv>
                <AssetTypeRowStyled.CellLinkDiv onClick={() => showTypeDrawer(assetType.typeByPlatform)} color='blue'>{assetType.type}</AssetTypeRowStyled.CellLinkDiv>
                <AssetTypeRowStyled.CellTextDiv onClick={() => showNameDrawer(assetType.displayName) } width={150}>{assetType.platform?.toUpperCase()}</AssetTypeRowStyled.CellTextDiv>
            </AssetTypeRowStyled.RowSimpleDiv>
        );
    }, [showNameDrawer, showTypeDrawer]);

    useDrawerHandler(EXAMPLE_REVERSE_LIST_HANDLER_ID, handleReverseListEvent);
    useDrawerHandler(EXAMPLE_RESTART_LIST_HANDLER_ID, handleRestartListEvent);
    useDrawerHandler(EXAMPLE_DELETE_TYPE_EVENT_HANDLER_ID, handleDeleteTypeEvent);

    return (
        <div className='bg-aside flex flex-1 flex-col'>
            <ExampleStyled.TopDiv>
                <ExampleStyled.DisclaimerDiv>
                    <p><strong>Disclaimer</strong>: This is not a real page in the system. </p>
                    <p>Rather, this page was created with the solely purpose of <strong>displaying the new Drawer infrastructure</strong>, on top of a simple, fake example.</p>
                    <p> This page, along with the corresponding menu on the left, will be visible only when the proper Active Flag ("page-drawer") is set.</p>
                </ExampleStyled.DisclaimerDiv>
                <ExampleStyled.TableDiv>
                    { getHeader() }
                    { assetTypes.map((assetType: IAsset) => getRow(assetType)) }
                </ExampleStyled.TableDiv>
            </ExampleStyled.TopDiv>
        </div>
    );
};
