import * as React from 'react';
import { SVGProps, Ref, forwardRef } from 'react';
const SvgGcp = (props: SVGProps<SVGSVGElement>, ref: Ref<SVGSVGElement>) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    viewBox="0 0 33.37 26.85"
    ref={ref}
    {...props}
  >
    <g data-name="Layer 2">
      <g data-name="Layer 1">
        <path
          fill="#ea4335"
          d="M21.17 7.4h.11l-.07-.06 4-4v-.08A13 13 0 0 0 4 9.62l.21-.07A9.41 9.41 0 0 1 9.37 8a8.69 8.69 0 0 1 1.84.17.09.09 0 0 1 .08 0 7.22 7.22 0 0 1 9.9-.75Z"
        />
        <path
          fill="#4285f4"
          d="M29.22 9.62a13 13 0 0 0-3.94-6.35l-4.07 4.07a7.25 7.25 0 0 1 2.66 5.74v.72c4.83 0 4.83 7.25 0 7.25h-7.26v5.75h7.25a9.42 9.42 0 0 0 5.36-17.18Z"
        />
        <path
          fill="#34a853"
          d="M9.37 26.81h7.24V21H9.37a3.66 3.66 0 0 1-1.49-.32l-4.19 4.2a9.42 9.42 0 0 0 5.69 1.89Z"
        />
        <path
          fill="#fbbc05"
          d="M9.37 8a9.42 9.42 0 0 0-5.69 16.89l4.2-4.21a3.62 3.62 0 1 1 4.8-4.79l4.2-4.2A9.38 9.38 0 0 0 9.37 8Z"
        />
      </g>
    </g>
  </svg>
);
const ForwardRef = forwardRef(SvgGcp);
export default ForwardRef;
