import React from 'react';
import { Icon } from '@dome9/berries/react-components';
import { Stack } from 'common/design-system/components-v2';
import { getConnectionStatusDetails } from 'common/module_interface/intelligence/Intelligence.utils';
import { ConnectionStatus } from 'common/module_interface/intelligence/Intelligence.const';
import { ICellRendererParams } from 'ag-grid-enterprise';

interface IStatusCellRendererDataProps {
    status: ConnectionStatus;
}

const StatusCellRenderer: React.FC<ICellRendererParams<IStatusCellRendererDataProps>> = (props) => {
    const status = props.data?.status;

    if (!status) {
        return null;
    }

    const statusDetails = getConnectionStatusDetails(status);

    return (
        <Stack direction={'row'} alignItems={'center'}>
            <Icon className={'w-7 mr-6'} name={statusDetails.icon}/>
            {statusDetails.text}
        </Stack>
    );
};

export default StatusCellRenderer;
