import React, { forwardRef } from 'react';
import TabsStyles from './Tabs.styles';
import { ITabItemProps, ITabsProps } from './Tabs.types';
import Typography from '../Typography/Typography';
import Stack from '../Stack/Stack';
import Counter from '../Counter/Counter';
import Dropdown from '../Dropdown/Dropdown';

const Tabs = forwardRef<HTMLDivElement, ITabsProps>((props, ref) => {
    const {
        tabs,
        selectedTab,
        disabled,
        className,
        direction = 'row',
        alignment = 'start',
        iconsOnly = false,
        showMoreProps,
        tabsMaxWidth,
        onClose,
        onTabSelected
    } = props;

    const innerRef = React.useRef<HTMLDivElement>(null);
    const [_selectedTab, _setSelectedTab] = React.useState<string | number | undefined>(selectedTab);
    const [dividerStyles, setDividerStyles] = React.useState<React.CSSProperties>({});
    const [showMoreState, setShowMoreState] = React.useState(false);
    const showMoreButtonRef = React.useRef<HTMLButtonElement>(null);
    const calculateDividerPositionDebounce = React.useRef<NodeJS.Timeout>(setTimeout(() => null, 0));

    React.useImperativeHandle(ref, () => innerRef.current as HTMLDivElement);

    React.useEffect(() => {
        _setSelectedTab(selectedTab);
    }, [selectedTab]);

    const handleOnClick = (tab: ITabItemProps, index: number, event: React.MouseEvent<HTMLButtonElement, MouseEvent>) => {
        _setSelectedTab(tab.value);
        onTabSelected && onTabSelected(tab, index, event);
    };

    const handleOnClickFromMore = (tab: ITabItemProps, index: number, event: React.MouseEvent<HTMLButtonElement, MouseEvent>) => {
        setShowMoreState(false);
        const newIndex = tabs.findIndex((t) => t.value === tab.value);
        handleOnClick(tab, newIndex, event);
    };

    const calculateDividerPosition = React.useCallback(() => {
        clearTimeout(calculateDividerPositionDebounce.current);
        calculateDividerPositionDebounce.current = setTimeout(() => {
            const selectedTabIndex = tabs.findIndex((tab) => tab.value === _selectedTab);
            if (selectedTabIndex === -1) {
                setDividerStyles({});
                return;
            }
    
            const allButtonsNodes = innerRef.current?.querySelectorAll('#button-tab-item');
            
            let selectedButtonRef = allButtonsNodes?.[selectedTabIndex] as any;
            
            if (showMoreProps && selectedTabIndex >= showMoreProps.maxTabs) {
                selectedButtonRef = showMoreButtonRef.current;
            }
    
            if (selectedButtonRef) {
                if (direction === 'row') {
                    const left = selectedButtonRef.offsetLeft || 0;
                    const width = selectedButtonRef.offsetWidth || 0;
                    setDividerStyles({ left: `${left}px`, width: `${width}px` });
                } 
                if (direction === 'column') {
                    const top = selectedButtonRef.offsetTop || 0;
                    const height = selectedButtonRef.offsetHeight || 0;
                    setDividerStyles({ top: `${top}px`, height: `${height}px` });
                }
            }
        }, 50);
    }, [_selectedTab, direction, showMoreProps, tabs]);

    React.useEffect(() => {
        if (!innerRef.current) return;
        const resizeObserver = new ResizeObserver(() => {
            if (!innerRef.current) return;
            calculateDividerPosition();
        });
        resizeObserver.observe(innerRef.current);
        return () => resizeObserver.disconnect();
    }, [calculateDividerPosition]);

    React.useEffect(() => {
        calculateDividerPosition();
    }, [calculateDividerPosition]);

    const handleOnClose = React.useCallback((tab: ITabItemProps, index: number, event: React.MouseEvent<HTMLButtonElement, MouseEvent>) => {
        event.stopPropagation();
        onClose && onClose(tab, index, event);
    }, [onClose]);

    const handleOnCloseFromMore = React.useCallback((tab: ITabItemProps, index: number, event: React.MouseEvent<HTMLButtonElement, MouseEvent>) => {
        setShowMoreState(false);
        const newIndex = tabs.findIndex((t) => t.value === tab.value);
        handleOnClose(tab, newIndex, event);
    }, [handleOnClose, tabs]);

    const showCloseIcon = Boolean(onClose);

    const tabsToRender = React.useMemo(() => {
        if (!showMoreProps) return tabs;
        return tabs.slice(0, showMoreProps.maxTabs);
    }, [tabs, showMoreProps]);

    const tabsToRenderInShowMore = React.useMemo(() => {
        if (!showMoreProps) return [];
        return tabs.slice(showMoreProps.maxTabs);
    }, [tabs, showMoreProps]);

    return (
        <TabsStyles.Wrapper
            className={className}
            direction={direction}
            alignment={alignment}
            spacing={direction === 'row' ? 1 : 0}
            fullWidth
            ref={innerRef}
        >
            {tabsToRender.map((tab, index) => (
                <TabsStyles.ButtonStyled
                    key={`button-group-item-${index}-${tabs.length}`}
                    id='button-tab-item'
                    iconProps={tab.icon}
                    disabled={disabled || tab.disabled}
                    onClick={(event) => handleOnClick(tab, index, event)}
                    variant='text'
                    color={_selectedTab === tab.value ? 'brandPrimary' : 'normal'}
                    direction={direction}
                    buttonAlignment='flex-start'
                    alignment={alignment}
                    tooltip={iconsOnly ? tab.label : undefined}
                    withCloseIcon={showCloseIcon}
                    contextMenuElement={tab.contextMenuElement}
                    tabsMaxWidth={tabsMaxWidth}
                >
                    {(!iconsOnly || tab.counter) && (
                        <Stack spacing={2} direction='row' alignItems='center'>
                            {!iconsOnly && (
                                <Typography
                                    variant='body'
                                    color={_selectedTab === tab.value ? 'brandPrimary' : 'normal'}
                                >
                                    {tab.label}
                                </Typography>
                            )}
                            {tab.counter && <Counter count={tab.counter} />}
                        </Stack>
                    )}
                    {showCloseIcon && (
                        <TabsStyles.CloseIcon // this is cause a warning because button inside button
                            iconProps={{ name: 'remove', size: 10 }}
                            size='small'
                            isSelected={_selectedTab === tab.value}
                            onClick={(event) => handleOnClose(tab, index, event)}
                            disabled={disabled || tab.disabled}
                        />
                    )}
                </TabsStyles.ButtonStyled>
            ))}
            {(showMoreProps && tabsToRenderInShowMore.length > 0) && (
                <Dropdown
                    ref={showMoreButtonRef}
                    label='More'
                    open={showMoreState}
                    onStateChange={(state) => setShowMoreState(state)}
                    buttonProps={{
                        disabled,
                        className: 'show-more-button',
                        color: tabs.findIndex(tab => tab.value === _selectedTab) >= showMoreProps.maxTabs ? 'brandPrimary' : 'normal',
                    }}
                >
                    <Tabs
                        tabs={tabsToRenderInShowMore.map((tab) => ({
                            label: tab.label,
                            value: tab.value,
                            icon: tab.icon,
                            counter: tab.counter,
                            disabled: tab.disabled,
                        }))}
                        selectedTab={_selectedTab}
                        onTabSelected={handleOnClickFromMore}
                        onClose={handleOnCloseFromMore}
                        direction='column'
                        tabsMaxWidth={tabsMaxWidth}
                    />
                </Dropdown>
            )}
            <TabsStyles.Divider direction={direction} style={dividerStyles} />
        </TabsStyles.Wrapper>
    );
});
Tabs.displayName = 'Tabs';

export default Tabs;
