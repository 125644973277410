import {
    CompoundFilterLogicalOperator, ICompoundFilter,
    ICompoundFilterNode,
} from 'common/erm-components/custom/FilterTree/CompoundFilter';
import { FilterConditionOperator } from 'common/erm-components/custom/FilterTree/FilterCondition';
import { IFilterTreeFieldDefinition } from 'common/erm-components/custom/FilterTree/FilterTree.interface';
import {
    DefaultConditionOperatorsMap
} from 'common/erm-components/custom/FilterTree/FilterTreeDefaults/DefaultConditionConsts';
import {
    DefaultValuesSelector
} from 'common/erm-components/custom/FilterTree/FilterTreeDefaults/DefaultValuesSelector';

export const defaultCompoundRoot: ICompoundFilterNode = {
    logicalOperator: CompoundFilterLogicalOperator.OR,
    operands: [
        {
            name: 'rule_severity_name',
            values: ['Critical', 'High'],
            operator: FilterConditionOperator.In,
        },
        {
            logicalOperator: CompoundFilterLogicalOperator.AND,
            operands: [
                {
                    name: 'origin_resolved',
                    values: ['Intelligence'],
                    operator: FilterConditionOperator.In,
                },
                {
                    name: 'env_cloudAccountId_name',
                    values: ['Azure'],
                    operator: FilterConditionOperator.NotIn,
                },
            ],
        },
    ],
};

export const filterDefsExample: IFilterTreeFieldDefinition[] = [
    {
        name: 'rule_severity_name',
        header: 'severity',
        conditionOperatorsInfo: DefaultConditionOperatorsMap.selection,
        conditionValuesComponent: DefaultValuesSelector,
        valueOptions: [
            {
                label: 'Critical',
                value: 'Critical',
            },
            {
                label: 'High',
                value: 'High',
            },
            {
                label: 'Medium',
                value: 'Medium',
            },
            {
                label: 'Low',
                value: 'Low',
            },
            {
                label: 'Informational',
                value: 'Informational',
            }
        ],
    },
    {
        name: 'env_cloudAccountId_name',
        header: 'platform',
        conditionOperatorsInfo: DefaultConditionOperatorsMap.selection,
        conditionValuesComponent: DefaultValuesSelector,
        valueOptions: [
            {
                label: 'AWS',
                value: 'AWS',
            },
            {
                label: 'GCP',
                value: 'GCP',
            },
            {
                label: 'Azure',
                value: 'Azure',
            },
            {
                label: 'Kubernetes',
                value: 'Kubernetes',
            }
        ],
    },
    {
        name: 'origin_resolved',
        header: 'origin',
        conditionOperatorsInfo: DefaultConditionOperatorsMap.selection,
        conditionValuesComponent: DefaultValuesSelector,
        valueOptions: [
            {
                label: 'Intelligence',
                value: 'Intelligence',
            },
            {
                label: 'Compliance Engine',
                value: 'ComplianceEngine',
            },
            {
                label: 'CIEM',
                value: 'CIEM',
            },
            {
                label: 'Serverless',
                value: 'Serverless',
            },
            {
                label: 'Image Assurance',
                value: 'ImageAssurance',
            },
        ],
    }
];

export const compoundFilterExample: ICompoundFilter = {
    root: defaultCompoundRoot,
};

export const aggregationOptionsExample = [
    { label: 'Severity', value: 'rule_severity_name' },
    { label: 'Environment', value: 'env_cloudAccountId_name' },
    { label: 'Rule name', value: 'rule_name' },
    { label: 'source', value: 'origin_resolved' },
];

export const countOptionsExample = [
    { label: 'Severity', value: 'rule_severity_name' },
    { label: 'Environment', value: 'env_cloudAccountId_name' },
    { label: 'Rule name', value: 'rule_name' },
    { label: 'source', value: 'origin_resolved' },
];

export const sortOptionsExample = [
    { label: 'Severity ASC', value: 'rule_severity_name asc' },
    { label: 'Source ASC', value: 'origin_resolved asc' },
    { label: 'Rule name ASC', value: 'rule_name asc' },
];

export const defaultLimit = '10';

export enum GslQueryMode {
    Items = 0,
    Aggregation = 1,
    Count = 2,
}
