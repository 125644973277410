import { buildPath, globalAddinContainer } from 'common/extensibility/AddinContainer';
import { IActiveFeatureInfo } from './ActiveFeatures';
import { Addin } from 'common/extensibility/AddinRegistry';

const PATH_ERM_EXPERIMENTS_PREFIX = buildPath('erm experiments', 'active features');
export const ActiveFeaturesRegistry = {
    getAllActiveFeaturesInfos: () => globalAddinContainer.get<IActiveFeatureInfo>(PATH_ERM_EXPERIMENTS_PREFIX),

    addActiveFeatureInfos: (activeFeatureInfos: IActiveFeatureInfo[]) => {
        const activeFeatureItemsAddIns: Addin<IActiveFeatureInfo>[] = activeFeatureInfos.map((item) => ({ id: item.key, content: item }));
        globalAddinContainer.add<IActiveFeatureInfo>(PATH_ERM_EXPERIMENTS_PREFIX, activeFeatureItemsAddIns);
    },
};
