import { getUserService } from '../../../interface/services';
import { OriginType } from '../Intelligence.const';
import { getVendor, IVendor, Vendors } from '../../../consts/vendors';
import { ALERT_ACTIONS } from './Remediation.const';
import { IFinding } from '../Findings/Findings.interface';

/**
 * Checks if the user has the 'ALERT_ACTIONS' permission.
 *
 * @returns {boolean} Returns true if the user has the 'ALERT_ACTIONS' permission, otherwise false.
 */
const isAlertActionEnabled = (): boolean => {
    return getUserService().hasAnyPermission([ALERT_ACTIONS]);
};

/**
 * Determines if remediation is supported for a given finding.
 *
 * @param {IFindingModel} finding - The finding to check for remediation support.
 *
 * @returns {boolean} Returns true if remediation is supported, otherwise false.
 *
 * The function first checks if the finding has a 'cloudAccountType'. If it does not, the function returns false.
 *
 * Then, it retrieves the vendor associated with the 'cloudAccountType' of the finding.
 * If no vendor is found, the function returns false.
 *
 * Next, it checks if the user has the 'SUPER_USER' role or 'alertActions' permission.
 * If the user does not have these permissions, the function returns false.
 *
 * Finally, it checks the origin of the finding. If the finding originates from 'CIEM' and the vendor is 'AWS',
 * or if the finding originates from 'ComplianceEngine' and the vendor is not 'OCI', the function returns true.
 * In all other cases, the function returns false.
 */
export const isRemediationSupported = (finding: IFinding): boolean => {
    if (!finding.cloudAccountType) return false;
    const vendor: IVendor | null = getVendor(finding.cloudAccountType);
    if (!vendor) return false;

    if (!getUserService().getIsSuperUser() && !isAlertActionEnabled()) return false;

    const isCiemFinding: boolean = finding.origin === OriginType.CIEM;
    const isAwsCiemFinding: boolean = isCiemFinding && vendor.name === Vendors.AWS;

    const isComplianceFinding: boolean = finding.origin === OriginType.ComplianceEngine;
    const isNonOciComplianceFinding: boolean = isComplianceFinding && vendor.name !== Vendors.OCI;

    return (isAwsCiemFinding || isNonOciComplianceFinding);
};
