import styled from 'styled-components';
import { Label, Stack } from 'common/design-system/components-v2';

const TopDiv = styled(Stack)`
  flex-direction: column;
  width: 100%;
  border-radius: ${({ theme }) => `${theme.border.radius(2)}${theme.units}`};
  background: ${({ theme }) => `${theme.palette.surface.brandLight}`};
  padding: 16px 24px;
  min-width: 1100px;
  min-height: 245px;
`;

const Header = styled(Label)`
  width: 100%;
  padding: 10px 0 25px;
`;

const Body = styled(Stack)`
  flex-direction: row;
  height: 100%;
  width: 100%;
`;

const StatusSep = styled.div`
  width: 1px;
  border-left: ${({ theme }) => `1px solid ${theme.palette.border.strong}`};
  margin: 24px;
`;

const BaseHintDiv = styled(Stack)`
  height: 130px;
`;

export const SummaryStyle = {
    TopDiv,
    Header,
    Body,
    StatusSep,
    BaseHintDiv,
};
