import { formatNumberShorthand, numberWithCommas } from 'common/utils/helpFunctions';
import React from 'react';
import './CountBox.scss';

interface CountBoxProps {
    text?: string;
    number: number;
    color?: string;
    bg?: string;
    size?: string;
    isAddCommas?: boolean;
    isAddShorthand?: boolean;
    onClick?: () => any;
}

export const CountBox: React.FC<CountBoxProps> = ({ text, number, color, bg, size, isAddCommas, isAddShorthand, onClick }) => {
    let displayNumber = number ? String(number) : '0';
    if (number) {
        if (isAddShorthand) {
            displayNumber = formatNumberShorthand(number);
        } else if (isAddCommas) {
            displayNumber = numberWithCommas(number);
        }
    }

    return (
        <div className={`count-box ${size} ${onClick ? 'cursor-pointer' : ''}`} style={{ backgroundColor: bg ? `${bg}` : '' }} onClick={onClick}>
            <div className='number'>{displayNumber}</div>
            {text && <div className='text'>{text}</div>}
            <div className='line' style={{ backgroundColor: `${color}` }}></div>
        </div>
    );
};

export default CountBox;
