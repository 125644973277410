import React from 'react';
import { ICellRendererParams } from 'ag-grid-enterprise';
import dayjs from 'dayjs';
import i18n from 'i18next';
import { EMPTY_STRING } from '../../../consts/GeneralConsts';

const CreatedTimeCellRender: React.FC<ICellRendererParams> = (params) => {
    const createdTime = params.value;
    if (createdTime) {
        return dayjs(createdTime).format(i18n.t('COMMON.DATE_FORMATS.LONG_FORMAT'));
    } else {
        return EMPTY_STRING;
    }
};
export default CreatedTimeCellRender;
