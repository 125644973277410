import React, { FC, ReactElement, useMemo, useRef } from 'react';
import { ICustomFormDialogProps } from '../../../../../components/GlobalModals/GlobalModals';
import { CustomForm } from '../../CustomForm';
import { Modal } from '../../../../../design-system/components-v2';
import { IFullCustomFormProps } from '../../CustomForm.interface';
import { DialogFormStyle } from './CustomFormDialog.styled';

export interface IFullCustomFormDialogProps extends ICustomFormDialogProps {
    onClose: () => void;
}

export const CustomFormDialog: FC<IFullCustomFormDialogProps> = ({ onClose, formProps, data }) => {
    const fullFormProps: IFullCustomFormProps = useMemo(() => ({
        formProps,
        data,
        onClose,
    }), [data, onClose, formProps]);
    const componentRef = useRef<ReactElement>(React.createElement(CustomForm, fullFormProps));

    return (
        <DialogFormStyle.TopModal isOpen={true} width={'xl'} shouldCloseOnOverlayClick={false} onRequestClose={onClose} >
            <Modal.ModalHeader title={formProps.title} onClose={onClose} />
            <DialogFormStyle.ContentDiv isShortDialog={formProps.isShortDialog} minHeight={formProps.minDialogHeight}>
                {componentRef.current}
            </DialogFormStyle.ContentDiv>
        </DialogFormStyle.TopModal>
    );
};
