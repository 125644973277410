import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { IFiltersValues } from '../FilterPanel.interface';
import { getStoreService } from '../../../interface/services';

interface FilterPanelExamplePageState {
    filterValues: { [key: string]: any };
}

const initialState: FilterPanelExamplePageState = {
    filterValues: {} as { [key: string]: any },
};

export const FilterPanelExamplePageSlice = createSlice({
    name: 'FilterPanelExamplePageSlice',
    initialState,
    reducers: {
        setFiltersValues: (state: FilterPanelExamplePageState, action: PayloadAction<IFiltersValues>) => {
            state.filterValues = action.payload;
        },
    },
});

export const { setFiltersValues } = FilterPanelExamplePageSlice.actions;

export const getFiltersValues = (): IFiltersValues => {
    return getStoreService().state.FilterPanelExamplePageSlice.filterValues;
};

export default FilterPanelExamplePageSlice.reducer;
