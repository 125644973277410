import { getProtectedAssetsService, IProtectedAssetDetailProvider } from './ProtectedAssets';
import { InfoPaneFieldProps } from '../../components/InfoPane/InfoPane';
import { ICloudEntityData } from './ICloudEntity';
import i18next from 'i18next';
import EnvironmentLink from '../../components/ProtectedAssets/Renderers/EnvironmentLink';
import { getRegionByName, getVendorDisplayName } from '../../consts/vendors';
import { isString } from '../../utils/helpFunctions';
import { Chip, ComponentOverflow } from 'common/design-system/components-v2';

export const ASSETS_SEARCH_URL = 'protected-asset/search';
export const ASSETS_GROUPING_URL = 'protected-asset/group-by-properties';

export const ASSETS_EXPORT_CSV_EMAIL = 'protectedAssetsReport/exportToCsv';
export const ASSETS_EXPORT_CSV_DOWNLOAD = 'protectedAssetsReport/exportToCsv/downloadCSV';

export interface IAssetFieldsMap {
    nameField: IProtectedAssetDetailProvider<InfoPaneFieldProps>;
    typeField: IProtectedAssetDetailProvider<InfoPaneFieldProps>;
    idField: IProtectedAssetDetailProvider<InfoPaneFieldProps>;
    internalIdField: IProtectedAssetDetailProvider<InfoPaneFieldProps>;
    createdDateField: IProtectedAssetDetailProvider<InfoPaneFieldProps>;
    environmentField: IProtectedAssetDetailProvider<InfoPaneFieldProps>;
    vpcField: IProtectedAssetDetailProvider<InfoPaneFieldProps>;
    regionField: IProtectedAssetDetailProvider<InfoPaneFieldProps>;
    descriptionField: IProtectedAssetDetailProvider<InfoPaneFieldProps>;
    platformField: IProtectedAssetDetailProvider<InfoPaneFieldProps>;
    organizationalUnitField: IProtectedAssetDetailProvider<InfoPaneFieldProps>;
    tagsField: IProtectedAssetDetailProvider<InfoPaneFieldProps>;
}

export const AssetFieldsMap: IAssetFieldsMap = {
    nameField: {
        isRelevant: ({ protectedAsset }) => {
            return !!protectedAsset.name;
        },
        getValue({ protectedAsset }): InfoPaneFieldProps {
            return {
                title: i18next.t('COMMON.PROTECTED_ASSET.DETAILS_PANE.FIELDS.NAME'),
                value: protectedAsset.name,
            };
        },
    },

    typeField: {
        isRelevant: true,
        getValue({ typeByPlatform }): InfoPaneFieldProps {
            const asset = getProtectedAssetsService().getAssetByType(typeByPlatform);
            return {
                title: i18next.t('COMMON.PROTECTED_ASSET.DETAILS_PANE.FIELDS.TYPE'),
                value: asset?.displayName || '',
            };
        },
    },

    idField: {
        isRelevant: true,
        getValue({ entityId }): InfoPaneFieldProps {
            return {
                title: i18next.t('COMMON.PROTECTED_ASSET.DETAILS_PANE.FIELDS.ID'),
                value: entityId,
            };
        },
    },

    internalIdField: {
        isRelevant: true,
        getValue({ protectedAsset }): InfoPaneFieldProps {
            return {
                title: i18next.t('COMMON.PROTECTED_ASSET.DETAILS_PANE.FIELDS.INTERNAL_ID'),
                value: protectedAsset.id,
            };
        },
    },

    createdDateField: {
        isRelevant: ({ cloudEntity }) => {
            return cloudEntity.createDate ?? cloudEntity.createTime ?? cloudEntity.creationDate ?? cloudEntity.createdDate ?? cloudEntity.createdAt ?? cloudEntity.creationTime !== undefined;
        },
        getValue({ cloudEntity }): InfoPaneFieldProps {
            //Bonus point for naming consistency at the backend :/
            const data = cloudEntity.createDate ?? cloudEntity.createTime ?? cloudEntity.creationDate ?? cloudEntity.createdDate ?? cloudEntity.createdAt ?? cloudEntity.creationTime;
            let displayDate = '';
            if (typeof data === 'number') {
                displayDate = i18next.t('COMMON.DATE_TIME', {
                    value: new Date(data * 1000),
                    formatParams: {
                        value: { weekday: 'long', year: 'numeric', month: 'long', day: 'numeric' },
                    },
                    interpolation: { escapeValue: false },
                });
            } else {
                displayDate = String(data);
            }
            return {
                title: i18next.t('COMMON.PROTECTED_ASSET.DETAILS_PANE.FIELDS.CREATED_DATE'),
                value: displayDate,
            };
        },
    },

    environmentField: {
        isRelevant: true,
        getValue({ cloudAccountId }): InfoPaneFieldProps {
            return {
                title: i18next.t('COMMON.PROTECTED_ASSET.DETAILS_PANE.FIELDS.ENVIRONMENT'),
                value: EnvironmentLink,
                componentProps: { cloudAccountId },
            };
        },
    },

    vpcField: {
        isRelevant: ({ cloudEntity }) => {
            return (isString(cloudEntity.vpc) && cloudEntity.vpc !== '') || (cloudEntity.vpc?.id ?? cloudEntity.vpcId !== undefined);
        },
        getValue({ cloudEntity }): InfoPaneFieldProps {

            const vpc = isString(cloudEntity.vpc) ? cloudEntity.vpc : (cloudEntity.vpc?.id ?? cloudEntity.vpcId);
            return {
                title: i18next.t('COMMON.PROTECTED_ASSET.DETAILS_PANE.FIELDS.VPC'),
                value: vpc,
            };
        },
    },

    regionField: {
        isRelevant: ({ protectedAsset, cloudEntity }) => {
            const regions = getRegionByName(protectedAsset.region || cloudEntity.region, protectedAsset.platform);
            return regions.length > 0;
        },
        getValue({ protectedAsset, cloudEntity }): InfoPaneFieldProps {
            const assetRegion = protectedAsset.region || cloudEntity.region;
            const regions = getRegionByName(assetRegion, protectedAsset.platform);
            let value = i18next.t('COMMON.PROTECTED_ASSET.DETAILS_PANE.UNKNOWN');
            if (regions.length === 1) {
                value = `${regions[0].region.description} (${assetRegion})`;
            }
            return {
                title: i18next.t('COMMON.PROTECTED_ASSET.DETAILS_PANE.FIELDS.REGION'),
                value,
            };
        },
    },

    descriptionField: {
        isRelevant: ({ cloudEntity }) => {
            return !!cloudEntity.description;
        },
        getValue({ cloudEntity }): InfoPaneFieldProps {
            return {
                title: i18next.t('COMMON.PROTECTED_ASSET.DETAILS_PANE.FIELDS.DESCRIPTION'),
                value: cloudEntity.description,
            };
        },
    },

    platformField: {
        isRelevant: (entityData: ICloudEntityData) => {
            return !!entityData.protectedAsset;
        },
        getValue(entityData: ICloudEntityData): InfoPaneFieldProps {
            return {
                title: i18next.t('COMMON.PROTECTED_ASSET.DETAILS_PANE.FIELDS.PLATFORM'),
                value: entityData.protectedAsset ? getVendorDisplayName(entityData.protectedAsset.platform) : '',
            };
        },
    },

    organizationalUnitField: {
        isRelevant: (entityData: ICloudEntityData) => {
            return !!entityData.organizationalUnitFullPath;
        },
        getValue(entityData: ICloudEntityData): InfoPaneFieldProps {
            return {
                title: i18next.t('COMMON.PROTECTED_ASSET.DETAILS_PANE.FIELDS.ORGANIZATIONAL_UNIT'),
                value: entityData.organizationalUnitFullPath || '',
            };
        },
    },

    tagsField: {
        isRelevant: ({ cloudEntity }) => {
            if (!cloudEntity.tags) return false;
            const isArrayTags = Array.isArray(cloudEntity.tags);
            return isArrayTags ? cloudEntity.tags.length > 0 : Object.keys(cloudEntity.tags).length > 0;
        },
        getValue({ cloudEntity }): InfoPaneFieldProps {
            const chips: string[] = [];
            const isArrayTags = Array.isArray(cloudEntity.tags);

            if (isArrayTags) {
                cloudEntity.tags.forEach((tag: any) => {
                    chips.push(`${tag.key}: ${tag.value}`);
                });
            } else {
                Object.keys(cloudEntity.tags).forEach((key) => {
                    chips.push(`${key}: ${cloudEntity.tags[key]}`);
                });
            }
            return {
                title: i18next.t('COMMON.PROTECTED_ASSET.DETAILS_PANE.FIELDS.TAGS'),
                value: ComponentOverflow,
                componentProps: {
                    components: chips.map((chip) => (
                        <Chip key={chip} label={chip} />
                    ))
                }
            };
        },
    },
};