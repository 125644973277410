import React, { useCallback, useMemo } from 'react';
import { VolumeStyled } from './DataVolume.styled';
import SimpleText from 'common/components/ProtectedAssets/Renderers/SimpleText';
import { IDataVolumeInfo } from './DataVolume.interface';

const DEFAULT_MAX_LEVEL = 5;
const LOW_OPACITY = 0.3;

const DataVolume: React.FC<{ dataInfo: IDataVolumeInfo }> = ({ dataInfo }) => {
    const maxLevel = useMemo(() => dataInfo?.maxLevel ?? DEFAULT_MAX_LEVEL, [dataInfo]);

    const createSingleBar = useCallback((index: number) => {
        const bg = dataInfo.color;
        const opacity = (index > dataInfo.zeroBasedIndex ? LOW_OPACITY : 1);
        const currHeightPercentage = 100 * (index + 1) / maxLevel;
        return <VolumeStyled.VerticalBarDiv key={index} bg={bg} opacity={opacity} heightPercentage={currHeightPercentage} />;
    }, [dataInfo, maxLevel]);

    const getAllBars = useCallback(() => {
        const bars = [];
        for (let index = 0; index < maxLevel; index++) {
            bars.push(createSingleBar(index));
        }
        return bars;
    }, [createSingleBar, maxLevel]);

    return (
        <VolumeStyled.TopDiv>
            <VolumeStyled.BarsBox>
                {getAllBars()}
            </VolumeStyled.BarsBox>
            <VolumeStyled.TextDiv>
                <SimpleText value={dataInfo.text} />
            </VolumeStyled.TextDiv>
        </VolumeStyled.TopDiv>
    );
};

export default DataVolume;
