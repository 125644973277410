import { Aggregations } from 'common/components/FilterPanel/FilterPanel.interface';
import { ColDef } from 'ag-grid-enterprise';
import { EXTERNAL_ADDITIONAL_FIELDS_SOURCE } from 'common/components/ProtectedAssets/ProtectedAssetsTable.consts';
import { Direction, FilterField, SortingModel } from '../Findings/Findings.interface';
import { IAsset, UrlFuncResult } from '../../assets/common.assets';
import { IFindingSeverityCounters } from '../../consts/FindingSeverity';
import { SortDirection } from 'common/interface/general';
import { IIssueSeverityCounters } from '../../module_interface/RiskManagement/issues/Issues.interface';

export interface ICsvExportConfig {
    title: string;
    subTitles?: string[];
    order: number;

    calculateValue(data: any): string | string[] | null | undefined | Promise<string | null | undefined>;
}

export interface CGColDef<T=any> extends ColDef<T> {
    sortField?: string;
    csvExport?: ICsvExportConfig;
    columnType?: ColumnType;
    additionalFieldSource?: ColumnAdditionalFieldSource;
    groupFieldGetter?: (data: GroupByResponseItem) => string | undefined;
    permission?: () => boolean;
    groupOrder?: SortDirection;
}

export type AgGridSortModel = {
    colId: string;
    sort: 'asc' | 'desc';
};

export type IFilterFieldsProps = {
    name: string;
    value: string;
}

export type RequestFilter = {
    freeTextPhrase?: string;
    fields?: string[] | IFilterFieldsProps[];
    tags?: string[];
    includedEntityTypes?: string[];
    excludedEntityTypes?: string[] | null;
};

export type SearchRequest = {
    pageSize: number;
    aggregations?: string[];
    skipAggregations?: boolean;
    sorting?: SortingModel;
    filter: RequestFilter;
    searchAfter?: string[];
    additionalFields?: {
        source?: string | null;
        filterFields?: string[];
        sortField?: SortingModel;
    };
    externalAdditionalFields?: IAdditionalFieldsRequest;
    optionalAggregationFields?: string[];
    filterEntitiesWithoutRiskScore?: boolean;
    lowAggregationsSize?:boolean;
    includedEntityTypes?: string[];
};

export type GroupByProperty = {
    property: string;
    direction: Direction;
    maxSize: number;
    sortOption: 'AggKey' | 'BucketsCount';
    aggregateMaxRiskScore: boolean;
};

export type GroupByAdditionalFields = {
    source?: string | null;
    filterFields?: string[];
    sortField?: SortingModel;
}

export type GroupByRequest = {
    filter: RequestFilter;
    propertiesList: GroupByProperty[];
    additionalFields?: GroupByAdditionalFields;
    filterEntitiesWithoutRiskScore?: boolean;
};

export interface IProtectedAssetTag {
    key: string;
    value: string;
}

export interface IProtectedAssetAdditionalField {
    name: string;
    value: string;
}

export interface IProtectedAssetExternalAdditionalField extends IProtectedAssetAdditionalField {
    source: string;
}

export interface IVulnerabilityCounters extends IFindingSeverityCounters {
    all: number;
    unknown: number;
}

export interface IProtectedAssetViewModel {
    isGrouped?: boolean;
    id: string;
    entityId: string;
    externalCloudAccountId: string;
    cloudAccountId: string;
    srl?: string;
    type: string;
    name: string;
    tags?: IProtectedAssetTag[];
    platform: string;
    typeByPlatform: string;
    network?: string;
    region: string;
    resourceGroup: string;
    compartment: string;
    additionalFields: IProtectedAssetAdditionalField[];
    externalAdditionalFields?: IProtectedAssetExternalAdditionalField[];
    assetLabelsList: string[];
    riskScore?: number;
    postureFindings?: IFindingSeverityCounters;
    businessPriority: string;
    cvEs?: IFindingSeverityCounters;
    malware?: number;
    malwarePerSeverity?: IFindingSeverityCounters;
    secrets?: number;
    secretsPerSeverity?: IFindingSeverityCounters;
    context?: {
        networkExposure: string,
        iamExposure?: string,
        iamSensitivity?: number,
        dataSensitivity?: string,
        dataClassifications?: string[],
        wafProtection?: string,
        openSecurityIssues?: IIssueSeverityCounters,
    };
    vulnerability?: {
        scanStatus?: string,
        scanStatusDetails?: string,
        stats?: {
            cve: IVulnerabilityCounters,
            cveFixable: IVulnerabilityCounters,
            secret: IVulnerabilityCounters,
            threat: IVulnerabilityCounters,
        }
    };
}

export interface IAssetUrlRequiredProps {
    entityId: string;
    platform: string;
    type: string;
    cloudAccountId: string;
    region: string;
    typeByPlatform?: string;
    dome9Id?: string;
    generateUrl?: (asset: IAsset, assetUrl: UrlFuncResult) => string;
}

export type ProtectedAssetsResponse = {
    searchRequest?: SearchRequest;
    assets: IProtectedAssetViewModel[];
    totalCount: number;
    aggregations: Aggregations;
    searchAfter?: string[];
};

export type GroupByResponseItem = {
    fieldName: string;
    fieldValue: any;
    numberOfDocuments: number;
    nestedBuckets: GroupByResponse;
    riskScore?: number;
}

export type GroupByResponse = GroupByResponseItem[];

export interface IAdditionalFieldsRequest {
    source?: EXTERNAL_ADDITIONAL_FIELDS_SOURCE,
    filterFields?: FilterField[],
    sortField?: SortingModel
}

export enum ColumnType {
    Additional = 'Additional',
    External = 'External'
}

export enum ColumnAdditionalFieldSource {
    KubernetesImage = 'KubernetesImage',
    AWP = 'AWP',
    ThirdPartyMs = 'ThirdPartyMs'
}
