import React, { forwardRef } from 'react';
import { ITrendProps } from './Trend.types';
import Chip from '../Chip/Chip';
import { IChipProps } from '../Chip/Chip.types';
import { useTranslation } from 'react-i18next';
import { getCompsI18nNS } from 'common/design-system/initialize.i18n';
import { AllIconsName } from '../Icon/Icon.types';

const Trend = forwardRef<HTMLDivElement, ITrendProps>((props, ref) => {
    const { percentage = 0, reverseLogic, maximumFractionDigits = 2, removePercentageSign, size = 'xs', ...rest } = props;
    const { t } = useTranslation(getCompsI18nNS('common'));

    const color: IChipProps['color'] = React.useMemo<IChipProps['color']>(() => {
        if (percentage === 'N/A') {
            return 'normal';
        }
        if (percentage === 0) {
            return 'brandPrimary';
        }
        if (reverseLogic) {
            return percentage >= 0 ? 'alert' : 'success';
        }
        return percentage >= 0 ? 'success' : 'alert';
    }, [percentage, reverseLogic]);

    const label = React.useMemo(() => {
        if (percentage === 'N/A') {
            return t('NA');
        }
        return `${percentage.toLocaleString(undefined, { maximumFractionDigits })}${removePercentageSign ? '' : '%'}`;
    }, [percentage, maximumFractionDigits, removePercentageSign, t]);

    const iconName = React.useMemo<AllIconsName | undefined>(() => {
        if (percentage === 'N/A') {
            return undefined;
        }
        if (percentage === 0) {
            return 'arrowRight';
        }
        return percentage >= 0 ? 'arrowUpRight' : 'arrowDownRight';
    }, [percentage]);

    return (
        <Chip color={color} label={label} trailIconProps={iconName && { name: iconName, size: 8 }} ref={ref} size={size} {...rest} />
    );
});
Trend.displayName = 'Trend';

export default Trend;

