import { getUserService } from '../interface/services';
import dayjs from 'dayjs';
import {
    ActiveFeatureTopicEnum,
    IActiveFeatureInfo
} from '../module_interface/RiskManagement/activeFeatures/ActiveFeatures';
import { ActiveFeaturesRegistry } from '../module_interface/RiskManagement/activeFeatures/ActiveFeaturesRegistry';

enum DebugActiveFeatures {
    DRAWER_EXAMPLE_ACTIVE_FEATURE = 'DRAWER_EXAMPLE_ACTIVE_FEATURE',
    ERM_COMPONENTS_EXAMPLES_ACTIVE_FEATURE = 'ERM_COMPONENTS_EXAMPLES_ACTIVE_FEATURE',
    SHOW_LOGGER_EVENTS_ACTIVE_FEATURE = 'SHOW_LOGGER_EVENTS_ACTIVE_FEATURE',
    SHOW_URL_LOG_ACTIVE_FEATURE = 'SHOW_URL_LOG_ACTIVE_FEATURE',
    DEBUG_FILTERS_ACTIVE_FEATURE = 'DEBUG_FILTERS_ACTIVE_FEATURE',
    DEBUG_ACTIONS_ACTIVE_FEATURE = 'DEBUG_ACTIONS_ACTIVE_FEATURE',
    SHOW_HIDDEN_ACTIVE_FEATURES = 'SHOW_HIDDEN_ACTIVE_FEATURES',
}

export const DebugActiveFeaturesMap: { [key in DebugActiveFeatures]: IActiveFeatureInfo } = {
    DRAWER_EXAMPLE_ACTIVE_FEATURE: {
        key: 'drawer-example',
        description: 'If enabled, a new menu will be added with a full Drawer example page',
        topic: ActiveFeatureTopicEnum.EXAMPLES,
    },

    ERM_COMPONENTS_EXAMPLES_ACTIVE_FEATURE: {
        key: 'erm-components-examples',
        description: 'If enabled, a new menu will be added with erm-components examples',
        topic: ActiveFeatureTopicEnum.EXAMPLES,
    },

    SHOW_LOGGER_EVENTS_ACTIVE_FEATURE: {
        key: 'show-logger-events',
        description: 'If enabled, all the logger events will be printed to console',
        topic: ActiveFeatureTopicEnum.LOG,
    },

    SHOW_URL_LOG_ACTIVE_FEATURE: {
        key: 'show-url-log',
        description: 'If enabled, an event with url details will be printed to console upon each url change',
        topic: ActiveFeatureTopicEnum.LOG,
    },

    DEBUG_FILTERS_ACTIVE_FEATURE: {
        key: 'debug-filters',
        description: 'If enabled, some outputs from the filtering process will be printed to console',
        topic: ActiveFeatureTopicEnum.DEV,
    },

    DEBUG_ACTIONS_ACTIVE_FEATURE: {
        key: 'debug-actions',
        description: 'If enabled, it introduces some debug actions related to customization api',
        hidden: true,
        topic: ActiveFeatureTopicEnum.DEV,
    },
    SHOW_HIDDEN_ACTIVE_FEATURES: {
        key: 'hidden-active-features',
        description: 'If enabled, Active Features Manager page will show also hidden active features',
        topic: ActiveFeatureTopicEnum.DEV,
    },
};


const activeFeaturesMap: { [key: string]: boolean } = {};

//Do not remove, used for debugging
export function debugLog(...args: any[]) {
    const timestamp = dayjs(new Date()).format('DD/MM/YY HH:mm:ss.SSS');
    console.log(`### [${timestamp}]`, ...args);
}
export const isAfEnabled = (activeFeatureName: string): boolean => {
    if (activeFeaturesMap[activeFeatureName] === undefined) {
        const storageValue = localStorage.getItem(activeFeatureName);
        if (storageValue) {
            activeFeaturesMap[activeFeatureName] = (storageValue.toLowerCase() === 'true');
        } else {
            const userService = getUserService();
            activeFeaturesMap[activeFeatureName] = userService ? userService.hasPermission([activeFeatureName]) : false;
        }
    }
    return activeFeaturesMap[activeFeatureName];
};

export const isAfShowLoggerEvents = () => isAfEnabled(DebugActiveFeaturesMap.SHOW_LOGGER_EVENTS_ACTIVE_FEATURE.key);
export const isAfPageDrawerExample = () => isAfEnabled(DebugActiveFeaturesMap.DRAWER_EXAMPLE_ACTIVE_FEATURE.key);
export const isAfErmComponentsExamples = () => isAfEnabled(DebugActiveFeaturesMap.ERM_COMPONENTS_EXAMPLES_ACTIVE_FEATURE.key);

export const initializeDebugActiveFeatures = () => {
    ActiveFeaturesRegistry.addActiveFeatureInfos(Object.values(DebugActiveFeaturesMap));
};
