import { IItem, IItemsMap } from '../../custom/CustomForm/CustomForm.interface';
import { isEmptyItemValue } from '../../custom/CustomForm/CustomForm.values';
import { RecipeItemNames } from './RecipeEditor.consts';

export const checkIngredientsValidation = async (item: IItem, itemsMap: IItemsMap): Promise<string | undefined> => {
    if (isEmptyItemValue(itemsMap[RecipeItemNames.meatTypes]) &&
        isEmptyItemValue(itemsMap[RecipeItemNames.meatAlternatives]) &&
        isEmptyItemValue(itemsMap[RecipeItemNames.dairyProducts])) {
        return 'A recipe has to include at least one meat, fish, dairy or meat alternative';
    }
};
