import React, { useEffect } from 'react';
import { getNotificationPageService } from 'common/interface/services';
import { Vendors } from 'common/consts/vendors';
import { useTranslation } from 'react-i18next';
import { ListItem } from './MultiSelectList/ListItemTypes/interfaces';
import { MultiSelectListComponent } from './MultiSelectList';
import { OnValidate } from '../Wizard/Wizard';
import { Button } from '@dome9/berries/react-components';
import { Stack } from '../../design-system/components-v2';
import { globalAddinContainer } from '../../extensibility/AddinContainer';

export interface PlatformNotificationsSelectionProps{
    onValidate:OnValidate;
    platform?: Vendors;
    selectedNotificationsChanged:Function;
    preSelectedItems?: ListItem[];
    canBeValidOnNonSelection?: boolean;
}
export const NotificationsSelectionComponent: React.FC<PlatformNotificationsSelectionProps> = ({ canBeValidOnNonSelection = false, preSelectedItems, onValidate, selectedNotificationsChanged }) => {
    const { t } = useTranslation();
    const [openModal,setOpenModal] = React.useState<boolean>(false);
    const notificationModal = globalAddinContainer.get<any>('notificationModal')[0];
    const modalElement = React.createElement(notificationModal,
        { isOpen: openModal,
            onClose: () =>{
                setOpenModal(false);
            } ,
            onSave: () =>{
                setOpenModal(false);
                getNotifications();
            } });

    const[notifications, setNotifications] = React.useState<ListItem[]>([]);
    useEffect(() => {
        getNotifications();
    }, []);
    useEffect(() => {
        if(canBeValidOnNonSelection){
            onValidate(true);
            return;
        }
        onValidate(!!preSelectedItems?.length);
    }, [canBeValidOnNonSelection, onValidate, preSelectedItems?.length]);
    const getNotifications = async () => {
        const notifications = await getNotificationPageService().getAllNotificationsSlim(false);
        
        const shallowNotifications : ListItem[] = notifications.map((ruleset) => {
            return {
                id: ruleset.id.toString(),
                name: ruleset.name,
            };
        });
        setNotifications(shallowNotifications);
    };

    const openNotificationModal= () => {
        setOpenModal(true);
    };

    const selectedItemsChanged = (selectedItems: ListItem[]) => {
        if(canBeValidOnNonSelection){
            onValidate(true);
            return;
        }
        onValidate(selectedItems?.length > 0);
        selectedNotificationsChanged(selectedItems);
    };

    return (
        <span data-aid={'notification-selection'}>
            <Stack>
                <Button dataAid={'add-notification'} onClick={openNotificationModal} className={'self-end'}>{t('GENERAL.ADD_NOTIFICATION')}</Button>
                <MultiSelectListComponent preSelectedItems={preSelectedItems} header={t('GENERAL.SELECT_NOTIFICATION')} items={notifications}
                    onSelectedItemsChanged={selectedItemsChanged}/>
            </Stack>
            {modalElement}
        </span>
    );

};