import React from 'react';
import i18n from 'i18next';
import { ColDef, ColumnApi, ColumnState, GridApi, GridOptions, GridReadyEvent, ICellRendererParams, ITooltipParams, SelectionChangedEvent } from 'ag-grid-community';
import { AgGridReact } from 'ag-grid-react';
import { Trans, useTranslation } from 'react-i18next';
import Stack from '../Stack';
import { getCompsI18nNS } from 'common/design-system/initialize.i18n';
import TableStyles from './Table.styles';
import Typography from '../Typography/Typography';
import { ITableProps } from './Table.types';
import ColumnsSelect from './components/ColumnsSelect/ColumnsSelect';
import Button from '../Button/Button';
import GroupBySelect from './components/GroupBySelect/GroupBySelect';
import TooltipStyled from 'common/design-system/components-v2/Tooltip/Tooltip.styled';
import Dropdown from '../Dropdown/Dropdown';
import List from '../List/List';
import Actions from './components/Actions';

const DefaultTooltip = ({ value, valueFormatted }: ITooltipParams) => {
    return <TooltipStyled.StyledTooltip>{valueFormatted || value}</TooltipStyled.StyledTooltip>;
};

const CustomLoadingOverlay = () => {
    const loading = i18n.t('COMMON.PROTECTED_ASSETS_TABLE.LOADING');
    return (<div data-aid='loading-overlay'>
        {loading}
    </div>);
};

const CustomLoadingCellRenderer = (props: ICellRendererParams) => {
    let loadingString = i18n.t('COMMON.PROTECTED_ASSETS_TABLE.LOADING');
    if (props.node?.failedLoad) {
        loadingString = i18n.t('COMMON.PROTECTED_ASSETS_TABLE.LOAD_ERROR');
    }
    return (
        <div
            className='ag-custom-loading-cell'
            style={{ paddingLeft: '10px', lineHeight: '25px' }}
        >
            <i className='fas fa-spinner fa-pulse'></i>{' '}
            <span> {loadingString} </span>
        </div>
    );
};

const defaultPageSize = 50;
const tableLocalStorageKey = 'tableColumnsState';
function Table<T = any>(props: React.PropsWithChildren<ITableProps<T>>) {
    const {
        tableId,
        saveColumnsState,
        pageSize = defaultPageSize,
        actions = [],
        exportButtons,
        gridOptions,
        footer,
        disableColumnMenu = false,
        disableGrouping = false,
        appliedFilters,
        disableRowsResetOnRowDataChange,
        disableRowsResetOnFilterChange,
        isLoading,
    } = props;

    React.useEffect(() => {
        if (saveColumnsState && !tableId) {
            throw new Error('Table component: You must provide a tableId when saveColumnsState is true');
        }
    }, [tableId, saveColumnsState]);

    const LOCAL_STORAGE_COLUMNS_SAVED_STATE = `ENVIRONMENTS_TABLE_${tableId}__COLUMNS_STATE`;

    const {
        onGridReady,
        onSelectionChanged,
        defaultColDef,
        onSortChanged,
        onColumnResized,
        onColumnMoved,
        onColumnVisible,
        onColumnRowGroupChanged,
        onFilterChanged,
        ...restGridOptions
    } = gridOptions || {};

    const { t } = useTranslation(getCompsI18nNS('table'));
    const apiRef = React.useRef<GridApi>();
    const columnApiRef = React.useRef<ColumnApi>();
    const [selectedRows, setSelectedRows] = React.useState<any[]>([]); // change to T
    const [gridReady, setGridReady] = React.useState<boolean>(false);
    const [exportOpen, setExportOpen] = React.useState<boolean>(false);
    
    const appliedFiltersString = React.useRef<string>(appliedFilters ? JSON.stringify(appliedFilters) : '');
    const rowDataString = React.useRef<string>(gridOptions?.rowData ? JSON.stringify(gridOptions.rowData) : '');

    const loadingCellRenderer = React.useMemo(() => {
        return CustomLoadingCellRenderer;
    }, []);

    const loadingOverlayComponent = React.useMemo(() => {
        return CustomLoadingOverlay;
    }, []);

    const handleOnSelectionChanged = (params: SelectionChangedEvent<any>) => {
        const selectedItems = params.api.getSelectedRows();
        onSelectionChanged?.(params);
        setSelectedRows(selectedItems);
    };

    const getCurrentSavedState = React.useCallback<() => { [tableId: string]: ColumnState[] | undefined }>(() => {
        if (!tableId || !saveColumnsState) return;
        const columnsSavedState = localStorage.getItem(tableLocalStorageKey);
        const parsedColumnsSavedState = columnsSavedState ? JSON.parse(columnsSavedState) : {};
        return parsedColumnsSavedState;
    }, [saveColumnsState, tableId]);

    const saveColumnsStateToLocalStorage = React.useCallback(() => {
        if (!tableId || !saveColumnsState) return;
        const currentColumnsState = columnApiRef.current?.getColumnState();
        const columnsSavedState = getCurrentSavedState();
        columnsSavedState[LOCAL_STORAGE_COLUMNS_SAVED_STATE] = currentColumnsState;
        localStorage.setItem(tableLocalStorageKey, JSON.stringify(columnsSavedState));
    }, [LOCAL_STORAGE_COLUMNS_SAVED_STATE, getCurrentSavedState, saveColumnsState, tableId]);

    const loadSavedColumnsStateFromLocalStorage = React.useCallback(() => {
        if (!tableId || !saveColumnsState) return;
        const columnsSavedState = getCurrentSavedState();
        const columnsState = columnsSavedState[LOCAL_STORAGE_COLUMNS_SAVED_STATE];
        if (!columnsState) return;
        columnApiRef.current?.applyColumnState({ state: columnsState, applyOrder: true });
    }, [LOCAL_STORAGE_COLUMNS_SAVED_STATE, getCurrentSavedState, saveColumnsState, tableId]);

    const handleOnGridReady = React.useCallback(
        (params: GridReadyEvent<any>) => {
            params.api.closeToolPanel();
            apiRef.current = params.api;
            columnApiRef.current = params.columnApi;
            if (saveColumnsState) {
                loadSavedColumnsStateFromLocalStorage();
            }
            
            onGridReady?.(params);
            setGridReady(true);
        },
        [loadSavedColumnsStateFromLocalStorage, onGridReady, saveColumnsState],
    );

    const defaultGridOptions: GridOptions<any> = {
        animateRows: true,
        cacheBlockSize: pageSize,
        rowBuffer: 0,
        maxConcurrentDatasourceRequests: 1,
        infiniteInitialRowCount: 1,
        overlayNoRowsTemplate: i18n.t('COMMON.PROTECTED_ASSETS_TABLE.NO_ITEMS'),
        suppressContextMenu: true,
        blockLoadDebounceMillis: 100,
        defaultColDef: { tooltipComponent: DefaultTooltip, resizable: true, suppressMenu: true, sortable: false, minWidth: 60, enableRowGroup: false, ...(defaultColDef || {}) },
        rowGroupPanelShow: 'never',
        rowMultiSelectWithClick: false,
        enableCellTextSelection: true,
        ensureDomOrder: true,
        skipHeaderOnAutoSize: true,
        enableRangeSelection: false,
        suppressCellFocus: true,
        suppressCopyRowsToClipboard: true,
        suppressRowClickSelection: true,
        suppressPropertyNamesCheck: true,
        detailRowAutoHeight: true,
        suppressDragLeaveHidesColumns: true,
        suppressBrowserResizeObserver: true,
        rowHeight: 48,
        onSelectionChanged: handleOnSelectionChanged,
        isRowSelectable: (node) => !node.group && !node.data?.['customData|unselectable'], // disable selection on group rows or rows with unselectable flag
        onSortChanged: (params) => {
            onSortChanged?.(params);
            params.api.deselectAll();
            saveColumnsStateToLocalStorage();
        },
        onColumnResized: (params) => {
            onColumnResized?.(params);
            saveColumnsStateToLocalStorage();
        },
        onColumnMoved: (params) => {
            onColumnMoved?.(params);
            saveColumnsStateToLocalStorage();
        },
        onColumnVisible: (params) => {
            onColumnVisible?.(params);
            saveColumnsStateToLocalStorage();
        },
        onColumnRowGroupChanged: (params) => {
            onColumnRowGroupChanged?.(params);
            params.api.deselectAll();
        },
        onFilterChanged: (params) => {
            onFilterChanged?.(params);
            if (!disableRowsResetOnFilterChange) {
                params.api.deselectAll();
            }
        },
        ...restGridOptions
    };

    React.useEffect(() => {
        const newRowDataString = gridOptions?.rowData ? JSON.stringify(gridOptions.rowData) : '';
        if ((newRowDataString !== rowDataString.current) && !disableRowsResetOnRowDataChange) {
            apiRef.current?.deselectAll();
            rowDataString.current = newRowDataString;
        }
    }, [gridOptions?.rowData, disableRowsResetOnRowDataChange]);

    React.useEffect(() => {
        const newAppliedFiltersString = appliedFilters ? JSON.stringify(appliedFilters) : '';
        if (newAppliedFiltersString !== appliedFiltersString.current) {
            apiRef.current?.deselectAll();
            appliedFiltersString.current = newAppliedFiltersString;
        }
    }, [appliedFilters]);

    const hasRowSelection = React.useMemo(() => {
        return !!gridOptions?.columnDefs?.some((column: ColDef) => column.checkboxSelection);
    }, [gridOptions]);

    const SelectedRowsComponent = React.useCallback(() => {
        if (hasRowSelection) {
            return (
                <Typography>
                    <Trans
                        components={{
                            boldText: <Typography type='key' elementType='span'>.</Typography>,
                            deselectAll: <Button variant='text' color='brandPrimary' removePadding onClick={() => apiRef.current?.deselectAll()}>.</Button>
                        }}
                        i18nKey={`${getCompsI18nNS('table')}:TOOLBAR.SELECTED_ROWS`}
                        values={{ count: selectedRows.length }}
                    />
                </Typography>
            );
        }
        return null;
    }, [hasRowSelection, selectedRows]);

    const toolbarActive = React.useMemo(() => {
        return gridReady && (!disableGrouping || actions.length > 0 || !disableColumnMenu || (exportButtons && exportButtons.length > 0));
    }, [gridReady, actions, disableColumnMenu, disableGrouping, exportButtons]);

    return (
        <TableStyles.Wrapper fullHeight fullWidth>
            {toolbarActive && (
                <Stack justifyContent='space-between' alignItems='center' direction='row' spacing={5} padding={[2,0]} overflow='hidden'>
                    <Actions
                        gridApi={apiRef.current}
                        columnApi={columnApiRef.current}
                        actions={actions}
                        selectedRows={selectedRows}
                    />
                    <Stack alignItems='center' direction='row' spacing={2}>
                        {!disableGrouping && (
                            <GroupBySelect
                                gridApi={apiRef.current}
                                columnApi={columnApiRef.current}
                                isLoading={isLoading}
                            />
                        )}
                        <Stack direction='row' alignItems='center'>
                            {!disableColumnMenu && (
                                <ColumnsSelect
                                    gridApi={apiRef.current}
                                    columnApi={columnApiRef.current}
                                    isLoading={isLoading}
                                />
                            )}
                            {(exportButtons && exportButtons.length > 1) && (
                                <TableStyles.DropdownWrapper>
                                    <Dropdown
                                        label={t('TOOLBAR.EXPORT')}
                                        buttonProps={{ iconProps: { name: 'export' }, dataAid: 'export', disabled: isLoading }}
                                        placement='bottom-start'
                                        onStateChange={(state) => setExportOpen(state)}
                                        open={exportOpen}
                                    >
                                        <List
                                            options={exportButtons.map((exportButton) => ({
                                                label: exportButton.label,
                                                value: exportButton.label,
                                                onClick: (e) => {
                                                    exportButton.onClick(e);
                                                    setExportOpen(false);
                                                },
                                                labelProps: { leadingIconProps: exportButton.icon },
                                                disabled: exportButton.disabled,
                                                tooltip: exportButton.tooltip,
                                            }))}
                                        />
                                    </Dropdown>
                                </TableStyles.DropdownWrapper>
                            )}
                            {(exportButtons && exportButtons.length === 1) && (
                                <Button
                                    variant='text'
                                    disabled={exportButtons[0].disabled}
                                    onClick={exportButtons[0].onClick}
                                    iconProps={{ name: 'export' }}
                                    data-aid='export'
                                >{t('TOOLBAR.EXPORT')}</Button>
                            )}
                        </Stack>
                    </Stack>
                </Stack>
            )}
            <Stack className='ag-theme-alpine relative' fullHeight fullWidth data-aid='table-component'>
                <AgGridReact
                    onGridReady={handleOnGridReady}
                    loadingOverlayComponent={loadingOverlayComponent}
                    loadingCellRenderer={loadingCellRenderer}
                    gridOptions={defaultGridOptions}
                    rowData={gridOptions?.rowData}
                    columnDefs={gridOptions?.columnDefs}
                />
                {(footer || hasRowSelection) && (
                    <TableStyles.Footer fullWidth padding={[2,0,0,0]} spacing={6} direction='row' alignItems='center' data-aid='table-component_footer'>
                        {hasRowSelection && <SelectedRowsComponent />}
                        {footer && typeof footer === 'string' ? <Typography>{footer}</Typography> : footer}
                    </TableStyles.Footer>
                )}
            </Stack>
        </TableStyles.Wrapper>
    );
}
Table.displayName = 'Table';

export default Table;
