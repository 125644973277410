import React from 'react';
import { ColumnApi, GridApi } from 'ag-grid-community';
import { useTranslation } from 'react-i18next';
import { getCompsI18nNS } from 'common/design-system/initialize.i18n';
import ColumnsSelectStyles from './ColumnsSelect.styles';
import { Button, Stack, Label, List, Dropdown, Input, Icon } from 'common/design-system/components-v2';

export interface ColumnsSelectProps {
    gridApi: GridApi | undefined;
    columnApi: ColumnApi | undefined;
    isLoading?: boolean;
}

const ColumnsSelect: React.FC<ColumnsSelectProps> = props => {
    const { gridApi, columnApi, isLoading } = props;
    const { t } = useTranslation(getCompsI18nNS('table'));

    const [columnsData, setColumnsData] = React.useState<{ label: string; id: string; selected: boolean; }[]>([]);
    const [searchValue, setSearchValue] = React.useState<string>('');
    const [isOpen, setIsOpen] = React.useState<boolean>(false);

    const setDefaultColumnsState = React.useCallback(() => {
        if (!columnApi) return;
        const columns = columnApi.getAllGridColumns();
        setColumnsData(columns
            .filter(column => column.getColId() !== 'ag-Grid-AutoColumn' && column.getColId() !== 'checkbox' && column.getColId() !== 'actions' && column.getColDef().suppressColumnsToolPanel !== true)
            .map((column) => ({ label: column.getColDef().headerName || column.getColId(), id: column.getColId(), selected: column.isVisible() })));
    }, [columnApi]);

    React.useEffect(() => {
        if (!gridApi) return;
        gridApi.addEventListener('columnVisible', () => {
            setDefaultColumnsState();
        });

        return () => {
            gridApi.removeEventListener('columnVisible', () => {
                setDefaultColumnsState();
            });
        };
    }, [gridApi, setDefaultColumnsState]);

    React.useEffect(() => {
        if (!gridApi || !columnApi) return;
        setDefaultColumnsState();
    }, [gridApi, columnApi, setDefaultColumnsState]);

    const onVisibleColumnChange = (columnId: string, newValue: boolean) => {
        if (!columnApi) return;
        columnApi.setColumnVisible(columnId, newValue);

        setColumnsData((current) => {
            const newDataSet = [...current];
            const index = newDataSet.findIndex(column => column.id === columnId);
            newDataSet[index].selected = newValue;
            return newDataSet;
        });
    };

    const resetGridColumns = () => {
        if (!columnApi || !gridApi) return;
        columnApi.resetColumnState();
        gridApi.sizeColumnsToFit();
        setDefaultColumnsState();
    };

    const autofitGridColumns = () => {
        if (!columnApi) return;
        columnApi.autoSizeAllColumns();
    };

    const selectAllColumns = () => {
        if (!columnApi) return;

        setColumnsData((current) => {
            const newDataSet = current.map(column => {
                columnApi.setColumnVisible(column.id, true);
                column.selected = true;
                return column;
            });
            return newDataSet;
        });
    };

    const filteredColumnsData = React.useMemo(() => {
        return columnsData.filter(column => column.label.toLowerCase().includes(searchValue.toLowerCase()) || column.id.toLowerCase().includes(searchValue.toLowerCase()));
    }, [searchValue, columnsData]);

    return (
        <ColumnsSelectStyles.DropdownWrapper>
            <Dropdown
                placement='bottom-start'
                open={isOpen}
                onStateChange={(state) => setIsOpen(state)}
                label='Columns'
                buttonProps={{ iconProps: { name: 'columns' }, disabled: isLoading }}
                maxHeight={500}
            >

                <Stack spacing={2} padding={2} data-aid='table-component_columns-select'>
                    <Input
                        startAdornment={<Icon name='magnify' />}
                        value={searchValue}
                        onChange={(event) => setSearchValue(event.target.value)}
                        placeholder={t('TOOLBAR.SEARCH')}
                        clearable
                        fullWidth
                    />
                    <Stack direction='row' spacing={1}>
                        <Button
                            variant='text'
                            iconProps={{ name: 'refresh' }} // TODO: icon not working auto-fit-columns
                            onClick={() => autofitGridColumns()}>
                            {t('TOOLBAR.AUTOFIT')}
                        </Button>
                        <Button
                            variant='text'
                            dataAid='reset-columns'
                            iconProps={{ name: 'refresh' }}
                            onClick={() => resetGridColumns()}>
                            {t('TOOLBAR.RESET')}
                        </Button>
                        <Button
                            variant='text'
                            dataAid='select-all-columns'
                            iconProps={{ name: 'doubleCheck' }}
                            onClick={() => selectAllColumns()}>
                            {t('TOOLBAR.SELECT_ALL')}
                        </Button>
                    </Stack>
                    <ColumnsSelectStyles.CheckboxList>
                        <>
                            <List
                                options={filteredColumnsData.map(column => ({ label: column.label, value: column.id, selected: column.selected }))}
                                onOptionClick={(option) => onVisibleColumnChange(option.value, !option.selected)}
                                withCheckbox
                            />
                            {filteredColumnsData.length === 0 && (
                                <Stack alignItems='center'>
                                    <Label text='No results' size='lg' />
                                </Stack>
                            )}
                        </>
                    </ColumnsSelectStyles.CheckboxList>
                </Stack>
            </Dropdown>
        </ColumnsSelectStyles.DropdownWrapper>
    );
};

export default ColumnsSelect;
