import { IMainMenuItem, IMenuSection, IMenuSectionItem } from '../interface/menu';
import globalAddinContainer, { buildPath } from '../extensibility/AddinContainer';
import { MENU_ITEMS } from '../extensibility/WellKnownPaths';

/**
 * A helper function that adds a menu section item to the menu.
 * @param mainMenuItemId the "id" of the main menu item to which you want to add
 * @param sectionId the section "id" of the section you want to add
 * @param item the item you are adding
 */
export function addMenuSectionItem(mainMenuItemId: string, sectionId: string, item: IMenuSectionItem) {
    globalAddinContainer.addMap(buildPath(MENU_ITEMS, mainMenuItemId, sectionId), [item]);
}

/**
 * A helper function that adds a new section and all of its inner items under a main menu item
 * @param mainMenuItemId the "id" of the main menu item to which you want to add
 * @param section the section you want to add
 */
export function addSection(mainMenuItemId: string, section: IMenuSection) {
    const sectionClone = Object.assign({}, section);
    delete sectionClone.items;
    globalAddinContainer.addMap(buildPath(MENU_ITEMS, mainMenuItemId), [sectionClone]);
    if (section.items) {
        section.items.forEach((item) => {
            addMenuSectionItem(mainMenuItemId, section.id, item);
        });
    }
}

/**
 * A helper function that adds a main menu item and all the inner sections
 * @param item the main menu item you want to add
 */
export function addMainMenuItem(item: IMainMenuItem) {
    const itemClone = Object.assign({}, item);
    delete itemClone.sections;
    globalAddinContainer.addMap(MENU_ITEMS, [itemClone]);
    if (item.sections) {
        item.sections.forEach((section) => {
            addSection(item.id, section);
        });
    }
}