import React from 'react';
import { RoundedStyle } from './LevelCounterIndicator.styled';
import { formatNumberShorthand } from 'common/utils/helpFunctions';

const LevelCounterIndicator: React.FC<{ bg: string, count: number, title: string }> = ({ bg, count, title }) => {
    return (
        <RoundedStyle.TopDiv bg={bg}>
            <RoundedStyle.TextDiv>{formatNumberShorthand(count)}</RoundedStyle.TextDiv>
            <RoundedStyle.TextDiv>{title}</RoundedStyle.TextDiv>
        </RoundedStyle.TopDiv>
    );
};

export default LevelCounterIndicator;