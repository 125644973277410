import React from 'react';
import { Typography } from '../index';
import { VulnerabilitiesProps, VulnerabilityItem } from './CountBySeverity.types';
import { CountWithSeverity, VulnerabilitiesWrapper } from './CountBySeverity.styled';
import { FindingSeverityEnum } from 'common/consts/FindingSeverity';
import { formatNumberShorthand } from 'common/utils/helpFunctions';

const severityOrder: Array<keyof typeof FindingSeverityEnum> = ['critical', 'high', 'medium', 'low', 'informational']; // unknown
const sortBySeverity = (a: VulnerabilityItem, b: VulnerabilityItem, sortOrder: 'ase' | 'des'): number => {
    const orderMultiplier = sortOrder === 'des' ? 1 : -1;
    return orderMultiplier * severityOrder.indexOf(a.severity) - severityOrder.indexOf(b.severity);
};

const CountBySeverity: React.FC<VulnerabilitiesProps> = ({ severityCounts, sortOrder = 'des', customWidth }) => (
    <VulnerabilitiesWrapper fullWidth alignItems={'center'} direction={'row'} spacing={1}>
        {severityCounts
            .sort((severityA, severityB) => sortBySeverity(severityA, severityB, sortOrder))
            .map((item) =>
                <CountWithSeverity
                    spacing={3}
                    direction={'column'}
                    alignItems={'center'}
                    key={item.severity}
                    severity={item.severity}
                    customWidth={customWidth}
                >
                    <Typography variant={'body500'}>{item.count ? formatNumberShorthand(item.count) : 0}</Typography>
                </CountWithSeverity>
            )}
    </VulnerabilitiesWrapper>
);

export default CountBySeverity;