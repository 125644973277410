import { ITableAction } from '../../../../../design-system/components-v2/Table/Table.types';
import {
    getPageTableCrudActions,
} from '../../../../custom/ClientFilterPageTable/ClientFilterPageTable.actions';
import { RECIPE_ICON_NAME, RECIPE_PAGE_TABLE_ID } from '../../RecipeEditor.consts';
import { IRecipe } from '../../../RecipeExampleCenter/RecipeExample.interface';
import { showRecipeForm } from '../../RecipeEditor.items';
import { getRecipeExampleService } from '../../../RecipeExampleCenter/RecipeExampleService';
import {
    IAddActionProps,
    IDeleteActionProps,
    IEditActionProps,
} from '../../../../custom/ClientFilterPageTable/ClientFilterPage.interface';

const deleteItems = (recipes: IRecipe[]): Promise<void> => {
    const ids = recipes.map(recipe => recipe.id);
    return getRecipeExampleService().deleteRecipes(ids);
};

export const getRecipesTableActions = (onTableDataChange: () => void, isUsingDrawer: () => boolean): ITableAction<IRecipe>[] => {
    const addProps: IAddActionProps = {
        callback: () => showRecipeForm(isUsingDrawer()),
    };

    const editProps: IEditActionProps<IRecipe> = {
        callback: (recipe: IRecipe) => showRecipeForm(isUsingDrawer(), recipe),
    };

    const deleteProps: IDeleteActionProps<IRecipe> = {
        deleteItems,
        getNameFromItem: (recipe: IRecipe) => recipe.name,
        itemIconName: RECIPE_ICON_NAME,
    };

    return getPageTableCrudActions<IRecipe>(RECIPE_PAGE_TABLE_ID, onTableDataChange, {
        addProps,
        editProps,
        deleteProps,
    });
};