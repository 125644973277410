import {
    AssetPopoverBadge,
    PATH_INSIGHT_PERIMETER_EXPOSURE_ASSET_POPOVER_BADGE,
    PATH_INSIGHT_PERIMETER_EXPOSURE_ASSET_POPOVER_CHIP,
    PATH_INSIGHT_PERIMETER_EXPOSURE_ASSET_POPOVER_ITEM,
    PerimeterExposureAsset,
} from './PerimeterExposureAsset';
import React from 'react';
import { ExposureNodeData } from './ExposureNodeData';
import { globalAddinContainer } from '../../extensibility/AddinContainer';
import { Addin } from '../../extensibility/AddinRegistry';

export interface IPopoverItemInfo {
    id: string;
    position: number;
    component: React.FC<{ data: ExposureNodeData }>;
    permission?: () => boolean;
}

const createPerimeterExposureAssetAddins = (itemInfos: IPopoverItemInfo[]): Addin<PerimeterExposureAsset>[] => {
    return itemInfos.map(itemInfo => {
        return {
            id: itemInfo.id,
            position: itemInfo.position,
            permission: itemInfo.permission,
            content: {
                id: itemInfo.id,
                component: itemInfo.component,
            },
        };
    });
};

export const InsightPopoverRegistry = {
    addBadges: (badges: AssetPopoverBadge[]) => {
        const badgesNodeAddins = badges.map(badge => {
            return {
                id: badge.id,
                content: badge,
                permission: badge.permission,
            };
        });
        globalAddinContainer.add<AssetPopoverBadge>(PATH_INSIGHT_PERIMETER_EXPOSURE_ASSET_POPOVER_BADGE, badgesNodeAddins);
    },

    addItems: (itemInfos: IPopoverItemInfo[]) => {
        const itemNodes = createPerimeterExposureAssetAddins(itemInfos);
        globalAddinContainer.add<PerimeterExposureAsset>(PATH_INSIGHT_PERIMETER_EXPOSURE_ASSET_POPOVER_ITEM, itemNodes);
    },

    addChips: (chipInfos: IPopoverItemInfo[]) => {
        const chipNodes = createPerimeterExposureAssetAddins(chipInfos);
        globalAddinContainer.add<PerimeterExposureAsset>(PATH_INSIGHT_PERIMETER_EXPOSURE_ASSET_POPOVER_CHIP, chipNodes);
    },

    getBadges: (): AssetPopoverBadge[] => {
        return globalAddinContainer.get<AssetPopoverBadge>(PATH_INSIGHT_PERIMETER_EXPOSURE_ASSET_POPOVER_BADGE);
    },

    getItems: (): PerimeterExposureAsset[] => {
        return globalAddinContainer.get<PerimeterExposureAsset>(PATH_INSIGHT_PERIMETER_EXPOSURE_ASSET_POPOVER_ITEM);
    },

    getChips: (): PerimeterExposureAsset[] => {
        return globalAddinContainer.get<PerimeterExposureAsset>(PATH_INSIGHT_PERIMETER_EXPOSURE_ASSET_POPOVER_CHIP);
    },
};
