import { IconProps } from '../Icon/Icon.types';
import { IAlertProps } from './Alert.types';
import { Theme } from '../../theme/theme.types';

export const iconProps = (type: IAlertProps['type'], theme: Theme, iconSize = 16): IconProps => {
    const { palette } = theme;
    const props = {
        info: { 
            name: 'info',
            customColor: palette['info'].foregrounds.regular.saturation,
            size: iconSize
        },
        success: { 
            name: 'checkCircle',
            customColor: palette['lowRisk'].foregrounds.regular.saturation,
            size: iconSize
        },
        warning: {
            name: 'warning', 
            customColor: palette['low'].foregrounds.regular.saturation,
            size: iconSize 
        },
        error: { 
            name: 'error', 
            customColor: palette['critical'].foregrounds.regular.saturation,
            size: iconSize 
        }
    };
    return props[type ? type : 'info'] as IconProps;
};

