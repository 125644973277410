import { buildPath, globalAddinContainer } from '../../extensibility/AddinContainer';
import { Addin } from '../../extensibility/AddinRegistry';
import { CGColDef } from '../../components/ProtectedAssets/ProtectedAssetsTable.interface';
import { IFilterProps } from '../../components/FilterPanel/FilterPanelManager';
import {
    IProtectedAssetFilterParams,
    IProtectedAssetTableCellRendererAddinContent
} from './ProtectedAssets';
import { ITableAction } from 'common/design-system/components-v2/Table/Table.types';

const PATH_PROTECTED_ASSETS_PREFIX = buildPath('assets', 'protected assets');
const PATH_PROTECTED_ASSETS_TABLE = buildPath(PATH_PROTECTED_ASSETS_PREFIX, 'table');
const PATH_PROTECTED_ASSETS_TABLE_COLUMNS = buildPath(PATH_PROTECTED_ASSETS_TABLE, 'columns');
const PATH_PROTECTED_ASSETS_TABLE_FILTERS = buildPath(PATH_PROTECTED_ASSETS_TABLE, 'filters');
const PATH_PROTECTED_ASSETS_TABLE_ACTIONS = buildPath(PATH_PROTECTED_ASSETS_TABLE, 'actions');
const PATH_PROTECTED_ASSETS_TABLE_CELL_RENDERERS = buildPath(PATH_PROTECTED_ASSETS_TABLE, 'renderers');

export const ProtectedAssetsTableRegistry = {
    addColumnDefAddins: (columnDefAddins: Addin<CGColDef>[]) => {
        globalAddinContainer.add<CGColDef>(PATH_PROTECTED_ASSETS_TABLE_COLUMNS, columnDefAddins);
    },

    addColumnDefs: (columnDefs: CGColDef[], idProperty: string) => {
        globalAddinContainer.addMap(PATH_PROTECTED_ASSETS_TABLE_COLUMNS, columnDefs, idProperty);
    },

    getColumnDefs: (): CGColDef[] => {
        return globalAddinContainer.get<CGColDef>(PATH_PROTECTED_ASSETS_TABLE_COLUMNS);
    },

    addFilterAddins: (filterAddins: Addin<IFilterProps>[]) => {
        globalAddinContainer.add<IFilterProps>(PATH_PROTECTED_ASSETS_TABLE_FILTERS, filterAddins);
    },

    getFilter: (filterId: string, filterParams: IProtectedAssetFilterParams[]): IFilterProps | undefined => {
        return globalAddinContainer.getById<IFilterProps>(PATH_PROTECTED_ASSETS_TABLE_FILTERS, filterId, filterParams);
    },

    addActions: (actionDefs: ITableAction[], idProperty: string) => {
        globalAddinContainer.addMap<ITableAction>(PATH_PROTECTED_ASSETS_TABLE_ACTIONS, actionDefs, idProperty);
    },

    getActions: (): ITableAction[] => {
        return globalAddinContainer.get<ITableAction>(PATH_PROTECTED_ASSETS_TABLE_ACTIONS);
    },

    addCellRendererAddins: (cellRendererAddins: Addin<IProtectedAssetTableCellRendererAddinContent>[]) => {
        globalAddinContainer.add<IProtectedAssetTableCellRendererAddinContent>(PATH_PROTECTED_ASSETS_TABLE_CELL_RENDERERS, cellRendererAddins);
    },
};
