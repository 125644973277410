import { getBreadcrumbsService } from 'common/interface/services';
import { useEffect } from 'react';

interface LastBreadcrumbInterface {
    setBreadCrumbsLastChildren: (lastChildren?: Array<string>) => void,
}

type UseBreadcrumbsLastChild = (lastChildren?: Array<string>) => LastBreadcrumbInterface;
export const useBreadcrumbsLastChildren: UseBreadcrumbsLastChild = (lastChildren): LastBreadcrumbInterface => {
    
    useEffect(() => {
        if (lastChildren) {
            getBreadcrumbsService().setBreadcrumbsLastChildren(lastChildren);
        }
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    return {
        setBreadCrumbsLastChildren: getBreadcrumbsService().setBreadcrumbsLastChildren,
    };
};

