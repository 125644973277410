import styled, { DefaultTheme, css } from 'styled-components';
import { AlignItems, Direction, JustifyContent, Spacing } from './Stack.types';

const resolveSpacing = (theme: DefaultTheme, spacing: Array<Spacing> | Spacing) => {
    if (Array.isArray(spacing)) {
        return `${spacing.map(s => theme.spacing(s)).join('px ')}px`;
    }
    return `${theme.spacing(spacing)}px`;
};

interface StackProps {
    direction: Direction;
    alignItems: AlignItems;
    justifyContent: JustifyContent;
    spacing: Array<Spacing> | Spacing;
    fullHeight?: boolean;
    fullWidth?: boolean;
    padding: Array<Spacing> | Spacing;
    margin: Array<Spacing> | Spacing;
    flexWrap?: boolean;
    flexShrink?: number;
    overflow?: 'hidden' | 'visible' | 'scroll' | 'auto';
}
const Stack = styled.div<StackProps>`
    display: flex;
    flex-direction: ${props => props.direction};
    align-items: ${props => props.alignItems};
    justify-content: ${props => props.justifyContent};
    gap: ${props => resolveSpacing(props.theme, props.spacing)};
    padding: ${props => resolveSpacing(props.theme, props.padding)};
    margin: ${props => resolveSpacing(props.theme, props.margin)};
    flex-wrap: ${props => props.flexWrap ? 'wrap' : 'nowrap'};
    flex-shrink: ${props => (props.flexShrink || props.flexShrink === 0) ? props.flexShrink : 1};

    ${props => props.overflow && css`
        overflow: ${props.overflow};
    `}

    ${props => props.fullHeight && css`
        height: 100%;
    `}
    ${props => props.fullWidth && css`
        width: 100%;
    `}
`;

const StackStyles = {
    Stack
};

export default StackStyles;
