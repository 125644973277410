import {
    IAddHandlerProps,
    IFiltersValues,
    IGeneralHandlerProps,
    IRenderedFilter,
    IRenderedHandler,
    SetInRequestObj,
} from './FilterPanel.interface';
import { DateFilter } from './DefaultFilters/DateFilter/DateFilter';
import FreeTextFilter from './DefaultFilters/FreeTextFilter/FreeTextFilter';
import ClearButton from './DefaultHandlers/ClearButton/ClearButton';
import SavedFilters from './DefaultHandlers/SavedFilters/SavedFilters';
import { SAVED_FILTERS_CATEGORIES } from './DefaultHandlers/SavedFilters/SavedFilters.consts';
import {
    FILTER_BOX_DISPLAY_TYPES,
    FILTER_DISPAY_TYPES,
    FILTER_PANEL_CONSTS,
    FILTER_TYPES,
    FILTERS_API_OBJECT_KEYS,
    FILTERS_KEYS,
} from './FilterPanel.consts';

import AddFilter from './DefaultHandlers/AddFilter/AddFilter';
import MultiSelectFilter from './DefaultFilters/MultiSelectFilter/MultiSelectFilter';
import {
    IAddItemFilter,
    IDateFilter,
    IDateFilterOption,
    IFlatTreeFilterObject,
    IGSLFilterProps,
    IMultiSelectFilter,
    IRangeFilterValue,
    IRangeSliderProps,
    ITagFilterProps,
    ITextFilter,
    ITreeFilterObject,
} from './DefaultFilters/DefaultFilters.interface';
import TagFilter from './DefaultFilters/TagFilter/TagFilter';
import TreeFilter from './DefaultFilters/TreeFilter/TreeFilter';
import { flattenTree } from './filterUtils';
import { ISavedFilter, ISavedFiltersRenderFunction } from './DefaultHandlers/SavedFilters/SavedFilters.interface';
import { IAddFilterTagView } from './DefaultHandlers/DeafultHandlers.interface';
import MultiRangeSlider from './DefaultFilters/RangeFilter/RangeFilter';
import AddItemFilter from './DefaultFilters/AddItemFilter/AddItemFilter';
import { computeRangeFromSelection } from './DefaultFilters/DateFilter/DateFilter.consts';
import RecentlyUsedFilters, { IRecentlyUsedFilters } from './DefaultHandlers/RecentlyUsedFilters/RecentlyUsedFilters';
import { getCustomizationService } from '../../interface/services';
import { Chip } from '@dome9/berries/react-components';
import React from 'react';
import { getProtectedAssetsService } from '../../module_interface/assets/ProtectedAssets';
import { getVendor } from '../../consts/vendors';
import GSLFilter from './DefaultFilters/GSLFilter/GSLFilter';
import { Icon, Spinner, Stack } from 'common/design-system/components-v2';

const AddFilterLoading: React.FC = () => (
    <Stack fullWidth padding={[0, 0, 0, 1]}>
        <Spinner size={16} />
    </Stack>
);

//Render Functions for Filters
export const renderDefaultFreeTextFilter = (
    freeTextFilterProps: ITextFilter,
    displayTypes: string[] = [FILTER_DISPAY_TYPES.ROW, FILTER_BOX_DISPLAY_TYPES.BOX_CONTENT_1],
    hiddenList: string[] = [FILTERS_KEYS.ADD_FILTER],
    keyInObjectForAPI: string = freeTextFilterProps.key,
    setInRequestObj?: SetInRequestObj,
): IRenderedFilter => {
    const clear = (): string => {
        return '';
    };

    const defaultSetInRequestObj = (requestObj: any, value: string): any => {
        requestObj[keyInObjectForAPI] = value;
        return requestObj;
    };

    const getAsTagView = (value: string, handlerKey: string): any => {
        return value === '' || hiddenList.includes(handlerKey) ? ['', []] : [freeTextFilterProps.title, [value]];
    };

    return {
        filterElement: FreeTextFilter,
        filterProps: freeTextFilterProps,
        type: FILTER_TYPES.FILTER_OUTSIDE,
        displayTypes,

        filterUtils: { clear: clear, keyInObjectForAPI, setInRequestObj: setInRequestObj ?? defaultSetInRequestObj, getAsTagView },
    };
};

export const renderDefaultDateFilter = (
    dateFilterProps: IDateFilter,
    displayTypes: string[] = [FILTER_DISPAY_TYPES.ROW, FILTER_BOX_DISPLAY_TYPES.BOX_CONTENT_1],
    hiddenList: string[] = [FILTERS_KEYS.ADD_FILTER],
    keyInObjectForAPI: string = dateFilterProps.key,
    setInRequestObj?: SetInRequestObj,
): IRenderedFilter => {
    const clear = (): IDateFilterOption => {
        return dateFilterProps.defaultValue;
    };
    const getAsTagView = (value: IDateFilterOption, handlerKey: string): IAddFilterTagView => {
        return hiddenList.includes(handlerKey) ? ['', []] : [dateFilterProps.title, [value.displayName]];
    };
    const shouldBeInURLParams = (value: IDateFilterOption): boolean => {
        return value !== dateFilterProps.defaultValue;
    };

    const defaultSetInRequestObj = (requestObj: any, value: IDateFilterOption) => {
        requestObj[keyInObjectForAPI] = computeRangeFromSelection(value);
        return requestObj;
    };

    return {
        filterElement: DateFilter,
        filterProps: dateFilterProps,
        type: FILTER_TYPES.FILTER,
        displayTypes,
        filterUtils: {
            clear: clear,
            shouldBeInURLParams,
            keyInObjectForAPI,
            setInRequestObj: setInRequestObj ?? defaultSetInRequestObj,
            getAsTagView,
        },
    };
};

export const renderTextFilter = (
    textFilterProps: ITextFilter,
    displayTypes: string[] = [FILTER_BOX_DISPLAY_TYPES.BOX_CONTENT_2],
    hiddenList: string[] = [],
    keyInObjectForAPI: string = textFilterProps.key,
    setInRequestObj?: SetInRequestObj,
): IRenderedFilter => {
    const clear = (): string => {
        return '';
    };

    const defaultSetInRequestObj = (requestObj: any, value: string): any => {
        requestObj[keyInObjectForAPI] = value;
        return requestObj;
    };

    const getAsTagView = (value: string, handlerKey: string): any => {
        return value === '' || hiddenList.includes(handlerKey) ? ['', []] : [textFilterProps.title, [value]];
    };

    return {
        filterElement: FreeTextFilter,
        filterProps: textFilterProps,
        type: FILTER_TYPES.FILTER,
        displayTypes,
        filterUtils: { clear: clear, keyInObjectForAPI,setInRequestObj: setInRequestObj ?? defaultSetInRequestObj, getAsTagView },
    };
};

export const renderMultiSelectFilter = (
    multiSelectProps: IMultiSelectFilter,
    displayTypes = [FILTER_BOX_DISPLAY_TYPES.BOX_CONTENT_2],
    hiddenList: string[] = [],
    keyInObjectForAPI: string = FILTERS_API_OBJECT_KEYS.FIELDS,
    setInRequestObj?: SetInRequestObj,
): IRenderedFilter => {
    const defaultSetInRequestObj = (requestObj: any, value: string[]) => {
        const { key } = multiSelectProps;
        const fields = [] as any[];
        value.forEach((val: string) => {
            fields.push({ name: key, value: val });
        });
        requestObj[keyInObjectForAPI] = requestObj[keyInObjectForAPI] ? [...requestObj.fields, ...fields] : [...fields];

        return requestObj;
    };
    const getAsTagView = (value: string[], handlerKey: string): IAddFilterTagView => {
        if (hiddenList.includes(handlerKey)) {
            return ['', []];
        }

        const title = multiSelectProps.title || multiSelectProps.key;
        const content = value.map((val: string) => multiSelectProps.displayMapping?.[val]?.displayText || val);

        return [title, content];
    };
    const clear = () => {
        return [];
    };

    return {
        filterElement: MultiSelectFilter,
        filterProps: multiSelectProps,
        type: FILTER_TYPES.FILTER,
        displayTypes,
        filterUtils: {
            getAsTagView,
            clear,
            keyInObjectForAPI,
            setInRequestObj: setInRequestObj ?? defaultSetInRequestObj,
        },
    };
};

export const renderTagFilter = (
    tagFilterProps: ITagFilterProps,
    displayTypes = [FILTER_BOX_DISPLAY_TYPES.BOX_CONTENT_2],
    hiddenList: string[] = [],
    keyInObjectForAPI?: string,
    setInRequestObj?: SetInRequestObj,
): IRenderedFilter => {
    const { defaultValue = [{ key: '', value: '' }] } = tagFilterProps;
    const getAsTagView = (value: any, handlerKey: string): IAddFilterTagView => {
        if (hiddenList.includes(handlerKey) || JSON.stringify(value) === JSON.stringify(defaultValue)) {
            return ['', []];
        }
        const title = tagFilterProps.title || tagFilterProps.key;
        const content = value.map((tag: { key: string; value: string }) => {
            let str = '( ';
            if (tag.key) {
                str += `${tag.key}`;
            }
            if (tag.key && tag.value) {
                str += ' :';
            }
            if (tag.value) {
                str += `${tag.value}`;
            }
            return str + ' )';
        });

        return [title, content];
    };
    const shouldBeInURLParams = (value: any): boolean => {
        return JSON.stringify(value) !== JSON.stringify(defaultValue);
    };
    const clear = () => {
        return defaultValue;
    };

    const defaultSetInRequestObj = (requestObj: any, value: string) => {
        requestObj.tags = value;
        return requestObj;
    };

    return {
        filterElement: TagFilter,
        filterProps: { ...tagFilterProps, defaultValue },
        type: FILTER_TYPES.FILTER,
        displayTypes,
        filterUtils: {
            getAsTagView,
            clear,
            shouldBeInURLParams,
            keyInObjectForAPI,
            setInRequestObj: setInRequestObj ?? defaultSetInRequestObj,
        },
    };
};

export const renderTreeFilter = (
    treeProps: { initialData: ITreeFilterObject; title: string; key: string; defaultValue: string },
    displayTypes = [FILTER_BOX_DISPLAY_TYPES.BOX_CONTENT_2],
    hiddenList?: string[],
    keyInObjectForAPI = 'fields',
    setInRequestObj?: SetInRequestObj,
): IRenderedFilter => {
    const { title, defaultValue, initialData } = treeProps;
    const flatterTreeData = flattenTree(initialData);

    const getAsTagView = (values: string[]): IAddFilterTagView => {
        const selectedNodesTitle: string[] = [];
        flatterTreeData.forEach((node: IFlatTreeFilterObject) => {
            if (values.includes(node.id)) {
                selectedNodesTitle.push(node.name);
            }
        });
        return !values.length ? ['', []] : [title, selectedNodesTitle];
    };

    const clear = () => {
        return defaultValue;
    };
    const shouldBeInURLParams = (value: string) => {
        return value !== treeProps.defaultValue;
    };

    const defaultSetInRequestObj = (requestObj: any, value: string | string[]) => {
        if (!value) return requestObj;

        if (!Array.isArray(value)) {
            value = [value];
        }
        const { key } = treeProps;
        const fields = [] as any[];
        value.forEach((val: string) => {
            fields.push({ name: key, value: val });
        });
        requestObj[keyInObjectForAPI] = requestObj[keyInObjectForAPI] ? [...requestObj[keyInObjectForAPI], ...fields] : [...fields];

        return requestObj;
    };

    return {
        filterElement: TreeFilter,
        filterProps: { ...treeProps, initialData: flatterTreeData },
        type: FILTER_TYPES.FILTER,
        displayTypes,
        filterUtils: {
            getAsTagView,
            clear,
            shouldBeInURLParams,
            keyInObjectForAPI,
            setInRequestObj: setInRequestObj ?? defaultSetInRequestObj,
        },
    };
};

export const renderRangeFilter = (
    rangeFilterProps: IRangeSliderProps,
    displayTypes = [FILTER_BOX_DISPLAY_TYPES.BOX_CONTENT_2],
    hiddenList: string[] = [],
    keyInObjectForAPI = 'fields',
    setInRequestObj?: SetInRequestObj,
): IRenderedFilter => {
    const {
        title,
        value,
        min,
        max,
        key,
        defaultValue = { min: rangeFilterProps.min, max: rangeFilterProps.max },
    } = rangeFilterProps;

    const checkIsDefault = (value: IRangeFilterValue) => {
        const { min, max } = defaultValue;
        return value.min === min && value.max === max;
    };

    const getAsTagView = (value: IRangeFilterValue, handlerKey: string): IAddFilterTagView => {
        return checkIsDefault(value) || hiddenList.includes(handlerKey) || !value.min || !value.max
            ? ['', []]
            : [title, [`${value.min.toString()} - ${value.max.toString()}`]];
    };
    const clear = () => {
        return { min, max };
    };
    const shouldBeInURLParams = (value: IRangeFilterValue) => {
        return !checkIsDefault(value);
    };

    const validateValue = (value: IRangeFilterValue) => {
        return value ? { min: Math.max(value.min, min), max: Math.min(value.max, max) } : { min, max };
    };

    const defaultSetInRequestObj = (requestObj: any, value: { min: number, max: number }) => {
        if (!checkIsDefault(value)) {
            const valueAsString = `${value.min}-${value.max}`;
            requestObj[keyInObjectForAPI] = requestObj[keyInObjectForAPI]
                ? [...requestObj[keyInObjectForAPI], { name: key, value: valueAsString }]
                : [{ name: key, value: valueAsString }];
        }
        return requestObj;
    };

    const convertWebAppValue = (value: any): any => {
        if (value?.length === 1) {
            const splitValue = value[0].split('-');
            if (splitValue.length === 2) {
                return { min: Number(splitValue[0]), max: Number(splitValue[1]) };
            }
        }
        return value;
    };

    return {
        filterElement: MultiRangeSlider,
        filterProps: {
            convertWebAppValue,
            ...rangeFilterProps,
            defaultValue: validateValue(defaultValue),
            value: validateValue(value),
        },
        type: FILTER_TYPES.FILTER,
        displayTypes,
        filterUtils: {
            getAsTagView,
            clear,
            shouldBeInURLParams,
            keyInObjectForAPI,
            setInRequestObj: setInRequestObj ?? defaultSetInRequestObj,
        },
    };
};

export const renderAddItemFilter = (
    addItemFilterProps: IAddItemFilter,
    displayTypes = [FILTER_BOX_DISPLAY_TYPES.BOX_CONTENT_2],
    hiddenList?: string[],
    keyInObjectForAPI = 'fields',
    setInRequestObj?: SetInRequestObj,

): IRenderedFilter => {
    const { title, defaultValue = [] } = addItemFilterProps;

    const getAsTagView = (value: string[]): IAddFilterTagView => {
        return [value.length > 0 ? title : '', value];
    };
    const clear = () => {
        return [];
    };

    const defaultSetInRequestObj = (requestObj: any, value: string[]) => {
        const { key } = addItemFilterProps;
        const fields = [] as any[];
        value.forEach((val: string) => {
            fields.push({ name: key, value: val });
        });
        requestObj[keyInObjectForAPI] = requestObj[keyInObjectForAPI] ? [...requestObj.fields, ...fields] : [...fields];

        return requestObj;
    };

    return {
        filterElement: AddItemFilter,
        filterProps: { ...addItemFilterProps, defaultValue },
        type: FILTER_TYPES.FILTER,
        displayTypes,
        filterUtils: {
            getAsTagView,
            clear,
            setInRequestObj: setInRequestObj ?? defaultSetInRequestObj,
        },
    };
};

//Render Functions for Handlers
export const renderSavedFilters = (
    savedFiltersProps: ISavedFiltersRenderFunction,
    displayTypes: string[] = [FILTER_DISPAY_TYPES.ROW, FILTER_BOX_DISPLAY_TYPES.BOX_CONTENT_1],
): IRenderedHandler => {
    const { savedFilters } = savedFiltersProps;

    const publicSavedFilters = savedFilters.filter((filter) => {
        return filter.scope === SAVED_FILTERS_CATEGORIES.PUBLIC;
    });
    const privateSavedFilters = savedFilters.filter((filter) => {
        return filter.scope === SAVED_FILTERS_CATEGORIES.PRIVATE;
    });

    const savedFiltersNewProps = {
        savedFiltersByCategories: { Public: publicSavedFilters, Private: privateSavedFilters },
        ...savedFiltersProps,
    };

    const getHandlerInitialValue = () => {
        return savedFiltersNewProps.savedFiltersByCategories;
    };

    const onFiltersChanged = (filtersValues: IFiltersValues, handlerData: any): { data: any; value: any } => {
        const filters: ISavedFilter[] = [];
        const savedFilters = handlerData;
        Object.keys(savedFilters).forEach((obj) => {
            const filtersForCategory = savedFilters[obj];
            const selectedFilters = filtersForCategory.filter((filter: ISavedFilter) => {
                return JSON.stringify(filtersValues) === JSON.stringify(filter.data.state);
            });
            filters.push(...selectedFilters);
        });
        const selectedFilterID = filters.length > 0 ? filters[0].id : '';
        return { data: savedFilters, value: selectedFilterID };
    };
    return {
        filterElement: SavedFilters,
        filterProps: { ...savedFiltersNewProps, title: FILTER_PANEL_CONSTS.SAVED_FILTERS_BUTTON_TEXT },
        type: FILTER_TYPES.HANDLER,
        displayTypes,
        handlerUtils: { getHandlerInitialValue, onFiltersChanged },
    };
};

export const renderClearAll = (
    clearButtonProps: Partial<IGeneralHandlerProps>,
    displayTypes: string[] = [FILTER_DISPAY_TYPES.ROW, FILTER_BOX_DISPLAY_TYPES.BOX_BUTTON],
): IRenderedHandler => {
    return {
        filterElement: ClearButton,
        filterProps: clearButtonProps,
        type: FILTER_TYPES.HANDLER_CLEAR,
        displayTypes,
        handlerUtils: {},
    };
};

export const renderAddFilter = (
    addFilterProps: Partial<IAddHandlerProps>,
    displayTypes = [FILTER_DISPAY_TYPES.ROW],
): IRenderedHandler => {

    function getIconFromTagValue(tagId: string, val: string): string | undefined | null {
        let icon;
        if (tagId === 'type') {
            icon = getProtectedAssetsService().getAssetIconByDisplayName(val);
        } else if (tagId == 'platform') {
            icon = getVendor(val)?.icon;
        } else {
            icon = val.toLowerCase();
        }
        return icon;
    }

    const IconChip: React.FC<any> = ({ onClick, onDelete, tagName, content, title }) => <Chip
        className='self-center'
        title={title}
        onClick={onClick}
        onDelete={() => onDelete(tagName)}
        key={tagName}
        deletable>
        <div className='flex items-center overflow-hidden divide-x '>
            {
                content.length ? content.map((val: string) => {
                    const icon = getIconFromTagValue(tagName, val);
                    return <div className='flex flex-0 items-center px-6' key={val}>
                        {icon && <Icon size={12} vendorNameOrPath={icon!} className='mr-6' />}
                        <span>{val}</span>
                    </div>;
                }) : ''
            }
        </div>
    </Chip>;

    const tagsMapping = {
        platform: (props?: any) => <IconChip {...props} />,
        type: (props?: any) => <IconChip {...props} />,
    };
    return {
        filterElement: addFilterProps.isLoading ? AddFilterLoading : AddFilter,
        filterProps: { tagsMapping, ...addFilterProps },
        type: FILTER_TYPES.HANDLER,
        displayTypes,
        handlerUtils: {},
    };
};

export const renderRecentlyUsedFilters = (
    recentlyUsedProps: IRecentlyUsedFilters,
    displayTypes = [FILTER_BOX_DISPLAY_TYPES.BOX_CONTENT_1],
): IRenderedHandler => {
    let currentList = recentlyUsedProps.recentlyUsedList[0] || [];
    const getHandlerInitialValue = () => {
        return recentlyUsedProps.recentlyUsedList[0] ? recentlyUsedProps.recentlyUsedList[0].data.state : [];
    };

    const saveRecentlyUsedAPI = (newRecentlyUsedList: any[]) => {
        if (currentList.id) {
            getCustomizationService().deleteCustomization(currentList.id);
        }

        currentList = getCustomizationService().setCustomization(
            recentlyUsedProps.componentName, recentlyUsedProps.key, newRecentlyUsedList);
    };
    const onFiltersChanged = (filtersValues: IFiltersValues, handlerData: any): { data: any; value: any } => {
        const newRecentlyUsedList = [...handlerData];

        const isValuesExist = Object.values(newRecentlyUsedList).filter((values) => {
            return JSON.stringify(filtersValues) === JSON.stringify(values);
        });

        if (isValuesExist.length === 0) {
            newRecentlyUsedList.unshift(filtersValues);
            if (newRecentlyUsedList.length > recentlyUsedProps.maxLength) {
                newRecentlyUsedList.length = recentlyUsedProps.maxLength;
            }

            saveRecentlyUsedAPI(newRecentlyUsedList);
        }

        return { data: newRecentlyUsedList, value: filtersValues.toString() };
    };
    return {
        filterElement: RecentlyUsedFilters,
        filterProps: recentlyUsedProps,
        type: FILTER_TYPES.HANDLER_OUTSIDE,
        displayTypes,
        handlerUtils: { getHandlerInitialValue, onFiltersChanged },
    };
};

export const renderGslFilter = (
    gslProps: IGSLFilterProps,
    displayTypes: string[] = [FILTER_BOX_DISPLAY_TYPES.BOX_CONTENT_2],
    hiddenList: string[] = [FILTERS_KEYS.ADD_FILTER],
    keyInObjectForAPI: string = gslProps.key,
    setInRequestObj?: SetInRequestObj,
): IRenderedFilter => {
    const clear = (): string => {
        return '';
    };

    const defaultSetInRequestObj = (requestObj: any, value: string): any => {
        requestObj[keyInObjectForAPI] = value;
        return requestObj;
    };

    const getAsTagView = (value: string, handlerKey: string): any => {
        return value === '' || hiddenList.includes(handlerKey) ? ['', []] : [gslProps.title, [value]];
    };


    return {
        filterElement: GSLFilter,
        filterProps: gslProps,
        type: FILTER_TYPES.FILTER,
        displayTypes,
        filterUtils: { clear, getAsTagView, setInRequestObj: setInRequestObj ?? defaultSetInRequestObj },
    };

};
