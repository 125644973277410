import React, { ReactElement, useCallback, useMemo, useRef, useState } from 'react';
import { ChipStyled, DropdownStyled } from './MultiSelector.styled';
import { IMultiSelectorItem, IMultiSelectorSettings } from './MultiSelector.interface';
import { useTranslation } from 'react-i18next';
import { findItemsByIds, ignoreEvent } from './MultiSelector.utils';
import MultiSelector from './MultiSelector';

const MAX_CHIP_COUNT = 3;
const ItemChip: React.FC<{ item: IMultiSelectorItem, onRemove?: Function, isEdit: boolean, toggleDropdownOpen: Function, width?: string }> = ({ item, onRemove, isEdit, toggleDropdownOpen, width }) => {
    return (
        <ChipStyled.ChipTopDiv key={item.id} width={width}>
            <ChipStyled.ChipTooltip content={item.labelsPath.join(' / ')}>
                <ChipStyled.ChipBodyDiv onMouseDown={ignoreEvent} onClick={isEdit ? () => toggleDropdownOpen() : undefined}>
                    {item.iconName && <ChipStyled.ChipIconDiv color={'undefined'} name={item.iconName} size={16} />}
                    <ChipStyled.ChipLabelDiv>{item.label}</ChipStyled.ChipLabelDiv>
                </ChipStyled.ChipBodyDiv>
            </ChipStyled.ChipTooltip>
            {!!onRemove && <ChipStyled.ChipCloseIconContainer onMouseDown={ignoreEvent} onClick={isEdit ? () => onRemove(item) : undefined}>
                <ChipStyled.ChipCloseIcon name='remove' isEdit={isEdit} size={12} />
            </ChipStyled.ChipCloseIconContainer>}
        </ChipStyled.ChipTopDiv>
    );
};

const getInputWidthStr = (isEdit: boolean, chipCount: number) => {
    if (!isEdit) {
        if (chipCount) {
            return '10px';
        } else {
            return '100%';
        }
    }

    switch (chipCount) {
        case 0:
            return '100%';

        case 1:
            return '50%';

        case 2:
            return '100px';

        default:
            return '60px';
    }
};

const MultiSelectorDropdown: React.FC<{
    items: IMultiSelectorItem[],
    isEdit?: boolean,
    selectedIds?: string[],
    nonSelectableIds?: string[],
    onSelectionChanged: (selIds: string[]) => void,
    settings?: IMultiSelectorSettings,
    isTree: boolean,
    nonSelectableReason?: string,
    placeholder?: string,
}> = ({
    items, isEdit = false, selectedIds = [], nonSelectableIds, onSelectionChanged, settings, isTree = false, nonSelectableReason, placeholder,
}) => {
    const [filter, setFilter] = useState<string | undefined>();
    const [isDropdownOpen, setIsDropdownOpen] = useState<boolean>(false);
    const filterInputRef = useRef<any>();
    const { t } = useTranslation();
    const selectedItems = useMemo(() => findItemsByIds(items, selectedIds), [items, selectedIds]);

    const onFilterChanged = useCallback((event: any) => {
        setFilter(event.target.value);
    }, []);

    const onFocus = useCallback(() => {
        setIsDropdownOpen(true);
    }, []);

    const onBlur = useCallback(() => {
        setIsDropdownOpen(false);
    }, []);

    const sendSelectionChanged = useCallback((selItems: IMultiSelectorItem[]) => {
        onSelectionChanged(selItems.map((item: IMultiSelectorItem) => item.id));
    }, [onSelectionChanged]);

    const unselectItem = useCallback((item: IMultiSelectorItem) => {
        sendSelectionChanged(selectedItems.filter((oldItem: IMultiSelectorItem) => oldItem.id !== item.id));
    }, [sendSelectionChanged, selectedItems]);

    const clearSelection = useCallback(() => {
        sendSelectionChanged([]);
    }, [sendSelectionChanged]);

    const toggleDropdownOpen = useCallback(() => {
        if (filterInputRef.current) {
            if (isDropdownOpen) {
                filterInputRef.current.blur();
            } else {
                filterInputRef.current.focus();
            }
        }
    }, [isDropdownOpen]);

    const getChipsListComponents = (): ReactElement[] => {
        const items = selectedItems.slice(0, MAX_CHIP_COUNT);
        const components: ReactElement[] = items.map((item: IMultiSelectorItem) => {
            return (
                <ItemChip item={item} key={item.id} onRemove={unselectItem} isEdit={isEdit} toggleDropdownOpen={toggleDropdownOpen}/>
            );
        });
        if (items.length < selectedItems.length) {
            const countItem: IMultiSelectorItem = {
                id: 'count-item',
                labelsPath: [],
                label: `+${selectedItems.length - items.length}`,
            };
            components.push(<ItemChip item={countItem} toggleDropdownOpen={toggleDropdownOpen} width={'40px'} isEdit={isEdit}/>);
        }
        return components;
    };

    const getTopBar = () => {
        const hasSelection = (selectedItems.length > 0);
        return (
            <DropdownStyled.TopBarDiv isOpen={isDropdownOpen} isEdit={isEdit} inputWidth={getInputWidthStr(isEdit, selectedItems.length)}>
                {hasSelection && <DropdownStyled.ChipListDiv onMouseDown={ignoreEvent}>{getChipsListComponents()}</DropdownStyled.ChipListDiv>}
                <input
                    placeholder={(isEdit && !hasSelection) ? placeholder ?? t('COMMON.SELECT_3_DOTS') : ''}
                    disabled={!isEdit}
                    type='text'
                    onChange={isEdit ? onFilterChanged : undefined}
                    value={isEdit ? filter || '' : ''}
                    onFocus={isEdit ? onFocus : undefined}
                    onBlur={isEdit ? onBlur : undefined}
                    ref={filterInputRef}/>
                <DropdownStyled.BarRightActionsDiv>
                    {isEdit && hasSelection && <DropdownStyled.RightActionIconContainer onMouseDown={ignoreEvent} isEdit={isEdit} onClick={() => clearSelection()}><DropdownStyled.CloseAllIcon name='remove' size={10} /></DropdownStyled.RightActionIconContainer>}
                    <DropdownStyled.RightActionIconContainer onMouseDown={ignoreEvent} onClick={isEdit ? toggleDropdownOpen : undefined} isEdit={isEdit}><DropdownStyled.ArrowDownIcon isOpen={isDropdownOpen} name={'chevron-down'} size={10} /></DropdownStyled.RightActionIconContainer>
                </DropdownStyled.BarRightActionsDiv>
            </DropdownStyled.TopBarDiv>
        );
    };

    return (
        <DropdownStyled.TopDropdownDiv>
            {getTopBar()}
            {isEdit && isDropdownOpen && <DropdownStyled.SelectorAreaDiv>
                <MultiSelector
                    isTree={isTree}
                    settings={settings}
                    allItems={items}
                    filter={filter}
                    highLevelSelectedItems={selectedItems}
                    nonSelectableIds={nonSelectableIds}
                    nonSelectableReason={nonSelectableReason}
                    onSelectionChanged={sendSelectionChanged} />
            </DropdownStyled.SelectorAreaDiv>}
        </DropdownStyled.TopDropdownDiv>
    );
};

export default MultiSelectorDropdown;

