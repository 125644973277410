import { ForegroundByContext, Palette } from './colors.types';

export const foregrounds = ( palette:Palette ):ForegroundByContext => ({
    default: {
        strong: {
            default: palette.slate.alpha[900],
            saturation: palette.slate.alpha[500]
        },
        regular: {
            default: palette.slate.alpha[700],
            saturation: palette.slate.alpha[700]
        },
        weak: {
            default: palette.slate.alpha[500],
            saturation: palette.slate.alpha[500]
        },
        weaker: {
            default: palette.slate.alpha[300],
            saturation: palette.slate.alpha[300]
        },
        weakest: {
            default: palette.slate.alpha[100],
            saturation: palette.slate.alpha[100]
        },
        invertedStrong: palette.slate.opaque[10]
    },
    critical: {
        strong: {
            default: palette.magenta.alpha[900],
            saturation: palette.magenta.opaque[500]
        },
        regular: {
            default: palette.magenta.alpha[700],
            saturation: palette.magenta.opaque[400]
        },
        weak: {
            default: palette.magenta.alpha[500],
            saturation: palette.magenta.opaque[300]
        },
        weaker: {
            default: palette.magenta.alpha[300],
            saturation: palette.magenta.opaque[200]
        },
        weakest: {
            default: palette.magenta.alpha[100],
            saturation: palette.magenta.opaque[90]
        },
        invertedStrong: palette.magenta.opaque[10]
    },
    high: {
        strong: {
            default: palette.red.alpha[900],
            saturation: palette.red.opaque[400]
        },
        regular: {
            default: palette.red.alpha[700],
            saturation: palette.red.opaque[300]
        },
        weak: {
            default: palette.red.alpha[500],
            saturation: palette.red.opaque[200]
        },
        weaker: {
            default: palette.red.alpha[300],
            saturation: palette.red.opaque[100]
        },
        weakest: {
            default: palette.red.alpha[100],
            saturation: palette.red.opaque[90]
        },
        invertedStrong: palette.red.opaque[10],
    },
    medium: {
        strong: {
            default: palette.orange.alpha[900],
            saturation: palette.orange.opaque[400]
        },
        regular: {
            default: palette.orange.alpha[700],
            saturation: palette.orange.opaque[300]
        },
        weak: {
            default: palette.orange.alpha[500],
            saturation: palette.orange.opaque[200]
        },
        weaker: {
            default: palette.orange.alpha[300],
            saturation: palette.orange.opaque[100]
        },
        weakest: {
            default: palette.orange.alpha[100],
            saturation: palette.orange.opaque[90]
        },
        invertedStrong: palette.orange.opaque[10]
    },
    low: {
        strong: {
            default: palette.yellow.alpha[900],
            saturation: palette.yellow.opaque[400]
        },
        regular: {
            default: palette.yellow.alpha[700],
            saturation: palette.yellow.opaque[300]
        },
        weak: {
            default: palette.yellow.alpha[500],
            saturation: palette.yellow.opaque[200]
        },
        weaker: {
            default: palette.yellow.alpha[300],
            saturation: palette.yellow.opaque[100]
        },
        weakest: {
            default: palette.yellow.alpha[100],
            saturation: palette.yellow.opaque[90]
        },
        invertedStrong: palette.yellow.opaque[10]
    },
    lowRisk: {
        strong: {
            default: palette.green.alpha[900],
            saturation: palette.green.opaque[500]
        },
        regular: {
            default: palette.green.alpha[700],
            saturation: palette.green.opaque[400]
        },
        weak: {
            default: palette.green.alpha[500],
            saturation: palette.green.opaque[300]
        },
        weaker: {
            default: palette.green.alpha[300],
            saturation: palette.green.opaque[200]
        },
        weakest: {
            default: palette.green.alpha[100],
            saturation: palette.green.opaque[90]
        },
        invertedStrong: palette.green.opaque[10]
    },
    info: {
        strong: {
            default: palette.blue.alpha[900],
            saturation: palette.blue.opaque[200]
        },
        regular: {
            default: palette.blue.alpha[700],
            saturation: palette.blue.opaque[100]
        },
        weak: {
            default: palette.blue.alpha[500],
            saturation: palette.blue.opaque[90]
        },
        weaker: {
            default: palette.blue.alpha[300],
            saturation: palette.blue.opaque[80]
        },
        weakest: {
            default: palette.blue.alpha[100],
            saturation: palette.blue.opaque[70]
        },
        invertedStrong: palette.blue.opaque[10]
    },
    important: {
        strong: {
            default: palette.purple.alpha[900],
            saturation: palette.purple.opaque[400]
        },
        regular: {
            default: palette.purple.alpha[700],
            saturation: palette.purple.opaque[300]
        },
        weak: {
            default: palette.purple.alpha[500],
            saturation: palette.purple.opaque[200]
        },
        weaker: {
            default: palette.purple.alpha[300],
            saturation: palette.purple.opaque[100]
        },
        weakest: {
            default: palette.purple.alpha[100],
            saturation: palette.purple.opaque[90]
        },
        invertedStrong: palette.purple.opaque[10]
    }
});