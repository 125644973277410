export enum RECIPE_FIELD_NAMES {
    NAME = 'name',
    SPICES = 'spices',
    VEGETABLES = 'vegetables',
    INVENTORS = 'inventors',
    MEAT_CATEGORY = 'meatCategory',
    MEAT_TYPES = 'meatTypes',
    MEAT_ALTERNATIVES = 'meatAlternatives',
    DAIRY_PRODUCTS = 'dairyProducts',
    TASTING_TIME_FRAME = 'tastingTimeFrame',
    CHEFS = 'chefs',
    TAGS = 'tags',
    INSTRUCTIONS = 'instructions',
    ORG_IDS = 'orgIds',
    ID = 'id',
}