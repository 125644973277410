import { DataGrid, Tooltip } from '@dome9/berries/react-components';
import { GridApi, ICellRendererParams } from 'ag-grid-enterprise';
import { GridReadyEvent } from 'ag-grid-community';
import React, { useEffect, useState } from 'react';
import IconCellRenderer from 'common/components/Widgets/List/CellRenderers/IconCellRenderer';
import { IGenericWidgetDataProps } from 'common/module_interface/overview/Interface';
import './style.scss';
import { ColDef } from 'ag-grid-community/dist/lib/entities/colDef';


/**
 * The list widget is actually ag-grid table with some small teaks
 * You can just use the ag-grid as you normally do.
 */

const clickableRenderer: React.FC<ICellRendererParams> = (params) => {
    const { data } = params;
    const isClickable = data.onclick;
    return (<Tooltip content={data.key}>
        <div className={`${isClickable && 'hover:underline cursor-pointer' } text-ellipsis`} onClick={ () => data.onclick && data.onclick() }>{data.key}</div>
    </Tooltip>);
};

const ValueCellRenderer: React.FC<ICellRendererParams> = (params) => {
    const value = params.data.value;

    return <div className='text-right'>{value}</div>;
};

const WidgetLatestList: React.FC<IGenericWidgetDataProps> = (props) => {
    const items = props.data?.items;
    const showIconColumn = items?.some(item => item.icon);
    const columnDefs: ColDef[] = [
        { field: 'key' , flex: 3, cellRenderer: clickableRenderer },
        { field: 'value', maxWidth: 150, cellRenderer: ValueCellRenderer, flex: 1 }
    ];

    if(showIconColumn){
        columnDefs.unshift({ field: 'icon', cellRenderer: IconCellRenderer, cellRendererParams: { className: 'absolute inset-0 pl-6' }, maxWidth: 30, minWidth: 30, flex: 0 });
    }

    const [gridApi, setGridApi] = useState<GridApi>();


    const onGridReady = (params: GridReadyEvent) => {
        setGridApi(params.api);
    };

    useEffect(() => {
        if(!gridApi) {
            return;
        }
        if(items?.length === 0 || !items) {
            gridApi.showNoRowsOverlay();
        }
        gridApi.sizeColumnsToFit();

    }, [items, gridApi]);


    return (
        <DataGrid
            hideHeader={true}
            columnDefs={columnDefs}
            rowData={items}
            suppressContextMenu
            onGridReady={onGridReady}
            className='list-widget scrollbar--inner'
            rowModelType='clientSide'
            {...props.data}/>
    );
};


export default WidgetLatestList;

