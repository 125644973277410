import { FC, useCallback, useEffect, useState } from 'react';
import { SelectOption } from 'common/design-system/components-v2/SelectV2/Select.types';
import { MultiOptionsComponent } from 'common/erm-components/custom/MultiChipCellContent/MultiOptionsComponent';
import { ISelectedOptionsChipsProps } from 'common/erm-components/utils/ermComponents.interface';

export const MultiOptionChipsComponent: FC<ISelectedOptionsChipsProps> = (params) => {
    const { selectedValues = [], fetchAllOptions } = params;
    const [allOptions, setAllOptions] = useState<SelectOption[]>([]);

    const loadData = useCallback(async () => {
        setAllOptions(await fetchAllOptions());
    }, [fetchAllOptions]);

    useEffect(() => {
        if (selectedValues.length > 0) {
            void loadData();
        }
    }, [loadData, selectedValues?.length]);

    if (selectedValues.length === 0) {
        return null;
    }

    return <MultiOptionsComponent allOptions={allOptions} selectedValues={selectedValues} />;
};