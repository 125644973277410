import styled, { css } from 'styled-components';
import Stack from '../Stack/Stack';
import Button from '../Button/Button';
import IconButton from '../IconButton/IconButton';

interface IWrapperStyledProps {
  alignment: 'start' | 'center' | 'end';
  direction: 'row' | 'column';
}
const Wrapper = styled(Stack)<IWrapperStyledProps>`
  position: relative;
  ${({ alignment }) => alignment === 'start' && css`
    justify-content: flex-start;
  `}
  ${({ alignment }) => alignment === 'center' && css`
    justify-content: center;
  `}
  ${({ alignment }) => alignment === 'end' && css`
    justify-content: flex-end;
  `}

  &:before {
    content: '';
    position: absolute;
    background: ${({ theme }) => theme.palette.componentStates.ghostHover};
    ${({ direction }) => direction === 'row' && css`
      height: 1px;
      left: 0;
      right: 0;
      bottom: 0;
    `}
    ${({ direction }) => direction === 'column' && css`
      width: 1px;
      top: 0;
      bottom: 0;
      left: 0;
    `}
  }

  .show-more-button {
    border-radius: ${({ direction }) => direction === 'row' ? '8px 8px 0 0' : '0 8px 8px 0'}; 
  }
`;

interface IButtonStyledProps {
  alignment: 'start' | 'center' | 'end';
  direction: 'row' | 'column';
  withCloseIcon: boolean;
  tabsMaxWidth?: number;
}
const ButtonStyled = styled(Button)<IButtonStyledProps>`
  position: relative;
  border: unset;
  border-radius: unset;
  width: 100%;
  max-width: ${({ tabsMaxWidth }) => tabsMaxWidth ? `${tabsMaxWidth}px` : 'fit-content'};
  white-space: nowrap;
  overflow: hidden;
  padding: ${({ theme, withCloseIcon }) => `${theme.spacing(2)}${theme.units} ${theme.spacing(withCloseIcon ? 8 : 2)}${theme.units} ${theme.spacing(2)}${theme.units} ${theme.spacing(3)}${theme.units}`};

  & * {
    overflow: hidden;
    text-overflow: ellipsis;
  }
  flex: 1;

  &:focus,:active {
    background: unset;
  }

  &:hover {
    background: unset;

    &:after {
      content: '';
      position: absolute;
      background: ${({ theme }) => theme.palette.componentStates.ghostActive};

      ${({ direction }) => direction === 'row' && css`
        height: 2px;
        left: 0;
        right: 0;
        bottom: 0;
      `}
      ${({ direction }) => direction === 'column' && css`
        width: 2px;
        top: 0;
        bottom: 0;
        left: 0;
      `}
    }
  }
`;

interface ICloseIconStyledProps {
  isSelected: boolean;
}
const CloseIcon = styled(IconButton)<ICloseIconStyledProps>`
  position: absolute;
  right: 4px;
  top: 50%;
  transform: translateY(-50%);
  display: ${(({ isSelected }) => isSelected ? 'block' : 'none')};

  ${ButtonStyled}:hover & {
    display: block;
  }

  &:hover {
    ${ButtonStyled} {
      background: unset !important;
    }
  }  
`;

interface IDividerStyledProps {
  direction: 'row' | 'column';
}
const Divider = styled.div<IDividerStyledProps>`
  background: ${({ theme }) => theme.palette.onSurface.brandPrimary};
  position: absolute;
  transition: all 0.2s ease-in-out;

  ${({ direction }) => direction === 'row' && css`
    height: 2px;
    bottom: 0;
  `}

  ${({ direction }) => direction === 'column' && css`
    width: 2px;
    left: 0;
  `}
`;

const ButtonGroupStyles = {
    Wrapper,
    ButtonStyled,
    CloseIcon,
    Divider,
};

export default ButtonGroupStyles;
