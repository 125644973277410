import styled, { css } from 'styled-components';
import { Icon , Tooltip } from '@dome9/berries/react-components';
import { IMultiSelectorSettings } from './MultiSelector.interface';

const defaultSelectorHeight = '500px';
const TopDiv = styled.div<{ options?: IMultiSelectorSettings }>`
  border: 1px solid #ccc;
  overflow-y: auto;
  max-height: ${props => props.options?.maxHeight ?? defaultSelectorHeight};
  min-height: ${props => props.options?.minHeight ?? '100px'};
  height: ${props => props.options?.maxHeight ?? defaultSelectorHeight};
  
  .ag-wrapper.ag-input-wrapper.ag-checkbox-input-wrapper {
    width: 14px;
    height: 14px;
    border-color: #ebebeb;

    .ag-input-field-input.ag-checkbox-input {
      cursor: pointer;
    }
  }

  .ag-theme-alpine {
    --ag-selected-row-background-color: #c4e0ff;
    --ag-row-group-indent-size: 15px;
  }
`;

export const SelectorStyled = {
    TopDiv,
};

const CellDiv = styled.div<{ isDisabled: boolean }>`
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: 5px;
  cursor: ${props => props.isDisabled ? 'default' : 'pointer'};
`;

const CellIconDiv = styled.div`
  display: flex;
  align-items: center;
`;

const CellIcon = styled(Icon)`
  
`;

const TextDiv = styled.div<{ isHighlighted: boolean, isDisabled: boolean }>`
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  padding: 2px 0 2px 5px;
  width: 100%;
  font-weight: ${props => props.isHighlighted ? 'bold' : 'normal'};
  color: ${props => props.isDisabled ? '#bbb' : 'black'};
`;

const CellTooltip = styled(Tooltip)`
  font-size: 13px;
`;

export const CellStyled = {
    CellDiv,
    CellIconDiv,
    CellIcon,
    TextDiv,
    CellTooltip,
};

const TopDropdownDiv = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  position: relative;
`;

const TopBarDiv = styled.div<{ inputWidth: string, isEdit: boolean, isOpen: boolean }>`
  display: flex;
  flex-direction: row;
  width: 100%;
  border: 1px solid ${props => props.isOpen ? 'blue' : '#ebebeb'};
  border-radius: 8px;
  height: 32px;
  align-items: center;
  gap: 5px;
  background-color: ${props => props.isEdit ? 'transparent' : '#f2f2f2'};
  padding: 0 3px 0 5px;
  
  & input {
    width: ${props => props.inputWidth};
    padding: 0 1px;
    outline: none;
  }

  &:focus {
    outline: none;
    border-color: blue;
  }
`;

const ChipListDiv = styled.div`
  display: flex;
  flex-direction: row;
  gap: 5px;
  flex: 1 1 0;
`;

const ChipTopDiv = styled.div<{ width?: string }>`
  display: flex;
  flex-direction: row;
  border-radius: 16px;
  height: 22px;
  color: rgb(51, 51, 51);
  font-size: 12px;
  background: ${({ theme }) => theme.palette.surface.normal};
  box-sizing: border-box;
  justify-content: space-between;
  align-items: center;
  flex: 1 1 0;
  width: 0;
  padding: 1px 3px 1px 4px;
  
  ${({ width }) => width && css`
    min-width: ${width};
    max-width: ${width};
  `}
  
`;

const ChipTooltip = styled(Tooltip)`
  font-size: 13px;
`;

const ChipBodyDiv = styled.div`
  display: flex;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  cursor: default;
  padding: 2px 0;
  width: 100%;
  gap: 4px;
`;

const ChipIconDiv = styled(Icon)`
`;

const ChipLabelDiv = styled.div`
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  width: 100%;
`;

const ChipCloseIconContainer = styled.div`
  display: flex;
  flex-direction: row;
  padding: 0 3px
` ;

const ChipCloseIcon = styled(Icon)<{ isEdit: boolean }>`
  cursor: ${props => props.isEdit ? 'pointer' : 'default'};
  opacity: ${props => props.isEdit ? 1.0 : 0.3};
  
  &:hover {
    opacity: ${props => props.isEdit ? 1 : 0.3};
  }
`;

const BarRightActionsDiv = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: 5px;
`;

const RightActionIconContainer = styled.div<{ isEdit: boolean }>`
  display: flex;
  width: 24px;
  height: 24px;
  padding: 7px;
  cursor: ${props => props.isEdit ? 'pointer' : 'default'};
  opacity: ${props => props.isEdit ? 0.5 : 0.3};

  &:hover {
    opacity: ${props => props.isEdit ? 1 : 0.3};
  }
`;

const RightActionIcon = styled(Icon)`
`;

const CloseAllIcon = styled(RightActionIcon)`
`;

const ArrowDownIcon = styled(RightActionIcon)<{ isOpen: boolean }>`
  transform: rotate(${props => props.isOpen ? '180deg' : '0deg'});
`;

const SelectorAreaDiv = styled.div`
    position: absolute;
    z-index: 10;
    top: 28px;
    width: 100%;
    max-height: 500px;
    box-shadow: 2px 2px 4px 0 rgba(0, 0, 0, 0.2);
    background-color: white;
`;

export const DropdownStyled = {
    TopDropdownDiv,
    TopBarDiv,
    ChipListDiv,
    BarRightActionsDiv,
    RightActionIconContainer,
    CloseAllIcon,
    ArrowDownIcon,
    SelectorAreaDiv,
};

export const ChipStyled = {
    ChipTopDiv,
    ChipCloseIconContainer,
    ChipTooltip,
    ChipBodyDiv,
    ChipIconDiv,
    ChipLabelDiv,
    ChipCloseIcon,
    BarRightActionsDiv,
    RightActionIconContainer,
    CloseAllIcon,
    ArrowDownIcon,
};
