import * as React from 'react';
import { SVGProps, Ref, forwardRef } from 'react';
const SvgEmailAttachment = (
  props: SVGProps<SVGSVGElement>,
  ref: Ref<SVGSVGElement>
) => (
  <svg
    viewBox="0 0 16 16"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    ref={ref}
    {...props}
  >
    <path
      d="M4.5 2.5v4a1 1 0 0 1-1 1v0a1 1 0 0 1-1-1v-4a2 2 0 0 1 2-2v0a2 2 0 0 1 2 2v4a3 3 0 0 1-3 3v0a3 3 0 0 1-3-3v-4"
      stroke="currentColor"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M2.5 11.5v2a1 1 0 0 0 1 1h11a1 1 0 0 0 1-1v-7a1 1 0 0 0-1-1h-6"
      stroke="currentColor"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="m7.5 9.5.911.607a1 1 0 0 0 1.155-.031L15 6"
      stroke="currentColor"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </svg>
);
const ForwardRef = forwardRef(SvgEmailAttachment);
export default ForwardRef;
