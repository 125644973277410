import * as React from 'react';
import { SVGProps, Ref, forwardRef } from 'react';
const SvgAccessControl = (
  props: SVGProps<SVGSVGElement>,
  ref: Ref<SVGSVGElement>
) => (
  <svg
    viewBox="0 0 16 16"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    ref={ref}
    {...props}
  >
    <path
      d="M10.061 7.555c.321-.873 1.557-.873 1.878 0l.12.329a1 1 0 0 0 1.11.64l.345-.06c.917-.158 1.535.912.939 1.627l-.225.269a1 1 0 0 0 0 1.28l.225.27c.596.714-.022 1.784-.94 1.625l-.344-.06a1 1 0 0 0-1.11.641l-.12.329c-.321.873-1.557.873-1.878 0l-.12-.329a1 1 0 0 0-1.11-.64l-.345.06c-.917.158-1.535-.912-.939-1.627l.225-.269a1 1 0 0 0 0-1.28l-.224-.27c-.597-.714.021-1.784.938-1.625l.345.06a1 1 0 0 0 1.11-.641l.12-.329Z"
      stroke="currentColor"
      strokeLinejoin="round"
    />
    <circle
      cx={10.96}
      cy={10.96}
      r={1}
      transform="rotate(-22.5 10.96 10.96)"
      stroke="currentColor"
    />
    <path
      d="M7.5 6.5v-4m0 0C5.426 1.118 2.875.69 1.497.559.947.506.5.948.5 1.5v9c0 .552.452.995.992 1.111C3.112 11.96 4.5 13 4.5 13m3-10.5C9.574 1.118 12.125.69 13.503.559c.55-.053.997.389.997.941v4"
      stroke="currentColor"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </svg>
);
const ForwardRef = forwardRef(SvgAccessControl);
export default ForwardRef;
