import { IClientFilterInfo, IItemDataGetter, IGridFilterOptions } from './ClientFilter.interface';
import { convertValueToArray, isNil, isString } from '../../../utils/helpFunctions';
import { Aggregations } from '../../../components/FilterPanel/FilterPanel.interface';
import { IValueCount } from '../../../interface/general';
import { IRowNode } from 'ag-grid-community';
import { IEntityItem } from '../../../utils/filterUtils';

export const createMultiGetter = <T extends IEntityItem = IEntityItem>(fieldsOrGetters: (string | IItemDataGetter<T>)[]): IItemDataGetter => {
    return (item: IEntityItem) => {
        const dataList: any[] = [];
        fieldsOrGetters.forEach(fieldOrGetter => {
            const value: any = isString(fieldOrGetter) ? item[String(fieldOrGetter) as keyof IEntityItem] : (fieldOrGetter as IItemDataGetter)(item);
            const fieldDataList: any[] = convertValueToArray(value);
            fieldDataList.forEach(fieldData => dataList.push(fieldData));
        });
        return dataList;
    };
};

export const createFieldGetter = (fieldName: string): IItemDataGetter => {
    return (item: IEntityItem) => item[fieldName as keyof IEntityItem];
};

export const getClientAggregations = <T extends IEntityItem = IEntityItem, R = any>(clientInfos: IClientFilterInfo<T, R>[], items: T[]): Aggregations => {
    const aggregations: Aggregations = {};
    const aggrClientInfos: IClientFilterInfo<T, R>[] = clientInfos.filter(info => !isNil(info.fieldName));
    items.forEach((item: T) => {
        aggrClientInfos.forEach(clientInfo => {
            const rawData = clientInfo.dataGetter(item);
            const dataList: any[] = convertValueToArray(rawData);
            const fieldName = clientInfo.fieldName!;
            dataList.forEach(data => {
                let fieldValueInfos: IValueCount[] = aggregations[fieldName];
                if (!fieldValueInfos) {
                    fieldValueInfos = [];
                    aggregations[fieldName] = fieldValueInfos;
                }
                let valueInfo = fieldValueInfos.find(fieldValue => fieldValue.value === data);
                if (valueInfo) {
                    valueInfo.count++;
                } else {
                    valueInfo = {
                        value: data,
                        count: 1,
                    };
                    fieldValueInfos.push(valueInfo);
                }
            });
        });
    });
    return aggregations;
};

export const buildSimpleClientFilterGridOptions = <T extends IEntityItem = IEntityItem, R = any>(
    isExternalFilterPresentFn: (filterValues?: R ) => boolean,
    doesExternalFilterPassFn: (clientInfos: IClientFilterInfo<T, R>[], filterValues?: R, item?: T) => boolean,
    getFilterValues: () => R | undefined,
    clientInfos: IClientFilterInfo<T, R>[]): IGridFilterOptions<T> => {

    return {
        isExternalFilterPresent: () => isExternalFilterPresentFn(getFilterValues()),
        doesExternalFilterPass: (node: IRowNode<T>) => doesExternalFilterPassFn(clientInfos, getFilterValues(), node.data),
    };
};