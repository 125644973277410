import React from 'react';
import { Spacing } from '../Stack/Stack.types';

export interface IAlertProps {
    type?: 'error' | 'warning' | 'success' | 'info';
    children: React.ReactNode;
    className?: string;
    margin?: Spacing[];
    withCloseButton?: boolean;
    hideStatusIcon?: boolean;
    onClose?: () => void;
    iconSize?: number;
}

export enum AlertTypeToContext {
    info = 'info',
    success = 'lowRisk',
    warning = 'low',
    error = 'high',
}