import * as React from 'react';
import { SVGProps, Ref, forwardRef } from 'react';
const SvgCsa = (props: SVGProps<SVGSVGElement>, ref: Ref<SVGSVGElement>) => (
  <svg
    id="CSA_svg__Layer_1"
    xmlns="http://www.w3.org/2000/svg"
    x={0}
    y={0}
    viewBox="0 0 64 64"
    xmlSpace="preserve"
    ref={ref}
    {...props}
  >
    <style>{'.CSA_svg__st0{fill:#0292d3}'}</style>
    <path
      className="CSA_svg__st0"
      d="M0 31.88c.02-5.16 1.4-9.95 3.73-14.46 3.5-6.77 8.84-11.64 15.84-14.63C23.84.97 28.33.05 32.98.22c6.34.23 12.17 2.06 17.41 5.72 2.11 1.48 4.05 3.12 5.76 5.06.57.65.59.98-.16 1.52-1.5 1.08-2.89 2.31-4.33 3.46-.91.73-.96.76-1.73-.1-2.06-2.3-4.46-4.13-7.26-5.43-6.75-3.15-13.57-3.32-20.41-.3-6.1 2.69-10.3 7.24-12.67 13.42-1.87 4.87-2.1 9.91-.56 14.91 2.87 9.32 9.14 15.06 18.72 16.92 6.98 1.35 13.38-.31 19.08-4.54 1.05-.78 2-1.71 2.9-2.65.51-.54.86-.7 1.46-.15 1.41 1.28 2.87 2.51 4.31 3.77.88.77.89.94.03 1.86A31.25 31.25 0 0 1 47.34 60c-3.83 2.05-7.89 3.29-12.24 3.67-9.58.83-17.86-1.99-24.9-8.52-4.87-4.53-8-10.06-9.34-16.56-.46-2.2-.58-4.47-.86-6.71z"
    />
    <path
      className="CSA_svg__st0"
      d="M35.11 37.1c0-4-.04-8 .01-11.99.05-3.93.18-7.85.29-11.78.04-1.33.16-1.45 1.49-1.08 8.23 2.25 13.44 7.53 15.21 15.86 1.13 5.32.03 10.4-3.04 14.98-.23.35-.5.67-.75 1-.4.53-.81.61-1.34.14-1.03-.9-2.1-1.77-3.16-2.63-.51-.41-.31-.75-.05-1.22.71-1.3 1.43-2.61 2-3.97.48-1.15.27-1.39-.91-1.46-.77-.05-1.55-.05-2.33-.03-1.29.03-1.38.12-1.4 1.49-.05 4.17-.08 8.33-.09 12.5 0 .82-.29 1.39-1.07 1.66-.95.34-1.9.69-2.88.96-1.93.52-2.08.4-2.08-1.53.01-2.63.04-5.25.05-7.88.01-1.67 0-3.34 0-5.01.02-.01.04-.01.05-.01zm8.41-8.21c.65 0 1.31.03 1.96-.01.52-.03.74-.36.59-.86-.73-2.43-1.95-4.58-3.77-6.34-.62-.6-1.1-.37-1.13.49-.06 1.48-.03 2.97-.03 4.45 0 2.33 0 2.33 2.38 2.27zM29.3 17.86c0 1.55.02 3.11-.01 4.66-.03 1.18-.11 1.26-1.2 1.29-1.13.03-2.27-.03-3.4-.01-.84.01-1.16-.36-1.18-1.19-.01-.55.31-1.4-.46-1.52-.42-.06-.95.58-1.42.91-.14.1-.26.25-.38.38-1.65 1.82-1.66 2.05.16 3.74 2.18 2.03 4.39 4.04 6.63 6 .87.76 1.31 1.57 1.27 2.77-.17 5.24-.25 10.48-.38 15.72-.03 1.14-.21 1.29-1.28 1.04-3.36-.79-6.38-2.27-9-4.56-2.71-2.37-4.66-5.24-5.81-8.65-.45-1.32-.41-1.35.89-1.72.92-.26 1.83-.53 2.76-.76 1.52-.38 1.43-.33 2.17 1.15.72 1.43 1.63 2.77 2.49 4.12.22.34.55.63.88.88.57.44 1.03.27 1.1-.48.1-.95.07-1.91.08-2.86.01-.72-.01-1.43.01-2.15.02-.71-.24-1.25-.77-1.72-2.62-2.34-5.23-4.7-7.84-7.06-2.48-2.24-2.08-1.97-1.07-4.16 2.86-6.2 7.66-10.07 14.32-11.59 1.17-.27 1.43-.03 1.45 1.28.02 1.49.01 2.99.01 4.48 0 .01-.01.01-.02.01zM64 32.17c-.05 1.83-1.99 3.78-3.7 3.71-2.11-.08-4.06-1.92-4.06-3.81 0-1.87 2.09-3.86 4.03-3.84 1.8.02 3.77 2.1 3.73 3.94zm-.62-.06c.02-1.78-1.52-3.3-3.29-3.26-1.71.03-3.18 1.5-3.18 3.19 0 1.79 1.51 3.29 3.29 3.27 1.7-.02 3.17-1.5 3.18-3.2z"
    />
    <path
      className="CSA_svg__st0"
      d="M61.51 33.86c-.31-.31-.62-.63-.94-.94-.62-.62-.71-.59-1.18.26-.12.21-.35.35-.53.52-.13-.19-.34-.36-.37-.56-.07-.4-.08-.82-.06-1.23.03-.63-.22-1.4.49-1.77.87-.44 1.75-.07 2.52.35.24.13.46.85.33 1.02-.58.75-.49 1.47.15 2.27-.14.03-.28.06-.41.08zm-.57-2.34c-.25-.35-.37-.66-.5-.67-.3-.02-.6.12-.91.19.08.2.13.55.24.57.31.05.64-.04 1.17-.09z"
    />
  </svg>
);
const ForwardRef = forwardRef(SvgCsa);
export default ForwardRef;
