import * as React from 'react';
import { SVGProps, Ref, forwardRef } from 'react';
const SvgOracle = (props: SVGProps<SVGSVGElement>, ref: Ref<SVGSVGElement>) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    viewBox="0 0 32 21"
    ref={ref}
    {...props}
  >
    <path
      fill="#C74634"
      d="M9.9 20.1c-5.5 0-9.9-4.4-9.9-9.9S4.4.3 9.9.3h11.6c5.5 0 9.9 4.4 9.9 9.9s-4.4 9.9-9.9 9.9H9.9m11.3-3.5c3.6 0 6.4-2.9 6.4-6.4 0-3.6-2.9-6.4-6.4-6.4h-11c-3.6 0-6.4 2.9-6.4 6.4s2.9 6.4 6.4 6.4h11"
    />
  </svg>
);
const ForwardRef = forwardRef(SvgOracle);
export default ForwardRef;
