import { IConditionOperatorsInfo } from '../FilterTree.interface';
import i18next from 'i18next';
import { FilterConditionOperator } from '../FilterCondition';

export enum DefaultFilterConditionType {
    SELECTION = 'selection',
    FREE_TEXT = 'freeText',
}

export const DefaultConditionOperatorsMap: { [key in DefaultFilterConditionType]: IConditionOperatorsInfo } = {
    selection: {
        operators: [
            FilterConditionOperator.In,
            FilterConditionOperator.NotIn,
        ],
        defaultOperator: FilterConditionOperator.In,
    },
    freeText: {
        operators: [
            FilterConditionOperator.Contains,
            FilterConditionOperator.NotContains,
        ],
        defaultOperator: FilterConditionOperator.Contains,
    },
};

export const defaultFreeTextValueChecker = (values: any[]) => {
    if (values.length === 0) {
        return i18next.t('FILTER_TREE.ERROR_MSG.CONDITION_HAS_NO_VALUE');
    } else {
        if (!values[0].trim()) {
            return i18next.t('FILTER_TREE.ERROR_MSG.CONDITION_HAS_EMPTY_VALUE');
        }
    }
};
