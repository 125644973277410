import { addMainMenuItem } from '../../helpers/menu';
import { isAfErmComponentsExamples } from '../../utils/debugUtils';
import { IReactPageRoute } from '../../interface/routing';
import { globalAddinContainer } from '../../extensibility/AddinContainer';
import { REACT_PAGE_ROUTE } from '../../extensibility/WellKnownPaths';
import { RecipeEditorPage } from './RecipeFormExample/RecipeEditorPage/RecipeEditorPage';
import { initializeRecipeEditorDrawer } from './RecipeFormExample/RecipeEditor.initialize';
import GslQueryFilterExample from './GslQueryExample/GslQueryFilterExample';

const RECIPES_FORM_EXAMPLE_PAGE_URL = '/risk-management/custom-form-example';
const GSL_QUERY_EXAMPLE_PAGE_URL = '/gsl-query-examples';

const initializeMenu = () => {
    addMainMenuItem({
        id: 'erm-components-example',
        position: 0,
        icon: 'risk-management_4',
        label: 'ERM Examples',
        permission: () => isAfErmComponentsExamples(),
        sections: [
            {
                id: 'default',
                position: 0,
                items: [
                    {
                        id: 'recipes form example',
                        position: 0,
                        label: 'Recipes Form',
                        state: RECIPES_FORM_EXAMPLE_PAGE_URL,
                        permission: () => isAfErmComponentsExamples(),
                    },
                    {
                        id: 'gsl query examples',
                        position: 30,
                        label: 'GSL Query Examples',
                        state: GSL_QUERY_EXAMPLE_PAGE_URL,
                        permission: () => isAfErmComponentsExamples(),
                    }
                ],
            },

        ],
    });

    const recipesFormExamplePage = (): IReactPageRoute => {
        return {
            condition: isAfErmComponentsExamples(),
            component: RecipeEditorPage,
            path: RECIPES_FORM_EXAMPLE_PAGE_URL,
        };
    };

    const gslQueryExamplePage = (): IReactPageRoute => {
        return {
            condition: isAfErmComponentsExamples(),
            component: GslQueryFilterExample,
            path: GSL_QUERY_EXAMPLE_PAGE_URL,
        };
    };

    globalAddinContainer.add(REACT_PAGE_ROUTE, [
        { id: 'RECIPES_FORM_EXAMPLE_PAGE', content: recipesFormExamplePage },
        { id: 'GSL_QUERY_EXAMPLE_PAGE', content: gslQueryExamplePage },
    ]);
};

export const initializeErmComponentsExamples = () => {
    initializeMenu();
    initializeRecipeEditorDrawer();
};