import React, { useMemo } from 'react';
import { IMultiSelectorItem, IMultiSelectorSettings } from './MultiSelector.interface';
import { getAllTreeItems } from './MultiSelector.utils';
import MultiSelectorDropdown from './MultiSelectorDropdown';
import { IOrganizationalUnit } from '../../interface/data_services';

const MultiSelectorTreeDropdown: React.FC<{
    organizationalUnitsRoot?: IOrganizationalUnit,
    isEdit?: boolean,
    selectedIds?: string[],
    nonSelectableIds?: string[],
    onSelectionChanged: (selectedIds: string[]) => void,
    settings?: IMultiSelectorSettings,
    nonSelectableReason?: string,
    placeholder?: string,
}> = ({
    organizationalUnitsRoot, isEdit = false, selectedIds = [], nonSelectableIds, onSelectionChanged, settings, nonSelectableReason, placeholder,
}) => {
    const treeItems: IMultiSelectorItem[] = useMemo(() => organizationalUnitsRoot ? getAllTreeItems(organizationalUnitsRoot) : [], [organizationalUnitsRoot]);
    return (
        <MultiSelectorDropdown
            isTree={true}
            items={treeItems}
            isEdit={isEdit}
            placeholder={placeholder}
            selectedIds={selectedIds}
            nonSelectableIds={nonSelectableIds}
            nonSelectableReason={nonSelectableReason}
            onSelectionChanged={onSelectionChanged}
            settings={settings} />
    );
};

export default MultiSelectorTreeDropdown;

