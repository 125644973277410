import styled, { css } from 'styled-components';

const TopDiv = styled.div<{ isLink: boolean, width?: string }>`
  display: flex;
  flex-direction: row;
  height: 48px;
  gap: 12px;
  cursor: ${props => props.isLink ? 'pointer' : 'default'};
  width: ${props => props.width};
`;

const BarDiv = styled.div<{ color: string }>`
  display: flex;
  width: 4px;
  height: 100%;
  border-right: 4px;
  border-radius: 10px;
  background: ${props => props.color};
`;

const BodyDiv = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: flex-start;
  height: 100%;
  width: 50px;
  gap: 7px;
`;

const TextDiv = styled.div`
  display: flex;
  font-size: 12px;
  line-height: 16px;
  color: ${({ theme }) => theme.palette.text.light};
  font-weight: 500;
`;

const CounterDiv = styled.div<{ isLink: boolean }>`
  display: flex;
  font-size: 24px;
  line-height: 32px;
  font-weight: 400;

  ${({ isLink, theme }) => isLink && css`
    &:hover {
      cursor: pointer;
      color: ${theme.palette.onSurface.brandPrimary};
      text-decoration: underline;
    }
  `}
`;


export const BoxStyle = {
    TopDiv,
    BarDiv,
    BodyDiv,
    TextDiv,
    CounterDiv,
};
