import React, { useCallback, useEffect, useRef, useState } from 'react';
import {
    IFilterPanelProps,
    IFiltersShowView,
    IFiltersUtilsList,
    IFiltersValues,
    IHandlersUtilsList,
    IHandlersValues,
    ILastEvent,
    IRenderedFilter,
    IRenderedHandler,
} from './FilterPanel.interface';
import { FILTER_DISPAY_TYPES, FILTER_EVENTS, FILTER_TYPES, FILTERS_KEYS } from './FilterPanel.consts';

import './FilterPanel.scss';
import FilterPanelBox from './FilterViews/FilterPanelBox';
import FilterPanelRow from './FilterViews/FilterPanelRow';
import { profileComponent } from '../profileComponent';

export const FilterPanel: React.FC<{ filterPanelProps: IFilterPanelProps }> = ({ filterPanelProps }) => {
    const [lastEvent, setLastEvent] = useState({} as ILastEvent);
    const [filtersValues, setFiltersValues] = useState({} as IFiltersValues);
    const [handlersValues, setHandlersValues] = useState({} as IHandlersValues);
    const [handlersUtils, setHandlersUtils] = useState({} as IHandlersUtilsList);
    const [filtersUtils, setFiltersUtils] = useState({} as IFiltersUtilsList);
    const [isShowPanelViews, setIsShowPanelViews] = useState({} as IFiltersShowView);
    const panelViews = filterPanelProps.views || [{
        key: FILTER_DISPAY_TYPES.ROW,
        component: FilterPanelRow,
    }, { key: FILTER_DISPAY_TYPES.BOX, component: FilterPanelBox }];
    const [changeFilterTimeOut, setChangeFilterTimeOut] = useState({} as any);
    const [defaultSearchTerm, setDefaultSearchTerm] = useState('');
    const { filters, onFilterPanelChange, onFilterPanelAsyncChangeFinished,onFilterPanelAsyncChangeStarted } = filterPanelProps;
    const refFilters = useRef<any>();
    const initMemo = useCallback(() => {

        function init() {
            const filtersValues = {} as IFiltersValues;
            const handlersValues = {} as IHandlersValues;
            const filtersUtils = {} as IFiltersUtilsList;
            const handlersUtils = {} as IHandlersUtilsList;
            Object.values(filters).forEach((filter) => {
                const { filterProps, type } = filter;
                switch (type) {
                    case FILTER_TYPES.FILTER:
                    case FILTER_TYPES.FILTER_OUTSIDE:
                        filtersValues[filterProps.key] = filterProps.value || filterProps.defaultValue || '';
                        filtersUtils[filterProps.key] = (filter as IRenderedFilter).filterUtils;
                        break;
                    case FILTER_TYPES.HANDLER:
                    case FILTER_TYPES.HANDLER_CLEAR:
                    case FILTER_TYPES.HANDLER_OUTSIDE:
                        handlersUtils[filterProps.key] = (filter as IRenderedHandler).handlerUtils;
                        handlersValues[filterProps.key] = {
                            data: handlersUtils[filterProps.key].getHandlerInitialValue
                                ? handlersUtils[filterProps.key].getHandlerInitialValue!()
                                : '',
                            value: '',
                        };
                        break;
                    default:
                        break;
                }
            });
            setFiltersValues(filtersValues);
            setFiltersUtils(filtersUtils);
            setHandlersUtils(handlersUtils);
            setHandlersValues(handlersValues);
            calcFiltersAndHandlersValues(handlersValues, handlersUtils, filtersValues);
            setIsShowPanelViews((oldState) => ({ ...oldState, 'row-view': true, 'separate-fields': true }));
            onEvent({ action: FILTER_EVENTS.FILTER_CHANGED, filterKey: FILTERS_KEYS.ALL, payload: filtersValues });
        }

        init();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [filters]);

    useEffect(() => {
        initMemo();
    }, [filters, initMemo]);


    const onEvent = useCallback(async ({ action, filterKey, payload }: ILastEvent) => {
        let responseFromPage = true as any; //{}
        switch (action) {
            case FILTER_EVENTS.SHOW_VIEW: {
                const isShowViews = { ...isShowPanelViews };
                isShowViews[payload.view] = payload.showview || !isShowViews[payload.view];
                setIsShowPanelViews(isShowViews);
                setDefaultSearchTerm(payload.searchTerm);
                setLastEvent({ ...lastEvent, action: action, payload: payload, filterKey: filterKey });
                break;
            }
            case FILTER_EVENTS.FILTER_CHANGED:
                refFilters.current = { ...filtersValues };
                filterKey === FILTERS_KEYS.ALL ? (refFilters.current = { ...refFilters.current, ...payload }) : (refFilters.current[filterKey] = payload);
                return new Promise((resolve) => {
                    changeFilterTimeOut && clearTimeout(changeFilterTimeOut);
                    const timeout = setTimeout(async () => {
                        onFilterPanelAsyncChangeStarted && await onFilterPanelAsyncChangeStarted({ action, filterKey, filtersValues: refFilters.current, payload });
                        const data = await onFilterPanelChange({
                            action,
                            filterKey,
                            filtersValues: refFilters.current,
                            payload,
                        });
                        onFilterPanelAsyncChangeFinished && await onFilterPanelAsyncChangeFinished({ action, filterKey, filtersValues: refFilters.current, payload });
                        if (data) {
                            setFiltersValues(refFilters.current);
                            calcFiltersAndHandlersValues(handlersValues, handlersUtils, refFilters.current);
                            setLastEvent({
                                action,
                                filterKey,
                                filtersValues: refFilters.current,
                                payload,
                                result: data,
                            });
                            resolve(data);
                        }
                    }, 500);
                    setChangeFilterTimeOut(timeout);
                });
            case FILTER_EVENTS.HANDLER_CHANGED: {
                let handlers = { ...handlersValues };
                filterKey === FILTERS_KEYS.ALL
                    ? (handlers = { ...handlers, ...payload })
                    : (handlers[filterKey] = payload);
                setHandlersValues(handlers);
                break;
            }
            case FILTER_EVENTS.CLEAR_FILTERS: {
                const clearedFilters = { ...filtersValues };
                if (filterKey === FILTERS_KEYS.ALL) {
                    Object.keys(clearedFilters).forEach((filterValue) => {
                        clearedFilters[filterValue] = filtersUtils[filterValue]?.clear() || '';
                    });
                } else {
                    clearedFilters[filterKey] = filtersUtils[filterKey].clear() || '';
                }
                await onEvent({
                    action: FILTER_EVENTS.FILTER_CHANGED,
                    filterKey: FILTERS_KEYS.ALL,
                    payload: clearedFilters,
                });
                break;
            }

            default:
                responseFromPage = await onFilterPanelChange({ action, filterKey, filtersValues, payload });
                responseFromPage &&
                setLastEvent({ action, filterKey, filtersValues, payload, result: responseFromPage });
        }

        return responseFromPage;
    }, [changeFilterTimeOut, filtersUtils, filtersValues, handlersValues, isShowPanelViews, onFilterPanelChange, lastEvent]);

    function calcFiltersAndHandlersValues(handlersValuesObj: any, handlersUtilsObj: any, filtersValuesObj: any) {
        if (Object.keys(handlersValuesObj).length > 0) {
            const handlersNewValues = { ...handlersValuesObj };
            Object.keys(handlersUtilsObj).forEach((key) => {
                const { onFiltersChanged } = handlersUtilsObj[key];
                handlersNewValues[key] = onFiltersChanged
                    ? onFiltersChanged(filtersValuesObj, handlersValuesObj[key].data)
                    : handlersValuesObj[key];
            });
            setHandlersValues(handlersNewValues);
        }
    }

    return (
        <div className='static'>
            <div data-aid='filter-panel-container'>
                {panelViews.map(view => {
                    return isShowPanelViews[view.key] && <view.component key={view.key} filterViewProps={{
                        filters,
                        filtersValues,
                        handlersValues,
                        onEvent,
                        lastEvent,
                        filtersUtils,
                        defaultSearchTerm,
                    }} />;
                })}
            </div>
        </div>
    );
};

export default profileComponent(FilterPanel);
