
interface IDrawerEventHandler {
    id: string;
    handleEvent: (event: any) => void;
}

class DrawerMessagesBus {
    private readonly eventHandlersMap: { [key: string]: IDrawerEventHandler } = {};

    public registerEventHandler(eventHandlerId: string, handleEvent: (event?: any) => void) {
        this.eventHandlersMap[eventHandlerId] = {
            id: eventHandlerId,
            handleEvent,
        };
    }

    public unregisterEventHandler(eventHandlerId: string) {
        if (this.eventHandlersMap[eventHandlerId]) {
            delete this.eventHandlersMap[eventHandlerId];
        }
    }

    public sendEvent(eventHandlerId: string, event?: any) {
        const eventHandler = this.eventHandlersMap[eventHandlerId];
        if (eventHandler) {
            eventHandler.handleEvent(event);
        }
    }
}
export const DrawerBus: DrawerMessagesBus = new DrawerMessagesBus();