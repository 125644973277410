import * as React from 'react';
import { SVGProps, Ref, forwardRef } from 'react';
const SvgMalicious = (
  props: SVGProps<SVGSVGElement>,
  ref: Ref<SVGSVGElement>
) => (
  <svg
    viewBox="0 0 16 16"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    ref={ref}
    {...props}
  >
    <path
      d="M5.5 14v1.5m2-1.5v1.5m0-5v1m2 2.5v1.5m0 0h1a1 1 0 0 0 1-1v-1.866c0-.527 2-.134 2.5-1.134.35-.7-.016-1.954-.268-2.646a1.87 1.87 0 0 1-.076-1.006C14.326 4.508 13.203.5 7.5.5 1.797.5.675 4.508 1.344 7.848c.067.336.04.685-.077 1.006C1.016 9.546.65 10.801 1 11.5c.5 1 2.5.607 2.5 1.134V14.5a1 1 0 0 0 1 1h5Z"
      stroke="currentColor"
      strokeLinecap="round"
    />
    <path
      d="M6.5 8a1.5 1.5 0 1 1-3 0 1.5 1.5 0 0 1 3 0ZM11.5 8a1.5 1.5 0 1 1-3 0 1.5 1.5 0 0 1 3 0Z"
      stroke="currentColor"
      strokeLinecap="round"
    />
  </svg>
);
const ForwardRef = forwardRef(SvgMalicious);
export default ForwardRef;
