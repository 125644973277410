import { SelectOption } from '../../../design-system/components-v2/SelectV2/Select.types';
import { getRecipeExampleService } from '../RecipeExampleCenter/RecipeExampleService';
import { IMeatCategory } from '../RecipeExampleCenter/FakeRecipeServer/FakeRecipeServer.data';
import { sleep } from '../../../utils/functions';
import { getOrganizationalUnitService, IOrganizationalUnit } from '../../../interface/data_services';

export const getOrgsRoot = async (): Promise<IOrganizationalUnit> => {
    return getOrganizationalUnitService().getOrganizationalUnitsView();
};
export const getSpiceOptions = async (): Promise<SelectOption[]> => {
    return (await getRecipeExampleService().getAllSpices()).map(spice => ({
        value: spice,
        label: spice,
        labelProps: { leadingIconProps: { name: 'partiallyConnectedStatus' } },
    }));
};
export const getVegetableOptions = async (): Promise<SelectOption[]> => {
    return (await getRecipeExampleService().getAllVegetables()).map(vegetable => ({
        value: vegetable,
        label: vegetable,
        labelProps: { leadingIconProps: { name: 'favorite' } },
    }));
};
export const getMeatCategoryOptions = async (): Promise<SelectOption[]> => {
    const allCategories: IMeatCategory[] = await getRecipeExampleService().getAllMeatCategories();

    return allCategories.map(category => ({
        value: category.name,
        label: category.name,
        labelProps: { leadingIconProps: { name: 'overview' } },
    }));
};
export const getMeatTypeOptionsByCategory = async (categoryName: string): Promise<SelectOption[]> => {
    const allCategories: IMeatCategory[] = await getRecipeExampleService().getAllMeatCategories();
    await sleep(100);
    const category: IMeatCategory | undefined = allCategories.find(cat => cat.name === categoryName);
    if (category) {
        return (category.meatTypes.map(meatType => ({
            value: meatType,
            label: meatType,
            labelProps: { leadingIconProps: { name: 'rocket' } },
        })));
    }
    return [];
};
export const getMeatTypeOptions = async (categoryName?: string): Promise<SelectOption[]> => {
    if (categoryName) {
        return await getMeatTypeOptionsByCategory(categoryName);
    }
    return [];
};

export const getMeatAlternativesOptions = async (): Promise<SelectOption[]> => {
    return (await getRecipeExampleService().getAllMeatAlternatives()).map(meatAlternatives => ({
        value: meatAlternatives,
        label: meatAlternatives,
        labelProps: { leadingIconProps: { name: 'malicious' } },
    }));
};
export const getDairyProductsOptions = async (): Promise<SelectOption[]> => {
    return (await getRecipeExampleService().getAllDairyProducts()).map(dairyProduct => ({
        value: dairyProduct,
        label: dairyProduct,
        labelProps: { leadingIconProps: { name: 'package' } },
    }));
};
export const getChefOptions = async (freeText?: string): Promise<SelectOption[]> => {
    const allChefs = await getRecipeExampleService().getAllChefs();
    const filteredChef = freeText ? allChefs.filter(chef => chef.toLowerCase().includes(freeText.toLowerCase())) : allChefs;
    return filteredChef.map(chef => ({
        value: chef,
        label: chef,
        labelProps: { leadingIconProps: { name: 'package' } },
    }));
};