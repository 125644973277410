import { buildPath, globalAddinContainer } from '../../extensibility/AddinContainer';
import { IEventOverviewPanelProvider } from './Events';
import { Addin } from '../../extensibility/AddinRegistry';
import { IProtectedAssetDetailProvider } from '../assets/ProtectedAssets';
import { InfoPaneFieldProps } from '../../components/InfoPane/InfoPane';

const PATH_EVENT_OVERVIEW_PANELS = buildPath('events', 'event-overview');
const PATH_FINDING_ASSET_PANEL_FIELDS = buildPath(PATH_EVENT_OVERVIEW_PANELS, 'fields');

export const EventOverviewRegistry = {
    addPanelProvider: (content: IEventOverviewPanelProvider, position: number) => {
        globalAddinContainer.add<IEventOverviewPanelProvider>(PATH_EVENT_OVERVIEW_PANELS, [{
            id: content.panelId,
            position,
            content,
        }]);
    },

    getPanelProviders: (): IEventOverviewPanelProvider[] => {
        return globalAddinContainer.get<IEventOverviewPanelProvider>(PATH_EVENT_OVERVIEW_PANELS);
    },

    addFieldAddins: (fieldAddins: Addin<IProtectedAssetDetailProvider<InfoPaneFieldProps>>[], panelId: string) => {
        const fullPath = buildPath(PATH_FINDING_ASSET_PANEL_FIELDS, panelId);
        globalAddinContainer.add<IProtectedAssetDetailProvider<InfoPaneFieldProps>>(fullPath, fieldAddins);
    },

    getFields: (panelId: string): IProtectedAssetDetailProvider<InfoPaneFieldProps>[] => {
        const fullPath = buildPath(PATH_FINDING_ASSET_PANEL_FIELDS, panelId);
        return globalAddinContainer.get<IProtectedAssetDetailProvider<InfoPaneFieldProps>>(fullPath);
    },

};
