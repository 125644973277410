import * as React from 'react';
import { SVGProps, Ref, forwardRef } from 'react';
const SvgPresetRowGroup = (
  props: SVGProps<SVGSVGElement>,
  ref: Ref<SVGSVGElement>
) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    viewBox="0 0 16 16"
    fill="none"
    ref={ref}
    {...props}
  >
    <path
      d="M8.5 3.5h4m-4 3h4m-4 3h4m-9-6h2m3 9h4M2.5 15.5h11a2 2 0 0 0 2-2v-11a2 2 0 0 0-2-2h-11a2 2 0 0 0-2 2v11a2 2 0 0 0 2 2Z"
      stroke="#003570"
      strokeOpacity={0.9}
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </svg>
);
const ForwardRef = forwardRef(SvgPresetRowGroup);
export default ForwardRef;
