import * as React from 'react';
import { SVGProps, Ref, forwardRef } from 'react';
const SvgArchive = (
  props: SVGProps<SVGSVGElement>,
  ref: Ref<SVGSVGElement>
) => (
  <svg
    viewBox="0 0 16 16"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    ref={ref}
    {...props}
  >
    <rect x={2.5} y={0.5} width={11} height={15} rx={2} stroke="currentColor" />
    <path
      d="M13.5 5.5h-11m0 5h11m-6-8h1m-1 5h1m-1 5h1"
      stroke="currentColor"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </svg>
);
const ForwardRef = forwardRef(SvgArchive);
export default ForwardRef;
