
class EnvironmentVariables {
    private _app: { [key: string]: string } | undefined;
    private isInitialized = false;

    get app(): { [key: string]: string } {
        return this._app ?? {};
    }

    async init() {
        if (!this.isInitialized) {
            this._app = {};
            const env = process.env.REACT_APP_DEV_ENV;
            if (env) {
                this._app = await this.resolveEnvironmentVariablesInDevelopmentMode(env);
            } else {
                this._app = await this.resolveEnvironmentVariables();
            }
            this.isInitialized = true;
            Object.freeze(this._app);
        }
        return this._app;
    }

    private async resolveEnvironmentVariables(): Promise<any> {
        const configFile = 'config/config.json';
        const response = await fetch(configFile);
        return await response.json();
    }

    private async resolveEnvironmentVariablesInDevelopmentMode(env: string): Promise<any> {
        const configFile = '/config/config.dev.json';
        const response = await fetch(configFile);
        const allEnvironments = await response.json();
        return env.startsWith('preqa') ? this.resolvePreQAConfig(env, allEnvironments) : allEnvironments[env];
    }

    private resolvePreQAConfig(env: string, allEnvironments: any) {
        const accountId = env.split('-')[1];
        const preqaConfig = allEnvironments['preqa'];
        for (const key in preqaConfig) {
            if (Object.hasOwn(preqaConfig, key)) {
                preqaConfig[key] = preqaConfig[key].replaceAll('{ACCOUNT_ID}', accountId);
            }
        }
        return preqaConfig;
    }
}

export default new EnvironmentVariables();
