import * as React from 'react';
import { SVGProps, Ref, forwardRef } from 'react';
const SvgExport = (props: SVGProps<SVGSVGElement>, ref: Ref<SVGSVGElement>) => (
  <svg
    viewBox="0 0 16 16"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    ref={ref}
    {...props}
  >
    <path
      d="M15 8a7 7 0 1 1-7-7m-.104 8.783c-.35-2.107.187-3.673 1.14-4.626.954-.954 2.42-1.39 4.131-1.048m-1.74 3.396 2.097-2.725a.483.483 0 0 0-.088-.677L10.71 2.006"
      stroke="currentColor"
      strokeLinecap="round"
    />
  </svg>
);
const ForwardRef = forwardRef(SvgExport);
export default ForwardRef;
