import * as React from 'react';
import { SVGProps, Ref, forwardRef } from 'react';
const SvgIssue = (props: SVGProps<SVGSVGElement>, ref: Ref<SVGSVGElement>) => (
  <svg
    viewBox="0 0 16 16"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    ref={ref}
    {...props}
  >
    <path
      d="M7.5 3.5v6m.309 2.469a.317.317 0 0 1-.088.22.3.3 0 0 1-.427.006.317.317 0 0 1-.014-.439.301.301 0 0 1 .427-.02c.06.054.096.131.101.213M8.715.892c1.203.856 2.33 1.447 3.787 1.63.548.069.998.527.998 1.103V8.84c0 3.76-3.85 5.846-5.382 6.527a1.505 1.505 0 0 1-1.236 0C5.35 14.686 1.5 12.6 1.5 8.84V3.625c0-.576.45-1.034.998-1.103 1.458-.183 2.584-.774 3.787-1.63a2.08 2.08 0 0 1 2.43 0Z"
      stroke="currentColor"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </svg>
);
const ForwardRef = forwardRef(SvgIssue);
export default ForwardRef;
