import * as React from 'react';
import { SVGProps, Ref, forwardRef } from 'react';
const SvgAddSection = (
  props: SVGProps<SVGSVGElement>,
  ref: Ref<SVGSVGElement>
) => (
  <svg
    viewBox="0 0 16 16"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    ref={ref}
    {...props}
  >
    <path
      d="M9.5 12.5h6m-3-3v6M.5 12.5h7M.5 7.5h15m-15-5h15"
      stroke="currentColor"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </svg>
);
const ForwardRef = forwardRef(SvgAddSection);
export default ForwardRef;
