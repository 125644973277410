import * as React from 'react';
import { SVGProps, Ref, forwardRef } from 'react';
const SvgTips = (props: SVGProps<SVGSVGElement>, ref: Ref<SVGSVGElement>) => (
  <svg
    viewBox="0 0 16 16"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    ref={ref}
    {...props}
  >
    <path
      d="M9.437 7.5a2 2 0 0 1-3.874 0"
      stroke="currentColor"
      strokeLinecap="round"
    />
    <path
      d="M7.5 10.5V9"
      stroke="currentColor"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M9.5 12c0-.556.34-1.046.801-1.358a5 5 0 1 0-5.602 0c.46.312.801.802.801 1.358"
      stroke="currentColor"
    />
    <path
      d="M5.5 12v2A1.5 1.5 0 0 0 7 15.5h1A1.5 1.5 0 0 0 9.5 14v-2M5.5 12.5h4"
      stroke="currentColor"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </svg>
);
const ForwardRef = forwardRef(SvgTips);
export default ForwardRef;
