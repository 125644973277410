import React from 'react';
import Chip from '../../design-system/components-v2/Chip';
import { useTranslation } from 'react-i18next';
import { getRiskLevelColorByScore, RiskLevelsMap } from '../../module_interface/RiskManagement/RiskLevel';

const RiskScoreBadge: React.FC<{ score: number; className?: string }> = ({
    score,
    className,
}) => {
    const { t } = useTranslation();

    return (
        <div className={`flex h-full items-center ${className}`}>
            <Chip
                label={t('COMMON.PRETTY_NUMBER', {
                    value: score,
                    minimumFractionDigits: 1,
                    maximumFractionDigits: 1,
                })}
                size="sm"
                minWidth={'32px'}
                customColor={getRiskLevelColorByScore(score) || RiskLevelsMap.unknown.color}
                disableInteraction
            />
        </div>
    );
};

export default RiskScoreBadge;
