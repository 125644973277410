import * as React from 'react';
import { SVGProps, Ref, forwardRef } from 'react';
const SvgImageProtection = (
  props: SVGProps<SVGSVGElement>,
  ref: Ref<SVGSVGElement>
) => (
  <svg
    viewBox="0 0 16 16"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    ref={ref}
    {...props}
  >
    <path
      d="m7.5 12-1.48.74a1 1 0 0 1-1.003-.062l-2.072-1.381a1 1 0 0 1-.445-.832V6.618a1 1 0 0 1 .553-.894L9.98 2.26a1 1 0 0 1 1.002.062l2.072 1.381a1 1 0 0 1 .445.832V6"
      stroke="currentColor"
      strokeLinecap="round"
    />
    <path
      d="M5.5 13V8.118a1 1 0 0 0-.553-.894L2.5 6"
      stroke="currentColor"
      strokeLinejoin="round"
    />
    <path
      d="M7.5 7.5v3m2-4V7m2-1.5V6"
      stroke="currentColor"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M8 14.5H1.5a1 1 0 0 1-1-1v-12a1 1 0 0 1 1-1h13a1 1 0 0 1 1 1v4.75"
      stroke="currentColor"
      strokeLinecap="round"
    />
    <path
      d="M9.5 8.567c1.09 0 2.182-.534 3-1.067.818.533 1.91 1.067 3 1.067v3.466c0 2.134-3 3.467-3 3.467s-3-1.333-3-3.467V8.567Z"
      stroke="currentColor"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </svg>
);
const ForwardRef = forwardRef(SvgImageProtection);
export default ForwardRef;
