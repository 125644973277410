import React from 'react';
import cpLogo from '../../../assets/images/check-point-logo-new.svg';
import './CheckpointLogoHeader.scss';
import { Link } from 'react-router-dom';

export const CheckpointLogoHeader: React.FC<{}> = () => {
    return (
        <div className='login-header'>
            <Link className='login-header__logo' to='./'>
                <img src={cpLogo} alt='Check Point Infinity Portal' />
            </Link>
        </div>
    );
};

export default CheckpointLogoHeader;
