import React, { useCallback } from 'react';
import { EntityHeader, Stack, Typography } from 'common/design-system/components-v2';
import { EntityHeaderProps } from 'common/design-system/components-v2/EntityHeader/EntityHeader.types';
import { TopSection } from './EntityCard.styled';
import { BlockInfoProps } from 'common/design-system/components-v2/BlockInfo/BlockInfo.types';
import { useTranslation } from 'react-i18next';
import { isEmpty } from 'lodash';
import { EntityCardProps } from './EntityCard.types';

const EntityCard: React.FC<EntityCardProps> = props => {
    const { cardTitle, blocks, levelIcon, ...rest } = props;

    const { t } = useTranslation();
    const NA_STRING = t('COMMON.N_A');
    
    const [isShowMoreExpanded, setIsShowMoreExpanded] = React.useState<boolean>(!!props.disableShowMore || !!props.initialShowMoreState);

    const getBlocks = useCallback((): BlockInfoProps[] => {
        return blocks.map(block => {
            return {
                ...block,
                info: isEmpty(block.info) ? NA_STRING : block.info,
            };
        });
    }, [NA_STRING, blocks]);

    const entityHeaderProps = React.useMemo((): EntityHeaderProps => {
        return {
            titleProps: {
                variant: isShowMoreExpanded ? 'subtitleLg' : 'body500',
            },
            levelIcon: levelIcon && {
                ...levelIcon,
                size: isShowMoreExpanded ? 'xl' : 'md'
            },
            blocks: getBlocks(),
            ...rest
        };
    }, [getBlocks, levelIcon, isShowMoreExpanded, rest]);

    return (
        <Stack spacing={1} data-aid='entity-card-wrapper'>
            {cardTitle && <Typography variant={'body500'} data-aid='entity-card-title'>{cardTitle}</Typography>}
            <TopSection padding={isShowMoreExpanded ? 6 : 2} data-aid='entity-card-content'>
                <EntityHeader {...entityHeaderProps} onShowMoreStateChange={setIsShowMoreExpanded} />
            </TopSection>
        </Stack>
    );
};

export default EntityCard;