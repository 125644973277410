import * as React from 'react';
import { SVGProps, Ref, forwardRef } from 'react';
const SvgSecurityEvent = (
  props: SVGProps<SVGSVGElement>,
  ref: Ref<SVGSVGElement>
) => (
  <svg
    viewBox="0 0 16 16"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    ref={ref}
    {...props}
  >
    <path
      d="M11.5 2.5V5m1.753 5c-.914 2.73-4.118 4.513-5.551 5.192a1.628 1.628 0 0 1-1.403 0C4.619 14.397.5 12.082.5 8.5V3.998c0-.552.45-.989.998-1.06 2.115-.271 3.16-1.396 5.084-2.26.266-.12.57-.12.836 0M11.5 6.51V6.5m4-2a4 4 0 1 1-8 0 4 4 0 0 1 8 0Z"
      stroke="currentColor"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </svg>
);
const ForwardRef = forwardRef(SvgSecurityEvent);
export default ForwardRef;
