import { buildPath } from 'common/extensibility/AddinContainer';

export const DASHBOARD_ITEMS = buildPath('dashboard', 'items');
export const DASHBOARD_DYNAMIC_WIDGETS = buildPath('overview', 'dynamicWidgets');
export const OVERVIEW_MENU_ITEM = 'overview';
export const FAVORITES_MENU_SECTION = 'favorites';
export const DEFAULT_LOCAL_DASHBOARD_ID = 'Home Dashboard';
export const DEFAULT_GLOBAL_HOME_PAGE_ID = 'Global Home Dashboard';
export const HOME_DASHBOARD_DEFAULT_CUSTOMIZATION_KEY = 'HOME_DASHBOARD_DEFAULT';

export const ALL_DASHBOARDS_CACHE_TAG = 'ALL_DASHBOARDS_CACHE_TAG';
export const DEFAULT_DASHBOARD_CACHE_TAG = 'DEFAULT_DASHBOARD_CACHE_TAG';
export const FAVORITES_DASHBOARD_CACHE_TAG = 'FAVORITES_DASHBOARD_CACHE_TAG';
