import * as React from 'react';
import { SVGProps, Ref, forwardRef } from 'react';
const SvgSeverityUnknown = (
  props: SVGProps<SVGSVGElement>,
  ref: Ref<SVGSVGElement>
) => (
  <svg
    viewBox="0 0 16 16"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    ref={ref}
    {...props}
  >
    <rect y={10} width={3} height={3} rx={1.5} fill="#C2CBDA" />
    <rect x={4} y={7} width={3} height={6} rx={1.5} fill="#C2CBDA" />
    <rect x={8} y={4} width={3} height={9} rx={1.5} fill="#C2CBDA" />
    <rect x={12} y={1} width={3} height={12} rx={1.5} fill="#C2CBDA" />
  </svg>
);
const ForwardRef = forwardRef(SvgSeverityUnknown);
export default ForwardRef;
