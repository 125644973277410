import * as React from 'react';
import { SVGProps, Ref, forwardRef } from 'react';
const SvgCiem = (props: SVGProps<SVGSVGElement>, ref: Ref<SVGSVGElement>) => (
  <svg
    viewBox="0 0 16 16"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    ref={ref}
    {...props}
  >
    <path
      d="M4.5.5h-2a2 2 0 0 0-2 2v2m11-4h2a2 2 0 0 1 2 2v2m-15 7v2a2 2 0 0 0 2 2h2m7 0h2a2 2 0 0 0 2-2v-2M11.5 11.5 13 10m-3-.003L12 8M12.5 12.5 7 7"
      stroke="currentColor"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <circle cx={5.5} cy={5.5} r={2} stroke="currentColor" />
  </svg>
);
const ForwardRef = forwardRef(SvgCiem);
export default ForwardRef;
