import { IDateFilterOption, ITimeFromTo } from '../DefaultFilters.interface';
import i18next from 'i18next';

export const TIME_RANGES_TYPES = {
    HOURS: 'hours',
    DAYS: 'days',
};

export enum TimePresets {
    FOUR_HOURS = '4 Hrs',
    TWELVE_HOURS = '12 Hrs',
    ONE_DAY = '24 Hrs',
    WEEK = '7 Days',
    THIRTY_DAYS = '30 Days',
    ALL = 'All',
    CUSTOM = 'Custom',
}

export const DATE_FILTER_TABS = {
    PRESETS: 'presets',
    CUSTOM: 'custom',
};
export const computeRangeFromSelection = (selectedDate: any): ITimeFromTo => {
    if (selectedDate.epoch) {
        return {
            from: selectedDate.epoch.from,
            to: selectedDate.epoch.to,
        };
    } else {
        const from = new Date();
        const { type, count } = selectedDate;
        switch (type) {
            case TIME_RANGES_TYPES.HOURS:
                from.setHours(from.getHours() - count);
                break;
            case TIME_RANGES_TYPES.DAYS:
                from.setDate(from.getDate() - count);
                break;
        }
        return { from: from.toISOString(), to: new Date().toISOString() };
    }
};

export const convertOldDateObjToNew = (selectedFilter: any): IDateFilterOption => {
    const newDateObjs = {} as IDateFilterOption;
    Object.values(DEFAULT_RANGES_VALUES).forEach((value) => {
        if (selectedFilter.name === value.key) {
            const { key, displayName, type, count } = value;
            newDateObjs.key = key;
            newDateObjs.displayName = displayName;
            newDateObjs.count = count;
            newDateObjs.type = type;
            newDateObjs.isNewDateFilter = true;
            newDateObjs.epoch = computeRangeFromSelection(
                key === TimePresets.CUSTOM ? selectedFilter.epoch : value
            );
        }
    });
    return newDateObjs;
};

export const DEFAULT_RANGES_VALUES = {
    FOUR_HOURS: {
        displayName: i18next.t('FILTER_PANEL.DATE_FILTER.PRESETS.4_HOURS'),
        type: TIME_RANGES_TYPES.HOURS,
        count: 4,
        key: TimePresets.FOUR_HOURS,
        isNewDateFilter: true,
    },
    TWELVE_HOURS: {
        displayName: i18next.t('FILTER_PANEL.DATE_FILTER.PRESETS.12_HOURS'),
        type: TIME_RANGES_TYPES.HOURS,
        count: 12,
        key: TimePresets.TWELVE_HOURS,
        isNewDateFilter: true,
    },
    ONE_DAY: {
        displayName: i18next.t('FILTER_PANEL.DATE_FILTER.PRESETS.24_HOURS'),
        type: TIME_RANGES_TYPES.HOURS,
        count: 24,
        key: TimePresets.ONE_DAY,
        isNewDateFilter: true,
    },
    WEEK: {
        displayName: i18next.t('FILTER_PANEL.DATE_FILTER.PRESETS.7_DAYS'),
        type: TIME_RANGES_TYPES.DAYS,
        count: 7,
        key: TimePresets.WEEK,
        isNewDateFilter: true,
    },
    THIRTY_DAYS: {
        displayName: i18next.t('FILTER_PANEL.DATE_FILTER.PRESETS.30_DAYS'),
        type: TIME_RANGES_TYPES.DAYS,
        count: 30,
        key: TimePresets.THIRTY_DAYS,
        isNewDateFilter: true,
    },
    ALL: {
        displayName: i18next.t('FILTER_PANEL.DATE_FILTER.PRESETS.ALL'),
        type: TIME_RANGES_TYPES.DAYS,
        count: Math.floor((new Date().getTime() - new Date(0).getTime() ) / (1000 * 60 * 60 * 24)),
        key: TimePresets.ALL,
        isNewDateFilter: true,
    },
    CUSTOM: { displayName: 'Custom', type: TIME_RANGES_TYPES.DAYS, count: 0, key: TimePresets.CUSTOM },
};
