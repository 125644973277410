import { Addin } from 'common/extensibility/AddinRegistry';
import globalAddinContainer, { buildPath } from '../extensibility/AddinContainer';
import {
    OciEnvironmentAction,
    OciEnvironmentData,
    OciEnvironmentTab,
    PATH_ENVIRONMENT_PAGE,
    PATH_ENVIRONMENT_PAGE_ACTION,
    PATH_ENVIRONMENT_PAGE_OCI_DETAILS_PANEL,
    PATH_ENVIRONMENT_PAGE_OCI_TAB,
    PATH_ENVIRONMENT_PAGE_OCI_CHIP,
} from 'common/module_interface/assets/OciEnvironment';
import { BlockInfoProps } from 'common/design-system/components-v2/BlockInfo/BlockInfo.types';
import { EnvironmentChip } from '../module_interface/assets/Environments';


export interface OciEnvironmentAddingBase<T> {
    isRelevant?: (ociData: OciEnvironmentData) => Promise<boolean> | boolean;
    getValue: (ociData: OciEnvironmentData) => Promise<T> | T;
    id: string;
    customLoadingLabel?: string; // Relevant for chips only at the moment
}

function addDetailsPanelItems(item: Addin<OciEnvironmentAddingBase<BlockInfoProps>>[]) {
    globalAddinContainer.add(buildPath(PATH_ENVIRONMENT_PAGE, PATH_ENVIRONMENT_PAGE_OCI_DETAILS_PANEL), item);
}

function getDetailsPanelItems(): OciEnvironmentAddingBase<BlockInfoProps>[] {
    return globalAddinContainer.get(buildPath(PATH_ENVIRONMENT_PAGE, PATH_ENVIRONMENT_PAGE_OCI_DETAILS_PANEL));
}

function addTabs(item: Addin<OciEnvironmentAddingBase<OciEnvironmentTab>>[]) {
    globalAddinContainer.add(buildPath(PATH_ENVIRONMENT_PAGE, PATH_ENVIRONMENT_PAGE_OCI_TAB), item);
}

function getTabs(): OciEnvironmentAddingBase<OciEnvironmentTab>[] {
    return globalAddinContainer.get(buildPath(PATH_ENVIRONMENT_PAGE, PATH_ENVIRONMENT_PAGE_OCI_TAB));
}

function addActions(item: Addin<OciEnvironmentAddingBase<OciEnvironmentAction>>[]) {
    globalAddinContainer.add(buildPath(PATH_ENVIRONMENT_PAGE, PATH_ENVIRONMENT_PAGE_ACTION), item);
}

function getActions(): OciEnvironmentAddingBase<OciEnvironmentAction>[] {
    return globalAddinContainer.get(buildPath(PATH_ENVIRONMENT_PAGE, PATH_ENVIRONMENT_PAGE_ACTION));
}

function addChips(item: Addin<OciEnvironmentAddingBase<EnvironmentChip>>[]) {
    globalAddinContainer.add(buildPath(PATH_ENVIRONMENT_PAGE, PATH_ENVIRONMENT_PAGE_OCI_CHIP), item);
}

function getChips(): OciEnvironmentAddingBase<EnvironmentChip>[] {
    return globalAddinContainer.get(buildPath(PATH_ENVIRONMENT_PAGE, PATH_ENVIRONMENT_PAGE_OCI_CHIP));
}

export const OciEnvironmentAddinRegistry = {
    addDetailsPanelItems,
    getDetailsPanelItems,
    addTabs,
    getTabs,
    addActions,
    getActions,
    addChips,
    getChips
};