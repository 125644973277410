import React, { FC, useCallback, useEffect, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { SelectOption } from 'common/design-system/components-v2/SelectV2/Select.types';
import Input from 'common/design-system/components-v2/Input';
import Stack from 'common/design-system/components-v2/Stack';
import IconButton from 'common/design-system/components-v2/IconButton';
import Popover from 'common/design-system/components-v2/Popover';
import List from 'common/design-system/components-v2/List';
import Chip from 'common/design-system/components-v2/Chip';
import ComponentOverflow from 'common/design-system/components-v2/ComponentOverflow';
import { IListItemProps } from 'common/design-system/components-v2/List/List.types';
import { FormStyled } from '../../CustomForm.styled';
import { FieldLabelComponent } from '../helperComponents/FieldLabelComponent';
import { IOnFieldChanged, IItemsMap, ILazySelectItem, IFullErrorInfo } from '../../CustomForm.interface';
import { getItemSelectPlaceholder } from '../../CustomForm.values';

export interface ILazySelectComponentProps {
    item: ILazySelectItem;
    itemsMap: IItemsMap,
    onFieldChanged: IOnFieldChanged;
    showErrors: boolean;
    errorInfo?: IFullErrorInfo;
    skipTitle?: boolean;
    isInner?: boolean;
}

export const LazySelectItemComponent: FC<ILazySelectComponentProps> = ({ item, itemsMap, onFieldChanged, skipTitle, isInner }) => {
    const { t } = useTranslation();
    const { state } = item;
    const inputAnchor = React.useRef<HTMLDivElement>(null);
    const [options, setOptions] = useState<SelectOption[]>([]);
    const [input, setInput] = useState<string>();
    const [selectedValuesVm, setSelectedValuesVm] = useState(state.value ?? []);
    const [selectedOptionsVm, setSelectedOptionsVm] = useState<SelectOption[]>([]);
    const [isOpen, setIsOpen] = React.useState(false);
    const [isLoadingOptions, setIsLoadingOptions] = React.useState(false);

    const getOptions = useCallback(async (freeText: string): Promise<SelectOption[]> => {
        const clean = freeText.replace(/%/g, '');

        if (freeText.startsWith('%') && freeText.endsWith('%')) {
            return [{ label: `${t('CUSTOM_FORM.COMPONENTS.CONTAINS')} ${clean}`, value: freeText }];
        }

        if (freeText.startsWith('%')) {
            return [{ label: `${t('CUSTOM_FORM.COMPONENTS.ENDS_WITH')} ${clean}`, value: freeText }];
        }

        if (freeText.endsWith('%')) {
            return [{ label: `${t('CUSTOM_FORM.COMPONENTS.STARTS_WITH')} ${clean}`, value: freeText }];
        }

        if (!freeText || freeText.length < 2) return [];
        return item.getLazyOptions(freeText, itemsMap);
    }, [item, itemsMap, t]);

    useEffect(() => {
        if (input === undefined) return;
        setIsLoadingOptions(true);
        getOptions(input)
            .then(option => setOptions(option))
            .finally(() => setIsLoadingOptions(false));
    }, [input, item, getOptions]);

    const handleOptionClick = useCallback((option: IListItemProps) => {
        const isRemoved = selectedOptionsVm.some(o => o.value === option.value);

        const newOptions = isRemoved ?
            selectedOptionsVm.filter(o => o.value !== option.value) :
            [option, ...selectedOptionsVm];
        setSelectedOptionsVm(newOptions);

        const newValues: string[] = isRemoved ?
            selectedValuesVm.filter(selectedValue => option.value !== selectedValue) :
            [option.value, ...selectedValuesVm];
        setSelectedValuesVm(newValues);
        onFieldChanged({ ...item, state: { ...state, value: newValues } });
    }, [item, onFieldChanged, selectedOptionsVm, selectedValuesVm, state]);

    const handleRemoveOptionValueClick = useCallback((optionValue: string) => {
        const newOptions = selectedOptionsVm.filter(o => o.value !== optionValue);
        setSelectedOptionsVm(newOptions);

        const newValues = selectedValuesVm.filter(selectedValue => optionValue !== selectedValue);
        setSelectedValuesVm(newValues);
        onFieldChanged({ ...item, state: { ...state, value: newValues } });
    }, [selectedOptionsVm, selectedValuesVm, onFieldChanged, item, state]);

    const listOptions = useMemo<IListItemProps[]>(() => {
        const list = options.map(option => {
            const isSelected = selectedOptionsVm.some(selectedOption => selectedOption.value === option.value);

            return {
                ...option,
                selected: isSelected
            };
        });

        if (list.length > 0) return list;
        if ((input?.length ?? 0) <= 1) return [{ label: t('CUSTOM_FORM.COMPONENTS.START_THE_SEARCH'), value: 'start-typing', itemType: 'text' }];
        if ((input?.length ?? 0) > 1 && isLoadingOptions) return [{ label: t('CUSTOM_FORM.COMPONENTS.SEARCHING'), value: 'start-typing', itemType: 'text' }];
        return [{ label: t('CUSTOM_FORM.COMPONENTS.NO_RESULTS'), value: 'no-results', itemType: 'text' }];
    }, [input?.length, isLoadingOptions, options, selectedOptionsVm, t]);

    return (
        <FormStyled.TopItem widthInfo={state.widthInfo} isInner={isInner}>
            {!skipTitle && <FieldLabelComponent item={item} />}
            <Input
                data-aid='dropdown-button'
                color='normal'
                placeholder={selectedValuesVm.length > 0 ? '' : getItemSelectPlaceholder(item)}
                inputWrapperRef={inputAnchor}
                onClick={() => setIsOpen(true)}
                isActive={isOpen}
                fullWidth
                value={input}
                onChange={e => setInput(e.target.value)}
                disabled={state.disabled || state.readonly}
                startAdornment={(
                    <Stack alignItems='center' direction='row' spacing={2} overflow='hidden'>
                        {!!selectedValuesVm?.length && <ComponentOverflow
                            components={selectedValuesVm.map(optionValue => (
                                <Chip
                                    key={`option-${optionValue}`}
                                    size='sm'
                                    label={optionValue}
                                    disableInteraction
                                    closeButton={{ onClick: () => handleRemoveOptionValueClick(optionValue) }}
                                />
                            ))}
                        />}
                    </Stack>
                )}
                endAdornment={(
                    <Stack alignItems='center' direction='row' spacing={2}>
                        {(selectedValuesVm.length > 0) && <IconButton onClick={() => {
                            setSelectedOptionsVm([]);
                            setSelectedValuesVm([]);
                            onFieldChanged({ ...item, state: { ...state, value: [] } });
                        }} iconProps={{ name: 'remove' }} size={'small'} />}
                        <IconButton iconProps={{ name: isOpen ? 'chevronUp' : 'chevronDown', size: 10 }} size='small' onClick={() => setIsOpen(!isOpen)} />
                    </Stack>
                )}
            />
            <Popover open={isOpen} fitToAnchorWidth anchorEl={inputAnchor.current} onClose={() => setIsOpen(!isOpen)} placement={'bottom-start'}>
                <List
                    withCheckbox={true}
                    options={listOptions}
                    onOptionClick={handleOptionClick}
                />
            </Popover>
        </ FormStyled.TopItem>
    );
};