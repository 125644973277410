import React from 'react';
import { ICellRendererParams } from 'ag-grid-enterprise';
import IconLinkCellRenderer, { IIconLinkCellProps } from 'common/components/ag-grid/Renderers/IconLinkCellRenderer';
import { getEntityTypeValue } from 'common/components/ProtectedAssets/Renderers/ColumnDisplayValueGetters';
import { CellToClipboardHandler } from 'common/components/ProtectedAssets/ProtectedAssetsTable';
import { getProtectedAssetsService } from '../../../module_interface/assets/ProtectedAssets';
import { IAsset } from '../../../assets/common.assets';

const EntityTypeCellRenderer: React.FC<ICellRendererParams> = (params) => {
    const { entityType, cloudAccountType } = params.data;
    if (!entityType || !cloudAccountType) {
        return null;
    }
    const typeByPlatform = cloudAccountType + '|' + entityType;
    const asset = getProtectedAssetsService().getAssetByType(typeByPlatform) as IAsset;
    const options: IIconLinkCellProps = {
        iconProps: asset?.icon ? { vendorNameOrPath: asset.icon } : { name: 'cloud' },
        isLink: false,
        value: getEntityTypeValue(params.data?.typeByPlatform) ?? params.value,
    };

    CellToClipboardHandler(params, String(options.value));
    return <IconLinkCellRenderer {...options} />;
};

export default EntityTypeCellRenderer;
