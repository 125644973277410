import * as React from 'react';
import { SVGProps, Ref, forwardRef } from 'react';
const SvgShieldWithCircle = (
  props: SVGProps<SVGSVGElement>,
  ref: Ref<SVGSVGElement>
) => (
  <svg
    viewBox="0 0 13 14"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    ref={ref}
    {...props}
  >
    <path
      d="M10.125 6.25v1.5c0 3-4.125 4.875-4.125 4.875S1.875 10.75 1.875 7.75V2.875c1.5 0 3-.75 4.125-1.5.344.23.722.458 1.125.666m4.5.834a1.5 1.5 0 1 1-3 0 1.5 1.5 0 0 1 3 0Z"
      stroke="currentColor"
      strokeOpacity={0.9}
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </svg>
);
const ForwardRef = forwardRef(SvgShieldWithCircle);
export default ForwardRef;
