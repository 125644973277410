import * as React from 'react';
import { SVGProps, Ref, forwardRef } from 'react';
const SvgCompute = (
  props: SVGProps<SVGSVGElement>,
  ref: Ref<SVGSVGElement>
) => (
  <svg
    viewBox="0 0 16 16"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    ref={ref}
    {...props}
  >
    <path
      d="M11.5 2.5h-8a1 1 0 0 0-1 1v8a1 1 0 0 0 1 1h8a1 1 0 0 0 1-1v-8a1 1 0 0 0-1-1ZM14.5 4.5h-2m2 6h-2m2-3h-2m-2-7v2m-6-2v2m3-2v2m3 12v-2m-6 2v-2m3 2v-2m-7-8h2m-2 3h2m-2 3h2"
      stroke="currentColor"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </svg>
);
const ForwardRef = forwardRef(SvgCompute);
export default ForwardRef;
