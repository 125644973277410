import { IInputLabelProps } from '../InputLabel/InputLabel.types';
import { RadioButtonLabelProps } from '../RadioButton/RadioButton.types';

export enum DirectionEnum {
    row = 'row',
    column = 'column',
}
export interface InputTypeConfig {
    label: string;
    value: string;
    name?: string;
    dataAid?: string;
    isHidden?: boolean;
    disabled?: boolean;
    className?: string;
    labelProps?: RadioButtonLabelProps;
}
export interface ISelectionSectionRadioProps {
    onChange?: (value: string) => void;
    value?: string;
    defaultValue?: string;

    label?: string;
    labelProps?: Omit<IInputLabelProps, 'text'>;
    helperText?: string | number;
    isError?: boolean;
    required?: boolean;
    options: InputTypeConfig[];
    direction?: 'row' | 'column';
}
