import * as React from 'react';
import { SVGProps, Ref, forwardRef } from 'react';
const SvgCveText = (
  props: SVGProps<SVGSVGElement>,
  ref: Ref<SVGSVGElement>
) => (
  <svg
    viewBox="0 0 16 16"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    ref={ref}
    {...props}
  >
    <path
      d="M4.5 11.5h-2a2 2 0 0 1-2-2v-4a2 2 0 0 1 2-2h3l2.5 8 2.5-8h1m0 4v4h4m-4-4h4m-4 0v-4m4 0h-4"
      stroke="currentColor"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </svg>
);
const ForwardRef = forwardRef(SvgCveText);
export default ForwardRef;
