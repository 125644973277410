import React, { useCallback } from 'react';
import { IFiltersUtilsList, IFiltersValues, IGeneralHandlerProps } from '../../FilterPanel.interface';
import Accordion from '../../GeneralComponents/Accordion/Accordion';
import { FILTER_EVENTS, FILTERS_KEYS } from '../../FilterPanel.consts';
import { Icon } from '@dome9/berries/react-components';
import { isIncludeSearchTerm } from '../../filterUtils';
export interface IRecentlyUsedFilters extends IGeneralHandlerProps {
    recentlyUsedList: any;
    maxLength: number;
    filtersUtils: IFiltersUtilsList;
    componentName: string;
}

export const RecentlyUsedFilters: React.FC<{ filterProps: IRecentlyUsedFilters }> = ({ filterProps }) => {
    const {
        title,
        currFilterData: currentRecentlyUsedList,
        filtersUtils,
        onEvent,
        key: filterKey,
        searchTerm,
    } = filterProps;

    const renderRecentlyUsedFiltersMemo = useCallback(renderRecentlyUsedFilters,[ currentRecentlyUsedList, filterKey, filtersUtils,onEvent]);
    const renderBoxViewRecentlyUsed = useCallback(() => {
        return (
            isIncludeSearchTerm(searchTerm!, title) && <Accordion title={title} content={renderRecentlyUsedFiltersMemo()} />
        );
    }, [searchTerm,renderRecentlyUsedFiltersMemo,title]);

    function renderRecentlyUsedFilters(){
        return (
            <div className='space-y-2'>
                {currentRecentlyUsedList.map((recentlyUsedFilter: IFiltersValues, index: number) => {
                    const listContent = [] as string[];
                    let key = '';
                    Object.entries(recentlyUsedFilter)?.forEach(([tagName, categoryContent]) => {
                        if (categoryContent && filtersUtils[tagName] && filtersUtils[tagName]!.getAsTagView) {
                            const [title, content] = filtersUtils[tagName].getAsTagView!(
                                categoryContent,
                                filterKey
                            );
                            if (title && content.length > 0) {
                                listContent.push(`${title}:${content}`);
                            }
                        }
                        key = categoryContent.toString();
                    });
                    return (
                        listContent.length > 0 && (
                            <div
                                key={key}
                                className='flex flex-0 hover:bg-hover active:bg-active py-5 cursor-default border bg-content px-7'
                                onClick={() =>
                                    onEvent({
                                        action: FILTER_EVENTS.FILTER_CHANGED,
                                        filterKey: FILTERS_KEYS.ALL,
                                        payload: currentRecentlyUsedList[index],
                                    })
                                }>
                                <Icon name='time' className='mr-7 self-center flex-0'></Icon>
                                <div
                                    key={key}
                                    className='truncate'
                                    title={listContent.length ? '' + listContent.map((val) => val) : ''}>
                                    {listContent.length ? '' + listContent.map((val) => val) : ''}
                                </div>
                            </div>
                        )
                    );
                })}
            </div>
        );
    }

    return <>{renderBoxViewRecentlyUsed()}</>;
};

export default RecentlyUsedFilters;
