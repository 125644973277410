import * as React from 'react';
import { SVGProps, Ref, forwardRef } from 'react';
const SvgNoResults = (
  props: SVGProps<SVGSVGElement>,
  ref: Ref<SVGSVGElement>
) => (
  <svg viewBox="0 0 32 32" ref={ref} {...props}>
    <path fill="#2e3f58" d="M19 26a6 6 0 1 0 6-6 6 6 0 0 0-6 6Z" />
    <path
      stroke="#fff"
      strokeMiterlimit={10}
      fill="#2e3f58"
      d="M22 25.54A2.57 2.57 0 1 0 24.57 23 2.57 2.57 0 0 0 22 25.54ZM26.38 27.36 28 29"
    />
    <path
      fill="none"
      strokeLinecap="round"
      stroke="#2e3f58"
      strokeLinejoin="round"
      d="M18.5 30.47H2.81a1.34 1.34 0 0 1-1.31-1.36V1.82A1.32 1.32 0 0 1 2.8.47h15.51a1.31 1.31 0 0 1 .93.39l4.88 5a1.39 1.39 0 0 1 .38.95v10.66"
    />
  </svg>
);
const ForwardRef = forwardRef(SvgNoResults);
export default ForwardRef;
