import { IApplicationReducer } from 'common/interface/redux';
import { APPLICATION_REDUCERS, REACT_PAGE_ROUTE } from 'common/extensibility/WellKnownPaths';

import FilterPanelExamplePageReducer from './FilterPanelExamplePage.reducer';
import FilterPanelExamplePage from './FilterPanelExamplePage';
import { globalAddinContainer } from '../../../extensibility/AddinContainer';

export function initializeFilterPanelPage() {
    const reducers: IApplicationReducer[] = [
        { name: 'FilterPanelExamplePageSlice', reducer: FilterPanelExamplePageReducer, isBlackList: true },
    ];
    globalAddinContainer.addMap(APPLICATION_REDUCERS, reducers, 'name');

    const filterPanelExamplePageRoute = () => {
        return {
            condition: true,
            component: FilterPanelExamplePage,
            path: '/debug/filterpanelexamplepage',
        };
    };
    globalAddinContainer.add(REACT_PAGE_ROUTE, [{ id: 'Filter panel example page', content: filterPanelExamplePageRoute }]);
}
