import React from 'react';
import { RemediationPanelStyled } from './RemediationPanel.styled';
import { Icon, Markdown, Stack } from 'common/design-system/components-v2';
import styled from 'styled-components';

interface RemediationPanelProps {
    remediation: string;
}

const Description = styled(Stack)`
    flex: 1;
`;

const RemediationPanel: React.FC<React.PropsWithChildren<RemediationPanelProps>> = ({ remediation, children }) => {
    return (
        <RemediationPanelStyled.RemediationPanelWrapper direction='row' padding={6} spacing={3} alignItems='flex-start'>
            <Icon name='remedy' size={24} color='brandPrimary' />
            <Description padding={[1, 0, 0, 0]}>
                {remediation.length > 0 && <Markdown markdown={remediation} />}
                {children}
            </Description>
        </RemediationPanelStyled.RemediationPanelWrapper>
    );
};

export default RemediationPanel;