/* eslint-disable react/prop-types */
import MapChart from 'common/components/Highcharts/MapChart';
import { IGenericWidgetDataProps } from 'common/module_interface/overview/Interface';


const WidgetMapChart: React.FC<IGenericWidgetDataProps> = (props) => {
    const data = props.data;
    const className = data?.className;
    return (
        <div className={`@container flex flex-1 ${className}`}>
            <div className={`flex flex-col @[300px]:flex-row gap-5 py-6 px-8 flex-1 ${className}`}>
                <div className={'flex flex-1'}>
                    <MapChart data={data} key={props.widget?.title || ''}/>
                </div>
            </div>
        </div>
    );
};


export default WidgetMapChart;
