import React, { forwardRef } from 'react';
import { ICheckboxProps } from './Checkbox.types';
import CheckboxStyles from './Checkbox.styles';
import Label from '../Label';

const CheckedSvg: React.FC = () => (
    <svg data-aid={'checked-svg'} xmlns="http://www.w3.org/2000/svg" width="16" height="16" viewBox="0 0 16 16" fill="none">
        <rect width="16" height="16" rx="4" fill="white"/>
        <path fillRule="evenodd" clipRule="evenodd" d="M4 0C1.79086 0 0 1.79086 0 4V12C0 14.2091 1.79086 16 4 16H12C14.2091 16 16 14.2091 16 12V4C16 1.79086 14.2091 0 12 0H4ZM12.509 6.65339C12.8392 6.31977 12.8392 5.77887 12.509 5.44525C12.1787 5.11163 11.6432 5.11163 11.313 5.44525L7.54247 9.25404C7.48437 9.31273 7.39023 9.31294 7.33188 9.2545L5.41501 7.33494C5.08331 7.00278 4.54785 7.00513 4.21903 7.3402C3.8902 7.67526 3.89253 8.21616 4.22423 8.54832L6.14109 10.4679C6.86081 11.1886 8.02187 11.1861 8.73847 10.4622L12.509 6.65339Z" fill="#3594FF"/>
    </svg>
);

const IndeterminateCheckedSvg: React.FC = () => (
    <svg data-aid={'indeterminate-svg'} xmlns="http://www.w3.org/2000/svg" width="16" height="16" viewBox="0 0 16 16" fill="none">
        <rect width="16" height="16" rx="4" fill="white"/>
        <path d="M0 4C0 1.79086 1.79086 0 4 0H12C14.2091 0 16 1.79086 16 4V12C16 14.2091 14.2091 16 12 16H4C1.79086 16 0 14.2091 0 12V4Z" fill="#3594FF"/>
        <path d="M4 8.5C4 7.94772 4.44772 7.5 5 7.5H11C11.5523 7.5 12 7.94772 12 8.5C12 9.05228 11.5523 9.5 11 9.5H5C4.44772 9.5 4 9.05228 4 8.5Z" fill="white"/>
    </svg>
);

const Checkbox = forwardRef<HTMLDivElement, ICheckboxProps>((props, ref) => {
    const { children, label, className, name, onChange, checked, indeterminate, readOnly, id, disabled, labelProps, required, tabIndex, ...rest } = props;
    const inputRef = React.useRef<HTMLInputElement>(null);
    const [checkedState, setCheckedState] = React.useState<boolean>(checked || false);

    const handleOnChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        if (checked === undefined) {
            setCheckedState(event.target.checked);
        }
        onChange && onChange(event);
    };

    const handleOnLabelClick = () => {
        inputRef?.current?.click();
    };

    React.useEffect(() => {
        setCheckedState(checked || false);
    }, [checked]);

    return (
        <CheckboxStyles.Wrapper
            {...rest}
            className={className}
            ref={ref}
            disabled={disabled || readOnly}
        >
            <CheckboxStyles.Input
                ref={inputRef}
                className='checkbox__input'
                checked={checkedState}
                disabled={disabled || readOnly}
                type='checkbox'
                name={name || `checkbox-${id}`}
                onChange={handleOnChange}
                id={id}
                tabIndex={tabIndex}
            />
            <CheckboxStyles.CheckboxLabelWrapper
                direction='row'
                alignItems={label ? 'flex-start' : 'center'}
            >
                <CheckboxStyles.CheckboxWrapper disabled={disabled || readOnly} checked={checkedState}>
                    {(checkedState && !indeterminate) && <CheckedSvg />}
                    {(indeterminate) && <IndeterminateCheckedSvg />}
                </CheckboxStyles.CheckboxWrapper>
                <CheckboxStyles.LabelWrapper disabled={!indeterminate && (disabled || readOnly)} size={labelProps?.size || 'md'} hasChildren={!!children} onClick={handleOnLabelClick}>
                    {label && <Label {...labelProps} size={labelProps?.size || 'md'} text={label} required={required} color={labelProps?.color || 'inherit'} whiteSpace='normal' />}
                    {children}
                </CheckboxStyles.LabelWrapper>
            </CheckboxStyles.CheckboxLabelWrapper>
        </CheckboxStyles.Wrapper>
    );
});
Checkbox.displayName = 'Checkbox';

export default Checkbox;
