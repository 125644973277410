import React from 'react';
import { LoadingState } from '../../interface/general';
import { useTranslation } from 'react-i18next';
import { WidgetCardStyled } from '../Dashboard/WidgetCard/WidgetCard.styled';
import { AssetWidgetStyled } from './AssetWidget.styled';
import AssetTooltipContent from './AssetTooltipContent';
import { ILevelsWidgetData, IWidgetLevelInfo } from './AssetWidget.interface';
import { LevelIcon, Spinner, Tooltip ,Typography } from 'common/design-system/components-v2';
import { RiskLevelIconPropsLevel } from '../../design-system/components-v2/LevelIcon/LevelIcon.types';

const AssetContent: React.FC<{ descendingLevelInfos: IWidgetLevelInfo[], widget: ILevelsWidgetData, subtitle?: string, loadingState?: LoadingState, onClick?: Function | null, isTwin?: boolean }> =
    ({ descendingLevelInfos, widget, subtitle = null, loadingState, onClick, isTwin = false }) => {
        const tooltip = widget.isTooltip ? <AssetTooltipContent descendingLevelInfos={descendingLevelInfos}/> : undefined;
        const { t } = useTranslation();
        if (loadingState !== LoadingState.LOADING_SUCCEEDED) {
            return (
                <AssetWidgetStyled.ContentDiv isTwin={isTwin}>
                    {(loadingState === LoadingState.IS_LOADING) && <AssetWidgetStyled.SpinnerDiv><Spinner /></AssetWidgetStyled.SpinnerDiv>}
                    {(loadingState === LoadingState.LOADING_FAILED) &&
                        <WidgetCardStyled.FailureDiv>{t('COMMON.ERROR_OCCURRED')}</WidgetCardStyled.FailureDiv>}
                </AssetWidgetStyled.ContentDiv>
            );
        }

        const highestLevelInfo = descendingLevelInfos.find((levelInfo: IWidgetLevelInfo) => levelInfo.count > 0) || { text: 'low' };

        const visualBox =
            <LevelIcon
                category='riskLevel'
                size='lg'
                iconProps={{
                    name: widget.icon
                }}
                level={highestLevelInfo?.text.toLowerCase() as RiskLevelIconPropsLevel}
            />;

        return (
            <AssetWidgetStyled.ContentDiv isTwin={isTwin}>
                <AssetWidgetStyled.BodyDiv>
                    {visualBox}
                    <AssetWidgetStyled.TextDiv>
                        {subtitle && <Typography variant='bodyXs'>{subtitle}</Typography>}
                        <Tooltip placement='bottom' content={tooltip}>
                            <AssetWidgetStyled.CounterDiv
                                isClickable={!!onClick}
                                onClick={() => onClick && onClick()} isTwin={isTwin}>{widget.highCriticalCountStr}
                            </AssetWidgetStyled.CounterDiv>
                        </Tooltip>
                    </AssetWidgetStyled.TextDiv>
                </AssetWidgetStyled.BodyDiv>
            </AssetWidgetStyled.ContentDiv>
        );
    };

export default AssetContent;
