import styled from 'styled-components';

const TopDiv = styled.div<{ bg: string }>`
  display: flex;
  background-color: ${props => props.bg};
  border-radius: 10px;
  gap: 5px;
  padding: 5px 8px;
`;

const TextDiv = styled.div`
  color: white;
  font-size: 12px;
  font-style: normal;
  font-weight: 400;
  line-height: 10px;
`;

export const RoundedStyle = {
    TopDiv,
    TextDiv,
};
