import * as React from 'react';
import { SVGProps, Ref, forwardRef } from 'react';
const SvgIdentityCard = (
  props: SVGProps<SVGSVGElement>,
  ref: Ref<SVGSVGElement>
) => (
  <svg
    viewBox="0 0 16 16"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    ref={ref}
    {...props}
  >
    <circle cx={5.5} cy={6.5} r={2} stroke="currentColor" />
    <path
      d="M2.211 10.803a3.5 3.5 0 0 1 6.586.023"
      stroke="currentColor"
      strokeLinecap="round"
    />
    <path
      d="M10.5 6.5H13m0 2h-2.5"
      stroke="currentColor"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <rect x={0.5} y={2.5} width={15} height={11} rx={1} stroke="currentColor" />
  </svg>
);
const ForwardRef = forwardRef(SvgIdentityCard);
export default ForwardRef;
