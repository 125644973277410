import * as React from 'react';
import { SVGProps, Ref, forwardRef } from 'react';
const SvgNotebookWithMagnify = (
  props: SVGProps<SVGSVGElement>,
  ref: Ref<SVGSVGElement>
) => (
  <svg
    viewBox="0 0 16 16"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    ref={ref}
    {...props}
  >
    <path
      d="M13.5 7.5v-6a1 1 0 0 0-1-1h-11a1 1 0 0 0-1 1v13a1 1 0 0 0 1 1h7M3.5 3.5h1M6.5 3.5h4M3.5 9.5h4M3.5 5.5h1M6.5 5.5h1M9.5 5.5h1M3.5 11.5h1M6.5 11.5h1M3.5 7.5h7"
      stroke="currentColor"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="m13.77 13.77 1.73 1.73m-1-3.5a2.5 2.5 0 1 1-5 0 2.5 2.5 0 0 1 5 0Z"
      stroke="currentColor"
      strokeLinecap="round"
    />
  </svg>
);
const ForwardRef = forwardRef(SvgNotebookWithMagnify);
export default ForwardRef;
