import { lightPallete } from 'common/design-system/theme/colors';

export enum ImportanceLevelEnum {
    critical = 'critical',
    high = 'high',
    medium = 'medium',
    low = 'low',
    unknown = 'unknown',
}

export interface IImportanceLevelInfo {
    key: ImportanceLevelEnum;
    bg: string;
    fg: string;
    level: number;
}

export const ImportanceLevelMap: { [key in ImportanceLevelEnum]: IImportanceLevelInfo } = {
    unknown: {
        key: ImportanceLevelEnum.unknown,
        fg: lightPallete.importance.unknownFg,
        bg: lightPallete.importance.unknownBg,
        level: -1,
    },
    low: {
        key: ImportanceLevelEnum.low,
        fg: lightPallete.importance.lowFg,
        bg: lightPallete.importance.lowBg,
        level: 0,
    },
    medium: {
        key: ImportanceLevelEnum.medium,
        fg: lightPallete.importance.mediumFg,
        bg: lightPallete.importance.mediumBg,
        level: 1,
    },
    high: {
        key: ImportanceLevelEnum.high,
        fg: lightPallete.importance.highFg,
        bg: lightPallete.importance.highBg,
        level: 2,
    },
    critical: {
        key: ImportanceLevelEnum.critical,
        fg: lightPallete.importance.criticalFg,
        bg: lightPallete.importance.criticalBg,
        level: 3,
    },
};
