import { AllIconsName } from 'common/design-system/components-v2/Icon/Icon.types';
import { buildPath } from 'common/extensibility/AddinContainer';

export const PATH_ENVIRONMENT_PAGE = 'environment';
export const PATH_OCI_ENVIRONMENT = buildPath(PATH_ENVIRONMENT_PAGE, 'ociEnvironment');
export const PATH_ENVIRONMENT_PAGE_OCI_DETAILS_PANEL = buildPath(PATH_OCI_ENVIRONMENT, 'detailsPanel');
export const PATH_ENVIRONMENT_PAGE_OCI_TAB = buildPath(PATH_OCI_ENVIRONMENT, 'tab');
export const PATH_ENVIRONMENT_PAGE_OCI_CHIP = buildPath(PATH_OCI_ENVIRONMENT, 'chip');
export const PATH_ENVIRONMENT_PAGE_ACTION = buildPath(PATH_OCI_ENVIRONMENT, 'action');


export interface OciEnvironmentData {
    account: OciCloudAccount;
}

export interface OciCloudAccount {
    id: string;
    vendor: string;
    name: string;
    ociAccountId: string;
    projectNumber: string;
    externalAccountNumber: string;
    error: string;
    magellan: boolean;
    isFetchingSuspended: boolean;
    creationDate: string;
    credentials: CloudAccountCredentials;
    organizationalUnitId?: any;
    organizationalUnitPath: string;
    organizationalUnitName: string;
    totalAssets: number;
    isServerlessActive: boolean;
    isCloudbotsOnboarded?: boolean;
  }

interface CloudAccountCredentials {
    accessKey?: string;
    accessSecret?: string;
}

export interface ServerlessOciAccountStatesGetResponse {
    cloudAccountId: string;
    externalAccountNumber: string;
    cloudVendor: string;
    state: string;
    accountId: number;
    reason: string;
    shouldUpdate: boolean;
    isOrgOnboarding: boolean;
    hasMissingPermissions: boolean;
    validatePermissionsStatus: string;
    lastValidatePermissionsTimestamp: number;
}

export interface OciEnvironmentTab {
    name: string;
    label: string;
    isReactTab: boolean;
    content?: React.ReactElement;
}

export interface OciEnvironmentAction {
    label: string;
    icon?: AllIconsName;
    callback: (ociAccount: OciEnvironmentData) => void,
    disabled?: (ociAccount: OciEnvironmentData) => boolean;
}