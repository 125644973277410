import * as React from 'react';
import { SVGProps, Ref, forwardRef } from 'react';
const SvgMore = (props: SVGProps<SVGSVGElement>, ref: Ref<SVGSVGElement>) => (
  <svg
    viewBox="0 0 16 16"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    ref={ref}
    {...props}
  >
    <circle cx={8} cy={3} r={0.5} stroke="currentColor" />
    <circle cx={8} cy={8} r={0.5} stroke="currentColor" />
    <circle cx={8} cy={13} r={0.5} stroke="currentColor" />
  </svg>
);
const ForwardRef = forwardRef(SvgMore);
export default ForwardRef;
