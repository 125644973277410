import * as React from 'react';
import { SVGProps, Ref, forwardRef } from 'react';
const SvgMoreHorizontal = (
  props: SVGProps<SVGSVGElement>,
  ref: Ref<SVGSVGElement>
) => (
  <svg
    viewBox="0 0 24 24"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    ref={ref}
    {...props}
  >
    <circle
      cx={21}
      cy={12}
      r={0.75}
      transform="rotate(90 21 12)"
      stroke="currentColor"
      strokeWidth={1.4}
    />
    <circle
      cx={12}
      cy={12}
      r={0.75}
      transform="rotate(90 12 12)"
      stroke="currentColor"
      strokeWidth={1.4}
    />
    <circle
      cx={3}
      cy={12}
      r={0.75}
      transform="rotate(90 3 12)"
      stroke="currentColor"
      strokeWidth={1.4}
    />
  </svg>
);
const ForwardRef = forwardRef(SvgMoreHorizontal);
export default ForwardRef;
