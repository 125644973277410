import styled, { css } from 'styled-components';
import { getTextContrastColor, ColorsWithState } from '../../theme/theme';
import Label from '../Label/Label';
import Stack from '../Stack/Stack';
import { Context } from 'common/design-system/theme/colors/colors.types';

interface IWrapperProps {
  disabled?: boolean;
  color: ColorsWithState;
  context?: Context;
  customColor?: string;
  customTextColor?: string;
  disableInteraction?: boolean;
  minWidth?: string;
  isIconChip: boolean;
  withBorder?: boolean;
  pointerCursor?: boolean;
}
const Wrapper = styled(Stack)<IWrapperProps>`
  max-width: fit-content;
  border-radius: ${({ theme, isIconChip }) => isIconChip ? '50%' : `${theme.border.radius(4)}${theme.units}`};
  display: flex;
  justify-content: center;
  align-items: center;

  ${({ minWidth }) => minWidth && css`
    min-width: ${minWidth};
  `}

  background: ${({ color, theme }) => theme.palette.surface[color]};
  color: ${({ color, customTextColor, theme }) => customTextColor ?? getTextContrastColor(theme.palette.surface[color], theme)};
  box-shadow: ${({ theme }) => theme.shadows[0]};
  outline: none;

  ${({ customColor, customTextColor, theme }) => customColor && css`
    background: ${customColor};
    color: ${customTextColor ?? getTextContrastColor(customColor, theme)};
  `};

  ${({ customColor, disableInteraction, color, theme }) => (!disableInteraction && !customColor) && css`
    &:hover {
      background: ${theme.palette.surfaceStates[`${color}Hover`]};
      box-shadow: ${theme.shadows[0]};
    }
    &:focus {
      background: ${theme.palette.surfaceStates[`${color}Focus`]};
      box-shadow: ${theme.shadows.focus};
    }
    &:active {
      background: ${theme.palette.surfaceStates[`${color}Active`]};
      box-shadow: ${theme.shadows[0]};
    }
  `};

  ${({ pointerCursor }) => pointerCursor && css`
    cursor: pointer;
  `}

  ${({ disabled, theme, color }) => disabled && css`
    box-shadow: ${theme.shadows[0]};
    opacity: ${theme.disabledOpacity};
    cursor: not-allowed;
    pointer-events: none;
    &:hover, &:focus, &:active {
      background: ${theme.palette.onSurface[color]};
    }
  `}

  ${({ withBorder, theme }) => withBorder && css`
    border: ${theme.border.width.standard}${theme.units} solid ${theme.palette.onSurface.reverse};
  `}

  ${({ context, theme, customColor, disableInteraction }) => context && css`
    background: ${theme.palette[context].backgrounds.strength.weaker};
    color: ${theme.palette[context].foregrounds.regular.default};
    outline: ${({ theme }) => `${theme.border.width.standard}${theme.units} solid ${theme.palette.common.white}` };

    ${ !disableInteraction && !customColor && css`
      &:hover {
        background: ${theme.palette[context].backgrounds.hover};
        box-shadow: ${theme.shadows[0]};
      }
      &:focus {
        background: ${theme.palette[context].backgrounds.selected};
        box-shadow: ${theme.shadows[0]};
      }
      &:active {
        background: ${theme.palette[context].backgrounds.hoverTransparent};
        box-shadow: ${theme.shadows[0]};
      }
    `};
  `}
`;

const StyledLabel = styled(Label)`
  p {
    text-overflow: ellipsis;
    overflow: hidden;
    white-space: nowrap;
  }
`;

const TypographyStyles = {
    Wrapper,
    Label: StyledLabel
};

export default TypographyStyles;
