import { FC } from 'react';
import './Error.scss';
import { CpIcon } from '@dome9/components/react/components';

interface ErrorInterface {
    title: string;
    text: string;
}

const Error: FC<ErrorInterface> = ({ title, text }) => {
    return (
        <div className='error-page-container flex'>
            <div className='error-page flex-column-center'>
                <div className='error-page-icon error-item flex'>
                    <CpIcon icon={'error'}></CpIcon>
                </div>
                <div className='error-page-title error-item'>{title}</div>
                <div className='title-page error-item'>{text}</div>
            </div>
        </div>
    );
};

export default Error;
