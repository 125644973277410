import { isNil, isString } from '../utils/helpFunctions';
import { SortOrder } from '../interface/general';
import { IconProps } from '../design-system/components-v2/Icon/Icon.types';
import { Context } from 'common/design-system/theme/colors/colors.types';

export interface IGeneralLevelsCounters {
    high?: number;
    critical?: number;
    medium?: number;
    low?: number;
}

export interface IBaseLevelInfo<T=string> {
    key: T;
    level: number;
    displayText: string;
    color: string;
    bg: string;
    iconProps: IconProps;
    context: Context;
}

export const getAscendingLevels = <T>(allLevels: IBaseLevelInfo<T>[]): IBaseLevelInfo<T>[] => {
    return allLevels.sort((s1: IBaseLevelInfo<T>, s2: IBaseLevelInfo<T>) => s1.level - s2.level);
};

export const getDescendingLevels = <T>(allLevels: IBaseLevelInfo<T>[]): IBaseLevelInfo<T>[] => {
    return allLevels.sort((s1: IBaseLevelInfo<T>, s2: IBaseLevelInfo<T>) => s2.level - s1.level);
};

export const getLevelInfo = <T>(allLevels: IBaseLevelInfo<T>[], key: T | string | number): IBaseLevelInfo<T> | undefined => {
    if (isNil(key)) {
        return undefined;
    }
    if (isString(key)) {
        const lowerKey = String(key).toLowerCase();
        return allLevels.find((severityInfo: IBaseLevelInfo<T>) => severityInfo.key === lowerKey);
    } else {
        try {
            const level = Number(key);
            return allLevels.find((severityInfo: IBaseLevelInfo<T>) => severityInfo.level === level);
        } catch (error) {
            return undefined;
        }
    }
};

export const getLevels = <T>(allLevels: IBaseLevelInfo<T>[], order?: SortOrder, lowestKey?: T): IBaseLevelInfo<T>[] => {
    let levels : IBaseLevelInfo<T>[];
    if (order === SortOrder.ascending) {
        levels = getAscendingLevels(allLevels);
    } else if (order === SortOrder.descending) {
        levels = getDescendingLevels(allLevels);
    } else {
        levels = allLevels;
    }

    if (lowestKey) {
        const lowestLevel: number | undefined = getLevelInfo(allLevels, lowestKey)?.level;
        if (lowestLevel !== undefined) {
            return levels.filter(info => info.level >= lowestLevel);
        } else {
            return levels;
        }
    } else {
        return levels;
    }
};
