import * as React from 'react';
import { SVGProps, Ref, forwardRef } from 'react';
const SvgStatusInfo = (
  props: SVGProps<SVGSVGElement>,
  ref: Ref<SVGSVGElement>
) => (
  <svg
    viewBox="0 0 16 16"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    ref={ref}
    {...props}
  >
    <path
      d="M8 0a8 8 0 1 0 0 16A8 8 0 0 0 8 0Zm.8 11.2a.8.8 0 1 1-1.6 0v-4a.8.8 0 0 1 1.6 0v4ZM8 5.6A.8.8 0 1 1 8 4a.8.8 0 0 1 0 1.6Z"
      fill="#3594FF"
    />
  </svg>
);
const ForwardRef = forwardRef(SvgStatusInfo);
export default ForwardRef;
