import React, { forwardRef } from 'react';
import ChipStyles from './Chip.styles';
import { IChipProps } from './Chip.types';
import IconButton from '../IconButton/IconButton';
import { IStackProps } from '../Stack/Stack.types';
import Stack from '../Stack/Stack';
import Spinner from '../Spinner/Spinner';
import Icon from '../Icon/Icon';
import Tooltip from '../Tooltip/Tooltip';
import { IconProps } from '../Icon/Icon.types';
import { ILabelProps } from '../Label/Label.types';

const Chip = forwardRef<HTMLDivElement, IChipProps>((props, ref) => {
    const { disabled, size = 'md', color = 'normal', closeButton, loading, onClick, withBorder, ...rest } = props;

    const isIconChip = props.label === undefined;

    const handleClick = React.useCallback((e: React.MouseEvent<HTMLElement, MouseEvent>) => {
        if (!onClick || disabled) return;
        let isNestedIconClicked = false;
        try {
            let target = e.target as HTMLElement;
            while (target.parentElement) {
                if (target.parentElement.id === 'close-button') {
                    isNestedIconClicked = true;
                    break;
                }
                target = target.parentElement;
            }
        } catch (error) { /* empty */ }
        if (isNestedIconClicked) return;
        onClick(e);
    }, [onClick, disabled]);

    const propsBySize = React.useMemo<{ stackProps: Partial<IStackProps>; size: IconProps['size']; labelSize: ILabelProps['size']; }>(() => {
        switch (size) {
            case 'xs':
                return {
                    labelSize: 'sm',
                    size: isIconChip ? 8 : 10,
                    stackProps: {
                        padding: isIconChip ? 1 : [0, 1],
                        spacing: 0.5,
                    }
                };
            case 'sm':
                return {
                    labelSize: 'md',
                    size: 12,
                    stackProps: {
                        padding: isIconChip ? 1 : [0.5, 1],
                        spacing: 1,
                    }
                };
            case 'md':
                return {
                    labelSize: 'md',
                    size: isIconChip ? 16 : 12,
                    stackProps: {
                        padding: isIconChip ? 1 : [1, 2],
                        spacing: 1,
                    }
                };
            case 'lg':
                return {
                    labelSize: 'md',
                    size: isIconChip ? 16 : 12,
                    stackProps: {
                        padding: isIconChip ? 2 : [2, 3],
                        spacing: 1,
                    }
                };
        }
    }, [isIconChip, size]);


    const chipContent = React.useMemo(() => {
        if (isIconChip) {
            if (loading) {
                return <Spinner size={propsBySize.size} />;
            }
            return <Icon {...props.iconProps} size={propsBySize.size} />;
        }
        return (
            <>
                {loading && <Spinner size={propsBySize.size} />}
                <ChipStyles.Label
                    text={props.label}
                    leadingIconProps={props.leadingIconProps && { size: propsBySize.size, ...props.leadingIconProps }}
                    trailIconProps={props.trailIconProps && { size: propsBySize.size, ...props.trailIconProps }}
                    size={propsBySize.labelSize}
                    color='inherit'
                    showDefaultTooltip={!props.tooltip}
                />
                {closeButton && (
                    <Stack id='close-button'>
                        <IconButton
                            iconProps={{ name: 'remove', size: propsBySize.size }}
                            size='small'
                            circleShape
                            disabled={disabled}
                            variant='text'
                            {...closeButton}
                        />
                    </Stack>
                )}
            </>
        );
    }, [props, isIconChip, propsBySize, loading, closeButton, disabled]);

    return (
        <Tooltip content={props.tooltip} interactive={props.interactiveTooltip}>
            <ChipStyles.Wrapper
                ref={ref}
                {...propsBySize.stackProps}
                disabled={disabled}
                color={color}
                tabIndex={0}
                onClick={handleClick}
                alignItems='center'
                direction='row'
                justifyContent='center'
                spacing={1}
                isIconChip={isIconChip}
                withBorder={withBorder}
                fullWidth
                {...rest}
            >
                {chipContent}
            </ChipStyles.Wrapper>
        </Tooltip>
    );
});
Chip.displayName = 'Chip';

export default Chip;
