import * as React from 'react';
import { SVGProps, Ref, forwardRef } from 'react';
const SvgListCheck = (
  props: SVGProps<SVGSVGElement>,
  ref: Ref<SVGSVGElement>
) => (
  <svg
    viewBox="0 0 16 16"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    ref={ref}
    {...props}
  >
    <path
      d="m10 13 1.5 1.5 3-3M10 3l1.5 1.5 3-3M1.5 3.5h6M10 8l1.5 1.5 3-3M1.5 8.5h6M1.5 13.5h6"
      stroke="currentColor"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </svg>
);
const ForwardRef = forwardRef(SvgListCheck);
export default ForwardRef;
