import React from 'react';
import { IAssetTwinWidgetsProps, IAssetWidgetContentInfo } from './AssetWidget.interface';
import AssetContent from './AssetContent';
import { AssetWidgetStyled } from './AssetWidget.styled';
import { Card, Icon, Tooltip } from '../../design-system/components-v2';
import { logAndRunOnComponentClick } from '../../utils/logUtils';

const createMultiContent = (leftInfo: IAssetWidgetContentInfo, rightInfo: IAssetWidgetContentInfo) => {
    return (
        <AssetWidgetStyled.TwinWidgetDiv>
            <AssetContent
                key={leftInfo.widget.title}
                subtitle={leftInfo.widget.title}
                widget={leftInfo.widget}
                loadingState={leftInfo.loadingState}
                descendingLevelInfos={leftInfo.descendingLevelInfos}
                onClick={leftInfo.widget?.onClick}
                isTwin={true} />
            <AssetContent
                key={rightInfo.widget.title}
                subtitle={rightInfo.widget.title}
                widget={rightInfo.widget}
                loadingState={rightInfo.loadingState}
                descendingLevelInfos={rightInfo.descendingLevelInfos}
                onClick={rightInfo.widget?.onClick}
                isTwin={true} />
        </AssetWidgetStyled.TwinWidgetDiv >
    );
};

const AssetTwinWidgets: React.FC<IAssetTwinWidgetsProps> =
    ({ title, leftContentInfo, rightContentInfo, helpInfoText, tipProps }) => {

        const onClickWithLog = tipProps ? () => logAndRunOnComponentClick(tipProps.onClick, title + '-header') : undefined;

        const rightContent = tipProps &&
            <AssetWidgetStyled.TitleActionDiv onClick={onClickWithLog}>
                <Tooltip placement='bottom' content={tipProps.text}>
                    <Icon name="tips" size={16}/>
                </Tooltip>
            </AssetWidgetStyled.TitleActionDiv>;

        return (
            <Card
                title={title}
                tooltip={helpInfoText}
                smallSize
                rightContent={rightContent}>
                {createMultiContent(leftContentInfo, rightContentInfo)}
            </Card>
        );
    };

export default AssetTwinWidgets;
