
export const baseTooltipStyle = {
    fontSize: '12px',
    fontFamily: 'Verdana, sans-serif'
};

export const getIsLabelsEnabled = (chartRef: React.MutableRefObject<any>) => {
    const chartContainerHeight = chartRef?.current?.chart?.chartHeight;
    if(chartContainerHeight && chartContainerHeight > 200){ // This number was defined by yevgeney belov
        return true;
    } else {
        return false;
    }
};

