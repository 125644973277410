import styled from 'styled-components';
import Stack from '../../Stack';
import { IStackProps } from '../../Stack/Stack.types';
import { StatusColor } from './StatusIcon.types';

const outlineBackground = {
    success: 'successLight',
    warning: 'warningLight',
    error: 'alertLight',
    info: 'brandLight',
} as const;

export const StatusIconContainer = styled(Stack)<IStackProps & { statusColor: StatusColor }>`
    height: 32px;
    width: 32px;
    border-radius: 28px;
    background: ${({ theme, statusColor }) => theme.palette.surface[outlineBackground[statusColor]]};
`;

