import * as React from 'react';
import { SVGProps, Ref, forwardRef } from 'react';
const SvgDuplicate = (
  props: SVGProps<SVGSVGElement>,
  ref: Ref<SVGSVGElement>
) => (
  <svg
    viewBox="0 0 16 16"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    ref={ref}
    {...props}
  >
    <rect x={0.5} y={3.5} width={12} height={12} rx={1} stroke="currentColor" />
    <path
      d="M6.5 6.5v6m-3-3h6M5 .5h8.5a2 2 0 0 1 2 2v11"
      stroke="currentColor"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </svg>
);
const ForwardRef = forwardRef(SvgDuplicate);
export default ForwardRef;
