import { IDashboardWidget, IGenericWidgetDataItem, NavigateOption } from '../../module_interface/overview/Interface';
import { getCloudAccountsService, IAggregationDataItem } from '../../interface/data_services';
import { IVendor, Vendors, VENDORS } from '../../consts/vendors';
import { limitDataItems } from './helpers';
import i18n from 'i18next';
import { changeUrl } from '../../utils/http';
import { getProtectedAssetsService } from '../../module_interface/assets/ProtectedAssets';
import { IAsset } from '../../assets/common.assets';
import { getCloudAccountIdAggregationLink } from 'common/module_interface/overview/Utils';

const defaultIcon = 'cloud';
const PROTECTED_ASSETS_BASE_URL = '/protected-asset/index';
const CLOUD_ACCOUNT_BASE_URL = '/cloud-account';

export const getPlatformDisplayData = (selectedAggregation: IAggregationDataItem[], widget: IDashboardWidget) => {
    const items = selectedAggregation?.map((platform) => {
        const vendorData = VENDORS.find(item => item.assessmentVendorType == platform.value || item.name == platform.value || item.uri === platform.value) as IVendor;
        return {
            key: vendorData.displayName || vendorData.name || i18n.t('EVENTS.NOT_AVAILABLE'),
            value: platform.count,
            icon: vendorData.icon || defaultIcon,
            onclick: () => changeUrl(`/alerts/findings?query=%7B"sorting":%7B"fieldName":"createdTime","direction":-1%7D,"filter":%7B"fields":%5B%7B"name":"organizationalUnitId","value":"00000000-0000-0000-0000-000000000000"%7D,%7B"name":"cloudAccountType","value":"${vendorData.assessmentVendorType}"%7D%5D,"freeTextPhrase":""%7D%7D&time=%7B"time":"All"%7D`)
        };
    });

    return {
        items: widget.limit && limitDataItems(items, widget.limit)
    };
};

export const getRegionDisplayData = (selectedAggregation: IAggregationDataItem[], widget: IDashboardWidget) => {
    const items = selectedAggregation?.map((item) => {
        return {
            key: item.value || i18n.t('EVENTS.GLOBAL'),
            value: item.count,
            icon: defaultIcon,
            onclick: () => changeUrl(`/alerts/findings?query=%7B"sorting":%7B"fieldName":"createdTime","direction":-1%7D,"filter":%7B"fields":%5B%7B"name":"organizationalUnitId","value":"00000000-0000-0000-0000-000000000000"%7D,%7B"name":"region","value":"${item.value}"%7D%5D,"freeTextPhrase":""%7D%7D&time=%7B"time":"All"%7D`)
        };
    });

    return {
        items: widget.limit && limitDataItems(items, widget.limit)
    };
};

export const getAssetTypeDisplayData = (selectedAggregation: IAggregationDataItem[], widget: IDashboardWidget) => {
    const items = selectedAggregation.map(aggregationDataItem => {
        const asset = getProtectedAssetsService().getAssetByType(aggregationDataItem.value) as IAsset;
        return {
            key: asset?.displayName || aggregationDataItem.value || i18n.t('EVENTS.NOT_AVAILABLE'),
            value: aggregationDataItem.count,
            icon: asset?.icon,
            onclick: () => changeUrl(`/protected-asset/index?filterPanel={"type":["${asset.typeByPlatform}"]}&`)
        };
    });
    return {
        items: limitDataItems(items, widget.limit || items.length)
    };
};

export const getCloudAccountIdDisplayData = async (selectedAggregation: IAggregationDataItem[], widget: IDashboardWidget) => {
    const allCloudAccounts = await getCloudAccountsService().getAllCloudAccounts();
    const cloudAccountItems = selectedAggregation.reduce((result: IGenericWidgetDataItem[], aggItem: IAggregationDataItem) => {
        const selectedCloudAccount = allCloudAccounts.find((cloudAccount: any) => aggItem.value === cloudAccount.id);
        if (selectedCloudAccount) {
            const link = widget.options.navigateOption === NavigateOption.ENVIRONMENT ? getCloudAccountIdAggregationLink(widget, selectedCloudAccount.id, CLOUD_ACCOUNT_BASE_URL, selectedCloudAccount.platform) : getCloudAccountIdAggregationLink(widget, selectedCloudAccount.id, PROTECTED_ASSETS_BASE_URL);
            result.push({
                key: selectedCloudAccount?.name || aggItem.value || i18n.t('EVENTS.NOT_AVAILABLE'),
                value: aggItem.count,
                icon: selectedCloudAccount.platform === Vendors.GOOGLE ? Vendors.GCP : selectedCloudAccount.platform || defaultIcon,
                onclick: () => changeUrl(link!)
            });
        }
        return result;
    }, []);
    return {
        items: limitDataItems(cloudAccountItems, widget.limit || cloudAccountItems.length)
    };
};
