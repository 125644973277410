/* eslint-disable react/prop-types */
import Highcharts from 'highcharts';
import HighchartsReact from 'highcharts-react-official';
import { useEffect, useMemo, useRef, useState } from 'react';
import { getColors } from '../Widgets/helpers';
import { useProfiler } from '@sentry/react';
import { Icon } from '../../design-system/components-v2';
import { IconProps } from '../../design-system/components-v2/Icon/Icon.types';
import { IPieChartProps } from './PieChart.interface';
import _ from 'lodash';

const PieChart: React.FC<IPieChartProps> = ({ items, title, subtitle, settings, highchartsOptions, header = '' }) => {
    useProfiler( `PieChart|${header}`);

    const chartRef = useRef<any>();
    const [chartOptions, setChartOptions] = useState<any>();

    useEffect(() => {
        const finalHighchartsOptions = {
            credits: {
                enabled: false,
            },
            colors: items && getColors(items),
            tooltip: {
                enabled: true,
                style: {
                    fontSize: '14px',
                },
            },
            title: '',
            chart: {
                backgroundColor: 'transparent',
                type: 'pie',
                renderTo: 'container',
            },
            plotOptions: {
                pie: {
                    allowPointSelect: true,
                    cursor: 'pointer',
                    dataLabels: {
                        enabled: false,
                    },
                    tooltip: {
                        pointFormat: '<span style="color:{point.color}; font-size:20px;">\u25A0 </span>{point.name}:<br /><div style="display: block; min-width: 40px" /><b>{point.y:,.0f} ({point.percentage:.1f}%)</b>',
                        headerFormat: '',
                    },
                },
                tooltip: {
                    enabled: true,
                },
            },
            series: [
                {
                    innerSize: '80%',
                    data: items && items.map(item => ({ name: item.key, y: item.value, onClick: item.onclick })),
                    point: {
                        events: {
                            click: function () {
                                const self = this as any;
                                self.onClick && self.onClick();
                            },
                        },
                    },
                },
            ],
            legend: {
                itemStyle: {
                    fontSize: '24px'
                },
            },
        };
        _.merge(finalHighchartsOptions, highchartsOptions);
        setChartOptions(finalHighchartsOptions);
    }, [highchartsOptions, items]);


    useEffect(() => {
        const chart = chartRef.current?.chart;
        if (chart) chart.reflow();
    }, [chartRef]);

    const containerProps = useMemo(() => {
        return {
            style: {
                width: '100%',
                display: 'flex',
                position: 'relative',
                height: '100%',
            },
        };
    },[]);

    return (
        <div className={'flex justify-center relative flex-1'}>
            <div className="@container aspect-square flex">
                <div className="flex-1 flex z-10">
                    <HighchartsReact
                        highcharts={Highcharts}
                        containerProps={containerProps}
                        options={chartOptions}
                        ref={chartRef}
                    />
                </div>

                <div className="flex flex-col items-center justify-center inset-0 absolute z-0">
                    <div className="flex flex-col gap-3 @[120px]:gap-5 text-center">
                        {!settings?.suppressTitle && title &&
                            <div className="text-xl text-block @[120px]:text-2xl @[300px]:text-3xl @[400px]:text-4xl  hover:cursor-pointer hover:underline" style={settings?.titleStyle}>
                                {title}
                            </div>
                        }
                        {!settings?.suppressSubtitle && subtitle && (
                            <div className="text-weak text-block text-2xs @[160px]:text-md" style={settings?.subtitleStyle}>{subtitle}</div>
                        )}
                        {settings?.centralIconProps && (
                            <Icon {...(settings.centralIconProps as IconProps)} />
                        )}
                    </div>
                </div>
            </div>
        </div>
    );
};


export default PieChart;
