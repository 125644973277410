/* eslint-disable react/prop-types */
import PieChart from 'common/components/Highcharts/PieChart';
import Legend from '../Legend/Legend';
import { IGenericWidgetDataProps } from 'common/module_interface/overview/Interface';


const WidgetPieChart: React.FC<IGenericWidgetDataProps> = (props) => {
    const data = props.data;
    const items = data?.items;
    const options = data?.options;
    const className = data?.className;
    return (
        <div className={`@container flex flex-1 ${className}`}>
            <div className={`flex flex-col @[300px]:flex-row gap-5 py-6 px-8 flex-1 ${className}`}>
                <div className={'flex flex-1 min-h-[150px]'}>
                    <PieChart items={items} title={data?.title} subtitle={data?.subtitle} header={props.widget?.title || ''} highchartsOptions={options} />
                </div>
                { items && !options?.suppressLegend &&
                    <div className={'items-start flex @[300px]:flex-1'}>
                        <Legend items={items}/>
                    </div>
                }
            </div>
        </div>
    );
};


export default WidgetPieChart;
