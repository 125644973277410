import * as React from 'react';
import { SVGProps, Ref, forwardRef } from 'react';
const SvgAutomatedAction = (
  props: SVGProps<SVGSVGElement>,
  ref: Ref<SVGSVGElement>
) => (
  <svg
    viewBox="0 0 24 24"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    ref={ref}
    {...props}
  >
    <path
      d="M5.912 2.54a11.25 11.25 0 0 1 17.002 12.19m-4.826 6.73A11.25 11.25 0 0 1 1.086 9.27m5.289-5.306a2.41 2.41 0 1 1-4.821 0 2.41 2.41 0 0 1 4.821 0Zm16.071 16.072a2.41 2.41 0 1 1-4.821 0 2.41 2.41 0 0 1 4.821 0Z"
      stroke="currentColor"
      strokeWidth={1.5}
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M15.77 11.136 9.753 7.627a1 1 0 0 0-1.504.864v7.018a1 1 0 0 0 1.504.864l6.015-3.51a1 1 0 0 0 0-1.727Z"
      stroke="currentColor"
      strokeWidth={1.5}
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </svg>
);
const ForwardRef = forwardRef(SvgAutomatedAction);
export default ForwardRef;
