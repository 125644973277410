import * as React from 'react';
import { SVGProps, Ref, forwardRef } from 'react';
const SvgDataSensitivity = (
  props: SVGProps<SVGSVGElement>,
  ref: Ref<SVGSVGElement>
) => (
  <svg
    viewBox="0 0 16 16"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    ref={ref}
    {...props}
  >
    <path
      d="M8.5.5v3a2 2 0 0 0 2 2h3l-5-5Zm0 0h-7a1 1 0 0 0-1 1v13a1 1 0 0 0 1 1h5"
      stroke="currentColor"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M10.5 9a1 1 0 0 1 1-1h1a1 1 0 0 1 1 1v1.5h-3V9Z"
      stroke="currentColor"
    />
    <path
      d="M8.5 11.5a1 1 0 0 1 1-1h5a1 1 0 0 1 1 1v3a1 1 0 0 1-1 1h-5a1 1 0 0 1-1-1v-3Z"
      stroke="currentColor"
      strokeLinejoin="round"
    />
  </svg>
);
const ForwardRef = forwardRef(SvgDataSensitivity);
export default ForwardRef;
