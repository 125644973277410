import { IFilterConditionsContainer } from 'common/erm-components/custom/FilterTree/FilterCondition';
import {
    createFreeTextFilter, createSelectionFilter,
    getCommonFiltersMap,
} from 'common/erm-components/custom/ClientFilterPageTable/ClientFilterPageTable.filters';
import { CommonFilterEnum } from 'common/erm-components/custom/ClientFilterPageTable/ClientFilterPageTable.consts';
import { IRecipe } from '../../../RecipeExampleCenter/RecipeExample.interface';
import { RECIPE_FIELD_NAMES } from '../../../RecipeExampleCenter/RecipeExample.consts';
import { RecipeHeadersNames, RecipeItemNames } from '../../RecipeEditor.consts';
import { IClientFilterType } from '../../../../custom/ClientFilterPageTable/ClientFilterPage.interface';

export const getRecipeFilters = (): IClientFilterType<IRecipe, IFilterConditionsContainer>[] => {
    const commonMap = getCommonFiltersMap<IRecipe>();
    return [
        commonMap[CommonFilterEnum.ADD_FILTER],
        commonMap[CommonFilterEnum.CLEAR_ALL],
        createFreeTextFilter([
            RECIPE_FIELD_NAMES.NAME,
            RECIPE_FIELD_NAMES.INSTRUCTIONS,
        ]),
        createSelectionFilter(RECIPE_FIELD_NAMES.MEAT_CATEGORY, RecipeHeadersNames[RecipeItemNames.meatCategory],),
        createSelectionFilter(RECIPE_FIELD_NAMES.MEAT_TYPES, RecipeHeadersNames[RecipeItemNames.meatTypes],),
        createSelectionFilter(RECIPE_FIELD_NAMES.DAIRY_PRODUCTS, RecipeHeadersNames[RecipeItemNames.dairyProducts],),
        createSelectionFilter(RECIPE_FIELD_NAMES.MEAT_ALTERNATIVES, RecipeHeadersNames[RecipeItemNames.meatAlternatives],),
    ];
};
