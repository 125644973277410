import { buildPath } from '../../extensibility/AddinContainer';

export const TABLE = 'table';
export const COLUMNS = 'columns';
export const FILTERS = 'filters';
export const ACTIONS = 'actions';
export const REPORTS_TABLE = buildPath('reports', TABLE);
export const REPORTS_TABLE_COLUMN_DEFS = buildPath(REPORTS_TABLE, COLUMNS);
export const REPORTS_TABLE_FILTER_DEFS = buildPath(REPORTS_TABLE, FILTERS);
export const REPORTS_TABLE_ACTION_DEFS = buildPath(REPORTS_TABLE, FILTERS);
