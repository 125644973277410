import { getService } from '../../../extensibility/AddinContainer';
import { ICompoundFilterNode } from '../../../erm-components/custom/FilterTree/CompoundFilter';

export const AUTO_ACTIONS_SERVICE_ID = 'AutoActionsService';

export const SUPPORTED_INTEGRATION_TYPES_URL = 'findings-policies/get-supported-integration-types';
export const GRAPH_RULES_URL = 'security-graph/rules';
export const GRAPH_ENTITY_TYPE_URL = 'security-graph/entity-types';

export enum AutoActionTriggerEnum {
    CREATED = 'Created',
    UPDATED = 'Updated',
    CLOSED = 'Closed',
}

export interface IAutoActionModel {
    id: string;
    name: string;
    description?: string;
    filter?: ICompoundFilterNode;
    triggers: AutoActionTriggerEnum[];
    integrationIds: string[];
    orgUnitIdsFilter?: string[];
    created?: string;
    updated?: string;
}


export interface IUpdatedAutoActionProps {
    name: string;
    description?: string;
    filter?: ICompoundFilterNode;
    triggers?: AutoActionTriggerEnum[];
    integrationIds: string[];
    orgUnitIdsFilter?: string[];
}

export interface IAutoActionsService {
    getAllAutoActions: () => Promise<IAutoActionModel[]>;

    updateAutoAction: (autoActionId: string, props: IUpdatedAutoActionProps) => Promise<string>;

    createAutoAction: (props: IUpdatedAutoActionProps) => Promise<string>;

    deleteAutoAction: (autoActionId: string) => Promise<string>;

    deleteAutoActions: (autoActionIds: string[], withException?: boolean) => Promise<string[]>;
}

export function getAutoActionsService(): IAutoActionsService {
    return getService<IAutoActionsService>(AUTO_ACTIONS_SERVICE_ID);
}
