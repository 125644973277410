import { IExportFile } from './Files.interface';

export const exportFileFromString = async (data: IExportFile) => {
    const url = window.URL.createObjectURL(new Blob([data.data as BlobPart]));
    const link = document.createElement('a');
    link.href = url;
    link.setAttribute('download', `${data.fileName}.${data.fileExtension}`);
    document.body.appendChild(link);
    link.click();
    document.body.removeChild(link);
};
