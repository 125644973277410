import i18n from 'i18next';
import { getRegionByName } from '../consts/vendors';

export function getRegionDisplayName(regionName: string, vendorId?: string): string {
    if (regionName === '' || regionName.toLowerCase() === 'global') {
        return i18n.t('ASSETS.PROTECTED_ASSETS.COLUMNS.REGION.GLOBAL');
    }

    const foundRegions = getRegionByName(regionName, vendorId);

    if (foundRegions.length === 0) {
        return regionName;
    }

    if (foundRegions.length === 1) {
        return `${foundRegions[0].region?.description ?? ''} (${regionName})`;
    }

    return `${i18n.t('ASSETS.PROTECTED_ASSETS.COLUMNS.REGION.MULTIPLE')} (${regionName})`;
}