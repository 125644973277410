import * as React from 'react';
import { SVGProps, Ref, forwardRef } from 'react';
const SvgDatalake = (
  props: SVGProps<SVGSVGElement>,
  ref: Ref<SVGSVGElement>
) => (
  <svg
    viewBox="0 0 16 17"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    ref={ref}
    {...props}
  >
    <path
      d="m.5 14 1.875 1.5a1 1 0 0 0 1.25 0l1.25-1a1 1 0 0 1 1.25 0l1.25 1a1 1 0 0 0 1.25 0l1.25-1a1 1 0 0 1 1.25 0l1.25 1a1 1 0 0 0 1.25 0L15.5 14m-15-2.5L2.375 13a1 1 0 0 0 1.25 0l1.25-1a1 1 0 0 1 1.25 0l1.25 1a1 1 0 0 0 1.25 0l1.25-1a1 1 0 0 1 1.25 0l1.25 1a1 1 0 0 0 1.25 0l1.875-1.5m-2-9c0 1.105-2.462 2-5.5 2s-5.5-.895-5.5-2m11 0v3m0-3s0-2-5.5-2-5.5 2-5.5 2m0 0v3m11 3c0 1.105-2.462 2-5.5 2s-5.5-.895-5.5-2m11 0v-3m0 3V11m-11-2.5V11m0-2.5v-3m11 0c0 1.105-2.462 2-5.5 2s-5.5-.895-5.5-2"
      stroke="currentColor"
      strokeLinecap="round"
    />
  </svg>
);
const ForwardRef = forwardRef(SvgDatalake);
export default ForwardRef;
