import styled from 'styled-components';
import Stack from '../Stack/Stack';

export const CardWrapper = styled(Stack)<{ smallSize?: boolean }>`
    border: 1px solid ${({ theme }) => `${theme.palette.border.light}`};
    border-radius: ${({ theme }) => `${theme.border.radius(2)}${theme.units}`};
    min-height: auto;
    .content {
        padding: ${({ smallSize }) => smallSize ? '0 8px 8px 8px' : '0 16px 16px 16px'};
        display: flex;
        flex-direction: column;
        flex: 1;
    }
`;

export const Header = styled(Stack)<{ smallSize?: boolean }>`
    padding: ${({ smallSize }) => smallSize ? '0px 8px' : '4px 8px 4px 16px'} ;
`;