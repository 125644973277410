import * as React from 'react';
import { SVGProps, Ref, forwardRef } from 'react';
const SvgCpLogotype = (
  props: SVGProps<SVGSVGElement>,
  ref: Ref<SVGSVGElement>
) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    viewBox="0 0 135 28.309"
    xmlSpace="preserve"
    ref={ref}
    {...props}
  >
    <path d="M30.629 16.36v-.03a4.892 4.892 0 0 1 5.031-4.966 4.965 4.965 0 0 1 3.796 1.497l-1.35 1.551a3.553 3.553 0 0 0-2.459-1.093 2.853 2.853 0 0 0-2.802 2.902l.003.089v.028a2.86 2.86 0 0 0 2.683 3.028l.102.004a3.47 3.47 0 0 0 2.531-1.123l1.345 1.365a4.92 4.92 0 0 1-3.952 1.708 4.857 4.857 0 0 1-4.923-4.963M41.212 11.546l2.118.006-.01 3.809 3.907.01.01-3.809 2.12.001-.025 9.627-2.118-.006.01-3.864-3.906-.01-.01 3.864-2.118-.006.022-9.622zM51.921 11.574l7.261.019-.005 1.885-5.155-.014-.005 1.952 4.538.012-.005 1.883-4.538-.012-.005 2.021 5.228.014-.005 1.884-7.328-.02.019-9.624zM60.695 16.438v-.027a4.892 4.892 0 0 1 5.031-4.966 4.971 4.971 0 0 1 3.792 1.494l-1.351 1.552a3.555 3.555 0 0 0-2.46-1.094 2.852 2.852 0 0 0-2.802 2.901l.003.09v.028a2.86 2.86 0 0 0 2.683 3.028l.1.004a3.48 3.48 0 0 0 2.534-1.123l1.344 1.365a4.917 4.917 0 0 1-3.946 1.708 4.858 4.858 0 0 1-4.923-4.963M71.159 11.624l2.119.006-.011 4.208 3.915-4.198 2.559.006-3.932 4.074 4.087 5.554-2.545-.007-2.973-4.107-1.118 1.152-.007 2.943-2.119-.006.025-9.625z" />
    <path
      fill="#D91572"
      d="m84.601 11.66 3.93.01c2.296.006 3.682 1.371 3.677 3.338v.024c-.006 2.228-1.74 3.379-3.901 3.373h-1.608l-.007 2.888-2.116-.006.025-9.627zm3.781 4.864a1.489 1.489 0 0 0 1.677-1.453v-.028c0-.948-.656-1.459-1.713-1.462h-1.637l-.007 2.943h1.68zM93.58 16.523v-.027a5.118 5.118 0 0 1 10.231 0v.028a5.118 5.118 0 0 1-10.231-.001m8.018.021v-.027a2.927 2.927 0 0 0-2.908-3.034 2.88 2.88 0 0 0-2.896 2.99v.031a2.925 2.925 0 0 0 2.815 3.03l.092.002a2.88 2.88 0 0 0 2.898-2.86c0-.043 0-.087-.002-.13M105.86 21.339l.028-9.627 2.116.006-.029 9.627zM110.662 11.726l1.953.005 4.495 5.937.016-5.926 2.089.005-.025 9.627-1.8-.005-4.647-6.132-.016 6.116-2.089-.006.024-9.621zM124.013 13.715l-2.929-.007.005-1.953 7.976.021-.005 1.953-2.926-.007-.02 7.674-2.122-.006.021-7.675zM131.512 13.998h-.393v-1.856h-.703v-.362h1.797v.361h-.703l.002 1.857zM133.911 13.459h-.013l-.692-1.04v1.582h-.386v-2.216h.416l.672 1.05.677-1.047H135v2.216h-.386v-1.589l-.703 1.044z"
    />
    <radialGradient
      id="cp-logotype_svg__a"
      cx={11.665}
      cy={13.358}
      r={11.666}
      gradientTransform="matrix(1 0 0 -1 0 30)"
      gradientUnits="userSpaceOnUse"
    >
      <stop
        offset={0}
        style={{
          stopColor: '#c40047',
        }}
      />
      <stop
        offset={0.887}
        style={{
          stopColor: '#ff0070',
        }}
      />
      <stop
        offset={1}
        style={{
          stopColor: '#ff0070',
        }}
      />
    </radialGradient>
    <path
      fill="url(#cp-logotype_svg__a)"
      d="M0 16.61c-.018 6.443 5.189 11.681 11.632 11.699s11.681-5.189 11.699-11.632a11.609 11.609 0 0 0-.319-2.741 4.272 4.272 0 0 1-3.901-.162l-3.437 4.562a2.875 2.875 0 1 1-4.722.528l-2.765-2.081-.859 1.141-3.169-2.383 2.382-3.165 3.165 2.382-.858 1.142 2.798 2.105a2.881 2.881 0 0 1 3.157-.354l3.423-4.544a4.259 4.259 0 0 1 0-6.109 11.605 11.605 0 0 0-6.537-2.023h-.032C5.229 4.98.017 10.183 0 16.61"
    />
    <path fill="#FFF" d="M18.232 7" />
    <path fill="none" d="M23.013 13.936" />
    <linearGradient
      id="cp-logotype_svg__b"
      gradientUnits="userSpaceOnUse"
      x1={0.482}
      y1={8.152}
      x2={22.447}
      y2={8.152}
      gradientTransform="matrix(1 0 0 -1 0 30)"
    >
      <stop
        offset={0}
        style={{
          stopColor: '#ff7bac',
          stopOpacity: 0,
        }}
      />
      <stop
        offset={0.289}
        style={{
          stopColor: '#ed6493',
          stopOpacity: 0.289,
        }}
      />
      <stop
        offset={0.902}
        style={{
          stopColor: '#c02852',
          stopOpacity: 0.9017,
        }}
      />
      <stop
        offset={1}
        style={{
          stopColor: '#b81e47',
        }}
      />
    </linearGradient>
    <path
      fill="url(#cp-logotype_svg__b)"
      d="M16.365 20.105a2.873 2.873 0 0 1-5.46 1.36C4.758 21.042.559 13.947.482 16.648.467 22.714 5.371 27.644 11.436 27.66s10.995-4.888 11.011-10.953c.004-2.002-2.356 1.361-6.082 3.398"
    />
    <path fill="none" d="M.479 16.037h21.967v11.508H.479z" />
    <path d="M28.909 7.107a4.371 4.371 0 0 1-6.814-5.474 4.37 4.37 0 0 1 6.144-.67l.004.003a4.37 4.37 0 0 1 .666 6.141" />
    <linearGradient
      id="cp-logotype_svg__c"
      gradientUnits="userSpaceOnUse"
      x1={1.956}
      y1={21.524}
      x2={18.526}
      y2={18.602}
      gradientTransform="matrix(1 0 0 -1 0 30)"
    >
      <stop
        offset={0}
        style={{
          stopColor: '#ff7bac',
          stopOpacity: 0,
        }}
      />
      <stop
        offset={0.468}
        style={{
          stopColor: '#ff7dad',
          stopOpacity: 0.468,
        }}
      />
      <stop
        offset={0.637}
        style={{
          stopColor: '#fd84b1',
          stopOpacity: 0.6367,
        }}
      />
      <stop
        offset={0.757}
        style={{
          stopColor: '#fa8fb7',
          stopOpacity: 0.7568,
        }}
      />
      <stop
        offset={0.854}
        style={{
          stopColor: '#f6a0c0',
          stopOpacity: 0.8539,
        }}
      />
      <stop
        offset={0.936}
        style={{
          stopColor: '#f1b6cb',
          stopOpacity: 0.9361,
        }}
      />
      <stop
        offset={1}
        style={{
          stopColor: '#ebcdd7',
        }}
      />
    </linearGradient>
    <path
      fill="url(#cp-logotype_svg__c)"
      d="M10.285 5.852a11.481 11.481 0 0 0-6.892 3.716 11.338 11.338 0 0 0-2.223 3.365c9.951-6.08 11.954-.114 16.202 1.306l.853-1.131a4.272 4.272 0 0 1-.705-5.206c.06-.099.129-.197.197-.288a10.963 10.963 0 0 0-6.032-1.863 9.316 9.316 0 0 0-1.396.099"
    />
    <path
      fill="#EBCDD7"
      d="M18.56 14.505a6.171 6.171 0 0 0 2.214-.197 4.236 4.236 0 0 1-1.663-.533l-.551.73z"
    />
    <linearGradient
      id="cp-logotype_svg__d"
      gradientUnits="userSpaceOnUse"
      x1={3.998}
      y1={22.152}
      x2={17.228}
      y2={6.385}
      gradientTransform="matrix(1 0 0 -1 0 30)"
    >
      <stop
        offset={0}
        style={{
          stopColor: '#ffe6ff',
          stopOpacity: 0.9,
        }}
      />
      <stop
        offset={0.101}
        style={{
          stopColor: '#ffcdec',
          stopOpacity: 0.8096,
        }}
      />
      <stop
        offset={0.248}
        style={{
          stopColor: '#ffb0d5',
          stopOpacity: 0.6763,
        }}
      />
      <stop
        offset={0.406}
        style={{
          stopColor: '#ff98c3',
          stopOpacity: 0.535,
        }}
      />
      <stop
        offset={0.573}
        style={{
          stopColor: '#ff88b6',
          stopOpacity: 0.384,
        }}
      />
      <stop
        offset={0.759}
        style={{
          stopColor: '#ff7eae',
          stopOpacity: 0.2166,
        }}
      />
      <stop
        offset={1}
        style={{
          stopColor: '#ff7bac',
          stopOpacity: 0,
        }}
      />
    </linearGradient>
    <path
      fill="url(#cp-logotype_svg__d)"
      d="M0 16.612c-.017 6.443 5.192 11.679 11.635 11.696l.296-5.677a2.882 2.882 0 0 1-.982-3.763l-2.762-2.082-.859 1.141-3.169-2.383 2.382-3.165 3.165 2.382-.858 1.141 2.798 2.105a2.881 2.881 0 0 1 3.157-.354l3.423-4.544a4.259 4.259 0 0 1 0-6.109 11.605 11.605 0 0 0-6.537-2.023h-.032C5.229 4.982.017 10.185 0 16.612"
    />
  </svg>
);
const ForwardRef = forwardRef(SvgCpLogotype);
export default ForwardRef;
