import * as React from 'react';
import { SVGProps, Ref, forwardRef } from 'react';
const SvgUser = (props: SVGProps<SVGSVGElement>, ref: Ref<SVGSVGElement>) => (
  <svg
    viewBox="0 0 16 16"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    ref={ref}
    {...props}
  >
    <circle cx={8} cy={4} r={3.5} stroke="currentColor" />
    <path
      d="M2.357 15.5h11.286a.857.857 0 0 0 .857-.857A5.143 5.143 0 0 0 9.357 9.5H6.643A5.143 5.143 0 0 0 1.5 14.643c0 .473.384.857.857.857Z"
      stroke="currentColor"
      strokeLinejoin="round"
    />
  </svg>
);
const ForwardRef = forwardRef(SvgUser);
export default ForwardRef;
