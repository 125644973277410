import styled from 'styled-components';
import Stack from '../../Stack';
import Typography from '../../Typography';

export const TextContainer = styled(Stack)`
    flex: 1 0 0;
    overflow-wrap: break-word;
`;

export const SubTitle = styled(Typography)`
    width: 100%;
    overflow-wrap: break-word;
`;
