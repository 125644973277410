import React, { useCallback, useEffect, useRef, useState } from 'react';
import { IFilterProps, initFilterPanel } from 'common/components/FilterPanel/FilterPanelManager';
import { Aggregations } from 'common/components/FilterPanel/FilterPanel.interface';
import { getCustomizationService } from 'common/interface/services';
import { getCloudAccountsService, getOrganizationalUnitService, ICloudAccount } from 'common/interface/data_services';
import { PanelStyled } from './ClientFilterPanel.styled';
import { getSavedFilterDefs } from 'common/utils/filterUtils';
import { Button, Spinner } from 'common/design-system/components-v2';
import {
    IFilterFieldOptionsMap,
    IFiltersInitialData,
    IProtectedAssetFilterParams,
} from 'common/module_interface/assets/ProtectedAssets';
import { IFilterConditionsContainer } from 'common/erm-components/custom/FilterTree/FilterCondition';
import { runAll } from 'common/utils/helpFunctions';
import { RECENTLY_USED_FILTER_ID, SAVED_FILTERS_FILTER_ID } from '../ClientFilterPageTable.filters';
import { IClientFilterPanelProps, IClientFilterType } from '../ClientFilterPage.interface';

const emptyFilterBox = (
    <PanelStyled.SpinnerDiv>
        <Button
            iconProps={{ name: 'filter' }}
            variant={'text'}>
            <Spinner size={16} />
        </Button>
    </PanelStyled.SpinnerDiv>
);

export const ClientFilterPanel: React.FC<IClientFilterPanelProps> = (props: IClientFilterPanelProps) => {
    const { getInitialAggregations, getAggregations, filterTypes, dataChangesCounter, pageTableId, getFieldOptionsMap } = props;
    const [filterElement, setFilterElement] = useState(emptyFilterBox);
    const dataChangesCounterRef = useRef<number>(-1);

    const getFilterById = useCallback( (filterId: string, filterParams: IProtectedAssetFilterParams[]): IFilterProps | undefined => {
        const filter = filterTypes.find((filterType: IClientFilterType<any, IFilterConditionsContainer>) => filterType.id === filterId);
        if (filter && filterParams && (filterParams.length === 1)) {
            return filter.content(filterParams[0]);
        }
    }, [filterTypes]);

    useEffect(() => {
        const getFiltersInitialData = async () => {
            const recentlyUsedComponentName = filterTypes.find(filterType => (filterType.id === RECENTLY_USED_FILTER_ID))?.filterProps?.componentName ?? `${pageTableId}-recent`;
            const recentlyUsedPromise = getCustomizationService().getCustomization(recentlyUsedComponentName);
            const savedFiltersComponentName: string = filterTypes.find(filterType => (filterType.id === SAVED_FILTERS_FILTER_ID))?.filterProps?.savedFiltersComponentName ?? `${pageTableId}-saved-filters`;
            const savedFiltersPromise = getCustomizationService().getCustomization(savedFiltersComponentName);
            const organizationalUnitsPromise = getOrganizationalUnitService().getOrganizationalUnitsView();

            return Promise.all([recentlyUsedPromise, savedFiltersPromise, organizationalUnitsPromise]).then(
                ([recentlyUsed, savedFilters, organizationalUnits]) => {
                    return {
                        recentlyUsed,
                        savedFilters,
                        organizationalUnits,
                    };
                },
            );
        };

        async function renderFilterPanel(aggregations: Aggregations) {
            const promisesMap: any = {
                filtersInitialData: getFiltersInitialData(),
                allCloudAccounts: getCloudAccountsService().getAllCloudAccounts(),
            };
            if (getFieldOptionsMap) {
                promisesMap.fieldOptionsMap = getFieldOptionsMap();
            }

            let filtersInitialData: IFiltersInitialData = {};
            let allCloudAccounts: ICloudAccount[] = [];
            let fieldOptionsMap: IFilterFieldOptionsMap | undefined;
            try {
                const resultsMap: any = await runAll(promisesMap, true);
                filtersInitialData = resultsMap.filtersInitialData;
                allCloudAccounts = resultsMap.allCloudAccounts;
                fieldOptionsMap = resultsMap.fieldOptionsMap;
            } catch (error) {
                console.error('Failed fetching filter data');
            }

            const filterPanelElementsList = getSavedFilterDefs(filterTypes, {
                aggregations,
                filtersInitialData,
                allCloudAccounts,
                fieldOptionsMap,
            }, getFilterById);
            const filterPanel = initFilterPanel({
                filterPanelElementsList,
                getAggregations,
                shouldBuildObjectForAPI: true,
                filterId: `${pageTableId}-filter-panel`,
            });
            setFilterElement(filterPanel);
        }

        if (dataChangesCounterRef.current !== dataChangesCounter) {
            dataChangesCounterRef.current = dataChangesCounter;
            getInitialAggregations().then((aggregations: Aggregations) => {
                void renderFilterPanel(aggregations);
            }).catch(() => {
                void renderFilterPanel({});
            });
        }
    }, [dataChangesCounter, filterTypes, getAggregations, getInitialAggregations, pageTableId, getFieldOptionsMap, getFilterById]);

    return <PanelStyled.TopDiv>{filterElement}</PanelStyled.TopDiv>;
};