import React, { useMemo } from 'react';
import Tabs from './components/Tabs/Tabs';
import { ContentItem, TopSection } from './EntityViewer.styled';
import { EntityViewerProps } from './EntityViewer.interface';
import useTabs from './hooks/useTabs';
import { EntityHeaderProps } from 'common/design-system/components-v2/EntityHeader/EntityHeader.types';
import EntityHeader from 'common/design-system/components-v2/EntityHeader/EntityHeader';
import { IWebappIframeServicePlacement, getWebAppIframeService } from 'common/interface/services';
import { Stack } from 'common/design-system/components-v2';

const EntityViewer: React.FunctionComponent<EntityViewerProps> = ({ title, levelIcon, titleRightElements, details, tabs, chips, context }) => {
    const { selectedTab, handleOnTabChange } = useTabs(tabs);
    const contentRef = React.useRef<HTMLDivElement>(null);

    const entityViewerInfoProps = useMemo<EntityHeaderProps>(() => ({
        title,
        levelIcon,
        titleRightElements,
        blocks: details,
        chips,
        initialShowMoreState: true,
    }), [title, levelIcon, titleRightElements, details, chips]);

    React.useEffect(() => {
        if (!contentRef.current || !selectedTab) return;
        getWebAppIframeService().setOverlayWrapperRef(contentRef.current, context || IWebappIframeServicePlacement.REACT_AND_ANGULAR);
        getWebAppIframeService().setVisibility(!selectedTab.isReactTab);
        return () => {
            getWebAppIframeService().clearOverlayWrapperRef();
        };
    }, [contentRef, selectedTab, context]);


    const relevantTabs = tabs.filter(tab => {
        if (typeof tab.isRelevant === 'function') return tab.isRelevant();
        return tab.isRelevant !== undefined ? tab.isRelevant : true;
    });

    return (
        <Stack fullHeight overflow='hidden'>
            <TopSection padding={6}>
                <EntityHeader {...entityViewerInfoProps} />
            </TopSection>
            {selectedTab && (
                <Stack direction='row' fullHeight overflow='hidden'>
                    <Tabs
                        headers={relevantTabs.map(tab => ({ label: tab.label || tab.name }))}
                        selectedTab={relevantTabs.findIndex(tab => tab.name === selectedTab.name)}
                        onTabChange={(tabIndex) => handleOnTabChange(relevantTabs[tabIndex])}
                    />
                    <Stack padding={6} fullHeight fullWidth overflow='auto'>
                        <ContentItem ref={contentRef} id='wrapperREf'>
                            {selectedTab.content || null}
                        </ContentItem>
                    </Stack>
                </Stack>
            )}
        </Stack>
    );
};

export default EntityViewer;
