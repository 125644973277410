import * as React from 'react';
import { SVGProps, Ref, forwardRef } from 'react';
const SvgRiskManagement4 = (
  props: SVGProps<SVGSVGElement>,
  ref: Ref<SVGSVGElement>
) => (
  <svg
    viewBox="0 0 16 16"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    ref={ref}
    {...props}
  >
    <path
      d="M13.661 13.49c.848-.001 1.629-.534 1.755-1.373A7.501 7.501 0 0 0 1.874 6.673a7.5 7.5 0 0 0-1.288 5.458c.128.838.91 1.37 1.757 1.37L7.5 13.5l6.161-.01Z"
      stroke="currentColor"
    />
    <path
      d="m8 13.5 3.5-6"
      stroke="currentColor"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </svg>
);
const ForwardRef = forwardRef(SvgRiskManagement4);
export default ForwardRef;
