import * as React from 'react';
import { SVGProps, Ref, forwardRef } from 'react';
const SvgRemedy = (props: SVGProps<SVGSVGElement>, ref: Ref<SVGSVGElement>) => (
  <svg
    viewBox="0 0 16 16"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    ref={ref}
    {...props}
  >
    <rect
      x={0.5}
      y={3.5}
      width={14}
      height={10}
      rx={1}
      stroke="currentColor"
      strokeLinejoin="round"
    />
    <path
      d="M4.5 2.5a2 2 0 0 1 2-2h2a2 2 0 0 1 2 2v1h-6v-1Z"
      stroke="currentColor"
    />
    <path d="M4.5 8.5h6m-3-3v6" stroke="currentColor" strokeLinecap="round" />
  </svg>
);
const ForwardRef = forwardRef(SvgRemedy);
export default ForwardRef;
