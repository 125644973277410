import React from 'react';
import { Button, Stack, Typography } from 'common/design-system/components-v2';
import { showRecipeForm } from '../../RecipeEditor.items';

export const RecipeExampleEmptyState: React.FC<{ getIsUsingDrawer: () => boolean }> = ({ getIsUsingDrawer }) => {
    return (
        <Stack fullHeight fullWidth>
            <Stack direction={'column'} spacing={10} alignItems={'center'} justifyContent={'center'} fullHeight>
                <Typography variant={'h2'}>You do not have any recipes, yet :-( </Typography>
                <Stack direction={'column'} spacing={12} alignItems={'center'}>
                    <Button label={'Create new recipe'} onClick={() => showRecipeForm(getIsUsingDrawer())} color={'brandPrimary'} iconProps={{ name: 'plus' }} />
                </Stack>
            </Stack>
        </Stack>
    );
};
