export const FINDINGS_SEARCH_URL = 'Compliance/Finding/search';
export const NOTIFICATION_URL = 'Compliance/ContinuousComplianceNotification';
export const FINDINGS_GROUPING_URL = 'Compliance/Finding/GroupsByProperties';
export const FINDINGS_AGGREGATIONS_URL = 'Compliance/Finding/searchWithCustomAggregations';
export const POSTURE_FINDINGS_PAGE_URL = 'posture-findings';
export const SECURITY_EVENTS_PAGE_URL = 'threat-and-security-events';
export const FINDINGS_URL = 'Compliance/Finding/';

export const FINDINGS_ORIGINS = {
    INTELLIGENCE: 'Intelligence',
};

export const ColumnTypes = {
    SELECTION: 'selection',
    RULE_NAME: 'ruleName',
    SEVERITY: 'severity',
    CREATED_TIME: 'createdTime',
    CLOUD_ACCOUNT_ID: 'cloudAccountId',
    DESCRIPTION: 'description',
    PLATFORM: 'platform',
    BUNDLE_NAME: 'bundleName',
    ORIGIN: 'origin',
    MITRE: 'mitre',
    REMEDIATION: 'remediation',
    ASSIGNEE: 'assignee',
    ACTION: 'action',
    ENTITY_NAME: 'entityName',
    ENTITY_TYPE: 'entityType',
};
