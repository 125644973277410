import Highcharts from 'highcharts';
import HighchartsReact from 'highcharts-react-official';
import React, { useRef, useEffect, useState, useMemo } from 'react';
import { generateColors } from '../Widgets/helpers';
import { IGenericWidgetDataItemSet, IGenericWidgetDataSetProps } from 'common/module_interface/overview/Interface';
import { baseTooltipStyle, getIsLabelsEnabled } from './Utils';
import { mergeJsons } from '../../utils/helpFunctions';
import { useProfiler } from '@sentry/react';

interface ISeriesDataItem {
    name?: string,
    data: any[],
    color?: string
}

const extractCategories = (items: IGenericWidgetDataItemSet[]) => {
    return items.reduce((result: string[], item) => {
        if (!result.find(resultItem => resultItem === item.key)) {
            result.push(item.key);
        }
        return result;

    }, []);
};

const extractSeries = (items?: IGenericWidgetDataItemSet[]) => {
    const alternateColors = items && items.length && generateColors(items.length) || generateColors(100);
    return items?.reduce((result: ISeriesDataItem[], item: IGenericWidgetDataItemSet) => {
        if (item.value?.length > 0 && Array.isArray(item.value)) {
            item.value?.forEach((dataItem, index) => {
                if (!dataItem) {
                    return null;
                }
                const selectedResultItem = result.find(resItem => resItem.name === dataItem.key);
                if (selectedResultItem) {
                    selectedResultItem.data.push(dataItem.value);
                } else {
                    result.push({
                        name: dataItem?.key,
                        data: [dataItem.value],
                        color: dataItem.color || alternateColors[index]
                    });
                }
            });
        }
        return result;
    }, []);
};


const getDefaultOptions = (chartWidth: null | number, isShowLabels: boolean, items: IGenericWidgetDataItemSet[] = [], categories: string[] = []) => {
    return {
        series: items && extractSeries(items),
        chart: {
            type: 'column',
            backgroundColor: 'transparent',
            style: {
                fontFamily: 'Verdana, sans-serif'
            },
            width: chartWidth,
        },
        legend: {
            enabled: false
        },
        credits: {
            enabled: false,
        },
        plotOptions: {
            column: {
                allowPointSelect: true,
                dataLabels: {
                    enabled: false,
                },
                stacking: 'normal',
                pointWidth: 10,
            },
            series: {
                dataLabels: {
                    enabled: false
                },
                cursor: 'pointer',
                events: {
                    click: () => {
                        if (items) {
                            items.length > 0 && items[0].onclick && items[0].onclick();
                        }
                    }
                },
                states: {
                    hover: {
                        enabled: false,
                    },
                    inactive: {
                        opacity: 1,
                    },
                },
            },
        },
        xAxis: {
            categories: categories,
            labels: {
                enabled: isShowLabels
            },
            style: {
                fontSize: '10px',
                fontFamily: 'Verdana, sans-serif'
            }
        },
        yAxis: {
            title: {
                text: '',
            },
            dataLabels: {
                enabled: false
            },
            labels: {
                enabled: false,
            },
            stackLabels: {
                dataLabels: {
                    enabled: false
                },
                enabled: true,
                style: {
                    fontWeight: 'inherit',
                    color: 'black',
                    fontSize: '12px',
                },
            },
        },
        title: {
            text: '',
        },
        tooltip: {
            enabled: true,
            shared: true,
            style: baseTooltipStyle,
        }
    };
};

const StackedColumnChart: React.FC<IGenericWidgetDataSetProps> = (props) => {
    useProfiler( `StackedColumnChart|${props.header}`);

    const chartRef = useRef<any>();
    const [categories, setCategories] = useState<string[] | undefined>();
    const [chartOptions, setChartOptions] = useState<Object>({});
    const [chartWidth, setChartWidth] = useState<null | number>(null);

    useEffect(() => {
        const chart = chartRef.current?.chart;
        const isLabelsEnabled = getIsLabelsEnabled(chartRef);
        const defaultOptions = getDefaultOptions(chartWidth, isLabelsEnabled, props?.data?.items, categories);
        const options = props?.data?.options;
        const finalOptions = options ? mergeJsons(defaultOptions, options) : defaultOptions;
        setChartOptions(finalOptions);
        if (chart) chart.reflow();
    }, [chartRef, chartWidth, categories, props?.data?.items, props?.data?.options]);

    useEffect(() => {
        const containerWidth = chartRef?.current?.container?.current.clientWidth;
        const items = props?.data?.items;
        const categories = items && extractCategories(items);
        setCategories(categories);
        if (containerWidth && categories && (categories?.length * 30 > containerWidth)) {
            setChartWidth(categories?.length * 30);
        } else {
            setChartWidth(null);
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    const containerProps = useMemo(() => {
        return {
            style: {
                width: chartWidth,
                display: 'flex',
                position: 'relative',
                height: '100%',
                flex: 1,
            },
        };
    },[chartWidth]);

    return (
        <div className='h-full overflow-x-auto stacked-column-chart' style={{ overflowX: 'auto' }}>
            {chartRef &&
                <HighchartsReact
                    ref={chartRef}
                    highcharts={Highcharts}
                    options={chartOptions}
                    containerProps={containerProps}
                />
            }
        </div>
    );
};
export default StackedColumnChart;
