import * as React from 'react';
import { SVGProps, Ref, forwardRef } from 'react';
const SvgGdpr = (props: SVGProps<SVGSVGElement>, ref: Ref<SVGSVGElement>) => (
  <svg
    id="GDPR_svg__Layer_1"
    xmlns="http://www.w3.org/2000/svg"
    x={0}
    y={0}
    viewBox="0 0 64 64"
    xmlSpace="preserve"
    ref={ref}
    {...props}
  >
    <style>
      {
        '.GDPR_svg__st0{fill:#fecc00}.GDPR_svg__st1{opacity:.15}.GDPR_svg__st2{fill:#022f9b}'
      }
    </style>
    <path
      className="GDPR_svg__st0"
      d="m31.89.3 1.3 2.62 2.9.42-2.1 2.05.5 2.88-2.6-1.36-2.59 1.36.5-2.88-2.1-2.05 2.9-.42z"
    />
    <path
      d="m31.89.97 1.03 2.08.07.14.16.02 2.3.33-1.66 1.62-.11.11.03.16.39 2.29-2.05-1.08-.14-.07-.14.07-2.07 1.09.39-2.29.03-.16-.11-.11-1.66-1.62 2.3-.33.15-.02.07-.14L31.89.97m0-.67-1.3 2.63-2.9.42 2.1 2.04-.5 2.89 2.59-1.36 2.59 1.36-.5-2.89 2.1-2.04-2.9-.42L31.89.3z"
      className="GDPR_svg__st1"
    />
    <path
      className="GDPR_svg__st0"
      d="m31.89 55.73 1.3 2.62 2.9.42-2.1 2.05.5 2.88-2.6-1.36-2.59 1.36.5-2.88-2.1-2.05 2.9-.42z"
    />
    <path
      d="m31.89 56.4 1.03 2.08.07.14.16.02 2.3.33-1.66 1.62-.11.11.03.16.39 2.29-2.05-1.08-.16-.07-.14.07-2.05 1.08.39-2.29.03-.16-.11-.11-1.66-1.62 2.3-.33.16-.02.07-.14 1.01-2.08m0-.67-1.3 2.63-2.9.42 2.1 2.04-.5 2.89 2.59-1.36 2.59 1.36-.5-2.89 2.1-2.04-2.9-.42-1.28-2.63z"
      className="GDPR_svg__st1"
    />
    <path
      className="GDPR_svg__st0"
      d="m59.81 28.01 1.29 2.63 2.9.42-2.1 2.04.5 2.89-2.59-1.36-2.6 1.36.5-2.89-2.1-2.04 2.9-.42z"
    />
    <path
      d="m59.81 28.69 1.03 2.08.07.14.16.02 2.3.33-1.66 1.62-.13.12.03.16.39 2.28-2.05-1.08-.14-.07-.14.07-2.05 1.08.38-2.29.03-.15-.11-.11-1.66-1.62 2.3-.33.16-.02.07-.14 1.02-2.09m0-.68-1.3 2.63-2.9.42 2.1 2.04-.5 2.89 2.59-1.36 2.59 1.36-.5-2.89 2.1-2.04-2.9-.42-1.28-2.63z"
      className="GDPR_svg__st1"
    />
    <path
      className="GDPR_svg__st0"
      d="m56.32 41.95 1.3 2.62 2.9.42-2.1 2.05.49 2.89-2.59-1.37-2.59 1.37.49-2.89-2.09-2.05 2.9-.42z"
    />
    <path
      d="m56.32 42.62 1.03 2.08.07.14.16.02 2.3.33-1.66 1.62-.11.11.03.16.39 2.29-2.05-1.08-.14-.07-.14.07-2.05 1.08.39-2.29.03-.16-.11-.11-1.66-1.62 2.3-.33.16-.02.07-.14.99-2.08m0-.67-1.3 2.63-2.9.42 2.1 2.04-.5 2.89 2.59-1.36 2.59 1.36-.5-2.89L60.5 45l-2.9-.42-1.28-2.63z"
      className="GDPR_svg__st1"
    />
    <path
      className="GDPR_svg__st0"
      d="m46.06 51.74 1.29 2.62 2.9.42-2.1 2.05.5 2.88-2.59-1.36-2.6 1.36.5-2.88-2.1-2.05 2.9-.42z"
    />
    <path
      d="m46.06 52.41 1.03 2.08.07.14.16.02 2.3.33-1.66 1.62-.11.11.03.16.39 2.29-2.05-1.08-.14-.07-.14.07-2.05 1.08.39-2.29.03-.16-.11-.11-1.66-1.62 2.3-.33.16-.02.07-.14.99-2.08m0-.67-1.3 2.63-2.9.42 2.1 2.04-.5 2.89 2.59-1.36 2.59 1.36-.5-2.89 2.1-2.04-2.9-.42-1.28-2.63z"
      className="GDPR_svg__st1"
    />
    <path
      className="GDPR_svg__st0"
      d="m4.19 28.01 1.3 2.63 2.9.42-2.1 2.04.5 2.89-2.6-1.36-2.59 1.36.5-2.89L0 31.06l2.9-.42z"
    />
    <path
      d="m4.19 28.69 1.03 2.08.07.14.16.02 2.3.33-1.66 1.62-.12.12.03.15.39 2.29-2.05-1.08-.14-.07-.14.07L2 35.44l.39-2.29.03-.15-.11-.11-1.66-1.62 2.3-.33.16-.02.07-.14 1.01-2.09m0-.68-1.3 2.63-2.89.42 2.1 2.04-.5 2.89 2.59-1.36 2.59 1.36-.5-2.89 2.1-2.04-2.9-.42-1.29-2.63z"
      className="GDPR_svg__st1"
    />
    <path
      className="GDPR_svg__st0"
      d="m18.15 3.88 1.29 2.63 2.9.42-2.1 2.04.5 2.89-2.59-1.36-2.6 1.36.5-2.89-2.1-2.04 2.9-.42z"
    />
    <path
      d="m18.15 4.56 1.03 2.08.07.14.16.02 2.3.33-1.66 1.62-.11.11.03.16.39 2.29-2.05-1.08-.14-.07-.14.07-2.05 1.08.39-2.29.03-.16-.11-.11-1.69-1.61 2.3-.33.16-.02.07-.14 1.02-2.09m0-.68-1.3 2.63-2.9.42 2.1 2.04-.5 2.89 2.59-1.36 2.59 1.36-.5-2.89 2.1-2.04-2.9-.42-1.28-2.63z"
      className="GDPR_svg__st1"
    />
    <path
      className="GDPR_svg__st0"
      d="m18.15 51.74 1.29 2.62 2.9.42-2.1 2.05.5 2.88-2.59-1.36-2.6 1.36.5-2.88-2.1-2.05 2.9-.42z"
    />
    <path
      d="m18.15 52.41 1.03 2.08.07.14.16.02 2.3.33-1.66 1.62-.11.11.03.16.39 2.29-2.05-1.08-.14-.07-.14.07-2.05 1.08.39-2.29.03-.16-.11-.11-1.66-1.62 2.3-.33.16-.02.07-.14.99-2.08m0-.67-1.3 2.63-2.9.42 2.1 2.04-.5 2.89 2.59-1.36 2.59 1.36-.5-2.89 2.1-2.04-2.9-.42-1.28-2.63z"
      className="GDPR_svg__st1"
    />
    <path
      className="GDPR_svg__st0"
      d="m46.06 4.29 1.29 2.62 2.9.42-2.1 2.05.5 2.88-2.59-1.36-2.6 1.36.5-2.88-2.1-2.05 2.9-.42z"
    />
    <path
      d="m46.06 4.96 1.03 2.08.07.14.16.02 2.3.33-1.66 1.62-.11.11.03.16.39 2.29-2.05-1.08-.14-.07-.14.07-2.05 1.08.39-2.29.03-.16-.11-.11-1.66-1.62 2.3-.33.16-.02.07-.14.99-2.08m0-.67-1.3 2.63-2.9.42 2.1 2.04-.5 2.89 2.59-1.36 2.59 1.36-.5-2.89 2.1-2.04-2.9-.42-1.28-2.63z"
      className="GDPR_svg__st1"
    />
    <path
      className="GDPR_svg__st0"
      d="m56.32 14.18 1.3 2.62 2.9.42-2.1 2.05.49 2.89-2.59-1.37-2.59 1.37.49-2.89-2.09-2.05 2.9-.42z"
    />
    <path
      d="m56.32 14.86 1.03 2.08.07.14.16.02 2.3.33-1.66 1.62-.11.11.03.16.39 2.29-2.05-1.08-.14-.07-.14.07-2.05 1.08.39-2.29.03-.16-.11-.11-1.66-1.62 2.3-.33.16-.02.07-.14.99-2.08m0-.68-1.3 2.63-2.9.42 2.1 2.04-.5 2.89 2.59-1.36 2.59 1.36-.5-2.89 2.1-2.04-2.9-.42-1.28-2.63z"
      className="GDPR_svg__st1"
    />
    <path
      className="GDPR_svg__st0"
      d="m7.82 42.19 1.3 2.62 2.9.43-2.1 2.04.5 2.89-2.6-1.37-2.59 1.37.5-2.89-2.1-2.04 2.9-.43z"
    />
    <path
      d="m7.82 42.87 1.03 2.08.07.14.16.02 2.3.33-1.66 1.62-.12.12.03.16.39 2.29-2.05-1.08-.14-.07-.14.07-2.05 1.08.39-2.29.03-.16-.11-.11-1.66-1.62 2.3-.33.16-.02.07-.14 1-2.09m0-.68-1.3 2.63-2.9.42 2.1 2.04-.5 2.89 2.59-1.36 2.59 1.36-.5-2.89 2.1-2.04-2.9-.42-1.28-2.63z"
      className="GDPR_svg__st1"
    />
    <path
      className="GDPR_svg__st0"
      d="m8.39 14.18 1.29 2.62 2.9.42-2.09 2.05.49 2.89-2.59-1.37-2.59 1.37.49-2.89-2.1-2.05 2.9-.42z"
    />
    <path
      d="m8.39 14.86 1.03 2.08.07.14.16.02 2.3.33-1.66 1.62-.11.11.03.16.39 2.29-2.05-1.08-.14-.07-.14.07-2.05 1.08.39-2.29.03-.16-.14-.11-1.66-1.62 2.3-.33.16-.02.07-.14 1.02-2.08m0-.68-1.3 2.63-2.9.42 2.1 2.04-.5 2.89 2.59-1.36 2.59 1.36-.5-2.89 2.1-2.04-2.9-.42-1.28-2.63z"
      className="GDPR_svg__st1"
    />
    <path
      className="GDPR_svg__st2"
      d="M18.58 31.44h3.64v4.76c-.59.19-1.15.33-1.67.4-.52.08-1.05.12-1.6.12-1.38 0-2.44-.41-3.17-1.22s-1.09-1.98-1.09-3.5c0-1.48.42-2.64 1.27-3.46.85-.83 2.02-1.24 3.52-1.24.94 0 1.85.19 2.72.56l-.65 1.56a4.69 4.69 0 0 0-2.09-.5c-.84 0-1.51.28-2.02.85-.51.56-.76 1.32-.76 2.28 0 1 .2 1.76.61 2.28.41.52 1 .79 1.78.79.41 0 .82-.04 1.24-.13v-1.91h-1.74v-1.64zM32.01 31.92c0 1.51-.43 2.67-1.29 3.47-.86.8-2.1 1.2-3.72 1.2h-2.6v-9.17h2.88c1.5 0 2.66.4 3.49 1.19.83.78 1.24 1.89 1.24 3.31zm-2.02.05c0-1.97-.87-2.96-2.61-2.96h-1.04v5.97h.83c1.89 0 2.82-1 2.82-3.01zM40.25 30.28c0 .99-.31 1.74-.93 2.27-.62.52-1.49.78-2.63.78h-.83v3.26h-1.95v-9.17h2.93c1.11 0 1.96.24 2.54.72.58.47.87 1.19.87 2.14zm-4.39 1.45h.64c.6 0 1.05-.12 1.34-.35.3-.24.45-.58.45-1.03 0-.46-.12-.79-.37-1.01s-.64-.33-1.17-.33h-.88v2.72zM43.93 33.07v3.52h-1.95v-9.17h2.67c1.25 0 2.17.23 2.77.68.6.45.9 1.14.9 2.07 0 .54-.15 1.02-.45 1.44-.3.42-.72.75-1.26.99 1.38 2.06 2.28 3.39 2.7 4h-2.16l-2.19-3.52h-1.03zm0-1.58h.63c.61 0 1.07-.1 1.36-.31.29-.2.44-.53.44-.97 0-.44-.15-.74-.45-.93-.3-.18-.76-.28-1.39-.28h-.59v2.49z"
    />
  </svg>
);
const ForwardRef = forwardRef(SvgGdpr);
export default ForwardRef;
