import React from 'react';
import { buildPath } from '../../extensibility/AddinContainer';
import { ExposureNodeData } from './ExposureNodeData';

export const PATH_INSIGHT = 'insight';
export const PATH_INSIGHT_PERIMETER_EXPOSURE = buildPath(PATH_INSIGHT, 'perimeter-exposure');
export const PATH_INSIGHT_PERIMETER_EXPOSURE_ASSET = buildPath(PATH_INSIGHT_PERIMETER_EXPOSURE, 'asset');
export const PATH_INSIGHT_PERIMETER_EXPOSURE_ASSET_POPOVER = buildPath(PATH_INSIGHT_PERIMETER_EXPOSURE_ASSET, 'popover');
export const PATH_INSIGHT_PERIMETER_EXPOSURE_ASSET_POPOVER_ITEM = buildPath(PATH_INSIGHT_PERIMETER_EXPOSURE_ASSET_POPOVER, 'item');
export const PATH_INSIGHT_PERIMETER_EXPOSURE_ASSET_POPOVER_CHIP = buildPath(PATH_INSIGHT_PERIMETER_EXPOSURE_ASSET_POPOVER, 'chip');
export const PATH_INSIGHT_PERIMETER_EXPOSURE_ASSET_POPOVER_BADGE = buildPath(PATH_INSIGHT_PERIMETER_EXPOSURE_ASSET_POPOVER, 'badge');

export interface PerimeterExposureAsset {
    id: string;
    component: React.FC<{ data: ExposureNodeData }>;
}

export interface AssetPopoverBadge {
    id: string;
    position: 'nw' | 'n' | 'ne' | 'e' | 'se' | 's' | 'sw' | 'w';
    component: React.FC<{ data: ExposureNodeData }>;
    permission?: () => boolean;
}