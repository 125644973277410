
interface IEventHandler {
    id: string;
    onEvent: IOnEvent;
}
export type IOnEvent = (event?: any) => void;

interface IEventBus {
    registerEventHandler: (id: string, onEvent: IOnEvent) => void;
    unregisterEventHandler: (id: string) => void;
    sendEvent: (id: string, event?: any) => void;
}

class EventBus implements IEventBus {
    private readonly eventHandlersMap: { [key: string]: IEventHandler } = {};

    public registerEventHandler(id: string, onEvent: IOnEvent) {
        this.eventHandlersMap[id] = {
            id,
            onEvent,
        };
    }

    public unregisterEventHandler(id: string) {
        if (this.eventHandlersMap[id]) {
            delete this.eventHandlersMap[id];
        }
    }

    public sendEvent(id: string, event?: any) {
        const eventHandler = this.eventHandlersMap[id];
        if (eventHandler) {
            eventHandler.onEvent(event);
        }
    }
}
export const globalEventBus: EventBus = new EventBus();