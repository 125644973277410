export const AssetTypes = {
    AWS_FIREWALLMANAGERADMINACCOUNT: 'aws|FirewallManagerAdminAccount',
    AWS_FIREWALLMANAGERPOLICY: 'aws|FirewallManagerPolicy',
    AWS_CONFIGRULE: 'aws|ConfigRule',
    AWS_COMPREHENDFLYWHEEL: 'aws|ComprehendFlywheel',
    AWS_COMPREHENDENDPOINT: 'aws|ComprehendEndpoint',
    AWS_COMPUTEOPTIMIZERRECSUMMARY: 'aws|ComputeOptimizerRecSummary',
    AWS_FORECASTPREDICTOR: 'aws|ForecastPredictor',
    AWS_FORECASTMONITOR: 'aws|ForecastMonitor',
    AWS_FORECAST: 'aws|Forecast',
    AWS_FORECASTEXPLAINABILITY: 'aws|ForecastExplainability',
    AWS_FORECASTDATASETGROUP: 'aws|ForecastDatasetGroup',
    AWS_FORECASTDATASET: 'aws|ForecastDataset',
    AWS_MWAAENVIRONMENT: 'aws|MWAAEnvironment',
    AWS_APPFABRICAPPBUNDLE: 'aws|AppFabricAppBundle',
    AWS_KINESISANALYTICSAPPLICATION: 'aws|KinesisAnalyticsApplication',
    AWS_APPSTREAM2USAGEREPORT: 'aws|AppStream2UsageReport',
    AWS_KEYSPACE: 'aws|KeySpace',
    AWS_APPRUNNERSERVICE: 'aws|AppRunnerService',
    AWS_APPRUNNERCONNECTION: 'aws|AppRunnerConnection',
    AWS_APPRUNNERVPCCONNECTOR: 'aws|AppRunnerVpcConnector',
    AWS_APPRUNNERAUTOSCALINGCONF: 'aws|AppRunnerAutoScalingConf',
    AWS_LIGHTSAILRELATIONALDATABASE: 'aws|LightsailRelationalDatabase',
    AWS_LIGHTSAILLOADBALANCER: 'aws|LightsailLoadBalancer',
    AWS_LIGHTSAILINSTANCE: 'aws|LightsailInstance',
    AWS_LIGHTSAILDOMAIN: 'aws|LightsailDomain',
    AWS_LIGHTSAILDISTRIBUTION: 'aws|LightsailDistribution',
    AWS_LIGHTSAILDISK: 'aws|LightsailDisk',
    AWS_ELASTICTRANSCODERPIPELINE: 'aws|ElasticTranscoderPipeline',
    AWS_CODESTARPROJECT: 'aws|CodeStarProject',
    AWS_CODESTARUSERPROFILE: 'aws|CodeStarUserProfile',
    AWS_CODEBUILDBUILD: 'aws|CodeBuildBuild',
    AWS_VERIFIEDPERMISSIONSPOLICYSTORE: 'aws|VerifiedPermissionsPolicyStore',
    AWS_BATCHJOBDEFINITION: 'aws|BatchJobDefinition',
    AWS_BATCHJOBCOMPUTEENVIRONMENT: 'aws|BatchJobComputeEnvironment',
    AWS_BATCHJOBQUEUE: 'aws|BatchJobQueue',
    AWS_APPFLOWCONNECTOR: 'aws|AppFlowConnector',
    AWS_CLOUDHSMCLUSTER: 'aws|CloudHSMCluster',
    AWS_CLOUDHSMBACKUP: 'aws|CloudHSMBackup',
    AWS_ACMPRIVATECERTIFICATEAUTHORITY: 'aws|ACMPrivateCertificateAuthority',
    AWS_GLOBALACCELERATOR: 'aws|GlobalAccelerator',
    AWS_GLOBALCUSTOMACCELERATOR: 'aws|GlobalCustomAccelerator',
    AWS_AMPLIFYAPP: 'aws|AmplifyApp',
    AWS_DEVOPSGURUSERVICEINTEGRATION: 'aws|DevOpsGuruServiceIntegration',
    AWS_RAMRESOURCE: 'aws|RamResource',
    AWS_RAMPERMISSION: 'aws|RamPermission',
    AWS_RAMRESOURCESHARE: 'aws|RamResourceShare',
    AWS_RAMPRINCIPAL: 'aws|RamPrincipal',
    AWS_CODEPIPELINEWEBHOOK: 'aws|CodePipelineWebhook',
    AWS_S3BUCKET: 'aws|S3Bucket',
    AWS_SERVERLESSAPPREPO: 'aws|ServerlessAppRepo',
    AWS_MEDIAPACKAGECHANNEL: 'aws|MediaPackageChannel',
    AWS_MEDIAPACKAGEHARVESTJOB: 'aws|MediaPackageHarvestJob',
    AWS_MEDIAPACKAGEORIGINENDPOINT: 'aws|MediaPackageOriginEndpoint',
    AWS_DATAEXCHANGEDATASET: 'aws|DataExchangeDataSet',
    AWS_DATAEXCHANGEEVENTACTION: 'aws|DataExchangeEventAction',
    AWS_DATAEXCHANGEJOB: 'aws|DataExchangeJob',
    AWS_MEDIALIVECHANNEL: 'aws|MediaLiveChannel',
    AWS_MEDIALIVEINPUT: 'aws|MediaLiveInput',
    AWS_MEDIALIVEINPUTSECURITYGROUP: 'aws|MediaLiveInputSecurityGroup',
    AWS_MEDIALIVEMULTIPLEX: 'aws|MediaLiveMultiplex',
    AWS_MEDIALIVERESERVATION: 'aws|MediaLiveReservation',
    AWS_CHIMEACCOUNT: 'aws|ChimeAccount',
    AWS_XRAYENCRYPTIONCONFIG: 'aws|XRayEncryptionConfig',
    AWS_MEDIASTORECONTAINER: 'aws|MediaStoreContainer',
    AWS_MEDIATAILORCHANNEL: 'aws|MediaTailorChannel',
    AWS_MEDIATAILORPLAYBACKCONF: 'aws|MediaTailorPlaybackConf',
    AWS_MEDIATAILORSOURCELOCATION: 'aws|MediaTailorSourceLocation',
    AWS_XRAYGROUP: 'aws|XRayGroup',
    AWS_XRAYSAMPLINGRULE: 'aws|XRaySamplingRule',
    AWS_CODEARTIFACTDOMAIN: 'aws|CodeArtifactDomain',
    AWS_CODEARTIFACTREPOSITORY: 'aws|CodeArtifactRepository',
    AWS_S3GLACIERVAULT: 'aws|S3GlacierVault',
    AWS_SESACTIVERECEIPTRULESET: 'aws|SesActiveReceiptRuleset',
    AWS_MEMORYDBACL: 'aws|MemoryDbAcl',
    AWS_MEMORYDBCLUSTER: 'aws|MemoryDbCluster',
    AWS_MEMORYDBSNAPSHOT: 'aws|MemoryDbSnapshot',
    AWS_MEMORYDBUSER: 'aws|MemoryDbUser',
    AWS_DOCDBCLUSTER: 'aws|DocDbCluster',
    AWS_DOCDBCLUSTERSNAPSHOT: 'aws|DocDbClusterSnapshot',
    AWS_DOCDBGLOBALCLUSTER: 'aws|DocDbGlobalCluster',
    AWS_DOCDBINSTANCE: 'aws|DocDbInstance',
    AWS_NEPTUNECLUSTER: 'aws|NeptuneCluster',
    AWS_NEPTUNECLUSTERSNAPSHOT: 'aws|NeptuneClusterSnapshot',
    AWS_NEPTUNEGLOBALCLUSTER: 'aws|NeptuneGlobalCluster',
    AWS_NEPTUNEINSTANCE: 'aws|NeptuneInstance',
    AWS_RDSEVENTSUBSCRIPTION: 'aws|RdsEventSubscription',
    AWS_CODEBUILDPROJECT: 'aws|CodeBuildProject',
    AWS_RDSDBCLUSTERPARAMETERGROUP: 'aws|RdsDbClusterParameterGroup',
    AWS_INSTANCE: 'aws|Instance',
    AWS_LAMBDA: 'aws|Lambda',
    AWS_IAMROLE: 'aws|IamRole',
    AWS_VOLUME: 'aws|Volume',
    AWS_EBSSNAPSHOT: 'aws|EbsSnapshot',
    AWS_IAMUSER: 'aws|IamUser',
    AWS_IAMPOLICY: 'aws|IamPolicy',
    AWS_SUBNET: 'aws|Subnet',
    AWS_NACL: 'aws|NACL',
    AWS_DYNAMODBTABLE: 'aws|DynamoDbTable',
    AWS_APPLICATIONAUTOSCALINGPOLICY: 'aws|ApplicationAutoScalingPolicy',
    AWS_VPC: 'aws|VPC',
    AWS_ACMCERTIFICATE: 'aws|AcmCertificate',
    AWS_KMS: 'aws|KMS',
    AWS_APIGATEWAY: 'aws|ApiGateway',
    AWS_APIGATEWAYV2: 'aws|ApiGatewayV2',
    AWS_RDS: 'aws|RDS',
    AWS_CLOUDFRONT: 'aws|CloudFront',
    AWS_IAMSERVERCERTIFICATE: 'aws|IamServerCertificate',
    AWS_VPNCONNECTION: 'aws|VPNConnection',
    AWS_CUSTOMERGATEWAY: 'aws|CustomerGateway',
    AWS_KINESIS: 'aws|Kinesis',
    AWS_CLOUDTRAIL: 'aws|CloudTrail',
    AWS_EFS: 'aws|EFS',
    AWS_WAFREGIONAL: 'aws|WAFRegional',
    AWS_WAFREGIONALV2: 'aws|WAFRegionalV2',
    AWS_REDSHIFT: 'aws|Redshift',
    AWS_ELB: 'aws|ELB',
    AWS_APPLICATIONLOADBALANCER: 'aws|ApplicationLoadBalancer',
    AWS_NETWORKLOADBALANCER: 'aws|NetworkLoadBalancer',
    AWS_GATEWAYLOADBALANCER: 'aws|GatewayLoadBalancer',
    AWS_ECSCLUSTER: 'aws|EcsCluster',
    AWS_ECSSERVICE: 'aws|EcsService',
    AWS_ECSTASKDEFINITION: 'aws|EcsTaskDefinition',
    AWS_ECSTASK: 'aws|EcsTask',
    AWS_EKSCLUSTER: 'aws|EksCluster',
    AWS_VPNGATEWAY: 'aws|VPNGateway',
    AWS_ROUTE53HOSTEDZONE: 'aws|Route53HostedZone',
    AWS_ROUTE53DOMAIN: 'aws|Route53Domain',
    AWS_SAGEMAKERNOTEBOOK: 'aws|SageMakerNotebook',
    AWS_ELASTICSEARCHDOMAIN: 'aws|ElasticSearchDomain',
    AWS_EMRCLUSTER: 'aws|EmrCluster',
    AWS_VPCENDPOINT: 'aws|VpcEndpoint',
    AWS_ELASTICACHE: 'aws|ElastiCache',
    AWS_CONFIGSETTING: 'aws|ConfigSetting',
    AWS_SQS: 'aws|Sqs',
    AWS_NATGATEWAY: 'aws|NatGateway',
    AWS_GLUESECURITYCONFIGURATION: 'aws|GlueSecurityConfiguration',
    AWS_GLUECONNECTION: 'aws|GlueConnection',
    AWS_STEPFUNCTIONSTATEMACHINE: 'aws|StepFunctionStateMachine',
    AWS_SNSTOPIC: 'aws|SnsTopic',
    AWS_SNSPLATFORMAPPLICATION: 'aws|SnsPlatformApplication',
    AWS_MSKCLUSTER: 'aws|MskCluster',
    AWS_REGION: 'aws|Region',
    AWS_ORGANIZATION: 'aws|Organization',
    AWS_ACCOUNT: 'aws|Account',
    AWS_NETWORKINTERFACE: 'aws|NetworkInterface',
    AWS_TRANSITGATEWAY: 'aws|TransitGateway',
    AWS_SECRETMANAGER: 'aws|SecretManager',
    AWS_ROUTETABLE: 'aws|RouteTable',
    AWS_SAGEMAKERTRAININGJOB: 'aws|SageMakerTrainingJob',
    AWS_COGNITOIDENTITYPOOL: 'aws|CognitoIdentityPool',
    AWS_COGNITOUSERPOOL: 'aws|CognitoUserPool',
    AWS_WORKSPACE: 'aws|Workspace',
    AWS_ECRREPOSITORY: 'aws|EcrRepository',
    AWS_ECRREGISTRYSCANNINGCONFIG: 'aws|EcrRegistryScanningConfig',
    AWS_DETECTIVEGRAPH: 'aws|DetectiveGraph',
    AWS_TRANSLATIONJOB: 'aws|TranslationJob',
    AWS_TRANSLATIONTERMINOLOGY: 'aws|TranslationTerminology',
    AWS_TRANSCRIBEJOB: 'aws|TranscribeJob',
    AWS_TRANSCRIBEMEDICALJOB: 'aws|TranscribeMedicalJob',
    AWS_PERSONALIZE: 'aws|Personalize',
    AWS_TRANSFER: 'aws|Transfer',
    AWS_SYSTEMMANAGERPARAMETER: 'aws|SystemManagerParameter',
    AWS_RDSDBSNAPSHOT: 'aws|RDSDBSnapshot',
    AWS_RDSDBCLUSTER: 'aws|RDSDBCluster',
    AWS_MQBROKER: 'aws|MqBroker',
    AWS_NETWORKFIREWALL: 'aws|NetworkFirewall',
    AWS_ATHENAWORKGROUP: 'aws|AthenaWorkGroup',
    AWS_DMSENDPOINT: 'aws|DmsEndpoint',
    AWS_CLOUDFORMATIONSTACK: 'aws|CloudFormationStack',
    AWS_KINESISFIREHOSE: 'aws|KinesisFirehose',
    AWS_STORAGEGATEWAY: 'aws|StorageGateway',
    AWS_SYSTEMMANAGERDOCUMENT: 'aws|SystemManagerDocument',
    AWS_CUSTOMDOMAINNAME: 'aws|CustomDomainName',
    AWS_ELASTICBEANSTALK: 'aws|ElasticBeanstalk',
    AWS_SHIELD: 'aws|Shield',
    AWS_BACKUPVAULT: 'aws|BackupVault',
    AWS_SES: 'aws|SES',
    AWS_IAMOPENIDCONNECTPROVIDER: 'aws|IamOpenIdConnectProvider',
    AWS_IAMSAMLPROVIDER: 'aws|IamSAMLProvider',
    AWS_LOGGROUP: 'aws|LogGroup',
    AWS_APPSYNC: 'aws|AppSync',
    AWS_FSXSNAPSHOT: 'aws|FSxSnapshot',
    AWS_GLUEMLTRANSFORM: 'aws|GlueMLTransform',
    AWS_GLUECRAWLER: 'aws|GlueCrawler',
    AWS_GLUEDEVENDPOINT: 'aws|GlueDevEndpoint',
    AWS_GLUEJOB: 'aws|GlueJob',
    AWS_SECURITYHUB: 'aws|SecurityHub',
    AWS_PROMETHEUSWORKSPACE: 'aws|PrometheusWorkspace',
    AWS_QUICKSIGHTACCOUNT: 'aws|QuickSightAccount',
    AWS_QUICKSIGHTGROUP: 'aws|QuickSightGroup',
    AWS_QUICKSIGHTUSER: 'aws|QuickSightUser',
    AWS_QUICKSIGHTVPCCONNECTIONS: 'aws|QuickSightVpcConnections',
    AWS_OUTPOST: 'aws|Outpost',
    AWS_TIMESTREAMQUERY: 'aws|TimestreamQuery',
    AWS_GUARDDUTYDETECTOR: 'aws|GuardDutyDetector',
    AWS_CLOUDFORMATIONHOOK: 'aws|CloudFormationHook',
    AWS_APPFLOW: 'aws|AppFlow',
    AWS_MANAGEDGRAFANAWORKSPACE: 'aws|ManagedGrafanaWorkspace',
    AWS_HEALTHLAKEDATASTORE: 'aws|HealthLakeDatastore',
    AZURE_LOGANALYTICSCLUSTER: 'azure|LogAnalyticsCluster',
    AZURE_DEDICATEDHOSTGROUP: 'azure|DedicatedHostGroup',
    AZURE_LOADBALANCER: 'azure|LoadBalancer',
    AZURE_SERVICEFABRICCLUSTER: 'azure|ServiceFabricCluster',
    AZURE_DATAMIGRATION: 'azure|DataMigration',
    AZURE_DATAMIGRATIONCLASSIC: 'azure|DataMigrationClassic',
    AZURE_NETAPPACCOUNT: 'azure|NetAppAccount',
    AZURE_CHAOSSTUDIOEXPERIMENT: 'azure|ChaosStudioExperiment',
    AZURE_EVENTGRIDNAMESPACE: 'azure|EventGridNamespace',
    AZURE_STREAMANALYTICSCLUSTER: 'azure|StreamAnalyticsCluster',
    AZURE_POWERBIEMBEDDEDCAPACITY: 'azure|PowerBIEmbeddedCapacity',
    AZURE_DATASHAREACCOUNT: 'azure|DataShareAccount',
    AZURE_COMPUTEGALLERY: 'azure|ComputeGallery',
    AZURE_ORBITALSPACECRAFT: 'azure|OrbitalSpacecraft',
    AZURE_VIRTUALMACHINE: 'azure|VirtualMachine',
    AZURE_VIRTUALNETWORKGATEWAY: 'azure|VirtualNetworkGateway',
    AZURE_FIREWALL: 'azure|Firewall',
    AZURE_STORAGEACCOUNT: 'azure|StorageAccount',
    AZURE_KEYVAULT: 'azure|KeyVault',
    AZURE_REDISCACHE: 'azure|RedisCache',
    AZURE_SQLSERVER: 'azure|SQLServer',
    AZURE_APPLICATIONGATEWAY: 'azure|ApplicationGateway',
    AZURE_ROUTETABLE: 'azure|RouteTable',
    AZURE_SYNAPSEWORKSPACE: 'azure|SynapseWorkspace',
    AZURE_NETWORKWATCHER: 'azure|NetworkWatcher',
    AZURE_NSGFLOWLOG: 'azure|NsgFlowLog',
    AZURE_LOGICAPP: 'azure|LogicApp',
    AZURE_POSTGRESQL: 'azure|PostgreSQL',
    AZURE_CONTAINERREGISTRY: 'azure|ContainerRegistry',
    AZURE_COSMOSDBACCOUNT: 'azure|CosmosDbAccount',
    AZURE_POLICYASSIGNMENT: 'azure|PolicyAssignment',
    AZURE_LOGPROFILE: 'azure|LogProfile',
    AZURE_VIRTUALMACHINESCALESET: 'azure|VirtualMachineScaleSet',
    AZURE_APPLICATIONSECURITYGROUP: 'azure|ApplicationSecurityGroup',
    AZURE_ANALYSISSERVICESERVER: 'azure|AnalysisServiceServer',
    AZURE_AKSCLUSTER: 'azure|AksCluster',
    AZURE_FUNCTIONAPP: 'azure|FunctionApp',
    AZURE_WEBAPP: 'azure|WebApp',
    AZURE_USER: 'azure|User',
    AZURE_VMSSINSTANCE: 'azure|VMSSInstance',
    AZURE_SQLMANAGEDINSTANCE: 'azure|SqlManagedInstance',
    AZURE_ACTIVITYLOGALERTRULE: 'azure|ActivityLogAlertRule',
    AZURE_APIMANAGEMENTSERVICE: 'azure|ApiManagementService',
    AZURE_HDINSIGHT: 'azure|HDInsight',
    AZURE_DATAEXPLORERCLUSTER: 'azure|DataExplorerCluster',
    AZURE_DISK: 'azure|Disk',
    AZURE_REGIONALWAF: 'azure|RegionalWAF',
    AZURE_ROLEASSIGNMENT: 'azure|RoleAssignment',
    AZURE_ROLEDEFINITION: 'azure|RoleDefinition',
    AZURE_VNET: 'azure|VNet',
    AZURE_SERVICEBUS: 'azure|ServiceBus',
    AZURE_EVENTHUBNAMESPACE: 'azure|EventHubNamespace',
    AZURE_MARIADB: 'azure|MariaDb',
    AZURE_BASTION: 'azure|Bastion',
    AZURE_SECURITYCONTACT: 'azure|SecurityContact',
    AZURE_AUTOPROVISIONINGSETTINGS: 'azure|AutoProvisioningSettings',
    AZURE_CONTAINERINSTANCE: 'azure|ContainerInstance',
    AZURE_DATABRICKSWORKSPACE: 'azure|DatabricksWorkspace',
    AZURE_MYSQLDBFLEXIBLESERVER: 'azure|MySQLDBFlexibleServer',
    AZURE_MYSQLDBSINGLESERVER: 'azure|MySQLDBSingleServer',
    AZURE_SPRINGCLOUD: 'azure|SpringCloud',
    AZURE_APPSERVICEENVIRONMENT: 'azure|AppServiceEnvironment',
    AZURE_COGNITIVESERVICE: 'azure|CognitiveService',
    AZURE_POSTGRESQLFLEXIBLESERVER: 'azure|PostgreSQLFlexibleServer',
    AZURE_GROUP: 'azure|Group',
    AZURE_ADAUTHORIZATIONPOLICY: 'azure|ADAuthorizationPolicy',
    AZURE_APPREGISTRATION: 'azure|AppRegistration',
    AZURE_ADSECURITYDEFAULTS: 'azure|ADSecurityDefaults',
    AZURE_USERASSIGNEDIDENTITY: 'azure|UserAssignedIdentity',
    AZURE_BLUEPRINTASSIGNMENT: 'azure|BlueprintAssignment',
    AZURE_SUBSCRIPTIONPOLICY: 'azure|SubscriptionPolicy',
    AZURE_BATCHACCOUNT: 'azure|BatchAccount',
    AZURE_POLICYSETDEFINITION: 'azure|PolicySetDefinition',
    GCP_SERVICEACCOUNT: 'google|ServiceAccount',
    GCP_BIGQUERY: 'google|BigQuery',
    GCP_BIGQUERYTABLE: 'google|BigQueryTable',
    GCP_STORAGEBUCKET: 'google|StorageBucket',
    GCP_GKECLUSTER: 'google|GkeCluster',
    GCP_KMSKEYRING: 'google|KmsKeyRing',
    GCP_VMINSTANCE: 'google|VMInstance',
    GCP_CLOUDSQL: 'google|CloudSql',
    GCP_CLOUDFUNCTION: 'google|CloudFunction',
    GCP_BIGTABLE: 'google|BigTable',
    GCP_CLOUDRUNSERVICE: 'google|CloudRunService',
    GCP_CLOUDRUNJOB: 'google|CloudRunJob',
    GCP_FIREBASEREALTIMEDATABASE: 'google|FirebaseRealtimeDatabase',
    GCP_FOLDER: 'google|Folder',
    GCP_GCPORGANIZATION: 'google|GcpOrganization',
    GCP_AVAILABLEORGPOLICYCONSTRAINT: 'google|AvailableOrgPolicyConstraint',
    GCP_SECRET: 'google|Secret',
    GCP_MACHINEIMAGE: 'google|MachineImage',
    GCP_CLOUDVPNGATEWAY: 'google|CloudVpnGateway',
    GCP_CLOUDVPNTUNNEL: 'google|CloudVpnTunnel',
    GCP_EXTERNALVPNGATEWAY: 'google|ExternalVpnGateway',
    GCP_TARGETVPNGATEWAY: 'google|TargetVpnGateway',
    GCP_NETWORK: 'google|Network',
    GCP_APIGEEAPI: 'google|ApigeeApi',
    GCP_SECURITYCENTERFINDING: 'google|SecurityCenterFinding',
    GCP_PUBSUBTOPIC: 'google|PubSubTopic',
    GCP_GCPIAMUSER: 'google|GcpIamUser',
    GCP_GCPIAMGROUP: 'google|GcpIamGroup',
    GCP_GCPIAMPOLICY: 'google|GcpIamPolicy',
    GCP_GCPIAMROLE: 'google|GcpIamRole',
    GCP_IMAGE: 'google|Image',
    GCP_REDIS: 'google|Redis',
    GCP_APPENGINE: 'google|AppEngine',
    GCP_FILESTOREINSTANCE: 'google|FilestoreInstance',
    GCP_ROUTER: 'google|Router',
    GCP_DISK: 'google|Disk',
    GCP_ROUTE: 'google|Route',
    GCP_URLMAP: 'google|UrlMap',
    GCP_TCPUDPLOADBALANCERTARGETPOOL: 'google|TcpUdpLoadBalancerTargetPool',
    GCP_INSTANCETEMPLATE: 'google|InstanceTemplate',
    GCP_APIKEY: 'google|APIKey',
    GCP_ALERTPOLICY: 'google|AlertPolicy',
    GCP_LOGBASEDMETRIC: 'google|LogBasedMetric',
    GCP_LOADBALANCERSSLPOLICY: 'google|LoadBalancerSslPolicy',
    GCP_BACKENDSERVICE: 'google|BackendService',
    GCP_LOGSINK: 'google|LogSink',
    GCP_LOGBUCKET: 'google|LogBucket',
    GCP_FORWARDINGRULE: 'google|ForwardingRule',
    GCP_DNSMANAGEDZONE: 'google|DnsManagedZone',
    GCP_TCPLOADBALANCERTARGETPROXY: 'google|TcpLoadBalancerTargetProxy',
    GCP_SSLLOADBALANCERTARGETPROXY: 'google|SslLoadBalancerTargetProxy',
    GCP_HTTPSLOADBALANCERTARGETPROXY: 'google|HttpsLoadBalancerTargetProxy',
    GCP_DATAPROCCLUSTER: 'google|DataprocCluster',
    GCP_ESSENTIALCONTACT: 'google|EssentialContact',
    ALIBABA_ACTIONTRAIL: 'alibaba|ActionTrail',
    ALIBABA_ECSINSTANCE: 'alibaba|EcsInstance',
    ALIBABA_ECSDISK: 'alibaba|EcsDisk',
    ALIBABA_VPC: 'alibaba|VPC',
    ALIBABA_VSWITCH: 'alibaba|VSwitch',
    ALIBABA_OSSBUCKET: 'alibaba|OssBucket',
    ALIBABA_RAMUSER: 'alibaba|RamUser',
    ALIBABA_RAMGROUP: 'alibaba|RamGroup',
    ALIBABA_RAMPASSWORDPOLICY: 'alibaba|RamPasswordPolicy',
    ALIBABA_RAMROLE: 'alibaba|RamRole',
    ALIBABA_RAMPOLICY: 'alibaba|RamPolicy',
    ALIBABA_RDSDBINSTANCE: 'alibaba|RdsDbInstance',
    ALIBABA_SECURITYGROUP: 'alibaba|SecurityGroup',
    ALIBABA_NAS: 'alibaba|NAS',
    ALIBABA_KMS: 'alibaba|KMS',
    ALIBABA_SLB: 'alibaba|SLB',
    ALIBABA_AUTOSCALINGGROUP: 'alibaba|AutoScalingGroup',
    ALIBABA_SECCENANTIVIRUSCONFIG: 'alibaba|SecCenAntiVirusConfig',
    ALIBABA_SECCENASSETSECURITYINFO: 'alibaba|SecCenAssetSecurityInfo',
    ALIBABA_SECCENEDITIONVERCONFIG: 'alibaba|SecCenEditionVerConfig',
    ALIBABA_SECCENLINKEDROLESTATUS: 'alibaba|SecCenLinkedRoleStatus',
    ALIBABA_SECCENLINUXVULNERABILITY: 'alibaba|SecCenLinuxVulnerability',
    ALIBABA_SECCENLOGANALYSISCONFIG: 'alibaba|SecCenLogAnalysisConfig',
    ALIBABA_SECCENNOTIFICATIONCONFIG: 'alibaba|SecCenNotificationConfig',
    ALIBABA_SECCENVULNSCANCONFIG: 'alibaba|SecCenVulnScanConfig',
    ALIBABA_SECCENWEBSHELLCONFIG: 'alibaba|SecCenWebshellConfig',
    DOME9_AGENT: 'dome9|Agent',
    KUBERNETES_KUBERNETESNODE: 'kubernetes|KubernetesNode',
    KUBERNETES_KUBERNETESPOD: 'kubernetes|KubernetesPod',
    KUBERNETES_KUBERNETESSERVICE: 'kubernetes|KubernetesService',
    KUBERNETES_KUBERNETESINGRESS: 'kubernetes|KubernetesIngress',
    KUBERNETES_KUBERNETESNETWORKPOLICY: 'kubernetes|KubernetesNetworkPolicy',
    KUBERNETES_KUBERNETESROLE: 'kubernetes|KubernetesRole',
    KUBERNETES_KUBERNETESROLEBINDING: 'kubernetes|KubernetesRoleBinding',
    KUBERNETES_KUBERNETESSERVICEACCOUNT: 'kubernetes|KubernetesServiceAccount',
    KUBERNETES_KUBERNETESPODSECURITYPOLICY: 'kubernetes|KubernetesPodSecurityPolicy',
    KUBERNETES_KUBERNETESNAMESPACE: 'kubernetes|KubernetesNamespace',
    KUBERNETES_KUBERNETESCLUSTER: 'kubernetes|KubernetesCluster',
    KUBERNETES_KUBERNETESIMAGE: 'kubernetes|KubernetesImage',
    CONTAINERREGISTRY_CONTAINERREGISTRYIMAGE: 'containerregistry|ContainerRegistryImage',
    KUBERNETES_KUBERNETESJOB: 'kubernetes|KubernetesJob',
    KUBERNETES_KUBERNETESCRONJOB: 'kubernetes|KubernetesCronJob',
    KUBERNETES_KUBERNETESSTATEFULSET: 'kubernetes|KubernetesStatefulSet',
    KUBERNETES_KUBERNETESDAEMONSET: 'kubernetes|KubernetesDaemonSet',
    KUBERNETES_KUBERNETESDEPLOYMENT: 'kubernetes|KubernetesDeployment',
    KUBERNETES_KUBERNETESREPLICASET: 'kubernetes|KubernetesReplicaSet',
    KUBERNETES_OPENSHIFTSECURITYCONTEXTCONSTRAINT: 'kubernetes|OpenshiftSecurityContextConstraint',
    SHIFTLEFT_SHIFTLEFTIMAGE: 'shiftleft|ShiftLeftImage',
    AWS_ECSIMAGE: 'aws|EcsImage',
    AZURE_DEFENDERINTEGRATIONS: 'azure|DefenderIntegrations',
    AZURE_DEFENDERPLANS: 'azure|DefenderPlans',
    AWS_RDSDBCLUSTERSNAPSHOT: 'aws|RdsDbClusterSnapshot',
    AZURE_FRONTDOORCLASSIC: 'azure|FrontDoorClassic',
    AZURE_GLOBALWAF: 'azure|GlobalWAF',
    AZURE_FRONTDOOR: 'azure|FrontDoor',
    AZURE_ADACCESSREVIEWSSCHEDULEDEFINITION: 'azure|ADAccessReviewsScheduleDefinition',
    AZURE_DEFENDERSERVERVULNASSMT: 'azure|DefenderServerVulnAssmt',
    AZURE_ADCONDACCESSNAMEDLOCATION: 'azure|ADCondAccessNamedLocation',
    AZURE_APPLICATIONINSIGHTS: 'azure|ApplicationInsights',
    AZURE_ADCONDACCESSPOLICY: 'azure|ADCondAccessPolicy',
    AZURE_RECOVERYSERVICESVAULT: 'azure|RecoveryServicesVault',
    AZURE_AUTOMATIONACCOUNTS: 'azure|AutomationAccounts',
    OCI_MYSQLCHANNEL: 'oci|MySqlChannel',
    OCI_MYSQLDBSYSTEM: 'oci|MySqlDbSystem',
    OCI_MYSQLCONFIGURATION: 'oci|MySqlConfiguration',
    OCI_MYSQLBACKUP: 'oci|MySqlBackup',
    OCI_STORAGEBUCKET: 'oci|StorageBucket',
    OCI_VCN: 'oci|Vcn',
    OCI_COMPUTEINSTANCE: 'oci|ComputeInstance',
    OCI_REGIONSUBSCRIPTION: 'oci|RegionSubscription',
    OCI_KUBERNETESCLUSTER: 'oci|KubernetesCluster',
    OCI_USER: 'oci|User',
    OCI_NETWORKSECURITYGROUP: 'oci|NetworkSecurityGroup',
    OCI_SECURITYLIST: 'oci|SecurityList',
    OCI_INTEGRATIONINSTANCE: 'oci|IntegrationInstance',
    OCI_IMAGE: 'oci|Image',
    OCI_BLOCKVOLUME: 'oci|BlockVolume',
    OCI_BOOTVOLUME: 'oci|BootVolume',
    OCI_AUTONOMOUSDATABASE: 'oci|AutonomousDatabase',
    OCI_FILESYSTEMS: 'oci|FileSystems',
    OCI_AUTHENTICATIONPOLICY: 'oci|AuthenticationPolicy',
    OCI_GROUP: 'oci|Group',
    OCI_LOGGROUP: 'oci|LogGroup',
    OCI_ANALYTICSINSTANCE: 'oci|AnalyticsInstance',
    OCI_TENANCY: 'oci|Tenancy',
    OCI_POLICY: 'oci|Policy',
    OCI_VNIC: 'oci|Vnic',
    OCI_NETWORKLOADBALANCER: 'oci|NetworkLoadBalancer',
    OCI_LOADBALANCER: 'oci|LoadBalancer',
    OCI_TAGDEFAULT: 'oci|TagDefault',
    OCI_DYNAMICGROUP: 'oci|DynamicGroup',
    OCI_ONSTOPIC: 'oci|OnsTopic',
    OCI_EVENTRULE: 'oci|EventRule',
    OCI_CLOUDGUARDCONFIGURATION: 'oci|CloudGuardConfiguration',
    OCI_VAULT: 'oci|Vault',
    OCI_DETECTORRECIPE: 'oci|DetectorRecipe',
    OCI_RESPONDERRECIPE: 'oci|ResponderRecipe',
    AWS_EVENTBRIDGEENDPOINT: 'aws|EventBridgeEndpoint',
    AWS_EVENTBRIDGECONNECTION: 'aws|EventBridgeConnection',
    AWS_SIMSPACEWEAVERSIMULATION: 'aws|SimSpaceWeaverSimulation',
    AWS_EVENTBRIDGEARCHIVE: 'aws|EventBridgeArchive',
    AWS_EVENTBRIDGEEVENTBUS: 'aws|EventBridgeEventBus',
    GCP_NETWORKENDPOINTGROUP: 'google|NetworkEndpointGroup',
    GCP_NETWORKATTACHMENT: 'google|NetworkAttachment',
    GCP_SERVICEATTACHMENT: 'google|ServiceAttachment',
    GCP_CONNECTIONPOLICY: 'google|ConnectionPolicy',
    AWS_DATASYNCTASK: 'aws|DataSyncTask',
    AWS_DATASYNCAGENT: 'aws|DataSyncAgent',
    AWS_DATASYNCSTORAGE: 'aws|DataSyncStorage',
    AWS_DRSJOB: 'aws|DrsJob',
    AWS_DRSLAUNCHCONFIGURATIONTEMPLATE: 'aws|DrsLaunchConfigurationTemplate',
    AWS_DRSRECOVERYINSTANCES: 'aws|DrsRecoveryInstances',
    AWS_DRSREPLICATIONCONFIGTEMPLATE: 'aws|DrsReplicationConfigTemplate',
    AWS_DRSSOURCENETWORK: 'aws|DrsSourceNetwork',
    AWS_DRSSOURCESERVER: 'aws|DrsSourceServer',
    AWS_TIMESTREAMTABLE: 'aws|TimestreamTable',
    AWS_TIMESTREAMDATABASE: 'aws|TimestreamDatabase',
    AWS_TIMESTREAMBATCHLOADTASK: 'aws|TimestreamBatchLoadTask',
    AWS_DIRECTORYSERVICEDIRECTORY: 'aws|DirectoryServiceDirectory',
    AWS_LAMBDALAYER: 'aws|LambdaLayer',
    AWS_ELASTICACHEPARAMETERGROUP: 'aws|ElastiCacheParameterGroup',
    AWS_ELASTICACHEUSER: 'aws|ElastiCacheUser',
    AWS_ELASTICACHEUSERGROUP: 'aws|ElastiCacheUserGroup',
    AWS_ELASTICACHESNAPSHOT: 'aws|ElastiCacheSnapshot',
    AWS_SIGNERPROFILE: 'aws|SignerProfile',
    AWS_SIGNERJOB: 'aws|SignerJob',
    AZURE_DATAFACTORY: 'azure|DataFactory',
    AWS_DAXCLUSTER: 'aws|DaxCluster',
    AWS_DAXPARAMETERGROUP: 'aws|DaxParameterGroup',
    AWS_NIMBLESTUDIO: 'aws|NimbleStudio',
    AWS_CLOUDSEARCHDOMAIN: 'aws|CloudSearchDomain',
    AWS_CONNECTINSTANCE: 'aws|ConnectInstance',
    AWS_SUPPORTCASE: 'aws|SupportCase',
    AWS_SUPPORTSEVERITYLEVEL: 'aws|SupportSeverityLevel',
    AWS_FINSPACEENVIRONMENT: 'aws|FinSpaceEnvironment',
    AWS_LAKEFORMATIONDATALAKESETTINGS: 'aws|LakeFormationDataLakeSettings',
};
