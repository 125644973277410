import { IConfigurationContainerProps } from 'common/module_interface/settings/integrations/configurations.interface';
import { IConfiguration } from 'common/module_interface/settings/integrations/Integrations';

export const CYERA_INTEGRATION_ID = 'Cyera';

export interface IGenericConfiguration extends IConfiguration {
    configurationObj: ICyeraConfiguration;
}

export interface IGenericConfigurationProps extends IConfigurationContainerProps {
    configuration: IGenericConfiguration;
}

interface ICyeraCredentialsConfiguration {
    clientId: string;
    secret: string;
}

export interface ICyeraConfiguration {
    id: string;
    name: string;
    cyeraOrganizationName: string;
    accountCredentials: ICyeraCredentialsConfiguration;
    created: string;
    lastSuccessfulSync: string | null;
}
