import * as React from 'react';
import { SVGProps, Ref, forwardRef } from 'react';
const SvgUnarchive = (
  props: SVGProps<SVGSVGElement>,
  ref: Ref<SVGSVGElement>
) => (
  <svg
    viewBox="0 0 16 16"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    ref={ref}
    {...props}
  >
    <path
      d="M14.59 15.16c.641-2.1.417-3.265-.427-4.365-.844-1.102-2.734-1.107-4.524-.988m0 0 1.771 3.06m-1.77-3.06a.497.497 0 0 1 .18-.679l3.06-1.772"
      stroke="currentColor"
      strokeLinecap="round"
    />
    <path
      d="M13.5 5.5h-11m0 5H8m-.5-8h1m-1 5h1m-1 5h1M13.5 5.5v-3a2 2 0 0 0-2-2h-7a2 2 0 0 0-2 2v11a2 2 0 0 0 2 2h7"
      stroke="currentColor"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </svg>
);
const ForwardRef = forwardRef(SvgUnarchive);
export default ForwardRef;
