import styled from 'styled-components';
import { Icon } from '@dome9/berries/react-components';

const CardDiv = styled.div`
  border-color: rgba(11, 42, 103, 0.18);
  border-radius: 8px;
`;

const HeaderDiv = styled.div`
  justify-content: space-between;
  overflow: hidden;
`;


const LeftHeaderBoxDiv = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  width: 100%;
`;

const TitleDiv = styled.div`
  display: flex;
  flex-direction: row;
  font-weight: 500;
  gap: 5px;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
`;

const SpinnerDiv = styled.div`
  display: flex;
  justify-content: center;
  margin: auto;
`;

const FailureDiv = styled.div`
  display: flex;
  justify-content: center;
  margin: auto;
  color: #7C7C7C;
`;

const TitleActionDiv = styled.div`
  cursor: pointer;
`;

const PopoverIconContainer = styled.div`
  display: flex;
  margin-left: 6px;

  &:hover {
    cursor: pointer;
  }
`;

const PopoverIcon = styled(Icon)`
    opacity: 0.5;
`;

export const WidgetCardStyled = {
    CardDiv,
    HeaderDiv,
    LeftHeaderBoxDiv,
    TitleDiv,
    SpinnerDiv,
    FailureDiv,
    TitleActionDiv,
    PopoverIconContainer,
    PopoverIcon,
};