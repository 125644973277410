import { buildPath, globalAddinContainer } from 'common/extensibility/AddinContainer';
import { CGColDef } from 'common/components/ProtectedAssets/ProtectedAssetsTable.interface';
import { Addin } from 'common/extensibility/AddinRegistry';
import { IFilterProps } from 'common/components/FilterPanel/FilterPanelManager';
import { IProtectedAssetFilterParams } from '../../assets/ProtectedAssets';

const PATH_ISSUES_TABLE_PREFIX = buildPath('issues', 'issues table');
const PATH_TOP_ISSUES_TABLE_PREFIX = buildPath('issues', 'top issues table');
const PATH_ISSUES_TABLE_COLUMN_DEFS = buildPath(PATH_ISSUES_TABLE_PREFIX, 'columns');
const PATH_TOP_ISSUES_TABLE_COLUMN_DEFS = buildPath(PATH_TOP_ISSUES_TABLE_PREFIX, 'columns');
const PATH_ISSUES_TABLE_FILTER_DEFS = buildPath(PATH_ISSUES_TABLE_PREFIX, 'filters');
const PATH_CVES_TABLE_PREFIX = buildPath('issues', 'cves table');
const PATH_CVES_TABLE_COLUMN_DEFS = buildPath(PATH_CVES_TABLE_PREFIX, 'columns');
const PATH_IAM_SENSITIVITY_TABLE_PREFIX = buildPath('issues', 'iam sensitivity table');
const PATH_IAM_SENSITIVITY_TABLE_COLUMN_DEFS = buildPath(PATH_IAM_SENSITIVITY_TABLE_PREFIX, 'columns');
const PATH_MALWARE_TABLE_PREFIX = buildPath('issues', 'malware table');
const PATH_MALWARE_TABLE_COLUMN_DEFS = buildPath(PATH_MALWARE_TABLE_PREFIX, 'columns');

export const IssuesRegistry = {
    addIssuesColumnDefs: (columnDefs: CGColDef[], idProperty: string) => {
        globalAddinContainer.addMap<CGColDef>(PATH_ISSUES_TABLE_COLUMN_DEFS, columnDefs, idProperty);
    },

    getIssuesColumnDefs: (): CGColDef[] => {
        return globalAddinContainer.get<CGColDef>(PATH_ISSUES_TABLE_COLUMN_DEFS);
    },

    addTopIssuesColumnDefs: (columnDefs: CGColDef[], idProperty: string) => {
        globalAddinContainer.addMap<CGColDef>(PATH_TOP_ISSUES_TABLE_COLUMN_DEFS, columnDefs, idProperty);
    },

    getTopIssuesColumnDefs: (): CGColDef[] => {
        return globalAddinContainer.get<CGColDef>(PATH_TOP_ISSUES_TABLE_COLUMN_DEFS);
    },

    addCvesColumnDefs: (columnDefs: CGColDef[], idProperty: string) => {
        globalAddinContainer.addMap<CGColDef>(PATH_CVES_TABLE_COLUMN_DEFS, columnDefs, idProperty);
    },

    getCvesColumnDefs: (): CGColDef[] => {
        return globalAddinContainer.get<CGColDef>(PATH_CVES_TABLE_COLUMN_DEFS);
    },

    addIamSensitivityColumnDefs: (columnDefs: CGColDef[], idProperty: string) => {
        globalAddinContainer.addMap<CGColDef>(PATH_IAM_SENSITIVITY_TABLE_COLUMN_DEFS, columnDefs, idProperty);
    },

    getIamSensitivityColumnDefs: (): CGColDef[] => {
        return globalAddinContainer.get<CGColDef>(PATH_IAM_SENSITIVITY_TABLE_COLUMN_DEFS);
    },

    addMalwareColumnDefs: (columnDefs: CGColDef[], idProperty: string) => {
        globalAddinContainer.addMap<CGColDef>(PATH_MALWARE_TABLE_COLUMN_DEFS, columnDefs, idProperty);
    },

    getMalwareColumnDefs: (): CGColDef[] => {
        return globalAddinContainer.get<CGColDef>(PATH_MALWARE_TABLE_COLUMN_DEFS);
    },

    addFilterAddins: (filterAddins: Addin<IFilterProps>[]) => {
        globalAddinContainer.add<IFilterProps>(PATH_ISSUES_TABLE_FILTER_DEFS, filterAddins);
    },

    getFilter: (filterId: string, filterParams: IProtectedAssetFilterParams[]): IFilterProps | undefined => {
        return globalAddinContainer.getById<IFilterProps>(PATH_ISSUES_TABLE_FILTER_DEFS, filterId, filterParams);
    },

};
