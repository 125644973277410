
export function calcGridPosition(gridLabels: string[], nodeContainerClassName: string): DOMRect[]{
    const cols: DOMRect[] = [];
    let leftmostLabelNodeNotExists = false;

    // gridLabels order is very important! the left most label should be the first in the array
    gridLabels.forEach((value) => {
        // find all elements of the same group / class (at the same column)
        const nodeElements = Array.from(document.getElementsByClassName(`${nodeContainerClassName}-${value.toLowerCase()}`));
        if (!nodeElements.length) {
            leftmostLabelNodeNotExists = true;
            return;
        }

        // find the highest element in each group, this will prevent the labels from overlapping the highest nodes in the graph
        const highestElement = nodeElements.reduce((highest, current) => {
            const highestPosition = highest.getBoundingClientRect().y;
            const currentPosition = current.getBoundingClientRect().y;

            return currentPosition > highestPosition ? current : highest;
        });

        // find the left mose element in each group so the vertical grid lines will be drawn at the left most position
        const leftMostElement = nodeElements.reduce((leftest, current) => {
            const leftestPosition = leftest.getBoundingClientRect().x;
            const currentPosition = current.getBoundingClientRect().x;

            return currentPosition < leftestPosition ? current : leftest;
        });

        // return an element which has the highest Y position and left most X position to insure correct line positions
        if (highestElement && leftMostElement) cols.push(new DOMRect(leftMostElement.getBoundingClientRect().x,
            highestElement.getBoundingClientRect().y,
            leftMostElement.getBoundingClientRect().width,
            leftMostElement.getBoundingClientRect().height));

    });

    if(!cols.length) return cols;

    // this is a dummy node for the first column (in cases like external & internal when we might have only internal nodes)
    // in that case the external label will still appear on the leftmost side of the graph
    // if you not wish the leftmost label to appear, when there are no nodes in that column - remove it from the gridLabels array
    const dummyElement: DOMRect = new DOMRect(
        cols[0].x - cols[0].width * 2 ,
        cols[0].y,
        cols[0].width,
        cols[0].height
    );

    if (leftmostLabelNodeNotExists) cols.unshift(dummyElement);
    return cols;
}

export function getGridLines(gridOutlines: DOMRect[], containerClassName: string, gridLabels: string[]) {
    const containerElementSize = document.getElementsByClassName(containerClassName)?.[0]?.getBoundingClientRect();

    if (!containerElementSize || !containerElementSize.height || gridOutlines.length !== gridLabels.length) return;

    const containerLeftMargin = containerElementSize.left;

    const gridLinePositions = [];

    for (let i = 1; i < gridLabels.length; i++) {
        const x = (gridOutlines[i].x - gridOutlines[i - 1].x) / 2 + gridOutlines[i - 1].x - (containerLeftMargin - (gridOutlines[0].width / 2));
        gridLinePositions.push(x);
    }

    const y1 = 2;
    const y2 = containerElementSize.height - 10;

    return { gridLinePositions, y1, y2, containerLeftMargin };
}

export function getRightMostNodePosition(): number | undefined{
    const allGraphNodes = Array.from(document.getElementsByClassName('react-flow__node'));
    if(!allGraphNodes) return undefined;

    const rightMostNode = allGraphNodes?.reduce((rightMost, current) => {
        const rightMostPosition = rightMost.getBoundingClientRect()?.x;
        const currentPosition = current.getBoundingClientRect()?.x;

        return currentPosition > rightMostPosition ? current : rightMost;
    });

    return rightMostNode.getBoundingClientRect()?.x;
}
