import * as React from 'react';
import { SVGProps, Ref, forwardRef } from 'react';
const SvgUsers = (props: SVGProps<SVGSVGElement>, ref: Ref<SVGSVGElement>) => (
  <svg
    viewBox="0 0 16 16"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    ref={ref}
    {...props}
  >
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M6.049 1.17a2.537 2.537 0 1 0 0 5.074 2.537 2.537 0 0 0 0-5.073ZM2.34 3.708a3.707 3.707 0 1 1 7.415 0 3.707 3.707 0 0 1-7.415 0Zm7.805-2.341c0-.323.262-.586.586-.586a2.927 2.927 0 1 1 0 5.854.585.585 0 1 1 0-1.17 1.756 1.756 0 1 0 0-3.513.585.585 0 0 1-.586-.585Zm-8.25 8.21c1.091-.623 2.561-.99 4.153-.99 1.591 0 3.061.367 4.153.99 1.076.615 1.896 1.554 1.896 2.717s-.82 2.1-1.896 2.716C9.11 15.633 7.64 16 6.05 16a9.526 9.526 0 0 1-2.588-.347.585.585 0 1 1 .32-1.126 8.36 8.36 0 0 0 2.268.302c1.425 0 2.687-.331 3.573-.837.901-.515 1.305-1.138 1.305-1.7 0-.56-.404-1.183-1.305-1.699-.886-.505-2.148-.837-3.573-.837-1.426 0-2.688.332-3.573.837-.902.516-1.305 1.138-1.305 1.7 0 .18.04.36.12.54a.585.585 0 0 1-1.067.48A2.474 2.474 0 0 1 0 12.294c0-1.163.82-2.101 1.895-2.716Zm10.605.25a.585.585 0 0 1 .698-.447c.75.165 1.427.462 1.929.873s.872.975.872 1.65c0 .608-.302 1.129-.728 1.525-.424.394-.998.693-1.64.886a.585.585 0 1 1-.337-1.121c.518-.156.92-.38 1.18-.623.26-.241.354-.47.354-.667 0-.215-.116-.476-.442-.743-.327-.267-.82-.5-1.44-.636a.585.585 0 0 1-.446-.697Z"
      fill="currentColor"
    />
  </svg>
);
const ForwardRef = forwardRef(SvgUsers);
export default ForwardRef;
