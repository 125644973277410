import { ITableAction } from 'common/design-system/components-v2/Table/Table.types';
import i18n from 'i18next';
import { AllIconsName } from '../../../design-system/components-v2/Icon/Icon.types';
import { getNotificationsService } from '../../../interface/services';
import { NotificationType } from '../../../interface/notifications';
import { globalModelUtils } from '../../../components/GlobalModals/GlobalModals';
import React from 'react';
import { List } from '../../../design-system/components-v2';
import { IPageTableActionsProps } from './ClientFilterPage.interface';
import { globalEventBus } from '../../utils/EventBus/eventBus';

const getListDisplay: React.FC<{ names: string[], iconName?: AllIconsName }> = ({ names, iconName }) => {
    return (
        <List options={names.map(name => ({
            label: name,
            value: name,
            key: name,
            labelProps: iconName ? { leadingIconProps: { name: iconName } } : undefined,
        }))} />
    );
};

export const getPageTableCrudActions = <T=any>(pageTableId: string, onTableDataChange: () => void,
    actionsProps: IPageTableActionsProps): ITableAction<T>[] => {

    const onDataChange = () => {
        globalEventBus.sendEvent(pageTableId);
        onTableDataChange();
    };

    const actions: ITableAction<T>[] = [];
    if (actionsProps.addProps) {
        actions.push({
            id: `${pageTableId}-add-action`,
            name: actionsProps.addProps.label || i18n.t('CLIENT_FILTER_PAGE_TABLE.ACTIONS.ADD.LABEL'),
            callback: () => actionsProps.addProps?.callback(),
            isActionDisabled: () => false,
            buttonProps: { iconProps: { name: actionsProps.addProps.iconName || 'plus' } }
        });
    }

    if (actionsProps.editProps) {
        actions.push({
            id: `${pageTableId}-edit-action`,
            name: actionsProps.editProps.label || i18n.t('CLIENT_FILTER_PAGE_TABLE.ACTIONS.EDIT.LABEL'),
            callback: (selectedRows: T[]) => {
                if (selectedRows.length === 1) {
                    actionsProps.editProps?.callback(selectedRows[0]);
                }
            },
            isActionDisabled: (selectedRows: T[]) => {
                return (selectedRows.length !== 1);
            },
            buttonProps: { iconProps: { name: actionsProps.editProps.iconName || 'edit' } }
        });
    }

    if (actionsProps.deleteProps) {
        actions.push({
            id: `${pageTableId}-delete-action`,
            name: actionsProps.deleteProps.label || i18n.t('CLIENT_FILTER_PAGE_TABLE.ACTIONS.DELETE.LABEL'),
            callback: (selectedRows: T[]) => {
                if (selectedRows.length === 0) {
                    return;
                }

                const getNameFromItem: ((item: T) => string) | undefined = actionsProps.deleteProps!.getNameFromItem;
                const names: string[] | undefined = getNameFromItem ? selectedRows.map((row: T) => getNameFromItem(row)) : undefined;
                globalModelUtils.showConfirmationModal({
                    title: i18n.t('CLIENT_FILTER_PAGE_TABLE.NOTIFICATIONS.DELETE_CONFIRMATION_TITLE'),
                    text: i18n.t('CLIENT_FILTER_PAGE_TABLE.NOTIFICATIONS.DELETE_CONFIRMATION_TEXT', { count: selectedRows.length }),
                    submitBtnText: i18n.t('CLIENT_FILTER_PAGE_TABLE.ACTIONS.DELETE.LABEL'),
                    children: names && (selectedRows.length > 1) ? getListDisplay({
                        names,
                        iconName: actionsProps.deleteProps?.itemIconName
                    }) : undefined,
                    onConfirm: () => {
                        actionsProps.deleteProps?.deleteItems(selectedRows).then(() => {
                            onDataChange();
                            getNotificationsService().addNotification({
                                type: NotificationType.SUCCESS,
                                text: actionsProps.deleteProps?.deleteSuccessMessage || i18n.t('CLIENT_FILTER_PAGE_TABLE.NOTIFICATIONS.DELETE_COMPLETED_SUCCESSFULLY'),
                            });
                        }).catch(() => {
                            onDataChange();
                            getNotificationsService().addNotification({
                                type: NotificationType.ERROR,
                                text: actionsProps.deleteProps?.deleteFailureMessage || i18n.t('CLIENT_FILTER_PAGE_TABLE.NOTIFICATIONS.DELETE_FAILED'),
                            });
                        });
                    },
                });
            },
            isActionDisabled: (selectedRows: T[]) => {
                return (selectedRows.length === 0);
            },
            buttonProps: { iconProps: { name: actionsProps.deleteProps.iconName || 'delete' } }
        });
    }
    return actions;
};