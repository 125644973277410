import * as React from 'react';
import { SVGProps, Ref, forwardRef } from 'react';
const SvgContainersRegistry = (
  props: SVGProps<SVGSVGElement>,
  ref: Ref<SVGSVGElement>
) => (
  <svg
    viewBox="0 0 16 16"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    ref={ref}
    {...props}
  >
    <path
      d="m.5 9 3 1.5v3L.5 12V9ZM3.5 4.5l3 1.5v3l-3-1.5v-3Z"
      stroke="currentColor"
      strokeLinejoin="round"
    />
    <path
      d="m3.5 7.5 3 1.5-3 1.5L.5 9l3-1.5ZM12.5 6l3 1.5-6 3-3-1.5 6-3ZM9.5 1.5l3 1.5-6 3-3-1.5 6-3ZM3.5 10.5l3-1.5v3l-3 1.5v-3ZM9.5 10.5l6-3v3l-6 3v-3ZM6.5 6l6-3v3l-6 3V6Z"
      stroke="currentColor"
      strokeLinejoin="round"
    />
    <path
      d="m6.5 9 3 1.5v3l-3-1.5V9Z"
      stroke="currentColor"
      strokeLinejoin="round"
    />
  </svg>
);
const ForwardRef = forwardRef(SvgContainersRegistry);
export default ForwardRef;
