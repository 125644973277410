import { ValueGetterFunc } from 'ag-grid-enterprise';
import { IProtectedAssetViewModel } from 'common/components/ProtectedAssets/ProtectedAssetsTable.interface';
import { FilterField, FindingsAlertType, SortingModel } from '../../components/Findings/Findings.interface';
import { changeUrl, getUrlAngularStyle, toQueryString } from '../../utils/http';
import { GenericObject, IFieldInfo, IValueCount } from '../../interface/general';
import { ASSETS_TAB_NAMES, getProtectedAssetsService } from './ProtectedAssets';
import { IAsset, UrlFuncResult } from '../../assets/common.assets';
import { ICloudAccount } from '../../interface/data_services';
import { getVendor } from '../../consts/vendors';
import { ITimeFromTo } from '../../components/FilterPanel/DefaultFilters/DefaultFilters.interface';
import { TimePresets } from '../../components/FilterPanel/DefaultFilters/DateFilter/DateFilter.consts';
import { POSTURE_FINDINGS_PAGE_URL, SECURITY_EVENTS_PAGE_URL } from '../events/EventsConsts';
import { getEnvironmentDisplayNameFromCloudAccount } from '../../components/ProtectedAssets/AssetUtils';
import i18next from 'i18next';
import { PROTECTED_ASSETS_URL } from './ProtectedAssets.consts';

export function getExternalAdditionalFieldValue(data: IProtectedAssetViewModel, fieldName: string) {
    const externalAdditionalFields = data?.externalAdditionalFields;
    if (!externalAdditionalFields) {
        return null;
    }
    const match = externalAdditionalFields.find((item) => item.name === fieldName);
    return match?.value ?? null;
}

export function getExternalAdditionalFieldValueGetter(fieldName: string): ValueGetterFunc {
    return (params) => {
        return getExternalAdditionalFieldValue(params?.data, fieldName) ?? '';
    };
}

export const TabPropNames = {
    TAB_NAME: 'tabName',
    INNER_TAB_INDEX: 'innerTabIndex',
    TIME_PERIOD_INDEX: 'timePeriodIndex',
    FILTER_FIELDS_ARR: 'filterFieldsArr',
    FILTER_FREE_TEXT: 'filterFreeText',
    FILTER_ENTITY: 'filterEntity',
    SELECTED_ITEM: 'selectedItem',
};

export const TabTimePeriodIndex = {
    HOURS_24: 0,
    DAYS_7: 1,
    DAYS_30: 2,
    ALL: 3,
};


export const getTabProps = (tabName: string, filterFieldsArr?: FilterField[], innerTabIndex = -1, timePeriodIndex = -1, selectedItem?: Object): GenericObject<string> => {
    const props: GenericObject<string> = {};
    props[TabPropNames.TAB_NAME] = tabName;
    if (innerTabIndex >= 0) {
        props[TabPropNames.INNER_TAB_INDEX] = innerTabIndex.toString();
    }
    if (timePeriodIndex >= 0) {
        props[TabPropNames.TIME_PERIOD_INDEX] = timePeriodIndex.toString();
    }
    if (filterFieldsArr) {
        props[TabPropNames.FILTER_FIELDS_ARR] = JSON.stringify(filterFieldsArr);
    }
    if (selectedItem) {
        props[TabPropNames.SELECTED_ITEM] = JSON.stringify(selectedItem);
    }
    return props;
};

export const changeTab = (tabName: string, filterFieldsArr?: FilterField[], innerTabIndex = -1, timePeriodIndex = -1, selectedItem?: Object) => {
    const searchParams = new URLSearchParams(window.location.search);
    removeAngularTabProps(searchParams);
    const tabProps = getTabProps(tabName, filterFieldsArr, innerTabIndex, timePeriodIndex, selectedItem);
    Object.keys(tabProps).forEach(key => {
        searchParams.set(key, tabProps[key]);
    });
    changeUrl(window.location.pathname + `?${searchParams.toString()}`);
};

export const removeAngularTabProps = (searchParams: URLSearchParams) => {
    searchParams.delete(TabPropNames.FILTER_FREE_TEXT);
    searchParams.delete(TabPropNames.FILTER_FIELDS_ARR);
    searchParams.delete(TabPropNames.INNER_TAB_INDEX);
    searchParams.delete(TabPropNames.TIME_PERIOD_INDEX);
    searchParams.delete(TabPropNames.FILTER_ENTITY);
};

export const isListAssetType = (assetType: string): boolean => {
    if (!assetType) return false;
    return !!((assetType || '').match(/List</i));
};

type GetAssetDisplayValues = (assetType: string) => { displayText: string, icon?: string };
export const getAssetDisplayValues: GetAssetDisplayValues = (assetType) => {
    let fixedAssetType = assetType;
    if (isListAssetType(assetType)) {
        fixedAssetType = assetType.replace(/list</i, '').replace(/>/, '');
    }
    const asset = getProtectedAssetsService().getAssetByType(fixedAssetType) as IAsset;
    const prefix: string = isListAssetType(assetType) ? 'List|' : '';
    return {
        displayText: `${prefix}${asset?.displayName || fixedAssetType || i18next.t('COMMON.PROTECTED_ASSET.DETAILS_PANE.UNKNOWN')}`,
        icon: asset?.icon,
    };
};

export const mapTypeItem = (aggregationItems: GenericObject<any>) => {
    if (aggregationItems) {
        return aggregationItems.reduce((prev: any, aggregationItem: any) => {
            prev[aggregationItem.value] = getAssetDisplayValues(aggregationItem?.value);
            return prev;
        }, {});
    } else {
        return null;
    }
};

export const mapEnvironmentItem = (allCloudAccounts: ICloudAccount[]) => {
    return allCloudAccounts.reduce((prev: any, cloudAccount: ICloudAccount) => {
        const icon = cloudAccount?.platform ? getVendor(cloudAccount?.platform)?.icon : '';
        const displayValue = getEnvironmentDisplayNameFromCloudAccount(cloudAccount, cloudAccount.id);
        prev[cloudAccount.id] = { displayText: displayValue, icon, ...cloudAccount };
        return prev;
    }, {});
};

export const buildCloudAccountList = (aggregatedValues: IValueCount[], allCloudAccounts: ICloudAccount[]) => {
    const filterSearchIds = aggregatedValues.map((item: any) => item.value);
    const shallowCloudAccounts = allCloudAccounts.filter((cloudAccount: ICloudAccount) => {
        return !filterSearchIds.includes(cloudAccount.id);
    }).map((cloudAccount: ICloudAccount) => {
        return { value: cloudAccount.id };
    });
    return [...shallowCloudAccounts, ...aggregatedValues];
};

export const getEventsUrl = (alertType: FindingsAlertType, freeTextPhrase: string, filterFields: IFieldInfo[], creationTime?: ITimeFromTo) => {
    const sorting: SortingModel = {
        fieldName: 'createdTime',
        direction: -1,
    };

    const timePreset = creationTime ? { Custom: creationTime } : TimePresets.ALL;
    const baseUrl = alertType === FindingsAlertType.SECURITY ? SECURITY_EVENTS_PAGE_URL : POSTURE_FINDINGS_PAGE_URL;
    return getUrlAngularStyle(baseUrl, sorting, filterFields, freeTextPhrase, timePreset);
};

export function generateAssetPageUrl(asset: IAsset, assetUrl: UrlFuncResult, baseUrl?: string, extraQueryParams?: any, tabName?: string, innerTabIndex?: number): string {
    baseUrl = baseUrl ?? PROTECTED_ASSETS_URL;
    const url = `/${baseUrl}/${asset?.isGenericObject ? 'generic' : 'details'}`;

    const query = {
        ...(assetUrl?.query as object || {}),
        ...(extraQueryParams as object || {}),
        [TabPropNames.TAB_NAME]: tabName || ASSETS_TAB_NAMES.OVERVIEW_TAB_NAME,
        [TabPropNames.INNER_TAB_INDEX]: innerTabIndex !== undefined ? innerTabIndex.toString() : undefined,
        type: asset?.typeNumber,
    };
    const queryParams = toQueryString({ query });
    return `${url}?${queryParams}`;
}

