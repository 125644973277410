import * as React from 'react';
import { SVGProps, Ref, forwardRef } from 'react';
const SvgUnpin = (props: SVGProps<SVGSVGElement>, ref: Ref<SVGSVGElement>) => (
  <svg
    viewBox="0 0 16 16"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    ref={ref}
    {...props}
  >
    <path
      d="M15.5 10.833V4.167a.667.667 0 0 0-1.2-.4l-1 1.333a1 1 0 0 1-.8.4H10a1 1 0 0 1-.8-.4l-.9-1.2a1 1 0 0 0-1.8.6v6a1 1 0 0 0 1.8.6l.9-1.2a1 1 0 0 1 .8-.4h2.5a1 1 0 0 1 .8.4l1 1.333a.667.667 0 0 0 1.2-.4ZM.5 7.5h6"
      stroke="currentColor"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </svg>
);
const ForwardRef = forwardRef(SvgUnpin);
export default ForwardRef;
