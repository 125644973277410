import * as React from 'react';
import { SVGProps, Ref, forwardRef } from 'react';
const SvgComments = (
  props: SVGProps<SVGSVGElement>,
  ref: Ref<SVGSVGElement>
) => (
  <svg
    viewBox="0 0 16 16"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    ref={ref}
    {...props}
  >
    <path
      d="M6.701 11.841 3.5 15.5v-4h-2a1 1 0 0 1-1-1v-8a1 1 0 0 1 1-1h13a1 1 0 0 1 1 1v8a1 1 0 0 1-1 1H7.454a1 1 0 0 0-.753.341ZM3.5 4.5h9m-9 3h9"
      stroke="currentColor"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </svg>
);
const ForwardRef = forwardRef(SvgComments);
export default ForwardRef;
