import * as React from 'react';
import { SVGProps, Ref, forwardRef } from 'react';
const SvgExpand = (props: SVGProps<SVGSVGElement>, ref: Ref<SVGSVGElement>) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    fill="currentColor"
    viewBox="0 0 16 16"
    ref={ref}
    {...props}
  >
    <path d="M15.075.625H10.1c-.3 0-.4.3-.15.55l1.55 1.55L8.575 5.65c-.15.15-.15.4 0 .55L9.8 7.425c.15.15.4.15.55 0L13.375 4.4l1.55 1.55c.25.25.55.15.55-.15V.95c.275-.2.025-.325-.4-.325zM1.1 15.625h4.925c.3 0 .4-.35.15-.575l-1.55-1.55 2.925-3.025a.424.424 0 0 0 0-.575L6.325 8.675a.392.392 0 0 0-.55 0L2.75 12.3 1.2 10.75c-.25-.25-.55-.15-.55.15v4.925c-.025-.325.175-.2.45-.2zM15.75 15.075V10.1c0-.3-.3-.4-.55-.15l-1.55 1.55-3.025-2.925a.392.392 0 0 0-.55 0L8.85 9.8c-.15.15-.15.4 0 .55l3.025 3.025-1.55 1.55c-.25.25-.15.55.15.55h4.975c.05.275.3.025.3-.4zM.625 1.1v4.925c0 .3.35.4.575.15l1.55-1.55L5.775 7.55c.15.15.425.15.575 0L7.6 6.325c.15-.15.15-.4 0-.55L4.575 2.75l1.55-1.55c.3-.25.175-.575-.2-.575H1.1a.46.46 0 0 0-.475.475z" />
  </svg>
);
const ForwardRef = forwardRef(SvgExpand);
export default ForwardRef;
