import {
    ICondOptionGetter,
    IFilterFieldOptionsInfo,
    IProtectedAssetFilterParams,
} from 'common/module_interface/assets/ProtectedAssets';
import { IConditionValueOption, IFacetCounter } from 'common/erm-components/custom/FilterTree/FilterTree.interface';
import { isArray } from 'common/utils/helpFunctions';
import { IItemDataGetter } from 'common/erm-components/custom/ClientFilter/ClientFilter.interface';
import { IFilterConditionsContainer } from 'common/erm-components/custom/FilterTree/FilterCondition';
import {
    addSimpleClientFreeTextCondition,
    addSimpleClientMultiValueCondition,
    createSimpleFieldClientFilterInfo,
    createSimpleFreeTextClientFilterInfo,
} from 'common/erm-components/custom/ClientFilter/SimpleClientFilter/SimpleClientFilter';
import {
    renderAddFilter,
    renderClearAll,
    renderDefaultFreeTextFilter,
    renderMultiSelectFilter,
    renderRecentlyUsedFilters,
    renderSavedFilters,
    renderTreeFilter,
} from 'common/components/FilterPanel/FilterPanelDefaultFilters';
import { FILTER_DISPAY_TYPES, FILTERS_KEYS } from 'common/components/FilterPanel/FilterPanel.consts';
import i18n from 'i18next';
import { IDisplayMappingObject } from 'common/components/FilterPanel/DefaultFilters/DefaultFilters.interface';
import { IconComponentProps, IconProps, VendorIconProps } from 'common/design-system/components-v2/Icon/Icon.types';
import { DEFAULT_ORGANIZATIONAL_UNIT_ID } from 'common/consts/DataConsts';
import { CommonFilterEnum, CommonFilterNames } from './ClientFilterPageTable.consts';
import { IEntityItem } from '../../../utils/filterUtils';
import { IClientFilterType } from './ClientFilterPage.interface';

export const RECENTLY_USED_FILTER_ID = 'recently used';
export const SAVED_FILTERS_FILTER_ID = 'saved filters';

export const getCommonFiltersMap = <T extends IEntityItem>(): { [key in CommonFilterEnum]: IClientFilterType<T, IFilterConditionsContainer> } => ({
    [CommonFilterEnum.ADD_FILTER]: {
        id: CommonFilterEnum.ADD_FILTER,
        content: () => ({
            filterProps: { key: FILTERS_KEYS.ADD_FILTER },
            renderFunction: renderAddFilter,
        }),
    },
    [CommonFilterEnum.SAVED_FILTERS]: {
        id: CommonFilterEnum.SAVED_FILTERS,
        content: ({ filtersInitialData }: IProtectedAssetFilterParams) => {
            return {
                filterProps: {
                    savedFilters: filtersInitialData?.savedFilters || [],
                    savedFiltersComponentName: '',
                    selectedFilterID: '',
                    key: FILTERS_KEYS.SAVED_FILTERS,
                },
                renderFunction: renderSavedFilters,
                displayTypes: [FILTER_DISPAY_TYPES.ROW],
            };
        },
    },
    [CommonFilterEnum.RECENTLY_USED]: {
        id: CommonFilterEnum.RECENTLY_USED,
        content: ({ filtersInitialData }: IProtectedAssetFilterParams) => {
            return {
                filterProps: {
                    key: FILTERS_KEYS.RECENTLY_USED_FILTERS,
                    recentlyUsedList: filtersInitialData?.recentlyUsed,
                    maxLength: 4,
                    componentName: '',
                    title: i18n.t('FILTER_PANEL.RECENTLY_USED_FILTERS'),
                },
                renderFunction: renderRecentlyUsedFilters,
            };
        },
    },
    [CommonFilterEnum.CLEAR_ALL]: {
        id: CommonFilterEnum.CLEAR_ALL,
        content: () => ({
            filterProps: {
                key: FILTERS_KEYS.CLEAR_BUTTON,
            },
            renderFunction: renderClearAll,
            displayTypes: [FILTER_DISPAY_TYPES.ROW],
        }),
    },
});
export const createFreeTextFilter = <T extends IEntityItem>(fieldsOrGetter: (string | IItemDataGetter<T>)[]): IClientFilterType<T, IFilterConditionsContainer> => ({
    id: CommonFilterNames.FREE_TEXT,
    clientInfo: createSimpleFreeTextClientFilterInfo<T>(
        FILTERS_KEYS.FREE_TEXT, fieldsOrGetter),
    content: () => ({
        filterProps: {
            key: FILTERS_KEYS.FREE_TEXT,
            title: i18n.t('FILTER_PANEL.FREE_TEXT_FILTER.FREE_TEXT_LABEL'),
        },
        renderFunction: renderDefaultFreeTextFilter,
        displayTypes: [FILTER_DISPAY_TYPES.ROW],
        keyInObjectForAPI: FILTERS_KEYS.FREE_TEXT,
        setInRequestObj: (requestObj: IFilterConditionsContainer, value: string) => {
            addSimpleClientFreeTextCondition(requestObj, FILTERS_KEYS.FREE_TEXT, value);
        },
    }),
});
export const getFilterValuesInitialData = (filterParams: IProtectedAssetFilterParams, field: string): IFacetCounter[] => {
    const { aggregations, fieldOptionsMap } = filterParams;
    const fieldInfo: IFilterFieldOptionsInfo | undefined = fieldOptionsMap?.[field];
    const counters: IFacetCounter[] | undefined = aggregations[field];
    if (counters && fieldInfo && isArray(fieldInfo.options) && fieldInfo.showAllOptions) {
        return (fieldInfo.options as IConditionValueOption[]).map(option => {
            const counter = counters.find(c => c.value === option.value);
            return {
                value: option.value,
                count: counter?.count ?? 0,
            };
        });
    } else {
        return counters;
    }
};
export const getDisplayMappingFromOptions = (filterParams: IProtectedAssetFilterParams, field: string): IDisplayMappingObject | undefined => {
    const displayMapping: IDisplayMappingObject = {};
    const updateFromOption = (option: IConditionValueOption) => {
        const realValue: string | number = option.value as string | number;
        displayMapping[realValue] = {
            displayText: option.label,
        };
        const iconProps: IconProps | undefined = option.labelProps?.leadingIconProps;
        if (iconProps) {
            if ((iconProps as VendorIconProps).vendorNameOrPath) {
                displayMapping[realValue].icon = (iconProps as VendorIconProps).vendorNameOrPath;
            } else {
                displayMapping[realValue].newIcon = (iconProps as IconComponentProps).name;
            }
        }
    };

    const { fieldOptionsMap } = filterParams;
    const fieldInfo: IFilterFieldOptionsInfo | undefined = fieldOptionsMap?.[field];
    if (!fieldInfo) {
        return;
    }
    const hasOptionsList = fieldInfo && isArray(fieldInfo.options);

    if (hasOptionsList) {
        (fieldInfo.options as IConditionValueOption[]).forEach(option => {
            updateFromOption(option);
        });
    } else {
        const { aggregations } = filterParams;
        const counters: IFacetCounter[] | undefined = aggregations[field];
        if (!counters || (counters.length === 0)) {
            return undefined;
        }
        const optionGetter: ICondOptionGetter = fieldInfo.options as ICondOptionGetter;
        counters.forEach(counter => {
            const option: IConditionValueOption = optionGetter(counter.value);
            if (option) {
                updateFromOption(option);
            } else {
                displayMapping[counter.value] = {
                    displayText: counter.value,
                };
            }
        });
    }
    return displayMapping;
};
export const createSelectionFilter =
    <T extends IEntityItem>(field: string, title: string): IClientFilterType<T, IFilterConditionsContainer> => ({
        id: field,
        isField: true,
        clientInfo: createSimpleFieldClientFilterInfo<T>(field),
        content: (filterParams: IProtectedAssetFilterParams) => ({
            filterProps: {
                initialData: getFilterValuesInitialData(filterParams, field),
                key: field,
                title,
                displayMapping: getDisplayMappingFromOptions(filterParams, field),
            },
            renderFunction: renderMultiSelectFilter,
            keyInObjectForAPI: field,
            setInRequestObj: (requestObj: IFilterConditionsContainer, value: any[]) => {
                addSimpleClientMultiValueCondition(requestObj, value, field);
            },
        }),
    });
export const createOrgUnitFilter =
    <T extends IEntityItem>(field: string, title: string): IClientFilterType<T, IFilterConditionsContainer> => ({
        id: field,
        isField: true,
        clientInfo: createSimpleFieldClientFilterInfo<T>(field),
        content: (filterParams: IProtectedAssetFilterParams) => ({
            filterProps: {
                isMultiSelect: true,
                initialData: filterParams.filtersInitialData?.organizationalUnits,
                key: field,
                title,
            },
            keyInObjectForAPI: field,
            setInRequestObj: (requestObj: IFilterConditionsContainer, value: any[]) => {
                if (!value.includes(DEFAULT_ORGANIZATIONAL_UNIT_ID)) {
                    addSimpleClientMultiValueCondition(requestObj, value, field);
                }
            },
            renderFunction: renderTreeFilter,
        }),
    });