import * as React from 'react';
import { SVGProps, Ref, forwardRef } from 'react';
const SvgCis = (props: SVGProps<SVGSVGElement>, ref: Ref<SVGSVGElement>) => (
  <svg
    id="CIS_svg__Layer_1"
    xmlns="http://www.w3.org/2000/svg"
    x={0}
    y={0}
    viewBox="0 0 64 64"
    xmlSpace="preserve"
    ref={ref}
    {...props}
  >
    <style>
      {
        '.CIS_svg__st0{fill:#fdfefe}.CIS_svg__st1{fill:#0c8ac1}.CIS_svg__st2{fill:#084060}'
      }
    </style>
    <path
      className="CIS_svg__st0"
      d="M28.8 62.8c-6.8-2-12.3-5.8-16.6-11.4-4.3-5.7-6.7-12.1-6.6-19.3C6 16.7 14.2 6.6 28.3 1V.7c0-.7-.1-.8-.8-.6-.5.1-1 .2-1.4.4-2.3.8-4.5 1.9-6.6 3.2-2.8 1.8-5.3 4-7.4 6.5-6.5 7.9-9 16.9-7.5 27 .9 5.9 3.3 11.1 7 15.7 2.6 3.2 5.7 5.9 9.3 8 2.2 1.3 4.5 2.2 7 3 .3.1.6.1.9.1 0-.2.1-.3.1-.4-.1-.2-.1-.4-.1-.8zM44.6 41c2.2.6 4.3.7 6.5.4.8-.1 1.6-.4 2.3-.7 1.5-.7 2.4-1.9 2.6-3.6.3-2.2-.3-4-2.2-5.3-1-.7-2-1.3-3-1.9-.6-.4-1.3-.8-1.8-1.4-.8-.7-.9-1.7-.6-2.7.3-.9 1-1.4 1.9-1.6.7-.1 1.4-.1 2 0 1.3.1 2.3.7 2.6 2.1.1.4.3.4.6.3v-3.4c-2.1-.3-4.2-.5-6.3-.1-3 .5-4.7 2.6-4.4 5.5.1 1.3.8 2.4 1.8 3.3.8.7 1.7 1.2 2.6 1.8.7.5 1.5 1 2.1 1.5 1 .8 1.2 2 .9 3.2-.3 1.2-1.2 1.7-2.4 1.9-1.1.2-2.3.1-3.3-.4-.8-.4-1.4-1.1-1.6-2.1 0-.1-.3-.2-.5-.3.2 1.1.2 2.3.2 3.5zm-13.2-3.6c-.5.1-.5.1-.5.4-.2 1.2-1 1.9-2.1 2.1-1.4.3-2.8.2-4.1-.3-1-.4-1.8-.9-2.3-1.8-.5-.9-.9-1.8-1.1-2.7-.4-1.7-.3-3.4-.2-5.1.1-1.2.4-2.4 1-3.5.7-1.2 1.7-2 3-2.3 1.2-.3 2.4-.3 3.6 0 1.1.3 2.1.7 2.3 2.1.1.4.3.3.6.2V23c-2.3-.3-4.6-.5-6.8-.1-3.6.6-6.2 2.5-7.5 6-.6 1.7-.7 3.5-.5 5.3.5 3.5 2.4 5.8 5.8 6.8 2.4.7 4.8.7 7.2.3.5-.1 1.1-.2 1.6-.3v-3.6zm3.1-14.5c-.2.4 0 .5.3.6.9.2 1.3.8 1.3 1.7V38.8c0 1-.4 1.8-1.5 2-.1 0-.2.3-.4.5h7.6c.2-.5-.1-.5-.4-.6-.8-.2-1.2-.7-1.2-1.5 0-.5-.1-1-.1-1.5v-11c0-.5 0-1 .1-1.5.1-.9.5-1.6 1.4-1.9.2 0 .3-.3.4-.4l-.1-.1c-2.5.1-4.9.1-7.4.1zm-23.9 4.9c.8 0 1-.1 1.3-.8.5-1.5.8-3 1.3-4.4 2.5-6.1 6.5-10.7 12.3-13.8C30.5 6 36 5.1 41.7 6c2.4.4 4.6 1 6.8 2l1.8.9c.4.2 1.3 0 1.4-.4.1-.5-.4-.6-.7-.7-1.5-.7-2.9-1.5-4.4-2-1.6-.6-3.2-1-4.9-1.2-5.4-.8-10.6-.1-15.6 2.2-4.7 2.2-8.5 5.5-11.4 9.8-1.5 2.3-2.7 4.8-3.4 7.4-.4 1.1-.8 2.4-.7 3.8zm50.3 12.1c0-1-.9-1.9-1.9-1.9-1.1 0-1.9.9-1.9 1.9 0 1.1.9 1.9 1.9 1.9 1.1.1 1.9-.8 1.9-1.9z"
    />
    <path
      className="CIS_svg__st1"
      d="M28.8 62.8v.8c0 .1 0 .2-.1.4-.3 0-.6 0-.9-.1-2.4-.7-4.8-1.7-7-3-3.6-2.1-6.7-4.7-9.3-8-3.7-4.6-6.1-9.8-7-15.7-1.5-10.1 1-19.1 7.5-27 2.1-2.6 4.6-4.7 7.4-6.5 2.1-1.3 4.3-2.4 6.6-3.2.5-.2 1-.3 1.4-.4.7-.2.8-.1.8.6V1C14.2 6.6 6 16.7 5.7 32.1c-.2 7.1 2.2 13.6 6.6 19.3 4.2 5.6 9.7 9.4 16.5 11.4z"
    />
    <path
      className="CIS_svg__st2"
      d="M44.6 41v-3.7c.2.1.5.2.5.3.2.9.7 1.6 1.6 2.1 1.1.6 2.2.6 3.3.4 1.1-.2 2.1-.7 2.4-1.9.3-1.2.1-2.3-.9-3.2l-2.1-1.5c-.9-.6-1.8-1.2-2.6-1.8-1-.8-1.6-1.9-1.8-3.3-.3-2.8 1.4-5 4.4-5.5 2.1-.4 4.2-.2 6.3.1v3.4c-.3.2-.5.1-.6-.3-.3-1.4-1.3-2-2.6-2.1-.7-.1-1.4-.1-2 0-.9.1-1.6.6-1.9 1.6-.3 1-.2 2 .6 2.7.5.5 1.2.9 1.8 1.4 1 .7 2 1.3 3 1.9 1.8 1.3 2.4 3.1 2.2 5.3-.2 1.7-1.1 2.9-2.6 3.6-.7.3-1.5.6-2.3.7-2.3.5-4.5.4-6.7-.2zM31.4 37.4v3.5c-.6.1-1.1.3-1.6.3-2.4.4-4.9.4-7.2-.3-3.4-1-5.3-3.3-5.8-6.8-.2-1.8-.2-3.6.5-5.3 1.3-3.5 3.9-5.4 7.5-6 2.3-.4 4.5-.2 6.8.1v3.5c-.2.1-.5.2-.6-.2-.2-1.3-1.2-1.8-2.3-2.1-1.2-.3-2.4-.2-3.6 0-1.3.3-2.3 1.1-3 2.3-.6 1.1-.9 2.3-1 3.5-.2 1.7-.2 3.4.2 5.1.2.9.6 1.9 1.1 2.7.5.9 1.3 1.5 2.3 1.8 1.4.5 2.7.6 4.1.3 1.2-.2 2-.9 2.1-2.1 0-.2.1-.2.5-.3zM34.5 22.9h7.4l.1.1c-.1.1-.2.3-.4.4-1 .3-1.4.9-1.4 1.9 0 .5-.1 1-.1 1.5v11c0 .5 0 1 .1 1.5.1.8.5 1.3 1.2 1.5.3.1.6.1.4.6h-7.6c.1-.2.2-.5.4-.5 1.1-.3 1.5-1 1.5-2V25.3c0-.9-.4-1.5-1.3-1.7-.3-.2-.5-.3-.3-.7z"
    />
    <path
      className="CIS_svg__st1"
      d="M10.6 27.8c-.1-1.4.3-2.6.7-3.9.8-2.6 1.9-5.1 3.4-7.4 2.9-4.3 6.7-7.6 11.4-9.8 5-2.3 10.2-3.1 15.6-2.2 1.7.3 3.3.7 4.9 1.2 1.5.5 3 1.3 4.4 2 .3.1.8.2.7.7-.1.4-1 .6-1.4.4l-1.8-.9c-2.2-1-4.5-1.6-6.8-2-5.7-.8-11.2.1-16.2 2.8-5.8 3.1-9.8 7.7-12.3 13.8-.6 1.4-.8 3-1.3 4.4-.3.7-.5.9-1.3.9zM60.9 39.9c0 1.1-.8 1.9-1.9 2-1 0-1.9-.9-1.9-1.9 0-1 .9-1.9 1.9-1.9 1.1-.1 1.9.8 1.9 1.8zm-2.7-1.1h1.2c.4.1.7.4.5.8-.3.6 0 .9.3 1.3.6-.8.6-1.5 0-2.1-.4-.4-1.7-.5-2.2-.1-.5.4-.6 1.4-.2 2 .4.7 1.1.9 1.9.6-.2-.3-.5-.7-.7-1.1-.1.8-.1.8-.7.7-.1-.6-.1-1.3-.1-2.1z"
    />
    <path
      className="CIS_svg__st0"
      d="M58.2 38.8v2.1c.5 0 .5 0 .7-.7.3.4.5.7.7 1.1-.8.4-1.5.2-1.9-.6-.3-.6-.2-1.6.2-2 .4-.4 1.7-.3 2.2.1.6.6.6 1.3 0 2.1-.2-.4-.6-.8-.3-1.3.2-.3-.1-.7-.5-.8h-1.1z"
    />
  </svg>
);
const ForwardRef = forwardRef(SvgCis);
export default ForwardRef;
