//***********************************************************
//*** THIS FILE IS DEPRECATED! DON'T ADD TO THIS ENUM    ****
//*** Most likely you want to add the active feature in  ****
//*** your own module and not in this super-duper-global ****
//*** let's-have-everyone-edit-the-same-file file        ****
//***********************************************************

export enum ActiveFeaturesTypes {
    ALLOW_ANY_AGENT_FEATURES = 'allow-any-agent-features',
    ALLOW_TRUST_FEATURE = 'allow-trust-feature',
    SHIFTLEFT = 'classic-shiftleft',
    SPECTRAL = '!classic-shiftleft',
}
