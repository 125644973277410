import * as React from 'react';
import { SVGProps, Ref, forwardRef } from 'react';
const SvgAttachment = (
  props: SVGProps<SVGSVGElement>,
  ref: Ref<SVGSVGElement>
) => (
  <svg
    viewBox="0 0 16 16"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    ref={ref}
    {...props}
  >
    <path
      d="m11.629 5.382-5.422 5.422a1.167 1.167 0 0 1-1.65 0v0a1.167 1.167 0 0 1 0-1.65L10.411 3.3a2.333 2.333 0 0 1 3.3 0v0a2.333 2.333 0 0 1 0 3.3l-6.286 6.286a3.5 3.5 0 0 1-4.95 0v0a3.5 3.5 0 0 1 0-4.95l5.854-5.854"
      stroke="currentColor"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </svg>
);
const ForwardRef = forwardRef(SvgAttachment);
export default ForwardRef;
