import { GenericObject, IColumnUsageDef } from 'common/interface/general';
import { buildPath, getService } from '../../extensibility/AddinContainer';
import { Aggregations } from 'common/components/FilterPanel/FilterPanel.interface';
import { ICloudAccount, IOrganizationalUnit } from 'common/interface/data_services';
import { IFilterProps } from 'common/components/FilterPanel/FilterPanelManager';
import { ColDef } from 'ag-grid-enterprise';
import { IWidgetConfigFilterState } from '../intelligence/Intelligence.interface';
import { getFindingSeverityInfo } from '../../consts/FindingSeverity';
import { IFindingModel } from '../../components/Findings/Findings.interface';
import React from 'react';
import { AllIconsName } from '../../design-system/components-v2/Icon/Icon.types';
import { IFinding } from '../intelligence/Findings/Findings.interface';

export const EVENTS_SERVICE_ID = 'EventsService';
export const PATH_EVENTS_FILTERS_DEFINITIONS = buildPath('events', 'filters_definitions');
export const PATH_EVENTS_WIDGETS_FILTERS = buildPath('events', 'widgets_filters');
export const PATH_RULE_AGGREGATION_FILTERS = buildPath('events', 'rule_aggregation_filters');
export const PATH_EVENTS_TREND_WIDGETS_FILTERS = buildPath('events', 'widgets_events_trend_filters');

export interface IFiltersInitialData {
    recentlyUsed?: { [key: string]: string | object }[];
    savedFilters?: { [key: string]: string | object }[];
    organizationalUnits?: IOrganizationalUnit;
}

export interface IEventsFilterParams {
    aggregations: Aggregations;
    filtersInitialData?: IFiltersInitialData;
    allCloudAccounts?: ICloudAccount[];
}

export interface IEventsFilter {
    id: string;
    filterProps?: GenericObject<any>;
}


export interface IEventsService {
    getFilterDefs(filters: IEventsFilter[], params: IEventsFilterParams): IFilterProps[];
    getColumnDefs(columnUsages: IColumnUsageDef[]): ColDef[];
}

export function getEventsService(): IEventsService {
    return getService<IEventsService>(EVENTS_SERVICE_ID);
}

export const getEventsOvertimeFilterString = (widgetConfigFilterStates: IWidgetConfigFilterState[]): string | null => {
    if (!widgetConfigFilterStates?.length) {
        return null;
    }

    enum KeyNameToFilterValue {
        'cloudAccountType' = 'env.cloudAccountType',
        'bundleName' = 'bundle.name',
        'origin' = 'origin',
        'severity' = 'rule.severity'
    }

    const filtersObject = widgetConfigFilterStates.reduce((result: { [key: string]: string[] }, filterItem: IWidgetConfigFilterState) => {
        const filterName = filterItem.name;
        let value = null;
        if (filterName === 'severity') {
            const severityValue = getFindingSeverityInfo(filterItem.value)?.level || 0;
            value = `'${severityValue}'`;
        } else {
            value = `'${filterItem.value}'`;
        }
        if (result[filterName] && result[filterName].length && typeof filterItem.value !== 'undefined') {
            result[filterName].push(value);
        } else if (!result[filterName] && (filterItem.value || filterItem.value === 0 )) {
            result[filterName] = [value];
        }
        return result;
    }, {});

    if (Object.keys(filtersObject).length > 0) {
        return Object.keys(filtersObject).reduce((result: string, filterName, index: number) => {
            const isLastFilterItem = index === Object.keys(filtersObject).length - 1;
            const values: string[] = filtersObject[filterName as keyof typeof filtersObject];
            const expression = ` ${KeyNameToFilterValue[filterName as keyof typeof KeyNameToFilterValue]} in (${values})`;

            if (isLastFilterItem) {
                result += expression;
            } else {
                result += `${expression} and`;
            }
            return result;
        }, 'alerts where');
    } else {
        return null;
    }
};

export enum IdType {
    FINDING_KEY = 'findingKey',
    FINDING_ID = 'findingId',
}
export interface IEvent extends IFindingModel {
    idType: IdType,
    isGrouped?: boolean;
}

export interface IEventOverviewPanelProvider {
    panelId: string;
    panel: React.FC<{ finding: IFinding }>;
    isRelevant?: (finding: IFinding) => boolean;
}

export interface EventAction {
    label: string;
    icon?: AllIconsName;
    callback: (event: IEvent) => void,
    disabled?: (event: IEvent) => boolean;

}
