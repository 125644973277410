import React from 'react';
import { ExampleStyled } from './DrawerExample.styled';


export const DrawerExampleTypeViewer: React.FC<{ typeByPlatform: string, onDelete: (typeByPlatform: string) => void }> = ({ typeByPlatform, onDelete }) => {
    return (
        <div style={{ display: 'flex', flexDirection: 'column' }}>
            <div style={{ fontSize: '16px', fontWeight: 'bold', color: 'blue' }}>{typeByPlatform}</div>
            <ExampleStyled.ButtonDiv onClick={() => onDelete(typeByPlatform)}>Delete Type</ExampleStyled.ButtonDiv>
        </div>
    );
};


export const DrawerLargeExampleTextViewer: React.FC<{ text: string, onRestart: (date: Date) => void, onReverse: (date: Date) => void }> = ({ text, onRestart, onReverse }) => {
    return (
        <div style={{ display: 'flex', flexDirection: 'column' }}>
            <div style={{ fontSize: '24px', fontWeight: 'bold', color: 'purple' }}>{text}</div>
            <ExampleStyled.ButtonDiv onClick={() => onRestart(new Date())}>Restart All Types</ExampleStyled.ButtonDiv>
            <ExampleStyled.ButtonDiv onClick={() => onReverse(new Date())}>Reverse Table Order</ExampleStyled.ButtonDiv>
        </div>
    );
};
