import styled from 'styled-components';

const TopDiv = styled.div`
  display: flex;
  flex-direction: column;
  gap: 4px;
`;

const TitleDiv = styled.div`
  color: var(--fg-colors-fg-regular, #354764);
  font-size: 12px;
  font-style: normal;
  font-weight: 500;
  line-height: 16px;
`;

const BaseTextDiv = styled.div`
  overflow: hidden;
  color: var(--fg-colors-fg-regular, #354764);
  text-overflow: ellipsis;
  font-size: 12px;
  font-style: normal;
  font-weight: 400;
  line-height: 16px;
`;

const TextValueDiv = styled(BaseTextDiv)`
`;

const LinkValueDiv = styled(BaseTextDiv)`
  cursor: pointer;
  
  &:hover {
    text-decoration: underline;
  }
`;


const ContentDiv = styled.div`
  display: flex;
  font-size: 12px;
  font-style: normal;
  font-weight: 400;
  line-height: 20px;
`;


export const PropStyled = {
    TopDiv,
    TitleDiv,
    TextValueDiv,
    LinkValueDiv,
    ContentDiv,
};