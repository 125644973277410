import * as React from 'react';
import { SVGProps, Ref, forwardRef } from 'react';
const SvgUnsecured = (
  props: SVGProps<SVGSVGElement>,
  ref: Ref<SVGSVGElement>
) => (
  <svg
    viewBox="0 0 16 16"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    ref={ref}
    {...props}
  >
    <path
      d="M5.5 5.5 8 8m0 0 2.5 2.5M8 8l2.5-2.5M8 8l-2.5 2.5M8 .5c-1.5 1-3.5 2-5.5 2V9c0 4 5.5 6.5 5.5 6.5S13.5 13 13.5 9V2.5c-2 0-4-1-5.5-2Z"
      stroke="#42526D"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </svg>
);
const ForwardRef = forwardRef(SvgUnsecured);
export default ForwardRef;
