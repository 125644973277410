import React from 'react';
import { ICellRendererParams } from 'ag-grid-enterprise';
import { FindingSeverityCellComponent } from '../../Findings/FindingSeverityCellComponent';
import { isNil } from '../../../utils/helpFunctions';

const FindingSeverityCellRender: React.FC<ICellRendererParams> = (params) => {
    const severityNameOrLevel: string | number | undefined = params.value;
    if (isNil(severityNameOrLevel)) {
        return null;
    }
    return <FindingSeverityCellComponent severityNameOrLevel={severityNameOrLevel } />;
};
export default FindingSeverityCellRender;
