import i18next from 'i18next';
import { ConnectionStatus } from './Intelligence.const';
import { GenericObject } from '../../interface/general';
import { IColumnMapper, IGslRunResponse } from './Intelligence.interface';
import { AssetUrlParams, IAsset } from '../../assets/common.assets';
import { getProtectedAssetsService } from '../assets/ProtectedAssets';
import { generateAssetPageUrl } from '../assets/utils';
import { PROTECTED_ASSETS_URL } from '../assets/ProtectedAssets.consts';

const t = i18next.getFixedT(null, null, 'COMMON.CONNECTION_STATUS');

export const getConnectionStatusDetails = (status: ConnectionStatus) => {
    switch (status) {
        case ConnectionStatus.connected:
            return { text: t('CONNECTED'), icon: 'connected-status' };
        case ConnectionStatus.canNotConnect:
            return { text: t('CAN_NOT_CONNECT'), icon: 'inactive' };
        case ConnectionStatus.partiallyConnected:
            return { text: t('PARTIALLY_CONNECTED'), icon: 'partially-connected-status' };
        default:
            return { text: t('NOT_CONNECTED'), icon: 'not-connected-status' };
    }
};

export type IServerValueToUiValueConvertors = GenericObject<(serverValue: any) => any>;
/**
 * Map the Gsl Run response to specific type T
 * @param columnMapperFields corresponding column mapping fields
 * @param response gsl run response
 * @param serverValueToUiValueConvertors value converter from server to ui
 * @return T[] array of mapped items
 */
export const mapGslResponseToType = <T>(columnMapperFields: GenericObject<string>, response: IGslRunResponse, serverValueToUiValueConvertors?: IServerValueToUiValueConvertors): T[] => {
    const columnMapper: IColumnMapper = {};
    Object.entries(response.cols).forEach(([key, value]) => {
        if (Object.values(columnMapperFields).includes(key)) {
            columnMapper[key] = value;
        }
    });

    return response.data.map((row: any) => {
        const item: Partial<T> = {};
        Object.entries(columnMapperFields).forEach(([uiFieldName, serverFieldName]) => {
            const colIndex: number = columnMapper[serverFieldName];
            const severValue: any = row[colIndex];
            const uiValue: any = serverValueToUiValueConvertors?.[uiFieldName] ? serverValueToUiValueConvertors?.[uiFieldName](severValue) : severValue;
            item[uiFieldName as keyof T] = uiValue;
        });
        return item as T;
    });
};

export function generateEntityLink(entityType: string, entityExternalId: string, cloudAccountId: string, entityId: string, tabName?: string): string {
    const iAsset: IAsset | null = getProtectedAssetsService().getProtectedAssetByTypeAndDome9Id(entityType, entityId);
    if (!iAsset) {
        return '';
    }

    const urlParams: AssetUrlParams = {
        asset: iAsset,
        entityId: entityExternalId,
        cloudAccountId,
        dome9Id: entityId || undefined,
        platform: iAsset.platform,
        entityType,
        typeNumber: iAsset?.typeNumber,
    };
    const assetUrl = iAsset?.getUrl(urlParams) ?? null;
    if (assetUrl === null) {
        return '';
    }

    return generateAssetPageUrl(iAsset, assetUrl, PROTECTED_ASSETS_URL, undefined, tabName);
}
