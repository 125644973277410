import * as React from 'react';
import { SVGProps, Ref, forwardRef } from 'react';
const SvgInterfaceFolderAdd = (
  props: SVGProps<SVGSVGElement>,
  ref: Ref<SVGSVGElement>
) => (
  <svg
    viewBox="0 0 16 16"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    ref={ref}
    {...props}
  >
    <path
      clipRule="evenodd"
      d="M1.667 1.5c-.31 0-.607.126-.825.351-.22.226-.342.53-.342.849v9.6c0 .318.123.623.342.848.219.226.515.352.825.352h11.666c.31 0 .607-.126.825-.351.219-.225.342-.53.342-.849V4.7c0-.318-.123-.623-.342-.849a1.15 1.15 0 0 0-.825-.351H7.5c-.224 0-.393-.15-.526-.34L5.453 1.5H1.667Z"
      stroke="currentColor"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M7.5 6v5M5 8.5h5"
      stroke="currentColor"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </svg>
);
const ForwardRef = forwardRef(SvgInterfaceFolderAdd);
export default ForwardRef;
