import * as React from 'react';
import { SVGProps, Ref, forwardRef } from 'react';
const SvgAdmissionControl = (
  props: SVGProps<SVGSVGElement>,
  ref: Ref<SVGSVGElement>
) => (
  <svg
    viewBox="0 0 16 16"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    ref={ref}
    {...props}
  >
    <path
      d="M6 7.5a.5.5 0 0 0-.5-.5h-2a.5.5 0 0 0 0 1h2a.5.5 0 0 0 .5-.5Z"
      fill="currentColor"
    />
    <path
      d="M10.5 9a1 1 0 0 1 1-1h1a1 1 0 0 1 1 1v1.5h-3V9Z"
      stroke="currentColor"
    />
    <path
      d="M8.5 12a1.5 1.5 0 0 1 1.5-1.5h4a1.5 1.5 0 0 1 1.5 1.5v2a1.5 1.5 0 0 1-1.5 1.5h-4A1.5 1.5 0 0 1 8.5 14v-2Z"
      stroke="currentColor"
      strokeLinejoin="round"
    />
    <path
      d="M5.5 15.5h-3a2 2 0 0 1-2-2v-11a2 2 0 0 1 2-2h7a2 2 0 0 1 2 2v2"
      stroke="currentColor"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </svg>
);
const ForwardRef = forwardRef(SvgAdmissionControl);
export default ForwardRef;
