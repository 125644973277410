import * as React from 'react';
import { SVGProps, Ref, forwardRef } from 'react';
const SvgPin = (props: SVGProps<SVGSVGElement>, ref: Ref<SVGSVGElement>) => (
  <svg
    viewBox="0 0 16 16"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    ref={ref}
    {...props}
  >
    <path
      d="M10.833.5H4.167a.667.667 0 0 0-.4 1.2l1.333 1a1 1 0 0 1 .4.8V6a1 1 0 0 1-.4.8l-1.2.9a1 1 0 0 0 .6 1.8h6a1 1 0 0 0 .6-1.8l-1.2-.9a1 1 0 0 1-.4-.8V3.5a1 1 0 0 1 .4-.8l1.333-1a.667.667 0 0 0-.4-1.2ZM7.5 15.5v-6"
      stroke="currentColor"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </svg>
);
const ForwardRef = forwardRef(SvgPin);
export default ForwardRef;
