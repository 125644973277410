import { ColumnTypes } from 'common/module_interface/events/EventsConsts';
import { IEventsTableColOverride } from 'common/erm-components/custom/EventsTableWidget/EventsTableWidget.interface';

export const getColumnsOverrideDefs = (): IEventsTableColOverride[] => {
    return [
        {
            colOverride: {
                id: ColumnTypes.RULE_NAME,
                colDefOverride: {
                    flex: 5,
                },
            },
            field: 'ruleName'
        },
        {
            colOverride: {
                id: ColumnTypes.SEVERITY,
                colDefOverride: {
                    flex: 2,
                }
            },
            field: 'severityName'
        },
        {
            colOverride: {
                id: ColumnTypes.CLOUD_ACCOUNT_ID,
                colDefOverride: {
                    flex: 5,
                }
            },
            field: 'cloudAccountId'
        },
        {
            colOverride: {
                id: ColumnTypes.PLATFORM,
                colDefOverride: {
                    flex: 2,
                },
            },
            field: 'cloudAccountType'
        },
        {
            colOverride: {
                id: ColumnTypes.ORIGIN,
                colDefOverride: {
                    flex: 2,
                },
            },
            field: 'origin'
        }
    ];
};
