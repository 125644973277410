import React from 'react';
import { formatNumberShorthand } from '../../utils/helpFunctions';
import { IWidgetLevelInfo } from './AssetWidget.interface';

const LevelFrame: React.FC<{ title: string, count: number, color: string }> = ({ title, count, color }) => {
    return (
        <div className='flex'>
            <div
                className={'rounded-full font-medium theme text-strong leading-[1em] text-2xs py-[3px] theme-dark px-[6px] self-center flex-0 text-center w-[50px]'}
                style={{ backgroundColor: color }}>{title}</div>
            <div className='flex-1 border-b mt-6 self-center border-dotted mx-5 min-w-[20px]'></div>
            <div className='flex-0 font-semibold w-10'>{formatNumberShorthand(count)}</div>
        </div>
    );
};

const AssetTooltipContent: React.FC<{
    descendingLevelInfos: IWidgetLevelInfo[],
}> = ({ descendingLevelInfos }) => {
    const createSingleFrame = (info: IWidgetLevelInfo) => {
        return <LevelFrame title={info.text} count={info.count} color={info.color} key={info.text}/>;
    };

    return (
        <div className='space-y-5'>
            {descendingLevelInfos.map((info: IWidgetLevelInfo) => createSingleFrame(info))}
        </div>
    );
};

export default AssetTooltipContent;
