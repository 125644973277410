import * as React from 'react';
import { SVGProps, Ref, forwardRef } from 'react';
const SvgPostureManagement = (
  props: SVGProps<SVGSVGElement>,
  ref: Ref<SVGSVGElement>
) => (
  <svg
    viewBox="0 0 16 16"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    ref={ref}
    {...props}
  >
    <path
      d="M7.106 1.169 1.048 3.765A.904.904 0 0 0 1.404 5.5h12.192a.904.904 0 0 0 .356-1.735L7.894 1.17a1 1 0 0 0-.788 0Z"
      stroke="currentColor"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M.5 15.5h14M2.5 7.5v6M12.5 7.5v6M7.5 7.5v6"
      stroke="currentColor"
      strokeLinecap="round"
    />
  </svg>
);
const ForwardRef = forwardRef(SvgPostureManagement);
export default ForwardRef;
