import { getService } from '../../extensibility/AddinContainer';
import { ColDef } from 'ag-grid-enterprise';
import { GenericObject, IColumnUsageDef } from '../../interface/general';
import { IAsset, UrlFuncResult } from 'common/assets/common.assets';
import { IFilterProps } from 'common/components/FilterPanel/FilterPanelManager';
import { InfoPaneFieldProps, InfoPaneInnerProps, InfoPanelActionProps } from '../../components/InfoPane/InfoPane';
import {
    IAssetUrlRequiredProps,
    IProtectedAssetViewModel,
    ProtectedAssetsResponse,
    RequestFilter,
} from '../../components/ProtectedAssets/ProtectedAssetsTable.interface';
import { ICloudEntityData } from './ICloudEntity';
import React from 'react';
import { ICachingConfig, ICustomzationResponse } from '../../interface/services';
import { ICellRendererParams } from 'ag-grid-community';
import { ProtectedAssetsPageRegistry } from './ProtectedAssetsPageRegistry';
import { AxiosError } from 'axios';
import { ICloudAccount, IOrganizationalUnit } from '../../interface/data_services';
import { Aggregations } from '../../components/FilterPanel/FilterPanel.interface';
import { ITableAction } from 'common/design-system/components-v2/Table/Table.types';
import { IConditionValueOption } from '../../erm-components/custom/FilterTree/FilterTree.interface';

export interface IAssetDetailsPageAliases {
    urlAliases: string[];
    getTabsMetadata: () => { tabsId: string, closeAllUrl: string } | undefined;
    isRelevant: () => boolean;
    isDefault?: boolean;
}

export interface IProtectedAssetTableCellRendererAddinContent {
    renderer: React.FC<ICellRendererParams>;
    params: GenericObject<any>;
}

export interface IOverviewPanelCategory {
    id: string;
    title: string;
    isRelevant?: (entity: ICloudEntityData) => boolean;
}

export interface IOverviewPanelCategoryItem {
    id: string;
    component: React.FC<{ entity: ICloudEntityData }>;
    wrapperClass?: string;
    isRelevant?: (entity: ICloudEntityData) => boolean;
}

export interface IProtectedAssetFilter {
    id: string;
    isField?: boolean;
    filterProps?: GenericObject<any>;
    position?: number;
}

export interface IProtectedAssetActionKey {
    id: string;
}

export interface IFiltersInitialData {
    recentlyUsed?: ICustomzationResponse<any>;
    savedFilters?: ICustomzationResponse<any>;
    organizationalUnits?: IOrganizationalUnit;
}

export type ICondOptionGetter = (value: string) => IConditionValueOption;

export interface IFilterFieldOptionsInfo {
    options: IConditionValueOption[] | ICondOptionGetter;
    showAllOptions?: boolean;
}

export type IFilterFieldOptionsMap = { [key: string]: IFilterFieldOptionsInfo };


export interface IProtectedAssetFilterParams {
    aggregations: Aggregations;
    filtersInitialData?: IFiltersInitialData;
    allCloudAccounts?: ICloudAccount [];
    fieldOptionsMap?: IFilterFieldOptionsMap;
}

type AssetRelevantPredicate = (entity: ICloudEntityData, isSummaryView?: boolean) => boolean | Promise<boolean>;


export interface IProtectedAssetDetailProvider<T extends InfoPaneInnerProps> {
    isRelevant: boolean | string[] | AssetRelevantPredicate;

    getValue(entity: ICloudEntityData): T;
}

export interface IProtectedAssetDetailPanelDescriptor {
    name: string;
    title: string;
    isReact?: boolean;
    component?: React.FC<{ entity: ICloudEntityData }>;
}

export interface IProtectedAssetDetailPanelProvider {
    isRelevant: boolean | string[] | AssetRelevantPredicate;

    getValue(entity: ICloudEntityData): IProtectedAssetDetailPanelDescriptor;

}

export const PROTECTED_ASSETS_SERVICE_ID = 'ProtectedAssetsService';

export interface IProtectedAssetsService {
    getProtectedAssetBySrl(assetSrl: string): Promise<IProtectedAssetViewModel | null>;

    getProtectedAssetById(id: string, typeByPlatform: string, cloudAccountId:string, cachingConfig?: ICachingConfig, customHandleError?: (error: AxiosError<ProtectedAssetsResponse>) => ProtectedAssetsResponse): Promise<IProtectedAssetViewModel | null>;

    getProtectedAssetsByFilter(filter: RequestFilter, cachingConfig?: ICachingConfig, customHandleError?: (error: AxiosError<ProtectedAssetsResponse>) => ProtectedAssetsResponse):Promise<IProtectedAssetViewModel[]>;

    getColumnDefs(columnIds: IColumnUsageDef[]): ColDef[];

    getFilterDefs(filters: IProtectedAssetFilter[], params: IProtectedAssetFilterParams): IFilterProps[];

    getFilterDefsForWidgets(filters: IProtectedAssetFilter[], params: IProtectedAssetFilterParams): IFilterProps[];

    getActionsDefs(actions: IProtectedAssetActionKey[]): ITableAction[];

    getAssetByType(type: string): IAsset | null;

    getAssetByTypeNumber(typeNumberAsString: string): IAsset | null;

    getAssetIconByDisplayName(assetDisplayName: string): string | undefined;

    getProtectedAssetUrlByProps(urlProps: IAssetUrlRequiredProps): string | null;

    getProtectedAssetUrl(assetViewModel: IProtectedAssetViewModel & {
        generateUrl?: (asset: IAsset, assetUrl: UrlFuncResult) => string
    }): string | null;

    getProtectedAssetByTypeAndDome9Id(type: string, dome9Id: string | null): IAsset | null;

}

export function getProtectedAssetsService(): IProtectedAssetsService {
    return getService<IProtectedAssetsService>(PROTECTED_ASSETS_SERVICE_ID);
}

export const ASSETS_TAB_NAMES = {
    OVERVIEW_TAB_NAME: 'overview',
    ENTITY_VIEW_TAB_NAME: 'jsonView',
    POSTURE_FINDINGS_TAB_NAME: 'Task',
    PROPERTIES_TAB_NAME: 'properties',
    ADDITIONAL_FINDINGS_TAB_NAME: 'findings',
    NETWORK_SECURITY_TAB_NAME: 'networkSecurityPolicies',
    SQL_SERVER_TAB_NAME: 'sqlServer',
    IAM_POLICY_TAB_NAME: 'iamPolicies',
    VULNERABILITIES_TAB_NAME: 'vulnerabilities',
};

function getEntityPredicate<T extends InfoPaneInnerProps>(entityData: ICloudEntityData, isSummaryView?: boolean) {
    return async (provider: IProtectedAssetDetailProvider<T>) => {
        if (typeof provider.isRelevant === 'boolean') {
            return provider.isRelevant;
        }
        if (Array.isArray(provider.isRelevant)) {
            return provider.isRelevant.includes(entityData.typeByPlatform);
        }
        return provider.isRelevant(entityData, isSummaryView);
    };
}

export async function getEntityFields(entityData: ICloudEntityData, providers: IProtectedAssetDetailProvider<InfoPaneFieldProps>[], isSummaryView?: boolean): Promise<InfoPaneFieldProps[]> {
    const result: InfoPaneFieldProps[] = [];
    for (let i = 0; i < providers.length; i++) {
        const isRelevant = await getEntityPredicate(entityData, isSummaryView)(providers[i]);
        if (isRelevant) {
            result.push(providers[i].getValue(entityData));
        }
    }
    return result;
}

export async function getEntityActions(entityData: ICloudEntityData, isSummaryView?: boolean): Promise<InfoPanelActionProps[]> {
    const result: InfoPanelActionProps[] = [];
    const providers = ProtectedAssetsPageRegistry.getChipProviders();

    for (let i = 0; i < providers.length; i++) {
        const isRelevant = await getEntityPredicate(entityData, isSummaryView)(providers[i]);
        if (isRelevant) {
            result.push(providers[i].getValue(entityData));
        }
    }

    return result;
}

