import * as React from 'react';
import { SVGProps, Ref, forwardRef } from 'react';
const SvgContainerImage = (
  props: SVGProps<SVGSVGElement>,
  ref: Ref<SVGSVGElement>
) => (
  <svg
    viewBox="0 0 16 16"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    ref={ref}
    {...props}
  >
    <path
      d="M4.5 15.5V9.313c0-.496-.272-.95-.704-1.172L.698 6.143m13.098 3.683-8.635 5.54c-.409.21-.896.178-1.276-.082L1.067 13.47A1.32 1.32 0 0 1 .5 12.383V6.845c0-.496.272-.95.704-1.171L10.02.638c.409-.21.895-.179 1.275.082l2.637 1.808c.354.243.567.652.567 1.09v5.037c0 .496-.272.95-.704 1.171Z"
      stroke="currentColor"
      strokeLinejoin="round"
    />
    <path
      d="M6.5 8.3v3.909m4-6.309v3.7m-2-2.3v3.595m4-6.495v3.9"
      stroke="currentColor"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </svg>
);
const ForwardRef = forwardRef(SvgContainerImage);
export default ForwardRef;
