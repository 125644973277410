import React, { useCallback, useEffect } from 'react';
import { Stack } from 'common/design-system/components-v2';
import { TextIconVersionItem } from './MultiSelectList/ListItemTypes/TextIconVersionItem';
import { useTranslation } from 'react-i18next';
import { ListItem } from './MultiSelectList/ListItemTypes/interfaces';
import { OnValidate } from '../Wizard/Wizard';
import { RulesetVersion } from '../../interface/ruleset';

interface Props {
    ruleset?: ListItem;
    onSelectedItemChanged:Function;
    preSelectedVersion?:RulesetVersion;
    onValidate:OnValidate
}


export const RulesetVersionSelectComponent: React.FC<Props> = ({ onSelectedItemChanged,ruleset ,onValidate ,preSelectedVersion }) => {
    const { t } = useTranslation();
    const handleSelectedItemChanged = useCallback((item: ListItem, selected?: boolean) => {
        onSelectedItemChanged(item, selected);
        onValidate(true);
    },[onSelectedItemChanged, onValidate]);

    useEffect(() => {
        onValidate(!!preSelectedVersion);
    }, [preSelectedVersion]);
    return (
        <Stack alignItems={'flex-start'}>
            <h3>{t('GENERAL.SELECT_VERSION')}</h3>
            {ruleset && <TextIconVersionItem
                item={{ id: ruleset.id.toString(), name: ruleset.name, availableVersions: ruleset.availableVersions , selectedVersion: preSelectedVersion }}
                versionEnabled={true}
                handleCheckboxChange={handleSelectedItemChanged} selected={true}/>}

        </Stack>
    );
};
