import styled from 'styled-components';
import Stack from 'common/design-system/components-v2/Stack';

const Wrapper = styled(Stack)`
    width: max-content;
`;

const DateFilterStyles = {
    Wrapper,
};

export default DateFilterStyles;
