import * as yup from 'yup';
import { SchemaOf } from 'yup';
import { getValidationErrors } from 'common/components/Forms/FormValidations';
interface ValidationParams {
    yupValidationObject: { [key: string]: SchemaOf<any> };
    payload: any;
}
const useCustomValidation = ({ yupValidationObject, payload }: ValidationParams) => {
    try {
        yup.object().shape(yupValidationObject).validateSync(payload, { abortEarly: false });
        return { valid: true };
    } catch (error: any) {
        return { valid: false, errors: getValidationErrors(error) };
    }
};

export default useCustomValidation;
