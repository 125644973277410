import * as React from 'react';
import { SVGProps, Ref, forwardRef } from 'react';
const SvgPackage = (
  props: SVGProps<SVGSVGElement>,
  ref: Ref<SVGSVGElement>
) => (
  <svg
    viewBox="0 0 16 18"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    ref={ref}
    {...props}
  >
    <path
      d="M15.5 5h.5a.5.5 0 0 0-.265-.441L15.5 5ZM8 17l-.235.441a.5.5 0 0 0 .47 0L8 17ZM.5 5l-.235-.441A.5.5 0 0 0 0 5h.5Zm.53 8.282.235-.44-.236.44Zm13.94 0-.235-.44.236.44ZM8.47 1.251l-.235.441.236-.441Zm-.94 0L7.293.81l.235.441ZM4.5 7.144l-.246-.436L4 6.852v.292h.5ZM4 9.5a.5.5 0 0 0 1 0H4Zm4.235-7.808 7.03 3.75.47-.883-7.03-3.75-.47.883ZM15 5v7.4h1V5h-1Zm-.265 7.841-6.97 3.718.47.882 6.97-3.717-.47-.883Zm-6.5 3.718-6.97-3.718-.47.883 6.97 3.717.47-.882ZM1 12.4V5H0v7.4h1ZM.735 5.441l7.03-3.749-.47-.882-7.03 3.749.47.882Zm-.47 0 7.5 4 .47-.882-7.5-4-.47.882Zm7.97 4 7.5-4-.47-.882-7.5 4 .47.882ZM7.5 9v8h1V9h-1Zm-6.235 3.841A.5.5 0 0 1 1 12.4H0a1.5 1.5 0 0 0 .794 1.323l.47-.882ZM15 12.4a.5.5 0 0 1-.265.441l.47.883A1.5 1.5 0 0 0 16 12.4h-1ZM8.706.81a1.5 1.5 0 0 0-1.412 0l.47.882a.5.5 0 0 1 .471 0l.47-.882ZM11.513 2.6l-7.26 4.107.493.87 7.26-4.107-.493-.87ZM4 7.144V9.5h1V7.144H4Z"
      fill="currentColor"
      fillOpacity={0.78}
    />
  </svg>
);
const ForwardRef = forwardRef(SvgPackage);
export default ForwardRef;
