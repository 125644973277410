import * as React from 'react';
import { SVGProps, Ref, forwardRef } from 'react';
const SvgExclude = (
  props: SVGProps<SVGSVGElement>,
  ref: Ref<SVGSVGElement>
) => (
  <svg
    viewBox="0 0 16 16"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    ref={ref}
    {...props}
  >
    <path
      d="M9.5 7.5h-8m8 0L7 5m2.5 2.5L7 10"
      stroke="currentColor"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <rect x={1.5} y={10.5} width={4} height={4} rx={1} stroke="currentColor" />
    <rect x={1.5} y={0.5} width={4} height={4} rx={1} stroke="currentColor" />
    <rect x={11.5} y={5.5} width={4} height={4} rx={1} stroke="currentColor" />
  </svg>
);
const ForwardRef = forwardRef(SvgExclude);
export default ForwardRef;
