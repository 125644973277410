import React from 'react';
import { IGenericWidgetDataProps } from '../../../module_interface/overview/Interface';
import TrendChangeSummary from '../TrendChangeSummary/TrandChangeSummary';
import AreaWidget from '../Area/AreaWidget';

const TrendLineWithChangeSummary: React.FC<IGenericWidgetDataProps> = (props) => {
    const data = props.data;
    return ( <div className='flex flex-col flex-1'>        
        { (data && data?.options && data.options?.changeSummaryOptions) &&
            <div className={'flex h-1/2'}><TrendChangeSummary data={{ options: data.options?.changeSummaryOptions }}/></div> }
        { (data && data?.items && data.options && data.options?.trendOptions) &&
            <div className={'flex h-1/2'}><AreaWidget data={{ items: data.items, options: data.options?.trendOptions }}/></div> }
    </div>
    );
};


export default TrendLineWithChangeSummary;