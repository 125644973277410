import React, { useState } from 'react';
import { FILTER_EVENTS } from '../../FilterPanel.consts';
import Accordion from '../../GeneralComponents/Accordion/Accordion';
import { Button, Modal, TextField } from '@dome9/berries/react-components';
import { IGSLFilterProps } from '../DefaultFilters.interface';
import './GSLFilter.scss';
import { CpRadioButton } from '@dome9/components/react/components';
import { isFilterBoxContent } from '../../filterUtils';
import { isEmptyObj } from '../../../../utils/helpFunctions';

const ACTIVE_TAB_INDEX = {
    BUILDER: 'builder',
    TEXT: 'text',
};

export const GSLFilter: React.FC<{ filterProps: IGSLFilterProps }> = ({ filterProps }) => {

    const { key, value, title, onEvent, displayType, defaultGsl } = filterProps;
    const [isOpenBuilder, openBuilder] = React.useState<boolean>(false);
    const [activeTab, setActiveTab] = useState(ACTIVE_TAB_INDEX.TEXT);
    const [gslFilterText, setGslFilterText] = useState(value || defaultGsl);
    const gslFilterRef: any = React.useRef();

    // TODO: populate dictionary from filterProps object inside schema view

    const handleGslTextChanged = (newGslQueryText: any) => {
        setGslFilterText(newGslQueryText);
        onEvent!({ action: FILTER_EVENTS.FILTER_CHANGED, filterKey: key, payload: newGslQueryText });
    };

    const handleOpenGslBuilder = () => {
        openBuilder(!isOpenBuilder);
    };

    const onClose = () => {
        openBuilder(false);
    };

    const handleModalClick = (event: any) => {
        // stop the event propagation when user clicks on modal as we have to keep it open until user click on close/save or outside the modal
        event.stopPropagation();
    };

    const discardChanges = () => {
        // TODO: show confirmation message to discard changes
        openBuilder(false);
    };

    const save = () => {
        // TODO: save changes and replicate the correct gsl value
        openBuilder(false);
        handleGslTextChanged(gslFilterRef?.current?.value);
    };

    const selectTab = (tab: string) => {
        if (tab === ACTIVE_TAB_INDEX.TEXT) {
            selectTextTab();
        } else {
            selectBuilderTab();
        }
    };

    const selectTextTab = () => {
        // TODO: convert existing configured GSL in builder view to GSL text/query
        setActiveTab(ACTIVE_TAB_INDEX.TEXT);
    };

    function selectBuilderTab() {
        if (isEmptyObj(gslFilterRef)) {
            setActiveTab(ACTIVE_TAB_INDEX.BUILDER);
        } else {
            // TODO : show confirmation modal when switched to view
            setActiveTab(ACTIVE_TAB_INDEX.BUILDER);
        }
    }

    const handleTextKeyPress = (event: any) => {
        if(event.key === 'Enter'){
            save();
        }
    };

    const clear = () => {
        handleGslTextChanged('');
    };

    const renderGslEditor = (integrated = false) => {
        return (
            <div className="flex">
                <TextField
                    className={`text-field flex flex-1 ${integrated ? 'text-field--integrated' : ''}`}
                    data-aid='gsl-text-filter'
                    value={gslFilterText}
                    onChange={(event) => handleGslTextChanged(event?.target?.value)}
                    placeholder='input your GSL query here'
                    onClear={clear}
                    allowClear={true}
                />
                <Button
                    variant="default"
                    onClick={handleOpenGslBuilder}
                    icon="edit"
                />
                {isOpenBuilder &&
                    <div onClick={handleModalClick}>
                        <Modal.ModalDialog
                            isOpen={isOpenBuilder}
                            width='lg'
                            onRequestClose={onClose}
                            className="gsl-builder-modal-container">
                            <Modal.ModalHeader title="GSL EDITOR" onClose={onClose}/>
                            <Modal.ModalContent>
                                <div className="flex">
                                    <div className="details-second-row">
                                        <div className="logic-mode-selection">
                                            <CpRadioButton
                                                name={'gslViewType'}
                                                value={'Builder'}
                                                // TODO: disabled for now, remove this when GSL builder is implemented
                                                disabled={true}
                                                onToggleRadioButton={() => selectTab(ACTIVE_TAB_INDEX.BUILDER)}
                                                checked={activeTab === ACTIVE_TAB_INDEX.BUILDER}>
                                                Builder
                                            </CpRadioButton>
                                            <CpRadioButton
                                                name={'gslViewType'}
                                                value={'Text'}
                                                onToggleRadioButton={() => selectTab(ACTIVE_TAB_INDEX.TEXT)}
                                                checked={activeTab === ACTIVE_TAB_INDEX.TEXT}>
                                                Text
                                            </CpRadioButton>
                                        </div>
                                        <div className="gsl-logic-container">
                                            {activeTab === ACTIVE_TAB_INDEX.TEXT &&
                                                <div className="gsl-input-text-mode">
                                                    <textarea className="fl-search"
                                                        autoComplete="off"
                                                        autoCorrect="off"
                                                        autoCapitalize="off"
                                                        spellCheck="false"
                                                        onKeyPress={handleTextKeyPress}
                                                        defaultValue={gslFilterText}
                                                        ref={gslFilterRef}
                                                        placeholder="Input your GSL query here">
                                                    </textarea>
                                                </div>
                                            }
                                            {activeTab === ACTIVE_TAB_INDEX.BUILDER &&
                                                <div className="rule-builder-container">
                                                    <div className="rule-builder-body">
                                                        <div className="header-section">Header Section</div>
                                                        <div className="content-container">Content Container</div>
                                                    </div>
                                                </div>
                                            }
                                        </div>
                                    </div>
                                    <div className="json">
                                        {/*// TODO: add schema json viewer component here*/}
                                        Schema
                                    </div>
                                </div>
                            </Modal.ModalContent>
                            <Modal.ModalFooter>
                                <div className="modal__footer-actions">
                                    <Button
                                        onClick={discardChanges}>
                                        Cancel
                                    </Button>
                                    <Button
                                        onClick={save}>
                                        Save
                                    </Button>
                                </div>
                            </Modal.ModalFooter>
                        </Modal.ModalDialog>
                    </div>
                }
            </div>
        );
    };

    const renderDropDownButtonView = () => {
        return (
            <Accordion
                title={title}
                content={renderGslEditor()}
            />
        );
    };

    return <>{isFilterBoxContent(displayType) ? renderDropDownButtonView() : renderGslEditor()}</>;
};

export default GSLFilter;
