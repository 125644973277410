import { lightPallete } from '../design-system/theme/colors';
import { ColorLightness, Colors } from '@dome9/berries/react-components';
import { SortOrder } from '../interface/general';
import { getDescendingLevels, getLevelInfo, getLevels, IBaseLevelInfo } from './levels';
import { SelectOption } from '../design-system/components-v2/SelectV2/Select.types';

export interface IFindingSeverityIconColors {
    color: Colors;
    colorLightness?: ColorLightness;
}

export enum FindingSeverityEnum {
    critical = 'critical',
    high = 'high',
    medium = 'medium',
    low = 'low',
    informational = 'informational',
    unknown = 'unknown',
}


type FindingSeverityCountersType = {
    [key in FindingSeverityEnum]? : number
}

export interface IFindingSeverityCounters extends FindingSeverityCountersType {
    informational: number;
    low: number;
    medium: number;
    high: number;
    critical: number;
}

export enum FindingSeverityServerEnum {
    Critical = 'Critical',
    High = 'High',
    Medium = 'Medium',
    Low = 'Low',
    Informational = 'Informational',
    Unknown = 'Unknown',
}

export interface IFindingSeverityInfo extends IBaseLevelInfo<FindingSeverityEnum> {
    serverKey: FindingSeverityServerEnum;
    iconColors: IFindingSeverityIconColors;
}

export const FindingSeveritiesMap: { [key in FindingSeverityEnum]: IFindingSeverityInfo } = {
    unknown: {
        key: FindingSeverityEnum.unknown,
        level: -1,
        displayText: 'Unknown',
        serverKey: FindingSeverityServerEnum.Unknown,
        color: lightPallete.severity.unknownFg,
        context: 'default',
        bg: lightPallete.severity.unknownBg,
        iconColors: {
            color: 'undefined',
            colorLightness: 'default',
        },
        iconProps: {
            name: 'severityUnknown',
        },
    },
    informational: {
        key: FindingSeverityEnum.informational,
        level: 0,
        displayText: 'Informational',
        serverKey: FindingSeverityServerEnum.Informational,
        color: lightPallete.severity.informationalFg,
        context: 'info',
        bg: lightPallete.severity.informationalBg,
        iconColors: {
            color: 'info',
            colorLightness: 'light'
        },
        iconProps: {
            name: 'severityInfo',
        },
    },
    low: {
        key: FindingSeverityEnum.low,
        level: 1,
        displayText: 'Low',
        serverKey: FindingSeverityServerEnum.Low,
        color: lightPallete.severity.lowFg,
        context: 'low',
        bg: lightPallete.severity.lowBg,
        iconColors: {
            color: 'warning',
            colorLightness: 'lighter'
        },
        iconProps: {
            name: 'severityLow',
        },
    },
    medium: {
        key: FindingSeverityEnum.medium,
        level: 2,
        displayText: 'Medium',
        serverKey: FindingSeverityServerEnum.Medium,
        color: lightPallete.severity.mediumFg,
        context: 'medium',
        bg: lightPallete.severity.mediumBg,
        iconColors: {
            color: 'warning',
        },
        iconProps: {
            name: 'severityMedium',
        }
    },
    high: {
        key: FindingSeverityEnum.high,
        level: 3,
        displayText: 'High',
        serverKey: FindingSeverityServerEnum.High,
        color: lightPallete.severity.highFg,
        context: 'high',
        bg: lightPallete.severity.highBg,
        iconColors: {
            color: 'danger',
            colorLightness: 'dark'
        },
        iconProps: {
            name: 'severityHigh',
        },
    },
    critical: {
        key: FindingSeverityEnum.critical,
        level: 4,
        displayText: 'Critical',
        serverKey: FindingSeverityServerEnum.Critical,
        color: lightPallete.severity.criticalFg,
        context: 'critical',
        bg: lightPallete.severity.criticalBg,
        iconColors: {
            color: 'danger',
            colorLightness: 'darkest'
        },
        iconProps: {
            name: 'severityCritical',
        },
    },
};

const getAllSeverities = (): IFindingSeverityInfo[] => {
    return Object.values(FindingSeveritiesMap);
};

const getDescendingSeverities = (): IFindingSeverityInfo[] => {
    return getDescendingLevels<FindingSeverityEnum>(getAllSeverities()) as IFindingSeverityInfo[];
};

export const getHighestFindingSeverityInfo = (counters?: IFindingSeverityCounters): IFindingSeverityInfo | undefined => {
    if (!counters) {
        return undefined;
    }
    return getDescendingSeverities().find(info => (counters[info.key] ?? 0) > 0);
};

export const getFindingSeverityInfo = (key: FindingSeverityEnum | string | number): IFindingSeverityInfo | undefined => {
    return getLevelInfo<FindingSeverityEnum>(getAllSeverities(), key) as IFindingSeverityInfo | undefined;
};

export const getSafeFindingSeverityInfo = (key: string | number): IFindingSeverityInfo => {
    return getFindingSeverityInfo(key) || FindingSeveritiesMap.unknown;
};

export const getFindingSeverities = (order?: SortOrder, lowestKey?: FindingSeverityEnum): IFindingSeverityInfo[] => {
    return getLevels<FindingSeverityEnum>(getAllSeverities(), order, lowestKey) as IFindingSeverityInfo[];
};

export const getSeveritiesSelectOptions = (): SelectOption[] => {
    return getDescendingSeverities()
        .filter((severity: IFindingSeverityInfo) => severity.serverKey !== FindingSeverityServerEnum.Unknown)
        .map((severityInfo: IFindingSeverityInfo) => {
            return {
                value: severityInfo.serverKey,
                label: severityInfo.displayText,
                labelProps: {
                    leadingIconProps: severityInfo.iconProps,
                }
            };
        });
};
