import { globalAddinContainer } from 'common/extensibility/AddinContainer';
import { APPLICATION_REDUCERS } from 'common/extensibility/WellKnownPaths';
import { IApplicationReducer } from 'common/interface/redux';
import { ICloudBot } from './CloudBots.interface';
import { Vendors } from '../../../consts/vendors';

const PATH_CLOUD_BOTS_DICTIONARY = 'cloud-bots-dictionary';

export const CloudBotsRegistry = {
    addCloudBots: (cloudBots: ICloudBot[]) => {
        globalAddinContainer.addMap<ICloudBot>(PATH_CLOUD_BOTS_DICTIONARY, cloudBots);
    },

    getCloudBots: (): ICloudBot[] => {
        return globalAddinContainer.get<ICloudBot>(PATH_CLOUD_BOTS_DICTIONARY);
    },

    getCloudBotById: (id: string): ICloudBot | undefined => {
        return globalAddinContainer.getById<ICloudBot>(PATH_CLOUD_BOTS_DICTIONARY, id);
    },

    getCloudBotsByPlatform: (platform: Vendors): ICloudBot[] => {
        const allCloudBots: ICloudBot[] = globalAddinContainer.get<ICloudBot>(PATH_CLOUD_BOTS_DICTIONARY);
        return allCloudBots.filter((cloudBot: ICloudBot) => cloudBot.platform.includes(platform));
    },

    addReducers: (reducers: IApplicationReducer[], idProperty: string) => {
        globalAddinContainer.addMap<IApplicationReducer>(APPLICATION_REDUCERS, reducers, idProperty);
    }
};
