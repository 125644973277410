import React from 'react';
import { LinkProps } from './Link.types';
import LinkStyled from './Link.styled';
import CopiableLine from 'common/design-system/components-v2/CopiableLine/CopiableLine';
import IconButton from 'common/design-system/components-v2/IconButton/IconButton';
import { Button, Icon, Typography } from 'common/design-system/components-v2';

const Link = React.forwardRef<HTMLAnchorElement, LinkProps>((props, ref) => {
    const { internalUrl, externalUrl, showExternalIcon = false, copyText, children, dataAid, buttonProps, textProps, leadingIconProps } = props;

    React.useEffect(() => {
        if (!internalUrl && !externalUrl) {
            console.error('internalUrl or externalUrl is required.');
        }
        if (internalUrl && externalUrl) {
            console.warn('Both internalUrl and externalUrl are provided. Only internalUrl will be used.');
        }
    }, [internalUrl, externalUrl]);

    const Content = React.useMemo(() => {
        const iconItem: React.ReactNode | undefined = leadingIconProps ? <Icon { ...leadingIconProps } /> : undefined;
        const textItem: React.ReactNode | undefined = textProps ? <Typography color={buttonProps ? 'inverse' : 'normal'} { ...textProps } >{textProps.text}</Typography> : undefined;
        if (internalUrl) {
            return (
                <LinkStyled.StyledInnerA href={internalUrl} ref={ref} dataAid={dataAid}>
                    {iconItem}
                    {textItem}
                    {children}
                </LinkStyled.StyledInnerA>
            );
        }
        if (externalUrl) {
            return (
                <LinkStyled.StyledOuterA ref={ref} href={externalUrl} target='_blank' rel="noreferrer" data-aid={dataAid}>
                    {iconItem}
                    {textItem}
                    {children}
                    {showExternalIcon && <IconButton iconProps={{ name: 'externalLink', size: 10 }} size='small' />}
                </LinkStyled.StyledOuterA>
            );
        }
        return null;

    }, [leadingIconProps, textProps, buttonProps, showExternalIcon, internalUrl, externalUrl, ref, dataAid, children]);

    if (buttonProps) {
        return (
            <Button { ...buttonProps }>
                {Content}
            </Button>
        );
    }

    if (copyText) {
        return (
            <CopiableLine value={copyText}>
                {Content}
            </CopiableLine>
        );
    }

    return Content;
});

Link.displayName = 'Link';

export default Link;
