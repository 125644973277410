import React, { useCallback, useEffect, useMemo, useRef, useState } from 'react';
import {
    FilterTreeProps,
    IFilterTree,
    IFilterTreeCommonProps,
    IFilterTreeCondition,
    IFilterTreeItem,
    IFilterTreeNode,
} from '../FilterTree.interface';
import { CompoundFilterLogicalOperator } from '../CompoundFilter';
import {
    createDefaultCompoundFilter,
    findTreeConditionById,
    findTreeItemIndexInParent,
    findTreeNodeById, moveTreeItem,
} from '../FilterTree.utils';
import { FilterTreeNode } from './FilterTreeNode';
import { compoundFilterToTree, treeToCompoundFilter } from '../FilterTree.convertors';
import { cloneFilterTreeRoot, createTreeCondition, createTreeNode } from '../FilterTree.creators';
import {
    getErrorRefs,
    getValidCompoundFilterSubsetFromTree,
    hasErrorsInFilterTree,
    updateDeepErrorsInFilterTree,
    updateNodeErrorState,
} from '../FilterTree.errors';
import { FilterStyled } from './FilterTree.styled';
import { DndContext } from '@dnd-kit/core';
import { DragEndEvent } from '@dnd-kit/core/dist/types';

let notificationCounter = 1;

const FilterTree = React.forwardRef<HTMLDivElement, FilterTreeProps>((props, ref) => {
    const { compoundFilter = createDefaultCompoundFilter(), filterDefinitions, displayErrors, onFilterChange } = props;
    const [filterTree, setFilterTree] = useState<IFilterTree>(compoundFilterToTree(filterDefinitions, compoundFilter));

    const fireNotification = useCallback((filterTree: IFilterTree) => {
        onFilterChange && onFilterChange({
            filter: treeToCompoundFilter(filterTree),
            validFilterSubset: getValidCompoundFilterSubsetFromTree(filterTree, filterDefinitions),
            hasErrors: hasErrorsInFilterTree(filterTree),
            errorRefs: getErrorRefs(filterTree),
        });
    }, [filterDefinitions, onFilterChange]);
    const firedInitialNotificationRef = useRef<boolean>(false);

    useEffect(() => {
        if (!firedInitialNotificationRef.current) {
            firedInitialNotificationRef.current = true;
            fireNotification(filterTree);
        }
    });

    const onTreeRootChange = useCallback((clonedRoot: IFilterTreeNode) => {
        updateDeepErrorsInFilterTree(clonedRoot, filterDefinitions);
        const newFilterTree: IFilterTree = {
            ...filterTree,
            root: clonedRoot,
        };
        setFilterTree(newFilterTree);
        notificationCounter++;
        const counter = notificationCounter;
        setTimeout(() => {
            if (counter === notificationCounter) {
                fireNotification(newFilterTree);
            }
        }, 500);
    }, [filterDefinitions, filterTree, fireNotification]);

    const onConditionChange = useCallback((updatedCondition: IFilterTreeCondition) => {
        const { name, operator, values } = updatedCondition;
        const clonedRoot: IFilterTreeNode = cloneFilterTreeRoot(filterTree.root, filterDefinitions);
        const clonedCondition = findTreeConditionById(clonedRoot, updatedCondition.id);
        if (clonedCondition) {
            const indexInParent = findTreeItemIndexInParent(clonedCondition);
            if (indexInParent >= 0) {
                clonedCondition.name = name;
                clonedCondition.operator = operator;
                clonedCondition.values = values;
                clonedCondition.parentNode.childItems[indexInParent] = clonedCondition;
                onTreeRootChange(clonedRoot);
            }
        }
    }, [filterDefinitions, onTreeRootChange, filterTree.root]);


    const onItemMove = useCallback((movingItemId: string, targetItemId: string) => {
        const clonedRoot: IFilterTreeNode = cloneFilterTreeRoot(filterTree.root, filterDefinitions);
        const isMoved = moveTreeItem(clonedRoot, movingItemId, targetItemId);
        if (isMoved) {
            onTreeRootChange(clonedRoot);
        }
    }, [filterDefinitions, filterTree.root, onTreeRootChange]);

    const onLogicalOperatorChange = useCallback((origNode: IFilterTreeNode, operator: CompoundFilterLogicalOperator) => {
        const clonedRoot: IFilterTreeNode = cloneFilterTreeRoot(filterTree.root, filterDefinitions);
        const clonedNode: IFilterTreeNode | undefined = findTreeNodeById(clonedRoot, origNode.id);
        if (clonedNode) {
            clonedNode.logicalOperator = operator;
            updateNodeErrorState(clonedNode);
            onTreeRootChange(clonedRoot);
        }
    }, [filterDefinitions, onTreeRootChange, filterTree.root]);

    const onRemoveItem = useCallback((item: IFilterTreeItem) => {
        const indexInParent = findTreeItemIndexInParent(item);
        if (indexInParent >= 0) {
            const clonedRoot: IFilterTreeNode = cloneFilterTreeRoot(filterTree.root, filterDefinitions);
            const clonedParentNode: IFilterTreeNode | undefined = findTreeNodeById(clonedRoot, item.parentNode!.id);
            if (clonedParentNode) {
                clonedParentNode.childItems.splice(indexInParent, 1);
                updateNodeErrorState(clonedParentNode);
                onTreeRootChange(clonedRoot);
            }
        }
    }, [filterDefinitions, onTreeRootChange, filterTree.root]);

    const onAddCondition = useCallback((parentNode: IFilterTreeNode, insertionIndex: number) => {
        const clonedRoot: IFilterTreeNode = cloneFilterTreeRoot(filterTree.root, filterDefinitions);
        const clonedParentNode: IFilterTreeNode | undefined = findTreeNodeById(clonedRoot, parentNode.id);
        if (clonedParentNode) {
            createTreeCondition(clonedParentNode, insertionIndex);
            updateNodeErrorState(clonedParentNode);
            onTreeRootChange(clonedRoot);
        }
    }, [filterDefinitions, onTreeRootChange, filterTree.root]);

    const onAddNode = useCallback((parentNode: IFilterTreeNode, insertionIndex: number, logicalOperator: CompoundFilterLogicalOperator) => {
        const clonedRoot: IFilterTreeNode = cloneFilterTreeRoot(filterTree.root, filterDefinitions);
        const clonedParentNode: IFilterTreeNode | undefined = findTreeNodeById(clonedRoot, parentNode.id);
        if (clonedParentNode) {
            createTreeNode(logicalOperator, clonedParentNode, insertionIndex);
            onTreeRootChange(clonedRoot);
        }
    }, [filterDefinitions, onTreeRootChange, filterTree.root]);

    const onAddChildCondition = useCallback((parentNode: IFilterTreeNode) => {
        const clonedRoot: IFilterTreeNode = cloneFilterTreeRoot(filterTree.root, filterDefinitions);
        const clonedParentNode: IFilterTreeNode | undefined = findTreeNodeById(clonedRoot, parentNode.id);
        if (clonedParentNode) {
            createTreeCondition(clonedParentNode);
            onTreeRootChange(clonedRoot);
        }
    }, [filterDefinitions, onTreeRootChange, filterTree.root]);

    const onAddChildNode = useCallback((parentNode: IFilterTreeNode, logicalOperator: CompoundFilterLogicalOperator) => {
        const clonedRoot: IFilterTreeNode = cloneFilterTreeRoot(filterTree.root, filterDefinitions);
        const clonedParentNode: IFilterTreeNode | undefined = findTreeNodeById(clonedRoot, parentNode.id);
        if (clonedParentNode) {
            createTreeNode(logicalOperator, clonedParentNode);
            onTreeRootChange(clonedRoot);
        }
    }, [filterDefinitions, onTreeRootChange, filterTree.root]);

    const clearFilter = useCallback(() => {
        const clonedRoot: IFilterTreeNode = cloneFilterTreeRoot(filterTree.root, filterDefinitions);
        clonedRoot.childItems = [];
        onTreeRootChange(clonedRoot);
    }, [filterDefinitions, onTreeRootChange, filterTree.root]);

    const filterProps: IFilterTreeCommonProps = useMemo(() => {
        return {
            filterDefinitions,
            displayErrors,
            api: {
                onConditionChange,
                onLogicalOperatorChange,
                onRemoveItem,
                onAddCondition,
                onAddNode,
                onAddChildCondition,
                onAddChildNode,
                clearFilter,
            },
        };
    }, [clearFilter, displayErrors, filterDefinitions, onAddChildCondition, onAddChildNode, onAddCondition, onAddNode, onConditionChange, onLogicalOperatorChange, onRemoveItem]);


    const onDragEnd = useCallback((event: DragEndEvent) => {
        const dropId = String(event.over?.id);
        if (!dropId) {
            return;
        }

        const dragId = String(event.active.id);
        if (!dragId) {
            return;
        }

        onItemMove(dragId, dropId);
    }, [onItemMove]);

    return (
        <DndContext onDragEnd={onDragEnd}>
            <FilterStyled.TopDiv ref={ref}>
                <FilterTreeNode node={filterTree.root} filterProps={filterProps} />
            </FilterStyled.TopDiv>
        </DndContext>
    );
});
FilterTree.displayName = 'FilterTree';

export default FilterTree;