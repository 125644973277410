import * as React from 'react';
import { SVGProps, Ref, forwardRef } from 'react';
const SvgSidebar = (
  props: SVGProps<SVGSVGElement>,
  ref: Ref<SVGSVGElement>
) => (
  <svg
    viewBox="0 0 16 16"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    ref={ref}
    {...props}
  >
    <path
      d="M.5 3.5a1 1 0 0 1 1-1h13a1 1 0 0 1 1 1v9a1 1 0 0 1-1 1h-13a1 1 0 0 1-1-1v-9Z"
      stroke="currentColor"
    />
    <path
      d="M8.5 2.5v11m2-9h3m-3 2h3m-3 2h3m-3 2h3"
      stroke="currentColor"
      strokeLinecap="round"
    />
  </svg>
);
const ForwardRef = forwardRef(SvgSidebar);
export default ForwardRef;
