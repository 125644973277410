import * as React from 'react';
import { SVGProps, Ref, forwardRef } from 'react';
const SvgUnlink = (props: SVGProps<SVGSVGElement>, ref: Ref<SVGSVGElement>) => (
  <svg
    viewBox="0 0 16 16"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    ref={ref}
    {...props}
  >
    <path
      d="m7 5 3-3a2.828 2.828 0 1 1 4 4l-3 3"
      stroke="currentColor"
      strokeLinecap="round"
    />
    <path
      d="m12.5 12.5 2 2m-4-1.5v2.5m2-5h3M1.5 1.5l2 2m2-2.5v2.5m-5 2h3"
      stroke="currentColor"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="m9 11-3.5 3.5a2.828 2.828 0 1 1-4-4L5 7"
      stroke="currentColor"
      strokeLinecap="round"
    />
  </svg>
);
const ForwardRef = forwardRef(SvgUnlink);
export default ForwardRef;
