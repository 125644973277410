import React from 'react';
import DataVolume from 'common/components/DataVolume/DataVolume';
import { FindingSeveritiesMap, FindingSeverityEnum } from 'common/consts/FindingSeverity';
import { Stack } from 'common/design-system/components-v2';
import styled from 'styled-components';

export interface SeverityChipProps {
    severity: FindingSeverityEnum;
}

const SeverityChipWrapper = styled(Stack)<{ background: string }>`
    max-width: fit-content;
    border-radius: ${({ theme }) => `${theme.border.radius(4)}${theme.units}`};
    display: 'flex';
    justify-content: center;
    align-items: center;
    box-shadow: ${({ theme }) => theme.shadows[0]};
    outline: none;
    background: ${({ background }) => background};
    padding: 4px 8px;
`;

export const SeverityChip: React.FC<SeverityChipProps> = ({ severity }) => {
    const { displayText, color, level, bg } = FindingSeveritiesMap[severity];
    return (
        <SeverityChipWrapper background={bg} alignItems='center' direction='row' justifyContent='center'>
            <DataVolume dataInfo={{ text: '', color, zeroBasedIndex: level, maxLevel: 5 }} />
            <div style={{ color }}>{displayText}</div>
        </SeverityChipWrapper>
    );
};
