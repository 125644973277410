import styled, { css } from 'styled-components';
import { Button, Stack, Typography } from 'common/design-system/components-v2';
import { IButtonProps } from 'common/design-system/components-v2/Button/Button.types';

const Wrapper = styled.div<{ minHeight?: string; }>`
    height: 100%;
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    min-height: ${({ minHeight }) => minHeight || '200px' };
    max-width: 720px;
`;

const LinksWrapper = styled(Stack)`
    width: 90%;
    padding-top: ${({ theme }) => `${theme.spacing(2)}${theme.units}`};
    border-top: ${({ theme }) => `${theme.border.width.standard}${theme.units} solid ${theme.palette.border.light}`};
`;

const TypographyWithStlyes = styled(Typography)`
    white-space: pre;
    text-align: center;
`;

const LinkButton = styled(Button)<IButtonProps & { asLink: boolean; }>`
    display: flex;
    gap: ${({ theme }) => `${theme.spacing(1)}${theme.units}`};
    white-space: nowrap;
    
    ${({ asLink }) => asLink && css`
        color: ${({ theme }) => theme.palette.status.info};
        :visited, :hover {
            --opacity: 1 !important;
            background-color: transparent !important;
            color: ${({ theme }) => theme.palette.status.info} !important;
        }
    `};
`;

const EmptyStateStyles = {
    Wrapper,
    LinksWrapper,
    LinkButton,
    TypographyWithStlyes
};

export default EmptyStateStyles;