import * as React from 'react';
import { SVGProps, Ref, forwardRef } from 'react';
const SvgListView = (
  props: SVGProps<SVGSVGElement>,
  ref: Ref<SVGSVGElement>
) => (
  <svg
    viewBox="0 0 16 16"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    ref={ref}
    {...props}
  >
    <path
      d="M5.5 13.5h10M5.5 8.5h10M5.5 3.5h10"
      stroke="currentColor"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <circle cx={2.5} cy={3.5} r={1} stroke="currentColor" />
    <circle cx={2.5} cy={8.5} r={1} stroke="currentColor" />
    <circle cx={2.5} cy={13.5} r={1} stroke="currentColor" />
  </svg>
);
const ForwardRef = forwardRef(SvgListView);
export default ForwardRef;
