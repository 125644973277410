import * as React from 'react';
import { SVGProps, Ref, forwardRef } from 'react';
const SvgGroupExclusion = (
  props: SVGProps<SVGSVGElement>,
  ref: Ref<SVGSVGElement>
) => (
  <svg
    viewBox="0 0 16 16"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    ref={ref}
    {...props}
  >
    <circle cx={4.5} cy={4.5} r={2} stroke="currentColor" />
    <circle cx={4.5} cy={11.5} r={2} stroke="currentColor" />
    <circle cx={11.5} cy={11.5} r={2} stroke="currentColor" />
    <path
      d="M4.5.5h-3a1 1 0 0 0-1 1v3M11.5.5h3a1 1 0 0 1 1 1v3M15.5 11.5v3a1 1 0 0 1-1 1h-3M4.5 15.5h-3a1 1 0 0 1-1-1v-3M9.5 2.51V2.5"
      stroke="currentColor"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <circle cx={11.5} cy={4.5} r={0.5} stroke="currentColor" />
    <path
      d="M13.5 2.51V2.5M9.5 6.51V6.5M13.5 6.51V6.5"
      stroke="currentColor"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </svg>
);
const ForwardRef = forwardRef(SvgGroupExclusion);
export default ForwardRef;
