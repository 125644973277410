import React from 'react';
import { IWelcomeItem } from './WelcomePage.interface';
import './WelcomePage.scss';
import { Button, Card, Media } from '@dome9/berries/react-components';

export const WelcomePageCard: React.FC<IWelcomeItem> = (props: IWelcomeItem) => {
    const { title, subtitle, icon, iconColor, cardButton, onClick, badges, linkTo, cardBody, cardColor } = props;

    return (
        <>
            <Card
                status={cardColor || 'primary'}
                header={
                    <Media
                        title={title}
                        subtitle={subtitle}
                        icon={icon}
                        iconColor={iconColor}></Media>
                }
                className={`h-full ${onClick || linkTo ? 'hover:cursor-pointer hover:border-info-strongest' : ''}`}
                onClick={() => {
                    if (onClick) {
                        onClick();
                    }
                }}
            >
                <>
                    {cardBody}
                    {cardButton && (
                        <div className='grid place-content-end pt-5'>
                            <Button
                                className='btn--respond-to-media-query'
                                icon={cardButton.icon}
                                label={cardButton.text}
                                onClick={(e: React.MouseEvent) => { e.stopPropagation(); cardButton.onClick(); }}
                            />
                        </div>
                    )}
                </>
            </Card>
            <div className='absolute top-2 right-2'>{badges}</div>
        </>
    );
};

export default WelcomePageCard;
