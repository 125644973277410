import styled from 'styled-components';

const TopDiv = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  height: 100%;
`;


export const TableStyled = {
    TopDiv,
};
