import { getService } from 'common/extensibility/AddinContainer';
import { REMEDIATION_SERVICE_ID } from './Remediation.const';
import { ICreateRemediationRequest, ICreateRemediationResponse } from './Remediation.interface';

export interface IRemediationService {
    createRemediation(data: ICreateRemediationRequest): Promise<ICreateRemediationResponse | undefined>;
}

export function getRemediationService(): IRemediationService {
    return getService<IRemediationService>(REMEDIATION_SERVICE_ID);
}
