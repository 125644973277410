import { Chip } from '@dome9/berries/react-components';
import React from 'react';

const TagLabel: React.FC<any> = ({ tagName,title,content,onClick, onDelete }) => <Chip
    className='self-center'
    title={title}
    onClick={onClick}
    label={content.length ? '' + content.map((val:string) => val) : ''}
    onDelete={() => onDelete(tagName)}
    key={tagName}
    deletable />;


export default TagLabel;
