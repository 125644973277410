import { FC, ReactNode, useCallback, useMemo } from 'react';
import { FormStyled } from '../../CustomForm.styled';
import { Button, IconButton, Input, Stack } from '../../../../../design-system/components-v2';
import { Tag } from '../../../../../components/Tags/Tags.types';
import { useTranslation } from 'react-i18next';
import _ from 'lodash';
import { TAG_KEY, TAG_VALUE } from '../../CustomForm.consts';
import { FieldLabelComponent } from '../helperComponents/FieldLabelComponent';
import { ErrorComponent } from '../helperComponents/ErrorComponent';
import { convertTagsToValues, convertValuesToTags } from '../../CustomForm.values';
import { IFullErrorInfo, IOnFieldChanged, ITagsItem } from '../../CustomForm.interface';

export interface ITagsItemComponentProps {
    item: ITagsItem;
    onFieldChanged: IOnFieldChanged;
    showErrors: boolean;
    errorInfo?: IFullErrorInfo;
    skipTitle?: boolean;
}

export const TagsItemComponent: FC<ITagsItemComponentProps> = ({ item, onFieldChanged, showErrors, errorInfo, skipTitle }) => {
    const { state } = item;
    const tags: Tag[] = useMemo(() => convertValuesToTags(state.value), [state.value]);
    const tagErrors: IFullErrorInfo[] | undefined = useMemo(() => errorInfo?.subErrors, [errorInfo?.subErrors]);
    const disabled: boolean = useMemo(() => !!(state.disabled || state.readonly), [state.disabled, state.readonly]);
    const { t } = useTranslation();

    const onChangeTags = useCallback((tags: Tag[]) => {
        onFieldChanged({ ...item, state: { ...state, value: convertTagsToValues(tags) } });
    }, [item, onFieldChanged, state]);

    const getTag = useCallback((index: number): Tag | undefined => {
        if (index > (tags.length - 1)) {
            return undefined;
        }
        return tags[index];
    }, [tags]);


    const getTagError = useCallback((index: number): IFullErrorInfo | undefined => {
        if (!tagErrors) {
            return undefined;
        }
        return tagErrors.find(errorInfo => errorInfo.name === String(index));
    }, [tagErrors]);

    const getTagInnerError = useCallback((index: number, name: string): IFullErrorInfo | undefined => {
        const tagError: IFullErrorInfo | undefined = getTagError(index);
        if (!tagError?.subErrors) {
            return undefined;
        }
        return tagError.subErrors.find(errorInfo => errorInfo.name === name);
    }, [getTagError]);


    const onTagChange = useCallback((str: string, index: number, isKey: boolean) => {
        if (index > (tags.length - 1)) {
            return;
        }

        const dupTags: Tag[] = _.cloneDeep(tags);
        const tag: Tag = dupTags[index];
        if (isKey) {
            tag.key = str;
        } else {
            tag.value = str;
        }
        onChangeTags(dupTags);
    }, [onChangeTags, tags]);

    const onTagAdd = useCallback(() => {
        const dupTags: Tag[] = _.cloneDeep(tags);
        dupTags.push({
            key: '',
            value: ''
        });
        onChangeTags(dupTags);
    }, [onChangeTags, tags]);

    const onTagRemove = useCallback((index: number) => {
        if (index > (tags.length - 1)) {
            return;
        }
        const dupTags: Tag[] = _.cloneDeep(tags);
        dupTags.splice(index, 1);
        onChangeTags(dupTags);
    }, [onChangeTags, tags]);

    const createTagRow = useCallback((index: number): ReactNode => {
        const tag: Tag | undefined = getTag(index);
        if (!tag) {
            return;
        }
        const keyError: IFullErrorInfo | undefined = getTagInnerError(index, TAG_KEY);
        const valueError: IFullErrorInfo | undefined = getTagInnerError(index, TAG_VALUE);

        return (
            <Stack direction={'row'} fullWidth alignItems={'flex-start'} spacing={2} >
                <FormStyled.TagInputContainer direction={'column'} >
                    <Input
                        fullWidth
                        placeholder={t('CUSTOM_FORM.COMPONENTS.TAG_KEY_PLACEHOLDER')}
                        onChange={(event) => onTagChange(event.target.value, index, true)}
                        value={tag.key}
                        disabled={disabled}
                        isError={!!(showErrors && keyError)}
                    />
                    <ErrorComponent showErrors={showErrors} errorInfo={keyError} />
                </FormStyled.TagInputContainer>
                <FormStyled.TagInputContainer direction={'column'} >
                    <Input
                        fullWidth
                        placeholder={t('CUSTOM_FORM.COMPONENTS.TAG_VALUE_PLACEHOLDER')}
                        onChange={(event) => onTagChange(event.target.value, index, false)}
                        value={tag.value}
                        disabled={disabled}
                        isError={!!(showErrors && valueError)}
                    />
                    <ErrorComponent showErrors={showErrors} errorInfo={valueError} />
                </FormStyled.TagInputContainer>
                <IconButton iconProps={{ name: 'remove', size: 16 }} disabled={disabled} style={{ flexGrow: 0, paddingTop: '7px' }} size='small' onClick={() => onTagRemove(index)} />
            </Stack>
        );
    }, [disabled, getTag, getTagInnerError, onTagChange, onTagRemove, showErrors, t]);

    return (
        <FormStyled.TopItem widthInfo={state.widthInfo}>
            {!skipTitle && <FieldLabelComponent item={item} />}
            { (tags.length > 0) &&
                <Stack direction={'column'} spacing={3} fullWidth padding={[2, 0, 0, 0]}>
                    <Stack direction={'row'} fullWidth alignItems={'flex-start'} spacing={2}>
                        <FormStyled.TagTitle required text={t('CUSTOM_FORM.COMPONENTS.TAG_KEY_HEADER')} />
                        <FormStyled.TagTitle required text={t('CUSTOM_FORM.COMPONENTS.TAG_VALUE_HEADER')} />
                        <FormStyled.TagTitleFiller />
                    </Stack>
                    { tags.map((tag, index) => createTagRow(index)) }
                </Stack>
            }
            <Button variant={'contained'} disabled={disabled} iconProps={{ name: 'plus' }} onClick={onTagAdd}></Button>
        </FormStyled.TopItem>
    );
};

