import * as React from 'react';
import { SVGProps, Ref, forwardRef } from 'react';
const SvgShiftleft = (
  props: SVGProps<SVGSVGElement>,
  ref: Ref<SVGSVGElement>
) => (
  <svg
    viewBox="0 0 16 16"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    ref={ref}
    {...props}
  >
    <path
      d="M3.712 1.613a7 7 0 0 1 10.579 7.585m-3.003 4.189A7 7 0 0 1 .709 5.802M4.5 7.5h6m-6 0 2-2m-2 2 2 2M4 2.5a1.5 1.5 0 1 1-3 0 1.5 1.5 0 0 1 3 0Zm10 10a1.5 1.5 0 1 1-3 0 1.5 1.5 0 0 1 3 0Z"
      stroke="currentColor"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </svg>
);
const ForwardRef = forwardRef(SvgShiftleft);
export default ForwardRef;
