import React, { useEffect, useState } from 'react';
import { FILTER_EVENTS } from '../../FilterPanel.consts';
import { Button } from 'common/design-system/components-v2';

export const AccordionComponent: React.FC<{
    title: any;
    content: any;
    showContent?: boolean;
    onOpen?: Function;
    optionsList?: JSX.Element[];
}> = ({ title, content, showContent, onOpen, optionsList }) => {
    const [isOpen, setIsOpen] = useState(showContent || false);
    const contentRef = React.useRef(null);
    useEffect(() => {
        showContent !== undefined && setIsOpen(showContent);
    }, [showContent]);

    const renderAccordionOptionsList = () => {
        return optionsList?.map((accordionOption) => {
            return accordionOption;
        });
    };


    return (
        <div ref={contentRef} className='' data-aid={title.replace(/\s+/g, '-').toLowerCase()}>
            <div className='flex hover:bg-hover pl-7 active:bg-active cursor-default p-2'>
                <div
                    className='flex items-center flex-1 font-medium gap-6'
                    onClick={() => {
                        setIsOpen(!isOpen);
                        onOpen && onOpen({ action: FILTER_EVENTS.BOX_FILTER_SECTION_OPENED, elementRef: contentRef });
                    }}>
                    <Button iconProps={{ name: !isOpen ? 'chevronRight' : 'chevronDown', size: 12 }} size='medium' iconButton variant='text' />
                    <div className='flex-1 '>{title}</div>
                </div>
                {isOpen && content && optionsList && renderAccordionOptionsList()}
            </div>

            {isOpen && <div className='pl-9 py-8 pr-8 bg-aside border-t border-b'>{content}</div>}
        </div>
    );
};

export default AccordionComponent;
