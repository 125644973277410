import { AssetUrlParams, UrlFuncResult } from './common.assets';
import { getRegionByNumber, Vendors } from '../consts/vendors';
import { findKey } from '../utils/helpFunctions';
import { GenericObject } from '../interface/general';
import { isEmpty } from 'lodash';

const getRegionNameMapping = () => {
    const regionName = sessionStorage.getItem('regionNames');
    if (regionName) {
        return JSON.parse(regionName);
    }
    return;
};

const resolveAwsRegion = (region: any) => {
    if (!region) {
        return;
    }
    region = region.replace(/-/g, '_');
    return findKey(getRegionNameMapping(), (regionName: any) => regionName === region) || region;
};

export const securityGroupUrl = ({ platform, cloudAccountId, entityType, entityId }: AssetUrlParams) => {
    let url = '';
    let query = {};
    if (platform === Vendors.ALIBABA) {
        url = '/protected-asset/generic';
        query = {
            cloudAccountId: cloudAccountId,
            assetType: entityType,
            assetId: entityId,
            platform: Vendors.ALIBABA,
        };
    } else if (entityId) {
        url = `/security-group/aws/${entityId}`;
    }

    return { url, query };
};

export const instanceUrl = ({ entityId, dome9Id, cloudAccountId, region, typeNumber }: AssetUrlParams) => {
    // In case there is no region, we need to extract it from the dome9Id
    let calculatedRegion: string | undefined;
    if (isEmpty(region)) {
        const regionNumber = dome9Id?.split('|')[3];
        calculatedRegion = getRegionByNumber(Number(regionNumber), Vendors.AWS)?.cloudRegionXID;
    } else {
        calculatedRegion = region;
    }
    return {
        url: cloudAccountId && entityId ? '/protected-asset/details' : '',
        query: cloudAccountId && entityId ? {
            cloudaccountid: cloudAccountId,
            instanceid: entityId,
            region: resolveAwsRegion(calculatedRegion),
            tabName: 'overview',
            type: typeNumber,
        } : {}
    };
};

export const detailsWithInstanceIdUrl = ({ dome9Id, typeNumber }: AssetUrlParams): UrlFuncResult => {
    if (dome9Id) {
        const url = '/protected-asset/details';
        const query: GenericObject<any> = {
            instanceid: dome9Id,
        };
        if (typeNumber !== undefined) {
            query.type = typeNumber;
        }
        return { url, query };
    }
    return { url: '', query: {} };

};

const detailsWithInstanceIdAndCloudAccountId = (params: AssetUrlParams): UrlFuncResult => {
    const { dome9Id, cloudAccountId } = params;
    const result = detailsWithInstanceIdUrl(params);
    if (cloudAccountId && dome9Id) {
        result!.query!.cloudAccountId = cloudAccountId;
    }
    return result;
};

export const elbUrl = (params: AssetUrlParams) => detailsWithInstanceIdAndCloudAccountId(params);

export const redshiftUrl = (params: AssetUrlParams) => {
    const { dome9Id } = params;
    const result = detailsWithInstanceIdUrl(params);
    if (dome9Id) {
        result!.query!.tabName = 'overview';
    }
    return result;
};

export const vmInstanceUrl = ({ dome9Id, entityId, cloudAccountId, typeNumber }: AssetUrlParams) => {
    let url = '';
    let query: GenericObject<any> = {};
    if (dome9Id) {
        url = '/protected-asset/details';
        query = {
            externalid: entityId,
            cloudaccountid: cloudAccountId,
            instanceid: dome9Id,
        };
        if (typeNumber !== undefined) {
            query.type = typeNumber;
        }
    }
    return { url, query };
};

export const kubernetesUrl = (params: AssetUrlParams) => {
    const { dome9Id, cloudAccountId, entityId } = params;
    const result = detailsWithInstanceIdUrl(params);
    if (dome9Id) {
        result!.query!.cloudaccountid = cloudAccountId;
        result!.query!.assetid = entityId;
    }
    result!.query!.tabName = 'overview';
    return result;
};

export const withPlatformUrl = ({
    asset,
    cloudAccountId,
    entityId,
    entityType,
    platform,
    typeNumber,
}: AssetUrlParams) => {
    let url = '';
    let query: GenericObject<any> = {};

    if (asset && asset.isGenericObject && entityId) {
        url = '/protected-asset/generic';
        query = {
            cloudAccountId: cloudAccountId,
            assetType: entityType,
            assetId: entityId,
            platform: platform?.toLowerCase(),
        };
        if (typeNumber !== undefined) {
            query.type = typeNumber;
        }
    }

    return { url, query };
};

export const getAssetUrlPreferDome9IdAsAssetId = (assetUrlParams: AssetUrlParams) => {
    const requestParams = getAssetUrl(assetUrlParams);

    if(!requestParams)
        return requestParams;

    const { url, query } = requestParams;
    query.assetId = assetUrlParams.dome9Id;
    return { url, query };
};

export const getAssetUrl = ({
    asset,
    entityId,
    cloudAccountId,
    dome9Id,
    region,
    tabName,
    typeNumber,
}: AssetUrlParams) => {
    if (entityId || dome9Id) {
        const url = `/protected-asset/${asset.isGenericObject ? 'generic' : 'details'}`;
        const query: GenericObject<any> = {
            cloudAccountId: cloudAccountId,
            assetType: asset.type,
            assetId: entityId,
            platform: asset.platform?.toLowerCase(),
        };
        if (!entityId) {//If you have entity id it should be enough to find it, if you don't you need the instance id and region
            query.instanceid = dome9Id;
            if (region) {
                query.region = region;
            }
        }

        if (tabName) {
            query.tabName = tabName;
        }
        if (typeNumber !== undefined) {
            query.type = typeNumber;
        }
        return { url, query };
    }
    return null;
};
