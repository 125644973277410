import { IRemediationExecutionInfo } from './CloudBots.interface';

export enum CloudBotParameterType {
    EMAIL = 'email',
    TEXT = 'text',
    NUMBER = 'number',
}

export const CloudBotKeys= {
    CUSTOM: 'custom',

    // AWS
    ACM_DELETE_CERTIFICATE: 'acm_delete_certificate',
    AMI_SET_TO_PRIVATE: 'ami_set_to_private',
    CLOUDTRAIL_ENABLE: 'cloudtrail_enable',
    CLOUDTRAIL_ENABLE_LOG_FILE_VALIDATION: 'cloudtrail_enable_log_file_validation',
    CLOUDTRAIL_SEND_TO_CLOUDWATCH: 'cloudtrail_send_to_cloudwatch',
    CLOUDWATCH_CREATE_METRIC_FILTER: 'cloudwatch_create_metric_filter',
    CONFIG_ENABLE: 'config_enable',
    EC2_ATTACH_INSTANCE_ROLE: 'ec2_attach_instance_role',
    EC2_ATTACH_SG: 'ec2_attach_sg',
    EC2_CREATE_SNAPSHOT: 'ec2_create_snapshot',
    EC2_RELEASE_EIPS: 'ec2_release_eips',
    EC2_QUARANTINE_INSTANCE: 'ec2_quarantine_instance',
    EC2_STOP_INSTANCE: 'ec2_stop_instance',
    EC2_TERMINATE_INSTANCE: 'ec2_terminate_instance',
    EC2_UPDATE_INSTANCE_ROLE: 'ec2_update_instance_role',
    ECS_SERVICE_ROLE_DETACH_INLINE_POLICY: 'ecs_service_role_detach_inline_policy',
    IAM_DETACH_POLICY: 'iam_detach_policy',
    IAM_GENERATE_CREDENTIAL_REPORT: 'iam_generate_credential_report',
    IAM_GROUP_DELETE_INLINE_GROUP: 'iam_group_delete_inline_group',
    IAM_ROLE_ATTACH_POLICY: 'iam_role_attach_policy',
    IAM_USER_ATTACH_POLICY: 'iam_user_attach_policy',
    IAM_USER_DEACTIVATE_UNUSED_ACCESS_KEY: 'iam_user_deactivate_unused_access_key',
    IAM_USER_DELETE_INLINE_POLICIES: 'iam_user_delete_inline_policies',
    IAM_USER_DISABLE_CONSOLE_PASSWORD: 'iam_user_disable_console_password',
    IAM_USER_FORCE_PASSWORD_CHANGE: 'iam_user_force_password_change',
    IAM_QUARANTINE_ROLE: 'iam_quarantine_role',
    IAM_QUARANTINE_USER: 'iam_quarantine_user',
    IAM_ENTITY_CREATE_AND_ATTACH_PERMISSION_BOUNDARY: 'iam_entity_create_and_attach_permission_boundary',
    IAM_ROLE_CLONE_WITH_NON_ENUMERABLE_NAME: 'iam_role_clone_with_non_enumerable_name',
    IAM_TURN_ON_PASSWORD_POLICY: 'iam_turn_on_password_policy',
    IGW_DELETE: 'igw_delete',
    KMS_CMK_ENABLE_KEY: 'kms_cmk_enable_key',
    KMS_ENABLE_ROTATION: 'kms_enable_rotation',
    LAMBDA_DETACH_BLANKET_PERMISSIONS: 'lambda_detach_blanket_permissions',
    LAMBDA_ENABLE_ACTIVE_TRACING: 'lambda_enable_active_tracing',
    LAMBDA_TAG: 'lambda_tag',
    LOAD_BALANCER_ENABLE_ACCESS_LOGS: 'load_balancer_enable_access_logs',
    MARK_FOR_STOP_EC2_RESOURCE: 'mark_for_stop_ec2_resource',
    NETWORK_FIREWALL_ENABLE_LOGGING: 'network_firewall_enable_logging',
    RDS_QUARANTINE_INSTANCE: 'rds_quarantine_instance',
    ROUTE53DOMAIN_ENABLE_AUTO_RENEW: 'route53domain_enable_auto_renew',
    ROUTE53DOMAIN_ENABLE_TRANSFER_LOCK: 'route53domain_enable_transfer_lock',
    S3_BLOCK_ALL_PUBLIC_ACCESS: 's3_block_all_public_access',
    S3_DELETE_ACLS: 's3_delete_acls',
    S3_DELETE_PERMISSIONS: 's3_delete_permissions',
    S3_DISABLE_STATIC_WEBSITE_HOSTING: 's3_disable_static_website_hosting',
    S3_ENABLE_ENCRYPTION: 's3_enable_encryption',
    S3_ENABLE_LOGGING: 's3_enable_logging',
    S3_ENABLE_VERSIONING: 's3_enable_versioning',
    S3_LIMIT_ACCESS: 's3_limit_access',
    S3_ONLY_ALLOW_SSL: 's3_only_allow_ssl',
    SECRETS_MANAGER_ENABLE_ENCRYPTION: 'secretsmanager_enable_encryption',
    SG_CLEAR_RULES_FOR_ANY_SCOPE: 'sg_clear_rules_for_any_scope',
    SG_DELETE: 'sg_delete',
    SG_DELETE_NOT_MATCHING_CIDR: 'sg_delete_not_matching_cidr',
    SG_MODIFY_SCOPE_BY_PORT: 'sg_modify_scope_by_port',
    SG_RULES_DELETE: 'sg_rules_delete',
    SG_RULES_DELETE_BY_SCOPE: 'sg_rules_delete_by_scope',
    SG_SINGLE_RULE_DELETE: 'sg_single_rule_delete',
    SNS_ENFORCE_SSE: 'sns_enforce_sse',
    SNS_SET_TOPIC_PRIVATE: 'sns_set_topic_private',
    SNS_TOPIC_DELETE: 'sns_topic_delete',
    SQS_CONFIGURE_DLQ: 'sqs_configure_dlq',
    SQS_ENFORCE_SSE: 'sqs_enforce_sse',
    TAG_EC2_RESOURCE: 'tag_ec2_resource',
    SSM_DOCUMENT_SET_PRIVATE: 'ssm_document_set_private',
    VPC_DELETE: 'vpc_delete',
    VPC_ISOLATE: 'vpc_isolate',
    VPC_TURN_ON_FLOW_LOGS: 'vpc_turn_on_flow_logs',
    EC2_TAG_INSTANCE_FROM_VPC: 'ec2_tag_instance_from_vpc',
    S3_DELETE_BUCKET: 's3_delete_bucket',

    // Azure
    COSMOS_DB_ACCOUNT_ADD_TAG: 'cosmos_db_account_add_tag',
    CREATE_DELETE_RESOURCE_LOCK_AT_RESOURCE_GROUP_LEVEL: 'create_delete_resource_lock_at_resource_group_level',
    CREATE_READONLY_RESOURCE_LOCK_AT_RESOURCE_GROUP_LEVEL: 'create_readonly_resource_lock_at_resource_group_level',
    ENABLE_NSG_FLOW_LOGS: 'enable_nsg_flow_logs',
    MODIFY_NETWORK_SECURITY_GROUP_SCOPE_BY_PORT: 'modify_network_security_group_scope_by_port',
    DELETE_NETWORK_SECURITY_GROUP: 'delete_network_security_group',
    DELETE_NETWORK_SECURITY_GROUP_SINGLE_RULE: 'delete_network_security_group_single_rule',
    DISABLE_BLOB_PUBLIC_ACCESS: 'disable_blob_public_access',
    POSTGRES_ENABLE_CONNECTION_THROTTLING: 'postgres_enable_connection_throttling',
    POSTGRES_ENABLE_LOG_CONNECTIONS: 'postgres_enable_log_connections',
    POSTGRES_ENABLE_LOG_DISCONNECTS: 'postgres_enable_log_disconnections',
    POSTGRES_ENABLE_LOG_DURATION: 'postgres_enable_log_duration',
    POSTGRES_ENABLE_LOG_RETENTION_DAYS_7: 'postgres_enable_log_retention_days_7',
    POSTGRES_ENFORCE_SSL_CONNECTION: 'postgres_enforce_ssl_connection',
    POSTGRES_ENFORCE_SSL_CONNECTION_TLS_12: 'postgres_enforce_ssl_connection_tls_12',
    REDIS_CACHE_SSL_ONLY: 'redis_cache_ssl_only',
    SQL_ADD_FIREWALL_RULE_BY_EXISTING_SUBNET: 'sql_add_firewall_rule_by_existing_subnet',
    SQL_ADD_FIREWALL_RULE_BY_IP: 'sql_add_firewall_rule_by_ip',
    SQL_DISABLE_PUBLIC_ACCESS: 'sql_disable_public_access',
    SQL_ENABLE_ACCESS_FROM_ALL_VNETS: 'sql_enable_access_from_all_vnets',
    SQL_ENABLE_DATA_ENCRYPTION: 'sql_enable_data_encryption',
    SQL_ENABLE_AZURE_AD_AUTHENTICATION: 'sql_enable_azure_ad_authentication',
    STORAGE_ACCOUNT_DISABLE_BLOB_PUBLIC_ACCESS: 'storage_account_disable_blob_public_access',
    STORAGE_ACCOUNT_DISABLE_PUBLIC_NETWORK_ACCESS: 'storage_account_disable_public_network_access',
    STORAGE_ACCOUNT_ENABLE_HTTPS_TRAFFIC_ONLY: 'storage_account_enable_https_traffic_only',
    TAG_VIRTUAL_MACHINE: 'tag_virtual_machine',
    VIRTUAL_MACHINE_DEALLOCATE: 'virtual_machine_deallocate',
    VIRTUAL_MACHINE_STOP: 'virtual_machine_stop',

    // Google
    FIREWALL_RULES_DELETE: 'firewall_rules_delete',
    VM_INSTANCE_STOP: 'vm_instance_stop',
    GKE_CHANGE_IMAGETYPE_TO_COS: 'gke_change_imageType_to_cos',
    GKE_ENABLE_MASTER_AUTHORIZED_NETWORKS: 'gke_enable_master_authorized_networks',
    GKE_SUBNET_SET_PRIVATE_GOOGLE_ACCESS_ON: 'gke_subnet_set_private_google_access_on',
    VM_INSTANCE_ADD_LABEL: 'vm_instance_add_label',
    VM_INSTANCE_DISABLE_PUBLIC_IP: 'vm_instance_disable_public_ip',
    SUBNET_SET_PRIVATE_GOOGLE_ACCESS_ON: 'subnet_set_private_google_access_on',
    CLOUD_SQL_DELETE_PUBLIC_IP_RANGES: 'cloud_sql_delete_public_ip_ranges',
    STORAGE_BUCKET_REMOVE_ALLOW_PUBLIC_ACCESS_RULES: 'storage_bucket_remove_allow_public_access_rules',
    FLOW_LOGS_ENABLE: 'flow_logs_enable'    
};

export const CLOUD_BOTS_PAGE_ID = 'cloudBotsPage';
export const CLOUD_BOTS_ENDPOINT_LOCAL_STORAGE_KEY = 'ARN_ENDPOINT';
export const CLOUD_BOTS_SERVICE_ID = 'cloudBotsService';
export const CLOUD_BOTS_ON_DEMAND_REMEDIATION = 'cloud-bots-on-demand-remediation';
export const FIX_IT = 'fix-it';

export enum EndPointType {
    SNS = 'SNS',
    HTTP = 'HTTP'
}

export enum TriggerStatus {
    TRUE = 'true',
    FALSE = 'false'
}

export enum ExecutionStatus {
    SUCCESS = 'success',
    FAILED = 'failed',
    WAITING = 'waiting',
    NOT_INITIATED = 'not-initiated'
}

export enum BotStatus {
    NOT_STARTED = 'not-started',
    IN_PROGRESS = 'in-progress',
    SUCCESS = 'success',
    FAILED = 'failed'
}

export const CloudBotsTableFields = {
    BOT_NAME: 'workItemBotName',
    ENTITY_NAME: 'workItemEntityName',
    TRIGGERED_STATUS: 'isTriggered',
    EXECUTION_STATUS: 'executionStatus',
};

export const RemediationResponseFields: { [key in keyof IRemediationExecutionInfo]: string } = {
    executionId: 'executionId',
    eventTime: 'event_time',
    type: 'type',
    accountId: 'accountId',
    vendor: 'vendor',
    findingKey: 'findingKey',
    cloudAccountId: 'envCloudAccountId',
    remediation: 'remediation',
    status: 'status',
    remediationInfo: 'remediationInfo'
};
