import * as React from 'react';
import { SVGProps, Ref, forwardRef } from 'react';
const SvgStatusSuccess = (
  props: SVGProps<SVGSVGElement>,
  ref: Ref<SVGSVGElement>
) => (
  <svg
    viewBox="0 0 16 16"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    ref={ref}
    {...props}
  >
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M8 16A8 8 0 1 0 8 0a8 8 0 0 0 0 16Zm2.829-10.829a.8.8 0 0 1 1.131 1.133l-4.522 4.522-.004.004a.799.799 0 0 1-1.13 0l-.004-.004-2.26-2.26a.8.8 0 1 1 1.131-1.13L6.87 9.131l3.96-3.96Z"
      fill="#3BA713"
    />
  </svg>
);
const ForwardRef = forwardRef(SvgStatusSuccess);
export default ForwardRef;
