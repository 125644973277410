import styled, { css } from 'styled-components';
import { TextAreaProps } from './TextArea.types';
import Stack from '../Stack';
import { CommonInputProps } from '../Input/Input.types';

export const TextAreaBase = styled.textarea<Pick<TextAreaProps, 'isError' | 'readOnly'>>`
    all: unset;
    width: 0;
    text-overflow: ellipsis;
    line-height: 16px;
    flex: 1;
    min-width: 30px;
    background: ${({ theme, readOnly }) => readOnly ? theme.palette.surface.secondary : 'transparent'};
    color: ${({ theme }) => theme.palette.text.strong};
    padding: ${({ theme }) => `${theme.spacing(1)}px 0`};

    &::placeholder {
        color: ${({ theme, isError }) => isError ? theme.palette.text.alert : theme.palette.text.light};
    }

    ${({ readOnly }) => readOnly && css`
        pointer-events: none;
    `};
`;

interface IInputFooterWrapper {
    inputSize: CommonInputProps['inputSize']
}

export const InputFooterWrapper = styled(Stack)<IInputFooterWrapper>`
    max-width: ${({ fullWidth, inputSize }) => {
        if (fullWidth) return '100%';
        if (inputSize === 'large') return '364px';
        return '240px';
    }};
    overflow: hidden;
`;
