import React from 'react';
import { Link } from 'common/erm-components/basic';
import { ICellRendererParams } from 'ag-grid-enterprise';

export const ExternalLinkRenderer: React.FC<ICellRendererParams> = (params) =>
{
    const { url, label } = params.value;
    return (
        <Link externalUrl={url} textProps={{ text: label }}/>
    );
};

export default ExternalLinkRenderer;