import i18n from 'i18next';
import { IValueCount } from '../interface/general';

export const deepFreeze = (obj: any) => {
    if (obj instanceof Map) {
        obj.clear = obj.delete = obj.set = function () {
            throw new Error('Frozen Map is read-only');
        };
    } else if (obj instanceof Set) {
        obj.add = obj.clear = obj.delete = function () {
            throw new Error('Frozen set is read-only');
        };
    }

    Object.freeze(obj);

    Object.getOwnPropertyNames(obj).forEach(function (name) {
        const prop = obj[name];

        // Freeze prop if it is an object
        if (typeof prop === 'object' && !Object.isFrozen(prop)) {
            deepFreeze(prop);
        }
    });
};

export const isEmptyObj = (obj: Object) => {
    for (const prop in obj) {
        if (Object.prototype.hasOwnProperty.call(obj, prop)) {
            return false;
        }
    }

    return JSON.stringify(obj) === JSON.stringify({});
};

export const numberWithCommas = (num: number): string => {
    return i18n.t('COMMON.PRETTY_NUMBER', { value: num });
};

export const percentageNumber = (num: number, digits?: number) => {
    return `${num.toFixed(digits || 2)}%`;
};

const i18nFormatNumber = (key: string, value: number, digits = 0) => {
    return i18n.t(key, { value, maximumFractionDigits: digits });
};

export const formatNumberShorthand = (number: number | string, highRes?: boolean): string => {
    const parsedNumber = typeof number === 'string' ? parseInt(number) : number;
    if (isNaN(parsedNumber)) {
        return '';
    }
    if (parsedNumber >= 1000000000) {
        return i18nFormatNumber('COMMON.BILLION', parsedNumber / 1000000000);
    } else if (parsedNumber >= 1000000) {
        return i18nFormatNumber('COMMON.MILLION', parsedNumber / 1000000);
    } else if (parsedNumber >= 1000) {
        return i18nFormatNumber('COMMON.THOUSANDS', parsedNumber / 1000);
    } else if (highRes && (parsedNumber >= 100)) {
        return i18nFormatNumber('COMMON.THOUSANDS', parsedNumber / 1000, 1);
    } else {
        return i18nFormatNumber('COMMON.PRETTY_NUMBER', parsedNumber);
    }
};

export const capitalizeWords = (string = '') => {
    return string.replace(/(?:^|\s)\S/g, function (a) {
        return a.toUpperCase();
    });
};

export const toKebabCase = (string = '') => {
    return string
        .replace(/([a-z])([A-Z])/g, '$1-$2')
        .replace(/\s+/g, '-')
        .toLowerCase();
};

export const toDataAid = (string = '') => {
    return toKebabCase(string).replace(/[./:`'(){}<>]/g, '-').replace(/-+/g, '-').replace(/^-/g, '').replace(/-$/g, '');
};

export const findKey = (object: any, value: any) => {
    if (!object) {
        return;
    }
    return Object.keys(object)?.find((key) => object[key] === value);
};

export const hash = (word: string) => {
    let hash = 0,
        i, chr;
    if (word.length === 0) return hash;
    for (i = 0; i < word.length; i++) {
        chr = word.charCodeAt(i);
        hash = ((hash << 5) - hash) + chr;
        hash |= 0; // Convert to 32bit integer
    }
    return hash;
};

export function isNil<T>(obj: T | null | undefined): obj is (undefined | null) {
    return obj === undefined || obj === null;
}

export function isNilOrEmpty<T>(obj: T[] | Record<string, T> | string | undefined | null): obj is (undefined | null) {
    return isNil(obj) || Object.keys(obj).length === 0;
}

export const toTitleCase = (str: string) => {
    return str.replace(
        /\w\S*/g,
        function (txt) {
            return txt.charAt(0).toUpperCase() + txt.substr(1).toLowerCase();
        }
    );
};

export const escapeCSVValue = (value:string):string =>{
    let result = value.replaceAll('"','""');
    if (result.search(/("|,|\n)/g) >= 0) {
        result = `"${result}"`;
    }
    return result;
};

export const flattenTree = (nodeTree: any) => {
    if (nodeTree.children.length === 0) {
        return [
            { name: nodeTree.item.name, id: nodeTree.item.id, path: [nodeTree.item.name], pathIds: [nodeTree.item.id] }
        ];
    }
    const items: any[] = [];
    const createTree = (node: any, parentPath: string[], pathIds?: string[]) => {
        const itemPath = [...parentPath, node.item.name];
        const itemPathIds = pathIds && [...pathIds, node.item.id];
        items.push({ name: node.item.name, id: node.item.id, path: itemPath, pathIds: itemPathIds });
        node.children?.forEach((child: any) => {
            createTree(child, itemPath, itemPathIds);
        });
    };
    nodeTree.children?.forEach((rootNode: any) => {
        createTree(rootNode, [nodeTree.item.name], [nodeTree.item.id]);
    });
    return items;
};

export type IRunAllResultsMap<T> = { [key in keyof T]: any };

export const runAll = async <T=any>(promisesMap: { [key in keyof T]: Promise<any> }, withExceptions?: boolean): Promise<IRunAllResultsMap<T>> => {
    const promises = Object.values(promisesMap);

    return Promise.allSettled(promises).then((results: PromiseSettledResult<any>[]) => {
        const keys = Object.keys(promisesMap);
        const resultsMap: { [key in keyof T]: any } = {} as T;
        const errorsMap: { [key in keyof T]: any } = {} as T;
        results.forEach((result: PromiseSettledResult<any>, index) => {
            const key: string = keys[index];
            if (result.status === 'fulfilled') {
                (resultsMap as any)[key] = result.value;
            } else {
                (errorsMap as any)[key] = result.reason;
            }
        });
        if (withExceptions && (Object.values(errorsMap).length > 0)) {
            throw {
                resultsMap,
                errorsMap,
            };
        }
        return resultsMap;
    });
};

export function uniq(list: string[] = []) {
    return Array.from(new Set(list));
}

export function removeUndefinedFields(obj: { [key: string]: any }) {
    const result = { ...obj };
    Object.keys(result).forEach(key => {
        if (result[key] === undefined) {
            delete result[key];
        }
    });
    return result;
}

export function isArray(val: any): boolean {
    return !isNil(val) && Array.isArray(val);
}
export function isObject(val: any): boolean {
    return !isNil(val) && !isArray(val) && (typeof val === 'object');
}
export function isString(val: any): boolean {
    return !isNil(val) && (typeof val === 'string');
}

export function mergeLists(baseList: any[], overridingList: any[]) {
    const result: any[] = [];
    const maxLength = Math.max(baseList.length, overridingList.length);
    for (let i = 0; i < maxLength; i++) {
        const hasBaseItem = (i < baseList.length);
        const hasOverridingItem = (i < overridingList.length);
        if (hasBaseItem && hasOverridingItem) {
            result[i] = mergeJsons(baseList[i], overridingList[i]);
        } else if (hasBaseItem) {
            result[i] = baseList[i];
        } else {
            result[i] = overridingList[i];
        }
    }
    return result;
}
export function mergeJsons(baseData: { [key: string]: any; } = {}, overridingData: { [key: string]: any; } = {}, mergeListItems?: boolean) {
    const result: { [key: string]: any; } = {};
    Object.keys(baseData).forEach(key => {
        if (overridingData[key] !== undefined) {
            if (isArray(baseData[key]) && isArray(overridingData[key])) {
                if (mergeListItems) {
                    result[key] = mergeLists(baseData[key], overridingData[key]);
                } else {
                    result[key] = overridingData[key];
                }
            } else if (isObject(baseData[key]) && isObject(overridingData[key])) {
                result[key] = mergeJsons(baseData[key], overridingData[key]);
            } else {
                result[key] = overridingData[key];
            }
        } else {
            result[key] = baseData[key];
        }
    });
    Object.keys(overridingData).forEach(key => {
        if (!result[key]) {
            result[key] = overridingData[key];
        }
    });
    return result;
}

export const getValueCount = (valueCountList: IValueCount[], value: string): number => {
    const valueCountItem = valueCountList.find(item => item.value === value);
    return valueCountItem ? valueCountItem.count : 0;
};

export const formatDate = (date: Date | string) => {
    const dateObj = new Date(date);
    const month = dateObj.toLocaleString('default', { month: 'short' });
    const day = dateObj.getDate();
    const year = dateObj.getFullYear();
    const hours = dateObj.getHours();
    const minutes = dateObj.getMinutes();
    const ampm = hours >= 12 ? 'PM' : 'AM';
    const formattedHours = hours % 12 || 12;
    const formattedMinutes = minutes < 10 ? `0${minutes}` : minutes;
    return `${month} ${day} ${year} ${formattedHours}:${formattedMinutes} ${ampm}`;
};

export const isEmptyString = (s: string | null | undefined) => isNil<string>(s) || s.trim() === '';


export const isImagePath = (iconName?: string) => {
    if(!iconName) {
        return false;
    }
    const imageRegex = /\.(jpg|jpeg|png|gif|bmp|svg)$/i;
    return imageRegex.test(iconName);
};


export type INumbersMap = { [key: string]: number };
export const convertValueToArray = (value: any): any[] => {
    if (!value) {
        return [];
    }
    return isArray(value) ? value as any[] : [value];
};

export const dateComparator = (dateA: Date | null, dateB: Date | null) => {
    if (!dateA && !dateB) return 0;
    if (!dateA) return -1;
    if (!dateB) return 1;
    return dateA.getTime() - dateB.getTime();
};