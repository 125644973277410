import { protocol } from '../interface/network';

export const ProtocolsAndPortsConsts: protocol[] = [
    { name: 'ALL', code: -1 },
    { name: 'HOPOPT', code: 0 },
    { name: 'ICMP', code: 1, portRange: false, icmp: true, ipVersion: 4 },
    { name: 'IGMP', code: 2 },
    { name: 'GGP', code: 3 },
    { name: 'IPV4', code: 4 },
    { name: 'ST', code: 5 },
    { name: 'TCP', code: 6, portRange: true },
    { name: 'CBT', code: 7 },
    { name: 'EGP', code: 8 },
    { name: 'IGP', code: 9 },
    { name: 'BBN_RCC_MON', code: 10 },
    { name: 'NVP_II', code: 11 },
    { name: 'PUP', code: 12 },
    { name: 'ARGUS', code: 13 },
    { name: 'EMCON', code: 14 },
    { name: 'XNET', code: 15 },
    { name: 'CHAOS', code: 16 },
    { name: 'UDP', code: 17, portRange: true },
    { name: 'MUX', code: 18 },
    { name: 'DCN_MEAS', code: 19 },
    { name: 'HMP', code: 20 },
    { name: 'PRM', code: 21 },
    { name: 'XNS_IDP', code: 22 },
    { name: 'TRUNK_1', code: 23 },
    { name: 'TRUNK_2', code: 24 },
    { name: 'LEAF_1', code: 25 },
    { name: 'LEAF_2', code: 26 },
    { name: 'RDP', code: 27 },
    { name: 'IRTP', code: 28 },
    { name: 'ISO_TP4', code: 29 },
    { name: 'NETBLT', code: 30 },
    { name: 'MFE_NSP', code: 31 },
    { name: 'MERIT_INP', code: 32 },
    { name: 'DCCP', code: 33 },
    { name: '3PC', code: 34 },
    { name: 'IDPR', code: 35 },
    { name: 'XTP', code: 36 },
    { name: 'DDP', code: 37 },
    { name: 'IDPR_CMTP', code: 38 },
    { name: 'TP++', code: 39 },
    { name: 'IL', code: 40 },
    { name: 'IPV6', code: 41 },
    { name: 'SDRP', code: 42 },
    { name: 'IPV6_ROUTE', code: 43 },
    { name: 'IPV6_FRAG', code: 44 },
    { name: 'IDRP', code: 45 },
    { name: 'RSVP', code: 46 },
    { name: 'GRE', code: 47 },
    { name: 'DSR', code: 48 },
    { name: 'BNA', code: 49 },
    { name: 'ESP', code: 50 },
    { name: 'AH', code: 51 },
    { name: 'I_NLSP', code: 52 },
    { name: 'SWIPE', code: 53 },
    { name: 'NARP', code: 54 },
    { name: 'MOBILE', code: 55 },
    { name: 'TLSP', code: 56 },
    { name: 'SKIP', code: 57 },
    { name: 'IPV6_ICMP', code: 58 },
    { name: 'IPV6_NONXT', code: 59 },
    { name: 'IPV6_OPTS', code: 60 },
    { name: 'CFTP', code: 62 },
    { name: 'SAT_EXPAK', code: 64 },
    { name: 'KRYPTOLAN', code: 65 },
    { name: 'RVD', code: 66 },
    { name: 'IPPC', code: 67 },
    { name: 'SAT_MON', code: 69 },
    { name: 'VISA', code: 70 },
    { name: 'IPCV', code: 71 },
    { name: 'CPNX', code: 72 },
    { name: 'CPHB', code: 73 },
    { name: 'WSN', code: 74 },
    { name: 'PVP', code: 75 },
    { name: 'BR_SAT_MON', code: 76 },
    { name: 'SUN_ND', code: 77 },
    { name: 'WB_MON', code: 78 },
    { name: 'WB_EXPAK', code: 79 },
    { name: 'ISO_IP', code: 80 },
    { name: 'VMTP', code: 81 },
    { name: 'SECURE_VMTP', code: 82 },
    { name: 'VINES', code: 83 },
    { name: 'TTP', code: 84 },
    { name: 'NSFNET_IGP', code: 85 },
    { name: 'DGP', code: 86 },
    { name: 'TCF', code: 87 },
    { name: 'EIGRP', code: 88 },
    { name: 'OSPFIGP', code: 89 },
    { name: 'SPRITE_RPC', code: 90 },
    { name: 'LARP', code: 91 },
    { name: 'MTP', code: 92 },
    { name: 'AX.25', code: 93 },
    { name: 'IPIP', code: 94 },
    { name: 'MICP', code: 95 },
    { name: 'SCC_SP', code: 96 },
    { name: 'ETHERIP', code: 97 },
    { name: 'ENCAP', code: 98 },
    { name: 'GMTP', code: 100 },
    { name: 'IFMP', code: 101 },
    { name: 'PNNI', code: 102 },
    { name: 'PIM', code: 103 },
    { name: 'ARIS', code: 104 },
    { name: 'SCPS', code: 105 },
    { name: 'QNX', code: 106 },
    { name: 'A/N', code: 107 },
    { name: 'IPCOMP', code: 108 },
    { name: 'SNP', code: 109 },
    { name: 'COMPAQ_PEER', code: 110 },
    { name: 'IPX_IN_IP', code: 111 },
    { name: 'VRRP', code: 112 },
    { name: 'PGM', code: 113 },
    { name: 'L2TP', code: 115 },
    { name: 'DDX', code: 116 },
    { name: 'IATP', code: 117 },
    { name: 'STP', code: 118 },
    { name: 'SRP', code: 119 },
    { name: 'UTI', code: 120 },
    { name: 'SMP', code: 121 },
    { name: 'SM', code: 122 },
    { name: 'PTP', code: 123 },
    { name: 'ISIS OVER IPV4', code: 124 },
    { name: 'FIRE', code: 125 },
    { name: 'CRTP', code: 126 },
    { name: 'CRUDP', code: 127 },
    { name: 'SSCOPMCE', code: 128 },
    { name: 'IPLT', code: 129 },
    { name: 'SPS', code: 130 },
    { name: 'PIPE', code: 131 },
    { name: 'SCTP', code: 132 },
    { name: 'FC', code: 133 },
    { name: 'RSVP_E2E_IGNORE', code: 134 },
    { name: 'MOBILITY HEADER', code: 135 },
    { name: 'UDPLITE', code: 136 },
    { name: 'MPLS_IN_IP', code: 137 },
    { name: 'MANET', code: 138 },
    { name: 'HIP', code: 139 },
    { name: 'SHIM6', code: 140 },
    { name: 'WESP', code: 141 },
    { name: 'ROHC', code: 142 }
];

export const CommonPorts: Record<string, string> = {
    21: '21 (FTP)',
    22: '22 (SSH)',
    23: '23 (Telnet)',
    25: '25 (SMTP)',
    53: '53 (DNS)',
    67: '67 (DHCP)',
    80: '80 (HTTP)',
    110: '110 (POP3)',
    115: '115 (SFTP)',
    119: '119 (NNTP)',
    123: '123 (NTP)',
    143: '143 (IMAP)',
    161: '161 (SNMP)',
    194: '194 (IRC)',
    443: '443 (HTTPS)',
    445: '445 (Microsoft-DS)',
    465: '465 (SMTPS)',
    514: '514 (Syslog)',
    546: '546 (DHCPv6 Client)',
    547: '547 (DHCPv6 Server)',
    563: '563 (NNTP over SSL)',
    587: '587 (SMTP Submission)',
    990: '990 (FTPS)',
    993: '993 (IMAPS)',
    995: '995 (POP3 over SSL)',
    1080: '1080 (SOCKS)',
    1433: '1433 (Microsoft-SQL)',
    1521: '1521 (Oracle)',
    1701: '1701 (L2TP)',
    1723: '1723 (PPTP)',
    3305: '3305 (OFTP)',
    3306: '3306 (MYSQL)',
    3389: '3389 (RDP)',
    5000: '5000 (UPnP)',
    5060: '5060 (SIP)',
    5222: '5222 (XMPP)',
    5900: '5900 (VNC)',
    6667: '6667 (IRC)',
    8444: '8444 (Bitmessage)',
    8000: '8000 (HTTP Alt)',
    8001: '8001 (HTTP Alt)',
    8088: '8088 (Radan HTTP)',
    8333: '8333 (Bitcoin)',
    8888: '8888 (HTTP Alt)',
    8080: '8080 (HTTP Alt)',
    9090: '9090 (HTTP Alt)',
    9418: '9418 (Git)',
    10000: '10000 (Webmin)',
    11211: '11211 (Memcached)',
    27017: '27017 (MongoDB)',
    27018: '27018 (MongoDB)',
    27019: '27019 (MongoDB)',
    28017: '28017 (MongoDB)',
    33060: '33060 (MySQL)',
};
