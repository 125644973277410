import { getProtectedAssetsService } from 'common/module_interface/assets/ProtectedAssets';
import { hash, isNil } from 'common/utils/helpFunctions';
import { Vendors } from 'common/consts/vendors';
import { Location } from 'common/utils/history';
import { getEntityService, getHttpService } from 'common/interface/services';
import { getTypeByPlatform } from 'common/assets/common.assets';
import { ICloudEntity, ICloudEntityData } from 'common/module_interface/assets/ICloudEntity';
import { IAssetInfo } from './AssetTabs';
import {
    getCloudAccountsService,
    getOrganizationalUnitService,
    ICloudAccount,
    IOrganizationalUnit,
} from '../../interface/data_services';
import { AxiosError } from 'axios';
import { IProtectedAssetViewModel, ProtectedAssetsResponse, SearchRequest } from './ProtectedAssetsTable.interface';
import { SelectOption } from '../../design-system/components-v2/SelectV2/Select.types';
import { EXTERNAL_ADDITIONAL_FIELDS_SOURCE } from './ProtectedAssetsTable.consts';
import { ASSETS_SEARCH_URL } from '../../module_interface/assets/AssetsConsts';

export async function getCloudEntityData(id: string, type: string, platform: string, cloudAccountId: string, customErrHandler?: (error: AxiosError<ICloudEntity>) => ICloudEntity): Promise<ICloudEntityData | null> {
    if (id === null || type === null || platform === null || cloudAccountId === null) {
        return null;
    }

    const cacheTimeout = 3 * 60; //3 minutes
    const cachingConfig = { useCache: true, cacheTimeout };
    const typeByPlatform = getTypeByPlatform(platform, type);
    const asset = getProtectedAssetsService().getAssetByType(typeByPlatform);
    if (asset === null) {
        return null;
    }

    async function getCloudEntity() {
        return await getEntityService().getEntity(id, type, platform, cloudAccountId, cachingConfig, customErrHandler);
    }

    const cloudEntityPromise: Promise<ICloudEntity | null> = isNil(asset.getSpecificEntity) ? getCloudEntity() : Promise.resolve(null);
    const protectedAssetPromise: Promise<IProtectedAssetViewModel | null> = getProtectedAssetsService().getProtectedAssetById(id, asset.typeByPlatform, cloudAccountId, cachingConfig);
    const cloudAccountPromise: Promise<ICloudAccount | null> = getCloudAccountsService().getCloudAccountByAccountId(cloudAccountId);
    const orgUnitsPromise: Promise<IOrganizationalUnit[]> = getOrganizationalUnitService().getAllOrganizationalUnitsFlatWithPath(true);


    try {
        const responses = await Promise.all([cloudEntityPromise, protectedAssetPromise, cloudAccountPromise, orgUnitsPromise]);
        const [cloudEntity, protectedAsset, cloudAccount, allOrgUnits] = responses;
        if (protectedAsset === null || cloudAccount === null) {
            return null;
        }
        let specificCloudEntity: ICloudEntity | null = cloudEntity;
        if (cloudEntity === null && asset.getSpecificEntity) {
            specificCloudEntity = await asset.getSpecificEntity(protectedAsset);
            if (specificCloudEntity === null) {
                specificCloudEntity = await getCloudEntity();
            }
        }

        if (specificCloudEntity === null) {
            return null;
        }
        const organizationalUnit = allOrgUnits.find((ou: IOrganizationalUnit) => ou.id === cloudAccount.organizationalUnitId);
        const organizationalUnitFullPath = await getOrgUnitFullPath(cloudAccount.organizationalUnitId);

        return {
            cloudEntity: specificCloudEntity,
            typeByPlatform,
            cloudAccountId,
            protectedAsset,
            entityId: specificCloudEntity.id ?? protectedAsset.entityId,
            cloudAccount,
            organizationalUnit,
            organizationalUnitFullPath,
        };
    } catch (error) {
        console.error(error);
        return null;
    }
}

export async function getEntityFromUrlParams(assetInfo: IAssetInfo | null): Promise<ICloudEntityData | null> {
    if (assetInfo === null) {
        return null;
    }
    const { id, type, platform, cloudAccountId } = assetInfo;
    if (id === null || type === null || platform === null || cloudAccountId === null) {
        return null;
    }
    return getCloudEntityData(id, type, platform, cloudAccountId);
}

function getHash(id: string, cloudAccountId: string) {
    return hash(`${id}|${cloudAccountId}`);
}

export function getAssetInfoFromLocation(location: Location<unknown>): IAssetInfo {
    const path = location.pathname + location.search;
    const queryParams = new URLSearchParams(location.search);
    const instanceId = queryParams.get('instanceid') || '';
    const type = queryParams.get('type');

    switch (type) {
        case '18':
        case '19':
        case '20': {
            const asset = getProtectedAssetsService().getAssetByTypeNumber(type);
            const entityDataChips = instanceId.split('|');
            const cloudAccountId = entityDataChips[1];
            const assetName = entityDataChips[2];
            return {
                id: entityDataChips[3],
                type: asset?.type || '',
                cloudAccountId: cloudAccountId,
                platform: asset?.platform || Vendors.KUBERNETES,
                name: asset?.displayName || assetName,
                url: path,
                hash: getHash(instanceId, cloudAccountId),
            };
        }
        case '21': {
            const asset = getProtectedAssetsService().getAssetByTypeNumber(type);
            const cloudAccountId = queryParams.get('cloudaccountid') || queryParams.get('cloudAccountId') || '';
            const assetId = queryParams.get('assetid') || '';
            const assetType = asset?.type || '';
            const platform = asset?.platform || '';
            return {
                id: assetId,
                type: assetType,
                platform: platform,
                cloudAccountId: cloudAccountId,
                url: path,
                hash: getHash(assetId, cloudAccountId),
                name: '',
            };
        }
        case '1': {
            const asset = getProtectedAssetsService().getAssetByTypeNumber(type);
            const assetType = asset?.type || '';
            const cloudAccountId = queryParams.get('cloudaccountid') || '';
            const assetId = queryParams.get('instanceid') || '';
            const platform = asset?.platform || '';
            return {
                id: assetId,
                type: assetType,
                platform: platform,
                cloudAccountId: cloudAccountId,
                url: path,
                hash: getHash(assetId, cloudAccountId),
                name: '',
            };
        }
        case '12': {
            const asset = getProtectedAssetsService().getAssetByTypeNumber(type);
            const cloudAccountId = queryParams.get('cloudaccountid') || '';
            const assetId = queryParams.get('externalid') || '';
            const assetType = asset?.type || '';
            const platform = asset?.platform || '';
            return {
                id: assetId,
                type: assetType || '',
                platform: platform,
                cloudAccountId: cloudAccountId,
                url: path,
                hash: getHash(assetId, cloudAccountId),
                name: '',
            };
        }
        case '2':
        case '3':
        case '5':
        case '6':
        case '7':
        case '8':
        case '9':
        case '10':
        case '13':
        case '14':
        case '17': {
            const entityDataChips = instanceId.split('|');
            const cloudAccountId = entityDataChips[1];
            const asset = getProtectedAssetsService().getAssetByTypeNumber(type);
            if (asset === null) {
                throw new Error(`Could not get asset type for number ${type}`);
            }
            const assetName = entityDataChips[5];
            return {
                id: instanceId,
                type: asset.type,
                cloudAccountId: cloudAccountId,
                platform: asset.platform,
                name: assetName,
                url: path,
                hash: getHash(instanceId, cloudAccountId),
            };
        }
        default: {
            if (instanceId) {
                const entityDataChips = instanceId.split('|');
                const cloudAccountId = entityDataChips[1];
                const asset = getProtectedAssetsService().getAssetByType(type ?? entityDataChips[4] ?? '');
                if (asset === null) {
                    throw new Error(`Could not get asset type from instanceId ${instanceId}`);
                }
                const assetName = entityDataChips[5];
                return {
                    id: instanceId,
                    type: asset.type,
                    cloudAccountId: cloudAccountId,
                    platform: asset.platform,
                    name: assetName,
                    url: path,
                    hash: getHash(instanceId, cloudAccountId),
                };
            } else {
                const assetType = queryParams.get('assetType') || '';
                let assetId = queryParams.get('assetId') || '';
                // this is a fix when we jump to aks asset page (angular to react).
                // should remove when we move the environment (workload) table to react
                // For example, AngularJS history update replaces assetId param from:
                // assetId=<sha256:id>~arn:aws:ecs:us-west-2:371954679992:cluster%2Fmicroservice-qa
                // to:
                //assetId=<sha256:id>~~arn:aws:ecs:us-west-2:371954679992:cluster~2Fmicroservice-qa
                // the HTML encoded / (%2F) turns to ~2F and tilda (~) turns into two tildas.
                assetId = assetId.replaceAll('~2F', '/').replaceAll('~~', '~');
                const cloudAccountId = queryParams.get('cloudAccountId') || queryParams.get('cloudaccountid') || '';
                const platform = queryParams.get('platform') || '';
                if (platform === Vendors.ALIBABA) {
                    return {
                        id: assetId,
                        type: assetType,
                        cloudAccountId: cloudAccountId,
                        platform: Vendors.ALIBABA,
                        name: assetId,
                        url: path,
                        hash: getHash(assetId, cloudAccountId),
                    };
                } else {
                    return {
                        id: assetId,
                        type: assetType || '',
                        platform: platform,
                        cloudAccountId: cloudAccountId,
                        url: path,
                        hash: getHash(assetId, cloudAccountId),
                        name: '',
                    };
                }
            }
        }
    }
}

export function getEnvironmentDisplayNameFromCloudAccount(cloudAccount: ICloudAccount, cloudAccountId: string) {
    if (cloudAccount.name) {
        return `${cloudAccount.name} (${cloudAccount.externalId})`;
    }
    return cloudAccount.externalId || cloudAccountId;
}

export async function getEnvironmentDisplayNameById(cloudAccountId: string): Promise<string | null> {
    if (cloudAccountId) {
        const cloudAccount = await getCloudAccountsService().getCloudAccountByAccountId(cloudAccountId);
        if (cloudAccount === null) {
            return null;
        }
        return getEnvironmentDisplayNameFromCloudAccount(cloudAccount, cloudAccountId);
    }
    return null;
}

export const getOrgUnitFullPath = async (orgUnitId: string | undefined): Promise<string> => {
    if (orgUnitId) {
        const ouPath = await getOrganizationalUnitService().getOrganizationalUnitPathById(orgUnitId);
        if (ouPath) {
            return (ouPath.map((ou) => ou.item.name).join('/'));
        }
    }
    const rootOrgUnit = await getOrganizationalUnitService().getOrganizationalUnitsView();
    return (rootOrgUnit?.name || '');
};

export async function getMatchingAssetsOptions(freeText: string | undefined, includedEntityTypes: string[]): Promise<SelectOption[]> {
    const requestObject: SearchRequest = {
        externalAdditionalFields: { source: EXTERNAL_ADDITIONAL_FIELDS_SOURCE.THIRD_PARTY },
        filter: {
            freeTextPhrase: freeText,
            includedEntityTypes,
        },
        pageSize: 100,
        skipAggregations: true,
    };

    const searchResult: ProtectedAssetsResponse = await getHttpService().request<ProtectedAssetsResponse>(
        ASSETS_SEARCH_URL,
        { data: requestObject, method: 'POST' },
        { cachingConfig: { useCache: true } },
    );

    return searchResult.assets.map(asset => ({
        label: asset.name,
        value: asset.name,
    }));
}