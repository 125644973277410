import styled from 'styled-components';

const ComponentWrapper = styled.div`
    height: calc(100vh);
    width: 100%;
`;

export const FullScreenModal = {
    ComponentWrapper: ComponentWrapper,
};
