import * as React from 'react';
import { SVGProps, Ref, forwardRef } from 'react';
const SvgCheck = (props: SVGProps<SVGSVGElement>, ref: Ref<SVGSVGElement>) => (
  <svg
    viewBox="0 0 16 16"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    ref={ref}
    {...props}
  >
    <path
      d="m2.5 9 3.716 4.13a1 1 0 0 0 1.521-.04L15.5 3.5"
      stroke="currentColor"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </svg>
);
const ForwardRef = forwardRef(SvgCheck);
export default ForwardRef;
