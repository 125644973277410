import * as React from 'react';
import { SVGProps, Ref, forwardRef } from 'react';
const SvgCcpa = (props: SVGProps<SVGSVGElement>, ref: Ref<SVGSVGElement>) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    viewBox="0 0 64 64"
    xmlSpace="preserve"
    ref={ref}
    {...props}
  >
    <path d="M32 0C14.3 0 0 14.3 0 32s14.3 32 32 32 32-14.3 32-32S49.7 0 32 0zm-8.8 56.5c-.6.4-1.3.6-2.1.6-1 0-1.9-.4-2.6-1.1-.7-.7-1-1.7-1-2.9 0-1.3.3-2.3 1-3.1.7-.7 1.6-1.1 2.7-1.1 1 0 1.8.3 2.4.9.4.3.6.8.8 1.5l-1.6.4c-.1-.4-.3-.7-.6-1-.3-.2-.7-.4-1.1-.4-.6 0-1.1.2-1.4.6-.4.4-.6 1.1-.6 2 0 1 .2 1.7.5 2.1.4.4.8.6 1.4.6.4 0 .8-.1 1.1-.4.3-.3.5-.7.7-1.3l1.5.5c-.1 1.1-.5 1.7-1.1 2.1zm-1-14.5c-1.6-1.9-2.5-4-2.9-6.4-.4-2.8 0-5.4 1.3-7.9.2-.3.3-.6.5-.9.1-.3.2-.5.2-.8v-3.8c0-2.1 0-4.3.1-6.5.2-2.9 1.4-5.3 3.5-7.3 1.6-1.5 3.5-2.5 5.7-2.7 4.2-.5 7.6 1 10.1 4.3 1.1 1.4 1.7 3 1.9 4.8.1.8.1 1.7.1 2.6V26c0 .2 0 .4.1.5 1 1.6 1.7 3.3 1.9 5.2.5 4.4-.9 8.2-4.3 11.6-1.8 1.6-4 2.6-6.4 3-3.6.6-7-.3-9.9-2.5-.7-.6-1.3-1.1-1.9-1.8zm9 14.5c-.6.4-1.3.6-2.1.6-1.1 0-1.9-.4-2.6-1.1-.7-.7-1-1.7-1-2.9 0-1.3.3-2.3 1-3.1.7-.7 1.6-1.1 2.7-1.1 1 0 1.8.3 2.4.9.4.3.6.8.8 1.5l-1.6.4c-.1-.4-.3-.7-.6-1-.3-.2-.7-.4-1.1-.4-.6 0-1.1.2-1.4.6-.4.4-.5 1.1-.5 2 0 1 .2 1.7.5 2.1.4.4.8.6 1.4.6.4 0 .8-.1 1.1-.4.3-.3.5-.7.7-1.3l1.5.5c-.2 1.1-.6 1.7-1.2 2.1zm7.7-3c-.3.2-.6.3-.8.4-.4.1-1 .1-1.7.1h-1v3h-1.6v-7.9h2.6c1 0 1.6 0 1.9.1.5.1.8.4 1.1.8.3.4.5.9.5 1.5 0 .5-.1.9-.3 1.2-.2.4-.5.7-.7.8zm6.8 3.5-.7-1.8h-3.2l-.6 1.8h-1.7l3.1-7.9h1.7l3.2 7.9h-1.8z" />
    <path d="M38.3 38.3s-.1.1 0 0c-.3.2-.4.4-.4.6 0 .2 0 .4-.1.5-.2.2-.1.4-.1.6 0 0 .1.1.1.2s.1.2.1.2c0 .1-.1.2-.2.2l-3 .3c-.1-.2-.1-.4-.2-.6s-.2-.4-.4-.6c-.2-.2-.4-.4-.6-.5-.1-.1-.2 0-.4 0V39c0-.2-.1-.3-.3-.3-.3 0-.6-.1-.9-.3-.3-.3-.8-.3-1.2-.3-.2 0-.3-.1-.3-.3s.1-.5 0-.6c-.1-.2-.2-.4-.4-.5-.3-.3-.6-.7-.9-1 0 0 0-.1-.1-.1-.3-.1-.3-.4-.3-.6 0-.1.1-.3.1-.4 0-.2-.1-.2-.3-.3-.1 0-.3-.1-.4-.2-.1-.3-.2-.7-.2-1 .2.2.3.4.5.6 0 0 .1 0 .1-.1l-.3-.6v-.4h-.1c-.1.1-.1.3-.2.4-.2-.1-.3-.2-.5-.3.1-.3-.1-.5-.3-.7l-.2-.2c-.4-.3-.4-.7-.5-1.1v-.2c.1-.5-.1-.9-.5-1.2-.2-.2-.3-.5-.1-.7.3-.4.2-.9.3-1.4 0-.1-.1-.3-.1-.4 0-.1-.1-.3-.1-.5h4.9c.3 0 .3.1.3.3v4.3c0 .2.1.3.2.5.5.4.9.9 1.4 1.3 1.6 1.5 3.3 3 4.9 4.5.1.1.2.3.3.5.1.2.2.4.4.6zM39.4 15.9v6.9c-2.2-1.5-4.7-2.3-7.4-2.3s-5.1.8-7.4 2.3V16c0-.9.1-1.7.4-2.5 1-2.6 2.9-4.3 5.7-4.8 4.2-.8 8 2 8.6 6.2 0 .3.1.7.1 1zM38.2 51.6c0 .2-.1.4-.2.6-.1.2-.3.3-.5.4-.2.1-.6.1-1.3.1h-.9v-2.2h.8c.6 0 1 0 1.1.1.3 0 .5.2.6.3.3.1.4.4.4.7zM44.5 53.9h-2.2l1.1-2.9z" />
  </svg>
);
const ForwardRef = forwardRef(SvgCcpa);
export default ForwardRef;
