import { CpCommonButton, CpIcon, CpTextBox, CpLoadingDots } from '@dome9/components/react/components';
import { FormFieldModel } from 'common/interface/formFieldModel';
import React, { useState } from 'react';
import ReCAPTCHA from 'react-google-recaptcha';
import { RouteComponentProps } from 'react-router-dom';
import { tryExtractResponseText, EMAIL_REGEX } from 'common/utils/http';
import CheckpointLogoHeader from './CheckpointLogoHeader';
import { LegalNotesFooter } from './LegalNotesFooter';
import './CaptchaValidationForm.scss';
import { useTranslation } from 'react-i18next';
import i18n from 'common/services/translations/translations';
import { getHttpService } from '../../interface/services';
import { Pages } from 'common/enum/Pages';
import { isChina } from '../../utils/dataCenter';

const EMAIL_INVALID_FORMAT = 'EMAIL_INVALID_FORMAT';
const EMAIL_NON_ENTERPRISE = 'EMAIL_NON_ENTERPRISE';
const INVALID_RECAPTCHA = 'INVALID_RECAPTCHA';

interface CaptchaFormProps extends RouteComponentProps {
    title: string;
    subTitle: string;
    action: Function;
}

class ClientMessageError extends Error {}

export const CaptchaValidationForm: React.FC<CaptchaFormProps> = ({ title, subTitle, action }) => {
    const [isLoading, setIsLoading] = useState<boolean>(false);
    const [messageSentSuccessfully, setMessageSentSuccessfully] = useState<boolean>(false);
    const [email, setEmail] = useState<FormFieldModel>({ value: '' });
    const [errorMessage, setErrorMessage] = useState<any>();
    const [recaptchaResponse, setRecaptchaResponse] = useState<any>();
    const handleCaptchaChange = (val: any) => {
        setRecaptchaResponse(val);
    };

    const { t } = useTranslation();

    const handleSubmit = async () => {
        setErrorMessage('');
        if (!EMAIL_REGEX.test(email.value)) {
            setEmail({ value: email.value, state: 'error', errorMessage: t('FORMS.CAPTCHA_FORM.EMAIL_ERROR') });
        } else {
            setIsLoading(true);
            try {
                await action({ email: email.value, recaptchaResponse: recaptchaResponse });
                setMessageSentSuccessfully(true);
            } catch (error) {
                setErrorMessage(tryExtractResponseText(error));
            } finally {
                setIsLoading(false);
            }
        }
    };

    return (
        <div className='captcha-form'>
            {isLoading && <CpLoadingDots append-to-body={true}></CpLoadingDots>}
            <CheckpointLogoHeader />
            <form className='validation-form flex-column form'>
                <div className='validation-form-container flex-column'>
                    {!messageSentSuccessfully ? (
                        <div className='content'>
                            <h2 className='text-uppercase form__title text-xl'>{title}</h2>
                            <h4 className='form__title my-8'>{subTitle}</h4>

                            <CpTextBox
                                className='email'
                                onCpChange={(ev: any) => setEmail({ value: ev.target.value })}
                                value={email.value}
                                label={t('FORMS.CAPTCHA_FORM.EMAIL')}
                                helperText={email.errorMessage}
                                state={email.state}
                                type={'email'}
                            />
                            {isChina() ? <></> : <ReCAPTCHA
                                sitekey='6LfWjyEUAAAAAATLmGjB1vgR5yg-m3RwD1EpFLOE'
                                onChange={handleCaptchaChange}
                            />}
                            <CpCommonButton className='submit-button' onClick={() => handleSubmit()}>
                                {t('FORMS.CAPTCHA_FORM.SUBMIT_BUTTON')}
                            </CpCommonButton>
                            <p className={'error-message'}>{errorMessage}</p>
                        </div>
                    ) : (
                        <div className='confirm-container content'>
                            <CpIcon className='check-icon' icon='check' color='green'></CpIcon>
                            <h1>{t('FORMS.CAPTCHA_FORM.VALIDATE_TEXT1')}</h1>
                            <h3>{t('FORMS.CAPTCHA_FORM.VALIDATE_TEXT2')}</h3>
                            <h3 className='email-address'>{email.value}</h3>
                            <h3>{t('FORMS.CAPTCHA_FORM.VALIDATE_TEXT3')}</h3>
                            <h3>
                                <span>{t('FORMS.CAPTCHA_FORM.VALIDATE_TEXT4')}</span>
                                <a href={`/v2/${Pages.ForgotPassword}`}>{t('FORMS.CAPTCHA_FORM.EDIT_EMAIL_LINK')}</a>
                            </h3>
                        </div>
                    )}
                    <LegalNotesFooter />
                </div>
            </form>
        </div>
    );
};

export const CaptchaValidationFormContentType = {
    FORGOT_PASSWORD: {
        title: i18n.t('FORMS.CAPTCHA_FORM.FORGOT_PASSWORD_TITLE'),
        subTitle: i18n.t('FORMS.CAPTCHA_FORM.FORGOT_PASSWORD_SUBTITLE'),
        action: (data: any) => {
            const httpService = getHttpService();
            return httpService.request('auth/reset-password-request', { method: 'POST', data }, { publicMode: true });
        },
    },
    REGISTER: {
        title: i18n.t('FORMS.CAPTCHA_FORM.REGISTER_TITLE'),
        subTitle: i18n.t('FORMS.CAPTCHA_FORM.REGISTER_SUBTITLE'),
        action: (data: any) => {
            const httpService = getHttpService();
            return httpService.request<any>('account-registration/invite', { method: 'POST', data }, { publicMode: true }, (error) => {
                const errorCode = error?.response?.status || -1;
                if (errorCode === 400) {
                    const responseText = error.response?.data?.message;
                    let translated = responseText;
                    switch (responseText) {
                        case EMAIL_INVALID_FORMAT:
                            translated = i18n.t('FORMS.CAPTCHA_FORM.EMAIL_ERROR');
                            break;
                        case EMAIL_NON_ENTERPRISE:
                            translated = i18n.t('FORMS.CAPTCHA_FORM.EMAIL_ERROR2');
                            break;
                        case INVALID_RECAPTCHA:
                            translated = i18n.t('FORMS.CAPTCHA_FORM.CAPTCHA_INVALID');
                            break;
                    }
                    if (translated) {
                        throw new ClientMessageError(translated);
                    }
                }
                throw error;
            });
        },
    },
};
