import styled from 'styled-components';
import { Stack } from '../../../../design-system/components-v2';

const Top = styled(Stack)`
    flex-direction: column;
    min-width: 100px;
    max-width: 100px;
    min-height: 100px;
    max-height: 100px;
    border: 3px solid darkred;
    border-radius: 50px;
`;

export const CalcStyled = {
    Top,
};
