import React from 'react';
import { FILTERS_KEYS, FILTER_DISPAY_TYPES, FILTER_TYPES } from '../FilterPanel.consts';
import { IFilterPanelView } from '../FilterPanel.interface';
import { Stack } from 'common/design-system/components-v2';

export const FilterPanelRow: React.FC<{ filterViewProps: IFilterPanelView }> = ({ filterViewProps }) => {
    const { filters, filtersValues, onEvent, lastEvent, filtersUtils, handlersValues } = filterViewProps;

    const addFiltersMemo = React.useMemo(() => {
        return filters.filter(filter => (filter.type === FILTER_TYPES.HANDLER && filter.filterProps.key === FILTERS_KEYS.ADD_FILTER))[0];
    }, [filters]);

    const clearAllMemo = React.useMemo(() => {
        return filters.find(filter => filter.type === FILTER_TYPES.HANDLER_CLEAR);
    }, [filters]);

    const restFilters = React.useMemo(() => {
        return filters.filter(filter => (filter.type === FILTER_TYPES.FILTER));
    }, [filters]);

    const insideHandlers = React.useMemo(() => {
        return filters.filter(filter => (filter.type === FILTER_TYPES.HANDLER && filter.filterProps.key !== FILTERS_KEYS.ADD_FILTER));
    }, [filters]);

    const outsideFiltersHandlers = React.useMemo(() => {
        return filters.filter(filter => (filter.type === FILTER_TYPES.FILTER_OUTSIDE || filter.type === FILTER_TYPES.HANDLER_OUTSIDE));
    }, [filters]);

    const hasInsideHandlers = insideHandlers.length > 0 && insideHandlers.some(filter => filter.displayTypes.includes(FILTER_DISPAY_TYPES.ROW));
    
    return (
        <Stack direction='row' alignItems='center' className='filter-panel-container-box'>
            <div className='filter-panel @container'>
                <Stack direction='row' alignItems='center' fullWidth>
                    <addFiltersMemo.filterElement
                        filterProps={{
                            ...addFiltersMemo.filterProps,
                            value: handlersValues[addFiltersMemo.filterProps.key].value,
                            currFilterData: handlersValues[addFiltersMemo.filterProps.key].data,
                            filtersUtils: filtersUtils,
                            onEvent,
                            lastEvent,
                            filtersValues,
                            displayType: FILTER_DISPAY_TYPES.ROW,
                        }}
                        key={addFiltersMemo.filterProps.key}
                    />
                    {restFilters.map((filter) => {
                        const { filterProps, displayTypes } = filter;
                        return (
                            <React.Fragment key={filterProps.key}>
                                {displayTypes.includes(FILTER_DISPAY_TYPES.ROW) && (
                                    <filter.filterElement
                                        filterProps={{
                                            ...filterProps,
                                            value:
                                            (filter.type === FILTER_TYPES.FILTER || filter.type === FILTER_TYPES.FILTER_OUTSIDE)
                                                ? filtersValues[filterProps.key]
                                                : handlersValues[filterProps.key].value,
                                            currFilterData:
                                            (filter.type === FILTER_TYPES.FILTER || filter.type === FILTER_TYPES.FILTER_OUTSIDE)
                                                ? lastEvent.result && lastEvent.result[filterProps.key]
                                                : handlersValues[filterProps.key].data,
                                            filtersUtils: filtersUtils,
                                            onEvent,
                                            lastEvent,
                                            filtersValues,
                                            displayType: FILTER_DISPAY_TYPES.ROW,
                                        }}
                                        key={filterProps.key}
                                    />
                                )}
                            </React.Fragment>
                        );
                    })}
                    {clearAllMemo && (
                        <clearAllMemo.filterElement
                            filterProps={{
                                ...clearAllMemo.filterProps,
                                value: handlersValues[clearAllMemo.filterProps.key].value,
                                currFilterData: handlersValues[clearAllMemo.filterProps.key].data,
                                filtersUtils: filtersUtils,
                                onEvent,
                                lastEvent,
                                filtersValues,
                                displayType: FILTER_DISPAY_TYPES.ROW,
                            }}
                            key={clearAllMemo.filterProps.key}
                        />
                    )}
                    {clearAllMemo && hasInsideHandlers && <div className='seperator' />}

                    {hasInsideHandlers && insideHandlers.map((filter) => {
                        const { filterProps, displayTypes } = filter;
                        return (
                            <React.Fragment key={filterProps.key}>
                                {displayTypes.includes(FILTER_DISPAY_TYPES.ROW) && (
                                    <filter.filterElement
                                        filterProps={{
                                            ...filterProps,
                                            value:
                                            (filter.type === FILTER_TYPES.FILTER || filter.type === FILTER_TYPES.FILTER_OUTSIDE)
                                                ? filtersValues[filterProps.key]
                                                : handlersValues[filterProps.key].value,
                                            currFilterData:
                                            (filter.type === FILTER_TYPES.FILTER || filter.type === FILTER_TYPES.FILTER_OUTSIDE)
                                                ? lastEvent.result && lastEvent.result[filterProps.key]
                                                : handlersValues[filterProps.key].data,
                                            filtersUtils: filtersUtils,
                                            onEvent,
                                            lastEvent,
                                            filtersValues,
                                            displayType: FILTER_DISPAY_TYPES.ROW,
                                        }}
                                        key={filterProps.key}
                                    />
                                )}
                            </React.Fragment>
                        );
                    })}
                </Stack>
            </div>
            <Stack direction='row' alignItems='center'>
                {outsideFiltersHandlers.map((filter) => {
                    const { filterProps, displayTypes } = filter;
                    return (
                        <React.Fragment key={filterProps.key}>
                            {displayTypes.includes(FILTER_DISPAY_TYPES.ROW) && (
                                <Stack margin={[0,0,0,2]}>
                                    <filter.filterElement
                                        filterProps={{
                                            ...filterProps,
                                            value:
                                        (filter.type === FILTER_TYPES.FILTER || filter.type === FILTER_TYPES.FILTER_OUTSIDE)
                                            ? filtersValues[filterProps.key]
                                            : handlersValues[filterProps.key].value,
                                            currFilterData:
                                        (filter.type === FILTER_TYPES.FILTER || filter.type === FILTER_TYPES.FILTER_OUTSIDE)
                                            ? lastEvent.result && lastEvent.result[filterProps.key]
                                            : handlersValues[filterProps.key].data,
                                            filtersUtils: filtersUtils,
                                            onEvent,
                                            lastEvent,
                                            filtersValues,
                                            displayType: FILTER_DISPAY_TYPES.ROW,
                                        }}
                                        key={filterProps.key}
                                    />
                                </Stack>
                            )}
                        </React.Fragment>
                    );
                })}
            </Stack>
        </Stack>
    );
};
export default FilterPanelRow;
