import { generalApiError } from 'common/utils/http';
import { clearCacheDataByTag, getCacheTag } from 'common/utils/apiCaching';
import { IRecipe, IRecipeExamplesService, IRecipeProps } from './RecipeExample.interface';
import {
    FAKE_RECIPE_CHEFS_URL,
    FAKE_RECIPE_DAIRY_PRODUCTS_URL,
    FAKE_RECIPE_MEAT_ALTERNATIVES_URL, FAKE_RECIPE_MEAT_PRODUCTS_URL,
    FAKE_RECIPE_SPICES_URL,
    FAKE_RECIPE_URL,
    FAKE_RECIPE_VEGETABLES_URL,
    getFakeRecipeHttpService,
    sendFakeRecipeHttpRequest,
} from './FakeRecipeServer/FakeRecipeServer.http';
import { IdResponse } from '../../utils/ermComponents.interface';
import { GenericObject } from '../../../interface/general';
import {
    convertDatesToValues, convertDateToValue,
    convertTagsToValues,
    convertValuesToDates, convertValuesToTags, convertValueToDate,
} from '../../custom/CustomForm/CustomForm.values';
import { IMeatCategory } from './FakeRecipeServer/FakeRecipeServer.data';

const SERVICE_NAME = 'recipe-example';
const getRecipeUrl = (recipeId: string) => `${FAKE_RECIPE_URL}/${recipeId}`;

export interface IRecipePropsModel extends GenericObject<any> {
    name: string;
    spices?: string[];
    vegetables?: string[];
    inventors?: string[];
    meatCategory?: string;
    meatTypes?: string[];
    meatAlternatives?: string[];
    dairyProducts?: string[];
    instructions?: string;
    deliveryDate?: string;
    tastingTimeFrame?: string[];
    chefs?: string[];
    tags?: string[];
    orgIds?: string[];
    id?: string;
}

export interface IRecipeModel extends IRecipePropsModel {
    id: string;
}

const createRecipeFromModel = (recipeModel: IRecipeModel): IRecipe => {
    return {
        name: recipeModel.name,
        spices: recipeModel.spices,
        vegetables: recipeModel.vegetables,
        inventors: recipeModel.inventors,
        meatCategory: recipeModel.meatCategory,
        meatTypes: recipeModel.meatTypes,
        meatAlternatives: recipeModel.meatAlternatives,
        dairyProducts: recipeModel.dairyProducts,
        instructions: recipeModel.instructions,
        deliveryDate: convertValueToDate(recipeModel.deliveryDate),
        tastingTimeFrame: recipeModel.tastingTimeFrame ? convertValuesToDates(recipeModel.tastingTimeFrame) : undefined,
        chefs: recipeModel.chefs,
        tags: recipeModel.tags ? convertValuesToTags(recipeModel.tags) : undefined,
        orgIds: recipeModel.orgIds,
        id: recipeModel.id,
    };
};

const createRecipesFromModels = (recipeModels: IRecipeModel[]): IRecipe[] => {
    return recipeModels.map(recipeModel => createRecipeFromModel(recipeModel));
};

const createRecipePropsModelFromRecipeProps = (recipe: IRecipeProps): IRecipePropsModel => {
    return {
        name: recipe.name,
        spices: recipe.spices,
        vegetables: recipe.vegetables,
        inventors: recipe.inventors,
        meatCategory: recipe.meatCategory,
        meatTypes: recipe.meatTypes,
        meatAlternatives: recipe.meatAlternatives,
        dairyProducts: recipe.dairyProducts,
        instructions: recipe.instructions,
        deliveryDate: convertDateToValue(recipe.deliveryDate),
        tastingTimeFrame: recipe.tastingTimeFrame ? convertDatesToValues(recipe.tastingTimeFrame) : undefined,
        chefs: recipe.chefs,
        tags: recipe.tags ? convertTagsToValues(recipe.tags) : undefined,
        orgIds: recipe.orgIds,
        id: recipe.id,
    };
};

export class RecipeExamplesService implements IRecipeExamplesService {
    private clearMultiActionsCache() {
        clearCacheDataByTag(SERVICE_NAME);
    }
    private clearSpecificActionCache(recipeId: string) {
        clearCacheDataByTag(SERVICE_NAME, recipeId);
    }

    public async getAllRecipes(): Promise<IRecipe[]> {
        const recipeModels: IRecipeModel[] = await sendFakeRecipeHttpRequest<IRecipeModel[]>(
            FAKE_RECIPE_URL, { method: 'GET' }, undefined,
            [getCacheTag(SERVICE_NAME)]);
        return createRecipesFromModels(recipeModels);
    }

    public async getAllSpices(): Promise<string[]> {
        return sendFakeRecipeHttpRequest<string[]>(
            FAKE_RECIPE_SPICES_URL, { method: 'GET' }, undefined,
            [getCacheTag(SERVICE_NAME)]);
    }

    public async getAllVegetables(): Promise<string[]> {
        return sendFakeRecipeHttpRequest<string[]>(
            FAKE_RECIPE_VEGETABLES_URL, { method: 'GET' }, undefined,
            [getCacheTag(SERVICE_NAME)]);
    }

    public async getAllDairyProducts(): Promise<string[]> {
        return sendFakeRecipeHttpRequest<string[]>(
            FAKE_RECIPE_DAIRY_PRODUCTS_URL, { method: 'GET' }, undefined,
            [getCacheTag(SERVICE_NAME)]);
    }

    public async getAllMeatCategories(): Promise<IMeatCategory[]> {
        return sendFakeRecipeHttpRequest<IMeatCategory[]>(
            FAKE_RECIPE_MEAT_PRODUCTS_URL, { method: 'GET' }, undefined,
            [getCacheTag(SERVICE_NAME)]);
    }

    public async getAllMeatAlternatives(): Promise<string[]> {
        return sendFakeRecipeHttpRequest<string[]>(
            FAKE_RECIPE_MEAT_ALTERNATIVES_URL, { method: 'GET' }, undefined,
            [getCacheTag(SERVICE_NAME)]);
    }

    public async getAllChefs(): Promise<string[]> {
        return sendFakeRecipeHttpRequest<string[]>(
            FAKE_RECIPE_CHEFS_URL, { method: 'GET' }, undefined,
            [getCacheTag(SERVICE_NAME)]);
    }


    public async updateRecipe(recipeId: string, recipeProps: IRecipeProps): Promise<void> {
        const propsModel: IRecipePropsModel = createRecipePropsModelFromRecipeProps(recipeProps);
        this.clearMultiActionsCache();
        this.clearSpecificActionCache(recipeId);
        void getFakeRecipeHttpService().request<void>(getRecipeUrl(recipeId), {
            method: 'PUT',
            data: propsModel,
        }, undefined, generalApiError);
    }

    public async createRecipe(recipeProps: IRecipeProps): Promise<string> {
        const propsModel: IRecipePropsModel = createRecipePropsModelFromRecipeProps(recipeProps);
        this.clearMultiActionsCache();
        const response: IdResponse = await getFakeRecipeHttpService().request<IdResponse>(FAKE_RECIPE_URL, {
            method: 'POST',
            data: propsModel
        }, undefined, generalApiError);
        return response.id;
    }

    public async deleteRecipe(recipeId: string, multiTagAlreadyCleared?: boolean): Promise<void> {
        if (!multiTagAlreadyCleared) {
            this.clearMultiActionsCache();
        }
        this.clearSpecificActionCache(recipeId);
        void getFakeRecipeHttpService().request(getRecipeUrl(recipeId), {
            method: 'DELETE',
        }, undefined, generalApiError);
    }

    public async deleteRecipes(recipeIds: string[]): Promise<void> {
        this.clearMultiActionsCache();
        const promises = recipeIds.map(recipeId => {
            return this.deleteRecipe(recipeId, true);
        });
        void Promise.all(promises).then(() => []);
    }
}

let recipeExampleService: IRecipeExamplesService | undefined;
export function getRecipeExampleService(): IRecipeExamplesService {
    if (!recipeExampleService) {
        recipeExampleService = new RecipeExamplesService();
    }
    return recipeExampleService;
}
