import protectedAssetTabsReducer from './ProtectedAssetTabs.reducer';
import { APPLICATION_REDUCERS } from '../../extensibility/WellKnownPaths';
import { globalAddinContainer } from '../../extensibility/AddinContainer';

export function initializeProtectedAssets() {
    globalAddinContainer.addMap(APPLICATION_REDUCERS, [{
        name: 'protectedAssetTabs',
        reducer: protectedAssetTabsReducer,
        isBlackList: false,
    }], 'name');
}