import styled from 'styled-components';
import { Stack } from 'common/design-system/components-v2';

const TableDiv = styled(Stack)`
    margin-top: 10px;
    height: 400px;
`;

const SelectWrapperDiv = styled(Stack)`
    display: flex;
    flex-direction: row;
    gap: 10px;
    max-width: 20%;
`;

const CompoundFilterWrapperDiv = styled(Stack)`
    gap: 10px;
`;

const TimeFilterWrapperDiv = styled(Stack)`
    flex-direction: row;
    gap: 10px;
    align-items: center;
`;

const QueryConfigurationsWrapperDiv = styled(Stack)`
    display: flex;
    flex-direction: column;
    gap: 10px;
    width: 100%;
`;

const QueryOptionsWrapperDiv = styled(Stack)`
    gap: 10px;
`;

export const GslQueryFilterExampleStyled = {
    TableDiv: TableDiv,
    SelectWrapperDiv: SelectWrapperDiv,
    CompoundFilterWrapperDiv: CompoundFilterWrapperDiv,
    QueryConfigurationsWrapperDiv: QueryConfigurationsWrapperDiv,
    TimeFilterWrapperDiv: TimeFilterWrapperDiv,
    QueryOptionsWrapperDiv: QueryOptionsWrapperDiv,
};
