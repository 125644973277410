import * as React from 'react';
import { SVGProps, Ref, forwardRef } from 'react';
const SvgRuntimeProtection = (
  props: SVGProps<SVGSVGElement>,
  ref: Ref<SVGSVGElement>
) => (
  <svg
    viewBox="0 0 16 16"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    ref={ref}
    {...props}
  >
    <path
      d="m15.104 8.056-2.5-.534a.5.5 0 0 0-.208 0l-2.5.534a.5.5 0 0 0-.396.488v3.436c0 1.223 1.217 2.316 2.101 2.949a1.534 1.534 0 0 0 1.798 0c.884-.633 2.101-1.726 2.101-2.949V8.544a.5.5 0 0 0-.396-.488Z"
      stroke="currentColor"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="m12.045 5.96-1.89-.158a.5.5 0 0 1-.457-.456l-.077-.924a.5.5 0 0 0-.822-.34l-.707.598a.5.5 0 0 1-.646 0l-.707-.598a.5.5 0 0 0-.822.34l-.077.924a.5.5 0 0 1-.456.456l-.924.077a.5.5 0 0 0-.34.822l.598.707a.5.5 0 0 1 0 .646l-.598.707a.5.5 0 0 0 .34.822l.924.077a.5.5 0 0 1 .456.456l.082.983a.5.5 0 0 0 .801.356l.442-.336"
      stroke="currentColor"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M8 9.5a1.5 1.5 0 1 1 .559-2.892"
      stroke="currentColor"
      strokeLinecap="round"
    />
    <circle
      cx={2.5}
      cy={12.5}
      r={1.5}
      transform="rotate(-90 2.5 12.5)"
      stroke="currentColor"
    />
    <circle
      cx={12.5}
      cy={2.5}
      r={1.5}
      transform="rotate(-90 12.5 2.5)"
      stroke="currentColor"
    />
    <path
      d="M1.613 11.288A7 7 0 0 1 9.198.709m4.189 3.003A7 7 0 0 1 14.337 6M5.802 14.29c.881.22 1.793.266 2.683.14"
      stroke="currentColor"
      strokeLinecap="round"
    />
  </svg>
);
const ForwardRef = forwardRef(SvgRuntimeProtection);
export default ForwardRef;
