import styled, { css } from 'styled-components';
import Stack from '../Stack/Stack';

interface IWrapperProps {
    maxWidth?: number;
    minWidth?: number;
}
const Wrapper = styled(Stack)<IWrapperProps>`
    overflow: hidden;
    max-width: 100%;
    ${({ minWidth, theme }) => minWidth && css`
        min-width: ${minWidth}${theme.units};
    `};
    ${({ maxWidth, theme }) => maxWidth && css`
        max-width: ${maxWidth}${theme.units};
    `};
`;
const ComponentOverflowStyles = {
    Wrapper,
};

export default ComponentOverflowStyles;
