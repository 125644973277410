import { AllIconsName } from 'common/design-system/components-v2/Icon/Icon.types';
export enum ActiveFeatureTopicEnum {
    ERM = 'ERM',
    CDR = 'CDR',
    EXAMPLES = 'EXAMPLES',
    LOG = 'LOG',
    DEV = 'DEV',
}

let position = 0;
export const ActiveFeatureTopicsMap: { [key in ActiveFeatureTopicEnum]: IActiveFeatureTopic } = {
    ERM: {
        key: ActiveFeatureTopicEnum.ERM,
        title: 'ERM',
        icon: 'riskManagement4',
        position: position++,
    },
    CDR: {
        key: ActiveFeatureTopicEnum.CDR,
        title: 'CDR',
        icon: 'microscope',
        position: position++,
    },
    EXAMPLES: {
        key: ActiveFeatureTopicEnum.EXAMPLES,
        title: 'Examples',
        icon: 'book',
        position: position++,
    },
    LOG: {
        key: ActiveFeatureTopicEnum.LOG,
        title: 'Log',
        icon: 'log',
        position: position++,
    },
    DEV: {
        key: ActiveFeatureTopicEnum.DEV,
        title: 'Dev',
        icon: 'log',
        position: position++,
    },
};

export interface IActiveFeatureInfo {
    key: string;
    description: string;
    hidden?: boolean;
    topic: ActiveFeatureTopicEnum;
}

export interface IActiveFeatureStatusInfo extends IActiveFeatureInfo {
    isActive: boolean;
    serverActive: boolean;
    localStorageActive: boolean;
}

export interface IActiveFeatureTopic {
    key: ActiveFeatureTopicEnum;
    title: string;
    icon: AllIconsName;
    position: number;
}