import React, { useCallback, useMemo } from 'react';
import { ICloudEntityData } from 'common/module_interface/assets/ICloudEntity';
import { EventOverviewRegistry } from 'common/module_interface/events/EventOverviewRegistry';
import { getCloudEntityData } from 'common/components/ProtectedAssets/AssetUtils';
import { AssetPropertiesPanel } from 'common/components/AssetPropertiesPanel/AssetPropertiesPanel';
import { EntityNameLink } from './EntityNameLink';
import { t } from 'i18next';
import { FINDING_ASSET_PANEL_ID } from '../../../module_interface/intelligence/Findings/Findings.const';

const getCloudEntityDataFromFinding = async (entityId: string, 
    entityExternalId: string, 
    entityType: string, 
    cloudAccountType: string, 
    cloudAccountId: string): Promise<ICloudEntityData | undefined> => {
    return getCloudEntityData(entityId||entityExternalId, entityType, cloudAccountType, cloudAccountId, err => { throw err; }).then((entityData: ICloudEntityData | null) => {
        return entityData || undefined;
    });
};

export const FindingAssetPanel: React.FC<{ entityName: string,
    entityType: string,
    entityDome9Id: string,
    entityExternalId: string, 
    cloudAccountType: string, 
    cloudAccountId: string }> =
    ({ entityName, entityExternalId, entityDome9Id, entityType, cloudAccountType, cloudAccountId }) => {

        const EntityNameLinkComponent = useMemo(() => {
            return <EntityNameLink entityName={entityName}
                entityType={entityType}
                entityId={entityDome9Id}
                entityExternalId={entityExternalId}
                cloudAccountId={cloudAccountId} />;
        }, [entityName, entityType, entityDome9Id, entityExternalId, cloudAccountId]);

        const entityFields = useMemo(() => {
            return EventOverviewRegistry.getFields(FINDING_ASSET_PANEL_ID);
        }, []);

        const getCloudEntityFromFinding = useCallback(async () => {
            return getCloudEntityDataFromFinding(entityDome9Id, entityExternalId, entityType, cloudAccountType, cloudAccountId);
        }, [entityDome9Id, entityExternalId, entityType, cloudAccountType, cloudAccountId]);

        return <AssetPropertiesPanel registeredEntityFields={entityFields}
            typeByPlatform={cloudAccountType + '|' + entityType}
            getCloudEntity={getCloudEntityFromFinding}
            headerComponent={EntityNameLinkComponent}
            title={t('EVENTS.FOUND_IN_ASSET')}
        />;
    };
