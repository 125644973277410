import * as React from 'react';
import { SVGProps, Ref, forwardRef } from 'react';
const SvgCloud = (props: SVGProps<SVGSVGElement>, ref: Ref<SVGSVGElement>) => (
  <svg
    viewBox="0 0 16 16"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    ref={ref}
    {...props}
  >
    <path
      d="M14.78 7.5a3.53 3.53 0 0 0-2.131-1.336 4.02 4.02 0 0 0-1.172-2.472A4.13 4.13 0 0 0 6.3 3.183 4.045 4.045 0 0 0 4.658 5.38a4.083 4.083 0 0 0-.426-.023 3.643 3.643 0 0 0-2.59.966 3.555 3.555 0 0 0-1.14 2.492 3.55 3.55 0 0 0 .978 2.559 3.637 3.637 0 0 0 2.523 1.126h8.336a3.119 3.119 0 0 0 1.682-.447 3.066 3.066 0 0 0 1.17-1.274A3.021 3.021 0 0 0 14.79 7.5h-.009Z"
      stroke="currentColor"
    />
  </svg>
);
const ForwardRef = forwardRef(SvgCloud);
export default ForwardRef;
