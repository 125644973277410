import * as React from 'react';
import { SVGProps, Ref, forwardRef } from 'react';
const SvgWeb = (props: SVGProps<SVGSVGElement>, ref: Ref<SVGSVGElement>) => (
  <svg
    viewBox="0 0 33 32"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    ref={ref}
    {...props}
  >
    <path
      d="M31.5 16c0 8.284-6.716 15-15 15m15-15c0-8.284-6.716-15-15-15m15 15h-30m15 15c-8.284 0-15-6.716-15-15m15 15c3.866 0 7-6.716 7-15 0-8.284-3.134-15-7-15m0 30c-3.866 0-7-6.716-7-15 0-8.284 3.134-15 7-15m-15 15c0-8.284 6.716-15 15-15"
      stroke="currentColor"
      strokeOpacity={0.99}
      strokeWidth={2}
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </svg>
);
const ForwardRef = forwardRef(SvgWeb);
export default ForwardRef;
