import styled from 'styled-components';
import Stack from '../../Stack';

const Wrapper = styled(Stack)`
    min-height: 0;
`;

const StepperWrapper = styled(Stack)`
    min-width: 196px;
`;

const ContentWrapper = styled.div`
    overflow: auto;
    flex: 1;
`;

const WizardContentStyles = {
    Wrapper,
    StepperWrapper,
    ContentWrapper
};

export default WizardContentStyles;