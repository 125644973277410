import React from 'react';
import { IProtectedAssetViewModel } from '../../../components/ProtectedAssets/ProtectedAssetsTable.interface';
import { IFilterCondition } from '../../../erm-components/custom/FilterTree/FilterCondition';
import { ICloudEntityData } from '../../assets/ICloudEntity';

export enum IdType {
    DOME9_ID_TYPE = 'Dome9Id',
    EXTERNAL_ID_TYPE = 'ExternalId',
}

export enum IdField {
    FIELD_NAME_ID = 'id',
    FIELD_NAME_ENTITY_ID = 'entityId',
}

export enum IssueStatusEnum {
    OPEN = 'Open',
    CLOSED = 'Closed',
}

export const ISSUE_ORIGIN_TYPE = {
    SECURITY_GRAPH: 'security-graph',
};

export enum SystemLabels {
    VULNERABILITIES = 'Vulnerabilities',
    NETWORK_EXPOSURE = 'Network Exposure',
    HIGH_IAM_PRIVILEGES = 'High IAM Privileges',
    MALWARE = 'Malware',
}

export enum IssueSeverityEnum {
    critical = 'critical',
    high = 'high',
    medium = 'medium',
    low = 'low',
    informational = 'informational',
    unknown = 'unknown',
}

export interface IIssueViewModel {
    accountId: string;
    alertType: string;
    createdTime: string;
    description?: string;
    entityId: string;
    entityIdType: string;
    entityExternalId: string;
    entityLabels: string[];
    entityName: string;
    entityTags: string[];
    entityType: string;
    entityTypeByPlatform: string;
    environmentExternalId: string;
    environmentId: string;
    findingKey: string;
    frameworks: string[];
    id: string;
    isExcluded: boolean;
    organizationalUnitId: string;
    origin: string;
    payload: string[];
    platform: string;
    region: string;
    remediation?: string;
    ruleId: string;
    ruleLogic: string;
    ruleTitle: string;
    severity: number;
    status: IssueStatusEnum;
    statusReason: string;
    systemLabels: SystemLabels[];
    updatedTime: string;
}

export interface IIssue extends IIssueViewModel {
    idType: IdType,
    isGrouped?: boolean;
}

export interface IIssueViewerPanelProvider {
    panelId: string;
    panel: React.FC<{ issue: IIssue }>;
    isRelevant?: (issue: IIssue) => boolean;
    permission?: () => boolean;
}

export interface IServerInputFreeText {
    text: string;
    fieldNames: string[];
}

export interface IServerInputFilterDetails {
    fields?: IFilterCondition[];
    freeText?: IServerInputFreeText;
    orgUnitsIds?: string[];
}

export interface IEvidenceInfo {
    cloudEntity: ICloudEntityData;
    title: string;
}

export interface IIssueEvidence {
    id: string;
    title: string;
    isRelevant: (issue: IIssue, asset: IProtectedAssetViewModel) => boolean;
    component: React.FC<{ issue: IIssue, asset: IProtectedAssetViewModel, title: string, initiallyOpen?: boolean }>;
    extraInfo?: React.FC<{
        issue: IIssue,
        asset: IProtectedAssetViewModel,
        evidenceInfo?: IEvidenceInfo,
    }>;
}

type IssueSeverityCountersType = {
    [key in IssueSeverityEnum]? : number
}

export interface IIssueSeverityCounters extends IssueSeverityCountersType {
    informational: number;
    low: number;
    medium: number;
    high: number;
    critical: number;
}
