import * as React from 'react';
import { SVGProps, Ref, forwardRef } from 'react';
const SvgSemiPublic = (
  props: SVGProps<SVGSVGElement>,
  ref: Ref<SVGSVGElement>
) => (
  <svg
    viewBox="0 0 16 16"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    ref={ref}
    {...props}
  >
    <circle cx={8} cy={8} r={7.5} stroke="currentColor" />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M11.59 4.41a10.415 10.415 0 0 0-.662-1.925C10.276 1.09 9.276 0 8 0S5.724 1.089 5.072 2.485C4.508 3.694 4.134 5.265 4.03 7H1a.5.5 0 0 0 0 1h3c0 1.286.147 2.507.41 3.59l.841-.841A14.71 14.71 0 0 1 5 8h3l1-1H5.032c.103-1.607.45-3.03.946-4.092C6.593 1.59 7.343 1 8 1c.657 0 1.407.59 2.022 1.908.306.657.556 1.452.727 2.343l.84-.84Z"
      fill="currentColor"
    />
    <path
      d="m13.305 2.695-10.61 10.61"
      stroke="currentColor"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <circle cx={10.5} cy={9.5} r={1} stroke="currentColor" />
    <path
      d="M10.5 10.5V12"
      stroke="currentColor"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </svg>
);
const ForwardRef = forwardRef(SvgSemiPublic);
export default ForwardRef;
