import { configureStore } from '@reduxjs/toolkit';
import { combineReducers } from 'redux';
import { PERSIST, persistReducer, persistStore } from 'redux-persist';
import storage from 'redux-persist/lib/storage';

import { APPLICATION_REDUCERS } from '../../extensibility/WellKnownPaths';
import { IApplicationReducer } from '../../interface/redux';
import { globalAddinContainer } from '../../extensibility/AddinContainer';
import { getStoreService } from '../../interface/services';
import { Persistor } from 'redux-persist/es/types';

let persistor: any = null;
export function getGlobalPersistor():Persistor {
    const storeService = getStoreService();
    const store = storeService.store;
    persistor = persistor || persistStore(store);
    return persistor;
}
export function createStore() {
    const reducers = globalAddinContainer.get(APPLICATION_REDUCERS) as IApplicationReducer[];
    const combinedReducer: { [index: string]: any } = {};
    const blacklist: string[] = [];
    reducers.forEach((appReducer) => {
        combinedReducer[appReducer.name] = appReducer.reducer;
        if (appReducer.isBlackList) {
            blacklist.push(appReducer.name);
        }
    });

    const persistConfig = {
        key: 'CloudGuardRootStore',
        blacklist,
        storage,
    };

    const persistedReducer = persistReducer(persistConfig, combineReducers(combinedReducer));
    return configureStore({
        reducer: persistedReducer,
        middleware: (getDefaultMiddleware) =>
            getDefaultMiddleware({
                serializableCheck: {
                    ignoredActions: [PERSIST],
                },
            }),
    });
}

export type RootState = ReturnType<any>;
export type DispatchAction = <AnyAction>(action: AnyAction) => AnyAction;
