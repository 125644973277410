import { forwardRef } from 'react';
import TypographyStyles from './Typography.styles';
import { ITypographyProps } from './Typography.types';

const Typography = forwardRef<HTMLDivElement, ITypographyProps>((props, ref) => {
    const { variant = 'body', elementType = 'p', color = 'normal', type = 'body', children, ellipsis, ...rest } = props;

    const isNewVariant = ['xs', 'sm', 'md', 'lg', 'xl', '2xl', '3xl', '4xl', '5xl'].includes(variant);

    return (
        <TypographyStyles.Wrapper variant={variant} color={color} as={elementType} type={type} ellipsis={ellipsis} isNewVariant={isNewVariant} {...rest} ref={ref}>
            {(isNewVariant && ellipsis) ? <div>{children}</div> : children}
        </TypographyStyles.Wrapper>
    );
});
Typography.displayName = 'Typography';

export default Typography;
