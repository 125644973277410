import { Stack } from 'common/design-system/components-v2';
import styled from 'styled-components';

const SelectWrapper = styled(Stack)`
    min-width: 200px;
`;

const GroupBySelectStyles = {
    SelectWrapper
};

export default GroupBySelectStyles;
