import * as React from 'react';
import { SVGProps, Ref, forwardRef } from 'react';
const SvgMicroscope = (
  props: SVGProps<SVGSVGElement>,
  ref: Ref<SVGSVGElement>
) => (
  <svg
    viewBox="0 0 16 16"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    ref={ref}
    {...props}
  >
    <path
      d="M15.478 12.5h-2.995v-3c0-2.5-1.498-4-2.996-4H7.49v-3m7.988 10s.049-2.097 0-3c0-4.5-2.995-7-5.99-7H7.49m7.988 10H2.996m12.482 0v3H.5m6.99-13H3.745a.25.25 0 0 0-.25.25V7a.5.5 0 0 0 .5.5h2.996A.5.5 0 0 0 7.49 7V2.5Zm0 10s0 3-4.494 3m1.508-13h1.977a.01.01 0 0 0 .01-.01V.51a.01.01 0 0 0-.01-.01H4.504a.01.01 0 0 0-.01.01v1.98c0 .006.005.01.01.01Z"
      stroke="currentColor"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </svg>
);
const ForwardRef = forwardRef(SvgMicroscope);
export default ForwardRef;
