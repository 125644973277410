import { buildPath, globalAddinContainer } from '../../../extensibility/AddinContainer';
import { PATH_ISSUE_VIEWER_PANELS } from './IssueViewerRegistry';
import { IIssueEvidence } from './Issues.interface';

const PATH_ISSUE_EVIDENCE = buildPath(PATH_ISSUE_VIEWER_PANELS, 'evidence');

export const EvidencesRegistry = {
    addItem: (position: number, item: IIssueEvidence) => {
        globalAddinContainer.add<IIssueEvidence>(PATH_ISSUE_EVIDENCE, [{
            id: item.id,
            position,
            content: item
        }]);
    },

    getItems: (): IIssueEvidence[] => {
        return globalAddinContainer.get<IIssueEvidence>(PATH_ISSUE_EVIDENCE);
    },
};
