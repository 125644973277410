import React from 'react';
import { ICellRendererParams } from 'ag-grid-enterprise';
import { IMultiSelectorItem, IMultiSelectorItemsMap } from './MultiSelector.interface';
import { CellStyled } from './MultiSelector.styled';
import { isNil } from '../../utils/helpFunctions';

export const ItemContent: React.FC<{ item: IMultiSelectorItem, isSemiSelected: boolean, isSelected: boolean, isDisabled: boolean, disableReason?: string }> = ({ item, isSemiSelected, isSelected, isDisabled, disableReason }) => {
    if (!item) {
        return '';
    }
    const disableTooltip = (isDisabled && disableReason) ? disableReason : '';
    return (
        <CellStyled.CellDiv isDisabled={isDisabled}>
            {item.iconName &&
                <CellStyled.CellIconDiv>
                    <CellStyled.CellIcon color={'undefined'} name={item.iconName} size={16} />
                </CellStyled.CellIconDiv>}
            <CellStyled.CellTooltip content={disableTooltip}>
                <CellStyled.TextDiv isHighlighted={isSemiSelected || isSelected} isDisabled={isDisabled}>{item.label}</CellStyled.TextDiv>
            </CellStyled.CellTooltip>
        </CellStyled.CellDiv>
    );
};

export const MultiSelectorItemCellRenderer: React.FC<ICellRendererParams<IMultiSelectorItem> & {
    semiSelectedIdsMap?: IMultiSelectorItemsMap,
    selectedItemsMap: IMultiSelectorItemsMap,
    nonSelectableIdsSet?: Set<string>,
    nonSelectableReason?: string,
} > = (params) => {
    const item: IMultiSelectorItem | undefined = params.data;
    if (!item) {
        return '';
    }
    const isDisabled = params.nonSelectableIdsSet ? params.nonSelectableIdsSet.has(item.id) : false;
    return <ItemContent item={item} isDisabled={isDisabled} isSemiSelected={!isNil(params.semiSelectedIdsMap?.[item.id])} isSelected={!isNil(params.selectedItemsMap?.[item.id])} disableReason={params.nonSelectableReason}/>;
};