import styled from 'styled-components';

const Wrapper = styled.div`
    pointer-events: none;
    opacity: 0.5;
    height: 31px;
`;

export default {
    Wrapper
};
