export enum NotificationType {
    PENDING = 'pending',
    SUCCESS = 'success',
    ERROR = 'error',
    INFO = 'info',
    WARNING = 'warning'
}


export const NOTIFICATIONS_TARGET_IDS_DTO_MAPPER : any = {
    'HTTP Endpoint': 'Webhook',
    'Email report': 'AggregatedEmail',
    'Teams report': 'Teams',
    'Slack report': 'Slack',
    'Teams Critical Events': 'Teams',
    'Slack Critical Events': 'Slack',
    'Each finding': 'EmailPerFinding',
    'SNS': 'Sns',
    'aws security hub': 'AwsSecurityHub',
    'pager duty': 'PagerDuty',
    'Azure Defender for Cloud': 'AzureSecurityCenter',
    'Alert Console': 'Alert Console',
    'GCP Security Command Center': 'GcpSecurityCommandCenter',
    'General Security Events Per Occurrence': 'General Security Events Per Occurrence'
};


export interface Notification {
    id?: string;
    type: NotificationType;
    title?: string;
    text?: string;
    autoClose?: number;
    time?: string;
}

type NotificationMap = Partial<Record<NotificationType, Pick<Notification, 'title'> & { text?: string }>>;

export interface PromiseNotification<T = any> extends NotificationMap {
    promise: Promise<T>;
    autoClose?: number;
    errorFormatter?: (error: any) => string;
}
