import * as React from 'react';
import { SVGProps, Ref, forwardRef } from 'react';
const SvgLocation = (
  props: SVGProps<SVGSVGElement>,
  ref: Ref<SVGSVGElement>
) => (
  <svg
    viewBox="0 0 16 16"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    ref={ref}
    {...props}
  >
    <path
      d="M13.5 5.943C13.5 2.945 11.025.5 8 .5S2.5 2.945 2.5 5.943v.092c0 1.846 1.198 3.066 2.2 4.521 1 1.258 1.89 2.406 2.494 3.19a1.004 1.004 0 0 0 1.6-.009c.596-.797 1.462-1.958 2.368-3.18C12.397 8.891 13.5 7.88 13.5 6.034v-.092Z"
      stroke="currentColor"
      strokeLinejoin="round"
    />
    <circle cx={8} cy={6} r={2.5} stroke="currentColor" />
  </svg>
);
const ForwardRef = forwardRef(SvgLocation);
export default ForwardRef;
