import React from 'react';
import { ICellRendererParams } from 'ag-grid-enterprise';
import { CellStyled } from 'common/components/ag-grid/Renderers/AgGridCellRenderers.styled';

export const BooleanBulletCellRender: React.FC<ICellRendererParams> = (params) => {
    const value = Boolean(params.value);
    return (
        <CellStyled.TopDiv>
            <CellStyled.BulletDiv value={value}></CellStyled.BulletDiv>
        </CellStyled.TopDiv>
    );
};