import * as React from 'react';
import { SVGProps, Ref, forwardRef } from 'react';
const SvgColumns = (
  props: SVGProps<SVGSVGElement>,
  ref: Ref<SVGSVGElement>
) => (
  <svg
    viewBox="0 0 16 16"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    ref={ref}
    {...props}
  >
    <path
      d="M9.5 1.5h6m-9 0h-4m7 3h6m-9 0h-6m9 3h6m-9 0h-6m9 3h6m-9 0h-6m9 3h4m-7 0h-6"
      stroke="currentColor"
      strokeLinecap="round"
    />
  </svg>
);
const ForwardRef = forwardRef(SvgColumns);
export default ForwardRef;
