import { ReactNode } from 'react';
import { DividerItemComponent } from './components/itemComponents/DividerItemComponent';
import { LabelItemComponent } from './components/itemComponents/LabelItemComponent';
import { TextAreaItemComponent } from './components/itemComponents/TextAreaItemComponent';
import { InputItemComponent } from './components/itemComponents/InputItemComponent';
import { MultiSelectItemComponent } from './components/itemComponents/MultiSelectItemComponent';
import { OrgUnitsItemComponent } from './components/itemComponents/OrgUnitsItemComponent';
import { DateRangeItemComponent } from './components/itemComponents/DateRangeItemComponent';
import { TagsItemComponent } from './components/itemComponents/TagsItemComponent';
import { LazySelectItemComponent } from './components/itemComponents/LazySelectItemComponent';
import { CustomItemComponent } from './components/itemComponents/CustomItemComponent';
import { FormStyled } from './CustomForm.styled';
import { RadioButtonOptionComponent } from './components/helperComponents/RadioButtonOptionComponent';
import { ErrorComponent } from './components/helperComponents/ErrorComponent';
import { Stack } from '../../../design-system/components-v2';
import { SingleSelectItemComponent } from './components/itemComponents/SingleSelectItemComponent';
import {
    IDividerItem,
    IFieldItem, IFullErrorInfo, IFullFormDetails,
    IItem, IItemsMap,
    ILabelItem,
    IRadioItem, IRadioOption, IRadioOptionProps, IBoxItem,
    ISimpleItem, ISpacing,
} from './CustomForm.interface';
import {
    getActiveItemsMap,
    getItemLabel, getRadioOptionItems, getVisibleRadioOptionItems, getVisibleRowSubItems,
    isRadioHasOptionsWithMultiFields,
} from './CustomForm.values';
import { FormFieldType, FormItemType, FormSimpleItemType } from './CustomForm.consts';
import { FieldLabelComponent } from './components/helperComponents/FieldLabelComponent';
import { SingleDateItemComponent } from './components/itemComponents/SingleDateItemComponent';
import { MultiInputItemComponent } from './components/itemComponents/MultiInputItemComponent';
import { AlignItems, Direction, JustifyContent } from '../../../design-system/components-v2/Stack/Stack.types';

const createDividerComponent = (formDetails: IFullFormDetails, dividerItem: IDividerItem): ReactNode => {
    return (
        <DividerItemComponent
            key={dividerItem.name}
            isInner={formDetails.isInnerComponent}
            item={dividerItem}
        />
    );
};

const createLabelComponent = (formDetails: IFullFormDetails, item: ILabelItem): ReactNode => {
    const { fullErrorsInfo, wasSubmitted } = formDetails;

    return (
        <LabelItemComponent
            key={item.name}
            isInner={formDetails.isInnerComponent}
            item={item}
            errorInfo={fullErrorsInfo.errorsMap[item.name]}
            showErrors={wasSubmitted}
        />
    );
};

const createFieldComponent = (formDetails: IFullFormDetails, item: IFieldItem, skipTitle?: boolean): ReactNode => {
    const { itemsMap, fullErrorsInfo, wasSubmitted, onFieldChanged } = formDetails;
    const commonParams = {
        key: item.name,
        errorInfo: fullErrorsInfo.errorsMap[item.name],
        skipTitle,
        onFieldChanged: onFieldChanged,
        showErrors: wasSubmitted,
        isInner: formDetails.isInnerComponent,
    };

    const activeItemsMap: IItemsMap = getActiveItemsMap(formDetails.params, itemsMap);
    switch(item.fieldType) {
        case FormFieldType.input:
            return <InputItemComponent {...commonParams} item={item} />;

        case FormFieldType.textArea:
            return <TextAreaItemComponent{...commonParams} item={item} />;

        case FormFieldType.multiInput:
            return <MultiInputItemComponent{...commonParams} item={item} />;

        case FormFieldType.singleSelect:
            return <SingleSelectItemComponent {...commonParams} item={item} />;

        case FormFieldType.multiSelect:
            return <MultiSelectItemComponent {...commonParams} item={item} />;

        case FormFieldType.orgUnits:
            return <OrgUnitsItemComponent {...commonParams} item={item} />;

        case FormFieldType.singleDate:
            return <SingleDateItemComponent {...commonParams} item={item} now={formDetails.params.now} />;

        case FormFieldType.dateRange:
            return <DateRangeItemComponent {...commonParams} item={item} now={formDetails.params.now} />;

        case FormFieldType.tags:
            return <TagsItemComponent {...commonParams} item={item} />;

        case FormFieldType.lazySelect:
            return <LazySelectItemComponent {...commonParams} item={item} itemsMap={activeItemsMap} />;

        case FormFieldType.custom:
            return <CustomItemComponent {...commonParams} item={item} itemsMap={activeItemsMap} />;
    }
};

const createRadioOptionProps = (formDetails: IFullFormDetails, option: IRadioOption): IRadioOptionProps => {
    const optionProps: IRadioOptionProps = {
        label: option.label || '',
        tooltip: option.tooltip,
        isRequired: option.isRequired,
    };

    if (optionProps.label && optionProps.tooltip && (option.isRequired !== undefined)) {
        return optionProps;
    }

    const subItems: IItem[] = getRadioOptionItems(option, formDetails.itemsMap);
    if (subItems.length !== 1) {
        return optionProps;
    }

    const onlyItem: IItem = subItems[0];
    const { state } = onlyItem;
    return {
        label: optionProps.label || getItemLabel(onlyItem),
        tooltip: optionProps.tooltip || state.tooltip,
        isRequired: (optionProps.isRequired !== undefined) ? optionProps.isRequired : state.isRequired,
    };
} ;


const createRadioOptionButton = (formDetails: IFullFormDetails, radioItem: IRadioItem, option: IRadioOption): ReactNode => {
    const disabled = getVisibleRadioOptionItems(option, formDetails.itemsMap).length === 0;
    const isSelected = option.name === radioItem.state.selectedName;
    const { onRadioSelectionChanged } = formDetails;
    return (
        <RadioButtonOptionComponent
            key={option.name}
            radioItem={radioItem}
            option={option}
            disabled={disabled}
            isSelected={isSelected}
            optionProps={createRadioOptionProps(formDetails, option)}
            onRadioSelectionChanged={onRadioSelectionChanged} />
    );

};

const createRadioComponent = (formDetails: IFullFormDetails, radioItem: IRadioItem): ReactNode => {
    const { fullErrorsInfo, wasSubmitted, itemsMap } = formDetails;
    const { state } = radioItem;
    if (!state.selectedName || radioItem.options.length === 0) {
        return null;
    }

    const selectedOption: IRadioOption | undefined = radioItem.options.find(option => option.name === state.selectedName);
    if (!selectedOption) {
        return null;
    }

    const visibleSubItems: IItem[] = getVisibleRadioOptionItems(selectedOption, itemsMap);
    if (visibleSubItems.length === 0) {
        return null;
    }

    const skipLabels = !isRadioHasOptionsWithMultiFields(radioItem);
    const errorInfo: IFullErrorInfo = fullErrorsInfo.errorsMap[radioItem.name];

    return (
        <FormStyled.TopItem key={radioItem.name}>
            {state.label && <FormStyled.RadioButtonsHeader>{state.label}</FormStyled.RadioButtonsHeader>}
            <FormStyled.RadioButtons>
                { radioItem.options.map(option => createRadioOptionButton(formDetails, radioItem, option)) }
            </FormStyled.RadioButtons>
            <ErrorComponent showErrors={wasSubmitted} errorInfo={errorInfo} />
            <Stack direction={'column'} spacing={3} padding={visibleSubItems.length > 1 ? [0, 0, 3, 0] : undefined }>
                {
                    visibleSubItems.map(subItem => {
                        return createItemComponent(formDetails, subItem, skipLabels);
                    })
                }
            </Stack>
        </FormStyled.TopItem>
    );
};

const getBoxDirection = (boxItem: IBoxItem): Direction => {
    return boxItem.state.direction ?? 'row';
};

const getBoxSpacing = (boxItem: IBoxItem): ISpacing => {
    return boxItem.state.spacing ?? 3;
};

const getBoxAlignItems = (boxItem: IBoxItem): AlignItems => {
    if (boxItem.state.alignItems !== undefined) {
        return boxItem.state.alignItems;
    }
    if ((getBoxDirection(boxItem) === 'column') || (getBoxDirection(boxItem) === 'column-reverse')) {
        return 'flex-start';
    } else {
        return 'center';
    }
};

const getBoxJustifyContent = (boxItem: IBoxItem): JustifyContent | undefined => {
    return boxItem.state.justifyContent;
};

const getBoxPadding = (boxItem: IBoxItem): ISpacing | undefined => {
    return boxItem.state.padding;
};

const createBoxComponent = (formDetails: IFullFormDetails, boxItem: IBoxItem): ReactNode => {
    const { state } = boxItem;
    const { itemsMap, wasSubmitted, fullErrorsInfo } = formDetails;
    const errorInfo: IFullErrorInfo = fullErrorsInfo.errorsMap[boxItem.name];
    if (boxItem.subItems.length === 0) {
        return null;
    }

    const visibleSubItems: IItem[] = getVisibleRowSubItems(boxItem, itemsMap);
    if (visibleSubItems.length === 0) {
        return null;
    }

    const innerFormDetails: IFullFormDetails = {
        ...formDetails,
        isInnerComponent: getBoxDirection(boxItem) === 'row',
    };

    return (
        <FormStyled.TopItem key={boxItem.name} padding={getBoxPadding(boxItem)} withBackground={boxItem.state.withBackground}>
            {state.label ? <FieldLabelComponent item={boxItem} /> : undefined}
            <ErrorComponent showErrors={wasSubmitted} errorInfo={errorInfo} />
            <Stack
                direction={getBoxDirection(boxItem)}
                spacing={getBoxSpacing(boxItem)}
                alignItems={getBoxAlignItems(boxItem)}
                justifyContent={getBoxJustifyContent(boxItem)}
                fullWidth>
                {
                    visibleSubItems.map(subItem => {
                        return createItemComponent(innerFormDetails, subItem);
                    })
                }
            </Stack>
        </FormStyled.TopItem>
    );
};

const createSimpleComponent = (formDetails: IFullFormDetails, item: ISimpleItem): ReactNode => {
    switch (item.simpleItemType) {
        case FormSimpleItemType.divider:
            return createDividerComponent(formDetails, item);

        case FormSimpleItemType.label:
            return createLabelComponent(formDetails, item);

        default:
            return null;
    }
};


const createItemComponent = (formDetails: IFullFormDetails, item: IItem, skipLabels?: boolean): ReactNode => {
    switch (item.itemType) {
        case FormItemType.simple:
            return createSimpleComponent(formDetails, item);

        case FormItemType.field:
            return createFieldComponent(formDetails, item, skipLabels);

        case FormItemType.radio:
            return createRadioComponent(formDetails, item);

        case FormItemType.box:
            return createBoxComponent(formDetails, item);

        default:
            return null;
    }
};

export const createItemComponents = (formDetails: IFullFormDetails): ReactNode[] => {
    const visibleItems: IItem[] =
        formDetails.params.topNames.map(name => formDetails.itemsMap[name]).filter(item => (!item.state.hidden));
    return visibleItems.map((item: IItem) => createItemComponent(formDetails, item));
};
