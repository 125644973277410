import React, { createElement, useEffect, useState } from 'react';
import { Button, Input, Spinner, Stack, Typography } from 'common/design-system/components-v2';
import styled from 'styled-components/macro';
import { TextIconItem } from './ListItemTypes/TextIconItem';
import { ListItem } from './ListItemTypes/interfaces';
import { useTranslation } from 'react-i18next';


interface Props {
    items: ListItem[] | null;
    header: string;
    onSelectedItemsChanged:Function;
    itemTemplate?: any;
    preSelectedItems?: ListItem[];
}

const ItemsContainer = styled(Stack)`
    height: 100%;
    .items-list {
       flex-grow: 1 ;
        overflow-y: auto;
        padding-left: 10px;
    }
`;


const MultiSelectListComponent: React.FC<Props> = ({ preSelectedItems, itemTemplate, header,items,onSelectedItemsChanged }) => {
    const [selectedItems, setSelectedItems] = useState<ListItem[]>(preSelectedItems||[]);
    const [filteredItems, setFilteredItems] = useState<ListItem[]>([]);
    const [filterText, setFilterText] = useState<string>('');
    const [showOnlySelected, setShowOnlySelected] = useState<boolean>();
    const { t } = useTranslation();
    const handleCheckboxChange = (item: ListItem, checked?:boolean) => {
        let _updatedSelectedItems = [];
        const index = selectedItems.findIndex(selectedItem => selectedItem.id === item.id);
        _updatedSelectedItems = [...selectedItems];
        if(index > -1)
        {
            _updatedSelectedItems.splice(index, 1);
        }
        if(checked){
            _updatedSelectedItems=[..._updatedSelectedItems, item];
        }
        setSelectedItems(_updatedSelectedItems);
        onSelectedItemsChanged(_updatedSelectedItems);
    };

    const handleFilterChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        setFilterText(event.target.value);
    };

    useEffect(() => {
        if(preSelectedItems){
            setSelectedItems(preSelectedItems);
        }
    }, [preSelectedItems]);
    
    const handleSelectAll = () => {
        if(selectedItems.length === filteredItems.length){
            setSelectedItems([]);
            onSelectedItemsChanged([]);
        }else{
            items && setSelectedItems(items);
            onSelectedItemsChanged(items);
        }


    };

    const handleShowSelected = () => {
        setShowOnlySelected(!showOnlySelected);
    };

    useEffect(() => {
        let filteredItems = items?.filter(item =>
            item.name.toLowerCase().includes(filterText.toLowerCase())
        );
        if(showOnlySelected) {
            filteredItems = filteredItems?.filter(item => selectedItems.some(selectedItem => selectedItem.id === item.id));
        }
        if(filteredItems?.length && selectedItems?.length){
            selectedItems.forEach((preSelectedItem) => {
                const match = filteredItems?.find((_filteredItems) => _filteredItems.id === preSelectedItem.id);
                if(match) {
                    match.selectedVersion = preSelectedItem.selectedVersion;
                }
            });
        }

        filteredItems && setFilteredItems(filteredItems);
    }, [filterText, showOnlySelected, items, selectedItems]);

    return (
        <>

            <ItemsContainer>
                <h3>{header}</h3>
                <Input
                    type="text"
                    placeholder={t('GENERAL.SEARCH')}
                    value={filterText}
                    onChange={handleFilterChange}
                />
                <>
                    { items ? <>
                        <Stack padding={[2, 0, 2, 0]} spacing={5} direction={'row'}>
                            <Button data-aid={'select-all-toggle'} variant={'text'} onClick={handleSelectAll}>Select/Deselect All</Button>
                            <Button data-aid={'show-selected-toggle'} variant={'text'} onClick={handleShowSelected}>Show Only Selected ({selectedItems.length})</Button>
                        </Stack>
                        <Stack className={'items-list'} spacing={2} >
                            {filteredItems.sort((a,b)=>(a.icon + a.name).localeCompare(b.icon + b.name)).map(item => {
                                return (<span key={item.name} data-aid={'row_'+item.name}>
                                    {itemTemplate ? createElement(itemTemplate,{ item: item,
                                        handleCheckboxChange: handleCheckboxChange,
                                        selected: selectedItems.some(selectedItem => selectedItem.id === item.id)
                                    }) : <TextIconItem selected={selectedItems.some(selectedItem => selectedItem.id === item.id)}
                                        handleCheckboxChange={handleCheckboxChange} key={item.name} item={item} />}

                                </span>); })}
                        </Stack>
                    </> : <Stack alignItems={'center'} padding={[10,0,0,0]} spacing={5} direction={'row'} >
                        <Spinner/>
                        <Typography variant={'body'}>Loading...</Typography>
                    </Stack>}
                </>

            </ItemsContainer>


        </>
    );
};

export default MultiSelectListComponent;
