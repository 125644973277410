import * as React from 'react';
import { SVGProps, Ref, forwardRef } from 'react';
const SvgDoubleCheck = (
  props: SVGProps<SVGSVGElement>,
  ref: Ref<SVGSVGElement>
) => (
  <svg
    viewBox="0 0 16 16"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    ref={ref}
    {...props}
  >
    <path
      d="m2.5 9 3.325 3.694a1.5 1.5 0 0 0 2.28-.06L15.5 3.5"
      stroke="currentColor"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="m4 6.5 2.6 3.033a.5.5 0 0 0 .775-.02L12 3.5"
      stroke="currentColor"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </svg>
);
const ForwardRef = forwardRef(SvgDoubleCheck);
export default ForwardRef;
