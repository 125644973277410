import styled from 'styled-components';
import { Stack } from '../index';
import { FindingSeverityEnum } from 'common/consts/FindingSeverity';

const SeverityToColor = {
    [FindingSeverityEnum.critical]: '#990000',
    [FindingSeverityEnum.high]: '#ED1212',
    [FindingSeverityEnum.medium]: '#EB9A00',
    [FindingSeverityEnum.low]: '#FFCA66',
    [FindingSeverityEnum.informational]: '#3594FF',
    [FindingSeverityEnum.unknown]: '#AAAAAA',
};

export const CountWithSeverity = styled(Stack)<{ severity: keyof typeof FindingSeverityEnum, customWidth?: string }>`
    width: ${({ customWidth }) => customWidth ? customWidth : '36px' };
    
    &::after {
        content: '';
        height: 4px;
        width: 100%;
        border-radius: 2px;
        background-color: ${({ theme, severity }) => SeverityToColor[severity] || theme.palette.severity.unknownFg};
        
    }
`;

export const VulnerabilitiesWrapper = styled(Stack)`
    flex: 1;
`;