import * as React from 'react';
import { SVGProps, Ref, forwardRef } from 'react';
const SvgHistory = (
  props: SVGProps<SVGSVGElement>,
  ref: Ref<SVGSVGElement>
) => (
  <svg
    viewBox="0 0 16 16"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    ref={ref}
    {...props}
  >
    <path
      d="m.5 4.5 1 3 3-1.5M8.5 3.5v5l2.5 2"
      stroke="currentColor"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M1.5 7.5a7 7 0 1 1 1.337 4.114"
      stroke="currentColor"
      strokeLinecap="round"
    />
  </svg>
);
const ForwardRef = forwardRef(SvgHistory);
export default ForwardRef;
