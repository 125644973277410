import React, { ReactElement, useCallback, useEffect, useRef, useState } from 'react';
import { DrawerStyled } from './Drawer.styled';
import { useLocation } from 'react-router-dom';
import { DrawerState, IDrawerContent, IDrawerIcon, IDrawerOptions, IDrawerWidgetUrlParams } from './Drawer.interface';
import { DrawerRegistry } from './DrawerRegistry';
import { useTranslation } from 'react-i18next';
import { getDrawerUrlParams, isEqualUrlParams, ON_DRAWER_CLOSE_EVENT, removeDrawerUrlParam } from './Drawer.utils';
import { Spinner, Stack, Typography } from 'common/design-system/components-v2';
import { Icon } from '@dome9/berries/react-components';
import { DrawerBus } from './DrawerBus';

export interface IDrawerContentProps {
    title?: string;
    icon?: IDrawerIcon;
    widget?: ReactElement;
    rightHeaderContent?: React.ReactNode;
}

const getHeaderIcon = (icon: IDrawerIcon): JSX.Element => {
    if (typeof icon === 'string') {
        return <Icon name={icon as string} color={'undefined'} height={36} width={36}/>;
    } else {
        return icon as JSX.Element;
    }
};

const DEFAULT_DRAWER_WIDTH = 'xl';
export const DrawerContent: React.FC <{ options?:IDrawerOptions, content?: ReactElement, title?: string, state: DrawerState, icon?: IDrawerIcon, rightHeaderContent?: React.ReactNode, onCloseClick: () => void }> = ({ options, content, title, state, icon, rightHeaderContent, onCloseClick }) => {
    const { t } = useTranslation();
    const isHeaderRelevant = !!(title || icon);
    return (
        <DrawerStyled.TopModal width={options?.width ?? DEFAULT_DRAWER_WIDTH} isOpen={true} shouldCloseOnOverlayClick={false} onRequestClose={() => options?.suppressCloseOnEscape ? null : onCloseClick()}>
            <DrawerStyled.TopDiv data-aid='page-drawer'>
                <DrawerStyled.ActionsBar>
                    <DrawerStyled.ActionIconDiv onClick={onCloseClick}><DrawerStyled.IconDiv name={'remove'} size={12} /></DrawerStyled.ActionIconDiv>
                </DrawerStyled.ActionsBar>
                <DrawerStyled.BodyDiv>
                    {(state === DrawerState.VISIBLE_READY && isHeaderRelevant) && <DrawerStyled.HeaderDiv data-aid={'drawer-header'} data-aid2={title} hasHeaderLineSeparator={options?.hasHeaderLineSeparator}>
                        <Stack direction='row' alignItems='center' spacing={2}>
                            {icon && getHeaderIcon(icon)}
                            <Typography variant={'subtitleLg'}>
                                {title}
                            </Typography>
                        </Stack>
                        {rightHeaderContent ? <DrawerStyled.RightHeaderContentDiv>
                            {rightHeaderContent}
                        </DrawerStyled.RightHeaderContentDiv> : null}
                    </DrawerStyled.HeaderDiv>}
                    {(state === DrawerState.VISIBLE_READY) && content && (
                        <DrawerStyled.ContentDiv
                            hasHeaderLineSeparator={options?.hasHeaderLineSeparator}
                            isHeaderRelevant={isHeaderRelevant}
                            disableSpacing={options?.disableSpacing}
                        >
                            <DrawerStyled.ContentComponentDiv>{content}</DrawerStyled.ContentComponentDiv>
                        </DrawerStyled.ContentDiv>
                    )}
                    {(state === DrawerState.VISIBLE_LOADING) && <DrawerStyled.LoadingDiv variant={'subtitleLg'}>
                        {t('GENERAL.LOADING')} <Spinner size={20}/>
                    </DrawerStyled.LoadingDiv>}
                </DrawerStyled.BodyDiv>
            </DrawerStyled.TopDiv>
        </DrawerStyled.TopModal>
    );
};

export const Drawer: React.FC = () => {
    const [options, setOptions] = useState<IDrawerOptions>();
    const [contentProps, setContentProps] = useState<IDrawerContentProps | undefined>();
    const [drawerState, setDrawerState] = useState<DrawerState>(DrawerState.HIDDEN);
    const urlParamsRef = useRef<IDrawerWidgetUrlParams | undefined>();
    const location = useLocation();

    const onDrawerOpened = useCallback((contentProps: IDrawerContentProps) => {
        setContentProps(contentProps);
        setDrawerState(DrawerState.VISIBLE_READY);
    }, []);

    const onDrawerClosed = useCallback(() => {
        setDrawerState(DrawerState.HIDDEN);
        setOptions(undefined);
        setContentProps(undefined);
        urlParamsRef.current = undefined;
    }, []);

    const closeDrawer = useCallback(() => {
        setDrawerState(DrawerState.HIDDEN);
        removeDrawerUrlParam();
    }, []);

    const onCloseClick = useCallback(() => {
        if (options?.onCloseHandlerId) {
            DrawerBus.sendEvent(options.onCloseHandlerId, ON_DRAWER_CLOSE_EVENT);
        } else {
            closeDrawer();
        }
    }, [closeDrawer, options?.onCloseHandlerId]);

    const handleLocationChanges = useCallback((search: string | undefined) => {
        const params: IDrawerWidgetUrlParams | undefined = getDrawerUrlParams(search);
        if (isEqualUrlParams(params, urlParamsRef.current)) {
            return;
        }
        urlParamsRef.current = params;
        if (!params) {
            onDrawerClosed();
            return;
        }

        if (!params.widgetId) {
            closeDrawer();
            return;
        }

        setDrawerState(DrawerState.VISIBLE_LOADING);
        const contentProvider = DrawerRegistry.getContentProvider(params.widgetId);
        if (!contentProvider) {
            closeDrawer();
            return;
        }

        setOptions(contentProvider.options);
        contentProvider.getDrawerContent(params.widgetData, params.handlersIdMap).then((content: IDrawerContent | undefined) => {
            if (!content) {
                closeDrawer();
                return;
            }
            onDrawerOpened( {
                title: content.title,
                icon: content.icon,
                widget: React.createElement(content.component, content.componentProps),
                rightHeaderContent: content.rightHeaderContent,
            });
        }).catch((e) => {
            console.error('Failed opening drawer. Error: ', e);
            closeDrawer();
            return;
        });
    }, [closeDrawer, onDrawerClosed, onDrawerOpened]);

    useEffect(() => {
        handleLocationChanges(location.search);
    }, [location, handleLocationChanges]);

    if (drawerState === DrawerState.HIDDEN) {
        return (
            <DrawerStyled.EmptyDiv />
        );
    }

    return (
        <DrawerContent
            options={options}
            content={contentProps?.widget}
            title={contentProps?.title}
            icon={contentProps?.icon}
            state={drawerState}
            rightHeaderContent={contentProps?.rightHeaderContent}
            onCloseClick={onCloseClick}
        />
    );
};
