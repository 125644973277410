import { FC } from 'react';
import { FormStyled } from '../../CustomForm.styled';
import { FieldLabelComponent } from '../helperComponents/FieldLabelComponent';
import { ErrorComponent } from '../helperComponents/ErrorComponent';
import { getItemEnterPlaceholder } from '../../CustomForm.values';
import { TextArea } from '../../../../../design-system/components-v2';
import { IFullErrorInfo, IOnFieldChanged, ITextAreaItem } from '../../CustomForm.interface';

export interface ITextAreaItemComponentProps {
    item: ITextAreaItem;
    onFieldChanged: IOnFieldChanged;
    showErrors: boolean;
    errorInfo?: IFullErrorInfo;
    skipTitle?: boolean;
    isInner?: boolean;
}

export const TextAreaItemComponent: FC<ITextAreaItemComponentProps> = ({ item, onFieldChanged, showErrors, errorInfo, skipTitle, isInner }) => {
    const { state } = item;
    return (
        <FormStyled.TopItem widthInfo={state.widthInfo} isInner={isInner}>
            {!skipTitle && <FieldLabelComponent item={item} />}
            <TextArea
                fullWidth={true}
                maxLength={state.charsLimit}
                clearable={true}
                inputSize="large"
                rows={4}
                value={state.value}
                placeholder={getItemEnterPlaceholder(item)}
                disabled={state.disabled || state.readonly}
                onChange={e => onFieldChanged({ ...item, state: { ...item.state, value: e.target.value } })}
                isError={!!(showErrors && errorInfo)}
            />
            <ErrorComponent showErrors={showErrors} errorInfo={errorInfo} />
        </FormStyled.TopItem>
    );
};

