import * as React from 'react';
import { SVGProps, Ref, forwardRef } from 'react';
const SvgTraffic = (
  props: SVGProps<SVGSVGElement>,
  ref: Ref<SVGSVGElement>
) => (
  <svg
    viewBox="0 0 16 16"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    ref={ref}
    {...props}
  >
    <path
      d="m2.5 9.5 4 5v-13m7 5-4-5v13"
      stroke="currentColor"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </svg>
);
const ForwardRef = forwardRef(SvgTraffic);
export default ForwardRef;
