import React from 'react';
import { FILTER_BOX_DISPLAY_TYPES, FILTER_EVENTS, FILTERS_KEYS } from '../../FilterPanel.consts';
import { IGeneralHandlerProps } from '../../FilterPanel.interface';
import { useTranslation } from 'react-i18next';
import { Button, IconButton } from 'common/design-system/components-v2';

export const ClearButton: React.FC<{ filterProps: IGeneralHandlerProps }> = ({ filterProps }) => {
    const { t } = useTranslation();
    const { onEvent, displayType, searchTerm } = filterProps;
    const handleClearAll = () => {
        onEvent!({ action: FILTER_EVENTS.CLEAR_FILTERS, filterKey: FILTERS_KEYS.ALL, payload: '' });
    };

    const renderDropDownButtonView = () => {
        const disabled = !!searchTerm?.length;
        return (
            <Button
                disabled={disabled}
                tooltip={disabled ? t('FILTER_PANEL.DISABLES_CLEAR_TOOLTIP') : ''}
                iconProps={{ name: 'remove' }}
                variant='outlined'
                color='brandPrimary'
                onClick={handleClearAll}
                label={t('FILTER_PANEL.CLEAR_ALL')}
                data-aid='clear-all-button'
            />
        );
    };

    const renderRowView = () => {
        return (
            <div className='filter-panel__clear-all' key={1} data-aid='clear-all'>
                <IconButton
                    iconProps={{ name: 'remove' }}
                    size='small'
                    onClick={handleClearAll}
                />
            </div>
        );
    };
    return <>{displayType === FILTER_BOX_DISPLAY_TYPES.BOX_BUTTON ? renderDropDownButtonView() : renderRowView()}</>;
};

export default ClearButton;
