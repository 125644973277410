import { Addin } from 'common/extensibility/AddinRegistry';
import globalAddinContainer, { buildPath } from '../extensibility/AddinContainer';
import {
    GcpEnvironmentAction,
    GcpEnvironmentData,
    GcpEnvironmentTab,
    PATH_ENVIRONMENT_PAGE,
    PATH_ENVIRONMENT_PAGE_ACTION,
    PATH_ENVIRONMENT_PAGE_GCP_DETAILS_PANEL,
    PATH_ENVIRONMENT_PAGE_GCP_TAB,
    PATH_ENVIRONMENT_PAGE_GCP_CHIP,
} from 'common/module_interface/assets/GcpEnvironment';
import { BlockInfoProps } from 'common/design-system/components-v2/BlockInfo/BlockInfo.types';
import { EnvironmentChip } from '../module_interface/assets/Environments';


export interface GcpEnvironmentAddingBase<T> {
    isRelevant?: (gcpData: GcpEnvironmentData) => Promise<boolean> | boolean;
    getValue: (gcpData: GcpEnvironmentData) => Promise<T> | T;
    id: string;
    customLoadingLabel?: string; // Relevant for chips only at the moment
}

function addDetailsPanelItems(item: Addin<GcpEnvironmentAddingBase<BlockInfoProps>>[]) {
    globalAddinContainer.add(buildPath(PATH_ENVIRONMENT_PAGE, PATH_ENVIRONMENT_PAGE_GCP_DETAILS_PANEL), item);
}

function getDetailsPanelItems(): GcpEnvironmentAddingBase<BlockInfoProps>[] {
    return globalAddinContainer.get(buildPath(PATH_ENVIRONMENT_PAGE, PATH_ENVIRONMENT_PAGE_GCP_DETAILS_PANEL));
}

function addTabs(item: Addin<GcpEnvironmentAddingBase<GcpEnvironmentTab>>[]) {
    globalAddinContainer.add(buildPath(PATH_ENVIRONMENT_PAGE, PATH_ENVIRONMENT_PAGE_GCP_TAB), item);
}

function getTabs(): GcpEnvironmentAddingBase<GcpEnvironmentTab>[] {
    return globalAddinContainer.get(buildPath(PATH_ENVIRONMENT_PAGE, PATH_ENVIRONMENT_PAGE_GCP_TAB));
}

function addActions(item: Addin<GcpEnvironmentAddingBase<GcpEnvironmentAction>>[]) {
    globalAddinContainer.add(buildPath(PATH_ENVIRONMENT_PAGE, PATH_ENVIRONMENT_PAGE_ACTION), item);
}

function getActions(): GcpEnvironmentAddingBase<GcpEnvironmentAction>[] {
    return globalAddinContainer.get(buildPath(PATH_ENVIRONMENT_PAGE, PATH_ENVIRONMENT_PAGE_ACTION));
}

function addChips(item: Addin<GcpEnvironmentAddingBase<EnvironmentChip>>[]) {
    globalAddinContainer.add(buildPath(PATH_ENVIRONMENT_PAGE, PATH_ENVIRONMENT_PAGE_GCP_CHIP), item);
}

function getChips(): GcpEnvironmentAddingBase<EnvironmentChip>[] {
    return globalAddinContainer.get(buildPath(PATH_ENVIRONMENT_PAGE, PATH_ENVIRONMENT_PAGE_GCP_CHIP));
}

export const GcpEnvironmentAddinRegistry = {
    addDetailsPanelItems,
    getDetailsPanelItems,
    addTabs,
    getTabs,
    addActions,
    getActions,
    addChips,
    getChips
};