import { formatNumberShorthand } from '../../utils/helpFunctions';
import { ILevelsSummaryData } from './AssetWidget.interface';
import { IGeneralLevelsCounters } from '../../consts/levels';

type DataCounter = {
    high: number;
    critical: number;
    highCriticalAssetsCount: number;
    totalAssets?: number | null;
}

export const createLevelsSummaryData = ({ high, critical, highCriticalAssetsCount, totalAssets }: DataCounter): ILevelsSummaryData => {
    const levelCounters: IGeneralLevelsCounters = {
        critical,
        high,
    };
    return {
        isTooltip: Boolean(critical || high),
        totalCountStr: totalAssets ? formatNumberShorthand(totalAssets) : undefined,
        highCriticalCountStr: highCriticalAssetsCount ? formatNumberShorthand(highCriticalAssetsCount) : '0',
        levelCounters: levelCounters,
    };
};
export const createEventsDataCounters = (highCount: number, criticalCount: number) => {
    return createLevelsSummaryData({
        high: highCount,
        critical: criticalCount,
        highCriticalAssetsCount: criticalCount + highCount,
    });
};

