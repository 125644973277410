import React from 'react';
import { DayPicker, DayPickerDefaultProps, DayPickerSingleProps, DayPickerMultipleProps, DayPickerRangeProps } from 'react-day-picker';
import Icon from '../Icon/Icon';
import DatePickerStyled from './DatePicker.styles';

const DatePickerBase: React.FC<DayPickerDefaultProps | DayPickerSingleProps | DayPickerMultipleProps | DayPickerRangeProps> = (props) => {
    return (
        <DatePickerStyled.DatePickerWrapper>
            <DayPicker
                components={{
                    IconRight: () =><Icon name='chevronRight' size={12} />,
                    IconLeft: () =><Icon name='chevronLeft' size={12} />,
                }}
                numberOfMonths={1}
                {...props}
            />
        </DatePickerStyled.DatePickerWrapper>
    );
};

export default DatePickerBase;
