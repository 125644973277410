import React, { useCallback, useEffect, useMemo, useRef, useState } from 'react';
import { ColumnApi, GridOptions, GridReadyEvent } from 'ag-grid-community';
import { GridApi } from 'ag-grid-enterprise';
import { TableStyled } from './ClientFilterTable.styled';
import {
    fixPinnedColumns,
    getAllTableItems,
    getAllTableItemsAfterFilter,
    getDisplayedTableItems,
    loadColumnsState,
    saveColumnsState,
} from 'common/utils/tableUtils';
import Table from 'common/design-system/components-v2/Table';
import { FilterChangedEvent } from 'ag-grid-community/dist/lib/events';
import { IGridFilterOptions } from 'common/erm-components/custom/ClientFilter/ClientFilter.interface';
import { buildSimpleClientFilterGridOptions } from 'common/erm-components/custom/ClientFilter/ClientFilter';
import {
    simpleClientHasConditions,
    simpleClientTestConditions,
} from 'common/erm-components/custom/ClientFilter/SimpleClientFilter/SimpleClientFilter';
import { useTranslation } from 'react-i18next';
import { DefaultTextCellRender } from 'common/components/ag-grid/Renderers/DefaultTextCellRender';
import { IFilterConditionsContainer } from 'common/erm-components/custom/FilterTree/FilterCondition';
import { ColDef } from 'ag-grid-community/dist/lib/entities/colDef';
import { IEntityItem } from '../../../../utils/filterUtils';
import i18n from 'i18next';
import { useEventHandler } from '../../../utils/EventBus/useEventHandler';
import { IClientFilterTableProps } from '../ClientFilterPage.interface';

const getDefaultFooterItemTitle = (count: number) => {
    return i18n.t('CLIENT_FILTER_PAGE_TABLE.TABLE.DEFAULT_FOOTER_ITEM_TITLE', { count });
};

export const ClientFilterTable: React.FC<IClientFilterTableProps<any>> =
    <T extends IEntityItem>(props: IClientFilterTableProps<T>) => {
        const { filterConditionsContainer, clientInfos, onFilteredItemsChanged, onDataChange,
            allItems, pageTableId, getFooterItemTitle = getDefaultFooterItemTitle, getTableColumnDefs, getTableActions, onPageTableReady } = props;
        const gridApiRef = useRef<GridApi<T>>();
        const columnApiRef = useRef<ColumnApi>();
        const columns: ColDef[] = useMemo<ColDef[]>(() => getTableColumnDefs(), [getTableColumnDefs]);
        const condFilterValuesRef = useRef<IFilterConditionsContainer | undefined>();
        const { t } = useTranslation();
        const getLoadingText = useCallback((): string => {
            return t('GENERAL.LOADING');
        }, [t]);
        const [footer, setFooter] = useState<string>(getLoadingText());
        const getStorageKey = useCallback((): string => {
            return `${pageTableId}-STORAGE-KEY`;
        }, [pageTableId]);

        const getFooterText = useCallback((): string => {
            if (!gridApiRef.current) {
                return getLoadingText();
            }
            const itemCount = getAllTableItemsAfterFilter(gridApiRef.current).length;
            const totalCount = getAllTableItems(gridApiRef.current).length;
            return t('CLIENT_FILTER_PAGE_TABLE.TABLE.FOOTER_TEXT', { itemCount, totalCount, itemTitle: getFooterItemTitle(totalCount) });
        }, [getLoadingText, getFooterItemTitle, t]);

        const onTableDataChange = useCallback((ignoreOverlay?: boolean) => {
            if (gridApiRef.current && !ignoreOverlay) {
                gridApiRef.current.showLoadingOverlay();
            }
            setFooter(getLoadingText());
            onDataChange();
        }, [getLoadingText, onDataChange]);

        useEventHandler(pageTableId, () => onTableDataChange(true));

        const onColumnStateChanged = useCallback(() => {
            if (!columnApiRef.current) {
                return;
            }
            saveColumnsState(columnApiRef.current, getStorageKey());
            fixPinnedColumns(columnApiRef.current);
        }, [getStorageKey]);

        const onFilterChanged = useCallback((event: FilterChangedEvent<T>) => {
            if (onFilteredItemsChanged) {
                onFilteredItemsChanged(getDisplayedTableItems<T>(event.api));
            }
            setFooter(getFooterText());
            gridApiRef.current?.hideOverlay();
        }, [getFooterText, onFilteredItemsChanged]);

        const getCondFilterValues = useCallback((): IFilterConditionsContainer | undefined => {
            return condFilterValuesRef.current;
        },[]);

        const fireFilterChanged = useCallback(() => {
            if (gridApiRef.current) {
                setFooter(getLoadingText());
                gridApiRef.current.showLoadingOverlay();
                gridApiRef.current.onFilterChanged();
            }
        }, [getLoadingText]);

        const onGridReady = useCallback((params: GridReadyEvent<T>) => {
            gridApiRef.current = params.api;
            columnApiRef.current = params.columnApi;
            loadColumnsState(params.columnApi, getStorageKey());
            fireFilterChanged();
            onPageTableReady();
        }, [fireFilterChanged, getStorageKey, onPageTableReady]);

        const gridOptions: GridOptions = useMemo(() => {
            const filterOptions: IGridFilterOptions<T> = buildSimpleClientFilterGridOptions<T, IFilterConditionsContainer>(
                simpleClientHasConditions,
                simpleClientTestConditions,
                getCondFilterValues,
                clientInfos);
            return {
                columnDefs: columns,
                rowSelection: 'multiple',
                suppressRowDeselection: false,
                onGridReady,
                defaultColDef: { cellRenderer: DefaultTextCellRender },
                rowModelType: 'clientSide',
                enableRangeSelection: false,
                suppressCellFocus: true,
                onSortChanged: onColumnStateChanged,
                onColumnResized: onColumnStateChanged,
                onColumnMoved: onColumnStateChanged,
                onColumnVisible: onColumnStateChanged,
                onGridColumnsChanged: onColumnStateChanged,
                ...filterOptions,
                onFilterChanged,
                rowData: allItems,
            };
        }, [allItems, clientInfos, columns, getCondFilterValues, onColumnStateChanged, onFilterChanged, onGridReady]);

        useEffect(() => {
            condFilterValuesRef.current = filterConditionsContainer;
            fireFilterChanged();
        }, [filterConditionsContainer, fireFilterChanged]);

        useEffect(() => {
            fireFilterChanged();
        }, [allItems, fireFilterChanged]);

        return (
            <TableStyled.TopDiv>
                <Table
                    gridOptions={gridOptions}
                    disableGrouping={true}
                    actions={getTableActions ? getTableActions(onTableDataChange) : undefined}
                    footer={footer}
                />
            </TableStyled.TopDiv>
        );
    };
