import i18n from 'i18next';
import _ from 'lodash';

const LOWER_TRUE = 'true';

export const isBoolean = (value?: boolean | string | number): boolean => {
    if (_.isString(value)) {
        return value.toLowerCase() === LOWER_TRUE;
    }
    return Boolean(value);
};

export const transBoolean = (value?: boolean | string | number): string => {
    return isBoolean(value) ? i18n.t('GENERAL.YES') : i18n.t('GENERAL.NO');
};

export const isValidJSON = (str: string): boolean => {
    try {
        JSON.parse(str);
        return true;
    } catch (e) {
        return false;
    }
};
