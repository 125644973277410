import * as React from 'react';
import { SVGProps, Ref, forwardRef } from 'react';
const SvgCloudGuard = (
  props: SVGProps<SVGSVGElement>,
  ref: Ref<SVGSVGElement>
) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    fill="currentColor"
    color="inherit"
    viewBox="0 0 37 37"
    ref={ref}
    {...props}
  >
    <path
      fill="#00B7FF"
      d="M26.742 14.77c-.08 0-.157.003-.234.005v-.005h-.005a7.71 7.71 0 0 0-15.42 0h-.239a7.71 7.71 0 0 0 0 15.42c.075 0 .147 0 .222-.003l3.034.003.543-.79a14.935 14.935 0 0 1 4.857-4.474c.15-.087.3-.177.454-.257a15.253 15.253 0 0 1 3.432-1.317c.274-.068.548-.127.825-.18.192-.037.386-.067.58-.097l.187-.03a16.58 16.58 0 0 1 1.53-.137v-.01a15.636 15.636 0 0 1 4.287.413 15.41 15.41 0 0 1 3.313 1.216l.017.01a7.461 7.461 0 0 0 .33-2.187c0-4.187-3.454-7.582-7.71-7.582l-.003.002Z"
    />
    <path
      fill="#00B7FF"
      d="M23.723 24.7c-1.084.27-2.13.675-3.12 1.198a13.506 13.506 0 0 0-4.812 4.29h11.255a7.344 7.344 0 0 0 3.966-1.304 6.753 6.753 0 0 0 2.466-3.114 13.938 13.938 0 0 0-3.01-1.106 14.212 14.212 0 0 0-6.748.035h.003Z"
    />
  </svg>
);
const ForwardRef = forwardRef(SvgCloudGuard);
export default ForwardRef;
