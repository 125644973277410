import React, { useEffect, useState } from 'react';
import { Checkbox, Icon, Stack, Typography } from 'common/design-system/components-v2';
import { CommonItemProps, ListItem } from './interfaces';
import Dropdown from 'common/design-system/components-v2/Dropdown';
import { RulesetVersion } from 'common/interface/ruleset';
import styled from 'styled-components/macro';
import { useTranslation } from 'react-i18next';
import moment from 'moment-mini';
const LATEST_VERSION = 'Latest';
const VersionItem = styled(Stack)`
        div{
            padding: 5px;
            cursor: pointer;
            &:hover {
                background: ${({ theme }) => theme.palette.surfaceStates.secondaryActive};
            }
        }
    `;
export const getFormattedTime = (time?: string) => {
    let dateStr = 'No Date';
    if(time){
        const dateArr = time.split('T');
        if(dateArr.length > 0){
            dateStr = moment(dateArr[0]).format('ll');
        }
    }
    return dateStr;
};
export const TextIconVersionItem: React.FC<CommonItemProps> = ({ item,handleCheckboxChange ,selected }) => {
    const { t } = useTranslation();
    const [isSelected, setIsSelected] = useState<boolean>();
    const [dropDownOpen, setDropDownOpen] = useState<boolean>();
    const [dropDownLabel, setDropDownLabel] = useState<string>(t('GENERAL.SELECT_VERSION'));

    const getFormattedVersion = (version:RulesetVersion) => {
        return version.createdTime ? version.version + ' - ' + getFormattedTime(version.createdTime) : version.version;
    };

    useEffect(() => {
        setDropDownLabel(item?.selectedVersion ? getFormattedVersion(item?.selectedVersion) : t('GENERAL.SELECT_VERSION'));
        setIsSelected(selected);
    }, [selected]);
    const handleItemVersionSelected = (item: ListItem, version: any) => {
        item.selectedVersion = version;
        handleCheckboxChange && handleCheckboxChange(item,true);
        setDropDownOpen(false);
        setDropDownLabel(getFormattedVersion(version));
    };

    const handleItemSelected = (checked: boolean) => {
        setIsSelected(checked);
        if(!checked) {
            handleCheckboxChange && handleCheckboxChange(item);
        }else if((item.versionEnabled && +item?.id > 1) || !item.versionEnabled){
            handleCheckboxChange && handleCheckboxChange(item,checked);
        }else if(item.versionEnabled && +item?.id < 1){
            item.selectedVersion = item.availableVersions?.find((version:RulesetVersion) => version.version === LATEST_VERSION);
            handleCheckboxChange && handleCheckboxChange(item,checked);
        }
    };


    function showVersion(item: ListItem) {
        return (isSelected || selected) && +item?.id < 1;
    }

    return (
        <Stack spacing={1} direction={'row'} alignItems={'center'} justifyContent={'space-between'}>
            <Stack spacing={1} direction={'row'} alignItems={'center'}>
                <Checkbox name={item.id}
                    onChange={(event) => handleItemSelected(event.target.checked)}
                    checked={isSelected || selected}
                />
                {item.icon && <Icon vendorNameOrPath={item.icon} size={20} />}
                <Typography variant={'body'}>{item.name}</Typography>
            </Stack>
            {showVersion(item) ? <Dropdown label={dropDownLabel} open={dropDownOpen}>
                <VersionItem spacing={1} alignItems={'flex-start'} style={{ minWidth: '100px' }} direction={'column'}>
                    {item?.availableVersions?.map((version:RulesetVersion) => (
                        <div className='w-full' key={version.version} onClick={()=> handleItemVersionSelected(item,version)}>
                            <Stack spacing={1} direction={'row'} alignItems={'center'}>
                                <Typography variant={'body'}>{version.version}</Typography>
                                {version.createdTime ? <Typography
                                    variant={'body'}> - {getFormattedTime(version.createdTime)}</Typography> : null}
                            </Stack>
                        </div>
                    ))}
                </VersionItem>
            </Dropdown> : null}

        </Stack>
    );
};
