import { FC, useState } from 'react';
import './AddItemFilter.scss';
import { IExtendAddItemFilterProps } from '../DefaultFilters.interface';
import Accordion from '../../GeneralComponents/Accordion/Accordion';
import { isFilterBoxContent, isIncludeSearchTerm, renderAccordionClearButton } from '../../filterUtils';
import { useTranslation } from 'react-i18next';
import { FILTER_EVENTS } from '../../FilterPanel.consts';
import { Button, Icon, TextField } from '@dome9/berries/react-components';

const AddItemFilter: FC<{ filterProps: IExtendAddItemFilterProps }> = ({ filterProps }) => {
    const { onEvent, value, key, title, searchTerm, displayType } = filterProps;
    const [newItemInput, setNewItemInput] = useState('');
    const { t } = useTranslation();
    const onAddItemClicked = () => {
        onEvent({ action: FILTER_EVENTS.FILTER_CHANGED, filterKey: key, payload: [...value, newItemInput] });
        setNewItemInput('');
    };

    const onRemoveItem = (index: number) => {
        const cloneItemsList = [...value];
        cloneItemsList.splice(index, 1);
        onEvent({
            action: FILTER_EVENTS.FILTER_CHANGED,
            filterKey: key,
            payload: cloneItemsList,
        });
    };

    const renderGeneralAddItemFilter = () => {
        return (
            <div>
                <div className='add-item-form flex-center-space-between'>
                    <TextField
                        allowClear={true}
                        value={newItemInput}
                        onChange={(e: any) => setNewItemInput(e.target.value)}
                    />
                    <Button variant='default' onClick={onAddItemClicked} data-aid='add-filter-button'>
                        <Icon name='plus' />
                        <div>{t('FILTER_PANEL.ADD_ITEM_FILTER.BUTTON_TEXT')!}</div>
                    </Button>
                </div>
                <div className='add-item-list' data-aid='added-filters-list'>
                    {value.map((val, index) => {
                        return (
                            <div className='flex-center-space-between' key={val}>
                                <span className='item' data-aid={val}>{val}</span>
                                <span onClick={() => onRemoveItem(index)}
                                    data-aid={`remove-${val.replace(/\s+/g, '-').toLowerCase()}`}>
                                    <Icon name='remove' />
                                </span>
                            </div>
                        );
                    })}
                </div>
            </div>
        );
    };
    const filterBySearchContent = () => {
        const isTitle = searchTerm ? isIncludeSearchTerm(searchTerm, title) : true;
        const isContent = searchTerm ? !!value.filter((val) => isIncludeSearchTerm(searchTerm, val)).length : true;

        return { isTitle, isContent };
    };

    const renderBoxViewAddItemFilter = () => {
        const { isTitle, isContent } = filterBySearchContent();
        return (
            (isContent || isTitle) && (
                <Accordion
                    title={title}
                    content={renderGeneralAddItemFilter()}
                    showContent={(isTitle || isContent) && searchTerm !== ''}
                    optionsList={[
                        renderAccordionClearButton(() =>
                            onEvent({
                                action: FILTER_EVENTS.CLEAR_FILTERS,
                                filterKey: key,
                                payload: '',
                            }),
                        ),
                    ]}
                    onOpen={(elementRef: any) => {
                        onEvent({
                            action: FILTER_EVENTS.BOX_FILTER_OPEN,
                            filterKey: key,
                            payload: elementRef,
                        });
                    }}
                />
            )
        );
    };

    return (
        <div className='add-item-filter' data-aid='add-item-filter'>
            {isFilterBoxContent(displayType) ? renderBoxViewAddItemFilter() : renderGeneralAddItemFilter()}
        </div>
    );
};

export default AddItemFilter;
