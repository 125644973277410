import styled from 'styled-components';
import Stack from '../Stack';
import Typography from '../Typography/Typography';

const Wrapper = styled(Stack)`
  max-width: 100%;
`;

const SubText = styled(Typography)`
  white-space: pre-line;
`;

const InputLabelStyles = {
    Wrapper,
    SubText,
};

export default InputLabelStyles;
