import React from 'react';
import PopoverStyles from './Popover.styles';
import { IPopoverProps } from './Popover.types';
import Tippy from '@tippyjs/react/headless';

const Popover = React.forwardRef<HTMLDivElement, IPopoverProps>((props, ref) => {
    const { open, children, onClose, anchorEl, fitToAnchorWidth, padding = 2, maxHeight = 240, placement = 'bottom-end' } = props;

    const [popoverWidth, setPopoverWidth] = React.useState<number | undefined>(undefined);

    const handleClose = () => {
        onClose?.();
    };

    React.useEffect(() => {
        if (!anchorEl || !fitToAnchorWidth) return;

        setPopoverWidth(anchorEl.offsetWidth);
        const resizeObserver = new ResizeObserver(() => {
            setPopoverWidth(anchorEl.offsetWidth);
        });
        resizeObserver.observe(anchorEl);

        return () => {
            if (resizeObserver) {
                resizeObserver.unobserve(anchorEl);
            }
        };
    }, [anchorEl, fitToAnchorWidth]);

    if (!open) {
        return null;
    }

    return (
        <Tippy
            visible={open}
            onClickOutside={handleClose}
            placement={placement}
            reference={anchorEl}
            interactive={true}
            appendTo={document.body}
            offset={[0, 4]}
            zIndex={1000}
            render={(attrs) => (
                <PopoverStyles.Wrapper ref={ref} padding={padding} maxHeight={maxHeight} width={popoverWidth} spacing={0.5} {...attrs} data-aid='popover-wrapper'>
                    {children}
                </PopoverStyles.Wrapper>
            )}
        />
    );
});
Popover.displayName = 'Popover';

export default Popover;
