import * as React from 'react';
import { SVGProps, Ref, forwardRef } from 'react';
const SvgObjectExplorer = (
  props: SVGProps<SVGSVGElement>,
  ref: Ref<SVGSVGElement>
) => (
  <svg
    viewBox="0 0 16 16"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    ref={ref}
    {...props}
  >
    <path
      d="M11.5 7.5V5.682a1 1 0 0 0-.521-.878l-4-2.182a1 1 0 0 0-.958 0l-4 2.182a1 1 0 0 0-.521.878v5.176a1 1 0 0 0 .521.878l4.032 2.2a1 1 0 0 0 .907.025l1.021-.484"
      stroke="currentColor"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M1.504 5.094 6.5 8.5m0 0v5.649m0-5.649 4.992-3.414"
      stroke="currentColor"
      strokeLinejoin="round"
    />
    <path
      d="m13 13 2 2"
      stroke="currentColor"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <circle cx={11.5} cy={11.5} r={2} stroke="currentColor" />
  </svg>
);
const ForwardRef = forwardRef(SvgObjectExplorer);
export default ForwardRef;
