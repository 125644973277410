import * as React from 'react';
import { SVGProps, Ref, forwardRef } from 'react';
const SvgNist = (props: SVGProps<SVGSVGElement>, ref: Ref<SVGSVGElement>) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    viewBox="0 0 64 64"
    xmlSpace="preserve"
    ref={ref}
    {...props}
  >
    <path d="M4.46 23.58C1.98 23.58 0 25.57 0 28.05v12.4h3.97v-12.4c0-.4.5-.6.84-.35l10.4 11.42c2.88 2.88 7.64.79 7.64-3.07v-12.5h-3.97v12.5c0 .4-.5.6-.82.35L7.7 24.98c-1-.99-1.79-1.39-3.24-1.4m20.35 0v12.4c0 2.46 2 4.46 4.46 4.46h16.64c2.88 0 5.21-2.33 5.21-5.21s-2.33-5.21-5.21-5.21H35.49c-.68 0-1.24-.56-1.24-1.24 0-.68.56-1.24 1.24-1.24h17.6v12.89h3.97V27.55H64v-3.97H35.49c-2.88 0-5.21 2.33-5.21 5.21S32.61 34 35.49 34H45.9c.68 0 1.24.56 1.24 1.24s-.56 1.24-1.24 1.24H29.29c-.27 0-.5-.22-.5-.5v-12.4h-3.98z" />
  </svg>
);
const ForwardRef = forwardRef(SvgNist);
export default ForwardRef;
