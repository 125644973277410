import styled, { css } from 'styled-components';
import Stack from '../../Stack';
import Typography from '../../Typography';

interface StepProps {
  isCompleted: boolean;
  isCurrent: boolean;
}

const StepWrapper = styled(Stack)`
  &:not(:last-child) {
    &::after {
      content: '';
      display: block;
      flex-grow: 1;
      margin: 4px 15px;
      height: 32px;
      width: 2px;
      min-height: 10px;
      background: ${({ theme }) => theme.palette.border.primary};
      border-radius: ${({ theme }) => `${theme.border.radius(2)}${theme.units}`};
    }
  }
`;

const StepCircle = styled(Stack)<StepProps>`
  min-width: 32px;
  min-height: 32px;
  border-radius: 50%;
  ${({ isCurrent, isCompleted, theme }) => (!isCurrent && !isCompleted) && css`
      border: 2px solid ${theme.palette.border.primary};
      background-color: ${theme.palette.surface.secondary};
  `}
  ${({ isCurrent, theme }) => isCurrent && css`
      border: 2px solid ${theme.palette.border.information};
      background-color: ${theme.palette.surface.primary};
  `}

  ${({ isCurrent, isCompleted, theme }) => (!isCurrent && isCompleted) && css`
      border: 2px solid ${theme.palette.border.information};
      background-color: ${theme.palette.surface.brandPrimary};

  `}
`;

const StepTitle = styled(Typography)`
  text-overflow: ellipsis;
  overflow: hidden;
`;

const StepStyles = {
    StepWrapper,
    StepCircle,
    StepTitle,
};

export default StepStyles;
