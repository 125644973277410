import styled, { css } from 'styled-components';
import { Modal, Icon, Stack, Typography } from '../../../design-system/components-v2';

const TopModal = styled(Modal.ModalDialog)`
  height: 100%;
  width: 100%;
  z-index: 500;

  .modal {
    display: flex;
    position: absolute;
    width: 80%;
    height: 100%;
    max-height: 100%;
    right: 0;
    box-shadow: none;
    transition: all 300ms ease-in-out;
    transform: translateX(400px);
    background: transparent;
  }

  .content-after {
    transform: translateX(0);
  }
` ;

const TopDiv = styled(Stack)`
  height: 100%;
  width: 100%;
  display: flex;
  flex-direction: row;
  padding-left: 10px;
  align-items: flex-start;
  gap: 8px;
`;

const HeaderDiv = styled(Stack)<{ hasHeaderLineSeparator?: boolean }>`
    display: flex;
    flex-direction: row;
    padding: 12px 16px;
    align-items: center;
    gap: 12px;
    justify-content: space-between;
    width: 100%;
    align-self: stretch;
    border-bottom : ${props => props.hasHeaderLineSeparator ? '1px solid rgba(0, 38, 114, 0.08)' : 'none'};
`;

const RightHeaderContentDiv = styled.div`
  display: flex;
  flex-direction: row;
  gap: 12px;
  padding-right: 8px;
`;

const LoadingDiv = styled(Typography)`
  display: flex;
  align-items: center;
  margin: 32px;
  gap: 15px;

  svg {
    margin: 0;
  }
`;

const BodyDiv = styled(Stack)`
  height: 100%;
  width: 100%;
  box-shadow: 0 4px 50px 0 rgba(0, 0, 0, 0.50);
  background-color: white;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  flex: 1 0 0;
  border-radius: var(--padding-v, 0);
  overflow-x: auto;
`;

interface ContentDivProps {
  isHeaderRelevant?: boolean;
  hasHeaderLineSeparator?: boolean;
  disableSpacing?: boolean;
}
const ContentDiv = styled(Stack)<ContentDivProps>`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  flex: 1 0 0;
  width: 100%;
  overflow-y: auto;
  ${({ disableSpacing, hasHeaderLineSeparator, isHeaderRelevant }) => !disableSpacing && css`
    gap: 32px;
    padding-bottom: 24px;
    margin-bottom: 24px;
    padding-right: 24px;
    padding-left: 24px;
    padding-top: ${hasHeaderLineSeparator || !isHeaderRelevant ? '24px' : '0px'};
  `}
`;

const ContentComponentDiv = styled(Stack)`
  display: flex;
  width: 100%;
  height: 100%;
`;

const ActionsBar = styled(Stack)`
  display: flex;
  flex-direction: column;
  flex-grow: 0;
  width: 24px;
  padding-top: 8px;
  align-items: center;
  gap: 24px;
`;

const ActionIconDiv = styled(Stack)<{ isDisabled?: boolean }>`
  width: 24px;
  height: 24px;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 32px;
  background: #FFF;
  box-shadow: 0 4px 4px 0 rgba(0, 0, 0, 0.25);
  backdrop-filter: blur(4px);
  cursor: ${props => props.isDisabled ? 'default' : 'pointer'};
  opacity: ${props => props.isDisabled ? '0.3' : '0.7'};
  
  &:hover {
    opacity: ${props => props.isDisabled ? '0.3' : '1'};     
  }
`;

const IconDiv = styled(Icon)`
`;

const EmptyDiv = styled.div`
`;

export const DrawerStyled = {
    TopModal,
    TopDiv,
    BodyDiv,
    ContentDiv,
    IconDiv,
    ContentComponentDiv,
    ActionsBar,
    ActionIconDiv,
    HeaderDiv,
    RightHeaderContentDiv,
    LoadingDiv,
    EmptyDiv,
};
