import { createContext, useContext } from 'react';
import { IIssue } from '../../module_interface/RiskManagement/issues/Issues.interface';
import PropTypes from 'prop-types';

interface IssueContextProps {
    issue: IIssue | undefined;
}

const IssueContext = createContext<IssueContextProps | undefined>(undefined);

export const useIssue = (): IssueContextProps => {
    const context: IssueContextProps | undefined = useContext(IssueContext);
    if (!context) {
        throw new Error('useIssue must be used within a IssueProvider');
    }
    return context;
};

export const IssueProvider: React.FC<{ issue: IIssue, children: any }> = ({ issue, children }) => {
    return <IssueContext.Provider value={{ issue: issue }}>{children}</IssueContext.Provider>;
};

IssueProvider.propTypes = {
    issue: PropTypes.any.isRequired,
    children: PropTypes.node.isRequired,
};
