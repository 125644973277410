import i18n from 'i18next';
import { initReactI18next } from 'react-i18next';
import untranslated from '../../../assets/languages/untranslated.json';
import english from '../../../assets/languages/en/common.json';
import japanese from '../../../assets/languages/ja/common.json';
import spanish from '../../../assets/languages/spa/common.json';
import german from '../../../assets/languages/ger/common.json';
import france from '../../../assets/languages/fr/common.json';

// import fake from '../../../assets/languages/fake/common.json';

export type ILanguageName = 'en' | 'ja' | 'spa' | 'ger' | 'fr'; // To test fake language add 'fake' here
export const validLanguages: Array<ILanguageName> = ['en', 'ja', 'spa', 'ger', 'fr']; // To test fake language add 'fake' here

export const resources = {
    en: {
        translation: { ...untranslated, ...english },
    },
    ja: {
        translation: japanese,
    },
    spa: {
        translation: spanish,
    },
    ger: {
        translation: german,
    },
    fr: {
        translation: france
    }
    // To test your translation you can generate the fake language by running "npm run lang-helper-generate-fake" then enable the language below
    // fake: {
    //     translation: fake
    // }
};

i18n.use(initReactI18next).init({
    resources,
    lng: 'en',
    fallbackLng: 'en',
});

export default i18n;
