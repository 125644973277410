import globalAddinContainer from 'common/extensibility/AddinContainer';
import { Addin } from 'common/extensibility/AddinRegistry';
import {
    PATH_EVENTS_DRAWER_DETAILS_PANEL,
    PATH_EVENTS_DRAWER_ACTIONS,
    PATH_EVENTS_DRAWER_CHIPS,
    PATH_EVENTS_DRAWER_TABS,
    PATH_EVENTS_DRAWER_SECTIONS
} from '../../interface/events';
import { EventAction, IEvent } from './Events';
import { IChipProps } from 'common/design-system/components-v2/Chip/Chip.types';
import { Tab } from 'common/components/EntityViewer/EntityViewer.interface';
import { BlockInfoProps } from 'common/design-system/components-v2/BlockInfo/BlockInfo.types';

export interface EventsDrawerAddingBase<T> {
    id: string;
    isRelevant?: (event: IEvent) => Promise<boolean> | boolean;
    getValue: (event: IEvent) => Promise<T> | T;
}

export interface EventsDrawerAddingBaseValueComponent<T> extends EventsDrawerAddingBase<T>{
    getValue: (event: IEvent) => T;
}

export interface EventOverviewTabProps {
    finding: IEvent;
}

export const EventsDrawerRegistry = {
    addDetailsPanelItems: (details: Addin<EventsDrawerAddingBase<BlockInfoProps>>[]) => {
        globalAddinContainer.add(PATH_EVENTS_DRAWER_DETAILS_PANEL, details);
    },

    getDetailsPanelItems: (): EventsDrawerAddingBase<BlockInfoProps>[] => {
        return globalAddinContainer.get(PATH_EVENTS_DRAWER_DETAILS_PANEL);
    },

    addActions: (actions: Addin<EventsDrawerAddingBase<EventAction>>[]) => {
        globalAddinContainer.add(PATH_EVENTS_DRAWER_ACTIONS, actions);
    },

    getActions: (): EventsDrawerAddingBase<EventAction>[] => {
        return globalAddinContainer.get(PATH_EVENTS_DRAWER_ACTIONS);
    },

    addTabs: (tabs: Addin<EventsDrawerAddingBase<Tab>>[]) => {
        globalAddinContainer.add(PATH_EVENTS_DRAWER_TABS, tabs);
    },

    getTabs: (): EventsDrawerAddingBase<Tab>[] => {
        return globalAddinContainer.get(PATH_EVENTS_DRAWER_TABS);
    },

    addChips: (chips: Addin<EventsDrawerAddingBase<IChipProps>>[]) => {
        globalAddinContainer.add(PATH_EVENTS_DRAWER_CHIPS, chips);
    },
    
    getChips: (): EventsDrawerAddingBase<IChipProps>[] => {
        return globalAddinContainer.get(PATH_EVENTS_DRAWER_CHIPS);
    },

    addOverviewSections: (sections: Addin<EventsDrawerAddingBaseValueComponent<React.FC<EventOverviewTabProps>>>[]) => {
        globalAddinContainer.add(PATH_EVENTS_DRAWER_SECTIONS, sections);
    },
    
    getOverviewSections: (): EventsDrawerAddingBaseValueComponent<React.FC<EventOverviewTabProps>>[] => {
        return globalAddinContainer.get(PATH_EVENTS_DRAWER_SECTIONS);
    }
};
