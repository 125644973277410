import * as React from 'react';
import { SVGProps, Ref, forwardRef } from 'react';
const SvgEyeOff = (props: SVGProps<SVGSVGElement>, ref: Ref<SVGSVGElement>) => (
  <svg
    viewBox="0 0 17 16"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    ref={ref}
    {...props}
  >
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M1.553 1.22a.75.75 0 0 1 1.06 0l2.454 2.453c1.068-.633 2.32-1.108 3.683-1.108 1.725 0 3.276.762 4.504 1.648 1.234.891 2.208 1.954 2.796 2.666l.007.008c.291.364.443.821.443 1.283 0 .462-.152.92-.443 1.283l-.007.008c-.562.68-1.48 1.685-2.642 2.553l2.539 2.539a.75.75 0 0 1-1.06 1.06L1.552 2.28a.75.75 0 0 1 0-1.06Zm10.781 9.72c1.106-.79 2.001-1.76 2.555-2.43a.556.556 0 0 0 .11-.34.556.556 0 0 0-.11-.34c-.545-.66-1.425-1.615-2.513-2.4-1.097-.792-2.34-1.365-3.626-1.365-.89 0-1.763.275-2.582.71l.9.898a3.01 3.01 0 0 1 4.18 4.18l1.086 1.088Zm-2.189-2.189a1.51 1.51 0 0 0-1.976-1.976l1.976 1.976ZM4.059 5.28a.75.75 0 0 1-.043 1.06A15.374 15.374 0 0 0 2.61 7.83a.556.556 0 0 0-.11.34c0 .137.045.258.11.34.546.66 1.426 1.615 2.514 2.4 1.097.792 2.34 1.365 3.626 1.365a4.83 4.83 0 0 0 1.401-.216.75.75 0 0 1 .434 1.436 6.327 6.327 0 0 1-1.835.28c-1.725 0-3.276-.762-4.504-1.648-1.234-.89-2.208-1.954-2.796-2.666l-.007-.008A2.053 2.053 0 0 1 1 8.17c0-.462.152-.92.443-1.283l.007-.008c.374-.453.901-1.044 1.55-1.642a.75.75 0 0 1 1.06.043Z"
      fill="currentColor"
    />
  </svg>
);
const ForwardRef = forwardRef(SvgEyeOff);
export default ForwardRef;
