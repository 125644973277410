import React from 'react';
import Modal from 'common/design-system/components-v2/Modal';
import { useTranslation } from 'react-i18next';
import { ConfirmationModalProps } from './ConfirmationModal.types';
import { Button, Stack } from 'common/design-system/components-v2';
import { messageVariantIcon } from '../../design-system/components-v2/Message/Message';

const ConfirmationModal: React.FunctionComponent<ConfirmationModalProps> = props => {
    const { isOpen = true, title, text, isLoading = false, children, width = 'lg', submitBtnColor = 'brandPrimary', cancelBtnText, variant, submitBtnText, submitBtnDisabled , onCancel, onClose, onConfirm } = props;
    const { t } = useTranslation();

    return (
        <Modal.ModalDialog isOpen={isOpen} width={width} shouldCloseOnOverlayClick onRequestClose={onClose}>
            <Modal.ModalHeader hasSeparator={false} subTitle={text} title={title} onClose={onClose} icon={variant && messageVariantIcon[variant]} dataAid="remove-azure-modal" />
            {children && (
                <Modal.ModalContent>
                    {children}
                </Modal.ModalContent>
            )}
            {(onCancel || onConfirm) && (
                <Modal.ModalFooter hasSeparator={!!children}>
                    <Stack direction='row' justifyContent='flex-end' fullWidth spacing={2}>
                        {onCancel && <Button disabled={isLoading} variant='text' onClick={onCancel}>
                            {cancelBtnText || t('COMMON.CANCEL')}
                        </Button>}
                        {onConfirm && <Button data-aid="remove-azure-confirm" disabled={submitBtnDisabled} color={submitBtnColor} loading={isLoading} onClick={onConfirm}>
                            {submitBtnText || t('COMMON.CONFIRM')}
                        </Button>}
                    </Stack>
                </Modal.ModalFooter>
            )}
        </Modal.ModalDialog>
    );};

export default ConfirmationModal;
